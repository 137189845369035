const updateJudgesListWithData = (judgesList, currentJudge, courtState) => {
  const foundIndex = judgesList.findIndex((judge) => judge._id === currentJudge._id);
  const updatedJudge = { ...currentJudge, courts: [...currentJudge.courts] };
  updatedJudge.courts.push(courtState);
  const updatedJudges = [...judgesList];
  updatedJudges[foundIndex] = updatedJudge;
  return updatedJudges;
};

export default updateJudgesListWithData;
