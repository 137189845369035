import { useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, Popover } from 'antd';
import { PieChartOutlined, CrownFilled, FileDoneOutlined, FieldNumberOutlined } from '@ant-design/icons';
import { ROLES } from 'common_constants/business/index';
import UserAvatar from '../../../../components/UserAvatar';
import HoverableUser from '../../../../components/HoverableUser/HoverableUser';

import './RatingItem.scss';

const RatingItem = ({ ratingItem, topPlaces, lastPlaces }) => {
  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const isHotlines = !!ratingItem?.hotlinesCount;
  let currentPlace;

  switch (ratingItem.place) {
    case 1:
      currentPlace = <CrownFilled style={{ color: '#fadb14' }} />;
      break;
    case 2:
      currentPlace = <CrownFilled style={{ color: '#bfbfbf' }} />;
      break;
    case 3:
      currentPlace = <CrownFilled style={{ color: '#faad14' }} />;
      break;
    default:
      currentPlace = <FieldNumberOutlined />;
  }

  let cardItemClassName;

  switch (true) {
    case topPlaces.includes(ratingItem.place):
      cardItemClassName = ' _top';
      break;
    case lastPlaces.includes(ratingItem.place):
      cardItemClassName = ' _last';
      break;
    default:
      cardItemClassName = '';
  }

  return (
    <>
      <Card style={{ opacity: ratingItem.rating ? '1' : '0.5' }} className={`rating-item-inner${cardItemClassName}`}>
        <Row className="rating-item" justify={'space-between'} align={'middle'}>
          <Col className="rating-item-fields-inner" flex={'auto'}>
            <Row className="rating-item-fields" justify={'space-between'}>
              <Col className="rating-item-fields-left">
                <Row className="rating-item-fields-row">
                  <Row className="rating-item-fields-col">{currentPlace}&nbsp;</Row>
                  <Row className="rating-item-fields-col">{ratingItem.place ?? '?'}</Row>
                  <Row className="rating-item-fields-col">
                    <UserAvatar user={users?.[ratingItem.userId]} />
                  </Row>

                  <Row className="rating-item-fields-col">
                    {users ? (
                      <Popover content={<HoverableUser id={users[ratingItem?.userId]?._id} />} arrow trigger="hover">
                        {users[ratingItem?.userId]?.p}
                      </Popover>
                    ) : (
                      <Skeleton.Input active className="rating-item-skeleton" />
                    )}
                  </Row>
                </Row>
              </Col>

              <Col className="rating-item-fields-right">
                <Row className="rating-item-fields-row">
                  {inspector && (
                    <Row className="rating-item-fields-col">
                      <Row>
                        <FileDoneOutlined />
                        :&nbsp;
                      </Row>
                      <Row>{ratingItem.contractsCount}</Row>
                      <Row>/</Row>
                      <Row>{isHotlines ? ratingItem.hotlinesCount : ratingItem.reachesCount}</Row>
                    </Row>
                  )}
                  <Row className="rating-item-fields-col">
                    <Row>
                      <PieChartOutlined />
                      :&nbsp;
                    </Row>
                    <Row>{ratingItem.rating}</Row>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default RatingItem;
