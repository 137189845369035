import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Button, Input, Upload, Tabs } from 'antd';
import { PlusOutlined, CloseOutlined, UploadOutlined, InsuranceOutlined } from '@ant-design/icons';
import { request, error, warn, success } from '../../tools';

import { setModal, addInsuranceTypes, editInsuranceTypes } from '../../store/commonReducer';

import { languageOptions } from 'common_constants/translation';

import './styles.scss';

const { TextArea } = Input;

const AddInsuranceType = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.common.modal);

  const [currentTab, setCurrentTab] = useState('ua');

  const [loading, setLoading] = useState(false);

  const initialIsCreatingState = useMemo(() => {
    return languageOptions.reduce((acc, lang) => {
      acc[lang.value] = false;
      return acc;
    }, {});
  }, []);

  const [isCreating, setIsCreating] = useState(initialIsCreatingState);

  const [creatingServiceName, setCreatingServiceName] = useState('');
  const [creatingServiceCost, setCreatingServiceCost] = useState('');

  const initialState = useMemo(() => {
    return languageOptions.reduce((acc, lang) => {
      acc[lang.value] = {
        name: data?.[lang.value]?.name || '',
        description: data?.[lang.value]?.description || '',
        services: data?.[lang.value]?.services || [],
      };
      return acc;
    }, {});
  }, [data]);

  const [insuranceState, setInsuranceState] = useState(initialState);

  const [minimalPrice, setMinimalPrice] = useState(data?.minimalPrice || { 6: 0, 12: 0, 36: 0 });

  const initialImages = useMemo(() => {
    const images = {};
    if (data) {
      languageOptions.forEach(({ value }) => {
        const ext = data[value]?.extension === 'svg+xml' ? 'svg' : data[value]?.extension;
        data[value]?.image && data[value]?.extension
          ? (images[value] = `${process.env.REACT_APP_API}/insuranceTypeImages/${data[value].image}.${ext}`)
          : (images[value] = null);
      });
    }
    return images;
  }, [data]);

  const [imageUploaded, setImageUploaded] = useState(initialImages);

  const initialFileList = useMemo(() => {
    const images = {};
    languageOptions.forEach(({ value }) => {
      images[value] = [];
    });
    return images;
  }, []);

  const [fileList, setFileList] = useState(initialFileList);
  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];

  const getUploadProps = useMemo(
    () => (lang) => {
      return {
        onRemove: () => {
          if (data?.[lang]?.image && data?.[lang]?.extension) {
            setImageUploaded((prev) => ({
              ...prev,
              [lang]: `${process.env.REACT_APP_API}/insuranceTypeImages/${data[lang].image}.${data[lang].extension}`,
            }));
          } else {
            setImageUploaded((prev) => ({ ...prev, [lang]: null }));
          }
          setFileList((prev) => ({ ...prev, [lang]: [] }));
        },
        beforeUpload: (file) => {
          const isImage = allowedTypes.includes(file.type);

          if (!isImage) {
            error('Можливо завантажити фото тільки в JPG, PNG та SVG форматі!', ' ');
            setFileList((prev) => ({ ...prev, [lang]: [] }));
            return false;
          }

          setImageUploaded((prev) => ({ ...prev, [lang]: URL.createObjectURL(file) }));
          setFileList((prev) => ({ ...prev, [lang]: [file] }));

          return false;
        },
        fileList: fileList[lang] || [],
      };
    },
    [fileList, data],
  );

  const handleInputChange = (lang, field, value) => {
    setInsuranceState((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value.trim(),
      },
    }));
  };

  const handlePriceChange = (key, value) => {
    setMinimalPrice((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const areObjectsEqual = (obj1, obj2) => {
    return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
  };

  const shouldSendData = () => {
    const isDataMinimalPriceNonZero = data?.minimalPrice && Object.values(data.minimalPrice).every((value) => value !== 0);
    const isMinimalPriceNotChanged = data?.minimalPrice ? areObjectsEqual(minimalPrice, data.minimalPrice) : false;

    if (isDataMinimalPriceNonZero && isMinimalPriceNotChanged) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!minimalPrice[6]) return warn('', 'Додайте мінімальну страхову суму для півроку!');
    if (!minimalPrice[12]) return warn('', 'Додайте мінімальну страхову суму для року!');
    if (!minimalPrice[36]) return warn('', 'Додайте мінімальну страхову суму для 3 років!');
    if (insuranceState['ua'].name === '' || insuranceState['ua'].services.length === 0) return warn('', 'Додайте тип страховки на українській мові!');

    setLoading(true);

    const formData = new FormData();

    const keys = [];
    let hasChanges = false;

    for (const { value, label } of languageOptions) {
      if (insuranceState[value].name && insuranceState[value].services.length > 0) {
        if (data && data?.[value] && Object.keys(data?.[value]).length > 0) {
          const currentState = insuranceState[value];
          const { image, extension, ...originalState } = data[value];

          const isChanged = JSON.stringify(currentState) !== JSON.stringify(originalState);

          if (isChanged) {
            const changedFields = {};

            if (currentState.name !== originalState?.name) changedFields.name = currentState.name;
            if (currentState.description !== originalState?.description) {
              if (!(currentState.description === '' && originalState?.description === undefined))
                changedFields.description = currentState.description;
            }

            for (const [index, service] of currentState.services.entries()) {
              if (originalState?.services?.length === currentState.services.length) {
                if (areObjectsEqual(service, originalState.services[index])) continue;
              }
              changedFields.services = currentState.services;
              break;
            }
            if (Object.keys(changedFields).length > 0) {
              formData.append(value, JSON.stringify(changedFields));
              hasChanges = true;
            }
          }
        } else {
          for (const service of insuranceState[value].services) {
            if (!(service?.name && service?.cost)) {
              setLoading(false);
              return warn(`Мова ${label}`, `Заповніть усі поля для послуги ${service.name}`);
            }
          }
          formData.append(value, JSON.stringify(insuranceState[value]));
          hasChanges = true;
        }

        keys.push(value);
      }
    }

    Object.entries(fileList).forEach(([lang, fileArray]) => {
      if (fileArray.length > 0 && keys.includes(lang)) {
        formData.append(`${lang}`, fileArray[0]);
        hasChanges = true;
      }
    });

    if (shouldSendData()) formData.append('minimalPrice', JSON.stringify(minimalPrice));
    else if (data && !hasChanges) {
      setLoading(false);
      return warn('', 'Ніяких змін внесено не було');
    }

    if (data) formData.append('id', data._id);

    await request.post(
      data ? '/insuranceType/editInsuranceType' : '/insuranceType/addInsuranceType',
      formData,
      ({ resultInsuranceType }) => {
        setLoading(false);
        data ? dispatch(editInsuranceTypes(resultInsuranceType)) : dispatch(addInsuranceTypes(resultInsuranceType));
        dispatch(setModal());
        success('', data ? 'Тип страховки успішно редаговано' : 'Тип страховки успішно додан');
      },
      () => {
        setLoading(false);
        error('', data ? 'Помилка при редагуванні.' : 'Помилка при додаванні.');
      },
    );
  };

  const handlerCreatingTask = (lang) => {
    setIsCreating((prev) => ({
      ...prev,
      [lang]: !prev[lang],
    }));
  };

  const updateServices = (lang, newServices) => {
    setInsuranceState((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        services: newServices,
      },
    }));
  };

  const updateField = (lang, index, field, newValue) => {
    const updatedServices = [...insuranceState[lang].services];
    updatedServices[index] = { ...updatedServices[index], [field]: newValue };

    updateServices(lang, updatedServices);
  };

  const deleteField = (lang, index) => {
    const updatedServices = insuranceState[lang].services.filter((_, i) => i !== index);

    updateServices(lang, updatedServices);
  };

  const onCreateTask = (lang) => {
    if (creatingServiceCost === '' || creatingServiceName === '') return warn('Створення послуги', 'Заповніть усі поля');

    setInsuranceState((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        services: [...(prevState[lang]?.services || []), { name: creatingServiceName.trim(), cost: creatingServiceCost }],
      },
    }));

    setCreatingServiceName('');
    setCreatingServiceCost('');
    handlerCreatingTask(lang);
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  const onChangeTab = (key) => {
    setCurrentTab(key);
  };

  const items = languageOptions.map(({ value, label }) => ({
    label: label,
    key: value,
    children: (
      <>
        <div className="box">
          <h4>Тип страховки</h4>
          <Input
            defaultValue={insuranceState[value]?.name || ''}
            placeholder="Назва типу"
            onBlur={(e) => handleInputChange(value, 'name', e.target.value)}
          />
        </div>

        <div className="box">
          <h4>Зображення</h4>
          <div className="image-wrapper">
            <div className="image-viewer">
              {imageUploaded?.[value] && <img src={imageUploaded[value]} className="image" alt="" />}
              <div className="preview-text">Попередній перегляд</div>
            </div>
            <div className="image-uploader">
              <Upload {...getUploadProps(value)}>
                <Button icon={<UploadOutlined />}>Виберіть фото</Button>
              </Upload>
            </div>
          </div>
        </div>

        <div className="box">
          <h4>Опис</h4>
          <TextArea
            defaultValue={insuranceState[value]?.description || ''}
            placeholder="Опис типу страховки"
            onBlur={(e) => handleInputChange(value, 'description', e.target.value)}
          />
        </div>

        <div className="box">
          <h4>Послуги</h4>
          {insuranceState[value]?.services?.length > 0 &&
            insuranceState[value].services.map((service, index) => (
              <div className="service" key={service.name}>
                <InsuranceOutlined />
                <div>
                  <div className="field">
                    <b style={{ marginRight: '10px' }}>Назва послуги:</b>
                    <Input
                      defaultValue={service.name}
                      type="text"
                      placeholder="Введіть назву..."
                      onBlur={(e) => {
                        updateField(value, index, 'name', e.target.value);
                      }}
                    />
                  </div>

                  <div className="field">
                    <b style={{ marginRight: '18px' }}>Ціна:</b>
                    <Input
                      onKeyDown={handleKeyPress}
                      defaultValue={service.cost}
                      type="number"
                      placeholder="Ціна"
                      min={0}
                      onBlur={(e) => {
                        updateField(value, index, 'cost', Number(e.target.value));
                      }}
                    />
                  </div>
                </div>
                <Button style={{ marginLeft: 'auto' }} type="text" icon={<CloseOutlined />} onClick={() => deleteField(value, index)} />
              </div>
            ))}

          {isCreating[value] && (
            <div className="box" style={{ marginTop: '10px', width: '100%' }}>
              <TextArea defaultValue={creatingServiceName} onBlur={(e) => setCreatingServiceName(e.target.value)} placeholder="Введіть назву..." />
              <Input
                onKeyDown={handleKeyPress}
                defaultValue={creatingServiceCost}
                type="number"
                placeholder="Ціна"
                min={0}
                onBlur={(e) => {
                  setCreatingServiceCost(Number(e.target.value));
                }}
              />
            </div>
          )}
          {isCreating[value] ? (
            <div className="row">
              <Button type="primary" onClick={() => onCreateTask(value)}>
                Створити
              </Button>
              <Button type="text" icon={<CloseOutlined />} onClick={() => handlerCreatingTask(value)}></Button>
            </div>
          ) : (
            <Button icon={<PlusOutlined />} style={{ marginTop: '10px' }} type="text" onClick={() => handlerCreatingTask(value)}>
              Додати
            </Button>
          )}
        </div>
      </>
    ),
  }));

  return (
    <Modal
      className="modalAddInsuranceType"
      title={data ? 'Редагування типу страховки' : 'Додати тип страховки'}
      open
      onCancel={() => dispatch(setModal())}
      footer={null}
    >
      <Spin spinning={loading}>
        <Tabs defaultActiveKey="ua" items={items} onChange={onChangeTab} />

        <div className="box">
          <h4>Додайте мінімальні страхові суми для періодів:</h4>
          <span className="period">Півроку</span>
          <Input
            onKeyDown={handleKeyPress}
            type="number"
            min={0}
            defaultValue={minimalPrice[6]}
            placeholder="2100"
            onBlur={(e) => handlePriceChange(6, Number(e.target.value))}
          />

          <span className="period">1 рік</span>
          <Input
            onKeyDown={handleKeyPress}
            type="number"
            min={0}
            defaultValue={minimalPrice[12]}
            placeholder="3600"
            onBlur={(e) => handlePriceChange(12, Number(e.target.value))}
          />

          <span className="period">3 роки</span>
          <Input
            onKeyDown={handleKeyPress}
            type="number"
            min={0}
            defaultValue={minimalPrice[36]}
            placeholder="9000"
            onBlur={(e) => handlePriceChange(36, Number(e.target.value))}
          />
        </div>

        <Button
          type="primary"
          className="button"
          onClick={handleSubmit}
          disabled={insuranceState[currentTab].name === '' || insuranceState[currentTab].services.length === 0}
        >
          {data ? 'Редагувати страховку' : 'Додати страховку'}
        </Button>
      </Spin>
    </Modal>
  );
};

export default AddInsuranceType;
