import React from 'react';
import { CLUB_CARDS_PERIODS } from 'common_constants/business';
import SearchSelect from './SearchSelect';

const SearchSelectClubCardPeriod = ({ onChange, value, className, style }) => {
  return (
    <SearchSelect
      className={className}
      style={style}
      placeholder="Оберіть період (міс.)"
      list={Object.entries(CLUB_CARDS_PERIODS).map(([key, value]) => ({ value: value, label: key }))}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectClubCardPeriod;
