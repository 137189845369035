import React from 'react';
import { Col, Row, Rate, Button } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { VOTING_STEPS } from '../../VotingConstants';

import './PersonalVotingResult.scss';

const PersonalVotingResult = ({ latestVote, setCurrentVotingStep, block, notMyBranch, notMyBlock }) => {
  const handleChangeVote = () => setCurrentVotingStep(VOTING_STEPS.VOTING);

  return (
    <Col className="personalVotingResult">
      <Row justify={'center'} className="personalVotingResult__title">{`Ви  ${
        latestVote.vote ? 'за архівацію' : 'проти архівації'
      }  даного договору!`}</Row>

      {latestVote.vote ? (
        <Row justify={'center'}>
          <CheckCircleTwoTone twoToneColor="#52c41a" className="personalVotingResult__icon" />
        </Row>
      ) : (
        <Row justify={'center'}>
          <CloseCircleTwoTone twoToneColor="red" className="personalVotingResult__icon" />
        </Row>
      )}

      {latestVote.vote && notMyBranch && notMyBlock && block && latestVote.rating ? (
        <>
          <Row justify={'center'} className="personalVotingResult-rate__title">
            Ваша оцінка Блоку
          </Row>
          <Row justify={'center'}>
            <Rate disabled defaultValue={latestVote.rating} justify={'center'} />
          </Row>
        </>
      ) : null}

      <Row justify={'center'} className="personalVotingResult-rate-buttons">
        <Button type="primary" onClick={handleChangeVote}>
          Змінити вибір
        </Button>
      </Row>
    </Col>
  );
};

export default PersonalVotingResult;
