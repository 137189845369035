import React, { useState } from 'react';
import clsx from 'clsx';
import './Collapse.scss';

const Collapse = ({ defaultActiveKey, children }) => {
  const [activeKey, setActiveKey] = useState(false);

  const handlePanelClick = (panelKey) => {
    setActiveKey(panelKey === activeKey ? null : panelKey);
  };

  return (
    <div className="collapse-component">
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          const panelKey = child.props.panelKey || index;
          const isActive = panelKey === activeKey;
          return React.cloneElement(child, {
            isActive,
            onClick: () => handlePanelClick(panelKey),
          });
        }
        return null;
      })}
    </div>
  );
};

const Panel = ({ header, isActive, onClick, arrowIcon, children }) => {
  return (
    <div className={clsx('panel', { active: isActive })} onClick={onClick}>
      <div className="panel-header">{header}</div>
      {isActive && <div className="panel-content">{children}</div>}
    </div>
  );
};

Collapse.Panel = Panel;

export default Collapse;
