import React from 'react';
import { useDispatch } from 'react-redux';
import { ArrowLeftOutlined, MoreOutlined, InfoCircleOutlined, ControlOutlined, SearchOutlined } from '@ant-design/icons';
import { CHAT_GROUP_INFO, CREATE_NEW_GROUP_CHAT } from 'common_constants/modals';

import { setModal } from '../../store/commonReducer';

const ChatGroupMoreContentPopover = ({ currentChat }) => {
  const dispatch = useDispatch();

  const openEditChatGroup = () => {
    if (currentChat?.isGroup) {
      dispatch(setModal({ name: CREATE_NEW_GROUP_CHAT, data: { chatId: currentChat._id } }));
    }
  };

  const openChatGroupInfo = () => {
    if (currentChat?.isGroup) {
      dispatch(setModal({ name: CHAT_GROUP_INFO, data: { chatId: currentChat._id } }));
    }
  };

  return (
    <div className="chat-popover _group">
      <div onClick={openChatGroupInfo} className="item">
        <InfoCircleOutlined />
        <p>Інформація про групу</p>
      </div>
      <div onClick={openEditChatGroup} className="item">
        <ControlOutlined />
        <p>Керувати групою</p>
      </div>
    </div>
  );
};

export default ChatGroupMoreContentPopover;
