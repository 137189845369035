import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'antd';
import ReactECharts from 'echarts-for-react';
import { request, warn } from '../../tools';
import { useSelector } from 'react-redux';

const StatFilActiveClientsWithApp = () => {
  const [contractsData, setContractsData] = useState([]);
  const FILII = useSelector((state) => state.common.FILII);

  const fetchContractsData = async () => {
    request.post('/statistic/filActiveClientsWithApp', null, ({ status, data }) => {
      if (!status || !data) return warn();

      const joinedData = data[0]?.withClientwithID?.map((item) => {
        const withoutClient = data[0]?.withoutClientwithID?.find((el) => +el._id === +item._id);
        console.log(withoutClient, item);
        const withApp = +item.count;
        const withoutApp = +withoutClient?.count;
        const withAppPercent = ((100 * withApp) / (withApp + withoutApp)).toFixed(2);
        const withoutAppPercent = ((100 * withoutApp) / (withApp + withoutApp)).toFixed(2);

        return {
          f: FILII[+item._id] || item.f,
          withApp,
          withoutApp,
          withAppPercent,
          withoutAppPercent,
        };
      });
      const sortedData = joinedData.sort((a, b) => a.withAppPercent - b.withAppPercent);
      setContractsData(sortedData);
    });
  };

  useEffect(() => {
    fetchContractsData();
  }, []);

  const option = {
    title: {
      text: 'Кількість активних клієнтів з додатком',
      left: 'center',
      padding: [40, 0, 0, 0],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const item = contractsData[params[0].dataIndex];
        return `${item.f}<br />З додатком: ${item.withAppPercent}% (${item.withApp} шт.)<br /> Без додатку: ${item.withoutAppPercent}% (${item.withoutApp} шт.)`;
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: contractsData.map((i) => i.f),
    },
    series: [
      {
        name: 'Заповнено',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'none',
        },
        data: contractsData.map((i) => i.withAppPercent),
      },
      {
        name: 'Не заповнено',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'none',
        },
        data: contractsData.map((i) => i.withoutAppPercent),
      },
    ],
  };
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card>
          <ReactECharts option={option} style={{ height: '1400px' }} />
        </Card>
      </Col>
    </Row>
  );
};

export default StatFilActiveClientsWithApp;
