import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Typography, List, Row, Button, Select, Modal, InputNumber, Switch } from 'antd';
import clsx from 'clsx';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, RestTwoTone } from '@ant-design/icons';

import { request } from '../../tools/index';
import { setPravoRating } from '../../store/commonReducer';
import { PRAVO, ROLES } from 'common_constants/business/index';

import './PravoRating.scss';

const { Title } = Typography;
const { Option } = Select;
const { Item } = List;

export const makePravoRating = (_pravoRating = []) => {
  const pravoRating = [];
  const allSugarsAmount = _pravoRating.reduce((acc, curr) => acc + (curr.sugarTotal || 0), 0);

  _pravoRating.forEach((item, i) => {
    pravoRating[i] = {
      ..._pravoRating[i],
      recCoeff: (item.sugarTotal / allSugarsAmount || 0).toFixed(2),
      recommendedPrice: Math.round(500 + 1500 * (item.sugarTotal / allSugarsAmount)) || 500,
    };
  });

  const manualRatingItems = pravoRating?.filter((item) => item.manualRating);
  const noWeightRatingItems = pravoRating.filter((item) => !item.weight && !item.manualRating);
  const weightRatingItems = pravoRating.filter((item) => item.weight && !item.manualRating);
  const arrayLength = noWeightRatingItems ? noWeightRatingItems.length : 0;

  const firstPart = noWeightRatingItems?.slice(0, 5);
  const rest = noWeightRatingItems?.slice(5, arrayLength);
  const thirdPart = rest?.slice(-10);
  const secondPart = thirdPart.length - 10 < 0 ? [] : thirdPart?.slice(0, thirdPart.length - 10);

  manualRatingItems.forEach((item) => {
    if (item.manualRating >= 0) {
      if (item.manualRating === 40) {
        firstPart.push(item);
      } else if (item.manualRating === 10) {
        secondPart.push(item);
      } else {
        thirdPart.push(item);
      }
    }
  });

  weightRatingItems.forEach((item) => {
    if (item.weight) {
      secondPart.push(item);
    }
  });

  return [firstPart, secondPart, thirdPart];
};

const PravoRatingItem = ({ item, index, admin, onSavePrice, openModal, category, onToggleInfoCenter }) => {
  const price = item.price || 500;

  const [value, setValue] = useState(price);
  const [editing, setEditing] = useState(false);

  const resetValue = () => {
    setValue(price);
  };

  return (
    <Item justify={'space-between'} className="pravoRating-item">
      <Row className={'pravoRating-item__content'} justify="space-between">
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col className={clsx('pravoRating-item__type', { editing })}>
            {index}. {PRAVO[item.pravo]}
            {/* {item.weight && <RestTwoTone style={{ marginLeft: 8 }} />} */}
          </Col>
          <br />
          {admin ? (
            <>
              {editing ? (
                <div justify={'none'}>
                  <InputNumber
                    value={value}
                    onChange={(value) => setValue(value)}
                    style={{ width: '80px', marginRight: 6, fontSize: 12 }}
                    step={100}
                  />
                  <CheckCircleOutlined
                    style={{ color: 'green', marginRight: 4 }}
                    onClick={() => {
                      onSavePrice(item._id, value);
                      setEditing(false);
                    }}
                  />
                  <CloseCircleOutlined
                    style={{ color: 'red' }}
                    onClick={() => {
                      resetValue();
                      setEditing(false);
                    }}
                  />
                </div>
              ) : (
                <span className="pravoRating-item__value">
                  ₴{price} <EditOutlined style={{ color: 'orange' }} onClick={() => setEditing(true)} />
                </span>
              )}
            </>
          ) : (
            <span className="pravoRating-item__value">₴{price}</span>
          )}
        </Row>

        {admin && (
          <Row className="recomended_price" justify={'space-between'}>
            <span>Рекомендована ціна: </span>
            <span>
              {' '}
              {item.recommendedPrice} ₴ ({item.recCoeff})
            </span>
          </Row>
        )}

        {admin && (
          <>
            <br />
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col className="pravoRating-item__type">
                {admin && (
                  <Row className="pravoRating-item__value">
                    <b>{item.rating}</b>
                  </Row>
                )}
              </Col>
              <br />
              {admin && (
                <Col>
                  <Button
                    style={{ padding: '0 15px', height: 24 }}
                    className={item.manualRating ? 'manual' : ''}
                    onClick={() => openModal(item._id, category)}
                  >
                    {category === 'free' && <>&rarr;</>}
                    {category === 'medium' && <>&harr;</>}
                    {category === 'paid' && <>&larr;</>}
                  </Button>
                </Col>
              )}
            </Row>
          </>
        )}
        {admin && (
          <>
            <br />
            <Row className="info-center" justify={'space-between'}>
              <span>Не інформаційний центр: </span>
              <Switch checked={item.infoCenter} onChange={(checked) => onToggleInfoCenter(item._id, checked)} />
            </Row>
          </>
        )}
      </Row>
    </Item>
  );
};

const PravoRating = () => {
  const dispatch = useDispatch();
  const pravoRating = useSelector((state) => state.common.pravoRating ?? []);
  const admin = useSelector((state) => ROLES[state.common.userAuth.role] === ROLES.admin);

  const [firstPart, secondPart, thirdPart] = makePravoRating(pravoRating);

  const openModal = (_id, currentCategory) => {
    Modal.info({
      title: 'Оберіть іншу категорію',
      content: (
        <Select defaultValue={currentCategory} onChange={(value) => saveManualRating(_id, value)}>
          <Option value="free">Безкоштовні</Option>
          <Option value="medium">Середні</Option>
          <Option value="paid">Платні</Option>
          <Option value="default">Скинути</Option>
        </Select>
      ),
      okText: 'Зберегти',
      maskClosable: true,
    });
  };

  const saveManualRating = (_id, selectedOption) => {
    request.post('/pravoRating/updateManualRating', { _id, category: selectedOption }, ({ pravoRating }) => {
      dispatch(setPravoRating(pravoRating));
    });
  };

  const onSavePrice = (_id, value) => {
    request.post('/pravoRating/updatePrice', { _id, price: value }, ({ status }) => {
      if (status) {
        dispatch(setPravoRating(pravoRating.map((item) => (item._id === _id ? { ...item, price: value } : item))));
      }
    });
  };

  const onToggleInfoCenter = (_id, infoCenter) => {
    request.post('/pravoRating/updateInfoCenter', { _id, infoCenter }, ({ status }) => {
      if (status) {
        dispatch(setPravoRating(pravoRating.map((item) => (item._id === _id ? { ...item, infoCenter } : item))));
      }
    });
  };

  return (
    <Col style={{ maxWidth: '1000px', margin: '0 auto' }}>
      <Title style={{ textAlign: 'center' }} level={3}>
        Рейтинг Права
      </Title>
      <Row style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <List
          header={<b>Безкоштовні</b>}
          className="pravoRating-list"
          dataSource={firstPart ?? []}
          renderItem={(item, index) => (
            <PravoRatingItem
              key={item._id}
              item={item}
              index={index + 1}
              admin={admin}
              onSavePrice={onSavePrice}
              openModal={openModal}
              category="free"
              onToggleInfoCenter={onToggleInfoCenter}
            />
          )}
        />

        {secondPart?.length ? (
          <List
            header={<b>Середні</b>}
            className="pravoRating-list"
            dataSource={secondPart ?? []}
            renderItem={(item, index) => (
              <PravoRatingItem
                key={item._id}
                item={item}
                index={index + firstPart.length + 1}
                admin={admin}
                onSavePrice={onSavePrice}
                openModal={openModal}
                category="medium"
                onToggleInfoCenter={onToggleInfoCenter}
              />
            )}
          />
        ) : null}

        {thirdPart?.length ? (
          <List
            header={<b>Платні</b>}
            className="pravoRating-list"
            dataSource={thirdPart ?? []}
            renderItem={(item, index) => (
              <PravoRatingItem
                key={item._id}
                item={item}
                index={index + firstPart.length + secondPart.length + 1}
                admin={admin}
                onSavePrice={onSavePrice}
                openModal={openModal}
                category="paid"
                onToggleInfoCenter={onToggleInfoCenter}
              />
            )}
          />
        ) : null}
      </Row>
    </Col>
  );
};

export default PravoRating;
