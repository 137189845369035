import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Row, Col, Button, Modal } from 'antd';
import { RedoOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { POSLUGI } from 'common_constants/business';
import { ROUTES } from 'common_constants/routes';
import { volcano, orange, red } from '@ant-design/colors';
import dayjs from 'dayjs';
import { request, warn } from '../../tools';
import { setMainLoader } from '../../store/uiReducer';

const HotlinesByClientsItem = ({ item, refresh }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const FILII = useSelector((state) => state.common.FILII);

  const removeConfirm = (data) => {
    const startTArr = data.startT.split(':');
    const _time = dayjs(data.date).set({
      hour: parseInt(startTArr[0]) - 1,
      minute: parseInt(startTArr[1]),
    });
    Modal.confirm({
      title: 'Ви впевнені у видаленні запису?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Так',
      okType: 'danger',
      cancelText: 'Ні',
      onOk() {
        console.log('');
        onRemoveClick(data);
      },
      onCancel() {
        console.log('');
      },
    });
  };

  const onRemoveClick = (data) => {
    const validation = (data) => {
      if (!data._id) {
        warn('', 'Для видалення запису потрібно вказати чинного клієнта', { 'data.id': data.id });
        return;
      }
      return true;
    };

    if (!validation(data)) return;

    dispatch(setMainLoader(true));

    const body = {
      _id: data._id,
    };

    request.post('/hotline/remove', body, (req) => {
      refresh();
      dispatch(setMainLoader(false));
    });
  };

  return (
    <Card style={item.e ? { backgroundColor: orange[2] } : item.disabled ? { backgroundColor: volcano[2] } : {}}>
      <Row justify={'space-between'}>
        <Col>
          {FILII[item.fil]} {dayjs(item.date).format('DD.MM.YYYY')}
        </Col>
        <Col>
          {item.startT} - {item.endT}
        </Col>
      </Row>
      <Row justify={'space-between'}>
        <Col>
          {item.name} +{item.countryPhCode} {item.phone}
        </Col>
        <Col>{POSLUGI[item.type]}</Col>
      </Row>
      <Row justify={'space-between'}>
        <Col>{item.qh?.qc >= 0 && 'Оцінка якості: ' + item.qh.qc}</Col>
      </Row>
      <Row justify={'space-between'}>
        <Col>{dayjs(item.crAt).format('DD.MM.YYYY HH:mm:ss')}</Col>
        <Col>
          {
            item.ip?.replace(/^::ffff:/, '') //* Додатково видалення ffff з ip
          }
        </Col>
      </Row>
      {(item.cost && item.pravo) || item.s === '1' || item.disabled ? null : (
        <Row justify={'center'}>
          <Button
            type="primary"
            onClick={(event) => {
              event.stopPropagation();
              history.push(ROUTES.HOTLINE_UPDATE, { data: { ...item, crAt: dayjs(item.crAt), date: dayjs(item.date) }, updateClientHotline: true });
            }}
          >
            Вказати відсутні поля
            <RedoOutlined />
          </Button>
          <Button style={{ background: red[5] }} onClick={() => removeConfirm(item)}>
            Скасувати зустріч
          </Button>
        </Row>
      )}
    </Card>
  );
};

export default HotlinesByClientsItem;
