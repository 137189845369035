import React from 'react';
import { Row, Tabs } from 'antd';
import { Sugar } from '../../Partials';

const { TabPane } = Tabs;

const FullBalance = ({
  sugarPageSize,
  currency,
  fullBalanceState,
  handleSetSugarApproved,
  onChangeFullBalanceSugarPage,
  onChangeFullBalanceSugarPageSize,
  handleFullBalanceSugarDateChange,
}) => (
  <Row>
    <Tabs className="tabs" defaultActiveKey="1" type="card">
      <TabPane tab="Доходи" key="1">
        <Sugar
          type={'fullBalance'}
          state={fullBalanceState}
          currency={currency}
          pageSize={sugarPageSize}
          onChangePage={onChangeFullBalanceSugarPage}
          handleDateChange={handleFullBalanceSugarDateChange}
          handleSetApproved={handleSetSugarApproved}
          onChangePageSize={onChangeFullBalanceSugarPageSize}
        />
      </TabPane>
    </Tabs>
  </Row>
);

export default React.memo(FullBalance);
