import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from 'antd';
import { CHAT_TYPES } from 'common_constants/business';
import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { UserColorAvatar } from '../../components/UserAvatar';
import { request, error } from '../../tools';
import { getChatName } from './helpers';
import ChatUserSkeleton from './ChatUserSkeleton';

const getClientAvatarUrl = (clientInfo) => {
  return clientInfo?.upa ? process.env.REACT_APP_API + `/avatars/${clientInfo.upa}_200.jpeg` : null;
};

const ChatUser = ({
  chatId, // it's id of chat or searched user
  onChangeCurrentChatId,
  getChatByInterlocutorId,
  setInputSearch,
  chatType,
  currentChatId,
  isSearchedUser,
}) => {
  const chatUserRef = useRef();
  const FILII = useSelector((state) => state.common.FILII);
  const unreadedMessagesData = useSelector((state) => state.common.unreadedMessagesData);
  const chatsData = useSelector((state) => state.common.chatsData);
  const onlineUsers = useSelector((state) => state.common.onlineUsers);
  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);

  const [lastMessageData, setLastMessageData] = useState({
    message: '',
    author: '',
    isClientAuthor: '',
    date: '',
    removed: true,
  });
  const [currentChat, setCurrentChat] = useState({});
  const [totalUnreadedMessages, setTotalUnreadedMessages] = useState(0);
  const [groupChatAvatarBase64, setGroupChatAvatarBase64] = useState(null);

  const activeUsers = useMemo(() => Object.values(users || {}).filter((user) => user._id !== userAuth?._id), [users]);

  const findCurrentInterlocutor = (item) => {
    if (item?.isGroup) return {};
    return item?.users ? activeUsers?.find((user) => item?.users?.includes(user._id)) : {};
  };

  const isContractChat = chatType === CHAT_TYPES.contractChat.key;
  const isAccountantChat = chatType === CHAT_TYPES.accountantChat.key;
  const isChatWithClient = chatType === CHAT_TYPES.chatWithClient.key || chatType === CHAT_TYPES.redButtonChat.key;
  const isLawyerChat = chatType === CHAT_TYPES.lawyerChat.key;
  const isGroupChat = chatType === CHAT_TYPES.groupChat.key;
  const isPersonalConsultantChat = chatType === CHAT_TYPES.personalConsultantChat.key;

  const currentInterlocutor = isSearchedUser ? users?.[chatId] : findCurrentInterlocutor(currentChat) ?? {};
  const currentClient = isChatWithClient || isPersonalConsultantChat ? currentChat?.clientInfo?.[0] : {};
  const isActiveChat = currentChatId === chatId;
  // const isArchived = currentChat?.isArchived;
  const isBlocked = currentChat?.clientBlockedId?.length > 0;
  const isDisabledChat = isBlocked;

  const onClickChatUser = () => {
    if (isSearchedUser && isLawyerChat) {
      getChatByInterlocutorId(chatId, (data) => {
        onChangeCurrentChatId(data._id);
      });
    } else {
      onChangeCurrentChatId(chatId);
    }

    setInputSearch('');
  };

  useEffect(() => {
    const chatTp = CHAT_TYPES.redButtonChat.key === chatType ? CHAT_TYPES.chatWithClient.key : chatType;

    const total =
      unreadedMessagesData?.find(
        (unreadedChat) =>
          unreadedChat?.chatId === chatId &&
          (unreadedChat?.chatType === chatTp ||
            (unreadedChat?.chatType === CHAT_TYPES.redButtonChat.key && CHAT_TYPES.chatWithClient.key === chatTp)),
      )?.total || 0;

    setTotalUnreadedMessages(total);
  }, [unreadedMessagesData]);

  useEffect(() => {
    const lastMessage = currentChat?.messages?.filter((item) => !item.removed)?.slice(-1)?.[0];

    if (!lastMessage) return;

    const userAuthorName =
      userAuth?._id === lastMessage.sender
        ? 'ви'
        : currentClient?._id === lastMessage.sender
        ? 'клієнт'
        : Object.values(users)?.find((user) => user._id === lastMessage.sender)?.p || 'Невідомо';

    setLastMessageData({
      message: lastMessage.fileId ? 'Файлове повідомлення' : lastMessage.message,
      author: userAuthorName,
      isClientAuthor: currentClient?._id === lastMessage.sender,
      date: lastMessage?.date,
      removed: lastMessage?.removed ?? false,
    });
  }, [currentChat?.messages]);

  useEffect(() => {
    const chat = chatsData?.[chatType]?.find((chat) => chat._id === chatId) ?? {};
    setCurrentChat(chat);
  }, [chatsData?.[chatType]]);

  useEffect(() => {
    if (!currentChat?.avatarImage) return;

    request.post(
      '/chatPrivate/chatGetFileBase64',
      { chatId, type: CHAT_TYPES.lawyerChat.key, fileId: currentChat.avatarImage },
      ({ image }) => {
        setGroupChatAvatarBase64(image);
      },
      error,
    );

    return () => {};
  }, [currentChat?.avatarImage]);

  return (
    <div ref={chatUserRef} className={clsx('interlocutor', isActiveChat && '_active', isDisabledChat ? '_disabled' : '')} onClick={onClickChatUser}>
      {!currentInterlocutor?._id && (isLawyerChat || isGroupChat) && !currentChat?.isGroup ? (
        <ChatUserSkeleton />
      ) : (
        <>
          <div
            className={clsx(
              'avatar-wrapper',
              onlineUsers?.includes(currentInterlocutor?._id) && '_online',
              onlineUsers?.includes(currentClient?._id) && '_online',
            )}
          >
            {isContractChat || isAccountantChat ? (
              <Avatar size="large" icon={<TeamOutlined />} />
            ) : isChatWithClient || isPersonalConsultantChat ? (
              <Avatar size="large" icon={<UserOutlined />} src={getClientAvatarUrl(currentClient)} />
            ) : currentChat?.isGroup ? (
              <Avatar size="large" icon={<TeamOutlined />} src={`data:image/jpeg;charset=utf-8;base64, ${groupChatAvatarBase64}`} />
            ) : (
              <UserColorAvatar user={currentInterlocutor} size="large" />
            )}
          </div>
          <div className="info">
            <div className="chat-name-block">
              <div className="top">
                <p className="name">{currentInterlocutor?.p || getChatName(currentChat, Object.values(users))}</p>
                <p className="last-message-date">{lastMessageData?.date ? dayjs(lastMessageData?.date).format('HH:mm') : null}</p>
              </div>
              {currentChat.messages ? (
                <div className="bottom">
                  <div className="last-message-wrapper">
                    {currentChat.messages?.length > 0 && !isAccountantChat && !lastMessageData.removed ? (
                      <p className={clsx('last-message-author', lastMessageData.isClientAuthor ? 'client-message' : '')}>{lastMessageData.author}:</p>
                    ) : null}
                    <p className="excerpt">
                      {currentChat.messages?.length > 0 && !lastMessageData.removed ? lastMessageData.message : 'Немає повідомлень'}
                    </p>
                  </div>

                  {totalUnreadedMessages > 0 && <p className="unreadedMessages">{totalUnreadedMessages}</p>}
                </div>
              ) : (
                <p className="fil">Філія: {FILII[currentInterlocutor?.fil || currentChat?.f]}</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatUser;
