import { useEffect, useState } from 'react';
import { Spin, Row, Pagination } from 'antd';
import { request, error } from '../../tools/';
import { DATA_PAGE_SIZE } from 'common_constants/db';
import RedButtonChatItem from './RedButtonChatItem';

import './RedButtonChat.scss';

const RedButtonChat = () => {
  const [redButtonContracts, setRedButtonContracs] = useState([]);
  const [contractsLength, setContractsLength] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchRedButtonContracts = (page) => {
    setLoading(true);
    const onSuccess = (res) => {
      setRedButtonContracs(res?.data);
      setContractsLength(res?.total);
      setLoading(false);
    };
    const onError = () => {
      error('', 'Не вдалось завантажити список договорів');
      setLoading(false);
    };
    request.post('/contracts/get', { onlyRedButton: true, page }, onSuccess, onError);
  };

  useEffect(() => {
    fetchRedButtonContracts(1);
  }, []);

  const onChangePagination = (event) => {
    fetchRedButtonContracts(event);
  };

  const sortedContracts = [...redButtonContracts].sort((a, b) => b.newMessageChatWithClient - a.newMessageChatWithClient);

  return (
    <section className="redButton_chat_page default_page">
      <h2>Термінові чати</h2>

      <div>
        <Spin spinning={loading}>
          <Row gutter={[16, 16]}>
            {sortedContracts.map((contract) => (
              <RedButtonChatItem key={contract._id} contract={contract} />
            ))}
          </Row>
          {contractsLength !== 0 && (
            <Pagination
              className="contracts_page__pagination"
              onChange={onChangePagination}
              defaultPageSize={DATA_PAGE_SIZE}
              defaultCurrent={1}
              total={contractsLength}
              style={{ marginTop: 20, textAlign: 'center' }}
            />
          )}
        </Spin>
      </div>
    </section>
  );
};

export default RedButtonChat;
