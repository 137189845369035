import { HotlineTimePicker } from 'common_components';
import { onTimeClick } from 'common_components/src/HotlineStep1/Step1/timeUtils';
import React from 'react';

export default function TimePicker({ data, startT, endT, setEndT, setStartT, lang, blockedArr, handleClickClearTime }) {
  const handleTimeClick = (e) => {
    onTimeClick(startT, endT, endT, endT, setEndT, setEndT, setStartT, e.target, data.type);
  };

  if (!!data.fil && !!data.type)
    return (
      <HotlineTimePicker
        startT={startT}
        endT={endT}
        blockedArr={blockedArr}
        onTimeClick={handleTimeClick}
        onTimeClear={handleClickClearTime}
        isForCRM={true}
        appointmentForToday={false}
        clientHotlinesPerWeek={[]}
        date={data.date}
        lang={lang}
      />
    );
  return null;
}
