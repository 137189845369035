import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, InputNumber, DatePicker, Button, Switch, Popover, Spin } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { setModal, editContract } from '../../store/commonReducer';
import { INCOME_TYPES } from 'common_constants/business';
import SearchSelect from '../../components/SearchSelect';
import UserAvatar from '../../components/UserAvatar';
import HoverableUser from '../../components/HoverableUser';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import { request, success, error } from '../../tools';

import './styles.scss';

const Incomes = () => {
  const dispatch = useDispatch();
  const { data, prev } = useSelector((state) => state.common.modal);
  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);
  const [loading, setLoading] = useState(false);
  const [formattedForm, setFormattedForm] = useState();
  const [form] = Form.useForm();

  const { contractForm, self } = data;

  useEffect(() => {
    setLoading(true);
    const incomesFormattedDate = contractForm?.incomes?.map((income) => {
      const updatedIncome = { ...income };
      if (income?.date) updatedIncome.date = dayjs(income.date);
      if (income?.date2) updatedIncome.date2 = dayjs(updatedIncome.date2);
      return updatedIncome;
    });

    setFormattedForm({ ...contractForm, incomes: incomesFormattedDate || [] });
    setLoading(false);
  }, [contractForm]);

  const onSendForm = (values) => {
    const incomes = values.incomes.map((income) => {
      if (income?.author) return income;

      income.author = userAuth._id;
      income.created = dayjs();

      if (income.date) {
        income.date = dayjs(income?.date);
      }
      if (income.date2) {
        income.date2 = dayjs(income?.date2);
      }

      return income;
    });

    const incomesFixBadItem =
      incomes?.filter((item) => {
        if (item?.type === undefined && item?.date === undefined && item?.amount === undefined && item?.date2 === undefined) {
          return false;
        }
        return true;
      }) || [];

    if (self) {
      setLoading(true);
      const data = { ...contractForm, incomes: incomesFixBadItem };

      const editContractData = {
        _id: contractForm._id,
        incomes: incomesFixBadItem,
      };

      request.post(
        '/contracts/add',
        data,
        () => {
          setLoading(false);
          dispatch(editContract(editContractData));
          dispatch(setModal());
          success('Доходи редаговано успішно!');
        },
        error,
      );
      return;
    }

    const updatedPrev = { ...prev, data: { ...prev.data, incomes: incomesFixBadItem } };

    dispatch(setModal(updatedPrev));
  };

  const onCancel = () => {
    if (prev) {
      return dispatch(setModal(prev));
    }
    dispatch(setModal());
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal className="income" open title={'Доходи'} onCancel={onCancel} footer={null}>
      <Spin spinning={loading}>
        {formattedForm && formattedForm.incomes && (
          <Form initialValues={formattedForm} onFinish={onSendForm} layout="vertical" form={form}>
            <Form.List name="incomes" className="income__form-list">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => {
                    const author = contractForm?.incomes?.[field?.key]?.author || null;
                    const isExist = field.key < contractForm?.incomes?.length && author && (contractForm.contractId || contractForm._id);

                    return (
                      <div className="income__form-item" key={field.key}>
                        <Form.Item
                          name={[field.name, 'type']}
                          label="Вид доходу"
                          rules={[{ required: true, message: 'Виберіть, будь ласка, вид доходу' }]}
                        >
                          <SearchSelect list={INCOME_TYPES} disabled={isExist} />
                        </Form.Item>
                        {isExist ? (
                          <>
                            <div className="income__form-author">
                              {author && (
                                <Popover content={<HoverableUser id={author} />} arrow placement="bottomLeft" trigger="hover">
                                  <p>{users?.[author].p}</p>
                                  <UserAvatar user={author} />
                                </Popover>
                              )}
                            </div>
                            <div className="income__form-item-outdated">
                              <label>Застаріла</label>
                              <Form.Item valuePropName="checked" name={[field.name, 'outdated']}>
                                <Switch size="small" />
                              </Form.Item>
                            </div>
                          </>
                        ) : (
                          <MinusCircleOutlined className="income__minus" onClick={() => remove(field.name)} />
                        )}
                        <Form.Item label="Дата" name={[field.name, 'date']}>
                          <DatePicker locale={locale} format="DD-MM-YYYY" disabled={isExist} />
                        </Form.Item>
                        <div className="income__form-item-group">
                          <Form.Item label="Сума" name={[field.name, 'amount']}>
                            <InputNumber onKeyDown={handleKeyPress} readOnly={isExist} step={100} min={0} />
                          </Form.Item>
                          <Form.Item label="Друга дата" name={[field.name, 'date2']}>
                            <DatePicker locale={locale} format="DD-MM-YYYY" disabled={isExist} />
                          </Form.Item>
                        </div>
                      </div>
                    );
                  })}

                  <Form.Item className="income__add-income-btn">
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Додати дохід
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="income__btns-wrapper">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Зберегти
                </Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={onCancel}>Скасувати</Button>
              </Form.Item>
            </div>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default Incomes;
