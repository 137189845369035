import React from 'react';
import { Row, Space } from 'antd';
import InputField from '../InputField/InputField';

const Footer = (props) => {
  const { loading, handleClick } = props;
  return (
    <Row className="footer">
      <Space.Compact className="inner">
        <InputField loading={loading} onSendMessage={handleClick} />
      </Space.Compact>
    </Row>
  );
};

export default Footer;
