import { notification } from 'antd';

const warn = (text, description, noteLog) => {
  const bodyNotif = {
    message: text || 'Увага!',
    description: description || 'Щось пішло не так',
    dismiss: {
      duration: 25000,
      onScreen: true,
    },
  };

  if (noteLog) {
    bodyNotif.onClose = () => {
      console.group('Notification Closed Warn!');
      Array.isArray(noteLog) || typeof noteLog === 'object' ? console.table(noteLog) : console.log(noteLog);
      console.groupEnd('Notification Closed Warn!');
    };
  }

  notification['warning'](bodyNotif);
}; // при неправильних введених даних адвокатом

export default warn;
