import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ROLES, CHAT_TYPES } from 'common_constants/business';
import { CONTRACTS_CHATS_FILTER } from 'common_constants/modals';
import { Input, InputNumber, Button } from 'antd';
import { SearchOutlined, FilterOutlined, ClearOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { request, error } from '../../tools';
import { setModal, clearContractsChatsFilter, updateChat } from '../../store/commonReducer';
import { getSearchInputPlaceholder } from './helpers';

const SearchWrapper = ({
  activeTab,
  setSearchedUsers,
  inputSearch,
  setInputSearch,
  contractNumberSearch,
  setContractNumberSearch,
  inputClientSearch,
  setInputClientSearch,
}) => {
  const dispatch = useDispatch();
  const contractsChatsFilter = useSelector((state) => state.common.contractsChatsFilter);
  const userAuth = useSelector((state) => state.common.userAuth);
  const chatsData = useSelector((state) => state.common.chatsData);

  const isInspector = ROLES[userAuth.role] <= ROLES.inspector;
  const isLawyerChat = activeTab === CHAT_TYPES.lawyerChat.key;
  const isGroupChat = activeTab === CHAT_TYPES.groupChat.key;
  const isChatWithClient = activeTab === CHAT_TYPES.chatWithClient.key;
  const isContractChat = activeTab === CHAT_TYPES.contractChat.key;
  const redButtonChat = activeTab === CHAT_TYPES.redButtonChat.key;
  const isPersonalConsultantChat = activeTab === CHAT_TYPES.personalConsultantChat.key;
  const isAccountantChat = activeTab === CHAT_TYPES.accountantChat.key;

  const isFilterActive = useMemo(() => contractsChatsFilter?.[activeTab]?.fil, [activeTab, contractsChatsFilter?.[activeTab]]);

  const onClickSearchContract = () => {
    if (!activeTab || isLawyerChat) return;

    if (!contractNumberSearch) {
      setSearchedUsers(chatsData?.[activeTab]);
      return;
    }

    const requestData = {
      f: '',
      i: contractNumberSearch,
      type: redButtonChat ? CHAT_TYPES.chatWithClient.key : activeTab,
    };

    request.post(
      isAccountantChat ? '/accountantChat/getChatsForCRM' : '/contracts/getForContractChatSearch',
      requestData,
      ({ data }) => {
        const changedData = data?.map((item) => ({
          _id: item?._id,
          type: activeTab,
          i: item?.i,
          fil: item?.f || '',
          clientInfo: item?.clientInfo,
          messages: item?.messages || [],
        }));

        setSearchedUsers(changedData);

        for (let itemData of changedData) {
          if (chatsData?.[activeTab]?.findIndex((item) => item?._id === itemData?._id) < 0) {
            dispatch(updateChat({ chatType: activeTab, data: { ...itemData, type: activeTab } }));
          }
        }
      },
      error,
    );
  };

  const onClickSearchGroups = () => {
    if (!inputSearch || inputSearch?.length < 1) return;

    const requestParams = { chatNameSearch: inputSearch };

    request.post(
      '/chatPrivate/searchGroupChats',
      requestParams,
      ({ data }) => {
        const changedData = data?.map((item) => ({ ...item, type: activeTab }));

        setSearchedUsers(changedData);
        for (let item of changedData) {
          dispatch(updateChat({ chatType: activeTab, data: item }));
        }
      },
      error,
    );
  };

  const onClickSearchPersonalConsultantChat = async () => {
    await request.post(
      '/chatPrivate/searchPersonalConsultantChats',
      { clientPhone: inputClientSearch },
      ({ data }) => {
        setSearchedUsers(data?._id ? [{ ...data, type: CHAT_TYPES.personalConsultantChat.key }] : []);
      },
      error,
    );
  };

  const onClickFilterButton = () => {
    dispatch(setModal({ name: CONTRACTS_CHATS_FILTER, data: { type: activeTab } }));
  };

  const onClickClearFilter = () => {
    dispatch(clearContractsChatsFilter({ type: activeTab }));
  };

  return (
    <div className="search">
      {isLawyerChat ? (
        <Input
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          placeholder={getSearchInputPlaceholder(activeTab)}
          size="large"
          className="input"
        />
      ) : isGroupChat ? (
        <>
          <Input
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
            placeholder={getSearchInputPlaceholder(activeTab)}
            size="large"
            className="input _group"
          />

          <Button onClick={onClickSearchGroups} icon={<SearchOutlined />} />
        </>
      ) : isChatWithClient || isContractChat || isAccountantChat || isPersonalConsultantChat ? (
        <>
          <div className="input-number-wrapper">
            {isPersonalConsultantChat ? (
              <>
                <Input
                  placeholder={getSearchInputPlaceholder(activeTab)}
                  onChange={(e) => setInputClientSearch(e.target.value)}
                  value={inputClientSearch}
                  min={0}
                  className="input"
                />
                <Button type="text" size="small" onClick={onClickSearchPersonalConsultantChat} icon={<SearchOutlined />} block />
              </>
            ) : (
              <>
                <InputNumber
                  placeholder={getSearchInputPlaceholder(activeTab)}
                  onChange={(val) => setContractNumberSearch(val)}
                  value={contractNumberSearch}
                  min={0}
                  className="input _number"
                />
                <Button type="text" size="small" onClick={onClickSearchContract} icon={<SearchOutlined />} block />
              </>
            )}
          </div>
          {isInspector ? (
            <div className="filter-btns-group">
              <Button className={clsx('btn-filter', isFilterActive ? 'active' : '')} icon={<FilterOutlined />} onClick={onClickFilterButton} />
              {isFilterActive ? <Button icon={<ClearOutlined />} onClick={onClickClearFilter} /> : null}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default SearchWrapper;
