const MOB_RESOLUTION = 992;

const INFORMATION_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

module.exports = {
  MOB_RESOLUTION,
  INFORMATION_TYPES,
};
