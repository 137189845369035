import React, { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Modal, Input, Tag, Checkbox } from 'antd';

const LABELS_DATA = [
  {
    text: 'Термінова',
    color: '#DB0B05',
    priority: 10,
    icon: ' &#x1F525;',
  },
  {
    text: 'Важлива',
    color: '#DB4312',
    priority: 8,
    icon: '&#x2757;',
  },
  {
    text: 'Не термінова',
    color: '#5AA3DB',
    priority: 3,
    icon: '&#128342;',
  },
  {
    text: 'Не важлива',
    color: '#33DB24',
    priority: 0,
    icon: '	&#128515;',
  },
];

const Tags = ({ setOpenLabels, task, setCurrentTask }) => {
  const [inputSearch, setInputSearch] = useState('');
  const [filteredLabels, setFilteredLabels] = useState(LABELS_DATA);

  useEffect(() => {
    let labels = LABELS_DATA.filter((label) => label.text.toLowerCase().includes(inputSearch.toLowerCase()));
    setFilteredLabels(labels);
  }, [inputSearch]);

  const onClickTagCheckbox = (e, label) => {
    const { checked } = e.target;
    let updatedLabels = [];
    let updatedPriority = 0;

    if (checked) {
      updatedLabels = [...task.labels, label];
      updatedPriority = label.priority > (task.priority || 0) ? label.priority : task.priority;
    } else {
      updatedLabels = task.labels.filter((item) => item.text !== label.text);
      updatedPriority = label.priority < (task.priority || 0) ? task.priority : setPrevTaskPriority(updatedLabels);
    }

    let data = {
      _id: task._id,
      labels: updatedLabels,
      priority: updatedPriority || 0,
    };

    if (updatedPriority === 10 && task.priority !== 10) {
      let date = new Date();
      data.deadline = new Date(date.getTime() + 2 * 24 * 60 * 60000);
    }

    setCurrentTask((prev) => ({ ...prev, ...data }));
  };

  const setPrevTaskPriority = (labels) => {
    let max = 0;
    for (let i = 0; i < labels.length; i++) {
      if (labels[i].priority > max) {
        max = labels[i].priority;
      }
    }

    return max;
  };

  const checkIfIncluded = (label) => task?.labels?.some(({ text }) => text === label.text);

  const onClickCancel = () => {
    setOpenLabels(false);
  };

  const onChangeInputSearch = (e) => {
    setInputSearch(e.target.value);
  };

  return (
    <Modal className="labels" title="Мітки" open mask={false} width={300} footer={null} onCancel={onClickCancel}>
      <Input value={inputSearch} onChange={onChangeInputSearch} suffix={<SearchOutlined />} className="input-search" placeholder="Шукати мітку..." />
      <div className="items-wrapper">
        {filteredLabels.map((label) => (
          <div className="item" key={label.text}>
            <Checkbox checked={checkIfIncluded(label)} onChange={(e) => onClickTagCheckbox(e, label)} />
            <Tag className="item-text" color={label.color}>
              <span dangerouslySetInnerHTML={{ __html: label.icon }} />
              {label.text}
            </Tag>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default Tags;
