import React from 'react';
import dayjs from 'dayjs';

const EditMessageHistory = ({ messageHistory }) => {
  return (
    <ul className="edit-message-history">
      {messageHistory
        ?.filter((item) => item?.message)
        ?.map((item) => (
          <li>
            {item.message} - {dayjs(item.date).format('DD.MM.YYYY HH:mm')}
          </li>
        ))}
    </ul>
  );
};

export default EditMessageHistory;
