import { List } from 'antd';
import RatingItem from '../RatingItem';

import './RatingList.scss';

const RatingList = ({ data }) => {
  let numberOfParticipants;

  switch (true) {
    case data.length < 20 && data.length % 2 === 0:
      numberOfParticipants = data.length / 2;
      break;
    case data.length < 20 && data.length % 2 !== 0:
      numberOfParticipants = (data.length - 1) / 2;
      break;

    default:
      numberOfParticipants = 10;
      break;
  }

  const topPlaces = [];
  const lastPlaces = [];

  for (let index = 0; index < numberOfParticipants; index++) {
    topPlaces.push(index + 1);
    lastPlaces.push(data.length - index);
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => <RatingItem ratingItem={item} topPlaces={topPlaces} lastPlaces={lastPlaces} />}
    />
  );
};
export default RatingList;
