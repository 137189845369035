import { Modal, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../../store/commonReducer';
import { request, success, error } from '../../tools';
import { useState } from 'react';
import { updateClientFeedbackList } from '../../store/commonReducer';

const PublishFeedback = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { data } = useSelector((state) => state.common.modal);

  const handlePublished = () => {
    if (!data?.answer || !data?.answerAuthor) {
      error('Заповніть відгук та  автора', ' ');
      return;
    }
    setLoading(true);
    const body = { id: data._id, published: data?.published, answer: data?.answer, answerAuthor: data?.answerAuthor };
    request.post(
      '/clientFeedback/published',
      body,
      (req, res) => {
        if (req.status) {
          setLoading(false);
          success();
          dispatch(updateClientFeedbackList(req.data));
          dispatch(setModal());
        }
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  return (
    <Spin loading={loading}>
      <Modal open onCancel={() => dispatch(setModal())} onOk={handlePublished}>
        {data?.published ? 'Відмінити публікацію відгуку на сайті?' : "Після публікаці відгук з'явиться на сайті"}
      </Modal>
    </Spin>
  );
};

export default PublishFeedback;
