import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import TaskItem from './TaskItem';

const TaskList = ({ title, listName }) => {
  const tasks = useSelector((state) => state.common.tasks);
  const [currentTasks, setCurrentTasks] = useState([]);

  useEffect(() => {
    if (tasks[listName]) {
      setCurrentTasks(tasks[listName]);
    }
  }, [tasks[listName]]);

  return (
    <div className="task-list">
      <p className="task-list-title">{title}</p>
      <div className="wrapper">
        {currentTasks.length > 0 ? currentTasks.map((task) => <TaskItem key={task?._id} task={task} listName={listName} />) : null}
      </div>
    </div>
  );
};

export default TaskList;
