import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Calendar, Col, List, Pagination, Radio, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ExcelBtn } from 'common_components';
import { ROLES } from 'common_constants/business';
import { ADD_ASSIGNMENT, ASSIGNMENT_MARK_AS_INCORRECT, ASSIGNMENT_REPORT, EXPORT_ASSIGNMENTS } from 'common_constants/modals';
import { setAssignmentsList, setCourtsList, setJudgesList, setLawyersList, setModal, setPlacesList } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { getAssignments, getCourts, getJudges, getLawyers, getPlaces } from './AssignmentsHelpers';
import { AssignmentItem, AssignmentsSearch } from './AssignmentsPartials';

import './Assignment.scss';

const { Title } = Typography;

const Assignment = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const assignmentsList = useSelector((state) => state.common.assignmentsList) ?? [];
  const deputyManager = ROLES[userAuth.role] <= ROLES.deputyManager;
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;

  const admin = ROLES[userAuth.role] === ROLES.admin;

  const rabfil = ROLES[userAuth.role] === ROLES.rabfil;

  const blockManagerOnly = ROLES[userAuth.role] === ROLES.blockManager;
  const isinspectorAndHigher = ROLES[userAuth.role] <= ROLES.inspector;
  const managerOnly = ROLES[userAuth.role] === ROLES.manager;
  const deputyManagerOnly = ROLES[userAuth.role] === ROLES.deputyManager;

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filFilter, setFilFilter] = useState(isinspectorAndHigher ? '' : userAuth.fil);
  const [blockFilter, setBlockFilter] = useState(isinspectorAndHigher ? '' : userAuth.block);

  const [serviceTypeFilter, setServiceTypeFilter] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [onlyArchived, setOnlyArchived] = useState(false);
  const [courtFilter, setCourtFilter] = useState('');
  const [contractNumberFilter, setContractNumberFilter] = useState('');

  const tabs = [{ label: 'Мої', value: 'myOnly' }];

  if (managerOnly || deputyManagerOnly) tabs.push({ label: 'Філія', value: 'filOnly' });

  if (blockManagerOnly) tabs.push({ label: 'Блок', value: 'blockOnly' });

  if (inspector) tabs.push({ label: 'Всі', value: 'all' });

  const [tab, setTab] = useState(tabs[0].value);
  const onChangeOwnFilter = ({ target: { value } }) => setTab(value);

  const handleAddAssignment = (data) => {
    if (!rabfil) {
      setTab(tabs[0].value);
    }
    dispatch(setModal({ name: ADD_ASSIGNMENT, data }));
  };
  const handleAddReport = (data) => dispatch(setModal({ name: ASSIGNMENT_REPORT, data }));
  const handleMarkAsIncorrect = (data) => dispatch(setModal({ name: ASSIGNMENT_MARK_AS_INCORRECT, data }));

  // const onSelect = (value) => setTargetDate(value);
  const onChangeFil = useCallback((value) => setFilFilter(value), []);
  const onChangeBlock = useCallback((value) => setBlockFilter(value), []);
  const onChangeCourt = useCallback((value) => setCourtFilter(value), []);
  const onChangeDateRange = useCallback((value) => setDateRange(value), []);
  const onChangeArchived = useCallback((value) => setOnlyArchived(value), []);
  const handleTableChange = (pageNumber) => setCurrentPage(pageNumber);
  const handleSetLawyersList = (lawyersList) => dispatch(setLawyersList(lawyersList));
  const handleSetJudgesList = (judgesList) => dispatch(setJudgesList(judgesList));
  const handleSetPlacesList = (placesList) => dispatch(setPlacesList(placesList));
  const handleSetCourtsList = (courtsList) => dispatch(setCourtsList(courtsList));
  const handleSetAssignmentsList = (assignmentsList) => dispatch(setAssignmentsList(assignmentsList));
  const handleSetMainLoader = (state) => dispatch(setMainLoader(state));

  const handleSearchAssignments = useCallback(() => {
    getAssignments(
      handleSetAssignmentsList,
      handleSetMainLoader,
      setTotal,
      currentPage,
      dateRange,
      onlyArchived,
      serviceTypeFilter,
      filFilter,
      blockFilter,
      contractNumberFilter,
      courtFilter,
      tab,
    );
  }, [currentPage, dateRange, onlyArchived, serviceTypeFilter, filFilter, blockFilter, contractNumberFilter, courtFilter, tab]);

  const handleClearFilters = useCallback(() => {
    setServiceTypeFilter('');
    setCourtFilter('');
    setContractNumberFilter('');

    if (inspector) {
      setFilFilter('');
      setBlockFilter('');
    }
  }, []);

  useEffect(() => {
    getLawyers(handleSetLawyersList, handleSetMainLoader)
      .then(() => getCourts(handleSetCourtsList, handleSetMainLoader))
      .then(() => getJudges(handleSetJudgesList, handleSetMainLoader))
      .then(() => getPlaces(handleSetPlacesList, handleSetMainLoader))
      .catch((error) => {
        handleSetMainLoader(false);
        console.error(error);
      });
  }, []);

  useEffect(() => {
    handleSearchAssignments();
  }, [currentPage, tab]);

  return (
    <Col style={{ padding: 10 }}>
      <Title style={{ textAlign: 'center' }} level={3}>
        Виходи
      </Title>

      {/* <Calendar value={targetDate} onSelect={onSelect} fullscreen={false} /> */}

      <Row justify={'center'} style={{ margin: '10px 0' }}>
        <Button className="assignment-add__button" type="primary" onClick={() => handleAddAssignment()}>
          <PlusCircleOutlined /> Додати вихід
        </Button>
      </Row>

      <br />

      <AssignmentsSearch
        dateRange={dateRange}
        setDateRange={setDateRange}
        onChangeDateRange={onChangeDateRange}
        onlyArchived={onlyArchived}
        setOnlyArchived={setOnlyArchived}
        onChangeArchived={onChangeArchived}
        filFilter={filFilter}
        blockFilter={blockFilter}
        onChangeFil={onChangeFil}
        onChangeBlock={onChangeBlock}
        courtFilter={courtFilter}
        onChangeCourt={onChangeCourt}
        serviceTypeFilter={serviceTypeFilter}
        setServiceTypeFilter={setServiceTypeFilter}
        contractNumberFilter={contractNumberFilter}
        setContractNumberFilter={setContractNumberFilter}
        handleClearFilters={handleClearFilters}
        handleSearchAssignments={handleSearchAssignments}
        onChangePage={setCurrentPage}
      />

      <Row justify={'space-between'}>
        <Radio.Group onChange={onChangeOwnFilter} value={tab} optionType="button" buttonStyle="solid" options={tabs} />
        {admin && (
          <ExcelBtn style={{ width: 'auto' }} onClick={() => dispatch(setModal({ name: EXPORT_ASSIGNMENTS }))}>
            Експорт
          </ExcelBtn>
        )}
      </Row>

      <List
        itemLayout="horizontal"
        dataSource={assignmentsList}
        renderItem={(item) => (
          <AssignmentItem
            assignment={item}
            handleAddReport={handleAddReport}
            handleAddAssignment={handleAddAssignment}
            handleMarkAsIncorrect={handleMarkAsIncorrect}
            userId={userAuth._id}
            admin={admin}
            deputyManager={deputyManager}
          />
        )}
      />
      {total !== 0 ? <Pagination style={{ textAlign: 'center' }} current={currentPage} total={total} onChange={handleTableChange} /> : null}
    </Col>
  );
};

export default Assignment;
