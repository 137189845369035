import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Input, Spin } from 'antd';

import { setModal } from '../../store/commonReducer';
import { request, success, warn, error } from '../../tools';
import { ROLES } from 'common_constants/business';
import { CLUB_CARDS_SERVICES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import { getCurrentMounth } from 'common_components/src/dateUtils';

import './ClubCardDecrement.scss';

const ClubCardDecrement = () => {
  const dispatch = useDispatch();
  const { data, prev } = useSelector((state) => state.common.modal);
  const [clubCard, setClubCard] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const userAuth = useSelector((state) => state.common.userAuth);
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;

  const isNewCard = clubCard?.services?.[1][0] >= 0;
  const correntMonth = getCurrentMounth(clubCard?.createdAt, clubCard?.expiredAt);

  useEffect(() => {
    request.post(
      '/clients/getCurrentClubCard',
      { _id: data.C },
      (req) => {
        setClubCard(req.data);
      },
      error,
    );
  }, []);

  const updateService = (service, increment) => {
    setClubCard((prevState) => {
      const updateValue = (currentValue) => currentValue + (increment ? 1 : -1);

      if (correntMonth) {
        return {
          ...prevState,
          services: {
            ...prevState.services,
            [correntMonth]: {
              ...prevState.services[correntMonth],
              [service]: updateValue(prevState.services[correntMonth][service]),
            },
          },
        };
      }

      return {
        ...prevState,
        services: {
          ...prevState.services,
          [service]: updateValue(prevState.services[service]),
        },
      };
    });
  };

  const onClickDecrementBtn = (service) => {
    const currentService = correntMonth ? clubCard.services[correntMonth][service] : clubCard.services[service];
    if (currentService < 1 || currentService === 'Infinity') return;

    setIsChanged(true);
    updateService(service, false);
  };
  const onClickIncrementBtn = (service) => {
    const maxServiceValue = CLUB_CARDS_SERVICES[service][clubCard?.cardType];
    const currentService = correntMonth ? clubCard.services[correntMonth][service] : clubCard.services[service];
    if (currentService === maxServiceValue || currentService === 'Infinity') return;

    setIsChanged(true);
    updateService(service, true);
  };

  const onSubmit = async (currentClubCard) => {
    if (!isChanged) {
      warn('Зміни не внесено', 'скоректуйте баланс клубної карти');
      return;
    }
    setLoading(true);

    await request.post(
      '/clients/updateClubCard',
      { _id: data.C, updatedClubCard: currentClubCard },
      (req) => {
        success('Баланс клубної карти оновлено');
      },
      error,
    );
    setLoading(false);
    dispatch(setModal());
  };

  const servicesItems = Object.keys(CLUB_CARDS_SERVICES).map((item) => {
    const totalServices = CLUB_CARDS_SERVICES[item][clubCard?.cardType];
    const serviceReminder = correntMonth && isNewCard ? clubCard?.services[correntMonth][item] : clubCard?.services[item];
    const infinityServices = serviceReminder === 'Infinity';
    let percent = 0;
    if (infinityServices) {
      percent = 100;
    }
    if (totalServices === 0) {
      percent = 0;
    }
    if (totalServices !== 0 && !infinityServices) {
      percent = (serviceReminder / totalServices) * 100;
    }
    return (
      <div key={item} className="row">
        <div className="club-card-wrapper">
          <div className="base-info">
            <div className="info">
              <div className="col">{translation.clubCardServices[item].ua}</div>
              <div className="col">
                {correntMonth && clubCard?.services[correntMonth][item] === 'Infinity' ? (
                  <span>&infin;</span>
                ) : (
                  <div className="counter">
                    <span>
                      {correntMonth && isNewCard ? (
                        clubCard?.services[correntMonth][item]
                      ) : clubCard?.services[item] === 'Infinity' ? (
                        <>&infin;</>
                      ) : (
                        clubCard?.services[item]
                      )}
                    </span>
                    <span>/</span>
                    <span>{totalServices === 'Infinity' ? <>&infin;</> : totalServices}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="bar">
              <span style={{ width: `${percent}%` }}></span>
            </div>
          </div>

          <div className="actions-wrapper">
            <span className="club-card-btn decrement" onClick={() => onClickDecrementBtn(item)} />
            {inspector && <span className="club-card-btn increment" onClick={() => onClickIncrementBtn(item)} />}
          </div>
        </div>
      </div>
    );
  });

  return (
    <Modal open className="add-transaction" title="Списання послуги з клубної карти" onCancel={() => dispatch(setModal(prev))} footer={null}>
      <Spin spinning={loading}>
        <div className="body">
          <div className="list">
            <div className="title">
              <h3>{translation.servicesBalance.ua}</h3>
            </div>
            {servicesItems}
          </div>
        </div>

        <Button type="primary" className="submit" onClick={() => onSubmit(clubCard)}>
          Зберегти зміни
        </Button>
      </Spin>
    </Modal>
  );
};

export default ClubCardDecrement;
