import React from 'react';
import SearchSelect from './SearchSelect';
import { EXPENSE_CATEGORIES } from 'common_constants/business';

const SearchSelectCategory = ({ onChange, value, disabled, className, style }) => {
  return (
    <SearchSelect
      className={className}
      style={{ minWidth: 200, ...style }}
      disabled={disabled}
      placeholder="Всі категорії"
      list={Object.values(EXPENSE_CATEGORIES).map((category) => ({ value: category.value + '', label: category.label }))}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectCategory;
