import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Row, Col, Popover, Spin } from 'antd';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';
import { BILL_STATUSES, PAY_TYPES, PAY_PURPOSES } from 'common_constants/business';

import { request } from '../../tools';
import { CLIENT_INFO } from 'common_constants/modals';
import { setModal } from '../../store/commonReducer';

import HoverableUser from '../../components/HoverableUser';
import SugarCard from './SugarCard';

import './Harvest.scss';

const HarvestInfo = ({ data }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const FILII = useSelector((state) => state.common.FILII);
  const users = useSelector((state) => state.common.users);
  const [client, setClient] = useState(null);

  const openClient = () => {
    dispatch(setModal({ name: CLIENT_INFO, data: data.client, fromContracts: true, interactionsBlocked: true }));
  };

  const getClientInfo = (clientId) => {
    setLoading(true);

    const body = { _id: clientId };

    request.post('/clients/find', body, (clientInfo) => {
      setClient(clientInfo.data);
    });

    setLoading(false);
  };

  useEffect(() => {
    getClientInfo(data?.author);
  }, [data]);

  const authorName = users?.[data?.author]?.name ? users?.[data?.author]?.name : '-';

  if (!data) return 'Відсутня інформація';

  return (
    <Card className="harvestInfo">
      <Row gutter={[0, 24]}>
        <Col sm={{ span: 13 }} xs={{ span: 24 }}>
          <span>
            <b>Філія:</b> {FILII[data.contractFil || data.fil]}
          </span>
          <span>
            <b>Квитанція:</b> {BILL_STATUSES[data.bill]?.label} {data.billOther}
          </span>
          <span>
            <b>Сума:</b> {data.amount}
          </span>
          <span>
            <b>Коментар:</b> {data.comment === '' ? '-' : data.comment ?? '-'}
          </span>

          <span>
            <b>Куди платив:</b> {PAY_TYPES[data.payBy]?.label} {data.payByOther}
          </span>
          <span>
            <b>Призначення:</b> {PAY_PURPOSES[data.payfor]?.label} {data.payforOther}
          </span>

          {data?.assignment?.assignmentIndex ? (
            <span>
              <b>Вихід №:</b> {data?.assignment?.assignmentIndex}
            </span>
          ) : null}

          <span>
            <b>Дата оплати:</b> {dayjs(data.payday).format('DD.MM.YYYY')}
          </span>
          <span>
            <b>Номер договору:</b> {data.contractIndex ?? '-'}
          </span>
          <span>
            <b>ПІБ:</b>{' '}
            <>
              {data.name ?? '-'} &nbsp;
              {data.client && <Link onClick={openClient}>подробиці</Link>}
            </>
            {data.unknownName ? 'Невідомо' : ''}
          </span>
          <hr />
          <span>
            <b>Дата створення:</b> {dayjs(data.createdAt).format('DD.MM.YYYY HH:mm:ss')}
          </span>
          <span>
            <b>Автор:</b>{' '}
            <Popover content={<HoverableUser id={data?.author} />}>
              <span className="author-link">{authorName}</span>
            </Popover>
          </span>
        </Col>
        <Col sm={{ span: 11 }} xs={{ span: 24 }} style={{ gap: 12 }}>
          {data?.sugars?.map((elem) => (
            <SugarCard sugar={elem} />
          ))}
        </Col>
      </Row>
    </Card>
  );
};

export default HarvestInfo;
