import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, Button } from 'antd';
import { addTweet, setModal, updateTweetItem } from '../../store/commonReducer';
import { error, request, success } from '../../tools';

const AddTweet = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const { editItem } = useSelector((state) => state.common.modal);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editItem) {
      form.setFieldsValue({
        message: editItem.message,
      });
    }
  }, [editItem, form]);

  const handleFormSubmit = async () => {
    setLoading(true);

    const data = {
      message: form.getFieldValue('message'),
    };

    const requestURL = editItem ? `/twitter/update/${editItem._id}` : '/twitter/addTweet';

    await request.post(
      requestURL,
      data,
      ({ tweet }) => {
        const successMessage = editItem ? 'Публікація була успішно відредагована.' : 'Публікація була успішно додана.';
        success('', successMessage);
        if (editItem) {
          dispatch(updateTweetItem(tweet));
        } else {
          dispatch(addTweet(tweet));
        }
        dispatch(setModal());
        setLoading(false);
      },
      () => {
        const errorMessage = editItem ? 'Помилка при редагуванні.' : 'Помилка при додаванні.';
        error('', errorMessage);
        setLoading(false);
      },
    );
  };

  return (
    <Modal title={editItem ? 'Редагувати твіт' : 'Додати новий твіт'} open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleFormSubmit} initialValues={{ userAuth }}>
          <Form.Item
            name="message"
            rules={[
              {
                required: true,
                message: 'Будь ласка, напишіть повідомлення!',
              },
              { max: 200, message: 'Не більше 200 символів!' },
            ]}
          >
            <Input.TextArea maxLength={200} style={{ width: '90%', minHeight: '100px' }} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit">
              Опублікувати
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddTweet;
