import React from 'react';
import { useState, useEffect } from 'react';
import { Image } from 'antd';

import ChatSpinner from '../ChatSpinner/ChatSpinner';
import Column from '../../Column/Column';

import './ChatImage.scss';

const ChatImage = ({ item }) => {
  const token = window.localStorage.getItem('accessToken');
  const [imageLoading, setImageLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const timerId = setTimeout(() => {
      const fileId = item.fileId;

      fetch(process.env.REACT_APP_API + '/chat/getChatDriveImage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ fileId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
          setImageLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching image from server:', error);
        });
    }, 2000);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <Column style={{ height: '96%' }}>
      {imageLoading ? (
        <ChatSpinner />
      ) : (
        <div className="chatImage-wrapper">
          <Image className="chatImage-ant" src={imageUrl} alt="test_image" />
        </div>
      )}
    </Column>
  );
};

export default ChatImage;
