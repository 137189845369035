import React from 'react';
import { Row } from 'antd';

import './ReceivingData.scss';

const ReceivingData = () => (
  <Row className="receivingData" justify={'space-around'}>
    Отримання даних
  </Row>
);
export default ReceivingData;
