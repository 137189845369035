import React, { useState } from 'react';
import { Button, Modal, Image, Spin } from 'antd';

import './ExpenseImageButton.scss';

const ExpenseImageButton = ({ fileId }) => {
  const [showImage, setShowImage] = useState(false);
  const token = window.localStorage.getItem('accessToken');
  const [imageLoading, setImageLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');

  const handleShowImage = () => {
    setShowImage(true);
    fetch(process.env.REACT_APP_API + '/expense/getExpenseDriveImage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ fileId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        setImageLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching image from server:', error);
      });
  };

  const handleCloseImage = () => {
    setShowImage(false);
  };

  return (
    <div className="expense-button">
      <a onClick={handleShowImage}>Показати квитанцію</a>
      <Modal className="expense-modal" open={showImage} onCancel={handleCloseImage} footer={null}>
        {imageLoading ? (
          <Spin />
        ) : (
          <div>
            <Image className="expense-modal-image" src={imageUrl} alt="Зображення квитанції" />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ExpenseImageButton;
