import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { DatePicker } from 'antd';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import { request, error } from '../../tools';
import { barChartOption, horizontalChartOption, bigPieChartOption } from './chartsOptions';

import './StatClientPages.scss';

const yearNow = dayjs();

const StatClientPages = () => {
  const [webPagesStatsData, setWebPagesStatsData] = useState(null);
  const [appPagesStatsData, setAppPagesStatsData] = useState(null);
  const [monthPagesStatsData, setMonthPagesStatsData] = useState(null);
  const [currentYear, setCurrentYear] = useState(yearNow);
  const [pagesStatsByYearData, setPagesStatsByYearData] = useState({
    pagesStatsByYear: [],
    countOfPageVisiting: 0,
    countOfUniqueClients: 0,
    countOfUniqueWebClients: 0,
    countOfUniqueAppClients: 0,
  });
  const [isPagesStatsByYearLoading, setIsPagesStatsByYearLoading] = useState(false);

  const clientsRoutes = Object.values(CLIENT_ROUTES);

  function translatePageName(name) {
    return clientsRoutes?.find((item) => item.pathname === name)?.name ?? '';
  }

  const getClientPagesStatsData = () => {
    request.post(
      '/statistic/clientPageStats',
      {},
      ({ data }) => {
        setMonthPagesStatsData(
          data[0].totalMonthStatsCountData?.sort((a, b) => dayjs().set('month', a._id.month) - dayjs().set('month', b._id.month)),
        );
        setWebPagesStatsData(data[0].totalWebPagesStats?.sort((a, b) => a.count - b.count));
        setAppPagesStatsData(data[0].totalAppPagesStats?.sort((a, b) => a.count - b.count));
      },
      error,
    );
  };

  const getClientPagesStatsDataByYear = async () => {
    setIsPagesStatsByYearLoading(true);

    await request.post(
      '/statistic/clientPagesStatsByYear',
      { year: dayjs(currentYear).format('YYYY') },
      ({ data }) => {
        setPagesStatsByYearData({
          pagesStatsByYear: data[0].pagesStatsByYear?.sort((a, b) => b.count - a.count),
          countOfPageVisiting: data[0]?.countOfPageVisiting?.[0]?.count ?? 0,
          countOfUniqueClients: data[0]?.countOfUniqueClients?.[0]?.count ?? 0,
          countOfUniqueWebClients: data[0]?.countOfUniqueWebClients?.[0]?.count ?? 0,
          countOfUniqueAppClients: data[0]?.countOfUniqueAppClients?.[0]?.count ?? 0,
        });
      },
      error,
    );

    setIsPagesStatsByYearLoading(false);
  };

  useEffect(() => {
    getClientPagesStatsData();
  }, []);

  useEffect(() => {
    getClientPagesStatsDataByYear();
  }, [currentYear]);

  const pageStatsByYearOption = bigPieChartOption(
    `Популярність сторінок за ${dayjs(currentYear).format('YYYY')} рік`,
    pagesStatsByYearData?.pagesStatsByYear?.map((item) => ({ value: item.count, name: translatePageName(item._id) })),
  );

  const webPagesStatsOption = horizontalChartOption(
    'Веб-перегляд',
    webPagesStatsData?.map((item) => item.count),
    webPagesStatsData?.map((item) => translatePageName(item._id.pageName)),
  );

  const appPagesStatsOption = horizontalChartOption(
    'Перегляд в додатку',
    appPagesStatsData?.map((item) => item.count),
    appPagesStatsData?.map((item) => translatePageName(item._id.pageName)),
  );

  const monthPagesStatsOption = barChartOption(
    '',
    monthPagesStatsData?.map((item) => item.count),
    monthPagesStatsData?.map((item) => item._id?.month + '/' + item._id?.year),
  );

  return (
    <section className="default_page pages-stats">
      <div className="">
        <h2>Статистика популярності сторінок додатка "єАдвокат"</h2>
      </div>
      <div className="">
        <div className="pages-year-stats">
          <div className="pages-year-stats-content">
            <div className="pages-year-stats-details">
              <DatePicker value={currentYear} onChange={setCurrentYear} locale={locale} placeholder="Оберіть рік" picker="year" />
              <p>
                Кіл. відвідувань: <b>{pagesStatsByYearData.countOfPageVisiting}</b>
              </p>
              <p>
                Кіл. унікальних відвідувачів: <b>{pagesStatsByYearData.countOfUniqueClients}</b>
              </p>
              <p>
                Кіл. унікальних web відвідувачів: <b>{pagesStatsByYearData.countOfUniqueWebClients}</b>
              </p>
              <p>
                Кіл. унікальних app відвідувачів: <b>{pagesStatsByYearData.countOfUniqueAppClients}</b>
              </p>
            </div>
            <ReactECharts showLoading={isPagesStatsByYearLoading} lazyUpdate={true} option={pageStatsByYearOption} />
          </div>
        </div>
        <ReactECharts lazyUpdate={true} option={webPagesStatsOption} />
        <ReactECharts lazyUpdate={true} option={appPagesStatsOption} />
        <ReactECharts lazyUpdate={true} option={monthPagesStatsOption} />
      </div>
    </section>
  );
};

export default StatClientPages;
