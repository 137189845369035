import { Alert } from 'antd';
import React from 'react';

export default function RatingAlert({ tip, currentItemRating }) {
  if (currentItemRating !== 'Платна' || !tip) return null;
  return (
    <Alert
      message="Платна"
      description={
        <>
          <b>Скажіть, будь ласка, в якій області Ви знаходитесь?</b> <br />
          Після відповіді перечислюємоо усі наші офіси у відповідній області. <br /> <b>У Вашій області ми маємо офіси...</b> (називаємо усі офіси в
          області) <br /> <b>Коли Вам буде зручно підʼїхати до нас на офіс?</b>
        </>
      }
      type="info"
      showIcon
    />
  );
}
