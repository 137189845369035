import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Spin, Typography, Empty, Divider, Upload, Input, Form, Card } from 'antd';
import { DownloadOutlined, FileAddOutlined, PlusOutlined } from '@ant-design/icons';
import { Box, Row as MyRow, tools, getFormattedDateWithRelativeDays } from 'common_components';
import { TYPES_OF_MAIL, CHAT_TYPES } from 'common_constants/business';
import dayjs from 'dayjs';

import PreviewImage from '../CorrespondenceModalForTheContract/Core/PreviewImage';
import AvatarFile from './Core/AvatarFile';
import { request, error, success, warn, requestFile, RequestPromise } from '../../tools';
import { setModal } from '../../store/commonReducer';

import 'dayjs/locale/uk';

const ContractListFiles = () => {
  dayjs.locale('uk');
  const dispatch = useDispatch();
  const formRef = useRef();

  const [{ data }, userAuth] = [useSelector((state) => state.common.modal), useSelector((state) => state.common.userAuth)];

  const [{ Item }, { Text }] = [Form, Typography];

  const onCancel = () => dispatch(setModal());

  const core_megaState = {
      loading: false, //* Завантаження списку
      loadingFile: false, //* Завантаження файлу
      correspondence: [], //* Список з кореспонденції
      massegeList: [], //* Список з чату
      auditAddFile: false, //* мітка на перевірку додавання
      fileList: [], //* Файл
    },
    [megaState, setMegaState] = useState(core_megaState);

  const download_file_by_fetch = async (itemDocs, item) => {
    const dynamicItem = itemDocs ?? item;

    const validation = (_L, _n, _f) => {
      if (!_L) {
        warn('', 'Для завантаження документу, потрібно вказати дійсний файл.', { L: !_L });
        return;
      }

      if (!_n) {
        warn('', 'Для завантаження документу, потрібно вказати назву файлу.', { n: !_n });
        return;
      }

      if (!_f) {
        warn('', 'Для завантаження документу, потрібно вказати формат файлу.', { n: !_f });
        return;
      }

      return true;
    };

    const valid_item_Arr = [dynamicItem.L, dynamicItem.nd, dynamicItem.fd];

    if (!validation(...valid_item_Arr)) return;

    const dataTransfer = {
      fileId: dynamicItem.L,
      fileName: dynamicItem.nd + '.' + dynamicItem.fd, //* для обробки даних в requestFile
    };

    requestFile(
      '/correspondence/getCorrespondenceDriveFile',
      dataTransfer,
      () => success('', 'Документ успішно завантажено'),
      (err) => error('', 'Помилка при отриманні файлу', err),
    );
  };

  const downloadFiles = (arrDocs, item) => {
    if (!arrDocs?.length) return;

    for (let i = 0; i < arrDocs?.length; i++) {
      download_file_by_fetch(arrDocs[i], item);
    }
  };

  const onGetFile = (fileGet = {}) => {
    if (!fileGet.fileId) return error('Відсутній ID файлу');

    const dataTransfer = {
      fileId: fileGet.fileId,
      fileName: fileGet.fileName, //* для обробки даних в requestFile
      chatId: data?._id,
      type: CHAT_TYPES.chatWithClient.key,
    };

    requestFile(
      '/chatPrivate/chatGetFile',
      dataTransfer,

      () => success('', 'Документ успішно завантажено'),
      (err) => error('', 'Помилка при отриманні файлу', err),
    );
  };

  const typePost = (value, value2) => {
    const type = TYPES_OF_MAIL.find((item) => item.value === Number(value));

    const dynamic =
      {
        1: [type.on, TYPES_OF_MAIL[TYPES_OF_MAIL.length - 1].on],
        2: [type.from, TYPES_OF_MAIL[TYPES_OF_MAIL.length - 1].from],
      }[value2] || '';

    return type ? dynamic?.[0] ?? '' : dynamic?.[1] ?? '';
  };

  const [styleCard, styleTitle, styleButton, styleBox, styleFile] = [
      {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        marginTop: -12,
        marginBottom: 16,
      },
      { margin: 0 },
      { marginTop: 6, marginBottom: 2 },
      { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' },
      { margin: 4 },
    ],
    [boxAllFuncCorrecpondence, boxAllFuncChat] = [
      (item) => (
        <div style={styleCard}>
          {item.o && item.p && (
            <Text style={styleTitle} children={({ 1: 'Вихідна ', 2: 'Вхідна ' }[item.o] || '') + typePost(item.p, item.o) + '.'} />
          )}
          <Text type={item.x ? 'success' : 'warning'} children={`Клієнт ${item.x ? '' : 'не '}бачить цю пошту`} />
          <Text style={styleTitle} children={item.h ?? 'Анонім'} />

          {item.df?.length > 0 && (
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              onClick={() => downloadFiles(item.df, item)}
              children={`Скачати усі файли (${item.df?.length} шт.)`}
              style={styleButton}
            />
          )}

          {item.df?.length > 0 && (
            <Box
              style={styleBox}
              children={item.df.map((i, step) => {
                const [prevComb, iconComb] = [<PreviewImage key={i.L} item={i.L} />, <AvatarFile key={i.L} item={i.fd} />],
                  dynamic =
                    {
                      png: prevComb,
                      jpg: prevComb,
                      jpeg: prevComb,
                      img: prevComb,
                    }[i.fd] || iconComb,
                  result = <div style={styleFile} key={step} children={dynamic} />;
                return result;
              })}
            />
          )}
        </div>
      ),
      (item) => (
        <div style={styleCard}>
          <Text style={styleTitle} children={`Ви ${item.sender === userAuth._id ? 'відправили' : 'отримали'} файл.`} />
          {item.comment && <Text style={styleTitle} children={item.comment} />}
          <Text style={styleTitle} children="Чат" />

          {item.fileSize > 0 && (
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              children="Скачати усі файли (1 шт.)"
              onClick={() => onGetFile({ fileId: item.fileId, fileName: item.fileName })}
              style={styleButton}
            />
          )}

          <Box
            children={(() => {
              const formatFile = item.fileName?.split('.').pop(),
                [prevComb, iconComb] = [<PreviewImage key={item.fileId} item={item.fileId} />, <AvatarFile key={item.fileId} item={formatFile} />],
                dynamic =
                  {
                    png: prevComb,
                    jpg: prevComb,
                    jpeg: prevComb,
                    img: prevComb,
                  }[formatFile] || iconComb,
                result = <div children={dynamic} />;
              return result;
            })()}
          />
        </div>
      ),
    ];

  const timingFunc = (date) => {
    if (date instanceof Date) date = date.toISOString();

    return (
      !isNaN(new Date(date)) &&
      typeof date === 'string' &&
      date.trim() !== '' && <div className="date-time" children={`${getFormattedDateWithRelativeDays(date)} - ${dayjs(date).format('HH:mm')}`} />
    );
  };

  const filesTabList = [
    ...(megaState.massegeList
      ?.filter((item) => typeof item.fileId === 'string')
      ?.map((item) => ({ ...item, whereIsTheFileFrom: 'chatClientToСurator' })) ?? []),
    ...(megaState.correspondence
      ?.filter((item) => item.df?.length > 0)
      ?.map((item) => ({ ...item, whereIsTheFileFrom: 'correspondence', date: item.t })) ?? []),
  ]
    ?.sort((a, b) => dayjs(b.date).diff(dayjs(a.date)))
    ?.map((item, step) => {
      const content =
        {
          correspondence: boxAllFuncCorrecpondence(item),
          chatClientToСurator: boxAllFuncChat(item),
        }[item.whereIsTheFileFrom] || null;

      return (
        <div key={item.fileId} className="assignment">
          <Divider children={timingFunc(item.date)} />
          {content}
        </div>
      );
    });

  const makeMyFileMessage = ({ fileId, fileName, fileSize, comment }) => ({
    fileId,
    fileName,
    fileSize,
    isSentByCurrentUser: true,
    date: new Date(),
    name: userAuth.name,
    ...(comment ? { comment: comment } : {}),
  });

  const onSendFile = (params = {}) => {
    const { formData, callback, onError } = params;

    if (!formData) return error('Відсутні даги для відправки');
    if (typeof callback !== 'function') return error('Не вказана функція callback');

    formData.append('chatId', data?._id);
    formData.append('type', CHAT_TYPES.chatWithClient.key);
    request.post('/chatPrivate/chatSendFile', formData, callback, onError);
  };

  const fetchDataFromServer = useCallback(async () => {
    const validation = (__id, _i) => {
      if (!__id) {
        warn('', 'Для отримання списку, потрібно щоб існував контракт.', { _id: !!__id });
        return false;
      }

      if (isNaN(_i)) {
        warn('', 'Для отримання списку, потрібно щоб було визначено номер документа.', { i: !!_i });
        return false;
      }

      return true;
    };

    if (!validation(data?._id, data?.i)) return;

    setMegaState((prev) => ({ ...prev, loading: true }));

    const [transactionCorr, transactionChat] = [
      { _id: data?._id, i: data?.i },
      { chatId: data?._id, type: CHAT_TYPES.chatWithClient.key },
    ];

    try {
      const [responseCorr, responseChat] = await Promise.all([
        new RequestPromise('/correspondence/getCorForButtonAddFileInContract', transactionCorr),
        new RequestPromise('/chatPrivate/getchat', transactionChat),
      ]);

      setMegaState((prev) => ({
        ...prev,
        correspondence: responseCorr?.cor,
        massegeList: responseChat?.data?.messages?.map((item) => ({ ...item })) ?? [],
      }));
    } catch (err) {
      error('', 'Помилка при отриманні списку файлів', err);
    } finally {
      setMegaState((prev) => ({ ...prev, loading: false }));
    }
  }, [data]);

  useEffect(() => {
    fetchDataFromServer();
  }, [fetchDataFromServer, data]);

  const config_file_receipt = {
    maxCount: 1,
    onRemove: () => {
      setMegaState((prevState) => ({ ...prevState, fileList: [] }));
    },
    beforeUpload: (file) => {
      if (!file.name.includes('.')) {
        warn('', 'У файлі немає конкретного формату. Запис неможливий в такому випадку');
        return false;
      }

      setMegaState((prevState) => ({ ...prevState, fileList: [file] }));

      return false;
    },
  };

  const launchFormAddFile = () => {
    const file = megaState.fileList[0];

    const comment = formRef.current.getFieldsValue()?.сomment;

    if (!file) return;

    setMegaState((prev) => ({ ...prev, loadingFile: true }));

    const formData = new FormData();
    formData.append('file', file, tools.translit(file.name));

    if (comment) formData.append('comment', comment);

    onSendFile({
      formData: formData,
      callback: (res) => {
        setMegaState((prev) => ({
          ...prev,
          loadingFile: false,
          auditAddFile: false,
          fileList: [],
          massegeList: Array.isArray(res.data)
            ? [...prev.massegeList, ...res.data.map((item) => makeMyFileMessage(item))]
            : [...prev.massegeList, makeMyFileMessage(res.data)],
        }));

        success('', 'Файл успішно додано');
      },
      onError: () => {
        setMegaState((prev) => ({ ...prev, loadingFile: false, auditAddFile: false, fileList: [] }));

        error('', 'Помилка при відправці файлу');
      },
    });
  };

  const [styleFormBody, styleFormDynamic, styleFormUploadBody, styleFormButtom] = [
      { width: '100%' },
      {
        labelCol: { span: 7 },
        wrapperCol: { span: 14 },
      },
      { marginTop: 8 },
      { wrapperCol: { offset: 7, span: 16 } },
    ],
    componentFormAddFile = megaState.auditAddFile ? (
      <Form
        style={styleFormBody}
        ref={formRef}
        onFinish={launchFormAddFile}
        children={
          <Spin size="large" tip="Зачекайте, йде процес додавання файлу." spinning={megaState.loadingFile}>
            <Card title="Форма для додавання" size="small">
              <Item
                label={<span style={{ whiteSpace: 'normal' }} children="Завантажити файл" />}
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) return e;
                  return e?.fileList;
                }}
                {...styleFormDynamic}
                children={
                  <Upload {...config_file_receipt} listType="picture-card">
                    <div>
                      <PlusOutlined />
                      <div style={styleFormUploadBody} children="Завантажити" />
                    </div>
                  </Upload>
                }
              />

              <Item
                name="сomment"
                label="Коментар:"
                preserve={true}
                {...styleFormDynamic}
                children={
                  <Input
                    placeholder="Додатковий опис."
                    aria-label="We need to enter a special comment for the file name in the chat"
                    style={styleFormBody}
                  />
                }
              />

              <Item
                {...styleFormButtom}
                children={<Button disabled={!megaState.fileList[0]} type="primary" htmlType="submit" children="Відправити форму" />}
              />
            </Card>
          </Spin>
        }
      />
    ) : (
      <Button
        type="primary orange"
        onClick={() => setMegaState((prev) => ({ ...prev, auditAddFile: true }))}
        icon={<FileAddOutlined />}
        children="Додати файл в чат з клієнтом"
      />
    );

  return (
    <Modal open title={'Файли з чату з клієнтом та кореспонденції'} onCancel={onCancel} footer={null}>
      <MyRow style={{ justifyContent: 'center' }} children={componentFormAddFile} />
      <Spin size="large" tip="Завантаження" spinning={megaState.loading} />
      {filesTabList ?? <Empty description={<Text>Нема файлів</Text>} />}
    </Modal>
  );
};

export default ContractListFiles;
