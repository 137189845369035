import { useState } from 'react';
import { Col, Card, Typography, Button, Badge } from 'antd';
import dayjs from 'dayjs';
import { CHAT_TYPES } from 'common_constants/business';
import { Link } from 'react-router-dom';
import { setUnreadedMessagesRedButtonContractsChatWithClient } from '../../../store/commonReducer';
import { useDispatch, useSelector } from 'react-redux';

const RedButtonChatItem = ({ contract }) => {
  const dispatch = useDispatch();
  const [unreadedMessagesCount, setUnreadedMessagesCount] = useState(contract?.newMessageChatWithClient);

  const unreadedMessagesTotal = useSelector((state) => state.common.unreadedMessagesRedButtonContractsChatWithClient);
  const openChat = () => {
    dispatch(setUnreadedMessagesRedButtonContractsChatWithClient(unreadedMessagesTotal - contract?.newMessageChatWithClient));
    setUnreadedMessagesCount(0);
  };

  const lastPayForRedButton = contract?.autoPayTransactions ? contract?.autoPayTransactions?.slice(-1)[0]?.date : contract.sd;

  return (
    <Col xs={{ span: 24 }}>
      <Card className="contract">
        <div>
          <Typography>
            <b>Дата:</b> {dayjs(contract.date).format('DD-MM-YYYY')}
          </Typography>
          <Typography>
            <b>Клієнт:</b> {contract?.client[0].n}
          </Typography>
          <Typography>
            <b>Остання оплата:</b> {dayjs(lastPayForRedButton).format('DD-MM-YYYY')}
          </Typography>
        </div>
        <Typography>
          <Badge count={unreadedMessagesCount}>
            <Link to={{ pathname: '/chat', state: { type: CHAT_TYPES.chatWithClient.key, id: contract._id } }} onClick={openChat}>
              <Button type="primary">Чат з клієнтом</Button>
            </Link>
          </Badge>
        </Typography>
      </Card>
    </Col>
  );
};

export default RedButtonChatItem;
