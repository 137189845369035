import { useState } from 'react';
import { Spin, Modal, Form, Input, Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../store/commonReducer';
import { TENDER_TERMS } from 'common_constants/business';
import { request, success, error } from '../../tools';

const RespondToTender = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { data, setTenders, tenders } = useSelector((state) => state.common.modal);
  const userAuth = useSelector((state) => state.common.userAuth);

  const handleFormSubmit = (formData) => {
    const body = {
      ...formData,
      tenderId: data._id,
      userId: userAuth._id,
      name: userAuth.name,
      upa: userAuth?.upa,
    };

    const onSuccess = (res) => {
      success('', `Відповідь на тендер відправлено`);
      dispatch(setModal());
      const { tender } = res;
      setTenders(tenders.map((item) => (item._id === tender._id ? tender : item)));
    };

    const onError = () => {
      error('', `Помилка при відправленні відповіді на тендер`);
    };

    request.post('/tenders/respondToTender', body, onSuccess, onError);
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal title="Відповідати на тендер" open onCancel={() => dispatch(setModal())} footer={null}>
      <Form form={form} onFinish={handleFormSubmit} layout="vertical">
        <Form.Item
          name="proposal"
          label="Пропозиція"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea placeholder="Пропозиція" />
        </Form.Item>
        <Form.Item
          name="taskResult"
          label="Кінцевий результат"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea placeholder="Кінцевий результат" />
        </Form.Item>
        <Form.Item
          name="term"
          label="Срок виконання"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Срок виконання" options={TENDER_TERMS} />
        </Form.Item>
        <Form.Item
          name="fee"
          label="Гонорар ₴"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input onKeyDown={handleKeyPress} type="number" placeholder="Гонорар" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Відправити
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RespondToTender;
