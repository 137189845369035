import React from 'react';
import { Select } from 'antd';

const billSelectGeneralVip = [{ 0: 'Тільки не vip', 1: 'Тільки vip' }, ['VIP консультація', 'vip_H'], ['VIP консультація', 'vip']];

const SelectGeneralVip = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть vip статус"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_"> Усі vip/не статуси </Option>
      <Option value="0"> Тільки не vip </Option>
      <Option value="1"> Тільки vip </Option>
    </Select>
  );
};

export { billSelectGeneralVip, SelectGeneralVip };
