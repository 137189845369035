import { useEffect, useState } from 'react';
import { Row, Col, Card, Statistic, Button, Collapse } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { red, green } from '@ant-design/colors';

import { request, warn, error } from '../../tools';
import PieChart from '../../components/PieChart';
import { DECLINE_REASONS } from 'common_constants/business';

const StatMeetCancelationReasons = () => {
  const [count, setCount] = useState({ was: 0, canceled: 0, all: 0, resasons: {}, moves: {} });
  const [ownReasons, setOwnReasons] = useState([]);
  const [oldData, setOldData] = useState(false);

  const getCount = () => {
    request.post(
      '/statistic/meetCancelationReasons',
      null,
      ({ status, data }) => {
        if (!data[0] || !status) return warn();

        const was = data[0].statuses.find((i) => i._id === '1').count ?? 1;
        const canceled = data[0].statuses.reduce((partialSum, i) => {
          return partialSum + ((i._id !== '1') & (i._id !== null) ? i.count : 0);
        }, 0);
        const all = was + canceled;

        const moves = {
          true: data[0].moves.find((i) => i._id === true)?.count ?? 0,
          false: data[0].moves.find((i) => i._id === false)?.count ?? 0,
        };
        moves.all = moves.true + moves.false;

        const resasons = {};
        let reasonsLength = 0;
        data[0].reasons.forEach((i) => {
          resasons[i._id] = {
            count: i.count,
            moved: data[0].reasonsMoved.find((rm) => rm._id === i._id)?.count ?? 0,
          };
          if (i._id !== null) reasonsLength += i.count;
        });

        setCount({
          ...count,
          was,
          canceled,
          all,
          moves,
          resasons,
          reasonsLength,
        });

        setOwnReasons(data[0].ownReasons.map((i) => i._id));
      },
      error,
    );
  };

  useEffect(() => {
    getCount();
  }, []);

  const countPart = (
    <Row gutter={16}>
      <Col span={12}>
        <Card bordered={false}>
          <Statistic
            title="Проведені"
            value={!count.was ? 0 : Number(count.was * 100) / count.all}
            precision={2}
            valueStyle={{ color: green.primary }}
            prefix={<CheckCircleOutlined />}
            suffix="%"
          />

          <Statistic value={count.was} precision={0} valueStyle={{ color: green.primary }} />
        </Card>
      </Col>

      <Col span={12}>
        <Card bordered={false}>
          <Statistic
            title="Відхилені"
            value={!count.canceled ? 0 : Number(count.canceled * 100) / count.all}
            precision={2}
            valueStyle={{ color: red.primary }}
            prefix={<CloseCircleOutlined />}
            suffix="%"
          />

          <Statistic value={count.canceled} precision={0} valueStyle={{ color: red.primary }} />
        </Card>
      </Col>
    </Row>
  );

  const countOFMoves = (
    <Row gutter={16}>
      <Col span={12}>
        <Card bordered={false}>
          <Statistic
            title="Перенесені"
            value={!count.moves.true ? 0 : Number(count.moves.true * 100) / count.moves.all}
            precision={2}
            valueStyle={{ color: green.primary }}
            prefix={<CheckCircleOutlined />}
            suffix="%"
          />

          <Statistic value={count.moves['true']} precision={0} valueStyle={{ color: green.primary }} />
        </Card>
      </Col>

      <Col span={12}>
        <Card bordered={false}>
          <Statistic
            title="Не перенесені"
            value={!count.moves.false ? 0 : Number(count.moves.false * 100) / count.moves.all}
            precision={2}
            valueStyle={{ color: red.primary }}
            prefix={<CloseCircleOutlined />}
            suffix="%"
          />

          <Statistic value={count.moves['false']} precision={0} valueStyle={{ color: red.primary }} />
        </Card>
      </Col>
    </Row>
  );

  const data = DECLINE_REASONS.map((i, index) => ({
    name: index === 0 ? 'Інша причина' : i,
    value: count.resasons[index]?.count ?? 0,
    moved: count.resasons[index]?.moved ?? 0,
  }));

  if (oldData) data['55'] = { name: 'Старі дані', value: count.resasons['null'] ?? 0 };
  return (
    <>
      <h2>Статистика причин відмов</h2>
      {countPart}
      <br />
      {countOFMoves}
      <br />

      <Card style={{}}>
        <PieChart data={data} all={count.reasonsLength} height={600} />
        <Collapse>
          <Collapse.Panel header="Свої причини відмов" key="1">
            {ownReasons.map((i, index) => (
              <div key={index}>{i}</div>
            ))}
          </Collapse.Panel>
        </Collapse>
      </Card>
      <Button onClick={() => setOldData(!oldData)}>Показати старі дані на діаграмі</Button>
    </>
  );
};

export default StatMeetCancelationReasons;
