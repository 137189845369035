import { SPHERE_EDIT_CATEGORY } from 'common_constants/modals';
import { useDeleteSphereServiceCategoryMutation, useGetSphereServiceCategoriesQuery } from '../../store/apis/sphere-api';
import { setModal } from '../../store/commonReducer';
import CategoriesListItem from './CategoriesListItem';
import { useDispatch } from 'react-redux';

const CategoriesList = ({ activeCategory, setActiveCategory }) => {
  const dispatch = useDispatch();

  const { data: categories, isSuccess } = useGetSphereServiceCategoriesQuery();
  const [deleteCategory] = useDeleteSphereServiceCategoryMutation();

  const handleCategoryClick = (e, category) => {
    e.stopPropagation();
    setActiveCategory((prev) => (prev?._id === category._id ? null : category));
  };

  const handleDeleteCategory = async (e, id) => {
    e.stopPropagation();
    if (!window.confirm('Ви точно хочете видалити категорію ?')) return;

    await deleteCategory(id);
  };
  const handleUpdateCategory = (e, category) => {
    e.stopPropagation();
    dispatch(setModal({ name: SPHERE_EDIT_CATEGORY, data: category }));
  };
  return (
    <div className="list-container categories-list">
      {isSuccess &&
        categories?.data.map((category) => (
          <CategoriesListItem
            key={category._id}
            handleCategoryClick={handleCategoryClick}
            handleUpdateCategory={handleUpdateCategory}
            handleDeleteCategory={handleDeleteCategory}
            category={category}
            isSelected={activeCategory?._id === category._id}
          />
        ))}
    </div>
  );
};

export default CategoriesList;
