import React from 'react';
import { CLIENT_COLOR } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralColor = [
  {
    null: 'Без позначки',
    ...Object.entries(CLIENT_COLOR).map((ar) => (ar[0] = ar[1].name)),
    '1or2': 'Золотий і Коричневий',
  },
  ['Колір клієнта', 'color_C'],
];

const SelectGeneralColor = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть колір клієнта"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі кольори</Option>
      <Option value="null">Без позначки</Option>
      {Object.keys(CLIENT_COLOR).map((index) => {
        const color_client = CLIENT_COLOR[index];
        return (
          <Option key={index} value={index}>
            {color_client.name}
          </Option>
        );
      })}
      <Option value="1or2">Золотий і Коричневий</Option>
    </Select>
  );
};

export { SelectGeneralColor, billSelectGeneralColor };
