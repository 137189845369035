import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { MailFilled } from '@ant-design/icons';

import { CHAT_WITH_ACCOUNTANT } from 'common_constants/modals';
import { setModal } from '../../../../../store/commonReducer';

const StartSMSMailingButton = ({ data }) => {
  const dispatch = useDispatch();
  const openModal = () => {
    dispatch(setModal({ name: CHAT_WITH_ACCOUNTANT, data }));
  };
  return (
    <Button icon={<MailFilled />} className="buttons-contracts" type="primary" onClick={openModal}>
      Жнивувати в єАдвокат
    </Button>
  );
};

export default StartSMSMailingButton;
