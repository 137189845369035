import { Row, Col, Rate } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';

const getResultForInspectorColumns = (block, users, notMyBranch) => {
  const columns = [
    {
      dataIndex: 'votedUserId',
      key: 'votedUserId',
      render: (_, record) => {
        const showRate = block !== users[record.votedUserId]?.block;
        return (
          <Row className="resultForInspector" gutter={[16, 16]}>
            <Col span={12}>
              <Row>
                <Col span={24}>{users[record.votedUserId]?.name}</Col>
                <Col span={24}>
                  {'updatedAt' in record
                    ? dayjs(record.updatedAt).format('DD.MM.YYYY HH:mm:ss')
                    : dayjs(record.createdAt).format('DD.MM.YYYY HH:mm:ss')}
                </Col>
              </Row>
            </Col>

            <Col style={{ textAlign: 'end' }} span={12}>
              <Row>
                <Col span={24} className="resultForInspector_logo">
                  {record.vote ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '20px' }} />
                  ) : (
                    <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: '20px' }} />
                  )}
                </Col>
                <Col className="resultForInspector-rate" span={24}>
                  {notMyBranch && block && record.vote && showRate ? <Rate disabled value={record.rating} justify={'center'} /> : 'Не оцінюється'}
                </Col>
              </Row>
            </Col>
          </Row>
        );
      },
    },
  ];

  return columns;
};

export default getResultForInspectorColumns;
