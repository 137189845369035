import React, { useEffect, useState } from 'react';

import { request, error } from '../../tools';
import PromocodeBarChart from './PromocodeBarChart';

const StatisticPromocode = () => {
  const [promocodes, setPromocodes] = useState([]);
  const [totalHotlineCount, setTotalHotlineCount] = useState([]);

  const getAllHotlines = () => {
    request.post(
      '/promocodes/statisticPromocodes',
      {},
      ({ data, totalHotlineCount }) => {
        setPromocodes(data);
        setTotalHotlineCount(totalHotlineCount);
      },
      error,
    );
  };

  useEffect(() => {
    getAllHotlines();
  }, []);

  const sortedPromocodes = promocodes.sort((a, b) => a.usageCount - b.usageCount);

  return (
    <section className="function-survey_page default_page">
      <div style={{ height: '100%' }}>
        <PromocodeBarChart data={sortedPromocodes} hotlines={totalHotlineCount} />
      </div>
    </section>
  );
};

export default StatisticPromocode;
