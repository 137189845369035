import React from 'react';
import { Card } from 'antd';
import clsx from 'clsx';

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

const MfoCard = ({ mfo }) => {
  const { name, email, address } = mfo;

  return (
    <Card title={name} className="selectedMfo">
      <p className={clsx('mfo-email', validateEmail(email) ? '' : 'not-valid')}>
        <b>Email:</b> {email}
      </p>
      <p>
        <b>Адрес:</b> {address}
      </p>
    </Card>
  );
};

export default MfoCard;
