import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'common_constants/routes';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

const SelectGeneralAppointed = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;
  const { pathname } = useLocation();
  const users = useSelector((state) => state.common.users);

  const dynamicList = pathname === ROUTES.CORRESPONDENCE_PAGE ? false : true;
  const dynamicPlaceholder = pathname === ROUTES.CORRESPONDENCE_PAGE ? 'автора пошти' : 'призначеного';

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder={`Оберіть ${dynamicPlaceholder}`}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {dynamicList && <Option value="_">Усі призначенні</Option>}
      {dynamicList && <Option value="-">Немає</Option>}
      {users &&
        Object.keys(users).length &&
        Object.values(users)?.map((i, index) => {
          return (
            <Option key={index} value={String(i._id)}>
              {i.p}
            </Option>
          );
        })}
    </Select>
  );
};

export default SelectGeneralAppointed;
