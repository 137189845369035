import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, List, Pagination } from 'antd';

import { setModal, setLawyersList, setJudgesList, setAssignmentsList, setHarvestList } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { request } from '../../tools';
import { HarvestInfoListItem } from './HarvestInfoListPartials';

const HarvestInfoList = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.common.modal.data);
  const harvestList = useSelector((state) => state.common.harvest) ?? [];
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSetMainLoader = (state) => dispatch(setMainLoader(state));

  const onCancel = () => dispatch(setModal());

  const handleTableChange = (pageNumber) => setCurrentPage(pageNumber);

  const fetchHarvest = () => {
    const onSuccess = (res) => {
      setTotal(res.total);
      dispatch(setHarvestList(res.data));
      handleSetMainLoader(false);
    };

    const onError = (err) => {
      handleSetMainLoader(false);
    };

    const body = { contractIndex: data.contractNumber, page: currentPage };

    handleSetMainLoader(true);
    request.post('/harvest/getByContract', body, onSuccess, onError);
  };

  useEffect(() => {
    fetchHarvest();
  }, [currentPage]);

  return (
    <Modal open title={`Жнива по договору № ${data.contractNumber}`} onCancel={onCancel} footer={null}>
      <List itemLayout="horizontal" dataSource={harvestList} renderItem={(harvest) => <HarvestInfoListItem harvest={harvest} />} />
      <Pagination style={{ textAlign: 'center' }} current={currentPage} total={total} onChange={handleTableChange} />
    </Modal>
  );
};

export default HarvestInfoList;
