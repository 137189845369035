import React from 'react';
import { SERVICE_PAYMENT_TYPE } from 'common_constants/business';
import SearchSelect from '../../components/SearchSelect';

const TransactionTypeSearchSelect = ({ onChange, value, className, style, disabledState }) => {
  return (
    <SearchSelect
      disabled={disabledState}
      className={className}
      style={style}
      placeholder="Оберіть призначення платежу"
      list={[SERVICE_PAYMENT_TYPE[0], SERVICE_PAYMENT_TYPE[2]]}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default TransactionTypeSearchSelect;
