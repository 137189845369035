import React from 'react';
import { Alert } from 'antd';

const ContractsWithoutBlockMsg = ({ numberWithoutBlock }) => {
  return (
    <Alert showIcon message="Увага! Неприв'язані блоки" type="warning" description={`${numberWithoutBlock} договорів не мають призначеного блоку`} />
  );
};

export default ContractsWithoutBlockMsg;
