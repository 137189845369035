import React, { useMemo } from 'react';
import { Button, Card, Col, Row } from 'antd';
import './WalletFilialsItem.scss';
import { useDispatch, useSelector } from 'react-redux';
import { error } from '../../../tools';
import { setModal, setFilialWorkers } from '../../../store/commonReducer';
import { FILIAL_WORKERS } from 'common_constants/modals';
import { CURRENCY_CODE_MAP } from 'common_constants/business';

const WalletFilialsItem = ({ fil, exchangeRates = [] }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);

  const activeFilialUsers = useMemo(() => {
    return Object.values(users).filter((user) => {
      if (fil === '') return !user.fired;
      return !user.fired && user.fil == fil.i;
    });
  }, [users, fil]);

  const convertToUAH = (amount, currency) => {
    if (currency === 'UAH') return amount;
    const ourExchange = exchangeRates.find((rate) => CURRENCY_CODE_MAP[currency] === rate.currencyCodeA);
    return ourExchange ? amount * (ourExchange.rateBuy || ourExchange.rateCross) : amount;
  };

  const totalBalanceInUAH = useMemo(() => convertToUAH(fil?.totalBalance?.total || 0, fil?.currency || 'UAH'), [fil, exchangeRates]);
  const totalExpenseInUAH = useMemo(() => convertToUAH(fil?.totalBalance?.expense || 0, fil?.currency || 'UAH'), [fil, exchangeRates]);
  const cashBalanceInUAH = useMemo(() => convertToUAH(fil?.cashBalance || 0, fil?.currency || 'UAH'), [fil, exchangeRates]);
  const lastMonthBalanceInUAH = useMemo(() => convertToUAH(fil?.totalBalanceLastMonth?.total || 0, fil?.currency || 'UAH'), [fil, exchangeRates]);
  const filialCurrentBalanceInUAH = (totalBalanceInUAH + cashBalanceInUAH).toLocaleString('uk-UA');
  const filialExpenseInUAH = (totalExpenseInUAH + cashBalanceInUAH).toLocaleString('uk-UA');

  const totalBalance = fil?.totalBalance?.total || 0;
  const totalExpense = fil?.totalBalance?.expense || 0;
  const cashBalance = fil?.cashBalance || 0;
  const lastMonthBalance = fil?.totalBalanceLastMonth?.total || 0;
  const filialCurrentBalance = (totalBalance + cashBalance).toLocaleString('uk-UA');
  const filialExpense = (totalExpense + cashBalance).toLocaleString('uk-UA');

  const handleCardClick = () => {
    if (activeFilialUsers.length > 0) {
      dispatch(setFilialWorkers(activeFilialUsers));
      dispatch(setModal({ name: FILIAL_WORKERS, data: { fil } }));
    } else {
      error('За обраною філією не знайдено жодного працівника.', ' ');
    }
  };

  return (
    <Card onClick={handleCardClick} className="walletFilialsInner">
      <Row className="item" justify="space-between" align="middle">
        <Col className="fields-inner" flex="auto">
          <Row className="fields" justify="space-between">
            <Col className="fields-item">
              <Row className="fields-row">
                <span className="info">{fil.name}</span>
              </Row>
            </Col>
            <Col className="fields-item">
              <Row className="fields-row">
                <span className="info">{filialCurrentBalanceInUAH} ₴ </span>
                {(fil?.currency || 'UAH') !== 'UAH' && (
                  <span className="info">
                    {filialCurrentBalance} {fil?.currency}
                  </span>
                )}
              </Row>
            </Col>
            <Col className="fields-item">
              <Row className="fields-row">
                <span className="info">{lastMonthBalanceInUAH} ₴ </span>
                {(fil?.currency || 'UAH') !== 'UAH' && (
                  <span className="info">
                    {lastMonthBalance} {fil?.currency}
                  </span>
                )}
              </Row>
            </Col>
            <Col className="fields-item">
              <Row className="fields-row">
                <span className="info">{filialExpenseInUAH} ₴ </span>
                {(fil?.currency || 'UAH') !== 'UAH' && (
                  <span className="info">
                    {filialExpense} {fil?.currency}
                  </span>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default React.memo(WalletFilialsItem);
