import { Typography, Card, Row, Col, Space } from 'antd';
import {
  JOB_SATISFACTION_TO_CLIENT,
  LOGICAL_ARCHIVE_CANDY_CHOISE_3_OPTIONS,
  LOGICAL_STANDARD_CHOISE_2_OPTIONS,
  LOGICAL_STANDARD_CHOISE_4_OPTIONS,
} from 'common_constants/business';

const { Text } = Typography;

const ContractQuestionnaire = ({ questionnaire: { qa, i, client } }) => {
  return (
    <Card className="questionnaire-card" title={`№ ${i}`} bordered={true} style={{ margin: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Клієнт:</Text>
          </Col>
          <Col span={12}>
            <Text>
              {client?.n} {`+${client?.countryPhCode}${client?.ph}`}
            </Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Оцінка якості:</Text>
          </Col>
          <Col span={12}>
            <Text>{qa.sq}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Задоволення роботою:</Text>
          </Col>
          <Col span={12}>
            <Text>{JOB_SATISFACTION_TO_CLIENT[qa.sw]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Задоволення комунікацією:</Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STANDARD_CHOISE_4_OPTIONS[qa.sc]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Звернувся б ще:</Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STANDARD_CHOISE_2_OPTIONS[qa.ca]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Порадили комусь:</Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STANDARD_CHOISE_2_OPTIONS[qa.as]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Довіряєте компанії:</Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STANDARD_CHOISE_4_OPTIONS[qa.ts]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Чи сподобався подарунок:</Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_ARCHIVE_CANDY_CHOISE_3_OPTIONS[qa.ac]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Місце для скарг чи пропозицій:</Text>
          </Col>
          <Col span={12}>
            <Text>{qa.pc}</Text>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default ContractQuestionnaire;
