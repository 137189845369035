import { Typography } from 'antd';
import { CLIENT_INFO } from 'common_constants/modals';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../../../store/commonReducer';

const ClientInfoRow = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const { Text, Link } = Typography;
  const openClient = () => dispatch(setModal({ name: CLIENT_INFO, data: data.client[0], fromContracts: true }));
  return (
    <span>
      <Text strong>Клієнт:</Text>&nbsp;
      {!data.client[0] ? (
        <span className="error">Помилка</span>
      ) : (
        <>
          {data.client[0]?.n} &nbsp;
          {data.client[0]?.ph} &nbsp;
          <Link onClick={openClient}>подробиці</Link>
        </>
      )}
    </span>
  );
};

export default ClientInfoRow;
