import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES } from 'common_constants/business';

import { request, error } from '../tools';
import { setUnreadedMessages } from '../store/commonReducer';

const useGetUserUnreadedMessages = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const psychologistOnly = ROLES[userAuth.role] === ROLES.psychologist;

  const getUserUnreadedMessages = () => {
    request.post(
      '/chatPrivate/getUnreadedMessages',
      null,
      (req) => {
        dispatch(setUnreadedMessages(req.data || []));
      },
      error,
    );
  };

  useEffect(() => {
    if (!userAuth) return;

    !psychologistOnly && getUserUnreadedMessages();
  }, [userAuth?._id]);
};

export default useGetUserUnreadedMessages;
