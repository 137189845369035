import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Modal, Input, Typography, Space } from 'antd';

import { setModal, setCurrentClient } from '../../store/commonReducer';
import Box from '../../components/Box';
import { request, warn, error } from '../../tools';
import { NEW_CONTRACT } from 'common_constants/modals';

import './styles.scss';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

const SearchClient = () => {
  const dispatch = useDispatch();
  const { data, prev } = useSelector((state) => state.common.modal);

  const [phone, setPhone] = useState(data.hotline.phone);
  const [client, setClient] = useState({});
  const { Text } = Typography;

  const onFind = () => {
    const validation = () => {
      if (!phone) {
        warn('', 'Для пошуку потрібно вказати номер телефону клієнта', { phone: phone });
        return;
      }

      return true;
    };

    if (!validation()) return;

    const transaction = { ph: phone };

    request.post(
      '/clients/find',
      transaction,
      (req) => {
        setClient(req.data);
      },
      error,
    );
  };

  const onAdd = () => {
    dispatch(setModal({ name: NEW_CONTRACT, data: data.contractForm, hotline: data.hotline }));
    dispatch(setCurrentClient(client));
  };

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);
    setPhone(validPhoneNumber);
  };

  return (
    <Modal open className="search-client" title="Пошук клієнта" onCancel={() => dispatch(setModal(prev))} footer={null}>
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <Card className="change">
          <Text>Телефон: (0501234567)</Text>
          <Input
            // className={validPhone?'':'error'}
            aria-label="Write phone client"
            value={phone}
            controls={false}
            onChange={(e) => handlePhoneNumberInput(e)}
            style={{ width: '100%' }}
          />
          <Box mt={12}>
            <Button type="primary" onClick={onFind}>
              Знайти
            </Button>
          </Box>
        </Card>
        {!client ? 'Не знайдено' : ''}

        {client?._id && (
          <Card className={'card' + (client.s === '1' ? ' success' : '')}>
            <div className="row">
              <Text strong className="row-space">
                Ім'я:
              </Text>
              <Text> {client.n ?? '-'} </Text>
            </div>
            <div className="row full hr">
              <Text strong className="row-space">
                Коментар:
              </Text>
              <Text> {client.c ?? '-'} </Text>
            </div>
            <hr />
            <div className="row full hr">
              <Text strong className="row-space">
                Існуючі договори:
              </Text>
              <Text> {client.c ?? '-'} </Text>
            </div>
          </Card>
        )}

        {client?._id && (
          <Button className="full add" type="primary" onClick={onAdd}>
            Обрати
          </Button>
        )}
      </Space>
    </Modal>
  );
};

export default SearchClient;
