import React, { useState } from 'react';
import { Checkbox, Button, Input } from 'antd';

import { error } from '../../../../tools';

import './ArchiveCandy.scss';

const ArchiveCandy = ({ archiveCandy, setArchiveCandy }) => {
  const [candyGiven, setCandyGiven] = useState(archiveCandy.candyGiven ?? false);
  const [candyNotGivenReason, setCandyNotGivenReason] = useState(archiveCandy.candyNotGivenReason ?? '');

  const onClickSend = () => {
    if (!candyGiven && (!candyNotGivenReason || candyNotGivenReason?.length <= 5)) {
      error('Помилка!', 'Поясність, будь ласка, причину, чому не було видано архівну цукерку (не менше 6-ти символів)');
      return;
    }

    setArchiveCandy({
      candyGiven,
      candyNotGivenReason: candyGiven ? '' : candyNotGivenReason,
    });
  };

  return (
    <div className="archive-candy">
      <label>Чи видана була цукерка?</label>
      &ensp;
      <Checkbox checked={candyGiven} onChange={(e) => setCandyGiven(e.target.checked)} />
      {!candyGiven ? (
        <div className="reason-block">
          <label htmlFor="">Поясність причину, чому цукeрку не було видано</label>
          <Input.TextArea value={candyNotGivenReason} onChange={(e) => setCandyNotGivenReason(e.target.value)} />
        </div>
      ) : null}
      <Button type="primary" onClick={onClickSend} className="btn-send">
        Надіслати
      </Button>
    </div>
  );
};

export default ArchiveCandy;
