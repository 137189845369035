import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { deleteLibraryItem } from '../../store/commonReducer';

import { Card, Popconfirm, Button, Tag, Typography, Spin, Dropdown } from 'antd';
import { DeleteOutlined, MoreOutlined, InfoOutlined, SnippetsOutlined } from '@ant-design/icons';
import { gray } from '@ant-design/colors';

import { request, error, success, warn, info } from '../../tools';
import { Box } from 'common_components';
import { ROLES } from 'common_constants/business';

import LibraryAvatarFile from './Core/LibraryAvatarFile';
import LibraryInfoFile from './Core/LibraryInfoFile';
import LibraryDownloadFile from './Core/LibraryDownloadFile';

import './LibraryItem.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const LibraryItem = ({ item, cardWidth, cardMargin, mfo }) => {
  const dispatch = useDispatch();

  const [userAuth, users] = [useSelector((state) => state.common.userAuth), useSelector((state) => state.common.users)];

  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const user = users?.[userAuth?._id];

  const core_megaState = {
      loading: false,
      loadingText: 'Завантаження', //* Текст спіна в залежності від дії
      auditСase: false, //* Результат зустрічі
      star: 3, //* Запропонована сума
    },
    [megaState, setMegaState] = useState(core_megaState);

  const [{ Grid }, { Text }] = [Card, Typography];

  useEffect(() => {
    if (item.h?.[0] === '1') setMegaState({ ...megaState, auditСase: true }); //* Контролер - Назва справи
  }, [item.h]);

  const delete_file_by_fetch = async () => {
    const validation = (id) => {
      if (!id) {
        warn('', 'Для видалення документа, потрібно вказати дійсний документ.', { id: !!id });
        return;
      }

      return true;
    };

    if (!validation(item._id)) return;

    setMegaState({ ...megaState, loading: true, loadingText: 'Зачекайте, йде видалення файлів' });

    await request.delete(
      '/library/',
      item._id,
      () => {
        dispatch(deleteLibraryItem(item._id));
        success('', 'Документ видалено успішно!');
      },
      error,
    );

    setMegaState({ ...megaState, loading: false, loadingText: core_megaState.loadingText });
  };

  const cancel = () => {
    info('', 'Ви не згодні зі своїм рішенням.');
  };

  const options_drop = [
    // p && {
    //   key: '1',
    //   label: <>Відкрити справу</>,
    //   icon: <DiffOutlined />,
    //   disabled: true,
    // },
    // {
    //   key: '2',
    //   label: <>Чат по {p ? 'справі' : 'файлу'}</>,
    //   icon: <TabletFilled />,
    //   disabled: true,
    // },
    // {
    //   key: '3',
    //   label: <>Редагувати</>,
    //   icon: <EditOutlined />,
    //   disabled: true,
    // },
    {
      key: '4',
      label: <LibraryInfoFile item={item} />,
      icon: <InfoOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: '5',
      danger: true,
      label: (
        <Popconfirm
          title="Підтвердження"
          description="Ви впевнені, що хочете видалити цей даний документ?"
          onConfirm={() => delete_file_by_fetch()}
          onCancel={cancel}
          okText="Гаразд"
          cancelText="Скасувати"
          className="btn-reach-popc"
        >
          Видалити
        </Popconfirm>
      ),

      icon: <DeleteOutlined />,
      disabled: inspector || item.a === user?._id ? false : true,
    },
  ];

  return (
    <Grid className="lib-item">
      <Spin tip={megaState.loadingText} spinning={megaState.loading} size="large">
        <Card
          title={
            <Box>
              <Tag color={gray[item.o ? 3 : 7]}>{item.o ? "Необов'язковий" : "Обов'язковий"}</Tag>
              <br />
              <Text>{item.n ?? '-'}</Text>
            </Box>
          }
          extra={
            <Dropdown placement="bottom" menu={{ items: options_drop || [] }}>
              <Button size="large" type="text">
                <MoreOutlined />
              </Button>
            </Dropdown>
          }
          style={{
            width: cardWidth ? cardWidth : 300,
            margin: cardMargin ? cardMargin : 8,
          }}
          actions={[
            // <Space>{tag_download_file}</Space>, // TODO
            <LibraryDownloadFile
              item={item}
              loadingDownload={(check) =>
                setMegaState({ ...megaState, loading: check, loadingText: check ? 'Зачекайте, йде скачування файлів' : core_megaState.loadingText })
              }
              mfo={mfo}
            />,
            // <Space>
            //   0&ensp;
            //   {/* <StarOutlined key="starRating" /> */}
            //   <Rate
            //     className="lib-item-star"
            //     tooltips={LOGICAL_STANDARD_QUALITY_LEVEL_5_OPTIONS}
            //     onChange={(value) => setMegaState({ ...megaState, star: value })}
            //     value={megaState.star}
            //   />
            // </Space>,
          ]}
        >
          <Box
            mt={16}
            style={{
              marginBottom: 16,
            }}
          >
            {item.z === '2' && (
              <SnippetsOutlined
                size={80}
                shape="square"
                style={{
                  display: 'block',
                }}
                className="lib-item-avatar-2"
              />
            )}

            <LibraryAvatarFile item={{ 0: item.f, 1: 'web' }[item.z]} />
          </Box>
        </Card>
      </Spin>
    </Grid>
  );
};

export default LibraryItem;
