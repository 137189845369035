import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spin, Modal, Input, DatePicker, Typography, Checkbox, Divider } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import { setModal, setCurrentClient, setContractsList, selectUserAuth, selectUsers } from '../../store/commonReducer';
import {
  PRAVO,
  REPEATS,
  PAYMENT_FORMAT,
  HOTLINE_ADV_WHERE,
  HOTLINE_ADV_WHERE_MORE,
  SERVIS_OPTIONS,
  PRAVO_TYPES,
  CHAT_TYPES,
  RED_BUTTON_PRICE,
  COUNTRY_PHONE_CODES,
} from 'common_constants/business';
import { SEARCH_CLIENT, NEW_CLIENT, CONFIRM_ADD_CONTRACT, NEW_CONTRACT, INCOMES } from 'common_constants/modals';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';
import SearchSelect from '../../components/SearchSelect';
import Box from '../../components/Box';
import PaymentManagementComponent from '../../components/PaymentManagementComponent';
import PaymentManagementForm from '../../components/PaymentManagementForm';
import CountryPhCode from '../../components/CountryPhCode';
import ShortQuestionnaireComponent from './ShortQuestionnaireComponent';
import { error, request, warn, success } from '../../tools';

import './styles.scss';

const findContract = (contractsList, data) => {
  const find = (contract) => contract?._id === data?._id;
  return contractsList?.filter(find)?.[0];
};

const NewContract = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector(selectUserAuth);
  const users = useSelector(selectUsers);
  const loading = useSelector((state) => state.common.reqLoader);
  const { data, fromContracts, hotline = {} } = useSelector((state) => state.common.modal);
  const contractsList = useSelector((state) => state.common.contracts);
  const currentClient = useSelector((state) => state.common.currentClient);
  const FILII = useSelector((state) => state.common.FILII);

  const findedContract = findContract(contractsList, data);
  const currentContract = findedContract || data || [];

  const client = data?.client?.[0] || currentClient;
  const hasSelectedClient = !!client;
  const hasIncomes = !!currentContract?.incomes;
  const isClientSelfRegistered = client?.selfRegistered;
  const { Text } = Typography;

  const shortQuestionnaireRef = useRef();

  const filialCurrency = hotline?.currency || 'UAH';
  const currentCurrency = client?.currency || filialCurrency;

  const [phone, setPhone] = useState(client?.ph);
  const [countryPhCode, setCountryPhCode] = useState(client?.countryPhCode ?? COUNTRY_PHONE_CODES.UA);
  const [isLoading, setIsLoading] = useState(false);
  const [totalInstallment, setTotalInstallment] = useState(0);
  const [paymentManagement, setPaymentManagement] = useState(currentContract?.paymentManagement || []);
  const [monthlyPayments, setMonthlyPayments] = useState(currentContract?.monthlyPaymentsManagement || []);
  const [totalPayByClient, setTotalPayByClient] = useState(0);
  const [sugar, setSugar] = useState([]);
  const [contractForm, setContractForm] = useState({
    ov1: currentContract.ov1 || '',
    ov2: currentContract.ov2 || '',
    ov3: currentContract.ov3 || '',
    sd: dayjs(currentContract.sd),
    f: currentContract.f ?? Number(hotline.fil),
    us: currentContract.us || userAuth._id,
    un: currentContract.un,
    od: currentContract.od || hotline.type ? 1 : 0,
    tm: currentContract.tm,
    aw: currentContract.aw || hotline.aw,
    am: currentContract.am || hotline.am,
    md: dayjs(currentContract.md),
    fp: currentContract.fp || hotline.cost,
    kk: currentContract.kk,
    pr: currentContract.pr,
    prc: currentContract.prc ?? Number(hotline.pravo),
    ci: currentContract.ci,
    cd: currentContract.cd,
    pf: currentContract.pf,
    pa: currentContract.pa,
    pm: 13,
    ea: currentContract.ea,
    rb: currentContract.rb,
    P: currentContract.P,
    H: currentContract.H || hotline?._id,
    promocode: hotline?.promocode || '',
    insuranceState: currentContract.insuranceState ? true : false,
    insurance: currentContract.insurance ?? {},
    incomes: currentContract.incomes ?? [],
    isNewContract: !(currentContract._id || currentContract.contractId),
    contractId: currentContract._id || currentContract.contractId,
    installmentMonths: currentContract.installmentMonths,
    monthlyFee: currentContract.monthlyFee,
    i: currentContract?.i,
    shortQuestionnaire: currentContract?.shortQuestionnaire || null,
  });

  const countTotalPayByClient = () => {
    const sugarSum = sugar?.reduce((acc, item) => acc + +item?.amount, 0) || 0;
    return sugarSum;
  };

  const getSugarForClient = () => {
    if (!currentContract?._id) return;

    setIsLoading(true);

    const params = { contractIndex: currentContract?.i, md: currentContract?.md };

    request.post(
      '/sugar/getContractSugar',
      params,
      ({ sugar }) => {
        setSugar(sugar);
        setIsLoading(false);
      },
      error,
    );
  };

  const onCancel = () => {
    dispatch(setModal());
    dispatch(setCurrentClient());
  };

  const cancelEditPaymentManagement = () => {
    setPaymentManagement(currentContract?.paymentManagement || []);
  };

  const updateMonthlyPayments = () => {
    if (!currentContract?.monthlyFee) return;

    const onSuccess = ({ monthlyPaymentsManagement }) => {
      setMonthlyPayments(monthlyPaymentsManagement);
    };

    const onError = () => {
      error('Виникла помилка при генерування щомісячних платежів');
    };

    request.post('/contracts/fillMonthlyPayments', { _id: currentContract._id, md: contractForm?.md }, onSuccess, onError);
  };

  const onSubmit = async () => {
    const fpValue = contractForm?.fp;
    const isFpInvalid = fpValue === null || fpValue === undefined || fpValue === '' || isNaN(Number(fpValue));
    if (!client?.ph) {
      warn('', 'Для договору потрібен клієнт', { 'client?.ph': client?.ph });
      return;
    }

    if (contractForm?.rb) {
      if (!contractForm?.pa || contractForm?.pa < RED_BUTTON_PRICE) {
        warn('', `Мінімальна вартість тривожної кнопки ${RED_BUTTON_PRICE}₴`);
        return;
      }
    }

    let validatedIncomes = true;

    contractForm?.incomes?.forEach((income) => {
      if (!income?.type && income?.type !== 0) {
        validatedIncomes = false;
        return;
      }
    });

    if (!validatedIncomes) {
      warn('Для створення потрібно вказати вид або видалити поле');
      return;
    }

    if (contractForm?.fp && (!contractForm?.pa || Number(contractForm?.fp) > Number(contractForm?.pa))) {
      warn('Сума першого платежу не може бути більшою за суму гонорару');
      return;
    }

    if (!contractForm?.md) {
      warn('Для створення потрібно вказати дату першого платежу');
      return;
    }

    if (!contractForm?.pa) {
      warn('Для створення потрібно вказати суму гонорару');
      return;
    }

    if (contractForm?.pa && contractForm?.pa !== contractForm?.fp && (isFpInvalid || !Number(contractForm?.installmentMonths))) {
      warn('Гонорар не може бути встановлений.', 'Вкажіть будь-ласка перший платіж та кількість місяців в розстрочку.');
      return;
    }

    const questionnaireData = await shortQuestionnaireRef.current.submitQuestionnaireForm();

    if (!questionnaireData && !['Блок АНК', 'Моя філія'].includes(SERVIS_OPTIONS[contractForm?.un])) {
      return warn(`Довідка меморандум`, `Заповніть Довідку меморандум `);
    }

    if (!questionnaireData?.tasks) {
      return warn(`Довідка меморандум`, `Заповніть всі поля в завданні `);
    }

    const tasksWithoutId = questionnaireData.tasks.map(({ id, ...rest }) => ({
      ...rest,
    }));

    const contract = {
      C: client?._id,
      H: contractForm.H,
      P: contractForm.P,
      ov1: contractForm.ov1,
      ov2: contractForm.ov2,
      ov3: contractForm.ov3,
      f: contractForm.f,
      us: contractForm.us,
      un: contractForm.un,
      od: contractForm.od,
      tm: contractForm.tm,
      aw: contractForm.aw,
      am: contractForm.am,
      md: contractForm.md,
      sd: contractForm.sd,
      kk: contractForm.kk,
      fp: contractForm.fp,
      pr: contractForm.pr,
      prc: contractForm.prc,
      ci: contractForm.ci,
      cd: contractForm.cd,
      pf: contractForm.pf,
      pa: contractForm.pa,
      pm: contractForm.pm,
      ea: contractForm.ea,
      promocode: contractForm.promocode,
      incomes: contractForm.incomes,
      rb: contractForm.rb,
      installmentMonths: contractForm.installmentMonths,
      monthlyFee: contractForm.monthlyFee,
      shortQuestionnaire: { ...questionnaireData, tasks: tasksWithoutId },
    };
    if (contractForm.insuranceState) contract.insurance = contractForm.insurance;

    if (contractForm.contractId) {
      const token = window.localStorage.getItem('accessToken');
      contract._id = contractForm.contractId;

      return fetch(process.env.REACT_APP_API + '/contracts/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: JSON.stringify(contract),
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.status) {
            warn();
            return;
          }
          dispatch(setModal());
          success();
          updateMonthlyPayments();

          const isCuratorChanged = currentContract.us !== contractForm.us;
          if (isCuratorChanged) {
            const idAssignedUser = Object.values(users).find((user) => user._id === contractForm.us);

            const messageData = {
              sender: contractForm.us,
              chatId: currentContract._id,
              message: `Доброго дня, мене звати ${idAssignedUser?.name} ${
                idAssignedUser?.userPersonalData?.workPhone ? `, мій номер телефону ${idAssignedUser.userPersonalData.workPhone}` : ''
              }. Я новий куратор по вашій справі. Залюбки допоможу вирішити Ваші питання!`,
              automated: true,
              type: CHAT_TYPES.chatWithClient.key,
            };

            request.post(
              '/chatPrivate/chatSendMessage',
              messageData,
              () => {
                success('Клієнту було надіслано повідомлення про зміну куратора');
              },
              error,
            );
          }

          const _contractsList = contractsList.map((i) => {
            if (i._id === contract._id) {
              const _sd = contract.sd ? { sd: contract.sd.toJSON() } : {}; //? fix non-serializable redux
              const _md = contract.md ? { md: contract.md.toJSON() } : {}; //? fix non-serializable redux
              const _incomesArr =
                contract.incomes?.map(
                  (item) =>
                    (item = {
                      ...item,
                      ...(item.date ? { date: dayjs(item.date)?.toJSON() || {} } : {}),
                      ...(item.date2 ? { date2: dayjs(item.date2)?.toJSON() || {} } : {}),
                      ...(item.created && dayjs.isDayjs(item.created) ? { created: item.created?.toJSON() || {} } : {}),
                    }),
                ) ?? [];
              const _incomes = contract.incomes ? { incomes: _incomesArr } : {}; //? fix non-serializable redux
              return { ...i, ...contract, client: [client], ..._sd, ..._md, ..._incomes };
            }
            return i;
          });
          dispatch(setContractsList(_contractsList));
        });
    }

    dispatch(
      setModal({
        name: CONFIRM_ADD_CONTRACT,
        data: { contract },
        hotline,
        prev: { name: NEW_CONTRACT, data: { ...contractForm, shortQuestionnaire: questionnaireData }, hotline },
      }),
    );
  };

  const handleIncomes = async () => {
    const preparedData = await shortQuestionnaireRef.current.prepareQuestionnaireData();

    dispatch(
      setModal({
        name: INCOMES,
        data: { contractForm: { ...contractForm, shortQuestionnaire: preparedData }, setContractForm },
        prev: { name: NEW_CONTRACT, data: { ...contractForm, shortQuestionnaire: preparedData }, hotline },
      }),
    );
  };

  const handleClientSelection = async () => {
    const preparedData = await shortQuestionnaireRef.current.prepareQuestionnaireData();

    dispatch(
      setModal({
        name: SEARCH_CLIENT,
        data: { hotline, contractForm: { ...contractForm, shortQuestionnaire: preparedData } },
        prev: { name: NEW_CONTRACT, data: { ...contractForm, shortQuestionnaire: preparedData } },
      }),
    );
  };

  const handleNewClient = async () => {
    const preparedData = await shortQuestionnaireRef.current.prepareQuestionnaireData();
    dispatch(
      setModal({
        name: NEW_CLIENT,
        data: { hotline, contractForm: { ...contractForm, shortQuestionnaire: preparedData } },
        prev: { name: NEW_CONTRACT, data: { ...contractForm, shortQuestionnaire: preparedData }, hotline },
        fromContracts,
      }),
    );
  };

  const onFind = () => {
    const transaction = { ph: phone || data?.hotline?.phone };
    request.post(
      '/clients/find',
      transaction,
      (req) => {
        if (!req.data || req.data?.countryPhCode !== countryPhCode) {
          dispatch(setCurrentClient(null));
          warn('', 'Номер телефону не знайдено');
        } else {
          dispatch(setCurrentClient(req.data));
        }
      },
      error,
    );
  };

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);
    setPhone(validPhoneNumber);
  };

  useEffect(() => {
    getSugarForClient();
  }, []);

  useEffect(() => {
    if (data?.hotline?.phone) {
      setPhone(data?.hotline?.phone);
      setCountryPhCode(data?.hotline?.countryPhCode);
    }
    onFind();
  }, []);

  useEffect(() => {
    setTotalPayByClient(countTotalPayByClient());
  }, [sugar]);

  if (!users) return null;

  return (
    <Modal
      open
      className="new-contract"
      title={contractForm.isNewContract ? 'Додаємо новий договір' : 'Редагуємо договір'}
      onCancel={onCancel}
      footer={null}
    >
      <Spin tip="Завантаження" spinning={loading || isLoading}>
        <Box>
          {contractForm.isNewContract ? (
            <>
              <div className="client-input-wrapper" style={{ gap: '16px' }}>
                <div className="phone-wrapper">
                  <div style={{ width: '80px' }}>
                    <Text>Код країни</Text>
                    <CountryPhCode value={countryPhCode} onChange={setCountryPhCode} />
                  </div>
                  <div>
                    <Text>Телефон</Text>
                    <Input aria-label="Телефон" value={phone} onChange={(e) => handlePhoneNumberInput(e)} placeholder="Введіть номер телефону" />
                  </div>
                </div>
                <Button className="find-client-btn" type="primary" onClick={onFind}>
                  Обрати клієнта
                </Button>
              </div>
              <div className="client-input-wrapper">
                <Box mt={16} className="client-label">
                  <Text strong className="row-space">
                    Клієнт:
                  </Text>
                  <Text> {client?.n || '-'} </Text>
                  {client?.ph && <CheckCircleOutlined style={{ fontSize: 20, fontWeight: 900, color: 'green' }} />}
                </Box>
                {client?.ph && (
                  <Button className="find-client-btn" type="primary" onClick={handleNewClient}>
                    Редагувати клієнта
                  </Button>
                )}
              </div>
            </>
          ) : (
            <>
              <Box mt={16} className="client-label">
                <Text strong className="row-space">
                  Клієнт:
                </Text>
                <Text> {client?.n || '-'} </Text>
                {client?.ph && <CheckCircleOutlined style={{ fontSize: 20, fontWeight: 900, color: 'green' }} />}
              </Box>
              <Box mt={16} className="group-button">
                <Button className="find-client-btn" type="primary" onClick={handleClientSelection}>
                  Обрати клієнта
                </Button>
                <Button className="find-client-btn" type="primary" onClick={handleNewClient}>
                  {hasSelectedClient ? 'Редагувати клієнта' : 'Додати нового клієнта'}
                </Button>
                <Button className="find-client-btn" type="primary" onClick={handleIncomes}>
                  {hasIncomes ? 'Редагувати доходи' : 'Додати доходи'}
                </Button>
              </Box>
            </>
          )}
        </Box>
        {hasSelectedClient && isClientSelfRegistered ? (
          <strong style={{ color: 'red', display: 'inline-block', marginTop: contractForm.isNewContract ? '' : '10px' }}>
            Клієнт реєструвався самостійно. Перевірте будь ласка, наявність необхідних полів, та оновіть дані за потреби.
          </strong>
        ) : null}
        <br />
        <div style={{ border: '1px solid red', padding: '10px', borderRadius: 7 }}>
          <Checkbox checked={contractForm.rb} onChange={(e) => setContractForm({ ...contractForm, rb: e.target.checked })}>
            Тривожна кнопка
          </Checkbox>
        </div>
        <br />
        <br />
        <Text>OB-1</Text>
        <Input aria-label="OB-1" value={contractForm.ov1} onChange={(e) => setContractForm({ ...contractForm, ov1: e.target.value })} />
        <br />
        <br />
        <Text>OB-2</Text>
        <Input aria-label="OB-2" value={contractForm.ov2} onChange={(e) => setContractForm({ ...contractForm, ov2: e.target.value })} />
        <br />
        <br />
        <Text>OB-3</Text>
        <Input aria-label="OB-3" value={contractForm.ov3} onChange={(e) => setContractForm({ ...contractForm, ov3: e.target.value })} />
        <br />
        <br />
        <Text>Дата підписання</Text>
        <DatePicker locale={locale} value={contractForm.sd} format="DD-MM-YYYY" onChange={(v) => setContractForm({ ...contractForm, sd: v })} />
        <br />
        <br />
        <Text>Філія</Text>
        <SearchSelect list={FILII} value={contractForm.f} onChange={(v) => setContractForm({ ...contractForm, f: v })} />
        <br />
        <br />
        <Text>Уклав</Text>
        <SearchSelect
          list={Object.values(users).map((i) => ({ ...i, label: i.p + ' ' + i.name }))}
          valueName="_id"
          labelName="label"
          value={contractForm.us}
          onChange={(v) => setContractForm({ ...contractForm, us: v })}
        />
        <br />
        <br />
        <Text>Хто буде обслуговувати?</Text>
        <SearchSelect list={SERVIS_OPTIONS} value={contractForm.un} onChange={(v) => setContractForm({ ...contractForm, un: v })} />
        <br />
        <br />
        <Text>Офіс/дист</Text>
        <SearchSelect list={['Офіс', 'Дист']} value={contractForm.od} onChange={(v) => setContractForm({ ...contractForm, od: v })} />
        <br />
        <br />
        <Text>Повторно</Text>
        <SearchSelect
          list={REPEATS}
          value={contractForm.tm}
          placeholder="Оберіть число"
          onChange={(v) => setContractForm({ ...contractForm, tm: v })}
        />
        <br />
        <br />
        <Text>Як дізнався / вид р</Text>
        <SearchSelect
          list={HOTLINE_ADV_WHERE}
          value={contractForm.aw}
          placeholder="Оберіть Як дізнався"
          onChange={(v) => setContractForm({ ...contractForm, aw: v })}
        />
        <br />
        <br />
        <Text>Як саме</Text>
        <SearchSelect
          list={HOTLINE_ADV_WHERE_MORE}
          value={contractForm.am}
          placeholder="Оберіть Що саме"
          onChange={(v) => setContractForm({ ...contractForm, am: v })}
        />
        <br />
        <br />
        <Text>№ ККАОЗ</Text>
        <Input aria-label="№ ККАОЗ" value={contractForm.kk} onChange={(e) => setContractForm({ ...contractForm, kk: e.target.value })} />
        <br />
        <br />
        <Text>Вид правовідносин:</Text>
        <SearchSelect
          list={PRAVO_TYPES}
          value={contractForm.pr}
          placeholder="Оберіть Вид правовідносин"
          onChange={(v) => setContractForm({ ...contractForm, pr: v })}
        />
        <br />
        <br />
        <Text>Що саме</Text>
        <SearchSelect
          list={PRAVO}
          value={contractForm.prc}
          placeholder="Оберіть Що саме"
          onChange={(v) => setContractForm({ ...contractForm, prc: v })}
        />
        <br />
        <br />
        <Text>Предмет договору</Text>
        <Input aria-label="Предмет договору" value={contractForm.ci} onChange={(e) => setContractForm({ ...contractForm, ci: e.target.value })} />
        <br />
        <br />
        <Text>Своїми словами</Text>
        <Input aria-label="Своїми словами" value={contractForm.cd} onChange={(e) => setContractForm({ ...contractForm, cd: e.target.value })} />
        <br />
        <br />
        Форма гонорару
        <SearchSelect
          list={PAYMENT_FORMAT}
          value={contractForm.pf}
          placeholder="Оберіть Форму гонорару"
          onChange={(v) => setContractForm({ ...contractForm, pf: v })}
        />
        <ShortQuestionnaireComponent data={contractForm} ref={shortQuestionnaireRef} />
        <Divider orientation="left" plain>
          <b>Менеджмент платежів</b>
        </Divider>
        <PaymentManagementForm
          form={contractForm}
          setForm={setContractForm}
          contract={currentContract}
          setLoading={setIsLoading}
          isEditContractModal={true}
        />
        <PaymentManagementComponent
          totalPayByClient={totalPayByClient}
          paymentManagement={paymentManagement}
          setPaymentManagement={setPaymentManagement}
          monthlyPayments={monthlyPayments}
          setMonthlyPayments={setMonthlyPayments}
          totalInstallment={totalInstallment}
          setTotalInstallment={setTotalInstallment}
          paymentManagementForm={contractForm}
          contract={currentContract}
          isNewContract={contractForm.isNewContract}
          cancelEdit={cancelEditPaymentManagement}
          setLoading={setIsLoading}
          setRemainingMoney={() => {}}
          currentCurrency={currentCurrency}
        />
        <Divider />
        <Button type="primary" className="submit" onClick={onSubmit}>
          {contractForm.isNewContract ? 'Додати' : 'Змінити'} договір
        </Button>
      </Spin>
    </Modal>
  );
};

export default NewContract;
