import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setOnlineUsers } from '../store/commonReducer';

const useListenOnlineUsers = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.common.socket);

  useEffect(() => {
    if (!socket) return;

    socket.on('getOnlineUsers', (data) => {
      dispatch(setOnlineUsers(data));
    });

    return () => socket?.off('getOnlineUsers');
  }, [socket]);
};

export default useListenOnlineUsers;
