import { Alert } from 'antd';
import React from 'react';

export default function TipAlert({ tip }) {
  if (!tip) return null;
  return (
    <Alert
      message="Оберіть вид і тип консультації"
      description={<>Слухаємо питання з метою визначення виду й типу консультації (за потреби тактично перебиваємо клієнта).</>}
      type="info"
      showIcon
    />
  );
}
