import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeftOutlined, MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { CHAT_TYPES } from 'common_constants/business';
import { CHAT_GROUP_INFO } from 'common_constants/modals';
import { Button, Popover } from 'antd';

import ChatMessagesComponent from '../../components/Chat';
import { request, requestFile, error } from '../../tools';
import { setModal } from '../../store/commonReducer';
import { getChatName, getChatShortName } from './helpers';
import ChatSearchMessages from './ChatSearchMessages';
import ChatGroupMoreContentPopover from './ChatGroupMoreContentPopover';
import PinnedMessages from './PinnedMessages';

const ChatMessages = ({ onChangeCurrentChatId, chatType, currentChatId, setReadedMessages }) => {
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);
  const chatsData = useSelector((state) => state.common.chatsData);
  const socket = useSelector((state) => state.common.socket);
  const onlineUsers = useSelector((state) => state.common.onlineUsers);

  const currentChat = useMemo(() => chatsData?.[chatType]?.find((item) => item._id === currentChatId) ?? {}, [chatsData, currentChatId]);
  const activeUsers = useMemo(() => Object.values(users || {}).filter((user) => user._id !== userAuth?._id), [users]);
  const isContractChat = currentChat.type === CHAT_TYPES.contractChat.key;
  const [countGroupOnlineUsers, setCountGroupOnlineUsers] = useState(0);
  const [isChatSearchMessagesOpen, setIsChatSearchMessagesOpen] = useState(false);
  const [fetchedMessageIndex, setFetchedMessageIndex] = useState(null);
  const [pinnedMessageScrollId, setPinnedMessageScrollId] = useState(null);

  const pinnedMessages = currentChat?.pinnedMessages;

  const onSendMessage = (params = {}) => {
    if (!params.message) return error('Пусте повідомлення');
    if (typeof params.callback !== 'function') return error('Не вказана функція callback');

    const messageData = {
      chatId: currentChat._id,
      message: params.message,
      type: currentChat.type,
      fromSocketId: socket?.id,
    };

    request.post('/chatPrivate/chatSendMessage', messageData, params.callback, error);
  };

  const onSendFile = (params = {}) => {
    const { formData, callback, onError } = params;

    if (!formData) return error('Відсутні даги для відправки');
    if (typeof callback !== 'function') return error('Не вказана функція callback');

    formData.append('chatId', currentChat._id);
    formData.append('type', currentChat?.type);
    formData.append('fromSocketId', socket?.id);

    request.post('/chatPrivate/chatSendFiles', formData, callback, onError);
  };

  const onGetFile = (params = {}) => {
    const { fileId, fileName, callback, onError } = params;

    if (!fileId) return error('Відсутній ID файлу');
    if (!currentChat) return error('Відсутній чат!');

    requestFile('/chatPrivate/chatGetFile', { fileId, fileName, chatId: currentChat._id, type: currentChat?.type }, callback, onError);
  };

  const onLoadMoreMessages = (offset, callback) => {
    if (chatType === CHAT_TYPES.accountantChat.key) {
      request.post(
        '/accountantChat/getChatsForCRM',
        { chatId: currentChatId, offset: offset },
        ({ data }) => {
          callback({
            messages:
              data[0]?.messages?.map((item) => ({
                ...item,
                chatType: chatType,
                isSentByCurrentUser: item.sender === userAuth._id,
                isSendByClient: item.fromUser,
                name: item.fromUser ? data[0]?.clientInfo?.[0]?.n : users[item.sender]?.name || 'Без імені',
              })) ?? [],
            messagesCount: data[0]?.messagesCount,
          });
        },
        error,
      );

      return;
    }

    request.post(
      '/chatPrivate/getChat',
      { chatId: currentChatId, type: chatType, offset: offset },
      ({ data }) => {
        callback({
          messages:
            data?.messages?.map((item) => ({
              ...item,
              isSentByCurrentUser: item.sender === userAuth._id,
              isSendByClient: item.sender === data?.clientInfo?.[0]?._id,
              name:
                (data.type === CHAT_TYPES.chatWithClient.key ||
                  data.type === CHAT_TYPES.redButtonChat.key ||
                  data.type === CHAT_TYPES.personalConsultantChat.key) &&
                item.sender === data?.clientInfo?.[0]?._id
                  ? data?.clientInfo?.[0]?.n
                  : users[item.sender]?.name || 'Без імені',
            })) ?? [],
          messagesCount: data?.messagesCount,
        });
      },
      error,
    );
  };

  let onGetMessages = useCallback(
    (callback) => {
      if (typeof callback !== 'function') return error('Не вказана функція callback');
      if (!currentChat) return error('Відсутній чат!');

      callback({
        messages:
          currentChat?.messages?.map((item) => ({
            ...item,
            isSentByCurrentUser: item.sender === userAuth._id,
            isSendByClient: item.sender === currentChat?.clientInfo?.[0]?._id || item.fromUser,
            name:
              ([
                CHAT_TYPES.chatWithClient.key,
                CHAT_TYPES.redButtonChat.key,
                CHAT_TYPES.accountantChat.key,
                CHAT_TYPES.personalConsultantChat.key,
              ].includes(currentChat.type) &&
                item.sender === currentChat?.clientInfo?.[0]?._id) ||
              item.fromUser
                ? currentChat?.clientInfo?.[0]?.n
                : users[item.sender]?.name || 'Без імені',
          })) ?? [],
        messagesCount: currentChat?.messagesCount,
      });
      return;
    },
    [currentChat],
  );

  const onEditMessage = (params = {}) => {
    if (!params.message) return error('Пусте повідомлення');
    if (typeof params.callback !== 'function') return error('Не вказана функція callback');

    const requestData = {
      chatId: currentChatId,
      type: chatType,
      messageId: params.messageId,
      newMessageText: params.message,
    };

    request.post(
      '/chatPrivate/editMessage',
      requestData,
      (res) => {
        params.callback();
      },
      error,
    );

    return;
  };

  const countOnlineUsersForGroup = () => {
    const count = currentChat.users?.filter((user) => onlineUsers?.includes(user))?.length ?? 0;

    setCountGroupOnlineUsers(count);
  };

  const onClickHeaderArrowButton = () => {
    onChangeCurrentChatId(null);
  };

  const openChatGroupInfo = () => {
    if (currentChat?.isGroup) {
      dispatch(setModal({ name: CHAT_GROUP_INFO, data: { chatId: currentChat._id } }));
    }
  };

  const handleOpenSearchMessages = () => {
    setIsChatSearchMessagesOpen(true);
  };

  const handleClickPinnedMessage = () => {
    const lastPinnedMessage = pinnedMessages?.[0];

    setPinnedMessageScrollId(lastPinnedMessage._id);
  };

  useEffect(() => {
    if (!currentChat?._id || !currentChat?.messages || currentChat?.messages?.length === 0) return;

    setReadedMessages();
  }, [currentChat?.messages]);

  useEffect(() => {
    if (!currentChat?.isGroup) return;

    countOnlineUsersForGroup();
  }, [onlineUsers, currentChat?.isGroup]);

  useEffect(() => {
    if (pinnedMessageScrollId) {
      const timeout = setTimeout(() => {
        setPinnedMessageScrollId(null);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [pinnedMessageScrollId]);

  return (
    <>
      {currentChat?._id ? (
        <>
          <div className="header">
            <div className="header-left">
              <Button className="arrow-btn" type="link" onClick={onClickHeaderArrowButton}>
                <ArrowLeftOutlined />
              </Button>
              <div onClick={openChatGroupInfo} className="header-interlocutor">
                <p className="chat-name">
                  {isChatSearchMessagesOpen ? getChatShortName(currentChat, activeUsers) : getChatName(currentChat, activeUsers)}
                </p>
                {currentChat?.isGroup && (
                  <p>
                    {currentChat.users?.length < 5 ? currentChat.users?.length + ' учасники' : currentChat.users?.length + ' учасників'}
                    {countGroupOnlineUsers > 1 ? `, ${countGroupOnlineUsers} в мережі` : ''}
                  </p>
                )}
              </div>
            </div>
            {isContractChat && isChatSearchMessagesOpen ? (
              <ChatSearchMessages
                setIsChatSearchMessagesOpen={setIsChatSearchMessagesOpen}
                currentChatId={currentChatId}
                setFetchedMessageIndex={setFetchedMessageIndex}
              />
            ) : null}
            {!isChatSearchMessagesOpen ? (
              <div className="">
                {isContractChat ? (
                  <div className="header-more-btn-wrapper">
                    <Button type="text" className="header-btn" size="large" onClick={handleOpenSearchMessages}>
                      <SearchOutlined />
                    </Button>
                  </div>
                ) : null}
                {currentChat.isGroup && (
                  <div className="header-more-btn-wrapper">
                    <Popover content={<ChatGroupMoreContentPopover currentChat={currentChat} />} trigger="click" showArrow={false}>
                      <Button type="text" size="large" className="header-btn">
                        <MoreOutlined />
                      </Button>
                    </Popover>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {pinnedMessages?.length > 0 ? (
            <PinnedMessages pinnedMessages={pinnedMessages} currentChatId={currentChatId} chatType={chatType} onClick={handleClickPinnedMessage} />
          ) : null}
          <ChatMessagesComponent
            onSendMessage={onSendMessage}
            onEditMessage={onEditMessage}
            onSendFile={onSendFile}
            onGetMessages={onGetMessages}
            onLoadMoreMessages={onLoadMoreMessages}
            onGetFile={onGetFile}
            chatType={chatType}
            chatId={currentChatId}
            fetchedMessageIndex={fetchedMessageIndex}
            pinnedMessageScrollId={pinnedMessageScrollId}
          />
        </>
      ) : (
        <div className="empty-wrapper">
          <span>Виберіть чат, щоб почати спілкування</span>
        </div>
      )}
    </>
  );
};

export default ChatMessages;
