import React from 'react';
import { Card, Button, Switch, Radio } from 'antd';
import { DownOutlined, RightOutlined, SmallDashOutlined, SortDescendingOutlined, SortAscendingOutlined } from '@ant-design/icons';

const ContractsFilterByEAdvokatChanges = ({
  isFiltersByEAdvokatChangesOpen,
  setIsFiltersByEAdvokatChangesOpen,
  setFiltersByEAdvokatChanges,
  sortingEAdvokatChanges,
  setSortingEAdvokatChanges,
  searchByEAdvokatChanges,
}) => {
  return (
    <Card className="filtersContainer">
      <div className="row align no_selection filters" onClick={() => setIsFiltersByEAdvokatChangesOpen((prev) => !prev)}>
        {isFiltersByEAdvokatChangesOpen ? <DownOutlined /> : <RightOutlined />} <h2>Пошук за наявністю змін даних клієнтів в додатку "єАдвокат":</h2>
      </div>
      {isFiltersByEAdvokatChangesOpen ? (
        <>
          <div className="row row-filters-wrapper">
            <div className="filters-switch-wrapper">
              <Switch
                style={{ width: 200 }}
                onChange={(v) => setFiltersByEAdvokatChanges((prev) => ({ ...prev, name: v }))}
                checkedChildren="ПІБ"
                unCheckedChildren="ПІБ"
              />
              <Switch
                style={{ width: 200 }}
                onChange={(v) => setFiltersByEAdvokatChanges((prev) => ({ ...prev, email: v }))}
                checkedChildren="Пошти"
                unCheckedChildren="Пошти"
              />
              <Switch
                style={{ width: 200 }}
                onChange={(v) => setFiltersByEAdvokatChanges((prev) => ({ ...prev, phone: v }))}
                checkedChildren="Телефону"
                unCheckedChildren="Телефону"
              />
            </div>
            <div className="sortings-wrapper">
              <h3>Сортування по даті змін:</h3>
              <div className="">
                <h4>ПІБ:</h4>
                <Radio.Group
                  size="small"
                  onChange={({ target: { value } }) => {
                    setSortingEAdvokatChanges((prev) => ({ ...prev, name: value }));
                  }}
                  value={sortingEAdvokatChanges.name}
                >
                  <Radio.Button value={0}>
                    <SmallDashOutlined />
                  </Radio.Button>
                  <Radio.Button value={-1}>
                    <SortDescendingOutlined />
                  </Radio.Button>
                  <Radio.Button value={1}>
                    <SortAscendingOutlined />
                  </Radio.Button>
                </Radio.Group>
              </div>
              <div className="">
                <h4>Пошти:</h4>
                <Radio.Group
                  size="small"
                  onChange={({ target: { value } }) => {
                    setSortingEAdvokatChanges((prev) => ({ ...prev, email: value }));
                  }}
                  value={sortingEAdvokatChanges.email}
                >
                  <Radio.Button value={0}>
                    <SmallDashOutlined />
                  </Radio.Button>
                  <Radio.Button value={-1}>
                    <SortDescendingOutlined />
                  </Radio.Button>
                  <Radio.Button value={1}>
                    <SortAscendingOutlined />
                  </Radio.Button>
                </Radio.Group>
              </div>
              <div className="">
                <h4>Телефону:</h4>
                <Radio.Group
                  size="small"
                  onChange={({ target: { value } }) => {
                    setSortingEAdvokatChanges((prev) => ({ ...prev, phone: value }));
                  }}
                  value={sortingEAdvokatChanges.phone}
                >
                  <Radio.Button value={0}>
                    <SmallDashOutlined />
                  </Radio.Button>
                  <Radio.Button value={-1}>
                    <SortDescendingOutlined />
                  </Radio.Button>
                  <Radio.Button value={1}>
                    <SortAscendingOutlined />
                  </Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </div>
          <Button type="primary" style={{ marginLeft: 16, marginBottom: 16 }} onClick={searchByEAdvokatChanges}>
            Пошук
          </Button>
        </>
      ) : null}
    </Card>
  );
};

export default ContractsFilterByEAdvokatChanges;
