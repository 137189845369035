import { FundOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import { VOTING_FOR_ARCHIVING } from 'common_constants/modals';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../../../store/commonReducer';

const VotingButton = ({ contract, correspondence }) => {
  const dispatch = useDispatch();
  const handleClickButton = () =>
    dispatch(
      setModal({
        name: VOTING_FOR_ARCHIVING,
        data: {
          contract,
          correspondence,
        },
      }),
    );
  return (
    <Button type="primary" icon={<FundOutlined />} onClick={handleClickButton}>
      Голосування за архівацію
    </Button>
  );
};

export default VotingButton;
