import React from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import openai from '../../images/openai.svg';
import { selectPlaceholder } from '../../../../store/aiReducer';

const StartPage = ({ selectedDialogueId }) => {
  const placeholder = useSelector(selectPlaceholder);

  if (selectedDialogueId || placeholder.status) return null;

  return (
    <Row className="startPage">
      <img src={openai} alt="openai" />
      <Row className="title">Вітаю!</Row>
      <Row className="info">Чим я можу допомогти?</Row>
    </Row>
  );
};

export default StartPage;
