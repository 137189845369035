import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Spin, Modal, DatePicker, Typography } from 'antd';
import { CSVLink } from 'react-csv';
import { useResize } from 'common_components';
import { SERVICE_PAYMENT_TYPE } from 'common_constants/business';

import { setModal } from '../../store/commonReducer';
import Excel from '../../images/excel_logo.svg';
import { request, error, success } from '../../tools';
import Box from '../../components/Box';

import dayjs from 'dayjs';
import locale from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';

const ExportClientsCosts = () => {
  const { Text } = Typography;
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const resize = useResize();
  const [data, setData] = useState();
  const [exportRange, setExportRange] = useState(null);

  const onGenerate = () => {
    setLoading(true);

    request.post(
      '/spreadsheets/exportClientsCosts',
      { exportRange },
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };

  const csvData = [['Номер транзакції', 'Імя', 'Телефон', 'Сума', 'Тип', 'Дата', 'Куратор', 'Філія']];

  data?.forEach((item) => {
    csvData.push([
      item.transactions.i,
      item.n,
      item.ph,
      item.transactions.amount,
      SERVICE_PAYMENT_TYPE[item.transactions.type]?.label || '',
      item.transactions.date && dayjs(item.transactions.date).format('DD.MM.YYYY HH:mm'),
      item.curator,
      item.fil,
    ]);
  });

  return (
    <Modal open className="export-hotline" title="Експорт витрат клієнтів" onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="change">
          <Box className={resize[0] >= 500 ? 'exp-filter-box-pc' : 'exp-filter-box-mob'}>
            <RangePicker locale={locale} className="exp-filter" onChange={(e) => setExportRange(e)} format="DD.MM.YYYY" />
            <Text className="exp-filter-box-text"> Дата звітування: </Text>
          </Box>
          <br />
          {data ? (
            <CSVLink asyncOnClick={true} data={csvData}>
              <Button className="excel">
                <img className="excel-img" src={Excel} alt="Excel" /> Скачати .csv
              </Button>
            </CSVLink>
          ) : (
            <Button onClick={onGenerate}>Генерувати</Button>
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

export default ExportClientsCosts;
