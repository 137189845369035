import { Modal, Input, Select, Button, Spin, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModal, setClientsList } from '../../store/commonReducer';
import { useState, useEffect, useRef } from 'react';
import { request, success, warn, error } from '../../tools';
import SelectAssignment from '../../components/SelectAssignment';
import dayjs from 'dayjs';
import TransactionTypeSearchSelect from './TransactionTypeSearchSelect';

import './ClientBalanceTransaction.scss';

const ClientBalanceTransaction = () => {
  const dispatch = useDispatch();
  const { data, prev } = useSelector((state) => state.common.modal);
  const clients = useSelector((state) => state.common.clients) || [];
  const [contractId, setContractId] = useState('');
  const [withdrawBalance, setWithdrawBalance] = useState(0);
  const [useBonus, setUseBonus] = useState(false);
  const [transactionType, setTransactionType] = useState();
  const [clientContracts, setClientContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assignmentData, setAssignmentData] = useState({});
  const [localAssignmentsList, setLocalAssignmentsList] = useState([]);
  const lastFetchedContract = useRef();

  const bonusPercentage = 0.3;
  const bonusAmount = useBonus ? Math.floor(parseInt(withdrawBalance) * bonusPercentage) : 0;

  useEffect(() => {
    const onSuccess = (res) => {
      setClientContracts(res.data || []);
    };

    const onError = () => {
      error('', 'Не вдалось завантажити список договорів клієнта');
    };

    request.post('/contracts/get', { ph: data.phone, searchByClient: true }, onSuccess, onError);
  }, []);

  useEffect(() => {
    if (transactionType === 2 && contractId && contractId !== lastFetchedContract.current) {
      setLoading(true);
      request.post('/assignment/getForContract', { contractIdFilter: contractId, noPagination: true }, ({ assignments }) => {
        setLocalAssignmentsList(assignments);
        setLoading(false);
        lastFetchedContract.current = contractId;
      });
      setAssignmentData({});
    }
  }, [transactionType, contractId]);

  const handleChangeContract = (value) => setContractId(value);

  const handleWithdrawBalance = () => {
    setLoading(true);

    const validation = (withdrawBalance, contractId) => {
      if (!contractId) {
        warn('', 'Виберіть договір', { '!contractId': !contractId });
        return;
      }

      if (!transactionType && transactionType !== 0) {
        warn('', 'Оберіть призначення платежу.', { '!transactionType': !transactionType });
        return;
      }

      if (!withdrawBalance || parseInt(withdrawBalance) === 0) {
        warn('', 'Заповніть поле "Сума списання".', { '!withdrawBalance': !withdrawBalance });
        return;
      }

      if (transactionType === 2 && (!assignmentData.assignmentId || !assignmentData.assignmentIndex)) {
        warn('', 'Виберіть вихід.', { '!assignmentData': !assignmentData });
        return;
      }
      return true;
    };

    const onSuccess = (res) => {
      const updatedClients = clients.map((i) =>
        i._id === data.C
          ? {
              ...i,
              clientBalance: res.clientBalance,
              transactions: [...i.transactions, res.transaction],
              B: res.B,
            }
          : i,
      );

      dispatch(setClientsList(updatedClients));
      dispatch(setModal());
      success('', 'Виконано успішно!');
      setLoading(false);
    };

    const onError = () => {
      setLoading(false);
      error('', 'Недостатньо коштів або бонусів на балансі клієнта.');
    };

    if (!validation(withdrawBalance, contractId)) {
      setLoading(false);
      return;
    }

    const body = {
      id: data.C,
      withdrawSum: parseInt(withdrawBalance),
      contractId,
      useBonus,
      assignmentId: assignmentData.assignmentId,
      assignmentIndex: assignmentData.assignmentIndex,
      transactionType,
    };

    request.post('/clients/withdrawClientBalance', body, onSuccess, onError);
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal open className="client-balance-transaction" title="Оплата гонорару за договором" onCancel={() => dispatch(setModal(prev))} footer={null}>
      <Spin spinning={loading}>
        <div>
          <b>Баланс клієнта: </b>
          {data?.clientBalance?.toFixed(2) || 0}
        </div>
        <br />
        <div>
          <b>Сума списання: </b>
          <Input onKeyDown={handleKeyPress} onChange={(e) => setWithdrawBalance(Math.abs(e.target.value).toFixed(0))} type="number" min={1} value={withdrawBalance} />
        </div>
        <br />
        <div>
          <b>Номер договору: </b>
          <Select
            style={{
              width: '100%',
            }}
            value={contractId || ''}
            onChange={handleChangeContract}
            options={clientContracts.map((contract) => ({
              value: contract._id,
              label: `${contract.i} / ${dayjs(contract.sd).format('DD.MM.YYYY')} ${contract.cn ? '/ ' + contract.cn : ''}`,
            }))}
            rules={[{ required: true }]}
          />
        </div>
        <TransactionTypeSearchSelect value={transactionType} onChange={(val) => setTransactionType(val)} />
        <br />
        {transactionType === 2 && (
          <div className="sugar-assignment">
            <SelectAssignment formData={assignmentData} setFormData={setAssignmentData} assignmentsList={localAssignmentsList} />
          </div>
        )}
        <br />
        <Checkbox onChange={(e) => setUseBonus(e.target.checked)}>Оплата бонусами</Checkbox>
        {useBonus && (
          <div>
            <p>
              Буде використано: {(withdrawBalance * 0.7).toFixed(2)} грн з балансу, залишок {(data.clientBalance - withdrawBalance * 0.7).toFixed(2)}{' '}
              грн
            </p>
            <p>
              Буде використано: {bonusAmount.toFixed(2)} грн бонусів, залишок {(data.B - bonusAmount).toFixed(2)} грн
            </p>
          </div>
        )}
        <br />
        <Button onClick={handleWithdrawBalance} type="primary" className="submit">
          Додати транзакцію
        </Button>
      </Spin>
    </Modal>
  );
};

export default ClientBalanceTransaction;
