import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Input, Pagination, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllTweets, setModal, deleteTweetItem, toggleFavoriteTweets } from '../../../store/commonReducer';
import { ADD_TWEET } from 'common_constants/modals';
import { error, request, success } from '../../../tools';
import HomeTwitterItem from './HomeTwitterItem';

const DEFAULT_PAGE_SIZE = 10;

const HomeTwitter = ({ requestType, activeMainTab }) => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.common.users);
  const tweets = useSelector((state) => state.common.allTweets || []);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { Text } = Typography;

  const getAllTweets = (limits) => {
    setLoading(true);

    const params = {
      ...limits,
      message: searchValue,
    };

    const endpoint = requestType === 'userTweets' ? '/twitter/getUserTweets' : '/twitter/getAllTweets';

    request.post(
      endpoint,
      params,
      ({ tweets, total }) => {
        dispatch(setAllTweets(tweets));
        setPageSize(pageSize);
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    const limits = {
      page: currentPage,
      pageSize,
    };
    getAllTweets(limits);
  }, [currentPage, pageSize, searchValue, activeMainTab]);

  const onChangeSearchInput = (e) => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteTweet = (id) => {
    const onSuccess = () => {
      dispatch(deleteTweetItem(id));
      success('Успіх!', 'Ваш твіт видалений успішно!');
    };
    const onError = () => error('Помилка!', 'Схоже, не вдалося видалити Ваш твіт!');

    request.delete('/twitter/', id, onSuccess, onError);
  };

  const editTweet = (id) => {
    const tweetToEdit = tweets.find((item) => item._id === id);
    dispatch(setModal({ name: ADD_TWEET, editItem: tweetToEdit }));
  };

  const addToFavorites = (id) => {
    const onSuccess = ({ isFavorite }) => {
      const updatedTweets = tweets.map((tweet) => {
        if (tweet._id === id) {
          return { ...tweet, isFavorite };
        }
        return tweet;
      });
      dispatch(toggleFavoriteTweets({ tweet: updatedTweets.find((tweet) => tweet._id === id), isFavorite }));
      // success('Успіх!', isFavorite ? 'Твіт додано до улюблених!' : 'Твіт видалено з улюблених!');
    };

    const onError = () => error('Помилка!', 'Не вдалося оновити улюблені твіти!');

    request.post(`/twitter/addToFavorites/${id}`, {}, onSuccess, onError);
  };

  if (!users) return null;

  return (
    <div>
      <Input placeholder="Пошук в записах за ключовим словом..." value={searchValue} onChange={onChangeSearchInput} />
      <Row style={{ justifyContent: 'center', marginTop: 18 }}>
        <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_TWEET }))}>
          <PlusCircleOutlined /> Створити твіт
        </Button>
      </Row>
      <br />
      <Spin spinning={loading} tip="Зачекайте" size="large">
        <Col>
          {tweets?.length ? (
            tweets.map((item) => (
              <Col key={item._id}>
                <HomeTwitterItem
                  item={item}
                  deleteTweet={deleteTweet}
                  editTweet={() => editTweet(item._id)}
                  addToFavorites={() => addToFavorites(item._id)}
                />
              </Col>
            ))
          ) : (
            <p className="filii_page__filtered-filii-not-found">
              <Empty description={<Text>Немає публікацій</Text>} />
            </p>
          )}
        </Col>
        <br />
        {total > 0 && (
          <Pagination style={{ textAlign: 'center' }} current={currentPage} pageSize={pageSize} total={total} onChange={handlePageChange} />
        )}
      </Spin>
    </div>
  );
};

export default HomeTwitter;
