import React from 'react';
import { Card, Popconfirm } from 'antd';
import { EditOutlined, DeleteFilled, CloseSquareOutlined } from '@ant-design/icons';

const MfoItem = ({ item, deleteMfo, editMfo }) => {

  const onClickEditButtonHandle = () => {
    editMfo(item._id);
  };

  const onClickDeleteButtonHandle = async () => {
    await deleteMfo(item._id);
  };

  return (
    <Card
      className="fil-item"
      actions={
        [
          <EditOutlined key="edit" onClick={onClickEditButtonHandle} />,
          <Popconfirm
            title="Видалення МФО"
            description="Ви дійсно хочете видалити данy МФО?"
            onConfirm={onClickDeleteButtonHandle}
            okText="Так"
            cancelText="Ні"
          >
            <DeleteFilled key="delete" />
          </Popconfirm>,
        ]
      }
    >
      <Card.Meta title={'№' + item.i + ' ' + item.name} />
      {item.isDisabled && (
        <div className="fil-item__disabled">
          <CloseSquareOutlined style={{ color: 'red' }} />
          Відключено
        </div>
      )}
      <p>
        <span>Повна назва:</span> {item.fullName}
      </p>
      <div>
        <span>Email:</span> {item.email}
      </div>
      <div>
        <span>Фізична адреса:</span> {item.address}
      </div>
      <div>
        <span>Реквізити:</span> {item.requisites}
      </div>
    </Card>
  );
};

export default MfoItem;
