import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ExportQuantityModal from '../../components/ExportQuantityModal';
import {
  PRAVO,
  DECIMAL_RATING_TO_CLIENT,
  LOGICAL_STARTED_ON_TIME_CHOISE_3_OPTIONS,
  LOGICAL_STANDARD_CHOISE_2_OPTIONS,
  LOGICAL_WOULD_YOU_AGREE_CHOISE_3_OPTIONS,
} from 'common_constants/business';

import { request, error, success, htmlDecode } from '../../tools';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';

const ExportHotlineQuestionnaires = () => {
  const users = useSelector((state) => state.common.users);
  const hotlineMaxIndex = useSelector((state) => state.common.hotlineMaxIndex);
  const FILII = useSelector((state) => state.common.FILII);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const fixValueForSlider = (value, date) => {
    const date1 = dayjs('01.10.2024', { format: 'MM.DD.YYYY' });
    const date2 = dayjs(date);
    const dateFix = date2 ? date1.isAfter(date2, 'day') : false;
    //? фіксить неточні дані в базі даних, коли було змінено з селекту на повзунок

    const result = DECIMAL_RATING_TO_CLIENT[dateFix ? value : value - 1] ?? '-';

    return result;
  };

  const max = hotlineMaxIndex;
  const [startI, setStartI] = useState(1);
  const [endI, setEndI] = useState(max);

  const onGenerate = () => {
    setLoading(true);

    const transaction = {
      startI,
      endI,
    };

    request.post(
      '/spreadsheets/exportHotlineQuestionnaires',
      transaction,
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };

  const csvData = [
    [
      '№',
      'ID ПІБ Клієнта',
      'Номер телефону',
      'Email',
      'Вид консультації',
      'Очна/дист',
      'Сума в договорі',
      'Названа сума на консультації',
      'VIP',
      'Коментар',
      'Філія',
      'Хто проводив',
      'Дата консультації',
      //
      'Дата анкетування',
      'Філія за договором',
      'Якість консультації',
      'Привітність персоналу',
      'Вчасно розпочалася',
      'Влаштувала ціна',
      'Зрозумілою консультація',
      'Зрозумілою мовою',
      'Розповіли про додаток «єАдвокат»',
      'Уклали договір',
      'Питання чи пропозиці',
    ],
  ];

  data?.forEach((i) => {
    const client = i.client?.[0] ?? {};
    const contract = i.contract?.[0] ?? {};

    csvData.push([
      i.i, // * '№',
      `${i?.name ?? client?.n ?? '<без імені>'} ${FILII[i.fil] ?? ''} ${client?.od === 1 ? 'Дист' : 'Офіс'} ${client?.ov1 ?? ''} ${
        client?.ov2 ?? ''
      } ${client?.ov3 ?? ''}`, // * 'ID ПІБ Клієнта'
      i?.phone ?? client?.ph ?? '', // * 'Номер телефону'
      client?.email ?? '', // * 'Email'

      PRAVO[i.pravo] ?? '-', // * 'Вид консультації',
      i.type === '3' ? 'Дист' : 'Очна', // * 'Вид',
      contract.fp ?? '-', // * 'Сума в договорі',
      i.cost ?? '-', // * 'Названа сума на консультації',
      i.vip ? 'Так' : 'Ні', // * 'VIP',
      i.comment ?? '-', // * 'Коментар',
      FILII[i.fil] ?? '', // * Філія
      users[i.amm]?.p ?? '-', // * 'Хто проводив',
      dayjs(i.date)?.format('DD.MM.YYYY') ?? '-', // * 'Дата консультації',

      // Анкета //
      dayjs(i.qh?.T)?.format('DD.MM.YYYY'), // Дата анкетування
      FILII[contract.f] ?? '-', //* Філія за договором
      fixValueForSlider(i.qh?.qc, i.qh?.T), // * Якість консультації
      fixValueForSlider(i.qh?.qs, i.qh?.T), // * Привітність персоналу
      LOGICAL_STARTED_ON_TIME_CHOISE_3_OPTIONS[i.qh?.qk] ?? '-', // * Вчасно розпочалася
      LOGICAL_STANDARD_CHOISE_2_OPTIONS[i.qh?.qp] ?? '-', // * Влаштувала ціна
      LOGICAL_STANDARD_CHOISE_2_OPTIONS[i.qh?.qa] ?? '-', // * Зрозумілою консультація
      LOGICAL_STANDARD_CHOISE_2_OPTIONS[i.qh?.ql] ?? '-', // * Зрозумілою мовою
      LOGICAL_STANDARD_CHOISE_2_OPTIONS[i.qh?.qv] ?? '-', // * Запропонували клубну карту
      LOGICAL_WOULD_YOU_AGREE_CHOISE_3_OPTIONS[i.qh?.qd] ?? '-', // * Уклали договір
      i.qh?.qt ? htmlDecode(i.qh?.qt) : '-', // * Питання чи пропозиці
    ]);
  });

  return (
    <ExportQuantityModal
      title={'Експорт анкет після консультацій'}
      csvData={csvData}
      onGenerate={onGenerate}
      max={max}
      data={!!data}
      loading={loading}
      startA={startI}
      setStartA={setStartI}
      endA={endI}
      setEndA={setEndI}
    />
  );
};

export default ExportHotlineQuestionnaires;
