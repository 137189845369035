import React, { useEffect, useState } from 'react';
import SearchSelect from './SearchSelect';
import { error, request } from '../../tools';

const SearchSelectHotlines = ({ onChange, value, disabled, className, style, date, fil, selectedFil }) => {
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);

  const getList = async (date, fil) => {
    if (!date) return;

    const dateString = date?.toISOString();
    setLoading(true);

    const transaction = {
      date: dateString,
      fil: selectedFil || fil,
    };

    await request.post(
      '/hotline/getAll',
      transaction,
      (req) => {
        setValues(req.result);
      },
      error,
    );

    setLoading(false);
  };

  useEffect(() => {
    setValues([]);
    getList(date, fil);
  }, [date, fil, selectedFil]);

  return (
    <SearchSelect
      className={className}
      style={style}
      disabled={disabled || loading}
      placeholder="Оберіть консультацію"
      list={values.map((hotline) => ({ value: hotline._id + '', label: `${hotline.startT}-${hotline.endT} ${hotline.name}` }))}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
      loading={loading}
    />
  );
};

export default SearchSelectHotlines;
