import React, { useEffect } from 'react';
import { Col, List, Card, Row, DatePicker, Spin, Pagination, Button } from 'antd';
import ExpensesItem from './ExpensesItem';
import { pageSizeOptions } from '../../Helpers';

import './Expenses.scss';
import { CSVLink } from 'react-csv';

const Expenses = ({ type, state, currency, handleSetApproved, pageSize, onChangePageSize, handleDateChange, onChangePage }) => {
  const onShowSizeChange = (current, pageSize) => {
    onChangePageSize(current, pageSize);
  };

  const totalAmountLabel = state.expensesTotalAmount.toLocaleString('uk-UA');
  const csvData = state.expensesData.map((item) => {
    return {
      Дата: item.date,
      Сума: item.amount,
      Категорія: item.category,
      Підкатегорія: item.subcategory,
      Перевірено: item.approved ? 'Так' : 'Ні',
    };
  });

  return (
    <Spin spinning={state.expensesLoading} tip="Завантаження...">
      <Col className="walletDetails">
        <Row justify={'space-between'} align={'middle'} style={{ margin: '0 0 16px 0' }}>
          <Row>
            <DatePicker placeholder={'місяць'} picker="month" onChange={handleDateChange} value={state.expensesSelectedDate} />
          </Row>
          <Row>
            {totalAmountLabel} {currency}.
          </Row>
          <Row>
            <CSVLink asyncOnClick={true} data={csvData}>
              <Button>Експорт - {csvData.length} </Button>
            </CSVLink>
          </Row>
        </Row>
        <Card className="expensesInner">
          <Row className="item" justify={'space-between'} align={'middle'}>
            <Col className="fieldsInner" flex={'auto'}>
              <Row className="fields" justify={'space-between'}>
                <Row className="row">
                  <Row className="title">Дата</Row>
                </Row>
                <Row className="row">
                  <Row className="title">Сума</Row>
                </Row>
                <Row className="row">
                  <Row className="title">Категорія</Row>
                </Row>
                <Row className="row">
                  <Row className="title">Підкатегорія</Row>
                </Row>
                <Row className="row">
                  <Row className="title">Перевірено</Row>
                </Row>
              </Row>
            </Col>
          </Row>
        </Card>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={state.expensesData}
          renderItem={(expense) => <ExpensesItem expense={expense} handleSetApproved={handleSetApproved} />}
        />
        <Row>
          <Pagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            total={state.expensesTotalRecords}
            onChange={onChangePage}
            pageSize={pageSize}
            current={state.expensesCurrentPage}
            pageSizeOptions={pageSizeOptions}
          />
        </Row>
      </Col>
    </Spin>
  );
};

export default React.memo(Expenses);
