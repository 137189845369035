import { Modal, Form, InputNumber, Button } from 'antd';
import { useDispatch } from 'react-redux';

import { info, request } from '../../../tools';
import PartnerPaymentsList from '../PartnerPaymentsList/PartnerPaymentsList';

import { setPartnerBalance } from '../../../store/commonReducer';

import './PartnerBalanceManagement.scss';

const PartnerBalanceManagement = ({
  balanceManagementState,
  setBalanceManagementState,
  bonusBalance,
  paymentBalance,
  setPaymentBalance,
  partnerId,
  requestPaymentList,
  setRequestPaymentList,
  paymentCard,
}) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    setBalanceManagementState(false);
  };

  const [form] = Form.useForm();

  const onFinish = ({ amount }) => {
    request.post('/partners/setBalance', { partnerId, amount }, ({ message }) => {
      info(message);
      dispatch(setPartnerBalance({ partnerId, amount }));
      form.resetFields(['amount']);
      setPaymentBalance(-amount);
    });
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal title="Зміна балансу" open={balanceManagementState} onCancel={handleCancel} footer={false} className="PartnerBalanceManagement">
      <div className="balance">
        <div className="balance-item">
          <span className="balance-item__title">Баланс: </span>
          <span className="balance-item__value">{bonusBalance}</span>
        </div>
        <div className="balance-item">
          <span className="balance-item__title">Баланс на виплату: </span>
          <span className="balance-item__value">{bonusBalance !== 0 && '-'}{bonusBalance}</span>
        </div>
      </div>
      {/* <div className="PartnerBalanceManagement-paymentCard-inner">
        {paymentCard ? (
          <>
            <span className="PartnerBalanceManagement-paymentCard__title">Карта для виплат: </span>
            <span className="PartnerBalanceManagement-paymentCard__number"> {paymentCard.match(/.{1,4}/g).join(' ')}</span>
          </>
        ) : (
          <span className="PartnerBalanceManagement-paymentCard__info">Карта для виплат відсутня!</span>
        )}
      </div> */}
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600, overflow: 'hidden' }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name={['amount']} label="Сума" rules={[{ type: 'number', min: 0, required: true, message: `Вкажіть додатнє число` }]}>
          <InputNumber onKeyDown={handleKeyPress} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button className="requestPayment-submit-btn" type="primary" htmlType="submit">
            Встановити баланс
          </Button>
        </Form.Item>
      </Form>
      {/* <PartnerPaymentsList
        partnerId={partnerId}
        requestPaymentList={requestPaymentList}
        setRequestPaymentList={setRequestPaymentList}
        paymentBalance={paymentBalance}
        setPaymentBalance={setPaymentBalance}
      /> */}
    </Modal>
  );
};

export default PartnerBalanceManagement;
