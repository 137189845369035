import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input } from 'antd';
import { SearchOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { CHAT_TYPES } from 'common_constants/business';
import dayjs from 'dayjs';

import UserAvatar from '../../components/UserAvatar';

const ChatSearchMessages = ({ setIsChatSearchMessagesOpen, currentChatId, chatType = CHAT_TYPES.contractChat.key, setFetchedMessageIndex }) => {
  const chatsData = useSelector((state) => state.common.chatsData);
  const users = useSelector((state) => state.common.users);

  const [messageSearchText, setMessageSearchText] = useState('');
  const [searchedMessages, setSearchedMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const currentChat = useMemo(() => chatsData?.[chatType]?.find((item) => item._id === currentChatId) ?? {}, [chatsData, currentChatId]);

  const handleInputSearchChange = (e) => {
    setMessageSearchText(e.target.value);
  };

  const handleCloseSearchMessages = () => {
    setIsChatSearchMessagesOpen(false);
  };

  const handleClickSearchedMessage = (messageId) => {
    const messageIndex = currentChat?.messages?.findIndex((message) => message._id === messageId);

    setIsChatSearchMessagesOpen(false);
    setFetchedMessageIndex(messageIndex);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const messageSearchTextLower = messageSearchText.toLocaleLowerCase();

      const messages = currentChat?.messages
        ?.filter((item) => item?.message?.toLocaleLowerCase().includes(messageSearchTextLower))
        ?.sort((item) => item.date)
        .reverse();
      setSearchedMessages(messages);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [messageSearchText]);

  return (
    <>
      <div className="chat-search-messages">
        <Input
          type="text"
          className="input-search-messages"
          placeholder="Пошук..."
          value={messageSearchText}
          onChange={handleInputSearchChange}
          prefix={isLoading ? <LoadingOutlined /> : <SearchOutlined />}
          suffix={
            <Button className="btn-search-messages" type="text" onClick={handleCloseSearchMessages}>
              <CloseOutlined />
            </Button>
          }
        />
      </div>
      {messageSearchText?.length > 0 ? (
        <div className="searched-messages-wrapper">
          {searchedMessages?.length === 0 ? (
            <p>
              Немає результатів для "<b>{messageSearchText}</b>". Спробуйте інший пошук
            </p>
          ) : (
            <div>
              {searchedMessages?.map((item) => (
                <div className="searched-message-item" onClick={() => handleClickSearchedMessage(item._id)} key={item._id}>
                  <div className="">{<UserAvatar user={users?.[item.sender]} />}</div>
                  <div className="content">
                    <div className="info">
                      <p>{users?.[item.sender]?.p}</p>
                      <p>{dayjs(item.date).format('DD.MM.YYYY')}</p>
                    </div>
                    <p className="message">{item.message}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default ChatSearchMessages;
