import React, { useEffect, useState } from 'react';
import { Button, Input, Row, Col, Pagination, Spin, Empty, Typography } from 'antd';
import MfoItem from './MfoItem';
import { PlusCircleOutlined } from '@ant-design/icons';
import { ADD_MFO } from 'common_constants/modals';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMfoItem, setMfo, setModal } from '../../store/commonReducer';
import { error, request, success } from '../../tools';

const DEFAULT_PAGE_SIZE = 10;

const Mfo = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const mfo = useSelector((state) => state.common.mfo || []);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { Text } = Typography;

  const getAllMfo = (limits) => {
    setLoading(true);

    const params = {
      ...limits,
      name: searchValue,
    };

    request.post(
      '/mfo/getMfo',
      params,
      ({ mfo, total }) => {
        dispatch(setMfo(mfo));
        setPageSize(pageSize);
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    const limits = {
      page: currentPage,
      pageSize,
    };
    getAllMfo(limits);
  }, [currentPage, pageSize, searchValue]);

  const onChangeSearchInput = (e) => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteMfo = (id) => {
    const onSuccess = () => {
      dispatch(deleteMfoItem(id));
      success('Успіх!', 'МФО видалена успішно!');
    };
    const onError = () => error('Помилка!', 'Схоже, не вдалося видалити МФО!');

    request.delete('/mfo/', id, onSuccess, onError);
  };

  const editMfo = (id) => {
    const mfoToEdit = mfo.find((item) => item._id === id);
    dispatch(setModal({ name: ADD_MFO, editItem: mfoToEdit }));
  };

  if (!users) return null;

  return (
    <section>
      <div className="filii_page default_page">
        <h2>Мікрофінансові організації</h2>
        <br />
        <div className="filii_page__header-options">
          <Input placeholder="Введіть назву для пошуку..." value={searchValue} onChange={onChangeSearchInput} />
          <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_MFO }))}>
            <PlusCircleOutlined /> Створити
          </Button>
        </div>
        <br />
        <Spin spinning={loading} tip="Зачекайте" size="large">
          <Row gutter={[24, 16]}>
            {mfo?.length ? (
              mfo.map((item) => (
                <Col key={item._id} xl={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                  <MfoItem item={item} deleteMfo={deleteMfo} editMfo={() => editMfo(item._id)} />
                </Col>
              ))
            ) : (
              <p className="filii_page__filtered-filii-not-found">
                <Empty description={<Text>Немає даних</Text>} />
              </p>
            )}
          </Row>
          <br />
          <Pagination style={{ textAlign: 'center' }} current={currentPage} pageSize={pageSize} total={total} onChange={handlePageChange} />
        </Spin>
      </div>
    </section>
  );
};

export default Mfo;
