import { createSlice } from '@reduxjs/toolkit';
import { MOB_RESOLUTION } from 'common_constants/ui';

const mobView = window.innerWidth < MOB_RESOLUTION;

const initialState = {
  mainLoader: false,
  universalLoader: false,
  mobSidebar: !mobView,
  mob: mobView,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setMainLoader: (state, action) => {
      state.mainLoader = action.payload;
    },
    setUniversalLoader: (state, action) => {
      state.universalLoader = action.payload;
    },
    setMobSideBar: (state, action) => {
      state.mobSidebar = action.payload;
    },
    setMob: (state, action) => {
      state.mob = action.payload;
    },
  },
});

export const { setMobSideBar, setMainLoader, setUniversalLoader, setMob } = uiSlice.actions;

export default uiSlice.reducer;
