import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResize } from 'common_components';

import { Card, Divider, Empty, List, Space, Spin, Typography } from 'antd';
import FailureToPayItem from './FailureToPayItem';

import { ROLES } from 'common_constants/business';
import Box from '../../components/Box';
import { setCurrentReachList } from '../../store/commonReducer';
import { error, request } from '../../tools';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';

import { SelectGeneralFil } from '../../components/SelectGeneral';

import './FailureToPay.scss';

const FailureToPay = (props) => {
  const [dispatch, resize, users, list] = [
    useDispatch(),
    useResize(),
    useSelector((state) => state.common.users),
    useSelector((state) => state.common.currentReachList),
  ];

  const inspector = ROLES[props.role] <= ROLES.inspector;

  const [loading, setLoading] = useState(false);
  const [fil, setFil] = useState(inspector ? '_' : props.fil ?? '_');
  const [listAndUsersState, setListAndUsersState] = useState(false);

  const [{ Text, Title }] = [Typography];

  setTimeout(() => {
    if (!listAndUsersState && !!list && !!users) setListAndUsersState(true);
  }, 0);

  const relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime).locale('uk');

  const getFailureToPayAll = () => {
    setLoading(true);
    request.post(
      '/hotline/toFailureToPayAll',
      {},
      (req) => {
        dispatch(setCurrentReachList(req.data));
        setLoading(false);
      },
      error,
    );
  };

  const updateRecordInList = (updatedRecord) => {
    const updatedList = list.map((item) => (item.phone === updatedRecord.phone ? updatedRecord : item));
    dispatch(setCurrentReachList(updatedList));
  };

  useEffect(() => {
    getFailureToPayAll();
  }, []);

  if (!users) return null;

  const listToRender = list?.filter((i) => (fil && fil !== '_' && fil !== 'undefined' ? i.fil === fil : true)) ?? [];

  return (
    <Spin tip="Завантаження" spinning={loading}>
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: 'flex',
        }}
        className="hotline-reach-page default-page"
      >
        <Title className="reach-title" level={2}>
          Невнесенні сплати:
        </Title>
        {inspector && (
          <Card>
            <Box mt={0} className={'reach-main-menu'}>
              <Box mt={8}>
                <Divider orientation="left">Фільтри:</Divider>
                <Box className={resize[0] >= 500 ? 'reach-filter-box-pc' : 'reach-filter-box-mob'}>
                  <SelectGeneralFil className={'reach-filter'} value={fil} onChange={(value) => setFil(value)}></SelectGeneralFil>
                  <Text className="reach-filter-box-text"> Філія: </Text>
                </Box>
              </Box>
            </Box>
          </Card>
        )}
        <Box className={'reach-list'} mt={16}>
          {listToRender.length ? (
            <List
              pagination={{
                hideOnSinglePage: true,
                position: 'both',
                align: 'end',
                defaultCurrent: 1,
                total: listToRender.length ?? 1,
                defaultPageSize: 20,
                showTotal: (total, range) => `${range[0]}-${range[1]} з ${total} дожимів`,
              }}
              dataSource={listToRender}
              renderItem={(item, index) => (
                <FailureToPayItem key={item._id} i={item} index={index} role={props.role} updateRecord={updateRecordInList} blocked={item.bl} />
              )}
            />
          ) : (
            <Empty description={<Text>Немає дожимів</Text>} />
          )}
        </Box>
      </Space>
    </Spin>
  );
};

export default FailureToPay;
