import React, { useState } from 'react';
import { Upload, message, Button } from 'antd';
import { InboxOutlined, CloseOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const CSVUploader = ({ onUpload }) => {
  const [fileList, setFileList] = useState([]);

  const handleChange = (info) => {
    setFileList(info.fileList);
  };

  const handleUpload = () => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      onUpload(file);
      setFileList([]);
    } else {
      message.error('Будь ласка, виберіть файл для завантаження.');
    }
  };

  const handleRemove = () => {
    setFileList([]);
  };

  return (
    <div>
      {fileList.length > 0 ? (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ flex: 1 }}>
            {fileList.map((file) => (
              <div key={file.uid} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#fafafa', padding: '5px', borderRadius: '4px', marginBottom: '5px' }}>
                <span>{file.name}</span>
                <Button type="link" icon={<CloseOutlined />} onClick={handleRemove} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Dragger
          showUploadList={{ showRemoveIcon: false }}
          onChange={handleChange}
          beforeUpload={() => false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Натисніть або перетягніть файл сюди</p>
        </Dragger>
      )}
      <Button type="primary" onClick={handleUpload} style={{ marginTop: '10px' }}>
        Завантажити
      </Button>
    </div>
  );
};

export default CSVUploader;
