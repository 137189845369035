import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Modal, Form, Button, message, Input, Upload, Spin } from 'antd';

import { success, request, error } from '../../tools';
import { setModal, setCurrentAuthUserPersonalDate } from '../../store/commonReducer';

import './CourtPractice.scss';

const { Item } = Form;
const { TextArea } = Input;

const CourtPractice = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.common.modal);
  const currentAuthUserPersonalDate = useSelector((state) => state.common.currentAuthUserPersonalDate);

  const [isLoading, setIsLoading] = useState(false);

  const beforeUploadPracticeFile = (file) => {
    const isDocx = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    if (!isDocx) {
      message.error('Можливо завантажити фото тільки в Docx форматі!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Розмір файлу повинен бути менше 2 мегабайт!');
    }
    if (!isDocx || !isLt2M) {
      return false;
    }

    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onClickSavePractice = () => {
    const formData = new FormData();
     setIsLoading(true);

    form
      .validateFields()
      .then((values) => {
        if (data?._id) {
          formData.append('courtPracticeId', data._id);
          formData.append('fileId', data.fileId);
        }

        formData.append('file', values?.file?.[0]?.originFileObj);
        formData.append('name', values.name);

        if (values?.briefly) {
          formData.append('briefly', values.briefly);
        }

        if (values?.description) {
          formData.append('description', values?.description);
        }

        request.post(
          '/profile/courtPracticeEdit',
          formData,
          (res) => {
            success('Успіх!', 'Практику додано успішно.');
            if (!data?._id) {
              const updatedUsers = {
                ...currentAuthUserPersonalDate,
                practice: [res.data, ...currentAuthUserPersonalDate.practice],
              };
              dispatch(setCurrentAuthUserPersonalDate(updatedUsers));
            } else {
              const updatedUser = {
                ...res.data,
              };
              const updatedUsers = currentAuthUserPersonalDate.practice.map((item) => (item._id === updatedUser._id ? updatedUser : item));
              dispatch(setCurrentAuthUserPersonalDate({ ...currentAuthUserPersonalDate, practice: updatedUsers }));
            }
            setIsLoading(false);
            dispatch(setModal());
          },
          () => {
            setIsLoading(false);
            error('Помилка!', 'Щось пішло не так.');
          },
        );
      })
      .catch((errors) => {
        setIsLoading(false);
        return;
      });
  };

  const onCancel = () => {
    dispatch(setModal());
  };

  return (
    <Modal className="courtPractice" open title={`${data?._id ? 'Редагувати' : 'Додати'} судову практику`} onCancel={onCancel} footer={null}>
      <Spin spinning={isLoading}>
        <Form initialValues={data} layout="vertical" form={form}>
          <div className="currentUserPersonalInfo__form-item">
            <Item
              rules={[{ required: true, message: "Дане поле обов'язкове!" }]}
              label="Назва:"
              name={'name'}
              className="currentUserPersonalInfo__input-wrapper"
            >
              <Input placeholder="Рішення суду" />
            </Item>
            <Item label="Коротко:" name={'briefly'} className="currentUserPersonalInfo__input-wrapper">
              <Input placeholder="Про розпізнавання кредитного договору" />
            </Item>
            <Item label="Опис:" name={'description'} className="currentUserPersonalInfo__input-wrapper">
              <TextArea placeholder='Клієнт звернувся до АО "Захист" з проблемою...' />
            </Item>
            {!data?._id ? (
              <Item
                rules={[{ required: true, message: "Дане поле обов'язкове!" }]}
                label="Файл:"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                name={'file'}
              >
                <Upload beforeUpload={beforeUploadPracticeFile} action="" acept=".docx,.doc" maxCount={1}>
                  <Button icon={<UploadOutlined />}>Загрузити</Button>
                </Upload>
              </Item>
            ) : null}
          </div>
          <Form.Item>
            <Button onClick={onClickSavePractice} type="primary">
              Зберегти практику
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CourtPractice;
