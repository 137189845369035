import React from 'react';
import SearchSelect from './SearchSelect';
import { useSelector } from 'react-redux';

const SearchSelectFil = ({ onChange, value, disabled, className, style }) => {
  const filii = useSelector((state) => state.common.filii);
  return (
    <SearchSelect
      className={className}
      style={{ minWidth: 200, ...style }}
      disabled={disabled}
      placeholder="Всі філії"
      list={Object.values(filii).map((i) => ({ value: i.i + '', label: i.name }))}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectFil;
