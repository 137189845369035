import React from 'react';
import { ARCHIVE_FEELS } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralTypeOfArchiving = [ARCHIVE_FEELS, ['Тип архівації по договору', 'at_K']];

const SelectGeneralTypeOfArchiving = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть тип архівації"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі типи архівації</Option>
      {ARCHIVE_FEELS.map((i, index) => (
        <Option key={i} value={String(index)}>
          {i}
        </Option>
      ))}
    </Select>
  );
};

export { billSelectGeneralTypeOfArchiving, SelectGeneralTypeOfArchiving };
