import React, { useState, useEffect, useMemo } from 'react';
import { ROLES } from 'common_constants/business';
import { Modal, Spin, Button, Typography, Avatar, Tag, Switch, Popover, Popconfirm, DatePicker, TimePicker, Input, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined, UserOutlined, DeleteOutlined } from '@ant-design/icons';
import ReactQuill, { Quill } from 'react-quill';
import { htmlEditButton } from 'quill-html-edit-button';
import BlotFormatter from 'quill-blot-formatter';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/uk';

import 'react-quill/dist/quill.snow.css';

import { request, error } from '../../tools';
import { setModal, editTask, deleteTask, setDoneTask, addTask } from '../../store/commonReducer';
import HoverableUser from '../../components/HoverableUser';
import Members from './Members';
import Labels from './Labels';
import './styles.scss';

dayjs.locale('uk');

Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register('modules/htmlEditButton', htmlEditButton);

const { Text } = Typography;
const { Group } = Avatar;

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  htmlEditButton: {},
  blotFormatter: {},
};

const isDone = (userId, doneUsers) => {
  return doneUsers?.includes?.(userId);
};

const TaskInfo = () => {
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.common.modal);
  const userAuth = useSelector((state) => state.common.userAuth);
  const tasks = useSelector((state) => state.common.tasks);
  const users = useSelector((state) => state.common.users);

  const [currentTask, setCurrentTask] = useState({
    title: '',
    description: '',
    isDaily: false,
    labels: [],
    appointed: [],
  });
  const [openMembers, setOpenMembers] = useState(false);
  const [openLabels, setOpenLabels] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [isTitleChanging, setIsTitleChanging] = useState(false);

  const isInspector = ROLES[userAuth.role] <= ROLES.inspector;
  const { listName, taskId } = modalData;

  useEffect(() => {
    if (tasks && taskId) {
      const task = tasks[listName].filter((item) => item._id === taskId)[0];
      setCurrentTask(task);
    }
  }, [tasks]);

  const getUserAvatar = (user) => {
    const imageName = users?.[user]?.upa;

    return imageName ? process.env.REACT_APP_API + `/avatars/${imageName}.jpeg` : null;
  };

  const createTask = () => {
    setIsSaveBtnLoading(true);

    const reqData = {
      ...currentTask,
    };

    request.post(
      '/tasks/add',
      reqData,
      ({ data }) => {
        if (currentTask?._id) {
          dispatch(editTask({ listName, task: data }));
        } else {
          dispatch(addTask({ listName, task: data }));
        }
        setIsSaveBtnLoading(false);
        setIsEditDescription(false);
        setIsTitleChanging(false);
        dispatch(setModal());
      },
      () => {
        error();
        setIsSaveBtnLoading(false);
      },
    );
  };

  const onChangeDoneTask = () => {
    setIsLoading(true);

    const requestData = {
      taskId: currentTask._id,
    };

    request.post(
      '/tasks/setDone',
      requestData,
      ({ data }) => {
        onCancel();
        dispatch(editTask({ listName, task: data }));
        dispatch(setDoneTask({ task: data }));
        setIsLoading(false);
      },
      error,
    );
  };

  const onDeleteTask = () => {
    setIsLoading(true);
    const taskId = currentTask._id;

    request.post(
      `/tasks/delete/${taskId}`,
      {},
      () => {
        setIsLoading(false);
        dispatch(setModal());
        dispatch(
          deleteTask({
            listName,
            taskId,
          }),
        );
      },
      error,
    );
  };

  const onChangeDeadlineTime = (value) => {
    let currentValue = dayjs(value);
    let deadline = dayjs(currentTask.deadline).set('hours', currentValue.hour()).set('minutes', currentValue.minute());
    deadline = deadline.toJSON();
    setCurrentTask((prev) => ({ ...prev, deadline: deadline }));
  };

  const onChangeDailyDeadlineTime = (value) => {
    if (!value) {
      return;
    }

    setIsLoading(true);
    const deadline = value?.toJSON();
    const data = {
      _id: currentTask._id,
      deadline: deadline,
    };

    request.post('/tasks/add', data, () => {
      dispatch(editTask({ listName, task: data }));
      setIsLoading(false);
    });
  };

  const onContractFiled = () => {
    if (!currentTask.contractIndex) return;

    request.post(
      '/contracts/getClientByIndex',
      { i: currentTask.contractIndex },
      (res) => {
        Modal.confirm({
          content: res.data.client ? "Використати ім'я: " + res.data.client + '?' : "Зверніть увагу, клієнт не прив'язаний до договору!",
          onOk() {
            if (!res.data.client) return;
            setCurrentTask((prev) => ({ ...prev, contractClientName: res.data.client ?? '' }));
          },
          onCancel() {},
        });
      },
      (_, __, axiosError) => {
        error(axiosError.response.data.error, ' ');
        setCurrentTask((prev) => ({ ...prev, contractClientName: null }));
      },
    );
  };

  const isAllowToEdit = useMemo(() => currentTask.author === userAuth._id || isInspector, [currentTask, userAuth]);

  const onClickAddMember = () => setOpenMembers(true);

  const onClickAddLabel = () => setOpenLabels(true);

  const onCancel = () => dispatch(setModal());

  const onClickTitle = () => setIsTitleChanging(true);

  const onTitleInputBlur = () => setIsTitleChanging(false);

  const onClickTaskDescription = () => setIsEditDescription(true);

  return (
    <Modal className="task-info" open footer={null} width={750} onCancel={onCancel}>
      <Spin spinning={isLoading}>
        <div className="title-wrapper">
          {isTitleChanging || !currentTask?._id ? (
            <Input
              value={currentTask.title}
              placeholder="Назва завдання..."
              onChange={(e) => setCurrentTask((prev) => ({ ...prev, title: e.target.value }))}
              onBlur={onTitleInputBlur}
              className="title-input"
            />
          ) : (
            <Typography onClick={onClickTitle} className="title">
              {currentTask?.title}
            </Typography>
          )}
        </div>

        <div className="top">
          <div className="members">
            <Text className="top-text">Учасники:</Text>
            <div className="members-group">
              <Group maxCount={5}>
                {currentTask?.appointed?.map((user) => (
                  <Popover key={user} content={<HoverableUser id={user} />}>
                    <Avatar icon={<UserOutlined />} src={getUserAvatar(user)} />
                  </Popover>
                ))}
              </Group>
              {!currentTask?._id || (isAllowToEdit && !isDone(userAuth._id, currentTask?.done)) ? (
                <Button className="add-btn" type="primary" icon={<PlusOutlined />} shape="circle" onClick={onClickAddMember} />
              ) : null}
            </div>
            {openMembers ? <Members task={currentTask} setCurrentTask={setCurrentTask} listName={listName} setOpenMembers={setOpenMembers} /> : null}
          </div>
          <div className="labels">
            <Typography.Text className="top-text">Мітки:</Typography.Text>
            <div className="labels-group">
              {currentTask.labels?.map((label) => (
                <Tag className="label-item" key={label.text} color={label.color}>
                  <span dangerouslySetInnerHTML={{ __html: label.icon }} />
                  {label.text}
                </Tag>
              ))}
              {!isDone(userAuth._id, currentTask?.done) ? (
                <Button className="add-btn" type="primary" icon={<PlusOutlined />} onClick={onClickAddLabel} />
              ) : null}
            </div>
            {openLabels ? <Labels task={currentTask} setCurrentTask={setCurrentTask} setOpenLabels={setOpenLabels} listName={listName} /> : null}
          </div>
          <div className="option">
            <p>Завдання щоденне:</p>
            <Switch
              disabled={!isAllowToEdit || isDone(userAuth._id, currentTask?.done)}
              checked={currentTask.isDaily}
              onChange={(val) => setCurrentTask((prev) => ({ ...prev, isDaily: val }))}
            />
          </div>
          <div className="option">
            <p>Винонане:</p>
            <Switch onChange={onChangeDoneTask} checked={isDone(userAuth._id, currentTask?.done)} disabled={!currentTask?._id} />
          </div>
          <div className="option">
            <p>Кінцевий термін:</p>
            {currentTask.isDaily ? (
              <TimePicker
                value={currentTask?.deadline ? dayjs(currentTask.deadline) : ''}
                onChange={onChangeDailyDeadlineTime}
                format="HH:mm"
                locale={locale}
              />
            ) : (
              <div className="deadlinePicker-wrapper">
                <DatePicker
                  value={currentTask?.deadline ? dayjs(currentTask.deadline) : ''}
                  onChange={(val) => setCurrentTask((prev) => ({ ...prev, deadline: val.toJSON() }))}
                  locale={locale}
                  disabledDate={(currentDate) => currentDate <= dayjs().startOf('day')}
                  disabled={isDone(userAuth._id, currentTask?.done) || (!isAllowToEdit && currentTask?._id)}
                />
                <TimePicker
                  value={currentTask?.deadline ? dayjs(currentTask.deadline) : ''}
                  onChange={onChangeDeadlineTime}
                  format="HH:mm"
                  locale={locale}
                  disabled={isDone(userAuth._id, currentTask?.done) || (!isAllowToEdit && currentTask?._id)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="contract-index-block">
          <div className="add-contract-index">
            <Text strong>Прив'язати договір</Text>
            <InputNumber
              min={1}
              onChange={(val) => setCurrentTask((prev) => ({ ...prev, contractIndex: val }))}
              onBlur={onContractFiled}
              value={currentTask?.contractIndex}
              disabled={!isAllowToEdit && currentTask?._id}
            />
          </div>

          {currentTask?.contractClientName ? (
            <div className="">
              <p>Ім'я клієнта:</p>
              <p>{currentTask?.contractClientName}</p>
            </div>
          ) : null}
        </div>
        <div className="description">
          <div className="description-header">
            <Text strong>Опис</Text>
          </div>
          <div>
            {isEditDescription && !isDone(userAuth._id, currentTask?.done) ? (
              <div>
                <ReactQuill
                  value={currentTask?.description}
                  onChange={(val) => setCurrentTask((prev) => ({ ...prev, description: val }))}
                  onBlur={() => setIsEditDescription(false)}
                  modules={modules}
                  theme="snow"
                />
                {/* <div className="description-btns">
                  <Button onClick={onClickCancelDescription}>Скасувати</Button>
                </div> */}
              </div>
            ) : currentTask?.description?.length > 0 || (!isAllowToEdit && currentTask?._id) ? (
              <div
                dangerouslySetInnerHTML={{ __html: currentTask?.description }}
                className="description-text"
                onClick={isAllowToEdit ? onClickTaskDescription : null}
              ></div>
            ) : (
              <div className="description-text block" onClick={onClickTaskDescription}>
                Додати опис...
              </div>
            )}
          </div>
        </div>

        <div className="footer-btns-wrapper">
          {currentTask?._id && isAllowToEdit ? (
            <Popconfirm
              title="Видалення завдання"
              description="Ви дійсно хочете видалити дане завдання?"
              okText="Так"
              cancelText="Ні"
              onConfirm={onDeleteTask}
            >
              <Button icon={<DeleteOutlined />} type="primary" danger />
            </Popconfirm>
          ) : null}
          {!currentTask?._id || isAllowToEdit ? (
            <Button type="primary" onClick={createTask} loading={isSaveBtnLoading} disabled={isSaveBtnLoading}>
              {currentTask?._id ? 'Зберегти' : 'Створити'}
            </Button>
          ) : null}
        </div>
      </Spin>
    </Modal>
  );
};

export default TaskInfo;
