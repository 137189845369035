import React, { useState } from 'react';
import { Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CHAT_TYPES } from 'common_constants/business';

import { setModal, updateChat } from '../../store/commonReducer';
import SelectMembers from '../../components/SelectMembers';
import { request, error } from '../../tools';

const AddChatGroupUsers = () => {
  const dispatch = useDispatch();

  const modalData = useSelector((state) => state.common.modal.data);

  const [selectedMembers, setSelectedMembers] = useState(modalData.currentUsers);
  const [disabledMembers, setDisabledMembers] = useState([modalData?.groupOwner]);
  const [isLoading, setIsLoading] = useState(false);

  const onSave = () => {
    setIsLoading(true);
    const dataRequest = {
      users: selectedMembers,
      chatId: modalData.chatId,
    };

    request.post(
      '/chatPrivate/changeGroupChatUsers',
      dataRequest,
      () => {
        dispatch(updateChat({ chatType: CHAT_TYPES.groupChat.key, data: { users: selectedMembers, _id: modalData.chatId } }));
        setIsLoading(false);
        onCancel();
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  const changeSelectedMembers = (values) => {
    setSelectedMembers(values);
  };

  const onCancel = () => {
    dispatch(setModal());
  };

  return (
    <Modal open onCancel={onCancel} okText={'Зберегти'} onOk={onSave} className="" width={400}>
      <Spin spinning={isLoading}>
        <SelectMembers selectedMembers={selectedMembers} setSelectedMembers={changeSelectedMembers} disabledUsers={disabledMembers} />
      </Spin>
    </Modal>
  );
};

export default AddChatGroupUsers;
