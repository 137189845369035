import { Button, Table } from 'antd';
import dayjs from 'dayjs';
import { getClientColor } from '../../tools';

export const formatSecondsTime = (seconds) => {
  let minutes = String(Math.floor(seconds / 60)) ?? '00';
  if (minutes.length === 1) minutes = '0' + minutes;

  let secondsFormated = String(seconds % 60) ?? '00';
  if (secondsFormated.length === 1) secondsFormated = '0' + secondsFormated;

  return `${minutes}:${secondsFormated}`;
};

const fields = ['status', 'refusalReason', 'pravoType', 'clientName', 'se', 'state'];

export const generateCallColumns = (mob, handleChangeClientName, handleClientBtnClick, handleAddHotlineCallsReport) =>
  mob
    ? [
        {
          title: 'Клієнт',
          key: 'client',
          align: 'center',
          render: (item) => {
            let content;
            if (item.client) {
              const { color, background } = getClientColor(item.client);
              content = (
                <Button
                  className="client-btn"
                  size="small"
                  shape="round"
                  style={{ color, background }}
                  onClick={(e) => handleClientBtnClick(e, item.client)}
                >
                  {item.client?.n || 'Невідомо'}
                </Button>
              );
            } else {
              content = <a href={`tel:0${item.from}`}>{item.from}</a> ?? '-';
            }

            return (
              <div className="mobile-client">
                {item?.start_time && <span>{dayjs(item?.start_time).format('DD.MM.YYYY HH:mm')}</span>}
                {content}
              </div>
            );
          },
        },
        {
          title: 'Статус',
          dataIndex: 'calledBack',
          key: 'calledBack',
          align: 'center',
          render: (calledBack, item) => {
            let filled = fields.filter((field) => item[field]).length;

            return (
              <div>
                <div style={{ marginBottom: 10 }}>{((filled / (fields.length + 3)) * 100).toFixed(0)}%</div>
                {!item?.status ? (
                  <Button type="primary" onClick={(e) => handleAddHotlineCallsReport(e, item)}>
                    Звітувати
                  </Button>
                ) : (
                  <Button onClick={(e) => handleAddHotlineCallsReport(e, item)}>Змінити</Button>
                )}
              </div>
            );
          },
        },
      ]
    : [
        Table.EXPAND_COLUMN,
        {
          title: 'Дата',
          dataIndex: 'start_time',
          key: 'start_time',
          render: (start_time) => dayjs(start_time).format('DD.MM.YYYY HH:mm'),
        },
        {
          title: 'З номеру',
          dataIndex: 'from',
          key: 'from',
          render: (from) => <a href={`tel:0${from}`}>{from}</a> ?? '-',
        },
        {
          title: 'Клієнт',
          dataIndex: 'client',
          key: 'client',
          render: (client, item) => {
            const { color, background } = getClientColor(client);

            if (item.client) {
              return (
                <Button
                  className="client-btn"
                  size="small"
                  shape="round"
                  style={{ color, background }}
                  onClick={(e) => handleClientBtnClick(e, client)}
                >
                  {client?.n || 'Невідомо'}
                </Button>
              );
            } else {
              return <a href={`tel:0${item.from}`}>{item.from}</a> ?? '-';
            }
          },
        },
        {
          title: 'Тривалість',
          dataIndex: 'answer_seconds',
          key: 'answer_seconds',
          align: 'center',
          render: (answer_seconds) => formatSecondsTime(answer_seconds) ?? '-',
        },
        {
          title: 'Відосток заповнення',
          key: 'percent',
          align: 'center',
          render: (item) => {
            let filled = fields.filter((field) => item[field]).length;
            return <div>{((filled / fields.length) * 100).toFixed(0)}%</div>;
          },
        },
        {
          title: 'Передзвонено',
          dataIndex: 'calledBack',
          key: 'calledBack',
          align: 'center',
          render: (calledBack, item) =>
            !item?.status ? (
              <Button type="primary" onClick={(e) => handleAddHotlineCallsReport(e, item)}>
                Звітувати
              </Button>
            ) : (
              <Button onClick={(e) => handleAddHotlineCallsReport(e, item)}>Змінити</Button>
            ),
        },
      ];
