import React, { useEffect, useState } from 'react';
import { Alert, Select, Typography } from 'antd';
import { HOTLINE_ADV_WHERE, HOTLINE_ADV_WHERE_MORE } from 'common_constants/business/index';

const { Text } = Typography;

const Step3 = ({ data, setData, ...props }) => {
  const [aw, setAw] = useState(data?.aw ?? 0);
  const [am, setAm] = useState(data?.am ?? 0);

  useEffect(() => {
    if (aw && am) {
      window.scroll(0, 9999);
    }
  }, [aw, am]);

  useEffect(() => {
    if (data?.aw !== undefined) {
      setAw(data.aw);
    }
    if (data?.am !== undefined) {
      setAm(data.am);
    }
  }, [data]);

  const handleChange = (value, type) => {
    if (type === 'aw') {
      setAw(value);
      setData({ ...data, aw: value });
    } else if (type === 'am') {
      setAm(value);
      setData({ ...data, am: value });
    }
  };

  return (
    <div className="step3">
      {props.tip && (
        <Alert
          style={{ margin: '10px 0 ' }}
          message="Проводимо анкетування"
          description={
            <>
              <b>Скажіть будь ласка, як Ви дізналися про нашу компанію?</b>
            </>
          }
          type="info"
          showIcon
        />
      )}
      <Text style={{ marginRight: 10 }}>Категорія:</Text>
      <Select
        showSearch
        value={aw}
        placeholder="Search to Select"
        optionFilterProp="children"
        onChange={(v) => handleChange(v, 'aw')}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
      >
        {HOTLINE_ADV_WHERE.map((i, index) => (
          <Select.Option key={i} value={index}>
            {i}
          </Select.Option>
        ))}
      </Select>
      <br />
      <br />
      <Text style={{ marginRight: 10 }}>Що саме:</Text>
      <Select
        showSearch
        value={am}
        placeholder="Search to Select"
        optionFilterProp="children"
        onChange={(v) => handleChange(v, 'am')}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
      >
        {HOTLINE_ADV_WHERE_MORE.map((i, index) => (
          <Select.Option key={i} value={index}>
            {i}
          </Select.Option>
        ))}
      </Select>
      <br />
    </div>
  );
};

export default Step3;
