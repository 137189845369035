import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Card, List, Typography } from 'antd';
import { setAssignmentsList } from '../../store/commonReducer';
import { useEffect, useState } from 'react';
import { error, request } from '../../tools';
import { ASSIGNMENTS_SERVICES_TYPE } from 'common_constants/business';

const { Text } = Typography;

const AssignmentsTable = () => {
  const dispatch = useDispatch();
  const assignments = useSelector((state) => state.common.assignmentsList) ?? [];
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    request.post(
      '/assignment/getNearestAssignments',
      {},
      ({ assignments }) => {
        const filteredAssignments = assignments.filter(
          (item) => dayjs(item.date).isSame(dayjs(), 'day') || dayjs(item.date).isSame(dayjs().add(1, 'day'), 'day'),
        );

        dispatch(setAssignmentsList(filteredAssignments));
      },
      error,
    );
  }, [dispatch]);

  return (
    <Card title="Найближчі виходи">
      <List
        itemLayout="horizontal"
        dataSource={assignments}
        renderItem={(item) => (
          <List.Item>
            <div style={{ width: '100%' }}>
              <Text strong>Дог.№{item.contractNumber}</Text>
              <br />
              {isMobile && <>&nbsp;&nbsp;&nbsp;</>}
              {!isMobile && item.courtCase && (
                <>
                  <Text>Сп.№{item.courtCase}</Text>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>
              )}
              {!isMobile && (
                <>
                  <Text>{ASSIGNMENTS_SERVICES_TYPE[item.serviceType]}</Text>
                  <br />
                </>
              )}

              <Text type="secondary">
                {dayjs(item.date).format('DD MMM')} {item.selectedTime}
              </Text>
            </div>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default AssignmentsTable;
