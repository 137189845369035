import { useState, useCallback, useEffect } from 'react';
import { useResize } from 'common_components';

import { Card, Button, Modal, Popover, Typography, Spin } from 'antd';
import { CheckCircleTwoTone, ExclamationCircleFilled, ExclamationCircleTwoTone, SmileTwoTone } from '@ant-design/icons';

import { PRAVO, WORK_TIME, POSLUGI } from 'common_constants/business';
import dayjs from 'dayjs';
import HoverableUser from '../../components/HoverableUser/HoverableUser';
import { request, error, warn, info } from '../../tools';

import clsx from 'clsx';
import './HackworkItem.scss';

const HackworkItem = (props) => {
  const resize = useResize();
  const [{ confirm }, { Link }] = [Modal, Typography];

  const { item, userId, userFil, date } = props;

  const [isAssignedToMe, setIsAssignedToMe] = useState(item.hackworkAcceptUserId === userId ? true : false);
  const [isAssignedToSomeone, setIsAssignedToSomeone] = useState(false);
  const [loading, setLoading] = useState(false);

  const isMy = item.fil === userFil;



  const handleAcceptHackwork = useCallback(async () => {
    const transaction = {
      hotlineId: item._id,
      type: item.type,
    };

    setLoading(true);

    await request.post(
      '/hotline/acceptHackwork',
      transaction,
      () => {
        info('', 'Халтуру успішно прийнято!');
        setIsAssignedToMe(true);
      },
      (s1, s2, res) => {
        if (res.error === 'hackwork accepted by another user') {
          warn('', 'Халтуру вже прийняв інший користувач.');
          setIsAssignedToSomeone(true);
          return;
        }
        warn();
      },
    );

    setLoading(false);
  }, [item._id, userFil, userId]);

  const showConfirm = useCallback(() => {
    confirm({
      title: `Впевнені, що хочете взяти халтуру?`,
      icon: <ExclamationCircleFilled />,
      okText: 'Підтвердити',
      cancelText: 'Скасувати',
      onOk: handleAcceptHackwork,
    });
  }, [handleAcceptHackwork, confirm]);


  return (
    <Card className={clsx({ 'card two_columns expand hackwork-item-inner': true, ic: item.type === '6' })}>
      <Spin size="large" tip="Завантаження" spinning={loading}>
        <div className={'hackwork-item'}>
          <div className="row">
            <b>Дата:</b> {dayjs(item.date)?.format('DD.MM.YYYY') ?? '-'}
          </div>
          <div className="row">
            <b>Ім'я:</b> {item.name ?? '-'}
          </div>
          <div className="row">
            <b>Час:</b> {(item.startT ?? '-') + ' - ' + (item.endT ?? '-')}
          </div>
          <div className="row">
            <b>Послуга:</b> {POSLUGI[item.type]}
          </div>
          <div className="row">
            <b>Вид:</b> {PRAVO[item.pravo] ?? '-'}
          </div>
          <div className="row">
            <b>Сума:</b> {item.cost ?? '-'}
          </div>
          <div className="row">
            <b>Створено:</b> {dayjs(item.crAt)?.format('DD.MM.YYYY HH:mm:ss') ?? '-'}
          </div>
          <div className="row">
            <b>Автор:</b>
            <Popover
              content={<HoverableUser id={(window.users && window.users[item.author]?._id) ?? '-'} />}
              arrow
              placement={resize[0] >= 500 ? 'rightBottom' : 'top'}
              trigger="hover"
            >
              <Link>{(window.users && window.users[item.author]?.p) ?? '-'}</Link>
            </Popover>
          </div>
          <div className="row">
            <span className="item-white-space">
              <b>Коментар:</b> {item.comment ?? '-'}
            </span>
          </div>
        </div>
        <div className="hackworkAcceptButton-inner">
          {isAssignedToSomeone && (
            <div style={{ fontSize: '16px' }}>
              <ExclamationCircleTwoTone twoToneColor="#ffc53d" />
              <span style={{ marginLeft: '5px' }}>Вже взяв інший користувач</span>
            </div>
          )}

          {isAssignedToMe ? (
            <div className="hackwork-check__item" style={{ display: isAssignedToMe ? 'flex' : 'none' }}>
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              <span>Прийнято</span>
            </div>
          ) : isMy ? (
            <div className="hackwork-check__item" style={{ display: isMy ? 'flex' : 'none' }}>
              <SmileTwoTone twoToneColor="#52c41a" />
              <span>Моя халтурка</span>
            </div>
          ) : (
            <Button type="primary" onClick={showConfirm}>
              Взяти
            </Button>
          )}
        </div>
      </Spin>
    </Card>
  );
};

export default HackworkItem;
