import clsx from 'clsx';

const Box = ({ children, className, mt }) => (
  <div
    className={clsx('box', className)}
    style={{
      marginTop: mt ?? 8,
    }}
  >
    {children}
  </div>
);

export default Box;
