import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, List, Pagination } from 'antd';

import { getJudges, getLawyers, getPlaces, getCourts } from '../../pages/Assingment/AssignmentsHelpers';
import { setModal, setLawyersList, setJudgesList, setCourtsList, setPlacesList, setAssignmentsList } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { request } from '../../tools';
import { AssignmentItem } from '../../pages/Assingment/AssignmentsPartials';
import { ADD_ASSIGNMENT, ASSIGNMENT_REPORT } from 'common_constants/modals';

const AssignmentsInfoList = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const data = useSelector((state) => state.common.modal.data);
  const assignmentsList = useSelector((state) => state.common.assignmentsList) ?? [];
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleAddAssignment = (data) => dispatch(setModal({ name: ADD_ASSIGNMENT, data }));
  const handleAddReport = (data) => dispatch(setModal({ name: ASSIGNMENT_REPORT, data }));

  const handleSetLawyersList = (lawyersList) => dispatch(setLawyersList(lawyersList));
  const handleSetJudgesList = (judgesList) => dispatch(setJudgesList(judgesList));
  const handleSetPlacesList = (placesList) => dispatch(setPlacesList(placesList));
  const handleSetCourtsList = (courtsList) => dispatch(setCourtsList(courtsList));
  const handleSetAssignmentsList = (assignmentsList) => dispatch(setAssignmentsList(assignmentsList));
  const handleSetMainLoader = (state) => dispatch(setMainLoader(state));

  const onCancel = () => dispatch(setModal());

  const handleTableChange = (pageNumber) => setCurrentPage(pageNumber);

  const fetchAssignments = () => {
    const onSuccess = (res) => {
      handleSetMainLoader(false);

      if (res.assignments) {
        handleSetAssignmentsList(res.assignments);
        setTotal(res.assignments.length);
      } else {
        handleSetAssignmentsList([]);
        setTotal(res.total);
      }
    };

    const onError = (err) => {
      handleSetMainLoader(false);
    };

    const body = { contractNumberFilter: data.contract.i, page: currentPage };

    handleSetMainLoader(true);
    request.post('/assignment/getForContract', body, onSuccess, onError);
  };

  useEffect(() => {
    getLawyers(handleSetLawyersList, handleSetMainLoader)
      .then(() => getJudges(handleSetJudgesList, handleSetMainLoader))
      .then(() => getPlaces(handleSetPlacesList, handleSetMainLoader))
      .then(() => getCourts(handleSetCourtsList, handleSetMainLoader))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    fetchAssignments();
  }, [currentPage]);

  return (
    <Modal open title={`Виходи по договору № ${data.contract.i}`} onCancel={onCancel} footer={null}>
      <List
        itemLayout="horizontal"
        dataSource={assignmentsList}
        renderItem={(item) => (
          <AssignmentItem
            assignment={item}
            handleAddReport={handleAddReport}
            handleAddAssignment={handleAddAssignment}
            userId={userAuth._id}
            admin={true}
            manager={true}
          />
        )}
      />
      <Pagination style={{ textAlign: 'center' }} current={currentPage} total={total} onChange={handleTableChange} />
    </Modal>
  );
};

export default AssignmentsInfoList;
