import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'common_constants/routes';
import { POSLUGI_NOMINATIVE_CASE, POSLUGI_NOMINATIVE_CASE_REACH_FILTER } from 'common_constants/business';
import { Select } from 'antd';

const obj = POSLUGI_NOMINATIVE_CASE.reduce((result, element, index) => {
  result[index] = element;
  return result;
}, {});

const billSelectGeneralTypeService = [
  { ...obj, '-1': 'Дист. та звичайна консультація' },
  ['Тип послуги по консультації', 'type_H'],
  ['Тип послуги', 'type'],
];

const SelectGeneralTypeService = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;
  const { pathname } = useLocation();

  const list_select_dynamic =
    pathname === ROUTES.HOTLINE_REACH
      ? Object.keys(POSLUGI_NOMINATIVE_CASE_REACH_FILTER).map((e) => (
          <Option key={e} value={Number(e)}>
            {POSLUGI_NOMINATIVE_CASE_REACH_FILTER[e]}
          </Option>
        ))
      : Object.keys(POSLUGI_NOMINATIVE_CASE).map((e) => (
          <Option key={e} value={Number(e)}>
            {POSLUGI_NOMINATIVE_CASE[e]}
          </Option>
        ));

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть тип послуг"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі типи послуг</Option>

      {pathname === ROUTES.SENDER && (
        <Option key={-1} value={-1}>
          Дист. та звичайна консультація
        </Option>
      )}
      {list_select_dynamic}
    </Select>
  );
};

export { billSelectGeneralTypeService, SelectGeneralTypeService };
