import React from 'react';
import { TOWNS } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralBirthCity = [TOWNS, ['Місто народження по клієнту', 'town_C']];

const SelectGeneralBirthCity = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть місто"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі міста</Option>
      {TOWNS.map((i, index) => (
        <Option key={i} value={String(index)}>
          {i}
        </Option>
      ))}
    </Select>
  );
};

export { billSelectGeneralBirthCity, SelectGeneralBirthCity };
