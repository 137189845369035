import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCourtsList, setJudgesList, setLawyersList, setModal, setPlacesList } from '../../store/commonReducer';
import { CloseCircleOutlined, EditOutlined, SnippetsOutlined, ContainerOutlined } from '@ant-design/icons';
import { gray, orange } from '@ant-design/colors';
import { Modal, Spin, Button } from 'antd';
import { request, error } from '../../tools';
import { ASSIGNMENTS_SERVICES_TYPE, ASSIGNMENT_PAYMENT_STATUS, ASSIGNMENT_SERVICE, ASSIGNMENT_STATUS, ROLES } from 'common_constants/business';
import { ASSIGNMENT_DOCUMENTS, ADD_ASSIGNMENT, ASSIGNMENT_REPORT, ASSIGNMENT_MARK_AS_INCORRECT, WALLET_ASSIGNMENT } from 'common_constants/modals';
import dayjs from 'dayjs';

import './style.scss';

const WalletAssignment = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.common.modal.data);
  const users = useSelector((state) => state.common.users);
  const judgesList = useSelector((state) => state.common.judgesList);
  const lawyersList = useSelector((state) => state.common.lawyersList);
  const placesList = useSelector((state) => state.common.placesList);
  const courtsList = useSelector((state) => state.common.courtsList);
  const FILII = useSelector((state) => state.common.FILII);

  const userAuth = useSelector((state) => state.common.userAuth);
  const admin = ROLES[userAuth.role] === ROLES.admin;
  const deputyManager = ROLES[userAuth.role] <= ROLES.deputyManager;

  const [loading, setLoading] = useState(false);
  const [assignment, setAssignment] = useState(data?.contractNumber ? data : null);

  const handleAddAssignment = (data) => dispatch(setModal({ name: ADD_ASSIGNMENT, data, prev: { name: WALLET_ASSIGNMENT, data } }));
  const handleAddReport = (data) => dispatch(setModal({ name: ASSIGNMENT_REPORT, data, prev: { name: WALLET_ASSIGNMENT, data } }));
  const handleMarkAsIncorrect = (data) => dispatch(setModal({ name: ASSIGNMENT_MARK_AS_INCORRECT, data, prev: { name: WALLET_ASSIGNMENT, data } }));
  const handleDocuments = (data) =>
    dispatch(setModal({ name: ASSIGNMENT_DOCUMENTS, data: { courtCase: assignment?.courtCase }, prev: { name: WALLET_ASSIGNMENT, data } }));

  const getLawyers = () => {
    return new Promise((resolve, reject) => {
      request.post(
        '/lawyer/get',
        {},
        ({ lawyers }) => {
          dispatch(setLawyersList(lawyers));
          resolve();
        },
        (error) => {
          reject(error);
        },
      );
    });
  };

  const getCourts = () => {
    return new Promise((resolve, reject) => {
      request.post(
        '/court/get',
        {},
        ({ courts }) => {
          dispatch(setCourtsList(courts));
          resolve();
        },
        (error) => {
          reject(error);
        },
      );
    });
  };

  const getPlaces = () => {
    return new Promise((resolve, reject) => {
      request.post(
        '/place/get',
        {},
        ({ places }) => {
          dispatch(setPlacesList(places));
          resolve();
        },
        (error) => {
          reject(error);
        },
      );
    });
  };

  const getJudges = () => {
    return new Promise((resolve, reject) => {
      request.post(
        '/judge/get',
        {},
        ({ judges }) => {
          dispatch(setJudgesList(judges));
          resolve();
        },
        (error) => {
          reject(error);
        },
      );
    });
  };

  const getAssignment = () => {
    const body = { contractIndex: data.contractIndex, id: data.assignmentId };
    return new Promise((resolve, reject) => {
      request.post(
        '/assignment/getForModalAssignment',
        body,
        ({ assignment }) => {
          setAssignment(assignment);
          resolve();
        },
        (error) => {
          reject(error);
        },
      );
    });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const promises = [];

      if (!assignment) promises.push(getAssignment());
      if (!judgesList) promises.push(getJudges());
      if (!lawyersList) promises.push(getLawyers());
      if (!placesList) promises.push(getPlaces());
      if (!courtsList) promises.push(getCourts());

      await Promise.all(promises);
    } catch (err) {
      error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Modal className="modalWalletAssignment" title={'Вихід'} open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <div className={`item-external${assignment?.incorrect ? ' incorrect' : ''}`}>
          <div className="item-assignment">
            <div className="fields">
              <span>
                <b>Номер виходу:</b> {assignment?.i}
              </span>

              <span>
                <b>ПІБ клієнта:</b> {assignment?.clientName}
              </span>

              <span>
                <b>Договір:</b> {assignment?.contractNumber}
              </span>

              <span>
                <b>Філія:</b> {FILII[assignment?.f]}
              </span>

              <span>
                <b>Час:</b> {assignment?.selectedTime}
              </span>

              <span>
                <b>Послуга:</b> {ASSIGNMENTS_SERVICES_TYPE[assignment?.serviceType]}
              </span>

              {assignment?.serviceType === ASSIGNMENT_SERVICE.OTHER ? (
                <span>
                  <b>Місце:</b> {placesList?.find((place) => place._id === assignment?.place)?.placeName ?? '-'}
                </span>
              ) : null}

              {assignment?.serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT ? (
                <span>
                  <b>Суддя:</b> {judgesList?.find((judge) => judge._id === assignment?.judgeId)?.name ?? '-'}
                </span>
              ) : null}

              {assignment?.serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT ? (
                <span>
                  <b>Суд:</b> {courtsList?.find((court) => assignment?.court === court.i)?.name}
                </span>
              ) : null}

              <span>
                <b>Судова справа:</b> {JSON.stringify(assignment?.courtCase) ?? '-'}
              </span>

              <span>
                <b>Дата виходу:</b> {dayjs(assignment?.date).format('DD.MM.YYYY')}
              </span>

              <span>
                <b>Адвокат:</b> {lawyersList?.find((lawyer) => lawyer._id === assignment?.lawyerId)?.name ?? '-'}
              </span>

              <span>
                <b>Сума клієнта:</b> {assignment?.clientAmount}
              </span>

              <span>
                <b>Сума для адвоката:</b> {assignment?.amountForLawyer}
              </span>

              <span>
                <b>Оплата клієнта:</b> {assignment?.sugar?.amounts?.length > 0 ? assignment?.sugar?.amounts.reduce((a, b) => a + ', ' + b) : 0}
              </span>

              <span>
                <b>Статус оплати:</b>{' '}
                {assignment?.report
                  ? assignment?.report?.paymentStatus === ASSIGNMENT_PAYMENT_STATUS.PAYED
                    ? 'оплачено'
                    : 'не оплачено'
                  : 'звіт відсутній'}
              </span>

              <span>
                <b>Статус виходу:</b>{' '}
                {assignment?.report
                  ? assignment.report.assignmentStatus === ASSIGNMENT_STATUS.HAPPEN
                    ? 'відбувся'
                    : 'не відбувся'
                  : 'звіт відсутній'}
              </span>

              <span>
                <b>Коментар для своїх:</b> {assignment?.mainComment ?? '-'}
              </span>

              <span>
                <b>Коментар для клієнта:</b> {assignment?.commentForClient ?? '-'}
              </span>

              <span>
                <b>Автор:</b> {users[assignment?.authorId]?.name ?? '-'}
              </span>

              <span>
                <b>Дата створення:</b> {dayjs(assignment?.createdAt).format('DD.MM.YYYY')}
              </span>
            </div>

            <div className="buttons-inner" style={assignment?.incorrect ? { marginTop: '25px' } : undefined}>
              {(assignment?.authorId === userAuth._id || deputyManager) && !assignment?.report ? (
                <Button type="primary" className="button" onClick={() => handleAddReport(assignment)}>
                  <SnippetsOutlined />
                </Button>
              ) : null}

              <Button type="primary" className="button" onClick={() => handleDocuments(assignment)}>
                <ContainerOutlined />
              </Button>

              {admin && (
                <Button
                  style={{ background: `${orange[5]}`, borderColor: `${orange[5]}`, color: gray[1] }}
                  type="primary"
                  className="button"
                  onClick={() => handleAddAssignment(assignment)}
                  icon={<EditOutlined />}
                />
              )}
              {!assignment?.incorrect && (
                <Button type="primary" danger className="button" onClick={() => handleMarkAsIncorrect(assignment)}>
                  <CloseCircleOutlined />
                </Button>
              )}
            </div>
          </div>

          <div className="bottom-buttons">
            {(assignment?.authorId === userAuth._id || deputyManager) && !assignment?.report ? (
              <Button className="bottom-button" icon={<SnippetsOutlined />} onClick={() => handleAddReport(assignment)} type={'primary'}>
                Звітувати
              </Button>
            ) : null}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default WalletAssignment;
