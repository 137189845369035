import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Button, DatePicker } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { setModal } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import dayjs from 'dayjs';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';

import './style.scss';

const AddExpenseLateness = () => {
  const dispatch = useDispatch();

  const { fil } = useSelector((state) => state.common.userAuth);

  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [result, setResult] = useState(false);

  const [sum, setSum] = useState(0);

  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [fillia, setFillia] = useState(fil);

  const getSum = async () => {
    const data = {
      month: selectedMonth.$M + 1,
      year: selectedMonth.$y,
      fil: fillia,
    };

    let amount = 0;

    await request.post(
      '/lateness/getFinesPerMonth',
      data,
      ({ sum }) => {
        amount = sum;
        setSum(amount);
      },
      () => {
        error('', `Помилка при розрахунку витрати.`);
      },
    );

    return amount;
  };

  const handleSubmit = async () => {
    if (!fillia) return error('', `Оберіть філію.`);

    setLoading(true);

    await addExpenseLateness(sum);

    setLoading(false);
  };

  const addExpenseLateness = async (amount) => {
    const expenseData = {
      fil: Number(fillia),
      date: selectedMonth,
      amount: amount,
      category: 9,
      subCategory: 3,
      comment: 'Штраф за запізнення',
    };

    await request.post(
      '/expense/addExpense',
      expenseData,
      () => {
        success('', `Витрата була успішно додана.`);
        setResult(true);
      },
      () => {
        error('', `Помилка при додаванні.`);
      },
    );
  };

  const getExpenses = async () => {
    const expenseData = {
      fil: Number(fillia),
      month: selectedMonth.$M + 1,
      year: selectedMonth.$y,
    };

    await request.post(
      '/expense/getLateness',
      expenseData,
      ({ result }) => {
        result ? setResult(true) : setResult(false);
      },
      () => {
        error('', `Помилка при розрахунку.`);
      },
    );
  };

  const getResult = async () => {
    if (!fillia) return error('', `Оберіть філію.`);

    setAddLoading(true);

    const amount = await getSum();

    if (amount > 0) await getExpenses();
    else setResult('Нема запізнень');

    setAddLoading(false);
  };

  useEffect(() => {
    getResult();
  }, [selectedMonth, fillia]);

  const onClose = () => {
    dispatch(setModal());
  };

  const changeDate = (e) => {
    setSelectedMonth(e.set('date', 28).set('hour', 0));
  };

  const changeFil = (e) => {
    setFillia(e);
  };

  return (
    <Modal className="addExpenseLateness-modal" title="Додати витрати для запізнень" open onCancel={onClose} footer={null}>
      <Spin spinning={loading}>
        <SearchSelectFil onChange={changeFil} value={fillia} />
        <DatePicker.MonthPicker value={selectedMonth} onChange={changeDate} allowClear={false} />
        <div className="buttons">
          <Spin spinning={addLoading}>
            <div className="status" style={{ backgroundColor: `${result === 'Нема запізнень' ? 'gray' : result ? 'green' : 'red'}` }}>
              {result === 'Нема запізнень' ? 'Нема запізнень' : result ? 'Внесено' : 'Не внесено'}
            </div>
          </Spin>

          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={result || addLoading || (selectedMonth.$y === dayjs().$y && selectedMonth.$M === dayjs().$M)}
          >
            <PlusCircleOutlined /> Додати
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default AddExpenseLateness;
