import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import ExportQuantityModal from '../../components/ExportQuantityModal';
import { request, warn, error, success } from '../../tools';

import { REPEATS } from 'common_constants/business';

const ExportMonthlyPayment = () => {
  const contracts = useSelector((state) => state.common.contracts);
  const FILII = useSelector((state) => state.common.FILII);

  const max = contracts[0]?.i;
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(max);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const onGenerate = () => {
    const validation = () => {
      if (!start || !end) {
        warn('', 'Для експорту таблиці, потрібно вказати діапазон кількості елементів', { '!!start': !!start, '!!end': !!end });

        return;
      }

      return true;
    };

    if (!validation()) return;

    setLoading(true);

    const transaction = {
      start,
      end,
    };

    request.post(
      '/spreadsheets/exportMonthlyPayment',
      transaction,
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };

  const csvData = [['№ Договору', 'ID ПІБ Клієнта', 'Дата місячної сплати', 'Сума місячної сплати']];

  data?.forEach((i) => {
    if (+i.i < +start) return;
    if (+i.i > +end) return;

    const client = i.client?.[0] ?? {};

    csvData.push([
      i.i, // * '№ Договору'
      `${client?.n ?? '<без імені>'} ${FILII[i.f] ?? ''} ${REPEATS[i.tm] ?? ''} ${i.od === 1 ? 'Дист' : 'Офіс'} ${i.ov1 ?? ''} ${i.ov2 ?? ''} ${
        i.ov3 ?? ''
      }`, // * 'ID ПІБ Клієнта'
      i.mp ? dayjs(i.mpd).format('DD.MM.YYYY') ?? '' : '', // * 'Дата місячної сплати'
      i.mp ? i.mg ?? '' : '', // * 'Сума місячної сплати'
    ]);
  });

  return (
    <ExportQuantityModal
      title={'Експорт жнив'}
      csvData={csvData}
      onGenerate={onGenerate}
      max={max}
      data={!!data}
      loading={loading}
      startA={start}
      setStartA={setStart}
      endA={end}
      setEndA={setEnd}
    />
  );
};

export default ExportMonthlyPayment;
