import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar } from 'antd';

import { setCurrentHackworksList } from '../../store/commonReducer';
import HackworkItem from './HackworkItem';
import { request, error } from '../../tools';

const HackworksPage = () => {
  const { _id: userId, role, fil } = useSelector((state) => state.common.userAuth);
  const [date, setDate] = useState(new Date().toISOString());
  const dispatch = useDispatch();

  const getHackworks = useCallback(
    (date) => {
      const dateString = date;

      request.post(
        '/hotline/getHackworksByDate',
        { userId, date: dateString },
        (res) => {
          if (res.status) dispatch(setCurrentHackworksList(res.hackworks));
        },
        () => dispatch(setCurrentHackworksList([])),
        error,
      );
    },
    [dispatch, userId],
  );

  const onDateChange = useCallback(
    (value) => {
      const targetDate = value.toISOString();
      getHackworks(targetDate);
      setDate(targetDate);
    },
    [getHackworks],
  );

  const hackworksInitState = useSelector((state) => state.common.currentHackworksList);

  useEffect(() => getHackworks(date), [date, getHackworks]);

  let listToRender = [];

  if (hackworksInitState) {
    listToRender = hackworksInitState.map((e) => {
      return <HackworkItem key={e._id} item={e} userId={userId} userFil={fil} userRole={role} date={date} />;
    });
  }

  const ifEmptyValuesElem = <h3 style={{ textAlign: 'center' }}>Нема доступних халтур за поточну дату</h3>;

  return (
    <div className="list_page default_page">
      <h2>Халтури</h2>
      <Calendar fullscreen={false} onSelect={onDateChange} />
      {listToRender.length ? listToRender : ifEmptyValuesElem}
    </div>
  );
};

export default HackworksPage;
