import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal, List, Button, Popconfirm } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';

import { getFormattedDateWithRelativeDays } from 'common_components';
import { PARTNER_CLIENT_REWARD } from 'common_constants/business';
import { setPartnerBalance } from '../../../store/commonReducer';
import { request, success } from '../../../tools';

import './PartnerInvitationsManagement.scss';

const PartnerInvitationsManagement = (props) => {
  const { partnerId, invitationsManagementState, setInvitationsManagementState, bonusBalance } = props;

  const dispatch = useDispatch();

  const [pageIndex, setPageIndex] = useState(0);
  const [invitationClients, setInvitationClients] = useState([]);

  const handleCancel = () => setInvitationsManagementState(false);

  const handleToggleInviteStatus = (invitedClientId, inviteStatus, invitedClientPhone) => {
    // todo validation of data transmitted in the request ?

    request.post('/partners/toggleInvitedClientStatus', { partnerId, invitedClientId, inviteStatus, invitedClientPhone }, () => {
      success('Статус успішно змінено!');

      const updatedInvitationClients = invitationClients.map((invitationClient) => {
        const targetInvitedClient = invitationClient._id === invitedClientId;

        return targetInvitedClient ? { ...invitationClient, status: !inviteStatus } : invitationClient;
      });

      setInvitationClients(updatedInvitationClients ?? []);

      const updatedBalance = inviteStatus ? bonusBalance - PARTNER_CLIENT_REWARD : bonusBalance + PARTNER_CLIENT_REWARD;

      dispatch(setPartnerBalance({ partnerId, amount: updatedBalance }));
    });
  };

  useEffect(() => {
    request.post('/partners/getPartnerDataFull', { partnerId }, ({ partner_invitedClients }) => {
      setInvitationClients(partner_invitedClients ?? []);
    });
  }, []);

  const dataSource = [...invitationClients].reverse();

  return (
    <Modal title="Управління запрошеннями" open={invitationsManagementState} onCancel={handleCancel} footer={false}>
      <List
        className="insurance-transaction-list"
        pagination={{
          pageSize: 5,
          style: { textAlign: 'center' },
          onChange: (page) => setPageIndex(page * 5 - 5),
        }}
        grid={{
          gutter: 16,
          column: 1,
        }}
        dataSource={dataSource}
        renderItem={(item, index) => {
          const itemDateBefore = dataSource[index + pageIndex - 1]?.dateOfInvitation;

          const confirm = () => handleToggleInviteStatus(item._id, item.status, item.phone);

          return (
            <>
              <span className="partnerInvitations-list-item-date">
                {index === 0 || dayjs(itemDateBefore).format('DD-MM-YYYY') !== dayjs(item.dateOfInvitation).format('DD-MM-YYYY')
                  ? getFormattedDateWithRelativeDays(item.dateOfInvitation)
                  : ''}
              </span>
              <li className="partnerInvitations-list-item">
                <div className="partnerInvitations-list-item__date">{dayjs(item.dateOfInvitation).format('DD.MM.YYYY HH:mm:ss')}</div>
                <div className="partnerInvitations-list-item__status">
                  <span>{item.phone}</span>
                  <span>
                    {item.status ? (
                      <CheckCircleTwoTone twoToneColor={'#52c41a'} style={{ marginRight: '4px' }} />
                    ) : (
                      <CloseCircleTwoTone twoToneColor={'#d50000'} style={{ marginRight: '4px' }} />
                    )}
                    {item.status ? 'Підтверджено' : 'Не підтверджено'}
                  </span>
                </div>
                <div className="partnerInvitations-list-item__button">
                  <Popconfirm title="Змінити статус запрошення?" onConfirm={confirm} okText="Так" cancelText="Ні">
                    <Button style={{ width: '110px' }} type={'primary'}>
                      {item.status ? 'Скасувати' : 'Підтвердити'}
                    </Button>
                  </Popconfirm>
                </div>
              </li>
            </>
          );
        }}
      />
    </Modal>
  );
};

export default PartnerInvitationsManagement;
