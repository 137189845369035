import { useState, useEffect, useRef } from 'react';
import { Progress } from 'antd';

const FRAME_RATE = 100;

const TimeLine = ({ period }) => {
  const [time, setTime] = useState(0);
  const timeRef = useRef(time);

  useEffect(() => {
    const makeStep = () => {
      if (timeRef.current >= period) {
        setTime(0);
        timeRef.current = 0;
      } else {
        setTime((prev) => prev + FRAME_RATE);
        timeRef.current += FRAME_RATE;
      }
    };

    const interval = setInterval(makeStep, FRAME_RATE);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      (Авто-оновлення данних)
      <Progress percent={(time * 100) / period} strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} showInfo={false} />
    </>
  );
};

export default TimeLine;
