import React, { useEffect, useState, useMemo } from 'react';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Tabs, Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES } from 'common_constants/business';
import { TASK_INFO, TASKS_FILTER } from 'common_constants/modals';

import { setTasks, setModal, setTasksFilter } from '../../store/commonReducer';
import { request, error } from '../../tools';
import TaskList from './TaskList';

import './Tasks.scss';

const getListTitle = (title) => {
  switch (title) {
    case 'ALL':
      return 'Дати завдання';
    case 'MY':
      return 'Завдання мені';
    case 'DONE':
      return 'Зроблені';
    default:
      return title;
  }
};

const getItemsForTabs = (currentTasks) => {
  let keys = Object.keys(currentTasks);

  return keys.map((item) => ({
    label: getListTitle(item),
    key: item,
    children: <TaskList title={getListTitle(item)} listName={item} data={currentTasks[item]} />,
  }));
};

const Tasks = () => {
  const dispatch = useDispatch();
  const currentTasks = useSelector((state) => state.common.tasks);
  const userAuth = useSelector((state) => state.common.userAuth);
  const isMob = useSelector((state) => state.ui.mob);
  const tasksFilter = useSelector((state) => state.common.tasksFilter);

  const [isLoading, setIsLoading] = useState(false);
  const isBlockManager = ROLES[userAuth.role] <= ROLES.blockManager;

  const isTasksFilterSelected = useMemo(
    () => tasksFilter.fil || tasksFilter.author || tasksFilter.contractIndex || tasksFilter.contractClientName || tasksFilter.appointed?.length > 0,
    [tasksFilter],
  );

  const onClickOpenCreationTask = () => {
    dispatch(setModal({ name: TASK_INFO, taskId: null, listName: 'ALL' }));
  };

  const onClickFilter = () => {
    dispatch(setModal({ name: TASKS_FILTER }));
  };

  const onClickClearFilter = () => {
    dispatch(
      setTasksFilter({
        fil: null,
        appointed: [],
        contractIndex: null,
        author: null,
      }),
    );
  };

  const getTasks = () => {
    setIsLoading(true);

    const reqData = {
      ...tasksFilter,
    };

    request.post(
      '/tasks/get',
      reqData,
      ({ data }) => {
        dispatch(setTasks(data));
        setIsLoading(false);
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    getTasks();
  }, [tasksFilter]);

  return (
    <section className="default_page tasks">
      <Spin spinning={isLoading}>
        <div className="tasks-header">
          <h2>Завдання</h2>
          <div className="">
            <Button type="text" icon={<FilterOutlined />} onClick={onClickFilter}>
              Фільтр
            </Button>
            {isTasksFilterSelected ? (
              <Button type="link" onClick={onClickClearFilter}>
                <u>Очистити</u>
              </Button>
            ) : null}
            {isBlockManager ? (
              <Button className="add-btn" icon={<PlusOutlined />} type="primary" onClick={onClickOpenCreationTask}>
                Додати завдання
              </Button>
            ) : null}
          </div>
        </div>

        {isMob ? (
          <Tabs centered items={getItemsForTabs(currentTasks)} />
        ) : (
          <div className="tasks-wrapper">
            {Object.keys(currentTasks).map((listKey, index) => (
              <TaskList key={index} title={getListTitle(listKey)} listName={listKey} />
            ))}
          </div>
        )}
      </Spin>
    </section>
  );
};

export default Tasks;
