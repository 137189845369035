import clsx from 'clsx';

const Row = ({ children, className, style }) => (
  <div
    className={clsx('row', className)}
    style={{
      display: 'flex',
      flexDirection: 'row',
      ...style,
    }}
  >
    {children}
  </div>
);

export default Row;
