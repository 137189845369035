import { Alert } from 'antd';
import React from 'react';

export default function RatingMiddleAlert({ currentItemRating, tip }) {
  if (currentItemRating !== 'Середня' || !tip) return null;
  return (
    <Alert
      message="Середня"
      description={
        <>
          <b>Скажіть, будь ласка, в якій області Ви знаходитесь?</b> <br />
          Після відповідіперечислюємоо усі наші офіси у відповідній області. <br />
          <b>У Вашій області ми маємо офіси...</b> (називаємо усі офіси в області) <br /> <b>Коли Вам буде зручно під'їхати до нас на офіс?</b>
          <br />
          Якщо людина записується на офісну консультацію, то вона безкоштовна якщо це перша консультація. <br /> Якщо клієнт не може приіхати до офісу
          пропонуємо дистанційну консультацію вона вже платна
          <br />
          <br />
          Якшо обрана галочка "Лінивець" консультація рахується середньою (Для лінивця офісна консультація платна)
        </>
      }
      type="info"
      showIcon
    />
  );
}
