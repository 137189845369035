import { Card, Input, Button, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { request } from '../../tools';
import { useSelector, useDispatch } from 'react-redux';
import { error, success } from '../../tools';
import { updateClientFeedbackList } from '../../store/commonReducer';
import { setModal } from '../../store/commonReducer';
import { PUBLISH_FEEDBACK } from 'common_constants/modals';

const ClientFeedbackInfo = ({ data, setLoading }) => {
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState(data?.answer);
  const [answerAuthor, setAnswerAuthor] = useState(data?.answerAuthor);
  const [disabled, setDisabled] = useState(data?.processed);
  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);

  const enableButton = () => {
    setDisabled(false);
  };

  const handleAnswer = () => {
    setLoading(true);

    const body = {
      id: data._id,
      answer,
      answerAuthor,
      inspector: userAuth._id,
      processed: true,
    };
    request.post(
      '/clientFeedback/addAnswer',
      body,
      (req) => {
        if (req.status) {
          setLoading(false);
          success();
          dispatch(updateClientFeedbackList(req.data));
          setDisabled(req?.data?.processed);
        }
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  return (
    <Card className="harvestInfo">
      <span>
        <b>Оцінка:</b> {data.rating}
      </span>
      <span>
        <b>Ім'я:</b> {data.name}
      </span>
      <span>
        <b>Дата:</b> {dayjs(data.payday).format('DD.MM.YYYY')}
      </span>
      <span>
        <b>Пошта:</b> {data.email}
      </span>
      <span>
        <b>Мова:</b> {data.language}
      </span>
      <span>
        <b>Відгук:</b> {data.message}
      </span>
      <div className="feedback-answer">
        <div>
          <b>Відповісти: </b>
        </div>
        <Input.TextArea
          style={{ marginBottom: '10px' }}
          disabled={disabled}
          defaultValue={data?.answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
        <Select
          showSearch
          allowClear
          disabled={disabled}
          defaultValue={data?.answerAuthor ? data?.answerAuthor : null}
          value={answerAuthor}
          placeholder="Оберіть автора відгуку"
          onChange={(value) => setAnswerAuthor(value)}
          style={{ maxWidth: 300, width: '100%', marginTop: 8 }}
          filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {Object.values(users)
            .filter((user) => user?.fired !== true)
            .map((user, i) => (
              <Select.Option value={user._id} key={i}>
                {user.p}
              </Select.Option>
            ))}
        </Select>
        <div className="feedback-answer-buttons">
          <Button
            type="primary"
            onClick={() => {
              disabled ? enableButton() : handleAnswer();
            }}
          >
            {disabled ? 'Змінити відповіть' : 'Відповісти'}
          </Button>
          <Button
            type="primary"
            disabled={!data?.processed || !disabled}
            style={{ width: 'auto' }}
            onClick={() => dispatch(setModal({ name: PUBLISH_FEEDBACK, data }))}
          >
            {data?.published ? 'Відмінити публікацію' : 'Опублікувати'}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ClientFeedbackInfo;
