import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { CLUB_CARD_PRICES, CLUB_CARDS_TYPES, PAYMENT_STATUS, CLUB_CARDS_PERIODS } from 'common_constants/business';

import './BuyClubCardModal.scss';
import ClubCardInfoAccordion from './ClubCardInfoAccordion';

const ByClubCardInfo = () => {
  const currentPeriod = CLUB_CARDS_PERIODS[1];

  const [loading, setLoading] = useState(false);
  const [cardType, setCardType] = useState(CLUB_CARDS_TYPES.GOLD);
  const [period, setPeriod] = useState(currentPeriod ? currentPeriod : 0);

  const isSilverCard = cardType === CLUB_CARDS_TYPES.SILVER;
  const firstCardTypePrice = CLUB_CARD_PRICES[0][cardType]?.AMOUNT;
  const selectedCardTypePrice = CLUB_CARD_PRICES[period]?.[cardType]?.AMOUNT;
  const periodMonths = CLUB_CARD_PRICES[period]?.PERIOD.MONTHS;
  const totalFirstTypePrice = firstCardTypePrice * periodMonths;
  const totalSecondTypePrice = selectedCardTypePrice * periodMonths;
  const priceDifference = totalFirstTypePrice - totalSecondTypePrice;

  const goldCardNavTitleClass = cardType === CLUB_CARDS_TYPES.GOLD ? 'checked' : '';
  const platinumCardNavTitleClass = cardType === CLUB_CARDS_TYPES.PLATINUM ? 'checked' : '';

  const checkboxClass = (index) => (period === index ? 'checked' : '');

  const periodsRef = useRef(null);

  const rowItems = isSilverCard
    ? ''
    : Object.keys(CLUB_CARD_PRICES).map((item, index) => {
        return (
          <li key={index} className="row" onClick={() => setPeriod(index)}>
            <div className={`checkbox ${checkboxClass(index)}`}>
              <span></span>
              <span></span>
            </div>
            <div className={`label ${checkboxClass(index)}`}>
              <span>{CLUB_CARD_PRICES[index].PERIOD.LABEL}</span>
              <span>{CLUB_CARD_PRICES[index][cardType].LABEL} грн/місяць</span>
            </div>
          </li>
        );
      });

  return (
    <div className="clubCardPay">
      <div className="inner">
        <div className="tab" style={{ display: 'flex', justifyContent: 'space-around' }}>
          <span className={`item ${goldCardNavTitleClass}`} onClick={() => setCardType(CLUB_CARDS_TYPES.GOLD)}>
            {CLUB_CARDS_TYPES.GOLD}
          </span>
          <div className={`item ${platinumCardNavTitleClass}`} onClick={() => setCardType(CLUB_CARDS_TYPES.PLATINUM)}>
            {CLUB_CARDS_TYPES.PLATINUM}
          </div>
        </div>

        <ClubCardInfoAccordion cardType={cardType} />
        <ul className="body" ref={periodsRef}>
          {rowItems}
        </ul>
        {!isSilverCard && (
          <>
            <div className="total">
              <span className="title">Економія</span>
              <span className="amount">{priceDifference}&nbsp;</span>
            </div>
            <div className="total">
              <span className="title">Загальна вартість</span>
              <span className="amount">{selectedCardTypePrice * periodMonths}</span>
            </div>{' '}
          </>
        )}
      </div>
    </div>
  );
};

export default ByClubCardInfo;
