import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { selectUsers, selectUserAuth } from '../../../../store/commonReducer';
import openai from '../../images/openai.svg';
import UserAvatar from '../../../../components/UserAvatar/UserAvatar';
import { selectPlaceholder } from '../../../../store/aiReducer';

const Placeholder = () => {
  const users = useSelector(selectUsers);
  const { _id: userId } = useSelector(selectUserAuth);
  const compareIds = ({ _id }) => _id === userId;
  const currentUser = users && Object.values(users)?.find(compareIds);
  const placeholder = useSelector(selectPlaceholder);

  if (!placeholder.status) return null;

  return (
    <Col className="dialogue">
      <Row key={0} className={`message user`}>
        <Row className="header">
          <Row className="avatar">
            <UserAvatar user={currentUser} />
          </Row>
          <Row className="label">
            <Row>{currentUser?.name}</Row>
          </Row>
        </Row>
        <Row className="content">{placeholder.message}</Row>
      </Row>
      <Row key={1} className="message assistant">
        <Row className="header">
          <Row className="avatar">
            <img src={openai} alt="openai" />
          </Row>
          <Row className="label">
            <Row>ChatGPT</Row>
          </Row>
        </Row>
        <Row className="content">
          <span className="typing-indicator"></span>
        </Row>
      </Row>
    </Col>
  );
};

export default Placeholder;
