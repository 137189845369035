import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spin, Modal, Form, Input, Button, InputNumber } from 'antd';
import { addPartner, setModal } from '../../store/commonReducer';
import { request, success, error } from '../../tools';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
import { COUNTRY_PHONE_CODES } from 'common_constants/business';
import CountryPhCode from '../../components/CountryPhCode';

const AddPartner = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    phone: '',
    countryPhCode: COUNTRY_PHONE_CODES.UA, // Default to UA
  });

  const handleFormSubmit = async () => {
    setLoading(true);

    const partnersUserData = {
      name: form.getFieldValue('name'),
      fil: form.getFieldValue('fil'),
      email: form.getFieldValue('email'),
      password: form.getFieldValue('password'),
      countryPhCode: form.getFieldValue('countryPhCode'),
      phone: form.getFieldValue('phone'),
      certificate: form.getFieldValue('certificate'),
      position: form.getFieldValue('position'),
      share: form.getFieldValue('share'),
    };

    await request.post(
      '/partnersUsers/addPartnersUser',
      partnersUserData,
      ({ data }) => {
        success('', `Партнер був успішно доданий.`);
        dispatch(setModal(false));
        setLoading(false);
        dispatch(addPartner(data));
      },
      () => {
        error('', `Помилка при додаванні.`);
        setLoading(false);
      },
    );
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    setFormData((prevData) => ({
      ...prevData,
      phone: input.slice(0, 10),
    }));
  };

  const handleCountryPhCodeChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      countryPhCode: value,
    }));
  };

  return (
    <Modal title="Додати нового партнера" open onCancel={() => dispatch(setModal(false))} footer={null}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleFormSubmit} initialValues={formData} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Form.Item label="ПІБ" name="name" rules={[{ required: true, message: 'Введіть ПІБ партнера' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Філія" name="fil" rules={[{ required: true, message: 'Введіть філію' }]}>
            <SearchSelectFil />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Введіть коректний email' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Введіть пароль' }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item name="phone" label="Телефон" rules={[{ required: true, message: 'Введіть телефон' }]}>
            <div style={{ display: 'flex' }}>
              <CountryPhCode value={formData.countryPhCode} onChange={handleCountryPhCodeChange} />
              <Input value={formData.phone} onChange={handlePhoneChange} maxLength={10} placeholder="Введіть номер телефону" />
            </div>
          </Form.Item>

          <Form.Item label="№ посвідчення" name="certificate" rules={[{ required: true, message: 'Введіть № посвідчення' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Доля %" name="share" rules={[{ required: true, message: 'Введіть долю' }]}>
            <InputNumber min={0} max={100} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit">
              Додати
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddPartner;
