import { HOTLINE_CALL_REPORT_FIELDS_CHECK } from 'common_constants/business';

export const populateHotlineCalls = (hotlines, calls, clients, contracts) => {
  return hotlines.map((hotline) => {
    const call = hotline.call_id ? calls.find((c) => hotline.call_id === String(c.callId)) : undefined;
    const client = clients.find((client) => client.ph === hotline.phone);
    const contract = contracts.find((c) => String(hotline.K) === String(c._id));

    let filled = HOTLINE_CALL_REPORT_FIELDS_CHECK.filter((field) => call?.[field]).length;

    return {
      ...call,
      hotline,
      client,
      reportPercent: ((filled / HOTLINE_CALL_REPORT_FIELDS_CHECK.length) * 100).toFixed(0) + '%',
      contract,
    };
  });
};

export const populateCalls = (noHotlineCalls, clients) => {
  return noHotlineCalls.map((call) => {
    const client = clients.find((client) => client.ph === `0${call.from}`);

    let filled = HOTLINE_CALL_REPORT_FIELDS_CHECK.filter((field) => call?.[field]).length;

    return {
      ...call,
      client,
      reportPercent: ((filled / HOTLINE_CALL_REPORT_FIELDS_CHECK.length) * 100).toFixed(0) + '%',
    };
  });
};
