import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import ExportQuantityModal from '../../components/ExportQuantityModal';
import { request, warn, error, success } from '../../tools';

import {
  REPEATS,
  PRAVO,
  DECIMAL_RATING_TO_CLIENT,
  JOB_SATISFACTION_TO_CLIENT,
  LOGICAL_STANDARD_CHOISE_4_OPTIONS,
  LOGICAL_STANDARD_CHOISE_2_OPTIONS,
  LOGICAL_ARCHIVE_CANDY_CHOISE_3_OPTIONS,
} from 'common_constants/business';

const ExportArchivalQuestionnaires = () => {
  const contractQuestionaireMaxIndex = useSelector((state) => state.common.contractQuestionaireMaxIndex);
  const FILII = useSelector((state) => state.common.FILII);

  const max = contractQuestionaireMaxIndex;
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(max);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const onGenerate = () => {
    const validation = () => {
      if (!start || !end) {
        warn('', 'Для експорту таблиці, потрібно вказати діапазон кількості елементів', { '!!start': !!start, '!!end': !!end });

        return;
      }

      return true;
    };

    if (!validation()) return;

    setLoading(true);

    const transaction = {
      start,
      end,
    };

    request.post(
      '/spreadsheets/exportArchivalQuestionnaires',
      transaction,
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };

  const csvData = [
    [
      '№',
      '№ Договору',
      'ID ПІБ Клієнта',
      'Номер телефону',
      'Дата договору',
      'Філія',
      'Кто уклав',
      'Дата архіву',
      'Що саме',
      // Анкета
      'Задоволені результатом',
      'Задоволення роботою?',
      'Задоволення комунікацією?',
      'Звернувся б ще?',
      'Порадили комусь?',
      'Довіряєте компанії?',
      'Чи сподобався подарунок?',
      'Пропозиції та скарги',
    ],
  ];

  data?.forEach((i) => {
    const client = i.client?.[0] ?? {};
    const crm_user = i.crm_user?.[0] ?? {};

    csvData.push([
      i.qa?.i, // * '№'
      i.i ?? '', // * '№ Договору'
      `${client?.n ?? '<без імені>'} ${FILII[i.f] ?? ''} ${REPEATS[i.tm] ?? ''} ${i.od === 1 ? 'Дист' : 'Офіс'} ${i.ov1 ?? ''} ${i.ov2 ?? ''} ${
        i.ov3 ?? ''
      }`, // * 'ID ПІБ Клієнта'
      client?.ph ?? '', // * 'Номер телефону'
      dayjs(i.sd)?.format('DD-MM-YYYY') ?? '', // * 'Дата договору'
      FILII[i.f] ?? '', // * Філія
      crm_user.name ?? '', // * 'Кто уклав'
      dayjs(i.ad)?.format('DD-MM-YYYY') ?? '', // * 'Дата архіву',
      PRAVO[i.prc] ?? '', // * 'Що саме (Тип права)'
      DECIMAL_RATING_TO_CLIENT[i.qa?.sq] ?? '', // *  Оцінка якості.
      JOB_SATISFACTION_TO_CLIENT[i.qa?.sw] ?? '', // *  Задоволення роботою?
      LOGICAL_STANDARD_CHOISE_4_OPTIONS[i.qa?.sc] ?? '', // *  Задоволення комунікацією?
      LOGICAL_STANDARD_CHOISE_2_OPTIONS[i.qa?.ca] ?? '', // *  Звернувся б ще?
      LOGICAL_STANDARD_CHOISE_2_OPTIONS[i.qa?.as] ?? '', // *  Порадили комусь?
      LOGICAL_STANDARD_CHOISE_4_OPTIONS[i.qa?.ts] ?? '', // *  Довіряєте компанії?
      LOGICAL_ARCHIVE_CANDY_CHOISE_3_OPTIONS[i.qa?.ac] ?? '', // * Чи сподобався подарунок? (архівна цукерка)
      i.qa?.pc ?? '', // *  Пропозиції та скарги.
    ]);
  });

  return (
    <ExportQuantityModal
      title={'Експорт анкет після контрактів'}
      csvData={csvData}
      onGenerate={onGenerate}
      max={max}
      data={!!data}
      loading={loading}
      startA={start}
      setStartA={setStart}
      endA={end}
      setEndA={setEnd}
    />
  );
};

export default ExportArchivalQuestionnaires;
