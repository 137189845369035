import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Pagination, Row, Spin, Typography } from 'antd';
import { ADD_PARTNER } from 'common_constants/modals';
import { setModal, setPartnersUsers } from '../../store/commonReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { PAGE_SIZE_OPTIONS } from 'common_constants/db';
import { error, request, success } from '../../tools';
import PartnersUsersItem from './PartnersUsersItem';

import './PartnersUsers.scss';

const PartnersUsers = () => {
  const dispatch = useDispatch();

  const partnersUsers = useSelector((state) => state.common.partnersList);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [loading, setLoading] = useState(false);

  const { Text } = Typography;

  const getAllPartnersUsers = (limits) => {
    setLoading(true);
    const params = { ...limits };

    request.post(
      '/partnersUsers/getAllPartnersUsers',
      params,
      ({ data, total }) => {
        dispatch(setPartnersUsers(data));
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    const limits = {
      page: currentPage,
      pageSize,
    };
    getAllPartnersUsers(limits);
  }, [currentPage, pageSize]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deletePartnersUser = (id) => {
    const onSuccess = () => {
      const updatedPartnersUsers = partnersUsers.map((partnersUser) => (partnersUser._id === id ? { ...partnersUser, isFired: true } : partnersUser));
      dispatch(setPartnersUsers(updatedPartnersUsers));
      success('Успіх!', 'Партнера успішно відзначено як звільненого!');
    };

    const onError = () => error('Помилка!', 'Схоже, не вдалося відзначити партнера як звільненого!');

    request.post(`/partnersUsers/markAsFired/${id}`, null, onSuccess, onError);
  };

  return (
    <div className="partnersUsers_page default_page">
      <h2>Партнери</h2>

      <Row style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_PARTNER }))}>
          <PlusCircleOutlined /> Додати
        </Button>
      </Row>

      <br />
      <Spin spinning={loading} tip="Зачекайте" size="large">
        <Row gutter={[24, 16]}>
          {partnersUsers?.length ? (
            partnersUsers.map((item) => (
              <Col key={item._id} lg={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <PartnersUsersItem item={item} deletePartnersUser={deletePartnersUser} />
              </Col>
            ))
          ) : (
            <p className="filii_page__filtered-filii-not-found">
              <Empty description={<Text>Немає даних</Text>} />
            </p>
          )}
        </Row>
        <br />
        <Pagination style={{ textAlign: 'center' }} current={currentPage} pageSize={pageSize} total={total} onChange={handlePageChange} />
      </Spin>
    </div>
  );
};

export default PartnersUsers;
