import React, { useState, useEffect } from 'react';
import { TOWNS } from 'common_constants/business';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'antd';
import { CSVLink } from 'react-csv';

import { setModal } from '../../store/commonReducer';
import { request, error, success } from '../../tools';
import Excel from '../../images/excel_logo.svg';

import './styles.scss';

const csvData = [['id клієнта', 'ПІБ', 'Номер телефону', 'День народження', 'Філія', 'Місто']];

const ExportDisabledPeople = () => {
  const dispatch = useDispatch();
  const FILII = useSelector((state) => state.common.FILII);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const onGenerate = async () => {
    setLoading(true);

    await request.post(
      '/spreadsheets/exportDisabledPeople',
      {},
      ({ data }) => {
        setData(data);
      },
      error,
    );

    setLoading(false);
  };

  useEffect(() => {
    data?.forEach(({ _id, n, ph, countryPhCode, b, f, t }) => {
      csvData.push([_id, n, `${countryPhCode ? countryPhCode : ''}${ph}`, b, FILII?.[f], TOWNS?.[t]]);
    });
  }, [data]);

  const handleCloseModal = () => dispatch(setModal());

  return (
    <Modal
      open
      title="Експорт клієнтів, які не можуть користуватися єАдвокат"
      onCancel={handleCloseModal}
      footer={null}
      className="export-disabled-model-modal"
    >
      <div className="content">
        {data ? (
          <CSVLink asyncOnClick={true} data={csvData}>
            <Button className="excel">
              <img className="excel-img" src={Excel} alt="Excel" />
              <p>Скачати .csv</p>
            </Button>
          </CSVLink>
        ) : (
          <Button onClick={onGenerate} loading={loading}>
            Генерувати
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ExportDisabledPeople;
