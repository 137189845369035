import React from 'react';
import { LEVEL_STANDARD_4_OPTIONS } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralCharacteristic = [
  LEVEL_STANDARD_4_OPTIONS,
  ['Хрт: Інтелект', 'ch1_C'],
  ['Хрт: Критичність мислення', 'ch2_C'],
  ['Хрт: Ініціативність', 'ch3_C'],
  ['Хрт: Поступливість', 'ch4_C'],
  ['Хрт: Дружелюбність', 'ch5_C'],
  ['Хрт: Емоційність', 'ch6_C'],
];

const SelectGeneralCharacteristic = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть рівень характеристики"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі рівні характеристики</Option>
      {Object.entries(LEVEL_STANDARD_4_OPTIONS).map((i) => (
        <Select.Option key={i[0]} value={i[0]}>
          {i[1]}
        </Select.Option>
      ))}
    </Select>
  );
};

export { billSelectGeneralCharacteristic, SelectGeneralCharacteristic };
