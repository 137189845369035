import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Popconfirm } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { PRAVO, ROLES } from 'common_constants/business';
import { EDIT_FEE, ADD_FEE } from 'common_constants/modals';

import { setModal, deleteFee } from '../../store/commonReducer';
import { request } from '../../tools';

import './FeeExploration.scss';

const FeeInfo = ({ data }) => {
  const users = useSelector((state) => state.common.users);
  const admin = useSelector((state) => ROLES[state.common.userAuth.role] === ROLES.admin);
  const deputyManager = useSelector((state) => ROLES[state.common.userAuth.role] <= ROLES.deputyManager);
  const FILII = useSelector((state) => state.common.FILII);

  const dispatch = useDispatch();

  const deleteFeeHandler = () => {
    if (!data._id) return;

    request.post(`/fee/delete/${data._id}`, {}, (res) => {
      dispatch(deleteFee(data._id));
    });
  };

  if (!data) return 'Відсутня інформація';
  return (
    <Card className="feeInfo">
      <div className="feeInfo__content">
        <div>
          <h6>Загальна інформація</h6>
          <div>
            <b>Автор:</b> {users[data.author]?.name}
          </div>
          <div>
            <b>Філія:</b> {FILII[data.fil]}
          </div>
          <div>
            <b>Дата взяття гонорару:</b> {dayjs(data.feeday).format('DD.MM.YYYY HH:mm:ss')}
          </div>
          <div>
            <b>Дата створення:</b> {dayjs(data.createdAt).format('DD.MM.YYYY HH:mm:ss')}
          </div>
        </div>
        <div>
          <h6>Дані про вид правовідносин, щодо яких проводиться розвідка</h6>
          <div>
            <b>Форма консультації:</b> {data.typeOfConsultation === 'online' ? 'онлайн' : 'офісна'}
          </div>
          <div>
            <b>Вид правовідносин:</b> {PRAVO[data.pravoType] || data.pravoType}
          </div>
          {data.pravoDescription && (
            <div>
              <b>Пояснення виду правовідносин:</b> {data.pravoDescription}
            </div>
          )}
        </div>
        <div>
          <h6>Дані про гонорар</h6>
          <div>
            <b>Сума:</b> {data.amount}&#8372;
          </div>
          <div>
            <b>Вихід адвоката:</b> {data.lawyerWithdrawal}
          </div>
          <div>
            <b>Коментар:</b> {data.comment}
          </div>
        </div>
        <div>
          <h6>Дані про адвоката</h6>
          <div>
            <b>Назва компанії або ПІБ:</b> {data.lawyerName}
          </div>
          <div>
            <b>Телефон:</b> {data.lawyerTelephone}
          </div>
        </div>
        {deputyManager && !data.removed && (
          <Button type="primary" icon={<EditOutlined />} onClick={() => dispatch(setModal({ name: ADD_FEE, fee: data }))}>
            Редагувати
          </Button>
        )}
        {admin && !data.removed && (
          <Popconfirm
            className="table-actions__delete"
            title="Видалення розвідки"
            description="Ви дійсно хочете видалити дану розвідку?"
            onConfirm={deleteFeeHandler}
            okText="Так"
            cancelText="Ні"
          >
            <Button> Видалити</Button>
          </Popconfirm>
        )}
      </div>
    </Card>
  );
};

export default React.memo(FeeInfo);
