import { SearchOutlined } from '@ant-design/icons';
import { Button, Collapse, DatePicker, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './WalletSearch.scss';
import { sortUsersByRole } from '../../../../tools';

const { Panel } = Collapse;

const WalletSearch = ({
  users,
  inspector,
  filterState,
  handleFilialChange,
  handleUserChange,
  handleSearch,
  handleStartDateChange,
  handleEndDateChange,
  handleClearFilters,
}) => {
  const FILII = useSelector((state) => state.common.FILII);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleCollapseChange = () => setIsExpanded(!isExpanded);
  const sortedUsers = sortUsersByRole(users);
  return (
    <Collapse className="walletFilter" in={isExpanded} onChange={handleCollapseChange}>
      <Panel header="Пошук" key={'search'}>
        <Row className="items" justify={'space-between'}>
          <Row className="item" align={'middle'}>
            <Row>Філія:&nbsp;</Row>
            <Select
              showSearch
              filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={filterState.filial}
              onChange={handleFilialChange}
              disabled={!inspector}
            >
              {FILII.map((i, index) => (
                <Select.Option key={i} value={String(index)}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Row>

          <Row className="item" align={'middle'}>
            <Row>Працівник:&nbsp;</Row>
            <Select
              showSearch
              filterOption={(input, option) => option.children?.[0]?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={filterState.userId}
              onChange={handleUserChange}
            >
              {sortedUsers.map((user) => (
                <Select.Option key={user._id} value={String(user._id)}>
                  {user.p} - {user.name}
                </Select.Option>
              ))}
            </Select>
          </Row>

          <Row className="item" align={'middle'}>
            <Row>Від:&nbsp;</Row>
            <DatePicker value={filterState.startDate} onChange={handleStartDateChange} />
          </Row>

          <Row className="item" align={'middle'}>
            <Row>До:&nbsp;</Row>
            <DatePicker value={filterState.endDate} onChange={handleEndDateChange} />
          </Row>
        </Row>

        <Row justify={'center'}>
          <Button className="button" onClick={handleClearFilters}>
            Очистити
          </Button>
          <Button icon={<SearchOutlined />} className="button" type="primary" onClick={handleSearch}>
            Пошук
          </Button>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default React.memo(WalletSearch);
