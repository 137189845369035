import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Calendar, DatePicker, Select, Row, Table, Collapse, Radio } from 'antd';
import { OPTIONS_WITH_DISABLED_VIA_CLIENT_HOTLINES_FILTER } from 'common_constants/business';
import dayjs from 'dayjs';
import { ROLES } from 'common_constants/business/index';

import { request, error, success, warn } from '../../tools';
import { setMainLoader } from '../../store/uiReducer';
import HotlinesByClientsItem from './HotlinesByClientsItem';
import './HotlinesByClients.scss';

const HotlinesByClients = () => {
  const dispatch = useDispatch();

  const FILII = useSelector((state) => state.common.FILII);
  const user = useSelector((state) => state.common.userAuth);
  const inspector = ROLES[user.role] <= ROLES.inspector;

  const [date, setDate] = useState(null);
  const [dateDiapazon, setDateDiapazon] = useState(null);
  const [filState, setFilState] = useState(inspector ? '_' : user?.fil ?? '_');
  const [clientsHotlines, setClientsHotlines] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isProcessed, setIsProcessed] = useState('unprocessed');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [{ Panel }, { Option }, { Group }, { RangePicker }] = [Collapse, Select, Radio, DatePicker];

  const handleCollapseChange = () => setIsExpanded(!isExpanded);

  const handleProcessedChange = ({ target: { value } }) => setIsProcessed(value);
  const handleTableChange = (pagination) => setCurrentPage(pagination.current);

  const getClientsHotlines = async () => {
    dispatch(setMainLoader(true));

    const validation = (_currentPage, _filState) => {
      if (typeof _currentPage !== 'number') {
        warn('', 'Для отримання списку записів, потрібно вказати номер сторінки списку', { _currentPage: !!_currentPage });
        return;
      }

      if (typeof _filState !== 'string') {
        warn('', 'Для отримання списку записів, потрібно вказати філію.', { _filState: !!_filState });
        return;
      }

      return true;
    };

    if (!validation(currentPage, filState)) return;

    const valUndefined = (val) => val === undefined || val === null;

    const transaction = {
      ...(valUndefined(date) ? {} : { targetDate: date }),
      ...(valUndefined(dateDiapazon) ? {} : { dateDiapazon: dateDiapazon }),
      ...(valUndefined(filState) ? {} : { fil: filState }),
      ...(valUndefined(isProcessed) ? {} : { isProcessed: isProcessed }),
      ...(valUndefined(currentPage) ? {} : { page: currentPage }),
    };

    await request.post(
      '/hotline/getClientHotlinesByDate',
      transaction,
      ({ clientsHotlines, total }) => {
        setClientsHotlines(clientsHotlines);
        setTotal(total);
        success();
      },
      error,
    );

    dispatch(setMainLoader(false));
  };

  useEffect(() => {
    if (!isExpanded) {
      setDate(null);
      setDateDiapazon(null);
    }
  }, [isExpanded]);

  useEffect(() => {
    getClientsHotlines();
  }, [date, dateDiapazon, filState, isProcessed, currentPage]);

  const columns = [
    {
      title: 'Список записів клієнтів',
      dataIndex: 'item',
      key: '_id',
      render: (_, record) => <HotlinesByClientsItem refresh={getClientsHotlines} key={record._id} item={record} />,
    },
  ];

  return (
    <div className="hotlinesByClients">
      <h2 style={{ paddingLeft: 0 }}>Записи клієнтів</h2>
      <Collapse in={isExpanded} onChange={handleCollapseChange}>
        <Panel header="Сортування за датою">
          {
            <>
              <Calendar
                style={{ opacity: dateDiapazon ? 0.5 : 1, pointerEvents: dateDiapazon ? 'none' : 'all' }}
                fullscreen={false}
                onSelect={(event) => setDate(dayjs(event))}
                defaultValue={null}
              />
              <Row justify={'center'} className="hotlinesByClients-datepicker-inner">
                <RangePicker
                  onChange={(event) => {
                    if (event) {
                      setDateDiapazon({
                        startDate: event[0].toDate(),
                        endDate: event[1].toDate(),
                      });
                      return;
                    }
                    setDateDiapazon(null);
                  }}
                />
              </Row>
            </>
          }
        </Panel>
      </Collapse>
      <Row justify={'space-between'} className="filters-row">
        <Select
          className="fil"
          showSearch
          defaultValue={filState}
          onChange={(value) => setFilState(value)}
          disabled={!inspector}
          filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value="_">Всі філії</Option>
          {FILII.map((i, index) => (
            <Option key={i} value={String(index)}>
              {i}
            </Option>
          ))}
        </Select>
        <Group
          options={OPTIONS_WITH_DISABLED_VIA_CLIENT_HOTLINES_FILTER}
          onChange={handleProcessedChange}
          value={isProcessed}
          defaultValue={'unprocessed'}
          optionType="button"
          buttonStyle="solid"
        />
      </Row>
      <Table
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={clientsHotlines}
        pagination={{ total, current: currentPage, style: { justifyContent: 'center' } }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default HotlinesByClients;
