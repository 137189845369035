import React from 'react';
import { Select } from 'antd';

const SearchSelectBrigadeZSU = (props) => {
  const { i, usersArr, handleTogglePreappointedUserZsu, style } = props;

  return (
    <Select
      showSearch
      allowClear
      value={i.zsu ? i.zsu : null}
      placeholder="Попереднє призначення ЗСУХ"
      onChange={(value) => handleTogglePreappointedUserZsu(value)}
      style={{ maxWidth: 300, width: '100%', marginTop: 8, ...style }}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {usersArr.map((user, i) => (
        <Select.Option value={user._id} key={i}>
          {user.name + '-' + user.p}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SearchSelectBrigadeZSU;
