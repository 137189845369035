import { Select } from 'antd';
import { ROLES } from 'common_constants/business';
import { useSelector } from 'react-redux';

const SearchSelectUser = (props) => {
  const { i, usersArr, handleTogglePreappointedUser, isIC, style } = props;
  const userAuth = useSelector((state) => state.common.userAuth);
  const rabfilOnly = ROLES[userAuth.role] === ROLES.rabfil;

  return (
    <Select
      showSearch
      allowClear
      value={i.bmm ? i.bmm : null}
      placeholder="Попереднє призначення"
      disabled={rabfilOnly}
      onChange={(value) => handleTogglePreappointedUser(value)}
      style={{ maxWidth: 300, width: '100%', marginTop: 8, ...style }}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {usersArr
        .filter((user) => (isIC ? user.ic === true : i.fil === user.fil || user._id === i.bmm))
        .map((user, i) => (
          <Select.Option value={user._id} key={i}>
            {user.name + '-' + user.p}
          </Select.Option>
        ))}
    </Select>
  );
};

export default SearchSelectUser;
