import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Spin, Modal } from 'antd';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { FIL_ADRESS } from 'common_constants/business';

import { setModal } from '../../store/commonReducer';
import { request, error } from '../../tools';
import Excel from '../../images/excel_logo.svg';

const ExportQuickChats = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const csvData = [['№', 'Дата', 'ФІО', 'Питання', 'Телефон', 'Філія', 'E-mail']];

  const onGenerate = () => {
    setLoading(true);
    request.post(
      '/spreadsheets/exportQuickChats',
      {},
      (req) => {
        setData(req.data);
        setLoading(false);
      },
      error,
    );
  };

  data?.forEach((i, index) => {
    const client = i.client ?? {};
    const chat = i.chat ?? [];

    csvData.push([
      index,
      dayjs(i.createdAt)?.format('DD-MM-YYYY') ?? '',
      client?.n ?? '<без імені>',
      chat?.[0].message,
      client.ph ?? '<без номеру>',
      client.f ? FIL_ADRESS[client.f] ?? '<без філії>' : '<без філії>',
      client.email ?? '<без пошти>',
    ]);
  });

  return (
    <Modal open className="export-hotline" title="Експорт швидких питань" onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="change">
          <br />
          {data ? (
            <CSVLink asyncOnClick={true} data={csvData}>
              <Button className="excel">
                <img className="excel-img" src={Excel} alt="Excel" /> Скачати .csv
              </Button>
            </CSVLink>
          ) : (
            <Button onClick={onGenerate}>Генерувати</Button>
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

export default ExportQuickChats;
