import React, { useEffect, useRef, useState } from 'react';
import { tools } from 'common_components';
import { useDispatch, useSelector } from 'react-redux';
import { CHAT_TYPES } from 'common_constants/business';

import { error } from '../../tools';
import { addNewMessageToChat, updateChat } from '../../store/commonReducer';
import MessagesWrapper from './MessagesWrapper';
import MessagesWrapperForModal from './MessagesWrapperForModal';
import AccountantChatInputWrapper from './AccountantChatInputWrapper';
import InputWrapper from './InputWrapper';

import './style.scss';

const Chat = ({
  onSendMessage,
  onEditMessage,
  onSendFile,
  onGetMessages,
  onLoadMoreMessages,
  onGetFile,
  isDisabled,
  chatType,
  chatId,
  fetchedMessageIndex,
  pinnedMessageScrollId,
  isModal = false,
}) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const fileInputRef = useRef(null);
  const listRef = useRef(null);

  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [sendLoader, setSendLoader] = useState(false);
  const [messagesLoader, setMessagesLoader] = useState(false);
  const [messagesCount, setMessagesCount] = useState(0);
  const [isMoreMessagesFetching, setIsMoreMessagesFetching] = useState(false);
  const [prevMessagesCount, setPrevMessagesCount] = useState(0);
  const [editMode, setEditMode] = useState({
    isEditing: false,
    messageId: '',
    prevMessageValue: '',
  });

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const makeMyMessage = (text) => ({
    message: text,
    sender: userAuth._id,
    date: new Date(),
    name: userAuth.name,
    isSentByCurrentUser: true,
  });

  const makeMyFileMessage = ({ fileId, fileName, fileSize }) => ({
    fileId,
    fileName,
    fileSize,
    sender: userAuth._id,
    date: new Date(),
    name: userAuth.name,
    isSentByCurrentUser: true,
  });

  const handleSend = () => {
    if (inputValue === '') return;
    setSendLoader(true);
    setInputValue('');

    if (editMode.isEditing) {
      onEditMessage({
        messageId: editMode.messageId,
        message: inputValue,
        callback: (res) => {
          resetEditModeData();
          setSendLoader(false);
        },
      });

      return;
    }

    onSendMessage({
      message: inputValue,
      callback: ({ newMessage }) => {
        if (chatType && chatId) {
          dispatch(addNewMessageToChat({ chatType, data: newMessage, chatId }));
        } else if (newMessage) {
          setMessages([...(messages ?? []), { ...newMessage, isSentByCurrentUser: true, name: userAuth.name }]);
        } else {
          setMessages([...(messages ?? []), makeMyMessage(inputValue)]);
        }
        setSendLoader(false);
      },
    });
  };

  const handleAttachFile = (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) return;
    setSendLoader(true);

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('files', file, tools.translit(file.name));
    }

    onSendFile({
      formData: formData,
      callback: (res) => {
        fileInputRef.current.value = '';
        if (Array.isArray(res.data)) {
          const newMessages = res.data.map((fileData) => makeMyFileMessage(fileData));
          if (chatType && chatId) {
            for (const fileData of newMessages) {
              dispatch(addNewMessageToChat({ chatType: chatType, chatId: chatId, data: fileData }));
            }
          } else {
            setMessages([...messages, ...newMessages]);
          }
          setSendLoader(false);

          return;
        }

        if (chatType && chatId) {
          dispatch(addNewMessageToChat({ chatType: chatType, chatId: chatId, data: res.data }));
        } else {
          setMessages([...messages, makeMyFileMessage(res.data)]);
        }

        setSendLoader(false);
      },
      onError: () => {
        setSendLoader(false);
        fileInputRef.current.value = '';
        error('Помилка при відправці файлу');
      },
    });
  };

  const resetEditModeData = () => {
    setEditMode({
      isEditing: false,
      messageId: null,
      prevMessageValue: '',
    });
  };

  const handleEditMode = (item) => {
    setInputValue(item.message);
    setEditMode({
      isEditing: true,
      messageId: item._id,
      prevMessageValue: item.message,
    });
  };

  const handleLoadMoreMessages = () => {
    if (isMoreMessagesFetching) return;

    setPrevMessagesCount(messages?.length);
    onLoadMoreMessages(messages?.length, (data) => {
      dispatch(updateChat({ chatType, data: { _id: chatId, messages: [...(data.messages ?? []), ...(messages ?? [])] } }));
      setMessages((prev) => [...(data.messages ?? []), ...(prev ?? [])]);
      setMessagesCount((prev) => data.messagesCount ?? prev);
      setIsMoreMessagesFetching(false);
      listRef.current.scrollToItem(messages?.length - prevMessagesCount);
    });
  };

  const onCloseEditingModeButton = () => {
    resetEditModeData();
    setInputValue('');
  };

  useEffect(() => {
    setMessagesLoader(true);
    onGetMessages(({ messages, messagesCount }) => {
      setMessages(messages);
      setMessagesCount(messagesCount);
      setMessagesLoader(false);
    });
  }, [onGetMessages]);

  useEffect(() => {
    resetEditModeData();
    setInputValue('');
    setPrevMessagesCount(0);
  }, [chatId, chatType]);

  return (
    <div className="chat-container">
      <div className="chat-comp-wrapper">
        {isModal ? (
          <MessagesWrapperForModal messages={messages} setMessagesLoader={setMessagesLoader} onGetFile={onGetFile} listRef={listRef} />
        ) : (
          <MessagesWrapper
            chatId={chatId}
            chatType={chatType}
            messages={messages}
            messagesCount={messagesCount}
            setMessagesLoader={setMessagesLoader}
            onGetFile={onGetFile}
            handleEditMode={handleEditMode}
            setIsMoreMessagesFetching={setIsMoreMessagesFetching}
            isMoreMessagesFetching={isMoreMessagesFetching}
            prevMessagesCount={prevMessagesCount}
            fetchedMessageIndex={fetchedMessageIndex}
            pinnedMessageScrollId={pinnedMessageScrollId}
            listRef={listRef}
            handleLoadMoreMessages={handleLoadMoreMessages}
          />
        )}

        {chatType === CHAT_TYPES.accountantChat.key ? (
          <AccountantChatInputWrapper chatId={chatId} chatType={chatType} />
        ) : (
          <InputWrapper
            editMode={editMode}
            handleAttachFile={handleAttachFile}
            handleSend={handleSend}
            fileInputRef={fileInputRef}
            onCloseEditingModeButton={onCloseEditingModeButton}
            inputValue={inputValue}
            isDisabled={isDisabled}
            handleInput={handleInput}
            sendLoader={sendLoader}
            isModal={isModal}
          />
        )}
      </div>
    </div>
  );
};

export default Chat;
