import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setCurrentReachList, setCurrentHotlineList } from '../../../store/commonReducer';
import { ROUTES } from 'common_constants/routes';
import { request, success, error, warn } from '../../../tools';

import { Button } from 'antd';
import { SendOutlined, StopOutlined } from '@ant-design/icons';

import '../List.scss';
import '../../HotlineReach/HotlineReach.scss';

const Block5daysProject3000 = ({ data, setLoading }) => {
  const [dispatch, { pathname }] = [useDispatch(), useLocation()];

  const listHotline = useSelector((state) => state.common.currentHotlineList),
    listReach = useSelector((state) => state.common.currentReachList);

  const machiningSetList = (_i) =>
    _i._id === data._id
      ? {
          ..._i,
          bsa5: !data.bsa5,
        }
      : _i;

  const setListHotline = setCurrentHotlineList(listHotline?.map((i) => machiningSetList(i)) || []),
    setListReach = setCurrentReachList(listReach?.map((i) => machiningSetList(i)) || []),
    itemInList = { setList: setListHotline, className: 'btn-list' },
    itemInReach = { setList: setListReach, className: 'btn-reach-all-width' },
    itemInNaN = { setList: undefined, className: ' ' };

  const dynamic_all =
    {
      [ROUTES.LIST]: itemInList,
      [ROUTES.HOTLINE_REACH]: itemInReach,
    }[pathname] || itemInNaN;

  const processing = async () => {
    const validation = (_data) => {
      if (!_data._id) {
        warn('', 'Для блокування розсилки потрібно вказати чинного клієнта', { 'data._id': !!_data._id });
        return;
      }

      return true;
    };

    if (!validation(data)) return;

    const transaction = {
      _id: data._id,
      bsa5: !data.bsa5,
    };

    setLoading(true);

    await request.post(
      '/hotline/blockConfirmSendViber',
      transaction,
      () => {
        success('', !data.bsa5 ? 'Розсилка "Проєкт 3000" більше не працює.' : 'Розсилка "Проєкт 3000" знову працює.');
        dispatch(dynamic_all.setList);
      },
      error,
    );

    setLoading(false);
  };

  return (
    <Button
      className={dynamic_all.className}
      danger={data.bsa5 ? false : true}
      type="primary"
      disabled={data.s !== '1' || data.K || data.amr !== '0'}
      onClick={processing}
    >
      {data.bsa5 ? <SendOutlined /> : <StopOutlined />}
      {data.bsa5 ? 'З' : 'Не з'}апускати Проєкт 3000
    </Button>
  );
};

export default Block5daysProject3000;
