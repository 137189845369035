import React from 'react';
import { HOTLINE_MEET } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralMeetingStatus = [HOTLINE_MEET, ['Статус зустрічі по консультації', 's_H'], ['Статус зустрічі', 's']];

const SelectGeneralMeetingStatus = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть статус зустрічі"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {HOTLINE_MEET.map((i, index) => {
        if (i === '-')
          return (
            <Option key={i} value="_">
              Усі статуси зустрічі
            </Option>
          );
        return (
          <Option key={i} value={String(index)}>
            {i}
          </Option>
        );
      })}
    </Select>
  );
};

export { billSelectGeneralMeetingStatus, SelectGeneralMeetingStatus };
