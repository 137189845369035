import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CHAT_TYPES, ROLES } from 'common_constants/business';
import { CREATE_NEW_GROUP_CHAT } from 'common_constants/modals';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Col, Tabs, Button } from 'antd';
import clsx from 'clsx';

import { request, error } from '../../tools';
import { updateChat, setModal, updateUnreadedMessages } from '../../store/commonReducer';
import { tabsItems, showSearchedNoResults } from './helpers';
import ChatUser from './ChatUser';
import SearchWrapper from './SearchWrapper';

const ChatsWrapper = ({
  activeTab,
  setInputSearch,
  setContractNumberSearch,
  contractNumberSearch,
  searchedUsers,
  setActiveTab,
  setSearchedUsers,
  inputSearch,
  totalCount,
  chatUsersLoading,
  onChangeCurrentChatId,
  currentChatId,
  setCurrentPage,
  setIsTypeChatsFetching,
  setIsFetchedChat,
  inputClientSearch,
  setInputClientSearch,
}) => {
  const dispatch = useDispatch();
  const listRef = useRef();

  const chatsData = useSelector((state) => state.common.chatsData);
  const userAuth = useSelector((state) => state.common.userAuth);

  const [isMobile, setIsMobile] = useState(false);

  const isGroupChat = activeTab === CHAT_TYPES.groupChat.key;
  const redButtonChat = activeTab === CHAT_TYPES.redButtonChat.key;
  const isManager = ROLES[userAuth.role] <= ROLES.manager;

  useEffect(() => {
    setIsMobile(window.innerWidth <= 760);
  }, [window.innerWidth]);

  function usersScrollHandle(e) {
    const element = listRef.current;
    const nearBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + 100;

    if (nearBottom && chatsData?.[activeTab]?.length < totalCount?.[activeTab]) {
      setIsTypeChatsFetching(true);
    }
  }

  function getChatByInterlocutorId(interlocutorId, callback) {
    request.post(
      '/chatPrivate/getchat',
      { type: activeTab, interlocutorId: interlocutorId },
      ({ data }) => {
        if (!data?._id) return;

        dispatch(updateChat({ chatType: activeTab, data: data }));
        dispatch(updateUnreadedMessages({ chatId: currentChatId, chatType: redButtonChat ? CHAT_TYPES.chatWithClient.key : activeTab }));

        if (typeof callback === 'function') callback(data);
        setIsFetchedChat(true);
      },
      error,
    );
  }
  const handleChangeActiveTab = (val) => {
    setActiveTab(val);
    setCurrentPage((prev) => ({ ...prev, [activeTab]: 1 }));
    setIsTypeChatsFetching(true);
    onChangeCurrentChatId(null);
  };

  const onClickCreateNewGroupButton = () => {
    dispatch(setModal({ name: CREATE_NEW_GROUP_CHAT }));
  };

  return (
    <Col md={{ span: 8 }} className={clsx('side-panel', isMobile && !currentChatId && '_open')}>
      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        tabPosition="top"
        onChange={handleChangeActiveTab}
        items={tabsItems
          ?.filter((item) => {
            return item.key === CHAT_TYPES.personalConsultantChat.key && !isManager ? false : true;
          })
          ?.map((item) => ({
            key: item.key,
            label: item.label,
          }))}
        destroyInactiveTabPane={true}
        className="tabs"
        size="small"
      ></Tabs>
      <SearchWrapper
        activeTab={activeTab}
        setSearchedUsers={setSearchedUsers}
        inputSearch={inputSearch}
        setInputSearch={setInputSearch}
        contractNumberSearch={contractNumberSearch}
        setContractNumberSearch={setContractNumberSearch}
        inputClientSearch={inputClientSearch}
        setInputClientSearch={setInputClientSearch}
      />
      {isGroupChat && (
        <Button type="dashed" icon={<PlusOutlined />} onClick={onClickCreateNewGroupButton}>
          Створити групу
        </Button>
      )}
      <div ref={listRef} className="users-wrapper" onScroll={usersScrollHandle}>
        {searchedUsers?.length === 0 ? (
          <div className="empty-block">{showSearchedNoResults(activeTab, inputSearch, contractNumberSearch)}</div>
        ) : (
          searchedUsers?.map((chat) => (
            <ChatUser
              key={`${chat._id}-${activeTab}`}
              onChangeCurrentChatId={onChangeCurrentChatId}
              chatId={chat?._id}
              setInputSearch={setInputSearch}
              getChatByInterlocutorId={getChatByInterlocutorId}
              chatType={activeTab}
              currentChatId={currentChatId}
              isSearchedUser={inputSearch?.length > 0}
            />
          ))
        )}
      </div>
    </Col>
  );
};

export default ChatsWrapper;
