import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResize, Box, Row as MyRow } from 'common_components';

import { Button, Input, InputNumber, Typography, Divider, DatePicker, List, Spin, Empty, Select } from 'antd';
import { FileAddOutlined, UserOutlined, PhoneOutlined, FieldNumberOutlined } from '@ant-design/icons';

import { request, error, warn } from '../../tools';
import { setModal, setCorrespondenceListPage } from '../../store/commonReducer';
import { ROLES, TYPES_OF_MAIL, LAW_BLOCKS } from 'common_constants/business';

import { CORRESPONDENCE_ADD_DOCUMENT } from 'common_constants/modals';
import CorrespondenceCardItem from '../../Modals/CorrespondenceModalForTheContract/CorrespondenceCardItem';
import { SelectGeneralAppointed } from '../../components/SelectGeneral/index';

import './CorrespondencePage.scss';

import dayjs from 'dayjs';
import locale from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

const CorrespondencePage = (props) => {
  const [dispatch, resize] = [useDispatch(), useResize()];
  const [userAuth, users, list, FILII] = [
    useSelector((state) => state.common.userAuth),
    useSelector((state) => state.common.users),
    useSelector((state) => state.common.correspondencePage),
    useSelector((state) => state.common.FILII),
  ];

  const manager = ROLES[props.role] <= ROLES.manager;

  const core_megaState = {
      loading: false, //* Завантаження
      dateArr: '', //* Фільтр - Дата відправлення/отримання пошти (Проміжок)
      numbClient: '', //* Пошук - Номер телефону клієнта
      nameClient: '', //* Пошук - ім'я клієнта
      uniqueNumbClient: '', //* Пошук - Унікальний номер клієнта
      uniqueNumbContract: '', //* Пошук - Унікальний номер договора
      uniqueNumbPost: '', //* Пошук - Унікальний номер пошти
      uniqueAddressee: undefined, //* Пошук - Унікальний адресат
      mailDirection: undefined, //* Фільтр - Напрямок пошти
      typeMail: undefined, //* Фільтр - Вид пошти
      fil: undefined, //* Фільтр - Філії договору
      author: undefined, //* Фільтр - Автор пошти
      block: undefined, //* Фільтр - Призначий блок договору
      page: 1, //* Номер пагінації
      total: 0, //* Кількість файлів пагінації
    },
    [megaState, setMegaState] = useState(core_megaState);

  const [{ Text }, { RangePicker }, { Option }] = [Typography, DatePicker, Select];

  const listToRender = list ?? [];

  const getAllListСorrespondence = useCallback(async () => {
    const validation = (_userAuth_id) => {
      if (!_userAuth_id) {
        warn('', 'Несанкціонований доступ. Зверніться до адміністратора компанії АО Захисту.', { _id: !!_userAuth_id });
        return;
      }

      return true;
    };

    if (!validation(userAuth._id)) return;

    setMegaState((prev) => ({ ...prev, loading: true }));

    const {
      page,
      mailDirection,
      typeMail,
      dateArr,
      author,
      fil,
      block,
      numbClient,
      nameClient,
      uniqueNumbClient,
      uniqueNumbContract,
      uniqueNumbPost,
      uniqueAddressee,
    } = megaState;

    const transaction = {
      page: page, //* Номер сторінки в пагінації
      // ? base
      ...(mailDirection && mailDirection !== 'undefined' ? { mailDirection: mailDirection } : {}), //* Напрямок пошти
      ...(typeMail && typeMail !== 'undefined' ? { typeMail: String(typeMail) } : {}), //* Вид пошти
      ...(dateArr && dateArr !== 'undefined' && dateArr !== '' ? { dateArr: dateArr } : {}), //* По даті відправлення/отримання пошти
      ...(author && author !== 'undefined' && users ? { author: author } : {}), //* Автор пошти
      ...(fil && fil !== 'undefined' ? { fil: fil } : {}), //* По філії
      ...(block && block !== 'undefined' ? { block: block } : {}), //* По блоку
      ...(numbClient && numbClient !== '' && numbClient !== 'undefined' ? { numbClient: numbClient } : {}), //* По номеру телефону
      ...(nameClient && nameClient !== '' && nameClient !== 'undefined' ? { nameClient: nameClient } : {}), //* По імені клієнта
      ...(uniqueNumbClient && uniqueNumbClient !== '' && uniqueNumbClient !== 'undefined' ? { uniqueNumbClient: uniqueNumbClient } : {}), //* По унікальному номеру клієнта
      ...(uniqueNumbContract && uniqueNumbContract !== '' && uniqueNumbContract !== 'undefined' ? { uniqueNumbContract: uniqueNumbContract } : {}), //* По унікальному номеру договора
      ...(uniqueNumbPost && uniqueNumbPost !== '' && uniqueNumbPost !== 'undefined' ? { uniqueNumbPost: uniqueNumbPost } : {}), //*  По унікальному номеру пошти
      ...(uniqueAddressee && uniqueAddressee !== 'underfined' && uniqueAddressee !== '' ? { uniqueAddressee: uniqueAddressee } : {}), //* По адресату
    };

    await request.post(
      '/correspondence',
      transaction,
      ({ cor, total }) => {
        dispatch(setCorrespondenceListPage(cor));

        setMegaState((prev) => ({ ...prev, total: total }));
      },
      error,
    );

    setMegaState((prev) => ({ ...prev, loading: false }));
  }, [
    megaState.page,
    megaState.mailDirection,
    megaState.typeMail,
    megaState.author,
    megaState.dateArr,
    megaState.fil,
    megaState.block,
    megaState.numbClient,
    megaState.nameClient,
    megaState.uniqueNumbClient,
    megaState.uniqueNumbContract,
    megaState.uniqueNumbPost,
    megaState.uniqueAddressee,
  ]);

  useEffect(() => {
    getAllListСorrespondence();
  }, [
    megaState.page,
    megaState.mailDirection,
    megaState.typeMail,
    megaState.author,
    megaState.dateArr,
    megaState.fil,
    megaState.block,
    megaState.numbClient,
    megaState.nameClient,
    megaState.uniqueNumbClient,
    megaState.uniqueNumbContract,
    megaState.uniqueNumbPost,
    megaState.uniqueAddressee,
  ]);

  const handleMultiChange = (value, nameChange) => {
    const staticChanges = { page: core_megaState.page, total: core_megaState.total },
      [fun_standard, fun_date] = [
        () => {
          if (nameChange === 'numbClient') {
            const validPhoneNumber = phoneNumberValidator(value);
            setMegaState((prev) => ({
              ...prev,
              ...staticChanges,
              numbClient: validPhoneNumber,
            }));
          } else {
            setMegaState((prev) => ({
              ...prev,
              ...staticChanges,
              [nameChange]: value || value !== 'undefined' || value !== '' ? value : core_megaState[nameChange],
            }));
          }
        },

        () =>
          setMegaState((prev) => ({
            ...prev,
            ...staticChanges,
            [nameChange]: value || value !== 'undefined' || value !== '' ? [dayjs(value?.[0]), dayjs(value?.[1])] : core_megaState[nameChange],
          })),
      ],
      all_dynamic = () =>
        ({
          mailDirection: fun_standard(),
          typeMail: fun_standard(),
          author: fun_standard(),
          dateArr: fun_date(),
          block: fun_standard(),
          fil: fun_standard(),
          numbClient: fun_standard(),
          nameClient: fun_standard(),
          uniqueNumbClient: fun_standard(),
          uniqueNumbContract: fun_standard(),
          uniqueNumbPost: fun_standard(),
          uniqueAddressee: fun_standard(),
        }[nameChange]);

    all_dynamic();
  };

  const styleSize = resize[0] >= 500 ? 'correspondence-filter-box-pc' : 'correspondence-filter-box-mob';

  return (
    <div className="correspondence-page">
      <h2 className="correspondence__title">Кореспонденція</h2>
      <MyRow style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={() => dispatch(setModal({ name: CORRESPONDENCE_ADD_DOCUMENT, data: { a: userAuth._id } }))}>
          <FileAddOutlined /> Додати пошту
        </Button>
      </MyRow>
      {manager && (
        <Box className={'correspondence-main-menu'}>
          <Box mt={0} className="correspondence-filter">
            <Divider orientation="left">Фільтри:</Divider>
            <Box className={styleSize}>
              <Select
                className="correspondence-filter-item"
                showSearch
                allowClear
                placeholder="Оберіть напрямок пошти"
                value={megaState.mailDirection}
                onChange={(e) => handleMultiChange(e, 'mailDirection')}
                filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="1">Вихідна</Option>
                <Option value="2">Вхідна</Option>
              </Select>
              <Text className="correspondence-filter-box-text"> Напрямок пошти: </Text>
            </Box>
            <Box className={styleSize}>
              <Select
                className="correspondence-filter-item"
                showSearch
                allowClear
                placeholder="Оберіть вид пошти"
                optionFilterProp="label"
                value={megaState.typeMail}
                onChange={(e) => handleMultiChange(e, 'typeMail')}
                filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                options={TYPES_OF_MAIL}
              />
              <Text className="correspondence-filter-box-text"> Вид пошти: </Text>
            </Box>
            <Box className={styleSize}>
              <SelectGeneralAppointed
                className={'correspondence-filter-item'}
                value={megaState.author}
                onChange={(e) => handleMultiChange(e, 'author')}
              />
              <Text className="sender-filter-box-text"> Автор пошти: </Text>
            </Box>
            <Box className={styleSize}>
              <RangePicker locale={locale} className="correspondence-filter-item" onChange={(value) => handleMultiChange(value, 'dateArr')} />
              <Text className="correspondence-filter-box-text"> Дата пошти: </Text>
            </Box>
            <Box className={styleSize}>
              <Select
                className="correspondence-filter-item"
                showSearch
                allowClear
                value={megaState.block}
                onChange={(e) => handleMultiChange(e, 'block')}
                placeholder="Оберіть блок"
                options={Object.keys(LAW_BLOCKS).map((i) => ({ value: i, label: LAW_BLOCKS[i] }))}
              />
              <Text className="correspondence-filter-box-text"> Блок договору: </Text>
            </Box>
            <Box className={styleSize}>
              <Select
                className="correspondence-filter-item"
                showSearch
                allowClear
                placeholder="Оберіть філію"
                value={megaState.fil}
                onChange={(e) => handleMultiChange(e, 'fil')}
                filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {FILII.map((i, index) => (
                  <Select.Option key={i} value={String(index)}>
                    {i}
                  </Select.Option>
                ))}
              </Select>
              <Text className="correspondence-filter-box-text"> Філія договору: </Text>
            </Box>
          </Box>

          <Box mt={0} className="correspondence-filter">
            <Divider orientation="left">Пошук:</Divider>
            <Box className={styleSize}>
              <Input
                placeholder="Введіть номер телефону"
                className="correspondence-filter-item"
                defaultValue={megaState.numbClient}
                value={megaState.numbClient}
                onChange={(e) => handleMultiChange(e, 'numbClient')}
                addonBefore={<PhoneOutlined />}
              />
              <Text className={'reach-sort-text reach-find-text'}> По телефону клієнта: </Text>
            </Box>
            <Box className={styleSize}>
              <Input
                placeholder="Введіть ім'я клієнта"
                className="correspondence-filter-item"
                defaultValue={megaState.nameClient}
                onChange={(e) => handleMultiChange(e.target.value, 'nameClient')}
                addonBefore={<UserOutlined />}
              />
              <Text className={'reach-sort-text reach-find-text'}> По імені клієнта: </Text>
            </Box>
            <Box className={styleSize}>
              <InputNumber
                placeholder="Введіть номер клієнта"
                className="correspondence-filter-item"
                defaultValue={megaState.uniqueNumbClient}
                onChange={(e) => handleMultiChange(e, 'uniqueNumbClient')}
                addonBefore={<FieldNumberOutlined />}
                min={0}
                step={100}
              />
              <Text className={'reach-sort-text reach-find-text'}> По номеру клієнта: </Text>
            </Box>
            <Box className={styleSize}>
              <InputNumber
                placeholder="Введіть номер договора"
                className="correspondence-filter-item"
                defaultValue={megaState.uniqueNumbContract}
                onChange={(e) => handleMultiChange(e, 'uniqueNumbContract')}
                addonBefore={<FieldNumberOutlined />}
                min={0}
                step={100}
              />
              <Text className={'reach-sort-text reach-find-text'}> По номеру договора: </Text>
            </Box>
            <Box className={styleSize}>
              <InputNumber
                placeholder="Введіть номер пошти"
                className="correspondence-filter-item"
                defaultValue={megaState.uniqueNumbPost}
                onChange={(e) => handleMultiChange(e, 'uniqueNumbPost')}
                addonBefore={<FieldNumberOutlined />}
                min={0}
                step={100}
              />
              <Text className={'reach-sort-text reach-find-text'}> По номеру пошти: </Text>
            </Box>
            <Box className={styleSize}>
              <Input
                placeholder="Введіть ім'я адресата"
                className="correspondence-filter-item"
                defaultValue={megaState.uniqueAddressee}
                onChange={(e) => handleMultiChange(e.target.value, 'uniqueAddressee')}
                addonBefore={<UserOutlined />}
              />
              <Text className={'reach-sort-text reach-find-text'}> По імені адресата: </Text>
            </Box>
          </Box>
        </Box>
      )}

      <Box style={{ marginLeft: '20px', marginRight: '20px' }}>
        <Spin size="large" tip="Завантаження" spinning={megaState.loading}>
          {megaState.total ? (
            <List
              itemLayout="horizontal"
              pagination={{
                hideOnSinglePage: true,
                position: 'both',
                align: 'end',
                defaultCurrent: 1,
                defaultPageSize: 10,
                showTotal: (total, range) => `${range[0]}-${range[1]} з ${total} пошт${megaState.total === 1 ? 'и' : ''}`,
                onChange: (value) => setMegaState((prev) => ({ ...prev, page: value })),
                current: megaState.page,
                total: megaState.total,
              }}
              dataSource={listToRender ?? []}
              renderItem={(item) => <CorrespondenceCardItem data={item} />}
            />
          ) : (
            <Empty description={<Text>Нема пошти</Text>} />
          )}
        </Spin>
      </Box>
      <br />
    </div>
  );
};

export default CorrespondencePage;
