import React from 'react';
import { Button, Col, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import './Voting.scss';

const Voting = ({ setVote }) => {
  const handleSetVoteYes = () => setVote(true);
  const handleSetVoteNo = () => setVote(false);

  return (
    <Col className="voting">
      <Row justify={'center'} className="voting__title">
        Ви підтримуєте перенесення даного договору в архів?
      </Row>
      <Row justify={'space-around'} className="voting-buttons">
        <Button icon={<CheckOutlined />} type="primary" onClick={handleSetVoteYes}>
          Так
        </Button>
        <Button icon={<CloseOutlined />} type="primary" danger onClick={handleSetVoteNo}>
          Ні
        </Button>
      </Row>
    </Col>
  );
};

export default Voting;
