import { Card, Row, Col, Progress, Skeleton } from 'antd';
import { magenta, purple } from '@ant-design/colors';
import './RatingCard.scss';

const RatingCard = ({ type, data }) => (
  <Card className="rating-progress">
    <span className="rating-progress__title">{`Персональний рейтинг ${type === 'contracts' ? 'договорів' : 'дожимів'}`}</span>
    <Row className="rating-progress-row">
      <Row className="rating-progress-circle-inner">
        <Progress
          type="circle"
          percent={data ? data.rating : 0}
          success={{
            percent: data ? data.rating : 0,
            strokeColor: type === 'contracts' ? magenta.primary : purple.primary,
            showInfo: false,
          }}
          format={(percent) => `${percent}`}
        />
      </Row>
      <Col className="rating-progress-info-inner">
        {type === 'contracts' ? (
          <Row className="rating-progress-info-item">
            <Row className="rating-progress-info-item__title">Записів:&nbsp;</Row>
            <Row className="rating-progress-info-item__data">
              {data ? data.hotlinesCount : <Skeleton.Input active className="rating-progress-info-skeleton" />}
            </Row>
          </Row>
        ) : (
          <Row className="rating-progress-info-item">
            <Row className="rating-progress-info-item__title">Дожимів:&nbsp;</Row>
            <Row className="rating-progress-info-item__data">
              {data ? data.reachesCount : <Skeleton.Input active className="rating-progress-info-skeleton" />}
            </Row>
          </Row>
        )}
        <Row className="rating-progress-info-item">
          <Row className="rating-progress-info-item__title">Договорів:&nbsp;</Row>
          <Row className="rating-progress-info-item__data">
            {data ? data.contractsCount : <Skeleton.Input active className="rating-progress-info-skeleton" />}
          </Row>
        </Row>
        <Row className="rating-progress-info-item">
          <Row className="rating-progress-info-item__title">Місце:&nbsp;</Row>
          <Row className="rating-progress-info-item__data">
            {data ? data.place : <Skeleton.Input active className="rating-progress-info-skeleton" />}
          </Row>
        </Row>
      </Col>
    </Row>
    <Row justify={'space-between'}>
      <Row>{data ? data.reportDate : <Skeleton.Input active className="rating-progress-info-skeleton" />}</Row>
      <Row>{data ? data.reportTime : <Skeleton.Input active className="rating-progress-info-skeleton" />}</Row>
    </Row>
  </Card>
);

export default RatingCard;
