import {
  DeleteOutlined,
  EditOutlined,
  PhoneOutlined,
  MailOutlined,
  IdcardOutlined,
  PercentageOutlined,
  FieldNumberOutlined,
  UserOutlined,
  CloseSquareOutlined,
  IssuesCloseOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Popconfirm, Typography } from 'antd';
import './PartnersUsers.scss';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const { Text } = Typography;

const PartnersUsersItem = ({ item, deletePartnersUser }) => {
  const FILII = useSelector((state) => state.common.FILII);
  const [isOpen, setIsOpen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onClickDeleteButtonHandle = async () => {
    await deletePartnersUser(item._id);
  };

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <Card
      title={
        <div style={{ display: 'flex' }}>
          <Text style={{ color: '#1890ff', fontSize: '18px', fontWeight: 'bold' }}>№{item.i}</Text>
          {item.isFired && (
            <Col style={{ marginTop: 4 }}>
              <Text type="danger" style={{ display: 'flex', alignItems: 'center' }}>
                <CloseSquareOutlined style={{ color: 'red', marginRight: '4px' }} />
                Звільнено
              </Text>
            </Col>
          )}
        </div>
      }
      extra={
        !item.isFired && (
          <div>
            {/* <Button type="default" shape="circle" icon={<EditOutlined />} className="edit-button" /> */}
            <Popconfirm
              title="Звільнення чинного партнера"
              description="Ви дійсно хочете звільнити партнера?"
              onConfirm={onClickDeleteButtonHandle}
              okText="Так"
              cancelText="Ні"
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} danger style={{ marginLeft: 8 }} />
            </Popconfirm>
          </div>
        )
      }
      style={{
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s',
      }}
    >
      <UserOutlined style={{ marginRight: 8 }} />
      <Text strong>ПІБ:</Text> {item.name || '-'}
      <br />
      <PhoneOutlined style={{ marginRight: 8 }} />
      <Text strong>Телефон:</Text> {'+' + item.countryPhCode} {item.phone || '-'}
      <br />
      {isOpen && (
        <>
          <MailOutlined style={{ marginRight: 8 }} />
          <Text strong>E-mail:</Text> {item.email || '-'}
          <br />
          <IssuesCloseOutlined style={{ marginRight: 8 }} />
          <Text strong>Пароль:</Text> {isPasswordVisible ? item.password : '*'.repeat(item.password.length)}
          <Button
            type="link"
            icon={isPasswordVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={togglePasswordVisibility}
            style={{ marginLeft: 8 }}
          />
          <br />
          <IdcardOutlined style={{ marginRight: 8 }} />
          <Text strong>Філія:</Text> {FILII[item.fil] || '-'}
          <br />
          <FieldNumberOutlined style={{ marginRight: 8 }} />
          <Text strong>Посвідчення:</Text> {item.certificate || '-'}
          <br />
          <PercentageOutlined style={{ marginRight: 8 }} />
          <Text strong>Доля:</Text> {item.share || '-'}%
          <br />
        </>
      )}
      <Button onClick={toggleCard} style={{ marginTop: 16 }}>
        {isOpen ? 'Сховати деталі' : 'Показати деталі'}
      </Button>
    </Card>
  );
};

export default PartnersUsersItem;
