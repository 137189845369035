import { Button, Card, Col, List, Modal, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal, setUserTotalBalance } from '../../store/commonReducer';
import './FilialWorkers.scss';
import FilialWorkersItem from '../../pages/WalletFilials/FilialWorkersItem';
import { error, request, success } from '../../tools';
import { ReloadOutlined } from '@ant-design/icons';

const FilialWorkers = () => {
  const dispatch = useDispatch();
  const filialWorkersFromState = useSelector((state) => state.common.filialWorkers);
  const modalData = useSelector((state) => state.common.modal.data);
  const filialWorkers = Object.values(filialWorkersFromState);

  const [loading, setLoading] = useState(false);

  const handleUpdateTotalBalance = async () => {
    setLoading(true);
    for (const user of filialWorkersFromState) {
      const route = `/auth/totalBalance/update`;
      const onSuccess = ({ totalBalance }) => {
        dispatch(setUserTotalBalance({ userId: user._id, totalBalance }));
      };
      const onError = (_, __, err) => {
        error('', 'Не вдалось оновити баланс. Будь ласка, спробуйте пізніше.');
      };
      await request.post(route, { filial: user.fil, userId: user._id }, onSuccess, onError);
    }
    setLoading(false);
    success('Баланс користувачів оновлено.');
  };

  return (
    <Modal
      title={
        <>
          Список працівників за філіалом у місті {modalData.fil.name}{' '}
          <Button
            onClick={handleUpdateTotalBalance}
            className="updateTotalBalance"
            icon={<ReloadOutlined />}
            type="primary"
            style={{ marginLeft: 8 }}
            loading={loading}
          >
            Оновити баланс
          </Button>
        </>
      }
      open
      onCancel={() => dispatch(setModal({ name: '', data: null }))}
      footer={null}
    >
      <Spin spinning={loading}>
        <Card className="workersFilialsInner">
          <Row className="item" justify={'space-between'} align={'middle'}>
            <Col className="fields-inner" flex={'auto'}>
              <Row className="fields" justify={'space-between'}>
                <Col className="fields-item">
                  <Row className="fields-row">
                    <Row className="title">Ім'я працівника</Row>
                  </Row>
                </Col>
                <Col className="fields-item">
                  <Row className="fields-row">
                    <Row className="title">Баланс</Row>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <List itemLayout="vertical" size="large" dataSource={filialWorkers} renderItem={(worker) => <FilialWorkersItem worker={worker} />} />
      </Spin>
    </Modal>
  );
};

export default FilialWorkers;
