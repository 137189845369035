import { useCallback, useEffect, useState } from 'react';
import { Space, Table, DatePicker, Row, Col, Typography, Divider, Spin } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import locale from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';

import { request, error } from '../../tools/index';
import { ROUTES } from 'common_constants/routes';

import './Journal.scss';

const { RangePicker } = DatePicker;

const { Title } = Typography;

const columns = [
  {
    title: 'Назва файлу',
    dataIndex: 'fileName',
    key: 'fileName',
    render: (_, record) => (
      <Space key={3} size="middle">
        <Link to={ROUTES.JOURNAL + '/' + record}>{record}</Link>
      </Space>
    ),
  },
];

export const Journal = () => {
  const [filesList, setFilesList] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChangeDateRange = useCallback((value) => {
    if (value) {
      setLoading(true);
      const startDateStr = value[0].format('YYYY-M-D');
      const endDateStr = value[1].format('YYYY-M-D');

      request.post(
        '/journal/getUsersLogsList',
        { startDateStr, endDateStr },
        (res) => {
          setFilesList(res.newFiles);
          setLoading(false);
        },
        error,
      );
    }
  }, []);

  useEffect(() => {
    handleChangeDateRange([dayjs(), dayjs()]);
  }, []);

  return (
    <div className="journal">
      <Row style={{ padding: '10px 0 0 10px' }}>
        <Col span={24}>
          <Title level={4}>Журнал активності користувачів</Title>
        </Col>
      </Row>
      <Divider orientation="left" plain>
        {`Вибір дати`}
      </Divider>
      <div className="journal-datepicker-inner">
        <Space direction="vertical" size={12}>
          <RangePicker onChange={handleChangeDateRange} locale={locale} defaultValue={[dayjs(), dayjs()]} />
        </Space>
      </div>
      <Spin size="large" spinning={loading} tip="Завантаження" style={{ position: 'absolute', zIndex: '1', left: '49%', top: '280px' }} />
      <Table
        rowKey={(record) => record}
        columns={columns}
        dataSource={filesList}
        pagination={{
          pageSize: 10,
          style: { justifyContent: 'center' },
        }}
        className="journal-dates-list"
      />
    </div>
  );
};
