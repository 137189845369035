//Docs: https://ant.design/docs/react/customize-theme

import { theme } from 'antd';

const Theme = {
  token: {
    algorithm: theme.defaultAlgorithm,

    // SEED TOKEN
    colorPrimary: '#1677ff',
    borderRadius: 7,
    colorLink: '#1890ff',
    colorError: '#ff4d4f',
    colorSuccess: '#52c41a',
    colorTextBase: '#000000',
    colorWarning: '#faad14',
    controlHeight: 32,
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    fontFamilyCode: "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
    fontSize: 14,
    lineType: 'solid',
    lineWidth: 1,
    motion: true,
    motionBase: 0,
    //... other motion styles//
    opacityImage: 1,
    sizePopupArrow: 16,
    sizeStep: 4,
    sizeUnit: 4,
    wireframe: false,
    zIndexBase: 0,
    zIndexPopupBase: 1000,

    // MAP_TOKEN
    borderRadiusLG: 8,
    borderRadiusOuter: 4,
    borderRadiusSM: 4,
    borderRadiusXS: 2,
    colorBgContainer: '#fff',
    colorBgElevated: '#ffffff',
    colorBgLayout: '#f5f5f5',
    colorBgMask: 'rgba(0, 0, 0, 0.45)',
    colorBgSpotlight: 'rgba(0, 0, 0, 0.85)',
    colorBorder: '#d9d9d9',
    colorBorderSecondary: '	#f0f0f0',
    colorErrorActive: '#d9363e',
    colorErrorBg: '#fff2f0',
    colorErrorBgHover: '#fff1f0',
    colorErrorBorder: '#ffccc7',
    colorErrorBorderHover: '#ffa39e',
    colorErrorHover: '#ff7875',
    colorErrorText: '#ff4d4f',
    colorErrorTextActive: '#d9363e',
    colorErrorTextHover: '#ff7875',
    colorFill: 'rgba(0, 0, 0, 0.15)',
    colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',
    colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
    colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
    colorInfoActive: '#0958d9',
    colorInfoBg: '#e6f4ff',
    colorInfoBgHover: '#bae0ff',
    colorInfoBorder: '#91caff',
    colorInfoBorderHover: '#69b1ff',
    colorInfoHover: '#69b1ff',
    colorInfoText: '#1677ff',
    colorInfoTextActive: '#0958d9',
    colorInfoTextHover: '#4096ff',
    colorLinkActive: '#0958d9',
    colorLinkHover: '#69b1ff',
    colorPrimaryActive: '#0958d9',
    colorPrimaryBg: '#e6f4ff',
    colorPrimaryBgHover: '#bae0ff',
    colorPrimaryBorder: '#91caff',
    colorPrimaryBorderHover: '#69b1ff',
    colorPrimaryHover: '#4096ff',
    colorPrimaryText: '#1677ff',
    colorPrimaryTextActive: '#0958d9',
    colorPrimaryTextHover: '#4096ff',
    colorSuccessActive: '#389e0d',
    colorSuccessBg: '#f6ffed',
    colorSuccessBgHover: '#d9f7be',
    colorSuccessBorder: '#b7eb8f',
    colorSuccessBorderHover: '#95de64',
    colorSuccessHover: '#95de64',
    colorSuccessText: '#52c41a',
    colorSuccessTextActive: '#389e0d',
    colorSuccessTextHover: '#73d13d',
    colorText: 'rgba(0, 0, 0, 0.88)',
    colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
    colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
    colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
    colorWarningActive: '#d48806',
    colorWarningBg: '#fffbe6',
    colorWarningBgHover: '#fff1b8',
    colorWarningBorder: '#ffe58f',
    colorWarningBorderHover: '#ffd666',
    colorWarningHover: '#ffd666',
    colorWarningText: '#faad14',
    colorWarningTextActive: '#d48806',
    colorWarningTextHover: '#ffc53d',
    colorWhite: '#fff',
    controlHeightLG: 40,
    controlHeightSM: 24,
    controlHeightXS: 16,
    fontSizeHeading1: 38,
    fontSizeHeading2: 30,
    fontSizeHeading3: 24,
    fontSizeHeading4: 20,
    fontSizeHeading5: 16,
    fontSizeLG: 16,
    fontSizeSM: 12,
    fontSizeXL: 20,
    lineHeight: 1.5714285714285714,
    lineHeightHeading1: 1.2105263157894737,
    lineHeightHeading2: 1.2666666666666666,
    lineHeightHeading3: 1.3333333333333333,
    lineHeightHeading4: 1.4,
    lineHeightHeading5: 1.5,
    lineHeightLG: 1.5,
    lineHeightSM: 1.6666666666666667,
    lineWidthBold: 2,
    motionDurationFast: '0.1s',
    motionDurationMid: '0.2s',
    motionDurationSlow: '0.3s',
    size: 16,
    sizeLG: 24,
    sizeMD: 20,
    sizeMS: 16,
    sizeSM: 12,
    sizeXL: 32,
    sizeXS: 8,
    sizeXXL: 48,
    sizeXXS: 4,

    // ALIAS_TOKEN
    boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    boxShadowSecondary: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    boxShadowTertiary: '0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)',
    colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
    colorBgTextHover: 'rgba(0, 0, 0, 0.06)',
    colorBorderBg: '#ffffff',
    colorErrorOutline: 'rgba(255, 38, 5, 0.06)',
    colorFillAlter: 'rgba(0, 0, 0, 0.02)',
    colorFillContent: 'rgba(0, 0, 0, 0.06)',
    colorFillContentHover: 'rgba(0, 0, 0, 0.15)',
    colorHighlight: '#ff4d4f',
    colorIcon: 'rgba(0, 0, 0, 0.45)',
    colorIconHover: 'rgba(0, 0, 0, 0.88)',
    colorSplit: 'rgba(5, 5, 5, 0.06)',
    colorTextDescription: 'rgba(0, 0, 0, 0.45)',
    colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
    colorTextHeading: 'rgba(0, 0, 0, 0.88)',
    colorTextLabel: 'rgba(0, 0, 0, 0.65)',
    colorTextLightSolid: '#fff',
    colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
    colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
    controlInteractiveSize: 16,
    controlItemBgActive: '#e6f4ff',
    controlItemBgActiveDisabled: 'rgba(0, 0, 0, 0.15)',
    controlItemBgActiveHover: '	#bae0ff',
    controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
    controlOutline: 'rgba(5, 145, 255, 0.1)',
    controlOutlineWidth: 2,
    controlPaddingHorizontal: 12,
    controlPaddingHorizontalSM: 8,
    fontSizeIcon: 12,
    fontWeightStrong: 600,
    lineWidthFocus: 4,
    linkDecoration: 'underfiend',
    linkFocusDecoration: 'undefined',
    linkHoverDecoration: 'underfined',
    margin: 16,
    marginLG: 24,
    marginMD: 20,
    marginSM: 12,
    marginXL: 32,
    marginXS: 8,
    marginXXL: 48,
    marginXXS: 4,
    opacityLoading: 0.65,
    padding: 16,
    paddingContentHorizontal: 16,
    paddingContentHorizontalLG: 24,
    paddingContentHorizontalSM: 16,
    paddingContentVertical: 12,
    paddingContentVerticalLG: 16,
    paddingContentVerticalSM: 8,
    paddingLG: 24,
    paddingMD: 20,
    paddingSM: 12,
    paddingXL: 32,
    paddingXS: 8,
    paddingXXS: 4,
    screenLG: 992,
    screenLGMax: 1199,
    screenLGMin: 992,
    screenMD: 768,
    screenMDMax: 991,
    screenMDMin: 768,
    screenSM: 576,
    screenSMMax: 767,
    screenSMMin: 576,
    screenXL: 1200,
    screenXLMax: 1599,
    screenXLMin: 1200,
    screenXS: 480,
    screenXSMax: 575,
    screenXSMin: 480,
    screenXXL: 1600,
    screenXXLMin: 1600,

    components: {},
  },
};

export default Theme;
