import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { BILL_STATUSES, PAY_TYPES, PAY_PURPOSES } from 'common_constants/business';
import './HarvestInfoListItem.scss';

const HarvestInfoListItem = ({ harvest }) => {
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);

  const [isExpanded, setIsExpanded] = useState(false);

  if (!isExpanded) {
    return (
      <Card className="harvestInfo-item-inner" onClick={() => setIsExpanded(true)}>
        <Row className="harvestInfo-item" justify={'space-between'} align={'middle'}>
          <Col className="harvestInfo-item-fields-inner" flex={'auto'}>
            <Row className="harvestInfo-item-fields" justify={'space-between'}>
              <Col className="harvestInfo-item-fields-left">
                <Row className="harvestInfo-item-fields-row">
                  <Row className="harvestInfo-item-fields-row__title">Дата платежу:&nbsp;</Row>
                  <Row className="harvestInfo-item-fields-row__info">{dayjs(harvest.payday).format('DD.MM.YYYY')}</Row>
                </Row>
                <Row className="harvestInfo-item-fields-row">
                  <Row className="harvestInfo-item-fields-row__title">Хто вніс:&nbsp;</Row>
                  <Row className="harvestInfo-item-fields-row__info">{users[harvest.author]?.p}</Row>
                </Row>
              </Col>
              <Col className="harvestInfo-item-fields-right">
                <Row className="harvestInfo-item-fields-row">
                  <Row className="harvestInfo-item-fields-row__title">Філія:&nbsp;</Row>
                  <Row className="harvestInfo-item-fields-row__info">{FILII[harvest.fil]}</Row>
                </Row>
                <Row className="harvestInfo-item-fields-row">
                  <Row className="harvestInfo-item-fields-row__title">Клієнт:&nbsp;</Row>
                  <Row className="harvestInfo-item-fields-row__info">
                    {harvest.name} {harvest.unknownName ? 'Невідомо' : ''}
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>

          <Row className="harvestInfo-item-buttons-inner">
            <Col className="harvestInfo-item-buttons">
              <Button className="harvestInfo-item__button">
                <DownOutlined />
              </Button>
            </Col>
          </Row>
        </Row>
      </Card>
    );
  }

  return (
    <Card className="harvestInfo-item-inner">
      <Row className="harvestInfo-item expanded" justify={'space-between'} align={'middle'}>
        <Col className="harvestInfo-item-fields-inner" flex={'auto'}>
          <Row className="harvestInfo-item-fields" justify={'space-between'}>
            <Col className="harvestInfo-item-fields-left">
              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Філія:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">{FILII[harvest.fil]}</Row>
              </Row>

              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Квитанція:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">
                  {BILL_STATUSES[harvest.bill]?.label} {harvest.billOther}
                </Row>
              </Row>

              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Сума:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">{harvest.amount}</Row>
              </Row>

              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Коментар:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">{harvest.comment}</Row>
              </Row>

              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Дата:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">{dayjs(harvest.createdAt).format('DD.MM.YYYY HH:mm:ss')}</Row>
              </Row>

              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Куди платив:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">
                  {PAY_TYPES[harvest.payBy]?.label} {harvest.payByOther}
                </Row>
              </Row>
            </Col>

            <Col className="harvestInfo-item-fields-right">
              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Призначення:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">
                  {PAY_PURPOSES[harvest.payfor]?.label} {harvest.payforOther}
                </Row>
              </Row>

              {harvest?.assignment?.assignmentIndex ? (
                <Row className="harvestInfo-item-fields-row">
                  <Row className="harvestInfo-item-fields-row__title">Вихід №:&nbsp;</Row>
                  <Row className="harvestInfo-item-fields-row__info">{harvest?.assignment?.assignmentIndex}</Row>
                </Row>
              ) : null}

              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Дата оплати:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">{dayjs(harvest.payday).format('DD.MM.YYYY')}</Row>
              </Row>

              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Номер договору:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">{harvest.contractIndex}</Row>
              </Row>

              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Прізвище:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">
                  {harvest.name} {harvest.unknownName ? 'Невідомо' : ''}
                </Row>
              </Row>

              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Дата створення:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">{dayjs(harvest.createdAt).format('DD.MM.YYYY HH:mm:ss')}</Row>
              </Row>

              <Row className="harvestInfo-item-fields-row">
                <Row className="harvestInfo-item-fields-row__title">Автор:&nbsp;</Row>
                <Row className="harvestInfo-item-fields-row__info">{users[harvest.author]?.name}</Row>
              </Row>
            </Col>
          </Row>
        </Col>

        <Row className="harvestInfo-item-buttons-inner">
          <Col className="harvestInfo-item-buttons">
            <Button className="harvestInfo-item__button" onClick={() => setIsExpanded(false)}>
              <UpOutlined />
            </Button>
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

export default HarvestInfoListItem;
