import { Badge, Button, Popover } from 'antd';
import { red, cyan, green } from '@ant-design/colors';
import { LAW_BLOCKS } from 'common_constants/business/index';
import { Row } from 'common_components';
import { SearchOutlined } from '@ant-design/icons';

import UserAvatar from '../../../components/UserAvatar';
import HoverableUser from '../../../components/HoverableUser/HoverableUser';

import { getClientColor } from '../../../tools';
import { Tag } from 'antd';

const getColumns = (
  onClientBtnClick,
  mob,
  users,
  FILII,
  getContractUnreadedMessages,
  getContractChatWithClientUnreadedMessages,
  handleGetContractsByClientInTable,
  setName,
  setPhone,
) => {
  const columns = [
    ...(mob
      ? [
          {
            title: '№ і Клієнт',
            key: 'i',
            render: (contract) => {
              const client = contract.client;
              const { color, background } = getClientColor(client[0]);

              return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div>
                    {contract.i}
                    {contract.uncompletedTasks > 0 && (
                      <span>
                        &nbsp;
                        <Badge color={red.primary} count={contract.uncompletedTasks} style={{ boxShadow: 'none', marginBottom: '25px' }} />
                      </span>
                    )}
                    {getContractChatWithClientUnreadedMessages(contract._id) > 0 && (
                      <span>
                        &nbsp;
                        <Badge
                          color={green.primary}
                          count={getContractChatWithClientUnreadedMessages(contract._id)}
                          style={{ boxShadow: 'none', marginBottom: '25px' }}
                        />
                      </span>
                    )}
                    {getContractUnreadedMessages(contract._id) > 0 && (
                      <span>
                        &nbsp;
                        <Badge
                          color={cyan.primary}
                          count={getContractUnreadedMessages(contract._id)}
                          style={{ boxShadow: 'none', marginBottom: '25px' }}
                        />
                      </span>
                    )}
                  </div>
                  <div style={{ display: 'flex', flexDirection: mob ? 'column' : 'row', alignItems: 'center' }}>
                    <Button size="small" shape="round" style={{ color, background }} onClick={(e) => onClientBtnClick(e, client[0])}>
                      {client[0]?.n ?? '-'}
                    </Button>
                    <Button
                      shape="circle"
                      icon={<SearchOutlined />}
                      style={{
                        marginLeft: '8px',
                        backgroundColor: 'rgba(0, 0, 0, 0.03)',
                        border: 'none',
                        width: '12px',
                      }}
                      onClick={(e) => {
                        setName(client[0].n);
                        setPhone(client[0].ph);
                        onClientBtnClick(e);
                        handleGetContractsByClientInTable(client[0].n, client[0].ph);
                      }}
                    />
                  </div>
                </div>
              );
            },
          },
        ]
      : [
          {
            title: '№',
            key: 'i',
            render: (contract) => (
              <>
                {contract.i}
                {contract.uncompletedTasks > 0 && (
                  <span>
                    &nbsp;
                    <Badge color={red.primary} count={contract.uncompletedTasks} style={{ boxShadow: 'none', marginBottom: '25px' }} />
                  </span>
                )}
                {getContractChatWithClientUnreadedMessages(contract._id) > 0 && (
                  <span>
                    &nbsp;
                    <Badge
                      color={green.primary}
                      count={getContractChatWithClientUnreadedMessages(contract._id)}
                      style={{ boxShadow: 'none', marginBottom: '25px' }}
                    />
                  </span>
                )}
                {getContractUnreadedMessages(contract._id) > 0 && (
                  <span>
                    &nbsp;
                    <Badge
                      color={cyan.primary}
                      count={getContractUnreadedMessages(contract._id)}
                      style={{ boxShadow: 'none', marginBottom: '25px' }}
                    />
                  </span>
                )}
              </>
            ),
          },
          {
            title: 'Клієнт',
            dataIndex: 'client',
            key: 'client',
            render: (client) => {
              const { color, background } = getClientColor(client[0]);

              return (
                <div style={{ display: 'flex', flexDirection: mob ? 'column' : 'row', alignItems: 'center' }}>
                  <Button size="small" shape="round" style={{ color, background }} onClick={(e) => onClientBtnClick(e, client[0])}>
                    {client[0]?.n ?? '-'}
                  </Button>
                  <Button
                    shape="circle"
                    icon={<SearchOutlined />}
                    style={{
                      marginLeft: '8px',
                      backgroundColor: 'rgba(0, 0, 0, 0.03)',
                      border: 'none',
                      width: '12px',
                    }}
                    onClick={(e) => {
                      setName(client[0].n);
                      setPhone(client[0].ph);
                      onClientBtnClick(e);
                      handleGetContractsByClientInTable(client[0].n, client[0].ph);
                    }}
                  />
                </div>
              );
            },
          },
        ]),
    {
      title: 'Обслуговує',
      key: 'f',
      render: (contract) => (
        <Popover content={<HoverableUser id={contract.us} />} arrow placement="bottomLeft" trigger="hover">
          <Row style={{ alignItems: 'center' }}>
            <div style={{ textAlign: 'center', marginRight: 8 }}>
              <UserAvatar user={users[contract.us]} />
              <br />
              <span style={{ textTransform: 'uppercase' }}>{users[contract.us]?.p}</span>
            </div>
            <div>
              <div>
                {FILII[contract.f] || '-'} <br /> {LAW_BLOCKS[contract.block] || '-'}
              </div>
              {contract.ad && (
                <span>
                  <span>Оцінка: </span>
                  <Tag key={users._id} color={contract.qa?.sq >= 7 ? 'green' : contract.qa?.sq >= 3 ? 'yellow' : 'ed'}>
                    {contract.qa?.sq >= 0 ? contract.qa.sq : '-'}
                  </Tag>
                </span>
              )}
            </div>
          </Row>
        </Popover>
      ),
    },
  ];

  return columns;
};

export default getColumns;
