import React, { useState, useEffect } from 'react';
import { MailOutlined, MoneyCollectOutlined, ReadOutlined, TabletFilled, FileAddOutlined, InteractionOutlined } from '@ant-design/icons';
import { Button, Modal, Row, Badge } from 'antd';
import { cyan, green } from '@ant-design/colors';
import {
  CORRESPONDENCE_MODAL_FOR_THE_CONTRACT,
  HARVEST_INFO_LIST,
  LIBRARY_MINI_LIST,
  DOCUMENT_TEMPLATES,
  SEND_CONTRACT_DATA_MFO,
} from 'common_constants/modals';
import { CHAT_TYPES } from 'common_constants/business';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setModal } from '../../store/commonReducer';
import { info } from '../../tools';

import './styles.scss';

const ButtonContainer = () => {
  const dispatch = useDispatch();

  const [data, title, chat, rest, unreadedMessagesData] = [
    useSelector((state) => state.common.modal.data.data),
    useSelector((state) => state.common.modal.data.title),
    useSelector((state) => state.common.modal.data.chat),
    useSelector((state) => state.common.modal.data.rest),
    useSelector((state) => state.common.unreadedMessagesData),
  ];

  const [isGenerateContractLoading, setIsGenerateContractLoading] = useState(false);
  const [unreadedMessagesContractsChat, setUnreadedMessagesContractsChat] = useState(0);
  const [unreadedMessagesContractsChatWithClient, setUnreadedMessagesContractsChatWithClient] = useState(0);

  useEffect(() => {
    if (!chat) return;

    const currentUnreadedMessagesContractsChat =
      unreadedMessagesData?.find((item) => item.chatType === CHAT_TYPES.contractChat.key && item.chatId === data._id)?.total ?? 0;

    const currentUnreadedMessagesContractsChatWithClient =
      unreadedMessagesData?.find((item) => item.chatType === CHAT_TYPES.chatWithClient.key && item.chatId === data._id)?.total ?? 0;

    setUnreadedMessagesContractsChat(currentUnreadedMessagesContractsChat);
    setUnreadedMessagesContractsChatWithClient(currentUnreadedMessagesContractsChatWithClient);
  }, [unreadedMessagesData]);

  const onGenerateDocument = () => {
    setIsGenerateContractLoading(true);

    const requestData = {
      contractId: data._id,
      clientId: data?.client[0]._id,
    };

    dispatch(setModal({ name: DOCUMENT_TEMPLATES, data: requestData }));
  };

  const onCancel = () => {
    dispatch(setModal());
  };

  const onClickChatButton = () => {
    dispatch(setModal());
  };

  return (
    <Modal open className="block-add-user button-container" title={title} onCancel={onCancel} footer={null}>
      {chat && (
        <Row className="contractInfo-assignment-buttons" justify={'space-between'}>
          <Link
            to={{ pathname: '/chat', state: { type: CHAT_TYPES.contractChat.key, id: data._id } }}
            onClick={onClickChatButton}
            className="chat-navLink"
          >
            <Button className="chat-button" type="primary" icon={<TabletFilled />}>
              Чат &nbsp;
              {unreadedMessagesContractsChat > 0 && (
                <Badge color={cyan.primary} count={unreadedMessagesContractsChat} style={{ marginBottom: '2.5px' }} />
              )}
            </Button>
          </Link>

          <Link
            to={{ pathname: '/chat', state: { type: CHAT_TYPES.chatWithClient.key, id: data._id } }}
            onClick={onClickChatButton}
            className="chat-navLink"
          >
            <Button className="chat-button" type="primary" icon={<TabletFilled />} danger>
              Чат з клієнтом &nbsp;
              {unreadedMessagesContractsChatWithClient > 0 && (
                <Badge color={green.primary} count={unreadedMessagesContractsChatWithClient} style={{ marginBottom: '2.5px' }} />
              )}
            </Button>
          </Link>
        </Row>
      )}
      {rest && (
        <Row className="contractInfo-assignment-buttons" justify={'space-between'}>
          <Button
            onClick={() => dispatch(setModal({ name: LIBRARY_MINI_LIST, data: { shoo: data.prc } }))}
            className="contractInfo-assignment__button"
            type="primary"
            icon={<ReadOutlined />}
          >
            Міні бібліотека
          </Button>
          <Button
            icon={<MailOutlined />}
            className="contractInfo-assignment__button"
            type="primary"
            onClick={() =>
              dispatch(
                setModal({
                  name: CORRESPONDENCE_MODAL_FOR_THE_CONTRACT,
                  data: {
                    _id: data._id,
                    i: data.i,
                    f: data.f,
                  },
                }),
              )
            }
          >
            Кореспонденція
          </Button>

          <Button
            style={{ marginTop: '20px' }}
            onClick={() => dispatch(setModal({ name: HARVEST_INFO_LIST, data: { contractNumber: data.i } }))}
            className="contractInfo-assignment__button"
            type="primary"
            icon={<MoneyCollectOutlined />}
          >
            Жнива
          </Button>

          <Button
            style={{ marginTop: '20px' }}
            className="contractInfo-assignment__button"
            type="primary"
            icon={<FileAddOutlined />}
            onClick={onGenerateDocument}
            loading={isGenerateContractLoading}
          >
            Генерувати документ
          </Button>

          <Button
            style={{ marginTop: '20px' }}
            className="contractInfo-assignment__button"
            type="primary"
            icon={<InteractionOutlined />}
            onClick={() => dispatch(setModal({ name: SEND_CONTRACT_DATA_MFO, data: { contractId: data?._id } }))}
            loading={isGenerateContractLoading}
          >
            Вибрати МФО
          </Button>
        </Row>
      )}
    </Modal>
  );
};
export default ButtonContainer;
