import { useState, useEffect, useRef } from 'react';
import { Progress } from 'antd';

const FRAME_RATE = 100;

const secondsForTimeline = () => {
  const seconds = new Date().getSeconds();
  if (seconds > 9) {
    return +seconds?.toString().slice(1) * 1000 || 0;
  }
  return seconds * 1000 || 0;
};

const TimeLineForHotlineCalls = ({ period }) => {
  const [time, setTime] = useState(secondsForTimeline() || 0);
  const timeRef = useRef(time);
  const lastUpdateTimeRef = useRef(Date.now());

  useEffect(() => {
    const makeStep = () => {
      const now = Date.now();
      const elapsed = now - lastUpdateTimeRef.current;

      if (timeRef.current + elapsed >= period) {
        setTime(0);
        timeRef.current = 0;
      } else {
        setTime((prev) => prev + elapsed);
        timeRef.current += elapsed;
      }

      lastUpdateTimeRef.current = now;
    };

    const interval = setInterval(makeStep, FRAME_RATE);

    return () => clearInterval(interval);
  }, [period]);

  return (
    <>
      (Авто-оновлення данних активних дзвінків)
      <Progress percent={(time * 100) / period} strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} showInfo={false} />
    </>
  );
};

export default TimeLineForHotlineCalls;
