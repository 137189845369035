import React, { useEffect } from 'react';
import { Row, Tabs } from 'antd';
import { Expenses, Sugar } from '../../Partials';

const { TabPane } = Tabs;

const FilialBalance = ({
  sugarPageSize,
  expensesPageSize,
  filialState,
  currency,
  handleSetSugarApproved,
  handleSetExpenseApproved,
  onChangeFilialSugarPage,
  onChangeFilialSugarPageSize,
  handleFilialSugarDateChange,
  handleFilialExpensesDateChange,
  onChangeFilialExpensesPage,
  onChangeFilialExpensesPageSize,
}) => (
  <Row>
    <Tabs className="tabs" defaultActiveKey="1" type="card">
      <TabPane tab="Плюс" key="1">
        <Sugar
          type={'filial'}
          state={filialState}
          pageSize={sugarPageSize}
          currency={currency}
          onChangePage={onChangeFilialSugarPage}
          handleDateChange={handleFilialSugarDateChange}
          handleSetApproved={handleSetSugarApproved}
          onChangePageSize={onChangeFilialSugarPageSize}
        />
      </TabPane>
      <TabPane tab="Мінус" key="2">
        <Expenses
          type={'filial'}
          state={filialState}
          currency={currency}
          pageSize={expensesPageSize}
          handleDateChange={handleFilialExpensesDateChange}
          onChangePage={onChangeFilialExpensesPage}
          handleSetApproved={handleSetExpenseApproved}
          onChangePageSize={onChangeFilialExpensesPageSize}
        />
      </TabPane>
    </Tabs>
  </Row>
);

export default React.memo(FilialBalance);
