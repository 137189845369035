import React from 'react';
import { useSelector } from 'react-redux';
import { Select, Row, Card } from 'antd';
import { ASSIGNMENTS_SERVICES_TYPE } from 'common_constants/business';

const { Option } = Select;

const SelectAssignment = ({ form, formData, setFormData, assignmentsList }) => {
  const courtsList = useSelector((state) => state.common.courtsList);
  const options = assignmentsList.map((assignment) => {
    return (
      <Option className="harvest-assignment-option" key={assignment._id} value={assignment._id}>
        <Card className="harvest-assignment-card">
          <Row>
            <strong>Час:&nbsp;</strong>
            <span>{assignment.selectedTime}</span>
          </Row>
          <Row>
            <strong>Послуга:&nbsp;</strong>
            <span>{ASSIGNMENTS_SERVICES_TYPE[assignment.serviceType]}</span>
          </Row>
          <Row>
            <strong>Суд:&nbsp;</strong>
            <span>{assignment?.court ? courtsList.find((court) => assignment?.court === court.i)?.name : '-'}</span>
          </Row>
          <Row>
            <strong>Сума клієнта:&nbsp;</strong>
            <span>{assignment.clientAmount}</span>
          </Row>
          <Row>
            <strong>Сума для адвоката:&nbsp;</strong>
            <span>{assignment.amountForLawyer}</span>
          </Row>
          <Row>
            <strong>Оплата клієнта:&nbsp;</strong>
            <span>{assignment.harvests.amounts.length ? assignment.harvests.amounts.join(', ') : '0'}</span>
          </Row>
        </Card>
      </Option>
    );
  });

  const handleSelectAssignment = (value) => {
    const currentAssignment = assignmentsList.find((assignment) => assignment._id === value);
    form.setFieldsValue({ assignmentId: value });
    setFormData({ ...formData, assignmentId: value, assignmentIndex: currentAssignment.i });
  };

  return (
    <Select onSelect={handleSelectAssignment} defaultValue="default" className="harvest-assignment-select" optionLabelProp="children">
      <Option className="harvest-assignment-option" key={Date.now()} value="default" disabled style={{ display: 'none' }}>
        <Card className="harvest-assignment-card">
          <Row align={'middle'} style={{ height: '150px', whiteSpace: 'normal', color: '#ff4d4f' }}>
            Якщо у списку немає вашої події, або він пустий, то спочатку додайте подію, а потім переходьте до фінансового звіту
          </Row>
        </Card>
      </Option>
      {options}
    </Select>
  );
};

export default SelectAssignment;
