import { DatePicker, Empty, Select, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageInfo from '../../components/PageInfo';

import { PSYCHOLOGIST_HOTLINE_REMOVE, PSYCHOLOGIST_HOTLINE_REPORTING, PSYCHOLOGIST_HOTLINE_EDIT } from 'common_constants/modals';
import { ROLES } from 'common_constants/business';
import { setCurrentPsychologistHotline, setModal } from '../../store/commonReducer';
import { error, request, success, warn } from '../../tools';
import Item from './Item';
import './List.scss';

dayjs.extend(isoWeek);
dayjs.extend(utc);

const ListPsychologistHotline = ({ selectedDate }) => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.common.currentPsychologistHotline);
  const users = useSelector((state) => state.common.users);
  const user = useSelector((state) => state.common.userAuth || {});

  const { Text } = Typography;
  const now = dayjs().utc();
  const psychologistOnly = ROLES[user?.role] === ROLES.psychologist;
  const admin = ROLES[user?.role] === ROLES.admin;

  // const [date, setDate] = useState(selectedDate.startOfWeek);
  // const [toDate, setToDate] = useState(selectedDate.endOfWeek);
  // const date = selectedDate.startOfWeek;
  // const toDate = selectedDate.endOfWeek;

  const [loading, setLoading] = useState(false);

  const getList = async (date, toDate) => {
    // const validation = (date) => {
    //   if (!date) {
    //     warn('', 'Для списку консультацій потрібно обрати дату', { date: date });
    //     return false;
    //   }
    //   if (toDate && !toDate.$D) {
    //     warn('', 'Для списку консультацій потрібно обрати період', { toDate: toDate, 'toDate.$D': toDate.$D });
    //     return false;
    //   }
    //   return true;
    // };

    // if (!validation(date, toDate)) return;

    setLoading(true);

    const requestData = {
      startDate: date,
      endDate: toDate,
    };

    date &&
      toDate &&
      (await request.post(
        '/psychologistHotline/get',
        requestData,
        (req) => {
          dispatch(setCurrentPsychologistHotline(req.data));
        },
        (error) => {
          console.error('Error in request:', error);
        },
      ));

    setLoading(false);
  };

  const removeHotline = (_id) => {
    dispatch(setModal({ name: PSYCHOLOGIST_HOTLINE_REMOVE, _id: _id, date: selectedDate.startOfWeek, toDate: selectedDate.endOfWeek, selectedDate }));
  };
  const reportingHotline = (_id) => {
    dispatch(setModal({ name: PSYCHOLOGIST_HOTLINE_REPORTING, _id: _id, date: selectedDate.startOfWeek, toDate: selectedDate.endOfWeek }));
  };
  const editHotline = (_id, dateHotline, endT, fil, startT, clientId) => {
    dispatch(
      setModal({
        name: PSYCHOLOGIST_HOTLINE_EDIT,
        _id: _id,
        date: dateHotline,
        endT,
        fil,
        startT,
        dateList: selectedDate.startOfWeek,
        toDate: selectedDate.endOfWeek,
        clientId,
        selectedDate,
        psychologistOnly,
      }),
    );
  };

  // const onChangeRange = (value) => {
  //   if (!value) return;
  //   setToDate(value[1]);
  //   getList(value[0], value[1]);
  // };

  // const onChangeRangeDate = (value) => {
  //   if (!value) {
  //     setToDate();
  //     getList(date);
  //   }
  // };
  useEffect(() => {
    getList(dayjs(selectedDate.startOfWeek).utc(), dayjs(selectedDate.endOfWeek).subtract(1, 'day').utc());
  }, [selectedDate]);

  if (!users) return null;
  const groupByDate = (list) => {
    const grouped = list?.reduce((acc, item) => {
      const dateKey = dayjs(item?.date).utc().format('YYYY-MM-DD');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(item);
      return acc;
    }, {});

    for (const dateKey in grouped) {
      grouped[dateKey].sort((a, b) => (dayjs(a.startT, 'HH:mm').utc().isBefore(dayjs(b.startT, 'HH:mm')) ? -1 : 1));
    }

    return grouped;
  };

  const groupedList = groupByDate(list);

  return (
    <Spin tip="Завантаження" spinning={loading}>
      <div className="list-pag-sychologist default-page">
        <h2>{psychologistOnly ? 'Записи' : 'Мої записи'}</h2>
        {/* <div className="wrapper-filter">

          <>
            <span style={{ display: 'inline-block', marginTop: '10px' }}>Обрати період:</span> <br />
            <DatePicker.RangePicker
              style={{ margin: '10px 10px 0 0' }}
              defaultValue={[date, toDate]}
              onChange={onChangeRange}
              onCalendarChange={onChangeRangeDate}
            />
          </>
        </div> */}

        {!list?.length && <Empty description={<Text>Нема записів</Text>} />}

        {groupedList &&
          Object.keys(groupedList)?.map((dateKey) => {
            const dateFormatted = dayjs(dateKey).format('dddd DD.MM.YYYY');
            return (
              <div key={dateKey}>
                <h3 className="dateFormatted-title">{dateFormatted}</h3>
                <div className="groupedList-wrapper">
                  {groupedList[dateKey]?.map((i) => (
                    <Item
                      key={i?._id}
                      clientId={i?.clientId}
                      clientName={i?.clientName}
                      clientPhone={i?.clientPhone}
                      crAt={i?.crAt}
                      date={i?.date}
                      endT={i?.endT}
                      fil={i?.fil}
                      i={i?.i}
                      psychologisPhone={i?.psychologisPhone}
                      psychologistId={i?.psychologistId}
                      psychologistName={i?.psychologistName}
                      startT={i?.startT}
                      type={i?.type}
                      _id={i?._id}
                      role={user?.role}
                      user={user}
                      removeHotline={removeHotline}
                      reportingHotline={reportingHotline}
                      editHotline={editHotline}
                      confirmDate={i?.confirmDate}
                      result={i?.result}
                      toDate={selectedDate.endOfWeek}
                    />
                  ))}
                </div>
              </div>
            );
          })}
      </div>
    </Spin>
  );
};

export default ListPsychologistHotline;
