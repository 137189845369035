import React from 'react';
import { Input, Button } from 'antd';
import { SendOutlined, PaperClipOutlined, CloseOutlined } from '@ant-design/icons';
import clsx from 'clsx';

const InputWrapper = ({
  editMode,
  handleAttachFile,
  handleSend,
  fileInputRef,
  onCloseEditingModeButton,
  inputValue,
  isDisabled,
  handleInput,
  sendLoader,
  isModal,
}) => {
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  return (
    <div className="input-container">
      {!isModal ? (
        <div className={clsx('edit-mode', !editMode.isEditing && '_hidden')}>
          <div className="block">
            <p className="editing-text">Редагування</p>
            <p className="prev-message">{editMode.prevMessageValue}</p>
          </div>
          <Button className="close-btn" onClick={onCloseEditingModeButton} icon={<CloseOutlined />} type="text" />
        </div>
      ) : null}

      <div className="new-message-wrapper">
        <input type="file" onChange={handleAttachFile} style={{ display: 'none' }} ref={fileInputRef} multiple aria-label="download files" />
        <Button
          type="text"
          onClick={() => fileInputRef.current.click()}
          loading={sendLoader}
          icon={<PaperClipOutlined />}
          disabled={isDisabled || editMode.isEditing}
          className="file-btn"
        />
        <Input
          placeholder="Напишіть повідомлення..."
          value={inputValue}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          disabled={isDisabled}
          style={{ marginTop: 0 }}
        />
        <Button type="primary straight-left" onClick={handleSend} loading={sendLoader} icon={<SendOutlined />} disabled={isDisabled} />
      </div>
    </div>
  );
};

export default InputWrapper;
