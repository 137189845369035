import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import SearchSelect from '../../components/SearchSelect';
import { useSelector } from 'react-redux';
import { DatePicker, Button, notification, Spin } from 'antd';
import { ROLES } from 'common_constants/business';
import { request, error } from '../../tools';
import { SearchOutlined } from '@ant-design/icons';

import './StatFiliiBalance.scss';

const StatFiliiBalance = () => {
  const FILII = useSelector((state) => state.common.FILII);
  const { role: userRole } = useSelector((state) => state.common.userAuth);
  const [filial, setFilial] = useState('');
  const [month, setMonth] = useState(null);
  const [data, setData] = useState([]);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateDaysInMonth = () => {
    const year = month.year();
    const monthIndex = month.month();
    const days = new Date(year, monthIndex + 1, 0).getDate();
    setDaysInMonth(Array.from({ length: days }, (_, i) => (i + 1).toString().padStart(2, '0')));
  };

  useEffect(() => {
    if (month) {
      updateDaysInMonth();
    }
  }, [month]);

  const getDailyBalances = () => {
    if (filial === '') {
      notification.warning({
        message: 'Увага!',
        description: 'Виберіть філію',
      });
      return;
    }
    if (!month) {
      notification.warning({
        message: 'Увага!',
        description: 'Виберіть місяць',
      });
      return;
    }
    setLoading(true);
    request.post(
      '/filii/dailyBalance/update',
      { filial, month },
      ({ dailyData }) => {
        const formattedData = dailyData.map((item) => {
          const date = new Date(item.date);
          return {
            date: date.getDate().toString().padStart(2, '0'),
            total: item.total,
          };
        });
        setData(formattedData);
        setLoading(false);
      },
      error,
    );
  };

  const balanceData = daysInMonth.map((day) => {
    const dayData = data.find((item) => item.date === day);
    return dayData ? dayData.total : 0;
  });

  const option = {
    title: {
      text: 'Статистика за місяць',
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: {
      type: 'category',
      data: daysInMonth,
      axisLabel: {
        formatter: (value) => `${value}`,
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Баланс',
        type: 'line',
        data: balanceData,
        itemStyle: {
          color: '#73a0fa',
        },
        label: {
          show: false,
          position: 'top',
        },
      },
    ],
    grid: {
      left: '3%',
      right: '3%',
      bottom: '3%',
      containLabel: true,
    },
    backgroundColor: '#ffffff',
  };

  return (
    <>
      <div className="statistic-balance-wrapper">
        <div>
          <h4>Філія: </h4>
          <SearchSelect list={FILII} value={filial} disabled={ROLES[userRole] > ROLES.inspector} placeholder="Філія" onChange={(v) => setFilial(v)} />
        </div>
        <div>
          <h4>Календар: </h4>
          <DatePicker
            style={{ width: 150 }}
            placeholder="місяць"
            picker="month"
            onChange={(value) => {
              setMonth(value);
            }}
            value={month}
          />
        </div>
        <Button style={{ width: 150 }} type="primary" htmlType="submit" onClick={getDailyBalances} loading={loading}>
          Пошук <SearchOutlined />
        </Button>
      </div>
      <Spin tip="Завантаження" spinning={loading}>
        <ReactECharts option={option} style={{ height: '600px' }} />
      </Spin>
    </>
  );
};

export default StatFiliiBalance;
