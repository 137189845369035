import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const monobankApi = createApi({
  reducerPath: 'monobankApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.monobank.ua',
  }),
  tagTypes: ['exchange-rates'],
  endpoints: (builder) => ({
    getExchangeRates: builder.query({
      query: () => `/bank/currency`,
      providesTags: ['exchange-rates'],
    }),
  }),
});

export const { useGetExchangeRatesQuery } = monobankApi;
