import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'antd';
import ReactECharts from 'echarts-for-react';
import { request, warn } from '../../tools';

const StatClientsDemography = () => {
  const [ageGroups, setAgeGroups] = useState([]);

  const fetchAgeGroupsData = async () => {
    request.post('/statistic/clientsDemography', null, ({ status, data }) => {
      if (!status || !data[0]) return warn();

      setAgeGroups(data[0]?.ageGroups.filter((group) => group._id !== 'Unknown'));
    });
  };

  useEffect(() => {
    fetchAgeGroupsData();
  }, []);

  const option = {
    title: {
      text: 'Демографія клієнтів',
    },
    tooltip: {},
    xAxis: {
      type: 'category',
      data: ageGroups.map((group) => group._id.toString()),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Count',
        type: 'bar',
        data: ageGroups.map((group) => group.count),
        itemStyle: {
          color: 'rgba(54, 162, 235, 0.6)',
        },
      },
    ],
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            <ReactECharts option={option} style={{ height: 400 }} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default StatClientsDemography;
