import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, DatePicker, Button, Spin, Popconfirm } from 'antd';
import { setCurrentClient, setModal } from '../../store/commonReducer';

import './styles.scss';

const ArchiveCalendar = () => {
  const token = window.localStorage.getItem('accessToken');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [numberOfContracts, setNumberOfContracts] = useState(0);
  const [numberOfClients, setNumberOfClients] = useState(0);
  const [arrayIDClient, setArrayIDClient] = useState();
  const [arrayIDOnlyActyve, setArrayIDOnlyActyve] = useState(0);
  const [arrayIDOnlyClose, setArrayIDOnlyClose] = useState(0);
  const [sendAllPop, setSendAllPop] = useState(false);

  const onCancel = () => {
    dispatch(setModal());
  };

  const onChangeRange = (value) => {
    sendAllArchiveCalendarProject3000(value[0], value[1]);
  };

  const sendAllArchiveCalendarProject3000 = (date, toDate) => {
    if (date && toDate) {
      const dateString = date?.toISOString();
      const toDateString = toDate?.toISOString();
      setLoading(true);

      fetch(process.env.REACT_APP_API + '/clients/getArchivedInRange', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ date: dateString, toDate: toDateString }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.status) return;
          setNumberOfContracts(res.numberOfContracts);
          setNumberOfClients(res.numberOfClients);
          setArrayIDClient(res.arrayIDClients);
          setArrayIDOnlyActyve(res.arrayIDOnlyActyve);
          setArrayIDOnlyClose(res.arrayIDOnlyClose);
          dispatch(setCurrentClient(res));
          setLoading(false);
        });
      return;
    }
  };

  const sendVSMSForArhiveProject3000 = (arrayIDClient) => {
    if (arrayIDClient && arrayIDClient.length !== 0) {
      setLoading(true);

      fetch(process.env.REACT_APP_API + '/clients/sendVSMSForArhiveProject3000', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ arrayIDClient: arrayIDClient }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.status) return;
          dispatch(setCurrentClient(res));
          setLoading(false);
          setSendAllPop(false);
        });
      return;
    }
  };

  const ononConfirmPopconfirm = () => {
    sendVSMSForArhiveProject3000(arrayIDClient);
  };

  const onCancelPopconfirm = () => {
    setSendAllPop(false);
  };

  return (
    <Modal open className="archive-calendar" title={'Пошук за архівами для проєкту 3000'} onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={loading}>
        <div>
          Обрати період: <br />
          <DatePicker.RangePicker onChange={onChangeRange} />
        </div>
        <div>
          <br />
          <p>Договорів в архіві: {numberOfContracts}</p>
          <p>Усього клієнтів в архіві: {numberOfClients}</p>
          <p>Клієнти з активними договорами: {arrayIDOnlyActyve}</p>
          <p>Клієнти без активних договорів: {arrayIDOnlyClose}</p>
        </div>
        <div className="group-btn-arhive ">
          <Popconfirm title="Починаємо?" open={sendAllPop} onConfirm={ononConfirmPopconfirm} onCancel={onCancelPopconfirm}>
            <Button type="primary" className="btn-arhive-calendar" onClick={() => setSendAllPop(true)}>
              Відправити
            </Button>
          </Popconfirm>
          <Button type="primary" danger className="btn-arhive-calendar" onClick={onCancel}>
            Скасувати
          </Button>
        </div>
        <br />
        <h6>*Відправити усім клієнтам розсилку "Проєкт 3000" за архівованими договорами, у яких більше немає з нами активних справ</h6>
      </Spin>
    </Modal>
  );
};

export default ArchiveCalendar;
