import { MONTHS_SMALL } from 'common_constants/business';

const date2VSMSMPayment = (numberDay, data) => {
  const getLastDayOfMonth = (year, month) => {
    let dateNow = new Date(year, month + 1, 0);
    return dateNow.getDate();
  };
  const daysOnMonth = getLastDayOfMonth(new Date().getFullYear(), new Date().getMonth());
  const dayFix = data.mpD + numberDay > 0 ? data.mpD + numberDay : daysOnMonth - 2;

  const beforeDate = new Date(data.mpD <= daysOnMonth ? new Date().setDate(dayFix) : new Date().setDate(daysOnMonth + numberDay));

  const dateStatic = beforeDate.getDate();
  const monthStatic = beforeDate.getMonth();
  const monthDynamic = dateStatic > 3 ? monthStatic : monthStatic + 1 === 11 ? 0 : monthStatic + 1;

  const dateDynamic = dayFix >= daysOnMonth ? daysOnMonth + numberDay : dateStatic;

  const dayFinish = dateDynamic + ' ' + MONTHS_SMALL[monthDynamic];

  return dayFinish;
};

export default date2VSMSMPayment;
