import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import ExportQuantityModal from '../../components/ExportQuantityModal';
import { request, warn, error, success } from '../../tools';

import {
  FORM_OF_BUSINESS,
  HOBBIES,
  HOTLINE_ADV_WHERE,
  HOTLINE_ADV_WHERE_MORE,
  HOTLINE_RESULT,
  HOTLINE_STATUS,
  JOBS,
  PAYMENT_FORMAT,
  POSLUGI,
  PRAVO,
  PRAVO_TYPES,
  REPEATS,
  SERVIS_OPTIONS,
  SOC_STATUS,
  TOWNS,
  WORK_STATES,
} from 'common_constants/business';

const ExportContracts = () => {
  const users = useSelector((state) => state.common.users);
  const contractMaxIndex = useSelector((state) => state.common.contractMaxIndex);
  const FILII = useSelector((state) => state.common.FILII);

  const max = contractMaxIndex;
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(max);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const onGenerate = () => {
    const validation = () => {
      if (!start || !end) {
        warn('', 'Для експорту таблиці, потрібно вказати діапазон кількості елементів', { '!!start': !!start, '!!end': !!end });

        return;
      }

      return true;
    };

    if (!validation()) return;

    setLoading(true);

    const transaction = {
      start,
      end,
    };

    request.post(
      '/spreadsheets/exportAllContract',
      transaction,
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };
  // first 9
  // 'Номер договру', '', 'Внесено', 'Блок', 'Дата Кл Адмін', 'Терміново!', 'Прим', 'Контроль', '№ а',
  const csvData = [
    [
      '№ Договору',
      'ID ПІБ Клієнта',
      'Дата дог',
      'Філія',
      'Уклав',
      'Хто буде обслуговувати?',
      'Офіс/дист',
      'Повторно',
      'Як дізнався вид р',
      'Що саме',
      'Дата 1п',
      'Сума 1п',
      'ФО/ФОП/ЮО',
      'Анонімна анкета. Якість послуг АО Захист - https://usnd.to/I978',
      'ОВ 1',
      'ОВ 2',
      'ОВ 3',
      'ДН',
      'Місто народження',
      'Стать',
      'Адреса',
      'Телефон',
      'E-mail',
      'Професія',
      'Соц статус',
      'Службове становище',
      'Хоббі',
      'Звязок 1',
      'телефон',
      'Звязок 2',
      'телефон',
      'Звязок 3',
      'телефон',
      'інтелект',
      'критичність мислення',
      'ініціативність',
      'поступливість',
      'дружелюбність',
      'емоційність',
      '№ ККАОЗ',
      'Вид правовідносин',
      'Що саме',
      'Предмет договору',
      'Своїми словами про справу',
      'Форма гонорара',
      'Гонорар',
      'Кільк міс',
      'Вихід адв',
      'Оплатив',

      // HOTLINE part :
      'Філія',
      'Послуга',
      'Дата',
      "Ім'я",
      'Час',
      'Телефон',
      'Вид',
      'Сума',
      'Коментар',
      'Автор',
      'Створено',
      'Завершеність',
      'Категорія анкетування',
      'Що саме',
      'Призначено',
      'Анкетування',
      'Названа ціна',
      'Результат зустрічі',
    ],
  ];

  data?.forEach((i) => {
    if (+i.i < +start) return;
    if (+i.i > +end) return;

    const hotline = i.hotline?.[0] ?? {};
    const client = i.client?.[0] ?? {};

    const address = `${client?.a ? client?.a + ',' : ''} ${client?.pt === 1 ? 'паспорт' : client?.pt === 0 ? 'ID-картка' : ''} ${
      client?.pn ? client?.pn + ',' : ''
    } ${client?.pkv ? 'виданий ' + client?.pkv : ''} ${client?.pdv ? 'від ' + dayjs(client?.pdv)?.format('DD-MM-YYYY') + ',' : ''} ${
      client?.inn ? 'ІПН ' + client?.inn : ''
    }`;

    let feeForm,
      paid,
      feeSum = i?.pa;

    if (i.monthlyFee && i?.fp >= i?.pa) {
      feeForm = PAYMENT_FORMAT[3];
      paid = `${i.fp}/${i.pa}`;
      feeSum = i?.monthlyFee;
    }
    if (i.monthlyFee && Number(i?.fp) < Number(i?.pa)) {
      feeForm = PAYMENT_FORMAT[4];
      paid = `${i?.fp || 0}/${i?.installmentMonths || 0}/${i.pa}`;
      feeSum = i?.monthlyFee;
    }
    if (i?.fp >= i?.pa && !i.monthlyFee) {
      feeForm = PAYMENT_FORMAT[0];
      paid = i?.fp;
      feeSum = i?.fp;
    }
    if (Number(i?.fp) < Number(i?.pa) && !i.monthlyFee) {
      feeForm = PAYMENT_FORMAT[1];
      paid = `${i?.fp || 0}/${i?.installmentMonths || 0}/${i.pa}`;
    }
    if (i?.pm && !Number(i?.pa)) {
      if (!Number(i?.fp) && !Number(i?.monthlyFee) && !Number(i?.installmentMonths)) {
        feeForm = PAYMENT_FORMAT[0];
        paid = i.fp;
      } else {
        feeForm = PAYMENT_FORMAT[2];
        paid = i?.fp;
        feeSum = i?.monthlyFee;
      }
    }

    csvData.push([
      i.i ?? '', // * '№ Договору'
      `${client?.n ?? '<без імені>'} ${FILII[i.f] ?? ''} ${REPEATS[i.tm] ?? ''} ${i.od === 1 ? 'Дист' : 'Офіс'} ${i.ov1 ?? ''} ${i.ov2 ?? ''} ${
        i.ov3 ?? ''
      }`,
      dayjs(i.sd)?.format('DD.MM.YYYY') ?? '',
      FILII[i.f] ?? '',
      users[i.us]?.p ?? '',
      SERVIS_OPTIONS[i.un] ?? '',
      i.od === 1 ? 'Дист' : 'Офіс' ?? '',
      REPEATS[i.tm] ?? '',
      HOTLINE_ADV_WHERE[i.aw] ?? '',
      HOTLINE_ADV_WHERE_MORE[i.am] ?? '',
      dayjs(i.md)?.format('DD.MM.YYYY') ?? '',
      i.fp ?? '',
      FORM_OF_BUSINESS[client?.u] ?? '',
      client?.n ?? '',
      i.ov1 ?? '',
      i.ov2 ?? '',
      i.ov3 ?? '',
      // birthday
      // dayjs(client?.b).format("DD-MM-YYYY"),
      client?.b?.length > 10 ? dayjs(client?.b).format('DD.MM.YYYY') : dayjs(client?.b, 'DD-MM-YYYY').format('DD.MM.YYYY'),
      TOWNS[client?.t] ?? '',
      client?.se === 1 ? 'ч' : 'ж',
      address,
      client?.ph ?? '',
      client?.email ?? '',
      JOBS[client?.j] ?? '',
      SOC_STATUS[client?.ss] ?? '',
      WORK_STATES[client?.ws] ?? '',
      HOBBIES[client?.h] ?? '',
      client?.r?.[0]?.name ?? '',
      client?.r?.[0]?.phone ?? '',
      client?.r?.[1]?.name ?? '',
      client?.r?.[1]?.phone ?? '',
      client?.r?.[2]?.name ?? '',
      client?.r?.[2]?.phone ?? '',
      client?.ch1 ?? '',
      client?.ch2 ?? '',
      client?.ch3 ?? '',
      client?.ch4 ?? '',
      client?.ch5 ?? '',
      client?.ch6 ?? '',
      i.kk ?? '',
      PRAVO_TYPES[i.pr] ?? '',
      PRAVO[i.prc] ?? '',
      i.ci ?? '',
      i.cd ?? '',
      feeForm ?? '',
      Number(feeSum) ? feeSum : '',
      i.monthlyFee ? '∞' : '',
      i.ea ?? '',
      paid ?? '',

      // HOTLINE part
      FILII[hotline.fil] ?? '-', // * 'Філія',
      POSLUGI[hotline.type] ?? '-', // * 'Послуга',
      dayjs(hotline.date)?.format('DD.MM.YYYY') ?? '-', // * 'Дата',
      hotline.name ?? '-', // * "Ім'я",
      (hotline.startT ?? '-') + '-' + (hotline.endT ?? '-'), // * 'Час',
      hotline.phone ?? '-', // * 'Телефон',
      PRAVO[hotline.pravo] ?? '-', // * 'Вид',
      hotline.cost ?? '-', // * 'Сума',
      hotline.comment ?? '-', // * 'Коментар',
      (window.users && window.users[hotline.author]?.p) ?? '-', // * 'Автор',
      dayjs(hotline.crAt)?.format('DD.MM.YYYY HH:mm:ss') ?? '-', // * 'Створено',
      HOTLINE_STATUS[hotline.s ?? 0], // * 'Завершеність',
      HOTLINE_ADV_WHERE[hotline.aw ?? 0], // * 'Категорія анкетування', (Як дізнався / вид р)
      HOTLINE_ADV_WHERE_MORE[hotline.am ?? 0], // * 'Що саме',
      (window.users && window.users[hotline.amm]?.p) ?? users[hotline.amm]?.p ?? '-', // * 'Призначено',
      'AUTO', // * 'Анкетування',
      hotline.amp ?? '-', // * 'Названа ціна',
      HOTLINE_RESULT[hotline.amr] ?? '-', // * 'Результат зустрічі',
    ]);

    // HOTLINE part :
  });

  return (
    <ExportQuantityModal
      title={'Експорт контрактів + клієнтів'}
      csvData={csvData}
      onGenerate={onGenerate}
      max={max}
      data={!!data}
      loading={loading}
      startA={start}
      setStartA={setStart}
      endA={end}
      setEndA={setEnd}
    />
  );
};

export default ExportContracts;
