import React from 'react';
import { PRAVO } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralTypeOfLegalRelationship = [
  PRAVO,
  ['Вид правовідносин Що саме по договору', 'prc_K'],
  ['Вид правовідносин по консультації', 'pravo_H'],
  ['Вид правовідносин', 'pravo'],
];

const SelectGeneralTypeOfLegalRelationship = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть вид правовідносин"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі види правовідносин</Option>
      {PRAVO.map((i, index) =>
        i !== '-' ? (
          <Option key={i} value={String(index)}>
            {i}
          </Option>
        ) : null,
      )}
    </Select>
  );
};

export { billSelectGeneralTypeOfLegalRelationship, SelectGeneralTypeOfLegalRelationship };
