import React, { useRef, useEffect } from 'react';

import Message from './Message';

const MessageRow = ({ data, index, setSize, handleOnContextMenu, handleDownloadFile }) => {
  const rowRef = useRef();

  let message = data[index];

  useEffect(() => {
    setSize(index, rowRef.current?.getBoundingClientRect()?.height ?? 0);
  }, [setSize, index]);

  if (message.removed) return <></>;

  return (
    <div ref={rowRef} onContextMenu={(e) => handleOnContextMenu(e, message)} onClick={(e) => e.stopPropagation()}>
      <Message item={message} onDownload={() => handleDownloadFile(message.fileId, message.fileName)} />
    </div>
  );
};

export default MessageRow;
