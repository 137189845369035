import React, { useEffect, useState } from 'react';
import { Row, DatePicker } from 'antd';
import ReactECharts from 'echarts-for-react';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import { request, error } from '../../tools';

import './StatServerLogs.scss';
import { chartOption } from './chartOptions';

const dayNow = dayjs();

const StatServerLogs = () => {
  const [currentMonth, setCurrentMonth] = useState(dayNow);
  const [statServerData, setStatServerData] = useState({
    topRoutes: [],
    longestRoutes: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const getServerLogsStat = async () => {
    setIsLoading(true);

    const reqBody = {
      year: currentMonth.format('YYYY'),
      month: currentMonth.format('M'),
    };

    await request.post(
      '/statistic/serverLogs',
      reqBody,
      ({ data }) => {
        setStatServerData((prev) => ({ ...prev, topRoutes: data.topRoutes, longestRoutes: data.longestRoutes }));
      },
      error,
    );

    setIsLoading(false);
  };

  useEffect(() => {
    getServerLogsStat();
  }, [currentMonth]);

  const topRoutesChartOption = chartOption(
    'Топ 100 найпопулярніших запитів',
    statServerData?.topRoutes?.map((item) => item?.count),
    statServerData?.topRoutes?.map((item) => item?.name + ' - ' + item?.avgTime + 'мс.'),
    ([item]) => `<b>${item.name}</b></br>Кількість запитів: ${item.data}`,
  );

  const longestRoutesChartOption = chartOption(
    'Топ 100 найдовших запитів',
    statServerData?.longestRoutes?.map((item) => item?.avgTime),
    statServerData?.longestRoutes?.map((item) => item?.name),
    ([item]) => `<b>${item.name}</b></br>Кількість часу: ${item.data}мс.`,
  );

  return (
    <div className="default_page stat-server-logs">
      <h2>Статистика логів серверу</h2>
      <Row align="center" className="picker-wrapper">
        <DatePicker value={currentMonth} locale={locale} onChange={setCurrentMonth} placeholder="Оберіть місяць" picker="month" />
      </Row>
      <div className="">
        <ReactECharts showLoading={isLoading} option={topRoutesChartOption} notMerge={true} lazyUpdate={true} style={{ minHeight: '900px' }} />
        <ReactECharts showLoading={isLoading} option={longestRoutesChartOption} notMerge={true} lazyUpdate={true} style={{ minHeight: '900px' }} />
      </div>
    </div>
  );
};

export default StatServerLogs;
