import React, { useState } from 'react';
import { Modal, InputNumber } from 'antd';

import './styles.scss';

const CloseCabinetModal = ({ isOpenCabinetModal, setIsOpenCabinetModal, onCloseCabinetClick, fil, date }) => {
  const [cabinetNumber, setCabinetNumber] = useState(1);

  const onClickOkButton = () => {
    onCloseCabinetClick(fil, date, cabinetNumber);
    setIsOpenCabinetModal(false);
  };

  const onClickCancelButton = () => {
    setIsOpenCabinetModal(false);
  };

  return (
    <Modal
      className="close-cabinet-modal"
      title="Зачинення кабінету"
      open={isOpenCabinetModal}
      onOk={onClickOkButton}
      onCancel={onClickCancelButton}
      okText="Надіслати"
      cancelText="Відмінити"
    >
      <div className="close-cabinet-modal__input-group">
        <p>Введеть номер кабінету:</p>
        <InputNumber value={cabinetNumber} onChange={(value) => setCabinetNumber(value)} min={1} />
      </div>
    </Modal>
  );
};

export default CloseCabinetModal;
