import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Button, DatePicker, Form, Input, InputNumber, Modal, Spin, TimePicker, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { setModal, addWebinars, editWebinars } from '../../store/commonReducer';
import { error, request, success } from '../../tools';

import './style.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Europe/Kiev');

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

const format = 'HH:mm';

const AddWebinar = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.common.modal.data);
  const webinars = useSelector((state) => state.common.webinars);

  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [imageUploaded, setImageUploaded] = useState(
    data?.teaserImg && data?.extension ? process.env.REACT_APP_API + `/webinarImages/${data.teaserImg}.${data.extension}` : null,
  );

  const props = {
    onRemove: () => {
      if (data?.teaserImg && data?.extension) {
        setImageUploaded(process.env.REACT_APP_API + `/webinarImages/${data.teaserImg}.${data.extension}`);
      } else {
        setImageUploaded(null);
      }
      setFileList([]);
    },
    beforeUpload: (file) => {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png';

      if (!isImage) {
        error('Можливо завантажити фото тільки в JPG та PNG форматі!');
        setFileList([]);
        return false;
      }
      setImageUploaded(URL.createObjectURL(file));

      setFileList([file]);
      return false;
    },
    fileList,
  };

  const onCancel = () => {
    dispatch(setModal());
  };

  const onFinish = (values) => {
    const { name, price, startDate, time, link, teaser } = values;

    const selectedTime = time.format(format).split(':');
    const startDateWithTime = dayjs(startDate).hour(selectedTime[0]).minute(selectedTime[1]).toISOString();

    const formData = new FormData();

    formData.append('file', fileList[0]);
    formData.append('name', name);
    formData.append('price', price);
    formData.append('link', link);
    formData.append('teaser', teaser);
    formData.append('startDateWithTime', startDateWithTime);

    const onSuccess = ({ data }) => {
      dispatch(addWebinars(data));
      success('Вебінар успішно створено.', ' ');
      onCancel();
    };

    const onError = (_, __, axiosData) => {
      error(' ', axiosData.response.data.error);
      setLoading(false);
    };

    setLoading(true);
    request.post('/webinars/create', formData, onSuccess, onError);
  };

  const onUpdate = (values) => {
    const { name, price, startDate, time, link, teaser } = values;

    const selectedTime = time.format(format).split(':');
    const startDateWithTime = dayjs(startDate).hour(selectedTime[0]).minute(selectedTime[1]).toISOString();

    const formData = new FormData();

    formData.append('file', fileList[0]);
    formData.append('_id', data._id);
    formData.append('name', name);
    formData.append('price', price);
    formData.append('link', link);
    formData.append('teaser', teaser);
    formData.append('startDateWithTime', startDateWithTime);
    formData.append('status', data.status);

    const onSuccess = ({ data }) => {
      dispatch(editWebinars(data));
      success('Вебінар успішно оновлено.', ' ');
      onCancel();
    };

    const onError = (_, __, axiosData) => {
      error(' ', axiosData.response.data.error);
      setLoading(false);
    };

    setLoading(true);
    request.post('/webinars/update', formData, onSuccess, onError);
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal open title={data ? 'Редагувати вебінар' : 'Новий вебінар'} className="addWebinar" onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={loading}>
        <Form {...layout} name="nest-messages" onFinish={data ? onUpdate : onFinish} style={{ maxWidth: 600, overflow: 'hidden' }}>
          <Form.Item
            initialValue={data ? data.name : ''}
            name={'name'}
            label="Назва вебінару"
            rules={[{ required: true, message: 'Будь ласка, вкажіть назву вебінару.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={'price'}
            label="Вартість вебінару"
            initialValue={data ? data.price : undefined}
            rules={[{ required: false, message: 'Будь ласка, вкажіть вартість вебінару.' }]}
          >
            <InputNumber onKeyDown={handleKeyPress} min={1} />
          </Form.Item>

          <Form.Item
            initialValue={data ? dayjs(data.startDateWithTime).tz() : dayjs()}
            name={'startDate'}
            label="Дата"
            rules={[{ required: true, message: 'Будь ласка, вкажіть дату.' }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            initialValue={data?.startDateWithTime ? dayjs(data.startDateWithTime).tz() : dayjs()}
            name={'time'}
            label="Час"
            rules={[{ required: true, message: 'Будь ласка, вкажіть час.' }]}
          >
            <TimePicker format={format} />
          </Form.Item>

          <Form.Item initialValue={data ? data.link : ''} name={'link'} label="Посилання на вебінар">
            <Input />
          </Form.Item>

          <Form.Item initialValue={data ? data.teaser : ''} name={'teaser'} label="Посилання на тизер">
            <Input />
          </Form.Item>

          <div style={{ marginBottom: 24 }}>
            <h4>Зображення</h4>
            <div className="image-wrapper">
              <div className="image-viewer">
                {imageUploaded && <img src={imageUploaded} className="image" alt="" />}
                <div className="preview-text">Попередній перегляд</div>
              </div>
              <div className="image-uploader">
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Виберіть фото</Button>
                </Upload>
              </div>
            </div>
          </div>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              {data ? 'Оновити' : 'Створити'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddWebinar;
