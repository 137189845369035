import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin, Modal, DatePicker, Row, Col } from 'antd';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

import { setModal } from '../../store/commonReducer';
import Excel from '../../images/excel_logo.svg';
import { request, error, success, info } from '../../tools';
import { URIST_ACTIVITY, URIST_ACTIVITY_REASONS } from 'common_constants/business';

import './ExportActivity.scss';

const ExportActivity = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);

  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [lastActivity, setLastActivity] = useState(null);
  const [allActivity, setAllActivity] = useState(null);

  const dateFormat = 'YYYY.MM.DD';

  const onGenerate = () => {
    if (!fromDate || !toDate) {
      info('', 'Для вивантаження даних, потрібно обрати дати відбору.');
      return;
    }

    setLoading(true);
    request.post(
      '/spreadsheets/exportAllActivity',
      { fromDate, toDate },
      (res) => {
        success();
        setLoading(false);
        setAllActivity(res.data.allActivity);
        setLastActivity(res.data.lastActivity);
      },
      error,
    );
  };

  const lastActivityCsvData = [['ПІБ', 'Філія', 'Дата', 'Статус', 'Причина', 'Власна причина']];

  lastActivity?.forEach((userItem) => {
    lastActivityCsvData.push([
      users[userItem._id]?.name, // ПІБ
      FILII[users[userItem._id]?.fil], // Філія
      userItem?.T ? dayjs(userItem.T)?.format('DD.MM.YYYY HH:mm:ss') : '-', // Дата
      URIST_ACTIVITY[userItem.value]?.label ?? '-', // Статус
      URIST_ACTIVITY_REASONS[userItem.reason]?.label ?? '-', // Причина
      userItem?.ownReason ?? '-', // Власна причина
    ]);
  });
  const allActivityCsvData = [['Дата', 'ПІБ', 'Філія', 'Статус', 'Причина', 'Власна причина']];

  allActivity?.forEach((activityItem) => {
    allActivityCsvData.push([
      activityItem?.T ? dayjs(activityItem.T)?.format('DD.MM.YYYY HH:mm:ss') : '-', // Дата
      users[activityItem.userId]?.name, // ПІБ
      FILII[users[activityItem.userId]?.fil], // Філія
      URIST_ACTIVITY[activityItem.value]?.label ?? '-', // Статус
      URIST_ACTIVITY_REASONS[activityItem.reason]?.label ?? '-', // Причина
      activityItem?.ownReason ?? '-', // Власна причина
    ]);
  });

  return (
    <Modal open className="export-activity" title="Експорт активності" onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Col>
          <Row className="export-activity-dateRange-row" justify={'space-between'}>
            <Col className="export-activity-dateRange-row-item">
              <Row>З</Row>
              <DatePicker onChange={(value) => setFromDate(value)} format={dateFormat} />
            </Col>

            <Col className="export-activity-dateRange-row-item">
              <Row>По</Row>
              <DatePicker onChange={(value) => setToDate(value)} format={dateFormat} />
            </Col>
          </Row>

          <Row style={{ marginTop: '20px' }} justify={'center'}>
            {lastActivity && allActivity ? (
              <Row className="export-activity-excel-buttons" justify={'space-between'}>
                <CSVLink filename="last_activity.csv" className="export-activity-excel__button" asyncOnClick={true} data={lastActivityCsvData}>
                  <Button className="excel">
                    <img className="excel-img" src={Excel} alt="Excel" /> Остання активність .csv
                  </Button>
                </CSVLink>
                <CSVLink
                  filename={`activity_from_${dayjs(fromDate).format('DD_MM_YYYY')}_to_${dayjs(toDate).format('DD_MM_YYYY')}.csv`}
                  className="export-activity-excel__button"
                  asyncOnClick={true}
                  data={allActivityCsvData}
                >
                  <Button className="excel">
                    <img className="excel-img" src={Excel} alt="Excel" /> Вся активність .csv
                  </Button>
                </CSVLink>
              </Row>
            ) : (
              <Button onClick={onGenerate}>Генерувати</Button>
            )}
          </Row>
        </Col>
      </Spin>
    </Modal>
  );
};

export default ExportActivity;
