import { useSelector } from 'react-redux';
import { CLUB_CARDS_SERVICES, CLUB_CARDS_TYPES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import Collapse from './Collapse';

import './ClubCardInfoAccordion.scss';

const { Panel } = Collapse;

const ClubCardInfoAccordion = ({ cardType }) => {
  const servicesItems = Object.keys(CLUB_CARDS_SERVICES).map((item, index) => {
    const servicesList = Object.values(CLUB_CARDS_TYPES)
      .filter((type) => type !== CLUB_CARDS_TYPES.SILVER)
      .map((type) => {
        const currentServices = CLUB_CARDS_SERVICES[index][type];
        const infinityServices = currentServices === 'Infinity';
        const infinityIcon = <span>&infin;</span>;
        const services = infinityServices ? infinityIcon : currentServices;
        return (
          <div key={type} className={`services ${type === cardType ? 'activ' : ''}`}>
            {services}
          </div>
        );
      });

    return (
      <div key={index} className="row">
        <div className="label">{CLUB_CARDS_SERVICES[item].LABEL}</div>
        {servicesList}
      </div>
    );
  });

  return (
    <div className="club-card-info">
      <div className="club-card-info-body">
        <Collapse defaultActiveKey={0}>
          <Panel header="Інформація" isActive={false}>
            {servicesItems}{' '}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default ClubCardInfoAccordion;
