import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MOB_RESOLUTION } from 'common_constants/ui';
import { setMob } from '../store/uiReducer';
import { request, error } from './index';
import { ROLES } from 'common_constants/business';
import { setUniversalLoader } from '../store/uiReducer';
import reactNativeService from './reactNativeService';
import {
  setCurrentUserInfo,
  setHotlineMaxIndex,
  setContractMaxIndex,
  setSuggestionsAndComplaintsMaxIndex,
  setContractArchiveMaxIndex,
  setContractQuestionaireMaxIndex,
  setUnreadedMessages,
  setTotalUncompletedTasks,
  setArrAddressees,
  setUnwatchedTendersCount,
  setUnwatchedTasksCount,
  setUnreadedMessagesRedButtonContractsChatWithClient,
} from '../store/commonReducer';

const CheckUserInfo = (dispatch) => {
  dispatch(setUniversalLoader(true));
  request.post(
    '/universal',
    null,
    (req) => {
      const {
        bellUser,
        bellUserAnk,
        bellUserZsu,
        hackworkAll,
        clientsHotlinesAll,
        hotlineMaxIndex,
        contractMaxIndex,
        suggestionsAndComplaintsMaxIndex,
        contractArchiveMaxIndex,
        contractQuestionaireMaxIndex,
        totalUncompletedTasks,
        transmutedArrAddressees,
        totalUnwatchedTendersCount,
        totalUnwatchedTasksCount,
        totalRedButtonContracstsUnreadedMessagesCount,
      } = req;
      if ((bellUser === undefined, bellUserAnk === undefined, bellUserZsu === undefined, hackworkAll === undefined, clientsHotlinesAll === undefined))
        return;

      dispatch(
        setCurrentUserInfo({
          bellUser: bellUser ?? 0,
          bellUserAnk: bellUserAnk ?? 0,
          bellUserZsu: bellUserZsu ?? 0,
          hackworkAll: hackworkAll ?? 0,
          clientsHotlinesAll: clientsHotlinesAll ?? 0,
        }),
      );
      dispatch(setHotlineMaxIndex(hotlineMaxIndex));
      dispatch(setContractMaxIndex(contractMaxIndex));
      dispatch(setSuggestionsAndComplaintsMaxIndex(suggestionsAndComplaintsMaxIndex));
      dispatch(setContractArchiveMaxIndex(contractArchiveMaxIndex));
      dispatch(setContractQuestionaireMaxIndex(contractQuestionaireMaxIndex));
      dispatch(setTotalUncompletedTasks(totalUncompletedTasks));
      dispatch(setArrAddressees(transmutedArrAddressees));
      dispatch(setUnwatchedTendersCount(totalUnwatchedTendersCount));
      dispatch(setUnwatchedTasksCount(totalUnwatchedTasksCount));
      dispatch(setUnreadedMessagesRedButtonContractsChatWithClient(totalRedButtonContracstsUnreadedMessagesCount));
      dispatch(setUniversalLoader(false));
    },
    error,
  );
}; // * Працює AuthControl та hook.js -> App.js - Авторизація / Реєстрація / Обнова - відповідно

const useControllView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      dispatch(setMob(window.innerWidth < MOB_RESOLUTION));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
};

const useUserInfo = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const { role } = useSelector((state) => state.common.userAuth);
  const workerOnly = ROLES[role] === ROLES.worker;
  const psychologistOnly = ROLES[role] === ROLES.psychologist;

  const getUserInfo = () => {
    if (!userAuth._id) return;
    if (psychologistOnly) return;
    CheckUserInfo(dispatch);
  };

  useEffect(() => {
    if (!userAuth) return;
    if (workerOnly) return;

    getUserInfo();
  }, []);
};

const useAuthData = () => {
  const userAuth = useSelector((state) => state.common.userAuth);

  useEffect(() => {
    const data = {
      ...userAuth,
      token: localStorage.getItem('accessToken'),
    };
    reactNativeService.sendMessageToWebview({ type: 'userAuthData', data: data });
  }, [userAuth?._id]);
};
const usePagination = ({ totalsItemsInit = 0, currentPageInit = 1, pageSizeInit = 10 }) => {
  const [totalItems, setTotalItems] = useState(totalsItemsInit);
  const [currentPage, setCurrentPage] = useState(currentPageInit);
  const [pageSize, setPageSize] = useState(pageSizeInit);

  const totalPages = Math.ceil(totalItems / pageSize);

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToPage = (pageNumber) => {
    const page = Math.max(1, Math.min(pageNumber, totalPages));
    setCurrentPage(page);
  };
  const resetPagination = () => {
    setCurrentPage(1);
  };

  return {
    currentPage,
    pageSize,
    totalPages,
    totalItems,
    setTotalItems,
    setPageSize,
    setCurrentPage,
    nextPage,
    prevPage,
    goToPage,
    resetPagination,
  };
};

const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState('');
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};
export { useControllView, useUserInfo, CheckUserInfo, useAuthData, usePagination, useDebounce };
