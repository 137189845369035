import React from 'react';
import { useSelector } from 'react-redux';
import SearchSelect from './SearchSelect';

const SearchSelectUsersMultiple = ({ onChange, value = [], users, fired, className, style, placeholder_topOption, disabled }) => {
  const allUsers = useSelector((state) => state.common.users);

  const filteredUsers = Object.values(allUsers)?.filter((i) => (fired ? true : i.fired !== true));

  const combinedUsers = [...users, ...filteredUsers.filter((user) => value.includes(user._id))];

  const reducedUsers = combinedUsers.reduce((acc, user) => {
    if (!acc.some((u) => u._id === user._id)) {
      acc.push(user);
    }
    return acc;
  }, []);

  return (
    <SearchSelect
      mode="multiple"
      className={className}
      style={style}
      disabled={disabled}
      placeholder="Оберіть людей для роботи з договором"
      placeholder_topOption={false}
      list={reducedUsers.map((i) => ({ value: i._id, label: `${i.name} - ${i.p} ` }))}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectUsersMultiple;
