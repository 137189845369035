import { Select } from 'antd';
import { COUNTRY_PHONE_CODES } from 'common_constants/business';
import { warn } from '../../tools';

const CountryPhCode = ({ value, onChange }) => {
  const handleBlur = () => {
    if (!Object.values(COUNTRY_PHONE_CODES).includes(value)) {
      warn('Зверніть увагу!', 'На цей номер не будуть приходити сповіщення');
    }
  };
  const handleChange = (input) => {
    if (!input) return;
    const isValidInput = /^[+\d]+$/.test(input);

    if (!isValidInput) {
      warn('Зверніть увагу!', 'Телефонний код може містити лише цифри або знак "+"');
      onChange(COUNTRY_PHONE_CODES.UA);
      return;
    }
    const formattedInput = input.startsWith('+') ? input.replace('+', '') : input;
    onChange(formattedInput);
  };

  return (
    <>
      <Select
        showSearch
        value={`+ ${value}`}
        style={{ width: '80px' }}
        placeholder="Введіть код"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={handleChange}
        onBlur={handleBlur}
      >
        {Object.entries(COUNTRY_PHONE_CODES).map(([country, code]) => (
          <Select.Option key={country} value={code}>
            +{code}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};
export default CountryPhCode;
