import React from 'react';
import { PAY_TYPES } from 'common_constants/business';
import SearchSelect from './SearchSelect';

const SearchSelectPayTypeFilter = ({ onChange, value, className, style }) => {
  return (
    <SearchSelect
      className={className}
      style={style}
      placeholder="Оберіть куди платив"
      list={Object.values(PAY_TYPES)}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectPayTypeFilter;
