import React from 'react';
import { PAY_PURPOSES } from 'common_constants/business';
import SearchSelect from './SearchSelect';

const SearchSelectPayPurp = ({ onChange, value, className, style, disabledState }) => {

  return (
    <SearchSelect
      disabled={disabledState}
      className={className}
      style={style}
      placeholder="Оберіть призначення платежу"
      list={Object.values(PAY_PURPOSES)}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectPayPurp;
