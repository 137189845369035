import { Button, Card, Popconfirm, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '../../components/Box/Box';
import { error, info, request, success, warn } from '../../tools';
const Item = ({ data }) => {
  const { username } = useSelector((state) => state.common.userAuth);
  const [block, setBlock] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleUserBlock = async () => {
    const validation = (_name, _phone, _username) => {
      if (!_name) {
        warn('', 'Для блокування телефону, повинен бути у клієнта вказаний ПІБ клієнта.', { _name: !!_name });
        return;
      }
      if (!_phone) {
        warn('', 'Для блокування телефону, повинен бути у клієнта вказаний номер телефону.', { _phone: !!_phone });
        return;
      }

      if (!_username) {
        warn('', 'Для блокування телефону, повинен бути чинний користувач.', { chn: !!_username });
        return;
      }

      return true;
    };

    if (!validation(data.name, data.phone, username)) return;

    const transaction = {
      name: data.name,
      phone: data.phone,
      countryPhCode: data.countryPhCode,
      comment: data.comment,
      email: data.email ?? '-',
      blockedBy: username,
    };
    setLoading(true);

    await request.post(
      '/block/blockAdd',
      transaction,
      () => {
        setBlock(!block);
        success('', `Телефон було успішно заблоковано.`);
      },
      error,
    );

    setLoading(false);
  };

  const handleUserUnblock = async () => {
    const validation = (_phone) => {
      if (!_phone) {
        warn('', 'Для розблокування телефону, повинен бути у клієнта вказаний номер телефону.', { _phone: !!_phone });
        return;
      }

      return true;
    };

    if (!validation(data.phone)) return;

    const transaction = {
      phone: data.phone,
      countryPhCode: data.countryPhCode,
    };

    await request.post(
      '/block/blockDelete',
      transaction,
      () => {
        setBlock(!block);
        success('', `Телефон було успішно розблоковано.`);
      },
      error,
    );
  };

  const cancel = () => {
    info('', 'Ви не згодні зі своїм рішенням.');
  };

  return (
    <Spin spinning={loading} tip="Зачекайте" size="large">
      <Card className="card hotline-list-item two_columns ">
        {block && (
          <div className="block-wrapper">
            <Tag color="error">Користувач заблокований</Tag>
          </div>
        )}

        <div className="row">
          <span className="item-white-space">
            <b>Ім'я:</b> {data.name !== 'No name' ? data.name : '-'}
          </span>
        </div>

        <div className="row">
          <span className="item-white-space">
            <b>Дата:</b> {data.createdAt ? dayjs(data.createdAt).format('DD.MM.YYYY HH:mm ') : '-'}
          </span>
        </div>

        <div className="row">
          <span className="item-white-space">
            <b>Коментар:</b> {data.comment !== 'No comment' ? data.comment : '-'}
          </span>
        </div>

        <div className="row">
          <span className="item-white-space">
            <b>Email:</b> {data.email !== 'No email' ? data.email : '-'}
          </span>
        </div>

        <div className="row">
          <span className="item-white-space">
            <b>Телефон:</b> +{data?.countryPhCode} {data.phone ?? '-'}
          </span>
        </div>

        <div className="row">
          <span className="item-white-space">
            <b>Заблокував:</b> {data.blockedBy ?? '-'}
          </span>
        </div>

        <Box mt={12} className="group-button">
          <Popconfirm
            title="Ви впевнені?"
            description={` ${block ? 'Номер телефону буде розблоковано' : 'Номер телефону буде знову заблоковано'}!`}
            onConfirm={block ? handleUserUnblock : handleUserBlock}
            onCancel={cancel}
          >
            <Button className=" btn-list-item" type="primary" danger={!block}>
              {block ? 'Розблокувати телефон' : 'Скасувати розблокування'}
            </Button>
          </Popconfirm>
        </Box>
      </Card>
    </Spin>
  );
};
export default Item;
