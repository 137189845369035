import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload, Space } from 'antd';

import { request } from '../../tools';
import { editUser } from '../../store/commonReducer';
import UserAvatar from '../../components/UserAvatar';
import CurrentUserPersonalInfo from '../../components/CurrentUserPersonalInfo/CurrentUserPersonalInfo';

const Profile = () => {
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.common.userAuth),
    users = useSelector((state) => state.common.users),
    user = users?.[userAuth?._id];

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append('file', file);
      formData.append('_id', userAuth._id);
      if (user?.upa) {
        formData.append('prevAvatarId', user.upa);
      }
    });
    setUploading(true);
    request.post(
      '/auth/profileEdit',
      formData,
      (res) => {
        setFileList([]);
        message.success('Фото успішно завантажено');
        setUploading(false);
        const newUser = {
          [userAuth._id]: {
            ...userAuth,
            upa: res.data.fileId,
          },
        };
        dispatch(editUser(newUser));
      },
      () => {
        message.error('Сталася помилка');
        setUploading(false);
      },
    );
  };

  const props = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const isJpg = file.type === 'image/jpeg';

      if (!isJpg) {
        message.error('Можливо завантажити фото тільки в JPG форматі!');
        setFileList([]);
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Розмір файлу повинен бути менше 2 мегабайт!');
        setFileList([]);
      }
      if (!isJpg || !isLt2M) {
        return false;
      }
      setFileList([file]);
      return false;
    },
    fileList,
  };

  return (
    <Space wrap size={16} style={{ padding: 32 }}>
      <UserAvatar user={user} style={{ width: 140, height: 140 }} />
      <div style={{ maxWidth: 200, width: '100%', margin: 20 }}>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Виберіть фото</Button>
        </Upload>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{
            marginTop: 16,
          }}
        >
          {uploading ? 'Завантаження...' : 'Завантажити'}
        </Button>
      </div>
      <CurrentUserPersonalInfo />
    </Space>
  );
};

export default Profile;
