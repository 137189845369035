import React from 'react';
import { HOTLINE_ADV_WHERE_MORE } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralHotlineAdvWhereMore = [HOTLINE_ADV_WHERE_MORE, ['Що саме по договору', 'am_K']];

const SelectGeneralHotlineAdvWhereMore = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть тип реклами"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі типи реклами</Option>
      {HOTLINE_ADV_WHERE_MORE.map((i, index) => (
        <Option key={i} value={String(index)}>
          {i}
        </Option>
      ))}
    </Select>
  );
};

export { billSelectGeneralHotlineAdvWhereMore, SelectGeneralHotlineAdvWhereMore };
