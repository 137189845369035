import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES, CHAT_TYPES } from 'common_constants/business';

import { removeChat } from '../store/commonReducer';

const useListenContractChats = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.common.socket);
  const userAuth = useSelector((state) => state.common.userAuth);

  const isInspector = userAuth.role <= ROLES.inspector;

  useEffect(() => {
    if (!socket) return;

    socket.on('removedFromContractChat', (data) => {
      if (!isInspector) {
        dispatch(removeChat({ chatId: data.chatId, chatType: CHAT_TYPES.contractChat.key }));
        dispatch(removeChat({ chatId: data.chatId, chatType: CHAT_TYPES.chatWithClient.key }));
      }
    });

    return () => {
      socket?.off('removedFromContractChat');
    };
  }, [socket]);
};

export default useListenContractChats;
