import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';
import dayjs from 'dayjs';

const DublicatePayrollInfo = ({ data }) => {
  const FILII = useSelector((state) => state.common.FILII);

  return (
    <Card
      title={`№${data.i} - ${dayjs(data.createdAt).format('DD.MM.YYYY')}`}
      size="small"
      className="dublicate-info"
      style={{ borderColor: '#' + data?.color }}
    >
      <p>
        <b>Філія:</b> {FILII?.[data?.fil] ?? '-'}
      </p>
      <p>
        <b>№ договору:</b> {data?.contractI}
      </p>
      <p>
        <b>Загальна сума:</b> {data?.totalAmount ?? '-'}
      </p>
      <p>
        <b>Дата:</b> {dayjs(data?.date).format('DD.MM.YYYY') ?? '-'}
      </p>
    </Card>
  );
};

export default DublicatePayrollInfo;
