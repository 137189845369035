import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spin, Modal, Input, DatePicker } from 'antd';
import dayjs from 'dayjs';

import { setModal, setCurrentClient, updateContract } from '../../store/commonReducer';
import { request, success, error } from '../../tools';
import Box from '../../components/Box';

import './styles.scss';

const ArchiveContract = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.common.modal.data);

  const [ai, setAi] = useState(data.ai);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ad = dayjs();

  const onCancel = () => {
    dispatch(setModal());
    dispatch(setCurrentClient());
  };

  const onSubmit = () => {
    setIsBtnLoading(true);

    request.post(
      '/contracts/toArchive',
      {
        _id: data._id,
        C: data.C,
        ai,
        n: data?.client[0]?.n ?? false,
        ph: data?.client[0]?.ph ?? false,
        p: data?.client[0]?.p ?? false,
        sa: data.sa ?? false,
      },
      () => {
        success();
        dispatch(setModal());
        dispatch(updateContract({ i: data.i, update: { ad: new Date() } }));
        setIsBtnLoading(false);
      },
      () => {
        setIsBtnLoading(false);
        error();
      },
    );
  };

  const getArchiveNumber = () => {
    setIsLoading(true);

    request.post(
      '/contracts/toArchive',
      {
        _id: data._id,
        C: data.C,
      },
      (res) => {
        setAi(res.ai);
        dispatch(updateContract({ i: data.i, update: { ai: res.ai } }));
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        error();
      },
    );
  };

  useEffect(() => {
    !ai && getArchiveNumber();
  }, []);

  return (
    <Modal open className="archive-contract" title={'Переносимо договір в архів'} onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={isLoading}>
        <label>Номер архіва</label>
        <Input aria-label="Write archive number" value={ai} />
        <br />
        <br />
        <label>Дата переведення в архів</label>
        <DatePicker value={ad} format="DD-MM-YYYY" disabled />
        <br />
        <br />
        <Box mt={0} className="group-button">
          <Button className="archive-btn" onClick={onCancel}>
            Скасувати
          </Button>
          <Button loading={isBtnLoading} disabled={!ai || !ad} type="primary" onClick={onSubmit} className="archive-btn">
            Відправити
          </Button>
        </Box>
      </Spin>
    </Modal>
  );
};

export default ArchiveContract;
