import { Row, Col } from 'antd';
import { ASSIGNMENTS_SERVICES_TYPE } from 'common_constants/business';
import dayjs from 'dayjs';

const getAddAssignmentInfoColumns = (courtsList) => {
  const columns = [
    {
      dataIndex: 'item',
      key: '_id',
      render: (_, record) => {
        return (
          <>
            <Row className="assignment-filter-row">
              <Col>
                <Row className="assignment-filter__data">
                  <strong>Вихід №:&nbsp;</strong>
                  <span>{record.i}</span>
                </Row>
                <Row className="assignment-filter__data">
                  <strong>Дата:&nbsp;</strong>
                  <span>{dayjs(record.date).format('DD.MM.YYYY')}</span>
                </Row>
              </Col>
              <Col>
                <Row className="assignment-filter__data">
                  <strong>Послуга:&nbsp;</strong>
                  <span>{ASSIGNMENTS_SERVICES_TYPE[record.serviceType]}</span>
                </Row>
                <Row className="assignment-filter__data">
                  <strong>Час:&nbsp;</strong>
                  <span>{record.selectedTime}</span>
                </Row>
              </Col>
            </Row>
            <Row className="assignment-filter__data">
              {/* <strong>Місце:&nbsp;</strong> */}
              <strong>Суд:&nbsp;</strong>
              <span>{courtsList.find((court) => record?.court === court.i)?.name ?? '-'}</span>
            </Row>
          </>
        );
      },
    },
  ];

  return columns;
};

export default getAddAssignmentInfoColumns;
