import { Collapse } from 'antd';

const { Panel } = Collapse;

const HotlineRequestBody = (props) => {
  const { requestBody } = props;

  const dataToRender = [];
  for (const key in requestBody) {
    const element = (
      <div key={key}>
        <span style={{ fontWeight: 'bold' }}>{key}: </span>
        <span>{typeof requestBody[key] === 'object' ? JSON.stringify(requestBody[key]) : String(requestBody[key])}</span>
      </div>
    );
    dataToRender.push(element);
  }

  return (
    <Collapse className="journal-card-body__item" style={{ maxWidth: '100%' }}>
      <Panel header="Request Body Details">
        <div className="journal-request-body__item">{dataToRender}</div>
      </Panel>
    </Collapse>
  );
};

export default HotlineRequestBody;
