import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from 'common_constants/routes';
import { DOCUMENT_TEMPLATES, SEND_CONTRACT_DATA_MFO } from 'common_constants/modals';

import { Avatar } from 'antd';

import icon_web from 'common_constants/images/icon_web.svg';
import icon_universal from 'common_constants/images/icon_universal.svg';
import icon_doc from 'common_constants/images/icon_doc.svg';
import icon_docx from 'common_constants/images/icon_docx.svg';
import icon_pdf from 'common_constants/images/icon_pdf.svg';
import icon_rtf from 'common_constants/images/icon_rtf.svg';
import icon_odt from 'common_constants/images/icon_odt.svg';
import icon_txt from 'common_constants/images/icon_txt.svg';
import icon_xls from 'common_constants/images/icon_xls.svg';
import icon_xlsx from 'common_constants/images/icon_xlsx.svg';

import '../LibraryItem.scss';
import '../../../Modals/LibraryMiniList/styles.scss';

const LibraryAvatarFile = ({ item }) => {
  const { pathname } = useLocation();
  const modalData = useSelector((state) => state.common.modal);

  const item_in_mini_library = { size: 50, className: ' ' },
    item_in_library = { size: 80, className: 'lib-item-avatar' },
    item_in_NaN = { size: true, className: ' ' };

  const dynamic_scss =
    modalData.name === DOCUMENT_TEMPLATES || modalData.name === SEND_CONTRACT_DATA_MFO
      ? item_in_library
      : {
          [ROUTES.CORRESPONDENCE_PAGE]: item_in_mini_library,
          [ROUTES.CONTRACTS]: item_in_mini_library,
          [ROUTES.LIST]: item_in_mini_library,
          [ROUTES.HOTLINE_REACH]: item_in_mini_library,
          [ROUTES.LIBRARY]: item_in_library,
        }[pathname] || item_in_NaN;

  const dynamic_avatar =
    {
      doc: icon_doc,
      docx: icon_docx,
      pdf: icon_pdf,
      rtf: icon_rtf,
      odt: icon_odt,
      web: icon_web,
      txt: icon_txt,
      xls: icon_xls,
      xlsx: icon_xlsx,
    }[item] || icon_universal;

  return (
    <Avatar
      size={dynamic_scss.size}
      style={{
        display: 'block',
      }}
      shape="square"
      className={dynamic_scss.className}
      src={dynamic_avatar}
    />
  );
};

export default LibraryAvatarFile;
