import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Statistic, Avatar, Popover, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { TASK_INFO } from 'common_constants/modals';
import dayjs from 'dayjs';

import { request, error } from '../../tools';
import { setModal, editTask } from '../../store/commonReducer';
import HoverableUser from '../../components/HoverableUser';

const { Group } = Avatar;
const { Countdown } = Statistic;

const getTaskDeadline = (task) => {
  let deadline = task.deadline;
  return deadline;
};

const getDailyTaskDeadline = (task) => {
  if (task.deadline) {
    let currentDate = new Date();
    let deadline = new Date(task.deadline);

    currentDate.setHours(deadline.getHours());
    currentDate.setMinutes(deadline.getMinutes());

    return currentDate;
  }
  return dayjs().endOf('day');
};

const isDone = (userId, doneUsers) => doneUsers?.includes?.(userId);

const TaskItem = ({ task, listName }) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);

  const getUserAvatar = (userId) => {
    const imageName = users?.[userId]?.upa;

    return imageName ? process.env.REACT_APP_API + `/avatars/${imageName}.jpeg` : null;
  };

  const onClickTaskHandle = () => {
    dispatch(setModal({ name: TASK_INFO, taskId: task._id, listName: listName }));
  };

  const onTaskFinish = (task) => {
    const data = {
      _id: task._id,
      outdated: true,
    };

    request.post(
      '/tasks/add',
      data,
      () => {
        dispatch(editTask({ listName: listName, task: data }));
      },
      error,
    );
  };

  return (
    <div onClick={onClickTaskHandle} className="task">
      <span>
        {task?.labels?.map((label) => (
          <Tag key={label?.text} color={label.color}>
            <span dangerouslySetInnerHTML={{ __html: label.icon }} />
          </Tag>
        ))}
      </span>
      <span>{task?.title}</span>
      <div className="bottom-wrapper">
        {task?.deadline && !isDone(userAuth._id, task?.done) && !task.isDaily ? (
          <>
            {!task.outdated ? (
              <Countdown onFinish={() => onTaskFinish(task)} className="timer" value={getTaskDeadline(task)} format="HH:mm:ss" />
            ) : (
              <p className="timer">Протермінована!</p>
            )}
          </>
        ) : task?.isDaily && !isDone(userAuth._id, task?.done) ? (
          <>
            {!task.outdated ? (
              <Countdown className="timer" value={getDailyTaskDeadline(task)} format="HH:mm:ss" />
            ) : (
              <p className="timer">Протермінована!</p>
            )}
          </>
        ) : (
          <span />
        )}
        <Group size="small" maxCount={5} className="members">
          {task?.appointed?.map((user) => (
            <Popover key={user} placement="topRight" content={<HoverableUser id={user} />}>
              <Avatar size="small" icon={<UserOutlined />} src={getUserAvatar(user)} />
            </Popover>
          ))}
        </Group>
      </div>
    </div>
  );
};

export default TaskItem;
