export const bigPieChartOption = (title, seriesData, radius = '50%') => {
  return {
    title: {
      text: title,
      left: 'center',
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      right: 10,
      top: 30,
      bottom: 30,
    },
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: radius,
        data: seriesData,
        label: {
          show: false,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
};

export const barChartOption = (title, seriesData, xAxisData) => {
  return {
    xAxis: {
      type: 'category',
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: seriesData,
        type: 'bar',
      },
    ],
  };
};

export const horizontalChartOption = (title, seriesData, yAxisData) => {
  return {
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
    },
    series: [
      {
        type: 'bar',
        data: seriesData,
      },
    ],
  };
};
