import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Space, Card, Collapse, Typography, Spin, Modal } from 'antd';

import { Box } from 'common_components';
import { DECLINE_REASONS, HOTLINE_RESULT, POSLUGI, PRAVO } from 'common_constants/business/index';

import dayjs from 'dayjs';

import { error, request } from '../../tools';
import { setModal } from '../../store/commonReducer';

const HistoryHotlines = () => {
    const [{ Panel }, { Text }] = [Collapse, Typography];

    const dispatch = useDispatch();

    const [hotlines, setHotlines] = useState([]);
    const onCancel = () => dispatch(setModal());
    const { phone } = useSelector((state) => state.common.modal.data);
    const [loading, setLoading] = useState(false);

    const getHotlineResult = () => { 
      request.post(
        '/hotline/getByPhone',
        { phone: phone},
        (res) => {
          const sorted =
            res.data?.sort((a, b) => {
              return new Date(b.date) - new Date(a.date);
            }) || [];
            setHotlines(sorted);
            setLoading(false);
        },
        error,
      );
     }

    useEffect(() => {
      setLoading(true);
      getHotlineResult();
    }, []);

    return (
      <Spin tip="Завантаження" spinning={loading}>
        <Modal className="library-info-list" open title={'Історія консультацій'} onCancel={onCancel} footer={null}>
          <Box>
            <Collapse size="small" accordion defaultActiveKey={['1']}>
              <Panel
                header={`Консультації по вказаному номеру. За місяць: ${
                  hotlines?.filter((i) => dayjs(i.date).isBetween(dayjs().subtract(1, 'month'), dayjs(), null, '[]'))?.length ?? 0
                }. Загалом: ${hotlines.length}. `}
                key="1"
                open
              >
                {hotlines.map((item) => (
                  <Card size="small" key={item._id}>
                    <Space wrap>
                      <Text strong>
                        {dayjs(item.date).format('DD.MM.YYYY')} - {item.name} - {item.phone}
                      </Text>
                    </Space>
                    <br />
                    <Space wrap>
                      <span>
                        <Text mark>На {POSLUGI[item.type]}</Text>
                      </span>
                      <span>
                        <Text strong>Вид:</Text> {PRAVO[item.pravo]}
                      </span>
                      <span>
                        <Text strong>Статус:</Text>&nbsp;
                        <Text type={item.s === '1' ? 'success' : 'danger'}>{item.s === '1' ? 'В' : 'Не в'}ідбулась</Text>
                      </span>
                      {item.s === '1' && (
                        <span>
                          <Text strong>Результат:</Text>&nbsp;
                          <Text type={item.amr === '2' ? 'success' : 'warning'}>{HOTLINE_RESULT[item.amr]}</Text>
                        </span>
                      )}
                      {item.s === '2' && item.meetDeclineReason && (
                        <span>
                          <Text strong>Причина відмови:</Text>&nbsp;
                          <Text type="warning">
                            {item.meetDeclineReason?.reason ? DECLINE_REASONS?.[item.meetDeclineReason?.reason] : item.meetDeclineReason?.ownReason}
                          </Text>
                          &nbsp;&nbsp;
                          <Text strong>Чи перенесено зустріч?</Text>&nbsp;
                          <Text type={item.meetDeclineReason?.moved ? 'success' : 'warning'}>
                            {item.meetDeclineReason?.moved ? 'П' : 'Не п'}еренесено
                          </Text>
                        </span>
                      )}
                      <span>
                        <Text strong>Ціна:</Text> {item.cost}
                      </span>
                      <span>
                        <Text strong>Коментар:</Text> {item.comment}
                      </span>
                    </Space>
                  </Card>
                ))}
              </Panel>
            </Collapse>
          </Box>
        </Modal>
      </Spin>
    );
};

export default HistoryHotlines;