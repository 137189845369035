import { DatePicker, Steps } from 'antd';
import { PAY_PURPOSES, PAY_TYPES } from 'common_constants/business';
import dayjs from 'dayjs';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { request } from '../../tools';
import { ExcelBtn } from 'common_components';

export default function ExportSugars({ FILII, users }) {
  const [exportData, setExportData] = useState(null);
  const [exportRange, setExportRange] = useState(null);
  const [step, setStep] = useState();

  const formatedExportData =
    exportData &&
    exportData
      .sort((a, b) => a.i - b.i)
      .map((item) => [
        item.i,
        FILII[item.contract_info?.f] || FILII[item.payrollToFil] || (item.payfor ? FILII[item.fil] : FILII[3]),
        dayjs(item.payday).format('DD.MM.YYYY'),
        item.amount,
        item?.payforRest,
        users[item.author]?.p,
        item?.contractIndex,
        item?.name,
        item.createdAt && dayjs(item.createdAt).format('DD.MM.YYYY HH:mm'),
        PAY_PURPOSES[item.payfor]?.label + ' ' + (item?.payforOther || ''),
        PAY_TYPES[item.payBy]?.label + ' ' + (item?.payByOther || ''),
        FILII[item.fil],
      ]);

  formatedExportData?.unshift([
    '№ інформації',
    'філія договору',
    'Дата платежа',
    'Сума платежа',
    'Сума залишку',
    'хто вніс',
    '№договора',
    'Прізвище клієнта',
    'дата внесення',
    'призначення платежу',
    'Куди платив',
    'філія внесача',
  ]);

  const loadExport = async () => {
    setStep(0);
    let sugars = [];
    await request.post('/spreadsheets/sugars', { exportRange }, ({ data }) => {
      sugars = data;
      setStep(1);
    });
    const contractIds = [...new Set(sugars.map((sugar) => sugar.contractIndex).filter((i) => i))];

    await request.post('/spreadsheets/sugars/contracts', { contractIds }, ({ data }) => {
      sugars.forEach((sugar, i) => {
        const contract = data.find((contract) => contract.i === sugar.contractIndex);
        sugars[i] = { ...sugar, contract_info: contract };
      });
      setStep(2);
    });
    setExportData(sugars);
  };

  const isExporting = Number.isInteger(step);

  return (
    <div className="exports">
      {exportData ? (
        <CSVLink asyncOnClick={true} data={exportData && formatedExportData} style={{ margin: 'auto' }}>
          <ExcelBtn style={{ width: 'auto' }}>Скачати файл</ExcelBtn>
        </CSVLink>
      ) : (
        <>
          <DatePicker.RangePicker
            disabled={isExporting}
            style={{ marginRight: 16, opacity: isExporting ? 0.5 : 1 }}
            onChange={(e) => setExportRange(e)}
            format="DD.MM.YYYY"
          />
          <ExcelBtn style={{ width: 'auto', pointerEvents: isExporting ? 'none' : 'all', opacity: isExporting ? 0.5 : 1 }} onClick={loadExport}>
            Export
          </ExcelBtn>
        </>
      )}
      {isExporting && (
        <Steps
          size="small"
          current={step}
          items={[
            {
              title: 'Завантажуємо сахарки',
            },
            {
              title: 'Завантажуємо контракти',
            },
          ]}
        />
      )}
    </div>
  );
}
