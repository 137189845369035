import { Card, Col, Row } from 'antd';
import React from 'react';
import '../../../Modals/FilialWorkers/FilialWorkers.scss';

function FilialWorkersItem({ worker }) {
  return (
    <Card className="workersFilialsInner">
      <Row className="item" justify={'space-between'} align={'middle'}>
        <Col className="fields-inner" flex={'auto'}>
          <Row className="fields" justify={'space-between'}>
            <Col className="fields-item">
              <Row className="fields-row">
                <Row className="info">{worker.name}</Row>
              </Row>
            </Col>
            {worker.totalBalance !== undefined ? (
              <Col className="fields-item">
                <Row className="fields-row">
                  <Row className="info">{(worker?.totalBalance?.total ?? '-').toLocaleString()}</Row>
                </Row>
              </Col>
            ) : (
              <Col className="fields-item">
                <Row className="fields-row">
                  <Row className="info">Відсутній</Row>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default FilialWorkersItem;
