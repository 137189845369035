import React from 'react';
import { Button, Row, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const Header = (props) => {
  const { selectDialogueOptions, selectedDialogueId, handleSelectDialogue, handlSetNewDialogue } = props;
  return (
    <Row className="header">
      <Select
        className="select"
        placeholder="Новий діалог"
        value={selectedDialogueId}
        onChange={handleSelectDialogue}
        options={selectDialogueOptions}
      />
      <Button icon={<EditOutlined />} type="primary" onClick={handlSetNewDialogue} />
    </Row>
  );
};

export default Header;
