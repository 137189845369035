import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Popover } from 'antd';
import dayjs from 'dayjs';

import HoverableUser from '../../components/HoverableUser';
import { setModal } from '../../store/commonReducer';
import { CLIENT_INFO } from 'common_constants/modals';
import Link from 'antd/es/typography/Link';
import { request } from '../../tools';

const SugarCard = ({ sugar }) => {
  const users = useSelector((state) => state.common.users);

  const authorName = users?.[sugar?.author] ? users?.[sugar?.author]?.name : '-';

  return (
    <Card bodyStyle={{ padding: 8 }}>
      <span>
        <b>Ім'я:</b> {sugar.name ?? 'Невідомо'}
      </span>
      <span>
        <b>Номер сахорка:</b> {sugar.i ?? 'Невідомо'}
      </span>
      <span>
        <b>Сума:</b> {sugar.amount ?? 0}
      </span>
      {sugar?.assignment?.assignmentIndex ? (
        <span>
          <b>Вихід №:</b> {sugar?.assignment?.assignmentIndex}
        </span>
      ) : null}
      {sugar?.author && (
        <span>
          <b>Автор:</b>{' '}
          <Popover content={<HoverableUser id={sugar?.author} />}>
            <span className="author-link">{authorName}</span>
          </Popover>
        </span>
      )}
      <span>
        <b>Дата створення:</b> {dayjs(sugar.createdAt).format('DD.MM.YYYY HH:mm:ss')}
      </span>
    </Card>
  );
};

export default SugarCard;
