import { Button, Col, Pagination, Row, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { error, request } from '../../../../tools';
import HotlineQuestionnaire from './HotlineQuestionnaire';
import { usePagination } from '../../../../tools/hooks';

const HotlineQuestionnaires = () => {
  const [questionnaires, setQuestionnaires] = useState([]);
  const { totalItems, currentPage, pageSize, setTotalItems, setCurrentPage, setPageSize } = usePagination({});

  const getQuestionnaires = async (page, pageSize) => {
    try {
      const onSuccess = (result) => {
        setQuestionnaires(result.data);
        setTotalItems(result.meta.totalItems);
      };

      const onError = () => {
        error('Помилка!', 'Помилка завантаження анкетування якості консультацій !');
      };
      await request.post('/questionnaires/get/hotlineQuestionnaires', { page, pageSize }, onSuccess, onError);
    } catch (e) {
      error('Помилка!', 'Помилка завантаження анкетування якості консультацій !');
    }
  };

  useEffect(() => {
    getQuestionnaires(currentPage, pageSize);
  }, [currentPage, pageSize]);

  return (
    <Col className="questionnaires-wrapper">
      <Col>
        <Typography.Title style={{ textAlign: 'center' }} level={4}>
          Якість консультацій
        </Typography.Title>
        {/* <Button onClick={() => getQuestionnaires(currentPage, pageSize)}>Пошук</Button> */}
      </Col>
      <div className="questionnaires-list">
        {questionnaires.map((questionnaire) => (
          <HotlineQuestionnaire key={questionnaire.i} questionnaire={questionnaire} />
        ))}
      </div>
      <Row>
        <Pagination
          showSizeChanger
          onShowSizeChange={(_, size) => setPageSize(size)}
          total={totalItems}
          onChange={(page) => setCurrentPage(page)}
          pageSize={pageSize}
          current={currentPage}
          // pageSizeOptions={pageSizeOptions}
        />
      </Row>
    </Col>
  );
};

export default HotlineQuestionnaires;
