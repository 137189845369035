const { QUESTIONNAIRE_INTERIM_SERVICE_QUALITY_ASSESSMENT } = require('../modals');
const CLIENT_DOMAIN = 'client.zahist.ua';
const CLIENT_LINK = `https://${CLIENT_DOMAIN}`;

const APP_DOMAIN = 'app.zahist.ua';
const APP_LINK = `https://${APP_DOMAIN}`;

const WEB_SITE_DOMAIN = 'zahist.ua';
const WEB_SITE_LINK = `https://${WEB_SITE_DOMAIN}`;

const OLD_WEB_SITE_DOMAIN = 'zahist.in.ua';
const OLD_WEB_SITE_LINK = `https://${OLD_WEB_SITE_DOMAIN}`;

const APP_LINK_ANDROID = 'https://play.google.com/store/apps/details?id=com.zahist.client';
const APP_LINK_IOS = 'https://apps.apple.com/us/app/єАдвокат/id6463437697';

const GET_IP_API = 'https://api64.ipify.org/?format=json';
const GEO_API = 'http://www.geoplugin.net/json.gp';

// CRM ROUTES
const ROUTES = {
  HOME: '/',
  AUTH: '/auth',
  HOME_DASHBOARD: '/homeDashboard',
  PROFILE: '/profile',
  HOTLINE: '/hotline',
  PSYCHOLOGIST_HOTLINE: '/PsychologistHotline',
  LIST_PSYCHOLOGIST_HOTLINE: '/ListPsychologistHotline',
  HOTLINE_SAVED: '/hotlineSaved',
  HOTLINE_ID: '/hotline/:_id',
  HOTLINE_REACH: '/hotlineReach',
  FAILURE_TO_PAY: '/failureToPay',
  LIST: '/list',
  HACKWORKS: '/hackworks',
  // GDUN: '/gdun',
  // GDUN_STAT: '/gdunStat',
  SEARCH: '/search',
  PERSONNEL_DIRECTORY: '/personnelDirectory',
  CLUB_CARDS: '/clubСards',
  CLIENTS: '/clients',
  PARTNERS: '/partners',
  CLIENT_INFO: '/clientInfo/:id',
  CLIENT_INFO_: '/clientInfo/',
  CONTRACTS: '/contracts',
  AUTOPAY_PAGE: '/autopay',
  HELPER: '/helper',
  HELPER_CALC: '/helperCalc',
  JOURNAL: '/journal',
  HOTLINES_BY_CLIENTS: '/hotlinesByClients',
  HOTLINE_UPDATE: '/hotlineUpdate',
  HARVEST: '/harvest',
  ASSIGNMENT: '/assignment',
  ACTIVITY: '/activity',
  LIBRARY: '/library',
  CORRESPONDENCE_PAGE: '/correspondencePage',
  BLOCKED: '/blocked',
  RATING: '/rating',
  PRAVO_RATING: '/pravoRating',
  FILII: '/filii',
  FEE: '/fee',
  CHAT: '/chat',
  CLIENTS_FEEDBACK: '/clientsFeedback',
  COMPLAINTS: '/complaints',
  FUNCTION_SURVEY: '/functionSurvey',
  EXPENSE: '/expenses',
  TASKS: '/tasks',
  SUGAR: '/sugar',
  PUSH_NOTIFICATION: '/pushNotification',
  TENDERS: '/tenders',
  INSURANCES: '/insurances',
  ACCESS_LIST: '/accessList',
  SUGGESTIONS: '/suggestions',
  CONFLICTS: '/conflicts',
  CONFLICT_CHAT: '/conflicts/:id',
  TWITTER: '/twitter',
  TWITTER_ID: '/twitter/:id',
  RED_BUTTON_CHAT: '/redButtonChat',
  DOCUMENTS: '/documents',
  WEBINARS: '/webinars',
  CLIENTS_QUICK_CHATS: '/clientsQuickChats',
  CLIENTS_DOZHIMS_AI_CHATS: '/clientsDozhimsAIChats',
  DOZHIMS_ANK: '/dozhimsAnk',
  DOZHIMS_ZSU: '/dozhimsZSU',

  //admin part
  USERS: '/users',
  USER_ADD: '/userAdd',
  USER_EDIT_ID: '/userEdit/:_id',
  CABINETS: '/cabinets',
  SENDER: '/sender',
  BANK_CARDS: '/bankCards',
  STATISTIC: '/statistic',
  STATISTIC_MEET_CANCELATION_REASONS: '/statistic/meetCancelationReasons',
  STATISTIC_CLIENTS_DEMOGRAPHY: '/statistic/clientsDemography',
  STATISTIC_FIL_ACTIVE_CLIENTS_WITH_APP: '/statistic/filActiveClientsWithApp',
  STATISTIC_FIL_ACTIVE_CONTRACTS: '/statistic/filActiveContracts',
  STATISTIC_FIL_ARCHIVE_CANDY: '/statistic/filArchiveCandy',
  STATISTIC_FIL_BALANCE: '/statistic/filBalance',
  STATISTIC_CLIENT_PAGES: '/statistic/clientPages',
  STATISTIC_USERS_LOGS: '/statistic/usersLogs',
  STATISTIC_SERVER_LOGS: '/statistic/serverLogs',
  WEBSITE_NEWS: '/websiteNews',
  PUBLISH_WEBSITE: '/publishWebsite',
  DEVELOPMENT: '/development',
  MFO: '/mfo',
  LATENESS: '/lateness',
  WALLET: '/wallet',
  WALLET_MONTH: '/walletMonth',
  WALLET_MONTH_FILIALS: '/walletMonthFilials',
  PAYMENT_MANAGEMENT_FILL_CHART: '/statistic/paymentManagementFillChart',
  STATISTIC_PROMOCODES: '/statistic/promocodes',
  FONDY_HOTLINES: '/fondyHotlines',
  INSURANCE_TYPE: '/insuranceType',
  IT_SERVICES: '/itServices',
  DUBLICATES: '/dublicates',
  HOTLINE_CALLS: '/hotlineCalls',
  QUESTIONNAIRES: '/questionnaires',
  SPHERE: '/sphere',
  PROMOCODES: '/promocodes',
  PARTNERS_USERS: '/partnersUsers',
};

// Please, for client pages statistics add additional information for your client routes!!!
const CLIENT_ROUTES = {
  //public client part
  CLIENT_LOGIN: {
    route: '/clientLogin',
    pathname: 'clientLogin',
    name: 'Вхід',
  },
  CLIENT_REGISTRATION: {
    route: '/clientReg',
    pathname: 'clientReg',
    name: 'Реєстрація',
  },
  CLIENT_CHANGE_PASSWORD: {
    route: '/clientChangePassword',
    pathname: 'clientChangePassword',
    name: 'Зміна паролю',
  },
  CLIENT_CONFIRM_NEW_PASSWORD: {
    route: '/clientConfirmNewPassword/:code/:email',
    pathname: 'clientConfirmNewPassword',
    name: 'Підтвердження нового паролю',
  },
  CLIENT_VERIFICATION_PHONE: {
    route: '/clientVerificationPhone',
    pathname: 'clientVerificationPhone',
    name: 'Підтвердження телефону',
  },
  CLIENT_POLICY_CONFIRM: {
    route: '/PolicyConfirm',
    pathname: 'PolicyConfirm',
    name: 'Підтвердження політики',
  },
  ANONIM_BONUS_HOME: {
    route: '/bonus3000',
    pathname: 'bonus3000',
    name: 'Анонімний бонус',
  },
  ANONIM_CERTIFICATE_OLD_: {
    route: '/certificate/',
    pathname: 'certificate',
    name: 'Старий анонімний сертифікат',
  },
  ANONIM_CERTIFICATE_: {
    route: '/cert/',
    pathname: 'cert',
    name: 'Анонімний сертифікат',
  },
  HOTLINE_MEET_INFO: {
    route: '/:hash',
    pathname: 'hotlineMeetInfo',
    name: 'Інформація про зустріч',
  },
  PAYMENT_SUCCESS: {
    route: '/paymentSuccess',
    pathname: 'paymentSuccess',
    name: 'Успішний платіж',
  },
  PAYMENT_FOR_HOTLINE: {
    route: '/pfh/:hotlineIndex',
    pathname: 'hotlinePayment',
    name: 'Платіж зустрічі',
  },
  LAWYER_CV: {
    route: '/cv/:id',
    pathname: 'cv',
    name: 'Резюме',
  },
  LAWYER_CV_HEADER_TEXT: '/cv/',
  FILII_STATE: {
    route: '/filiiState/:state',
    pathname: 'filiiState',
    name: 'Інфо філії',
  },
  CLUB_CARD_PAGE: {
    route: '/clubCardPage',
    pathname: 'clubCardPage',
    name: 'Клубна карта сторінка',
  },
  INVITE_LINK: {
    route: '/i/:token',
    pathname: 'i',
    name: 'Посилання запрошення',
  },

  //protected client part

  CLIENT_HOME: {
    route: '/homepage',
    pathname: 'homepage',
    name: 'Головна сторінка',
  },
  CLUB_CARD: {
    route: '/clubCard',
    pathname: 'clubCard',
    name: 'Клубна карта',
  },
  CLIENT_INSURANCE: {
    route: '/insuranceAccount',
    pathname: 'insuranceAccount',
    name: 'страховий рахунок',
  },
  CLIENT_HOTLINE: {
    route: '/hotline',
    pathname: 'hotline',
    name: 'Записи зустрічей',
  },
  // CLIENT_MY_HOTLINES: {
  //   route: '/myHotlines',
  //   pathname: 'myHotlines',
  //   name: 'Мої зустрічі',
  // },
  INVITE_FRIEND: {
    route: '/inviteFriend',
    pathname: 'inviteFriend',
    name: 'Запросити друга',
  },
  CLIENT_CONTRACTS: {
    route: '/contracts',
    pathname: 'contracts',
    name: 'Договори',
  },
  CLIENT_CONTRACT_INFO: {
    route: '/contractInfo/:id',
    pathname: 'contractInfo',
    name: 'Інфо договора',
  },
  CLIENT_CONTRACT_INFO_HEADER_TEXT: '/contractInfo/',
  CLIENT_PROFILE: {
    route: '/profile',
    pathname: 'profile',
    name: 'Профіль',
  },
  CLIENT_TENDERS: {
    route: '/clientTenders',
    pathname: 'clientTenders',
    name: 'Тендери',
  },
  CREATE_TENDER: {
    route: '/createTender',
    pathname: 'createTender',
    name: 'створення тендеру',
  },
  CLIENT_TENDER_INFO: {
    route: '/clientTenderInfo/:id',
    pathname: 'clientTenderInfo',
    name: 'Інфо тендеру',
  },
  CLIENT_TENDER_INFO_HEADER_TEXT: '/clientTenderInfo/',
  CLIENT_QUICK_CHAT: {
    route: '/clientQuickChat',
    pathname: 'clientQuickChat',
    name: 'Швидкі питання',
  },
  CLIENT_CONTACT_US: {
    route: '/clientContactUs',
    pathname: 'clientContactUs',
    name: 'Контакти',
  },
  CLIENT_BONUS: {
    route: '/bonusAccount',
    pathname: 'bonusAccount',
    name: 'Бонуси',
  },
  RED_BUTTON: {
    route: '/redButton',
    pathname: 'redButton',
    name: 'Червона кнопка',
  },
  CLIENT_CHATS: {
    route: '/clientChats',
    pathname: 'clientChats',
    name: 'Чат',
  },
  AUTOPAY_PAGE: {
    route: '/autopay',
    pathname: 'autopay',
    name: 'Автооплата',
  },
  CLIENTS_FEEDBACK: {
    route: '/clientsFeedback',
    pathname: 'clientsFeedback',
    name: 'Фідбек клієнта',
  },
  REGISTER: {
    route: '/register',
    pathname: 'register',
    name: 'Реєстр',
  },
  REGISTER_LIST: {
    route: '/registerList',
    pathname: 'registerList',
    name: 'Реєстр список',
  },
  REGISTER_SEARCH: {
    route: '/registerSearch',
    pathname: 'registerSearch',
    name: 'Реєстр пошук',
  },
  REGISTER_SELF_SEARCH: {
    route: '/registerSelfSearch',
    pathname: 'registerSelfSearch',
    name: 'Реєстр пошук себе',
  },
  WEBINAR_ID: {
    route: '/webinar/:id',
    pathname: 'webinar',
    name: 'Вебінар',
  },
  HOTLINE: {
    route: '/hotline',
    pathname: 'hotline',
    name: 'Запис на консультацію',
  },
  NOT_FOUND: {
    route: '/',
    pathname: 'notFound',
    name: 'Не знайдено',
  },

  // PUSH MODALS
  PUSH_MODALS: {
    INTERIM_SERVICE_QUALITY_ASSESSMENT: {
      route: '/pushModals/interimServiceQualityAssessment',
      pathname: 'pushModals/interimServiceQualityAssessment',
      modal: QUESTIONNAIRE_INTERIM_SERVICE_QUALITY_ASSESSMENT,
      name: 'Проміжкова оцінка якості послуг',
    },
  },
};

const OPEN_ROUTES = [
  ROUTES.AUTH,
  CLIENT_ROUTES.HOTLINE_MEET_INFO.route,
  CLIENT_ROUTES.ANONIM_BONUS_HOME.route,
  CLIENT_ROUTES.CLIENT_LOGIN.route,
  CLIENT_ROUTES.ANONIM_CERTIFICATE_.route,
  CLIENT_ROUTES.ANONIM_CERTIFICATE_OLD_.route,
  CLIENT_ROUTES.CLIENT_REGISTRATION.route,
  CLIENT_ROUTES.CLIENT_CHANGE_PASSWORD.route,
  CLIENT_ROUTES.CLIENT_CONFIRM_NEW_PASSWORD.route,
  CLIENT_ROUTES.PAYMENT_SUCCESS.route,
  CLIENT_ROUTES.PAYMENT_FOR_HOTLINE.route,
];

const ADMIN_ROUTES = [ROUTES.FILII, ROUTES.COMPLAINTS];

module.exports = {
  APP_DOMAIN,
  APP_LINK,
  CLIENT_DOMAIN,
  CLIENT_LINK,
  WEB_SITE_DOMAIN,
  WEB_SITE_LINK,
  OLD_WEB_SITE_DOMAIN,
  OLD_WEB_SITE_LINK,
  ROUTES,
  OPEN_ROUTES,
  ADMIN_ROUTES,
  APP_LINK_ANDROID,
  APP_LINK_IOS,
  GET_IP_API,
  GEO_API,
  CLIENT_ROUTES,
};
