import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Spin } from 'antd';

import { Row } from 'common_components';
import { request } from '../../tools';
import { error } from '../../tools';
import { setClientFeedbackList } from '../../store/commonReducer';

import dayjs from 'dayjs';
import ClientFeedbackInfo from './ClientFeedbackInfo';
import './ClientsFeedback.scss';

const ClientsFeedback = () => {
  const dispatch = useDispatch();
  const mob = useSelector((state) => state.ui.mob);
  const feedbackList = useSelector((state) => state.common.clientFeedbackList);
  const [loading, setLoading] = useState(false);

  const getAllFeedback = () => {
    setLoading(true);

    const body = {};

    request.post('/clientFeedback/get', body, (req, res) => {
      if (req.status) {
        dispatch(setClientFeedbackList(req.data));
        setLoading(false);
      } else {
        setLoading(false);
        error('Помилка завантаженя');
      }
    });
  };

  useEffect(() => {
    getAllFeedback();
  }, []);

  const columns = [
    ...(mob
      ? [
          {
            title: 'Інфо',
            key: 'mobX3',
            render: (feedback) => (
              <Row style={{ alignItems: 'center' }}>
                <div style={{ marginRight: 8 }}>
                  <span style={{ color: feedback.rating < 4 && 'red' }}>{feedback.rating}</span>
                  <br />
                  <span>{dayjs(feedback.date).format('DD.MM.YYYY')}</span>
                  <br />
                  <span>{feedback.name}</span>
                  <br />
                  <span>{feedback.email}</span>
                  <br />
                  <span>{feedback?.language}</span>
                </div>
              </Row>
            ),
          },
        ]
      : [
          {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (date) => dayjs(date).format('DD.MM.YYYY'),
          },
          {
            title: 'Оцінка',
            dataIndex: 'rating',
            key: 'rating',
            render: (rating) => <span style={{ color: rating < 4 && 'red' }}>{rating}</span>,
          },
          {
            title: "Ім'я",
            dataIndex: 'name',
            key: 'name',
            render: (name) => name,
          },
          {
            title: 'Пошта',
            dataIndex: 'email',
            key: 'email',
            render: (email) => email,
          },
          {
            title: 'Мова',
            dataIndex: 'language',
            key: 'language',
            render: (language) => language,
          },
        ]),

    {
      title: 'Відгук',
      dataIndex: 'message',
      key: 'feedback',
      render: (message) => <div className="feedback-message">{message}</div>,
    },
  ];
  return (
    <div className="clients_page default_page">
      <h2>Відгуки клієнтів на сайті</h2>
      <Spin spinning={loading} tip="Зачекайте" size="large">
        <Table
          dataSource={feedbackList}
          columns={columns}
          rowKey={(item) => item._id}
          rowClassName={(record) => (record.processed ? 'processed-feedback' : 'unprocessed-feedback')}
          expandable={{
            expandRowByClick: true,
            expandIconColumnIndex: -1,
            expandedRowRender: (item) => <ClientFeedbackInfo data={item} setLoading={setLoading} />,
          }}
          //   pagination={{ total, current: currentPage }}
          //   onChange={handleTableChange}
        />
      </Spin>
    </div>
  );
};

export default ClientsFeedback;
