const PHONE_VALIDATION = [
  {
    required: true,
    message: 'Введіть номер телефону!',
  },
  {
    pattern: /^[0-9]{10}$/,
    message: 'Номер телефону повинен містити тільки 10 цифр та починатися з 0!',
  },
];

export default PHONE_VALIDATION;
