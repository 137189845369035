import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Spin, Modal, DatePicker, Typography } from 'antd';
import { CSVLink } from 'react-csv';
import {useResize} from 'common_components';

import { setModal } from '../../store/commonReducer';
import Excel from '../../images/excel_logo.svg';
import { request, error, success } from '../../tools';
import Box from '../../components/Box';

import dayjs from 'dayjs';
import locale from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';

import './styles.scss';
import { POSLUGI } from 'common_constants/business';

const ExportAllHotline = () => {
  const dispatch = useDispatch();
  const resize = useResize();
  const { RangePicker } = DatePicker;
  const { Text } = Typography;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [dateArr, setDateArr] = useState('_');

  const onGenerate = () => {
    setLoading(true);

    const transaction = { ...(dateArr !== '_' ? { dateArr: dateArr } : {}) };

    request.post(
      '/spreadsheets/exportAllHotline',
      transaction,
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };

  const csvData = [['Створено', 'Автор', 'Послуга']];

  data?.forEach((i) => {
    csvData.push([
      dayjs(i.crAt)?.format('DD.MM.YYYY HH:mm:ss') ?? '-', //* 'Створено'
      (window.users && window.users[i.author]?.p) ?? '-', //* Автор
      i.vip ? 'VIP' : POSLUGI[i.type] ?? '-', //* Послуга + VIP
    ]);
  });

  return (
    <Modal open className="export-hotline" title="Експорт консультацій + клієнтів." onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="change">
          <Box className={resize[0] >= 500 ? 'exp-filter-box-pc' : 'exp-filter-box-mob'}>
            <RangePicker
              locale={locale}
              className="exp-filter"
              onChange={(value) => setDateArr(value ? [new Date(value?.[0]), new Date(value?.[1])] : '_')}
            />
            <Text className="exp-filter-box-text"> Дата звітування: </Text>
          </Box>
          <br />
          {data ? (
            <CSVLink asyncOnClick={true} data={csvData}>
              <Button className="excel">
                <img className="excel-img" src={Excel} alt="Excel" /> Скачати .csv
              </Button>
            </CSVLink>
          ) : (
            <Button onClick={onGenerate}>Генерувати</Button>
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

export default ExportAllHotline;
