import React from 'react';
import { useSelector } from 'react-redux';

import * as MODALS from 'common_constants/modals';

import Actualization from './Actualization';
import AddAssignment from './AddAssignment';
import AddBankCards from './AddBankCards';
import AddBlock from './AddBlock';
import AddExpense from './AddExpense';
import InfoExpense from './InfoExpense';
import AddFee from './AddFee';
import AddHarvest from './AddHarvest';
import AddSugar from './AddSugar';
import AddInsurance from './AddInsurance';
import InfoInsurance from './InfoInsurance';
import AddWebinar from './AddWebinar';
import ArchiveCalendar from './ArchiveCalendar';
import ArchiveContract from './ArchiveContract';
import AssignmentMarkAsIncorrect from './AssignmentMarkAsIncorrect/AssignmentMarkAsIncorrect';
import AssignmentReport from './AssignmentReport';
import AssignmentsInfoList from './AssignmentsInfoList';
import WalletAssignment from './WalletAssignment';
import BellToReach from './BellToReach';
import ButtonContainer from './ButtonContainer';
import ChatForHotline from './ChatForHotline';
import ChatForTender from './ChatForTender';
import ClientBalanceTransaction from './ClientBalanceTransaction';
import ClientInfo from './ClientInfo';
import ConfirmAddContract from './ConfirmAddContract';
import ContractTasks from './ContractTasks/ContractTasks';
import { ContractListFiles } from './ContractListFiles';
import CorrespondenceAddDocument from './CorrespondenceAddDocument';
import CorrespondenceModalForTheContract from './CorrespondenceModalForTheContract';
import CourtPractice from './CourtPractice/CourtPractice';
import DocumentTemplates from './DocumentTemplates';
import UserCourtPractice from './CourtPractice/UserCourtPractice';
import ExportActivity from './ExportActivity';
import ExportAllHotline from './ExportAllHotline';
import ExportPaymentQuickChat from './ExportPaymentQuickChat';
import ExportActualizations from './ExportActualizations';
import ExportArchivalQuestionnaires from './ExportArchivalQuestionnaires';
import ExportArchiveContracts from './ExportArchiveContracts';
import ExportAssignments from './ExportAssignments';
import ExportContracts from './ExportContracts';
import ExportCorrespondence from './ExportCorrespondence';
import ExportHotlineQuestionnaires from './ExportHotlineQuestionnaires';
import ExportIncomes from './ExportIncomes';
import ExportRatingLawyers from './ExportRatingLawyers/ExportRatingLawyers';
import ExportLibrary from './ExportLibrary';
import ExportMonthlyPayment from './ExportMonthlyPayment';
import ExportPayments from './ExportPayments';
import ExportDisabledPeople from './ExportDisabledPeople';
import HarvestInfoList from './HarvestInfoList/HarvestInfoList';
import Incomes from './Incomes';
import LibraryAddDocument from './LibraryAddDocument';
import LibraryMiniList from './LibraryMiniList';
import BalanceMonthly from './BalanceMonthly';
import MonthlyPaymentContract from './MonthlyPaymentContract';
import MoveToArchive from './MoveToArchive';
import NewClient from './NewClient';
import NewClientTransaction from './NewClientTransaction';
import NewContract from './NewContract';
import PublishFeedback from './PublishFeedback';
import RespondToTender from './RespondToTender';
import SearchClient from './SearchClient';
import TaskInfo from './TaskInfo';
import TasksFilter from './TasksFilter';
import VotingForArchiving from './VotingForArchiving';
import HistoryHotlines from './HistoryHotlines';
import HotlineSubmit from './HotlineSubmit/HotlineSubmit';
import PsychologistHotlineSubmit from './PsychologistHotlineSubmit/PsychologistHotlineSubmit';
import AutopayInfoList from './AutopayInfoList';
import ConflictComplete from './ConflictComplete';
import SendContractDataMFO from './SendContractDataMFO/SendContractDataMFO';
import PaymentManagement from './PaymentManagement';
import ExportClientsCosts from './ExportClientsCosts';
import ContractPayrollPercents from './ContractPayrollPercents';
import CreateNewGroupChat from './CreateNewGroupChat';
import AddExpenseLateness from './AddExpenseLateness';
import ChatGroupInfo from './ChatGroupInfo/ChatGroupInfo';
import AddChatGroupUsers from './AddChatGroupUsers';
import ExportLoad from './ExportLoad';
import ClubCardDecrement from './ClubCardDecrement';
import AddMfo from './AddMfo';
import AddTweet from './AddTweet';
import CheckSugar from './CheckSugar';
import ExportQuickChats from './ExportQuickChats';
import AddInsuranceType from './AddInsuranceType';
import AddClubCard from './AddClubCard';
import FilialWorkers from './FilialWorkers';
import AssignmentDocuments from './AssignmentDocuments';
import ContractChatsFilter from './ContractsChatsFilter';
import PsychologistHotlineRemove from './PsychologistHotlineRemove';
import PsychologistHotlineReporting from './PsychologistHotlineReporting/PsychologistHotlineReporting';
import PsychologistHotlineEdit from './PsychologistHotlineEdit/PsychologistHotlineEdit';
import DismissalEmployee from './DismissalEmployee/DismissalEmployee';
import ChatWithAccountant from './ChatWithAccountant';
import HotlineCallsFilter from './HotlineCallsFilter';
import AddHotlineCallsReport from './AddHotlineCallsReport';
import HotlineQue from './HotlineQue';
import HotlineMeet from './HotlineMeet';
import ExportSuggestionsAndComplaints from './ExportSuggestionsAndComplaints';
import ExportHotlineCalls from './ExportHotlineCalls';
import AddMoneyToClientBalance from './AddMoneyToClientBalance';
import SphereEditCategory from './SphereEditCategory';
import SphereEditServiceItem from './SphereEditServiceItem';
import AddPromocode from './AddPromocode';
import './style.scss';
import AddPartner from './AddPartner';


const Modals = () => {
  const name = useSelector((state) => state.common.modal?.name);

  switch (name) {
    case MODALS.NEW_CONTRACT:
      return <NewContract />;
    case MODALS.ARCHIVE_CONTRACT:
      return <ArchiveContract />;
    case MODALS.MONTHLY_PAYMENT_CONTRACT:
      return <MonthlyPaymentContract />;
    case MODALS.LIBRARY_ADD_DOCUMENT:
      return <LibraryAddDocument />;
    case MODALS.LIBRARY_MINI_LIST:
      return <LibraryMiniList />;
    case MODALS.BALANCE_MONTHLY:
      return <BalanceMonthly />;
    case MODALS.CORRESPONDENCE_ADD_DOCUMENT:
      return <CorrespondenceAddDocument />;
    case MODALS.CORRESPONDENCE_MODAL_FOR_THE_CONTRACT:
      return <CorrespondenceModalForTheContract />;
    case MODALS.NEW_CLIENT:
      return <NewClient />;
    case MODALS.CLIENT_INFO:
      return <ClientInfo />;
    case MODALS.NEW_TRANSACTION:
      return <NewClientTransaction />;
    case MODALS.SEARCH_CLIENT:
      return <SearchClient />;
    case MODALS.EXPORT_ARCHIVE_CONTRACTS:
      return <ExportArchiveContracts />;
    case MODALS.EXPORT_CONTRACTS_CLIENTS:
      return <ExportContracts />;
    case MODALS.EXPORT_QUICK_CHATS:
      return <ExportQuickChats />;
    case MODALS.EXPORT_MONTHLY_PAYMENT:
      return <ExportMonthlyPayment />;
    case MODALS.EXPORT_HOTLINE_QUESTIONNAIRES:
      return <ExportHotlineQuestionnaires />;
    case MODALS.EXPORT_ARCHIVAL_QUESTIONNAIRES:
      return <ExportArchivalQuestionnaires />;
    case MODALS.EXPORT_ALL_HOTLINE:
      return <ExportAllHotline />;
    case MODALS.EXPORT_PAYMENT_QUICK_CHAT:
      return <ExportPaymentQuickChat />;
    case MODALS.EXPORT_ACTUALIZATIONS:
      return <ExportActualizations />;
    case MODALS.EXPORT_ASSIGNMENTS:
      return <ExportAssignments />;
    case MODALS.EXPORT_ACTIVITY:
      return <ExportActivity />;
    case MODALS.EXPORT_INCOMES:
      return <ExportIncomes />;
    case MODALS.EXPORT_RATING_LAWYERS:
      return <ExportRatingLawyers />;
    case MODALS.EXPORT_LOAD:
      return <ExportLoad />;
    case MODALS.EXPORT_LIBRARY:
      return <ExportLibrary />;
    case MODALS.EXPORT_CORRESPONDENCE:
      return <ExportCorrespondence />;
    case MODALS.EXPORT_DISABLED_PEOPLE:
      return <ExportDisabledPeople />;
    case MODALS.CONFIRM_ADD_CONTRACT:
      return <ConfirmAddContract />;
    case MODALS.MOVE_TO_ARCHIVE:
      return <MoveToArchive />;
    case MODALS.ARCHIVE_CALENDAR:
      return <ArchiveCalendar />;
    case MODALS.CHAT_FOR_TENDER:
      return <ChatForTender />;
    case MODALS.CHAT_FOR_HOTLINE:
      return <ChatForHotline />;
    case MODALS.BELL_TO_REACH:
      return <BellToReach />;
    case MODALS.ADD_HARVEST:
      return <AddHarvest />;
    case MODALS.ADD_BANK_CARDS:
      return <AddBankCards />;
    case MODALS.VOTING_FOR_ARCHIVING:
      return <VotingForArchiving />;
    case MODALS.ADD_ASSIGNMENT:
      return <AddAssignment />;
    case MODALS.ASSIGNMENT_REPORT:
      return <AssignmentReport />;
    case MODALS.ASSIGNMENTS_INFO_LIST:
      return <AssignmentsInfoList />;
    case MODALS.WALLET_ASSIGNMENT:
      return <WalletAssignment />;
    case MODALS.HARVEST_INFO_LIST:
      return <HarvestInfoList />;
    case MODALS.ASSIGNMENT_MARK_AS_INCORRECT:
      return <AssignmentMarkAsIncorrect />;
    case MODALS.ADD_FEE:
      return <AddFee />;
    case MODALS.ADD_BLOCK:
      return <AddBlock />;
    case MODALS.PSYCHOLOGIST_HOTLINE_REMOVE:
      return <PsychologistHotlineRemove />;
    case MODALS.PSYCHOLOGIST_HOTLINE_REPORTING:
      return <PsychologistHotlineReporting />;
    case MODALS.DISMISSAL_EMPLOYEE:
      return <DismissalEmployee />;
    case MODALS.PSYCHOLOGIST_HOTLINE_EDIT:
      return <PsychologistHotlineEdit />;
    case MODALS.PUBLISH_FEEDBACK:
      return <PublishFeedback />;
    case MODALS.CONTRACT_TASKS_CRM:
      return <ContractTasks />;
    case MODALS.CONTRACT_LIST_FILES:
      return <ContractListFiles />;
    case MODALS.INCOMES:
      return <Incomes />;
    case MODALS.BUTTON_CONTAINER:
      return <ButtonContainer />;
    case MODALS.ADD_EXPENSE:
      return <AddExpense />;
    case MODALS.INFO_EXPENSE:
      return <InfoExpense />;
    case MODALS.EXPORT_PAYMENTS:
      return <ExportPayments />;
    case MODALS.CLIENT_BALANCE_TRANSACTION:
      return <ClientBalanceTransaction />;
    case MODALS.ADD_TO_CLIENT_BALANCE:
      return <AddMoneyToClientBalance />;
    case MODALS.ACTUALIZATION:
      return <Actualization />;
    case MODALS.TASK_INFO:
      return <TaskInfo />;
    case MODALS.TASKS_FILTER:
      return <TasksFilter />;
    case MODALS.ADD_SUGAR:
      return <AddSugar />;
    case MODALS.CHECK_SUGAR:
      return <CheckSugar />;
    case MODALS.ADD_INSURANCE:
      return <AddInsurance />;
    case MODALS.INFO_INSURANCE:
      return <InfoInsurance />;
    case MODALS.ADD_CLUB_CARD:
      return <AddClubCard />;
    case MODALS.ADD_WEBINAR:
      return <AddWebinar />;
    case MODALS.RESPOND_TO_TENDER:
      return <RespondToTender />;
    case MODALS.COURT_PRACTICE:
      return <CourtPractice />;
    case MODALS.USER_COURT_PRACTICE:
      return <UserCourtPractice />;
    case MODALS.HISTORY_HOTLINES:
      return <HistoryHotlines />;
    case MODALS.HOTLINE_SUBMIT:
      return <HotlineSubmit />;
    case MODALS.PSYCHOLOGIST_HOTLINE_SUBMIT:
      return <PsychologistHotlineSubmit />;
    case MODALS.ADD_MFO:
      return <AddMfo />;
    case MODALS.DOCUMENT_TEMPLATES:
      return <DocumentTemplates />;
    case MODALS.AUTOPAY_INFO_LIST:
      return <AutopayInfoList />;
    case MODALS.CONFLICT_COMPLETE:
      return <ConflictComplete />;
    case MODALS.ADD_TWEET:
      return <AddTweet />;
    case MODALS.SEND_CONTRACT_DATA_MFO:
      return <SendContractDataMFO />;
    case MODALS.PAYMENT_MANAGEMENT:
      return <PaymentManagement />;
    case MODALS.EXPORT_CLIENTS_COSTS:
      return <ExportClientsCosts />;
    case MODALS.CONTRACT_PAYROLL_PERCENTS:
      return <ContractPayrollPercents />;
    case MODALS.CREATE_NEW_GROUP_CHAT:
      return <CreateNewGroupChat />;
    case MODALS.ADD_EXPENSE_LATENESS:
      return <AddExpenseLateness />;
    case MODALS.CHAT_GROUP_INFO:
      return <ChatGroupInfo />;
    case MODALS.ADD_CHAT_GROUP_USERS:
      return <AddChatGroupUsers />;
    case MODALS.CLUB_CARD_DECREMENT:
      return <ClubCardDecrement />;
    case MODALS.ADD_INSURANCE_TYPE:
      return <AddInsuranceType />;
    case MODALS.FILIAL_WORKERS:
      return <FilialWorkers />;
    case MODALS.ASSIGNMENT_DOCUMENTS:
      return <AssignmentDocuments />;
    case MODALS.CONTRACTS_CHATS_FILTER:
      return <ContractChatsFilter />;
    case MODALS.CHAT_WITH_ACCOUNTANT:
      return <ChatWithAccountant />;
    case MODALS.HOTLINE_CALLS_FILTER:
      return <HotlineCallsFilter />;
    case MODALS.ADD_HOTLINE_CALLS_REPORT:
      return <AddHotlineCallsReport />;
    case MODALS.HOTLINE_QUE:
      return <HotlineQue />;
    case MODALS.HOTLINE_MEET_MODAL:
      return <HotlineMeet />;
    case MODALS.EXPORT_HOTLINE_CALLS:
      return <ExportHotlineCalls />;
    case MODALS.EXPORT_SUGGESTION_AND_COMPLAINTS:
      return <ExportSuggestionsAndComplaints />;
    case MODALS.SPHERE_EDIT_SERVICE_ITEM:
      return <SphereEditServiceItem />;
    case MODALS.SPHERE_EDIT_CATEGORY:
      return <SphereEditCategory />;
    case MODALS.ADD_PROMOCODE:
      return <AddPromocode />;
    case MODALS.ADD_PARTNER:
      return <AddPartner />;
    default:
      return null;
  }
};

export default Modals;
