import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';

const ServiceItemsListItem = ({ serviceItem, handleUpdateCategory, handleDeleteCategory }) => {
  return (
    <div key={serviceItem._id} className="list-item">
      <Typography.Text className="item-title">{serviceItem.text.ua}</Typography.Text>
      <div className="item-actions">
        <Button className="edit" onClick={(e) => handleUpdateCategory(e, serviceItem)}>
          <EditOutlined />
        </Button>
        <Button className="delete" onClick={(e) => handleDeleteCategory(e, serviceItem._id)}>
          <DeleteOutlined />
        </Button>
      </div>
    </div>
  );
};

export default ServiceItemsListItem;
