import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHAT_TYPES } from 'common_constants/business';
import { ADD_CHAT_GROUP_USERS } from 'common_constants/modals';
import { TeamOutlined, UserAddOutlined } from '@ant-design/icons';

import { setModal } from '../../store/commonReducer';
import { UserColorAvatar } from '../../components/UserAvatar';
import { request, error } from '../../tools';

import { Modal, Avatar } from 'antd';

import './styles.scss';

const ChatGroupInfo = () => {
  const dispatch = useDispatch();

  const modalData = useSelector((state) => state.common.modal.data);
  const chatsData = useSelector((state) => state.common.chatsData);
  const users = useSelector((state) => state.common.users);
  const onlineUsers = useSelector((state) => state.common.onlineUsers);

  const currentChat = chatsData?.[CHAT_TYPES.groupChat.key]?.find((chat) => chat._id === modalData.chatId);

  const [groupChatAvatarBase64, setGroupChatAvatarBase64] = useState(null);

  const getUser = (userId) => {
    return users?.[userId] ?? {};
  };

  const onClickAddUsers = () => {
    dispatch(
      setModal({
        name: ADD_CHAT_GROUP_USERS,
        data: { currentUsers: currentChat?.users, chatId: currentChat._id, groupOwner: currentChat?.groupOwner },
      }),
    );
  };

  const onCancel = () => {
    dispatch(setModal());
  };

  useEffect(() => {
    if (!currentChat?.avatarImage) return;

    request.post(
      '/chatPrivate/chatGetFileBase64',
      { chatId: currentChat?._id, type: CHAT_TYPES.groupChat.key, fileId: currentChat.avatarImage },
      ({ image }) => {
        setGroupChatAvatarBase64(image);
      },
      error,
    );

    return () => {};
  }, [currentChat?.avatarImage]);

  return (
    <Modal open className="chat-group-info" title="Про групу" onCancel={onCancel} footer={null}>
      <div className="chat-info">
        <div>
          <Avatar size={70} icon={<TeamOutlined />} src={`data:image/jpeg;charset=utf-8;base64, ${groupChatAvatarBase64}`} />
        </div>
        <div>
          <p className="chat-name">{currentChat?.chatName}</p>
          <p>{currentChat?.users?.length < 5 ? `${currentChat?.users?.length} учасники` : `${currentChat?.users?.length} учасників`}</p>
        </div>
      </div>

      <div className="members">
        <div onClick={onClickAddUsers} className="members-header">
          <div className="members-header-left">
            <TeamOutlined size="large" />
            <p>{currentChat?.users?.length < 5 ? `${currentChat?.users?.length} учасники` : `${currentChat?.users?.length} учасників`}</p>
          </div>
          <UserAddOutlined size="large" />
        </div>
        <div>
          {currentChat?.users?.map((userId) => (
            <div className="member" key={userId}>
              <div className="member-info">
                <div>
                  <UserColorAvatar size="large" user={getUser(userId)} />
                </div>
                <div>
                  <p className="member-name">{getUser(userId)?.p}</p>
                  {onlineUsers?.includes(userId) && <p className="member-online">В мережі</p>}
                </div>
              </div>
              {userId === currentChat?.groupOwner && <p className="member-owner">Власник</p>}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ChatGroupInfo;
