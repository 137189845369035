import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form } from 'antd';

import { request, info, success } from '../../../tools';
import { setPartnersList } from '../../../store/commonReducer';
import PartnerAddInput from '../PartnerAddInput/PartnerAddInput';
import PartnerAddSubmitBtn from '../PartnerAddSubmitBtn';

const PartnerAddModal = (props) => {
  const { isOpen, setAddPartnerModalState } = props;
  const dispatch = useDispatch();
  const list = useSelector((state) => state.common.partners);

  const [form] = Form.useForm();

  const handleCancel = () => setAddPartnerModalState(false);

  const onFinish = (values) => {
    const partnerData = { ...values };

    request.post('/partners/add', { partnerData }, (res) => {
      const { insertedPartner, isPhoneInUse } = res;

      if (isPhoneInUse) return info('Пертнера з таким номером телефону вже зареєстровано!');
      success('Партнера успішно додано!');
      dispatch(setPartnersList([...list, insertedPartner]));
      form.resetFields();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Modal title="Створення партнера" open={isOpen} onCancel={handleCancel} footer={null}>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <PartnerAddInput label={'ПІБ'} name={'name'} required />
          <PartnerAddInput label={'Дата народження'} name={'birthday'} required isDateField />
          <PartnerAddInput label={'Телефон'} name={'ph'} required isPhoneField />
          <PartnerAddInput label={'Пошта'} name={'email'} required />
          <PartnerAddInput label={'Місто'} name={'city'} required />
          <PartnerAddSubmitBtn />
        </Form>
      </Modal>
    </>
  );
};

export default PartnerAddModal;
