import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

const GroupChatAddPhoto = ({ imageUrl, setImageUrl, onChangeAvatar, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (file) => {
    setLoading(true);
    const isJpgOrPng = file.file.type === 'image/jpeg' || file.file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      setLoading(false);
      return;
    }
    const isLt2M = file.file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      setLoading(false);
      return;
    }

    getBase64(file.fileList[0].originFileObj, (url) => {
      setLoading(false);
      setImageUrl(url);
    });
    onChangeAvatar(file);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Загрузити
      </div>
    </div>
  );
  return (
    <Upload
      {...rest}
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      onChange={handleChange}
      maxCount={1}
      beforeUpload={() => false}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{
            width: '100%',
          }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default GroupChatAddPhoto;
