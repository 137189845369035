import React from 'react';
import { JOBS } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralJobs = [JOBS, ['Професія по клієнту', 'j_C']];

const SelectGeneralJobs = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть професію"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі професії</Option>
      {Object.entries(JOBS).map((i) => (
        <Select.Option key={i[0]} value={i[0]}>
          {i[1]}
        </Select.Option>
      ))}
    </Select>
  );
};

export { billSelectGeneralJobs, SelectGeneralJobs };
