import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Form, Radio, Button, Select, Input, Switch, Collapse, Alert } from 'antd';

import { setModal } from '../../store/commonReducer';
import { setLawyersList, setAssignmentsList } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import { ADD_SUGAR } from 'common_constants/modals';
import { updateAssignmentsListWithData } from '../ModalsHelpers';
import { ASSIGNMENT_PAYMENT_STATUS, ASSIGNMENT_STATUS_OPTIONS, HARVEST_STATUS_OPTIONS } from 'common_constants/business';

const { Panel } = Collapse;

const AssignmentReport = () => {
  const dispatch = useDispatch();
  const lawyersList = useSelector((state) => state.common.lawyersList);
  const assignmentsList = useSelector((state) => state.common.assignmentsList);

  const { data, prev } = useSelector((state) => state.common.modal);
  const [loading, setLoading] = useState(false);
  const [isNewLawyer, setIsNewLawyer] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleCollapseChange = () => setIsExpanded(!isExpanded);
  const [sugarIndex, setSugarIndex] = useState(data?.sugar?.indexes?.length > 0 ? data?.sugar?.indexes[0] : '');
  const totalSugarAmount = data?.sugar?.amounts?.reduce((a, b) => a + b, 0) || 0;
  const isEnoughSugar = totalSugarAmount >= Number(data?.clientAmount);
  const onCancel = () => {
    dispatch(setModal(prev));
  };

  const onFinish = (values) => {
    const { assignmentStatus, lawyerId, newLawyer, paymentStatus, isNeededNewHarvest } = values;
    const reportBody = {
      contractIndex: data.contractNumber,
      assignmentId: data._id,
      assignmentStatus,
      paymentStatus,
      isNewLawyer,
      lawyerId,
      newLawyer,
      sugarIndex,
    };

    // const isPaymentStatusPayed = paymentStatus === ASSIGNMENT_PAYMENT_STATUS.PAYED;
    // const hasNoSugar = data?.sugar?.amounts?.length === 0;
    // const hasSugarAndNeedsNew = data?.sugar?.amounts?.length > 0 && isNeededNewHarvest === '1';

    // if ((isPaymentStatusPayed && hasNoSugar && !sugarIndex) || (isPaymentStatusPayed && hasSugarAndNeedsNew && !sugarIndex)) {
    //   dispatch(setModal({ name: ADD_SUGAR, data: { reportBody, assignmentData: data } }));
    //   return;
    // }

    const onSuccess = (res) => {
      const updatedData = { report: res.report };

      const updatedAssignments = updateAssignmentsListWithData(assignmentsList, data._id, updatedData);
      dispatch(setAssignmentsList(updatedAssignments));

      if (res.insertedLawyer) {
        dispatch(setLawyersList([...lawyersList, res.insertedLawyer]));

        const updatedData = { lawyerId: res.insertedLawyer._id };
        const updatedAssignments = updateAssignmentsListWithData(assignmentsList, data._id, updatedData);

        dispatch(setAssignmentsList(updatedAssignments));
      }

      if (lawyerId && !isNewLawyer) {
        const updatedData = { lawyerId };
        const updatedAssignments = updateAssignmentsListWithData(assignmentsList, data._id, updatedData);

        dispatch(setAssignmentsList(updatedAssignments));
      }

      success('Вихід успішно додано.', ' ');
      onCancel();
    };

    const onError = (_, __, axiosData) => {
      error(' ', axiosData.response.data.error);
      setLoading(false);
    };

    setLoading(true);
    request.post('/assignment/report', reportBody, onSuccess, onError);
  };

  const onChangeIsNewLawyer = (checked) => setIsNewLawyer(checked);

  return (
    <Modal open className="" title={'Звіт'} onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={loading}>
        <Form name="nest-messages" onFinish={onFinish} style={{ maxWidth: 600, overflow: 'hidden' }}>
          <Form.Item initialValue={ASSIGNMENT_PAYMENT_STATUS.PAYED} name={'assignmentStatus'} label="Статус виходу">
            <Radio.Group options={ASSIGNMENT_STATUS_OPTIONS} optionType="button" buttonStyle="solid" />
          </Form.Item>

          <Form.Item
            initialValue={data?.sugar?.amounts?.length > 0 ? ASSIGNMENT_PAYMENT_STATUS.PAYED : ASSIGNMENT_PAYMENT_STATUS.NOT_PAYED}
            name={'paymentStatus'}
            label="Статус оплати"
          >
            <Radio.Group disabled={data?.sugar?.amounts?.length > 0} options={HARVEST_STATUS_OPTIONS} optionType="button" buttonStyle="solid" />
          </Form.Item>
          {/* <Form.Item label="Номер сахарка" name={'sugarId'} initialValue={sugarIndex} onChange={(e) => setSugarIndex(e.target.value)}>
            <Input></Input>
          </Form.Item> */}
          {isEnoughSugar ? (
            <Alert style={{ marginBottom: '20px' }} message="Оплата клієнта" description={data?.sugar?.amounts.join(', ')} type="success" showIcon />
          ) : (
            <Alert style={{ marginBottom: '20px' }} message="Оплата недостатня" description={data?.sugar?.amounts.join(', ')} type="info" showIcon />
          )}

          <Collapse className="assignment-filter-colapse" in={isExpanded} onChange={handleCollapseChange}>
            <Panel header="Змінити адвоката">
              <Form.Item name={'isNewLawyer'} label="Новий адвокат" initialValue={isNewLawyer}>
                <Switch checked={isNewLawyer} onChange={onChangeIsNewLawyer} />
              </Form.Item>

              {!isNewLawyer && (
                <Form.Item name={'lawyerId'} label="Адвокат">
                  <Select
                    showSearch
                    style={{
                      width: '100%',
                    }}
                    placeholder="Оберіть адвоката"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    sorter={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {lawyersList?.map((lawyer) => (
                      <Select.Option key={lawyer._id} value={lawyer._id}>
                        {lawyer.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              {isNewLawyer && (
                <Form.Item name={'newLawyer'} label="Новий адвокат">
                  <Input placeholder="Введіть ПІБ адвоката" />
                </Form.Item>
              )}
            </Panel>
          </Collapse>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              {'Відправити'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AssignmentReport;
