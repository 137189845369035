import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Card, Divider, Modal, Popconfirm, Spin, Tag, Typography, Timeline, Checkbox } from 'antd';
import { lime, orange, volcano, yellow } from '@ant-design/colors';
import {
  CheckOutlined,
  CloseOutlined,
  CrownOutlined,
  DatabaseOutlined,
  DeleteFilled,
  DiffOutlined,
  EditFilled,
  ExclamationCircleFilled,
  PhoneOutlined,
  ReadOutlined,
  RedoOutlined,
  TabletFilled,
  UpOutlined,
  UsbOutlined,
} from '@ant-design/icons';

import dayjs from 'dayjs';
import clsx from 'clsx';

import { Box, Ribbon } from 'common_components';
import {
  CURRENCIES,
  HOTLINE_MEET,
  HOTLINE_RESULT,
  POSLUGI_NOMINATIVE_CASE,
  POSLUGI_NOMINATIVE_CASE_MOBILE_SIZE,
  PRAVO,
  ROLES,
  WHY_NOT_USE_APP,
} from 'common_constants/business';
import {
  ADD_BLOCK,
  CHAT_FOR_HOTLINE,
  LIBRARY_MINI_LIST,
  NEW_CONTRACT,
  HISTORY_HOTLINES,
  HOTLINE_MEET_MODAL,
  HOTLINE_QUE,
} from 'common_constants/modals';
import { ROUTES } from 'common_constants/routes';
import useResize from 'common_components/src/useResize';

import Timer from '../../components/Timer';
import CheckClientWallet from '../../components/Hotline/CheckClientWallet';
import { editHotline, setModal } from '../../store/commonReducer';
import { error, info, request, success, warn } from '../../tools';
import HotlineContractDetails from '../Journal/HotlineContractDetails';
import HotlineMeetDetails from '../Journal/HotlineMeetDetails';
import HotlineRequestBody from '../Journal/HotlineRequestBody';
import Block5daysProject3000 from './Core/Block5daysProject3000';

import BlockBtn from '../../UI/BlockBtn/BlockBtn';
import SearchSelectUser from '../../components/SearchSelect/SearchSelectUser';

import './List.scss';

const getSerializbleItemData = (item) => {
  return { ...item, date: item?.date?.toISOString(), crAt: item?.date?.toISOString() };
};

const Item = ({
  i,
  index,
  onEditClick,
  removeConfirm,
  isInJournal,
  meetReport,
  changeContract,
  contract,
  client,
  requestBody,
  updateRecord,
  blocked,
  pravoRatings,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const resize = useResize();
  const users = useSelector((state) => state.common.users);
  const usersArr = useSelector((state) => Object.values(state.common.users));
  const FILII = useSelector((state) => state.common.FILII);
  const promocodes = useSelector((state) => state.common.promocodes);
  const { role: userRole } = useSelector((state) => state.common.userAuth);
  const notFiredUsers = usersArr.filter((user) => user?.fired !== true);

  const inspector = ROLES[userRole] <= ROLES.inspector;
  const manager = ROLES[userRole] <= ROLES.manager;
  const worker = ROLES[userRole] <= ROLES.worker;
  const rabfilOnly = ROLES[userRole] >= ROLES.rabfil;

  const konsultation = i.type === '0' || i.type === '3' || i.type === '6' || i.type === '8';
  const isIC = i.type === '6'; // is it information center

  const { confirm } = Modal;
  const { Text } = Typography;

  const filClose = i.filClose;
  const filOnline = i.filOnline;
  const createdFromCrm = Number.isFinite(i.cost) && i.pravo;
  const finishHotlineBtns = !createdFromCrm && !filClose && !filOnline && !isInJournal;

  const [expand, setExpand] = useState(false);
  const [isHackwork, setIsHackwork] = useState(i.hackwork);

  const [certificateState, setCertificateState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [middlePrice, setMiddlePrice] = useState();
  const [middlePriceStatCount, setMiddlePriceStatCount] = useState();
  const [middlePriceLoading, setMiddlePriceLoading] = useState(false);
  const [middlePriceLawyer, setMiddlePriceLawyer] = useState();
  const [middlePriceLawyerStatCount, setMiddlePriceLawyerStatCount] = useState();
  const [historyAmount, setHistoryAmount] = useState(0);
  const [clientState, setClientState] = useState(null);

  const showConfirm = (typeOfAction) => {
    confirm({
      title: `Впевнені, що хочете ${typeOfAction ? 'відмінити' : 'запропонувати'} халтуру?`,
      icon: <ExclamationCircleFilled />,
      okText: 'Підтвердити',
      cancelText: 'Скасувати',
      onOk() {
        handleToggleHackwork();
      },
    });
  };

  const handleToggleHackwork = useCallback(() => {
    request.post(
      '/hotline/manageHackwork',
      { _id: i._id, isHackwork: !isHackwork },
      () => {
        info('', isHackwork ? 'Халтуру скасовано!' : 'Халтуру успішно запропоновано!');
        setIsHackwork(!isHackwork);
        return;
      },
      (req) => {
        if (req.error === 'hackwork is accepted') {
          warn('', 'Халтура вже кимось прийнята');
          return;
        }
        warn('', 'Щось пішло не так');
      },
    );
  }, [i._id, isHackwork]);

  const handleTogglePreappointedUser = useCallback(
    (value) => {
      const body = value ? { _id: i._id, bmm: value, fil: notFiredUsers.find((user) => user._id === value)?.fil } : { _id: i._id, bmm: undefined };
      request.post(
        '/hotline/setOrRemovePreappointedUser',
        body,
        () => {
          dispatch(editHotline(body));
          info('', users[value]?.name ? `${users[value].name} попередньо призначений` : `Попереднє призначення скасовано`);
        },
        () => {
          warn('', 'Щось пішло не так');
        },
      );
    },
    [i._id, i.bmm],
  );

  const expandCard = (e) => {
    if (e.target.classList.contains('ant-select-selection-search-input')) return;
    if (finishHotlineBtns) return;

    setExpand(true);
  };

  const statusIcon = { 1: <CheckOutlined />, 2: <CloseOutlined />, 3: <PhoneOutlined /> }[i.s] || <DiffOutlined />;

  const onClickContinueMeetingHandle = (event) => {
    event.stopPropagation();
    if (i.ic) {
      history.push(`${ROUTES.HOTLINE}/${i._id}`, { data: i });
    } else {
      history.push(ROUTES.HOTLINE_UPDATE, { data: i, updateClientHotline: true });
    }
  };

  const cancel = () => {
    info('', 'Ви не згодні зі своїм рішенням.');
  };

  const changeCertificate = async (record) => {
    const { phone, chn, certificateUsed, _id } = record;

    const validateCertificateUsage = () => {
      if (!phone) {
        warn('', 'Для використання сертифіката, повинен бути у клієнта номер телефону.', { phone });
        return false;
      }
      if (!chn) {
        warn('', 'Для використання сертифіката, повинні бути активні сертифікати.', { chn });
        return false;
      }
      if (certificateUsed) {
        warn('', `Сертифікат №${phone} вже використано`, { certificateUsed });
        return false;
      }
      return true;
    };

    if (!validateCertificateUsage()) return;

    const transaction = {
      phone,
      chn: !certificateState ? chn : certificateState,
      hotlineId: _id,
    };

    setLoading(true);

    await request.post(
      '/hotline/changeCertificate',
      transaction,
      (req) => {
        success('', `Сертифікат №${phone} було успішно використано.`);

        updateRecord({ ...record, certificateUsed: true });

        setCertificateState(req.chn);
        dispatch(setModal());
      },
      error,
    );

    setLoading(false);
  };

  const getMiddlePrice = () => {
    setMiddlePriceLoading(true);

    request.post(
      '/fee/getMiddlePrice',
      {
        fil: i.fil,
        pravoType: i.pravo,
      },
      (res) => {
        setMiddlePriceLoading(false);
        setMiddlePrice(res.data.middlePrice);
        setMiddlePriceStatCount(res.data.countPrice);
        setMiddlePriceLawyer(res.data.middlePriceLawyer);
        setMiddlePriceLawyerStatCount(res.data.countPriceLawyer);
      },
    );
  };

  const handleBlockDataModal = () => {
    const blockData = { userName: i.name, phoneNumber: i.phone };
    dispatch(setModal({ name: ADD_BLOCK, data: blockData }));
  };

  useEffect(() => {
    if (expand && !middlePrice) getMiddlePrice();
  }, [expand]);

  useEffect(() => {
    if (!expand) return;

    request.post(
      '/hotline/getAmountByPhone',
      { phone: i.phone },
      ({ amount }) => {
        setHistoryAmount(amount);
      },
      error,
    );
  }, []);

  let ribbonText;
  let ribbonColor;

  const userBalance = i.hotlineMatch?.clientBalance;
  const userBonus = i.hotlineMatch?.B;

  if (!i.cost) {
    ribbonText = i.hotlineMatch?.phoneVerified ? 'Безкоштовна' : 'Безкоштовно але не скачано';
    ribbonColor = 'blue';
  } else if (i.paymentByClubCard) {
    ribbonText = 'Оплата клубною картою';
    ribbonColor = 'green';
  } else if (i.transactions?.length) {
    ribbonColor = 'green';

    if (!i?.transactions[0]?.order_time) {
      ribbonText = 'Не оплачена';
      ribbonColor = 'red';
    } else {
      ribbonText = i.hotlineMatch?.phoneVerified ? 'Оплачено і скачано' : 'Оплачено і не скачано';
    }
  } else if (i.isPayedFromBalance) {
    ribbonText = 'Оплачено з балансу';
    ribbonColor = 'green';
  } else if (!i.transactions?.length) {
    ribbonText = 'Не оплачена';
    ribbonColor = 'red';
  }
  const currentPravo = pravoRatings.find((el) => el.pravo == i.pravo);

  const onSuccessHandler = () => {
    success('Статус клієнта успішно оновлено', '');
  };

  const onErrorHandler = () => {
    error('Статус клієнта не оновлено', '');
  };

  useEffect(() => {
    setClientState({ _id: i.hotlineMatch?._id, ph: i.phone, n: i.surname + ' ' + i.name, disabledPeople: i.hotlineMatch?.disabledPeople });
  }, [i]);

  const updateDisabledPeople = (event) => {
    if (i?.notUseApReason) return;
    new Promise((resolve, reject) => {
      setClientState((prev) => {
        const updatedClientState = { ...prev, disabledPeople: event.target.checked };
        resolve(updatedClientState);
        return updatedClientState;
      });
    }).then((updatedClientState) => {
      request.post('/clients/add', updatedClientState, onSuccessHandler, onErrorHandler);
    });
  };

  if (!expand)
    return (
      <Ribbon condition={true} text={ribbonText} color={ribbonColor}>
        <Card
          key={index} //
          data-id={i._id}
          className={clsx({ 'card hotline-list-item two_columns': true, disable: i.disabled, edited: i.e, vip: i.vip, ic: isIC, error: blocked })}
          style={i.e ? { backgroundColor: orange[2] } : i.disabled ? { backgroundColor: volcano[2] } : {}}
          onClick={expandCard}
        >
          <Spin tip="Завантаження" spinning={loading}>
            <div style={{ cursor: 'pointer' }} onClick={expandCard}>
              <div className="row">
                <span className="item-white-space">
                  <b>Філія:</b> {FILII[i.fil] ?? '-'}
                </span>
              </div>
              <div className="row">
                {resize[0] >= 500 ? (
                  <span>
                    <b>Послуга:</b>
                    {POSLUGI_NOMINATIVE_CASE[i.type] ?? '-'}
                  </span>
                ) : (
                  <span>
                    <b>Посл.:</b>
                    {POSLUGI_NOMINATIVE_CASE_MOBILE_SIZE[i.type] ?? '-'}
                  </span>
                )}
              </div>
              <div className="row">
                <b>Час:</b> {i.startT ?? '-'} - {i.endT ?? '-'}
              </div>
              <div className="row">
                {(i.byClient && !i.pravo) || i.ic ? (
                  <>
                    <b>Телефон:</b> {i.phone ?? '-'}
                  </>
                ) : (
                  <span className="item-white-space">
                    <b>Вид:</b> {PRAVO[i.pravo] ?? '-'}
                  </span>
                )}
              </div>

              {filClose && (
                <div className="row">
                  <h4>Філія закрита</h4>
                </div>
              )}
              {finishHotlineBtns && !i.e && !i.disabled && (
                <>
                  <Button className={'meet bigBtn update'} type="primary" onClick={onClickContinueMeetingHandle}>
                    <RedoOutlined /> Продовжити оформлення зустрічі
                  </Button>
                  <Button className={'meet bigBtn remove'} onClick={() => removeConfirm(i)}>
                    Скасувати зустріч
                  </Button>
                </>
              )}
              {createdFromCrm && (!i.bmm || isIC) && (
                <div className="row">
                  <SearchSelectUser
                    style={{ border: '4px solid purple' }}
                    i={i}
                    usersArr={notFiredUsers}
                    isIC={isIC}
                    handleTogglePreappointedUser={handleTogglePreappointedUser}
                  />
                </div>
              )}
              <span className="btns">
                {createdFromCrm && (
                  <Button className={'meet t' + String(i.s)} type="primary">
                    {statusIcon}
                  </Button>
                )}
                {createdFromCrm && <Timer time={i.crAt} />}

                {i.vip && (
                  <div>
                    <Button style={{ background: yellow[10], marginRight: '12px' }}>
                      <CrownOutlined style={{ color: 'gold' }} />
                    </Button>
                  </div>
                )}
              </span>
            </div>
          </Spin>
        </Card>
      </Ribbon>
    );

  return (
    <>
      <Card
        key={index} //
        className={clsx({
          'card hotline-list-item two_columns expand': true,
          disable: i.disabled,
          edited: i.e,
          vip: i.vip,
          ic: isIC,
          error: blocked,
        })}
        style={i.e ? { backgroundColor: orange[2] } : i.disabled ? { backgroundColor: volcano[2] } : {}}
      >
        <Spin size="large" tip="Завантаження" spinning={loading}>
          {i.vip && (
            <div className="row full vip_block">
              <span>
                VIP <CrownOutlined style={{ color: 'gold', width: '16px', marginLeft: 8 }} />
              </span>
            </div>
          )}
          {blocked && (
            <div className="block-wrapper">
              <Tag color="error">Користувач заблокований</Tag>
            </div>
          )}
          {i.vip && <div className="row full vip_block"></div>}
          <div className="row">
            <span className="item-white-space">
              <b>Філія:</b> {FILII[i.fil] ?? '-'}
            </span>
          </div>
          <div className="row">
            {resize[0] >= 500 ? (
              <span>
                <b>Послуга:</b>
                {POSLUGI_NOMINATIVE_CASE[i.type] ?? '-'}
              </span>
            ) : (
              <span>
                <b>Посл.:</b>
                {POSLUGI_NOMINATIVE_CASE_MOBILE_SIZE[i.type] ?? '-'}
              </span>
            )}
          </div>
          <div className="row">
            <b>Дата:</b> {i.date?.format('DD.MM.YYYY') ?? '-'}
          </div>
          <div className="row">
            <span className="item-white-space">
              <b>Ім'я:</b>
              {`${i.surname ? i.surname + ' ' : ''}${i.name ?? '-'}`}
            </span>
          </div>
          <div className="row">
            <b>Час:</b> {i.startT ?? '-'} - {i.endT ?? '-'}
          </div>
          <div className="row">
            <b>{resize[0] >= 500 ? 'Телефон:' : 'Тел.:'}</b> +{i?.countryPhCode} {i.phone ?? '-'}
          </div>
          <div className="row">
            <span className="item-white-space">
              <b>Вид:</b> {PRAVO[i.pravo] ?? '-'}
            </span>
          </div>

          <div className="row">
            <b>Сума:</b>
            {!i.hotlineMatch?.phoneVerified ? (
              <>
                <span style={{ textDecoration: 'line-through', marginRight: 8, color: 'red' }}>
                  {i.currency ? CURRENCIES[i.currency].symbol || '₴' : '₴'}
                  {i.cost}
                </span>{' '}
                {i.currency ? CURRENCIES[i.currency].symbol || '₴' : '₴'}
                {i.costNoApp}
              </>
            ) : (
              <>
                {i.currency ? CURRENCIES[i.currency].symbol || '₴' : '₴'}
                {i.cost ?? '-'}
              </>
            )}
          </div>

          <CheckClientWallet userBalance={userBalance} userBonus={userBonus} />

          <div className="container2">
            <div className="row restyling" style={{ alignItems: 'center' }}>
              <b>Дата оплати:</b>
              {i.transactions?.[0]?.order_time ? dayjs(i.transactions[0].order_time).format('DD.MM.YYYY HH:mm:ss') : '-'}
              &nbsp; &nbsp;
              {i.paymentUrl && (
                <Button size="small" type="primary" as="a" target="_blank" rel="noopener noreferrer" href={i.paymentUrl} children="&nbsp;₴&nbsp;" />
              )}
              &nbsp; &nbsp;
              <Button
                size="small"
                type="primary"
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href={'http://client.zahist.ua/' + i._id}
                children="&nbsp;≫&nbsp;"
              />
            </div>

            <div className="row comment">
              <span className="item-white-space restyling">
                <b>Коментар:</b> {i.comment ?? '-'}
              </span>
            </div>
            <div className="row">
              <span className="item-white-space restyling" style={{ display: 'flex' }}>
                <b>єАдвокат:</b> {i.hotlineMatch?.phoneVerified ? <img src="https://client.zahist.ua/logo.svg" alt="ea" width={20} /> : '-'}
              </span>
            </div>
            <div className="row">
              <Checkbox checked={clientState?.disabledPeople} onChange={updateDisabledPeople} disabled={true} />
              <UsbOutlined style={{ marginLeft: 8 }} />
              <Text style={{ marginLeft: 8 }}>Не може користуватися єАдвокат</Text>
            </div>

            <div className="row full">
              <b>Аудіозапис дзвінка:</b> {!i?.audioFile?.length && 'Відсутній'}
            </div>
            {i?.audioFile?.length > 0 && (
              <div className="row full" style={{ width: '100%' }}>
                <audio controls preload="none" style={{ width: '100%' }}>
                  <source src={i.audioFile?.[0]?.audio_record_link} type="audio/mpeg" />
                  Браузер не підтримує відтворення аудіозапису
                </audio>
              </div>
            )}

            <div className="row full">&nbsp;</div>
          </div>

          <Divider className="divider-hotline-item-info" />
          <div className="container">
            {/* <div className="row"></div> */}

            <div className="row">
              <b>Автор:</b> {(window.users && window.users[i.author]?.p) ?? '-'}
            </div>
            <div className="row">
              <b>Створено:</b> {i.crAt?.format('DD.MM.YYYY HH:mm:ss') ?? '-'}
            </div>
          </div>
          {konsultation ? (
            <div className="row">
              <b>{resize[0] >= 500 ? 'Призначено:' : 'При-но:'}</b> {(users && users[i.amm]?.p) ?? '-'}
            </div>
          ) : (
            <div className="row"></div>
          )}
          {konsultation ? (
            <div className="row">
              <b>Запр{resize[0] >= 500 ? 'опонована' : '.'} сума:</b> {i.amp ?? '-'}
            </div>
          ) : (
            <div className="row"></div>
          )}
          <div className="row">
            <span className="item-white-space tag">
              <b>{resize[0] >= 500 ? 'Результат зустрічі:' : 'Рез. зустр.:'}</b>
              <Tag
                key={users._id}
                color={
                  HOTLINE_RESULT[2] === HOTLINE_RESULT[i.amr] || (HOTLINE_MEET[i.s] === HOTLINE_MEET[1] && !konsultation)
                    ? 'green'
                    : HOTLINE_RESULT[1] === HOTLINE_RESULT[i.amr]
                    ? 'yellow'
                    : 'red'
                }
              >
                {HOTLINE_MEET[i.s] === HOTLINE_MEET[3] ? HOTLINE_MEET[i.s] : konsultation ? HOTLINE_RESULT[i.amr] ?? '-' : HOTLINE_MEET[i.s]}
              </Tag>
            </span>
          </div>

          <div className="row">
            <span className="item-white-space tag">
              <b>{resize[0] >= 500 ? 'Оцінка якості:' : 'Оц. якості:'}</b>
              <Tag key={users._id} color={i.qh?.qc >= 7 ? 'green' : i.qh?.qc >= 3 ? 'yellow' : 'red'}>
                {i.qh?.qc >= 0 ? i.qh.qc : '-'}
              </Tag>
            </span>
          </div>

          <div className="row"></div>

          {i.promocode ? (
            <div className="row">
              <Tag color="green" style={{ fontSize: '14px' }}>
                <b>{promocodes.find((promo) => promo._id === i.promocode)?.promocode}:</b>
                знижка {promocodes.find((promo) => promo._id === i.promocode)?.discount}%
              </Tag>
            </div>
          ) : null}

          <div className="row">
            <span className="item-white-space">
              <b>Сертифікат:</b> {i.certText ?? '-'}
            </span>
          </div>

          <div className="row">
            <b>{resize[0] >= 500 ? 'Прийнято, як халтуру:' : 'Халтура'}</b> {i.isAcceptedHackwork ? 'так' : '-'}
          </div>

          <div className="row">
            <b>№: </b> {i.i}
          </div>

          <div className="row-full"></div>

          {!isInJournal && (
            <div className="row full controls">
              {i.disabled && <div style={{ color: 'red' }}> {i.e ? 'Відредаговано та в' : 'В'}идалено</div>}
              {!i.disabled && i.rrh?.rsh === true && <div style={{ color: 'red' }}> Попав в дожим</div>}
              <div>
                <SearchSelectUser i={i} usersArr={notFiredUsers} isIC={isIC} handleTogglePreappointedUser={handleTogglePreappointedUser} />
              </div>
              <Box>
                <Text>
                  Середня ціна послуги:
                  {middlePriceLoading ? <Spin /> : <>{middlePrice ? <Text strong>&nbsp;{middlePrice} грн.</Text> : ' ще не визначена'}</>}
                  {inspector && <Text> - {middlePriceStatCount} шт(ДСК)</Text>}
                </Text>
              </Box>
              <Box>
                <Text>
                  Середня ціна виходу адвоката:
                  {middlePriceLoading ? <Spin /> : <>{middlePriceLawyer ? <Text strong> {middlePriceLawyer} грн.</Text> : ' ще не визначена'}</>}
                  {inspector && <Text> - {middlePriceLawyerStatCount} шт(ДСК)</Text>}
                </Text>
              </Box>

              <Card title={`Кращі за напрямком "${PRAVO[i?.pravo]}":`}>
                {i?.topWorkers && (
                  <Timeline style={{ marginTop: '20px' }}>
                    {i?.topWorkers[0]
                      ?.filter?.((item) => {
                        return !users[item?.userId]?.fired && item.contractsCount > 0;
                      })
                      .map((item = {}, index) => (
                        <Timeline.Item key={index} color={index === 0 ? 'green' : 'gray'}>
                          {users[item?.userId]?.name} ({users[item?.userId]?.p})
                          {inspector && (
                            <Text>
                              - {item.contractsCount} / {item.hotlinesCount}
                            </Text>
                          )}
                        </Timeline.Item>
                      ))}
                  </Timeline>
                )}
              </Card>

              <Box mt={12} className="group-button">
                {!i.s && !isInJournal && (
                  <Button
                    disabled={rabfilOnly && !i.bmm}
                    className="btn-list"
                    type="primary"
                    onClick={() =>
                      konsultation || isIC
                        ? dispatch(setModal({ name: HOTLINE_QUE, data: getSerializbleItemData(i) }))
                        : dispatch(setModal({ name: HOTLINE_MEET_MODAL, data: getSerializbleItemData(i) }))
                    }
                  >
                    Звітувати про зустріч
                  </Button>
                )}
                {!i.s && !isInJournal && (
                  <Button
                    className="btn-list"
                    style={isHackwork ? {} : { backgroundColor: lime.primary, borderColor: lime.primary }}
                    type="primary"
                    disabled={!konsultation || !manager || !!i.isAcceptedHackwork}
                    danger
                    onClick={() => showConfirm(isHackwork)}
                  >
                    {isHackwork ? 'Відмінити халтуру' : 'Запропонувати халтуру'}
                  </Button>
                )}
              </Box>
              <Box mt={12} className="group-button">
                {konsultation && worker && !isInJournal && (
                  <Button
                    className="btn-list"
                    type="primary"
                    disabled={i.s !== '1' || i.K || i.rrh?.rsh === true}
                    onClick={() => dispatch(setModal({ name: NEW_CONTRACT, data: { hotline: i }, hotline: i }))}
                  >
                    {i.K && <CheckOutlined />} Додати договір
                  </Button>
                )}
                <Button onClick={() => dispatch(setModal({ name: HISTORY_HOTLINES, data: { phone: i.phone } }))} className="btn-list">
                  Історія ({historyAmount})
                </Button>
              </Box>
              <Box mt={12} className="group-button">
                {konsultation && manager && !isInJournal && <Block5daysProject3000 data={i} setLoading={(item) => setLoading(item)} />}
                <Button
                  type="primary"
                  className="btn-list"
                  disabled={rabfilOnly}
                  onClick={() =>
                    dispatch(
                      setModal({
                        name: CHAT_FOR_HOTLINE,
                        data: {
                          hotlineId: i._id,
                          hotlineTitle: 'Чат по запису за тел. ' + (i.phone ?? '-') + ' на ' + (i.date?.format('DD.MM.YYYY') ?? '-'),
                        },
                      }),
                    )
                  }
                  icon={<TabletFilled />}
                >
                  Чат
                </Button>
              </Box>
              <Box mt={12} className="group-button">
                <Popconfirm
                  title="Підтвердження"
                  description="Ви впевнені, що хочете використати сертифікат?"
                  onConfirm={() => changeCertificate(i)}
                  onCancel={cancel}
                  okText="Гаразд"
                  cancelText="Скасувати"
                  disabled={!i.chn || certificateState === 0 || i.certificateUsed}
                >
                  <Button className="btn-list" type="primary" disabled={!i.chn || certificateState === 0 || i.certificateUsed}>
                    {i.certificateUsed
                      ? 'Сертифікат вже використано'
                      : `Використати сертифікат ( ${!i.chn ? 0 : certificateState === false ? i.chn : certificateState}/3 )`}
                  </Button>
                </Popconfirm>

                <Button
                  onClick={() => dispatch(setModal({ name: LIBRARY_MINI_LIST, data: { shoo: i.pravo } }))}
                  className="btn-list"
                  type="primary"
                  disabled={rabfilOnly}
                  icon={<ReadOutlined />}
                >
                  Міні бібліотека
                </Button>
              </Box>
              <Box mt={12}></Box>
              <BlockBtn blocked={blocked} modal={handleBlockDataModal} updateRecord={updateRecord} phone={i.phone} data={i} />
            </div>
          )}

          <div className="btns">
            <Button className="expand" onClick={() => setExpand(false)}>
              <UpOutlined />
            </Button>
            <Button
              disabled={rabfilOnly || i?.s}
              className={'meet t' + String(i.s)}
              type="primary"
              onClick={() =>
                konsultation || isIC
                  ? dispatch(setModal({ name: HOTLINE_QUE, data: getSerializbleItemData(i) }))
                  : dispatch(setModal({ name: HOTLINE_MEET_MODAL, data: getSerializbleItemData(i) }))
              }
            >
              {statusIcon}
            </Button>
            <>
              <Button disabled={rabfilOnly} className="edit" type="primary" onClick={() => onEditClick(i)}>
                <EditFilled />
              </Button>
              <Button disabled={rabfilOnly} className="remove" type="primary" onClick={() => removeConfirm(i)}>
                <DeleteFilled />
              </Button>
            </>
          </div>
        </Spin>
      </Card>
      {isInJournal && meetReport && <HotlineMeetDetails hotline={i} />}
      {isInJournal && changeContract && <HotlineContractDetails contract={contract} client={client} />}
      {isInJournal && <HotlineRequestBody requestBody={requestBody} />}
    </>
  );
};

export default React.memo(Item);
