import React from 'react';
import SearchSelect from './SearchSelect';

const approvedOptions = [
  { value: 'all', label: 'Всі' },
  { value: 'approved', label: 'Перевірені' },
  { value: 'notApproved', label: 'Не перевірені' },
];

const SearchSelectApproved = ({ onChange, value, className, style }) => {
  return (
    <SearchSelect
      className={className}
      style={style}
      placeholder="Оберіть статус"
      list={approvedOptions}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectApproved;
