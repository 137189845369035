import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { error, request } from '../../tools';
import { setQuickChats, setModal } from '../../store/commonReducer';
import { Empty, Pagination, Spin, Typography, Button } from 'antd';
import ClientsQuickChatItem from './ClientsQuickChatItem';
import Excel from '../../images/excel_logo.svg';
import { EXPORT_QUICK_CHATS } from 'common_constants/modals';


const DEFAULT_PAGE_SIZE = 10;

const ClientsQuickChats = () => {
  const dispatch = useDispatch();
  const quickChats = useSelector((state) => state.common.quickChats || []);

  const { Text } = Typography;

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [loading, setLoading] = useState(false);

  const getAllQuickChats = (limits) => {
    setLoading(true);

    const params = { ...limits };

    request.post(
      '/quickChat/getQuickChats',
      params,
      ({ quickChats, pageSize, total }) => {
        dispatch(setQuickChats(quickChats));
        setPageSize(pageSize);
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    const limits = {
      page: currentPage,
      pageSize,
    };
    getAllQuickChats(limits);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllQuickChats({ page, pageSize });
  };

  return (
    <div className="clients_page default_page">
    <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_QUICK_CHATS }))} style={{display: 'flex', gap: '6px'}}>
          <img src={Excel} alt="Excel" width={24} /> Експорт швидких питань
        </Button>
      <h2>Швидкі питання клієнтів</h2>
      <br />

      <Spin spinning={loading} tip="Зачекайте" size="large">
        {quickChats?.length ? (
          quickChats?.map((item) => <ClientsQuickChatItem key={item._id} data={item} />)
        ) : (
          <Empty description={<Text>Немає даних</Text>} />
        )}
        <br />
        <Pagination style={{ textAlign: 'center' }} current={currentPage} pageSize={DEFAULT_PAGE_SIZE} total={total} onChange={handlePageChange} />
      </Spin>
    </div>
  );
};

export default ClientsQuickChats;
