import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Typography, Empty, List, Spin } from 'antd';

import { setModal, setTemplates } from '../../store/commonReducer';
import { request, error } from '../../tools';
import LibraryItem from '../../components/LibraryItem/LibraryItem';

import './styles.scss';

const { Text } = Typography;

const DocumentTemplates = () => {
  const dispatch = useDispatch();

  const currentTemplates = useSelector((state) => state.common.templates);
  const [isLoading, setIsLoading] = useState(false);

  const getTemplates = () => {
    setIsLoading(true);
    request.post(
      '/library/getTemplates',
      null,
      (data) => {
        dispatch(setTemplates(data?.library));
        setIsLoading(false);
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const onCancel = () => {
    dispatch(setModal());
  };

  return (
    <Modal open className="document-templates" title={'Шаблони документів'} onCancel={onCancel} footer={null}>
      <Spin spinning={isLoading}>
        {currentTemplates.length ? (
          <List
            className={'library-page__list-main'}
            pagination={{
              hideOnSinglePage: true,
              position: 'both',
              align: 'end',
              defaultCurrent: 1,
              total: currentTemplates.length ?? 1,
              defaultPageSize: 20,
              showTotal: (total, range) => `${range[0]}-${range[1]} з ${total} файлів`,
            }}
            dataSource={currentTemplates ?? []}
            renderItem={(item) => <LibraryItem item={item} key={item._id} />}
          ></List>
        ) : (
          <Empty description={<Text>Нема файлів</Text>} />
        )}
      </Spin>
    </Modal>
  );
};

export default DocumentTemplates;
