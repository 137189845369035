import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Spin, Popover, Col, Row } from 'antd';
import { CONFLICT_STATUS } from 'common_constants/business';

import { setConflicts } from '../../store/commonReducer';
import { request, error } from '../../tools';
import HoverableUser from '../../components/HoverableUser';

import './Conflicts.scss';

const Conflicts = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const conflicts = useSelector((state) => state.common.conflicts);

  const [isLoading, setIsLoading] = useState(false);

  const getConflicts = () => {
    setIsLoading(true);

    request.post(
      '/conflicts/getConflicts',
      null,
      (req) => {
        dispatch(setConflicts(req.data));
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        error();
      },
    );
  };

  useEffect(() => {
    getConflicts();
  }, []);

  return (
    <section className="conflicts_page default_page">
      <h2>Конфлікти</h2>

      <Spin spinning={isLoading} size="large">
        <Row gutter={[15, 15]}>
          {conflicts?.map((item) => (
            <Col xs={12} sm={8} lg={8} xl={6} key={item._id}>
              <Card
                title="Внутрішній конфлікт"
                className={`conflict-card ${
                  item.status === CONFLICT_STATUS.active ? 'active' : item.status === CONFLICT_STATUS.notPaid ? 'notPaid' : 'completed'
                }`}
              >
                <p>
                  Перша сторона:{' '}
                  <Popover content={<HoverableUser id={item.firstSide} />} trigger="hover">
                    {users[item.firstSide].p}
                  </Popover>
                </p>
                <p>
                  Дрга сторона:{' '}
                  <Popover content={<HoverableUser id={item.secondSide} />} trigger="hover">
                    {users[item.secondSide].p}
                  </Popover>
                </p>
                <p>
                  Суддя:{' '}
                  {item?.judge ? (
                    <Popover content={<HoverableUser id={item.judge} />} trigger="hover">
                      {users[item.judge].p}
                    </Popover>
                  ) : (
                    '-'
                  )}
                </p>
                <Link key={item._id} to={`/conflicts/${item._id}`}>
                  <Button type="link">Переглянути</Button>
                </Link>
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
    </section>
  );
};

export default Conflicts;
