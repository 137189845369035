import React from 'react';
import { useSelector } from 'react-redux';
import { Tag } from 'antd';
import { RESPONSIBILITY_TEAMS } from 'common_constants/business';

const User = ({ user }) => (
  <Tag key={user._id} color={user.activity?.value === 1 ? 'green' : 'red'}>
    {user.p}
  </Tag>
);

const Responsibilities = () => {
  const FILII = useSelector((state) => state.common.FILII);
  const _users = useSelector((state) => state.common.users);
  const users = Object.values(_users).filter((user) => !user.fired);

  return (
    <>
      {RESPONSIBILITY_TEAMS.map((team) => (
        <React.Fragment key={team.index}>
          <h4>{team.label}</h4>
          {team.fils.map((i) => (
            <Tag key={i}>{FILII[i]}</Tag>
          ))}
          <br />
          <br />
          <div className="responsibilities">
            {users
              .filter((user) => team.fils.includes(user.fil))
              .map((user) => (
                <User key={user._id} user={user} />
              ))}
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default React.memo(Responsibilities);
