import React from 'react';
import { Row, Tabs, Divider, Button } from 'antd';
import { SyncOutlined, CalendarOutlined } from '@ant-design/icons';
import { Expenses, Sugar } from '../../Partials';

const { TabPane } = Tabs;

const PersonalBalance = ({
  handleClickMonthlyBalans,
  sugar,
  expenses,
  currency,
  sugarPageSize,
  expensesPageSize,
  loading,
  personalTotalBalance,
  handleUpdatePersonalTotalBalance,
  personalState,
  handleSetSugarApproved,
  handleSetExpenseApproved,
  onChangePersonalSugarPage,
  onChangePersonalSugarPageSize,
  handlePersonalSugarDateChange,
  handlePersonalExpensesDateChange,
  onChangePersonalExpensesPage,
  onChangePersonalExpensesPageSize,
}) => (
  <Row>
    <Divider style={{ margin: '16px 0 16px 0' }} orientation="left">
      {personalTotalBalance} грн.
      <Button loading={loading} style={{ marginLeft: '1em' }} icon={<SyncOutlined />} type="primary" onClick={handleUpdatePersonalTotalBalance} />
      <Button
        onClick={() => handleClickMonthlyBalans(sugar, expenses)}
        style={{ marginLeft: '1em' }}
        type="primary"
        icon={<CalendarOutlined />}
      ></Button>
    </Divider>
    <Tabs className="tabs" defaultActiveKey="1" type="card">
      <TabPane tab="Доходи" key="1">
        <Sugar
          type={'personal'}
          state={personalState}
          currency={currency}
          pageSize={sugarPageSize}
          onChangePage={onChangePersonalSugarPage}
          handleDateChange={handlePersonalSugarDateChange}
          handleSetApproved={handleSetSugarApproved}
          onChangePageSize={onChangePersonalSugarPageSize}
        />
      </TabPane>
      <TabPane tab="Витрати" key="2">
        <Expenses
          type={'personal'}
          currency={currency}
          state={personalState}
          pageSize={expensesPageSize}
          handleDateChange={handlePersonalExpensesDateChange}
          onChangePage={onChangePersonalExpensesPage}
          handleSetApproved={handleSetExpenseApproved}
          onChangePageSize={onChangePersonalExpensesPageSize}
        />
      </TabPane>
    </Tabs>
  </Row>
);

export default React.memo(PersonalBalance);
