import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';
import dayjs from 'dayjs';

const PushNotificationInfo = ({ data }) => {
  const FILII = useSelector((state) => state.common.FILII);

  if (!data) {
    return 'Відсутня інформація';
  }

  return (
    <Card className="harvestInfo">
      <span>
        <b>Філія:</b> {FILII[data.fil]}
      </span>
      <span>
        <b>Отримувач: </b>
        {data.name} &nbsp;
      </span>
      {data.phone && (
        <span>
          <b>Телефон: </b>
          {data.phone} &nbsp;
        </span>
      )}
      {data.contract && (
        <span>
          <b>№ договору:</b> {data.contract}
        </span>
      )}
      <span>
        <b>Заголовок:</b> {data.title}
      </span>
      <span>
        <b>Текст повідомлення:</b> {data.text}
      </span>
      <span>
        <b>Тип:</b> {data.type}
      </span>

      <span>
        <b>Дата відправки:</b> {dayjs(data.createdAt).format('DD.MM.YYYY HH:mm:ss')}
      </span>
    </Card>
  );
};

export default PushNotificationInfo;
