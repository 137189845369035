import React, { useEffect, useState } from 'react';

import { request, error } from '../../tools';
import BarChart from '../../components/BarChart';

import './styles.scss';

const FilledContractPM = () => {
  const [contracts, setContracts] = useState([]);

  const getAllContracts = () => {
    request.post(
      '/contracts/statisticFilledPM',
      {},
      ({ data }) => {
        setContracts(data);
      },
      error,
    );
  };

  useEffect(() => {
    getAllContracts();
  }, []);

  const data = contracts
    .map((elem) => {
      const allContracts = elem?.contracts?.length ?? 0;
      const contractsToFil = elem?.contracts?.filter((item) => item.editPMonceAccess)?.length ?? 0;
      const filledContracts = allContracts - contractsToFil;
      const contractsToFilPercent = ((contractsToFil / allContracts) * 100).toFixed(1);
      const filledContractsPercent = ((filledContracts / allContracts) * 100).toFixed(1);

      const fil = elem?.name;

      return {
        fil,
        contractsToFil,
        contractsToFilPercent,
        filledContracts,
        filledContractsPercent,
      };
    })
    .sort((a, b) => a.filledContractsPercent - b.filledContractsPercent);

  const totalContractsToFil = data.reduce((sum, item) => sum + item.contractsToFil, 0);
  const totalFilledContracts = data.reduce((sum, item) => sum + item.filledContracts, 0);
  const totalAllContracts = totalContractsToFil + totalFilledContracts;
  const totalContractsToFilPercent = ((totalContractsToFil / totalAllContracts) * 100).toFixed(1);
  const totalFilledContractsPercent = ((totalFilledContracts / totalAllContracts) * 100).toFixed(1);

  data.push({
    fil: 'Загальне значення',
    contractsToFil: totalContractsToFil,
    contractsToFilPercent: totalContractsToFilPercent,
    filledContracts: totalFilledContracts,
    filledContractsPercent: totalFilledContractsPercent,
  });

  return (
    <section className="function-survey_page default_page">
      <div style={{ height: '100%' }}>
        <BarChart data={data} />
      </div>
    </section>
  );
};

export default FilledContractPM;
