import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Popconfirm } from 'antd';
import { EditOutlined, EnvironmentOutlined, DeleteFilled, YoutubeOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { UKRAINE_STATES } from 'common_constants/business';

const FilItem = ({ filii, fil = {}, deleteFil, editable, editFil, deleteFilImage }) => {
  const users = useSelector((state) => state.common.users || {});

  const onClickEditButtonHandle = () => {
    editFil(fil);
  };

  const onClickDeleteButtonHandle = async () => {
    await deleteFilImage({ id: fil._id });
    await deleteFil(fil._id);
  };

  return (
    <Card
      className="fil-item"
      actions={
        editable && [
          <EditOutlined key="edit" onClick={onClickEditButtonHandle} />,
          <Popconfirm
            title="Видалення філії"
            description="Ви дійсно хочете видалити дану філію?"
            onConfirm={onClickDeleteButtonHandle}
            okText="Так"
            cancelText="Ні"
          >
            <DeleteFilled key="delete" />
          </Popconfirm>,
        ]
      }
    >
      <Card.Meta title={'№' + fil.i + ' ' + fil.name} description={fil.address} />
      {fil.isDisabled && (
        <div className="fil-item__disabled">
          <CloseSquareOutlined style={{ color: 'red' }} />
          Відключено
        </div>
      )}
      {fil.address_description !== '' && fil.address_description !== undefined && (
        <p>
          <span>Детальний опис місця розташування:</span> {fil.address_description}
        </p>
      )}
      <p>
        <span>Зона поширення:</span> {fil.zone}
      </p>
      {fil.video && fil.video.trim() !== '' && (
        <div className="fil-item__detail">
          <div>
            <YoutubeOutlined />
          </div>
          <a href={fil.video}>{fil.video}</a>
        </div>
      )}
      {fil.map && fil.map.trim() !== '' && (
        <div className="fil-item__detail">
          <div>
            <EnvironmentOutlined />
          </div>
          <a href={fil.map}>{fil.map}</a>
        </div>
      )}
      <div>
        <span>Менеджер філії:</span> {users[fil.managerId]?.name}
      </div>
      <div>
        <span>Є округом:</span> {fil.isDistrict ? 'Так' : 'Ні'}
      </div>
      <div>
        <span>Області:</span> {fil.states?.map((i) => UKRAINE_STATES[i]).join(', ')}
      </div>
      {fil.district !== undefined && fil.district !== null && (
        <div>
          <span>Входить в округ: </span>
          {filii.filter((item) => item.i === fil.district).map((item) => item.name)}
        </div>
      )}
    </Card>
  );
};

export default FilItem;
