import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Typography, Tabs, Collapse, Button, Alert, Popconfirm, Switch, Spin } from 'antd';
import { TeamOutlined, MergeCellsOutlined, SyncOutlined, WarningOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import RatingList from './RatingPartials/RatingList';
import RatingCard from './RatingPartials/RatingCard/RatingCard';
import { request, success } from '../../tools/index';
import { setMainLoader } from '../../store/uiReducer';
import {
  setContractsRating,
  setReachesRating,
  setAutoRotateReachesState,
  setCurrentMonthContractsRating,
  setCurrentMonthReachesRating,
} from '../../store/commonReducer';
import { ROLES } from 'common_constants/business/index';

import './Rating.scss';

const { Title } = Typography;
const { Panel } = Collapse;

const Rating = () => {
  const dispatch = useDispatch();
  const contractsRating = useSelector((state) => state.common.contractsRating);
  const currentMonthContractsRating = useSelector((state) => state.common.currentMonthContractsRating);
  const reachesRating = useSelector((state) => state.common.reachesRating);
  const currentMonthReachesRating = useSelector((state) => state.common.currentMonthReachesRating);
  const autoRotateReachesState = useSelector((state) => state.common.autoRotateReachesState);
  const [isExpanded, setIsExpanded] = useState(false);
  const [autoRotateReachesLoadingState, setAutoRotateReachesLoadingState] = useState(false);
  const userAuth = useSelector((state) => state.common.userAuth);
  const admin = ROLES[userAuth.role] === ROLES.admin;
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const [isPersonalRatingLoading, setIsPersonalRatingLoading] = useState(false);
  const currentUserContractsRating = currentMonthContractsRating?.find((ratingItem) => ratingItem.userId === userAuth._id);
  const currentUserReachesRating = currentMonthReachesRating?.find((ratingItem) => ratingItem.userId === userAuth._id);

  const handleCollapseChange = () => setIsExpanded(!isExpanded);

  const getRating = () => {
    setIsPersonalRatingLoading(true);

    request.post(
      '/rating/get',
      {},
      ({ contractsRating, reachesRating, autoRotateReachesState, currentMonthContractsRating, currentMonthReachesRating }) => {
        dispatch(setContractsRating(contractsRating));
        dispatch(setCurrentMonthContractsRating(currentMonthContractsRating));
        dispatch(setReachesRating(reachesRating));
        dispatch(setCurrentMonthReachesRating(currentMonthReachesRating));
        dispatch(setAutoRotateReachesState(autoRotateReachesState));

        setIsPersonalRatingLoading(false);
      },
    );
  };

  const handleCalcContractsRating = () => {
    request.post('/rating/calcContractsRating', {}, (res) => {
      getRating();
    });
  };

  const handleCalcReachesRating = () => {
    request.post('/rating/calcReachesRating', {}, (res) => {
      getRating();
    });
  };

  const handleRotateReaches = () => {
    dispatch(setMainLoader(true));
    request.post('/rating/rotateReaches', {}, (res) => {
      success('', 'Здійснено перекидку дожимів, згідно рейтингу.');
      dispatch(setMainLoader(false));
    });
  };

  const handleToggleAutoReachesRotate = (value) => {
    setAutoRotateReachesLoadingState(true);
    request.post(
      '/rating/toggleAutorotateReaches',
      { value },
      ({ autoRotateReachesState }) => {
        dispatch(setAutoRotateReachesState(autoRotateReachesState));
        setAutoRotateReachesLoadingState(false);
      },
      () => {
        setAutoRotateReachesLoadingState(false);
      },
    );
  };

  const startForContracts = dayjs().subtract(3, 'month').format('MM');
  const endForContracts = dayjs().subtract(1, 'month').format('MM');
  const monthForReaches = dayjs().subtract(2, 'month').format('MM');

  const items = [
    {
      key: '1',
      label: (
        <span>
          <TeamOutlined />
          {`Договори (${dayjs().format('DD.MM.YYYY')})`}
        </span>
      ),
      children: <RatingList data={currentMonthContractsRating ?? []} />,
    },
    {
      key: '2',
      label: (
        <span>
          <MergeCellsOutlined />
          {`Дожими (${dayjs().format('DD.MM.YYYY')})`}
        </span>
      ),
      children: <RatingList data={currentMonthReachesRating ?? []} />,
    },
  ];

  if (inspector)
    items.push(
      {
        key: '3',
        label: (
          <span>
            <TeamOutlined />
            {`Договори (${startForContracts} - ${endForContracts})`}
          </span>
        ),
        children: <RatingList data={contractsRating ?? []} />,
      },
      {
        key: '4',
        label: (
          <span>
            <MergeCellsOutlined />
            {`Дожими (${monthForReaches})`}
          </span>
        ),
        children: <RatingList data={reachesRating ?? []} />,
      },
    );

  useEffect(() => {
    if (!contractsRating && !reachesRating) {
      getRating();
    }
  }, []);

  return (
    <Col style={{ padding: 10, maxWidth: '600px', margin: '0 auto' }}>
      <Title style={{ textAlign: 'center' }} level={3}>
        Рейтинг
      </Title>
      {isPersonalRatingLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row className="rating-cards">
          <RatingCard type={'contracts'} data={currentUserContractsRating} />
          <RatingCard type={'hotlineReach'} data={currentUserReachesRating} />
        </Row>
      )}
      {admin && (
        <Collapse className="rating-filter-colapse" in={isExpanded} onChange={handleCollapseChange}>
          <Panel header="Ручний розрахунок" key={'search'}>
            <Row justify={'center'}>
              <Popconfirm title="Запустити розрахунок рейтингу договорів?" onConfirm={handleCalcContractsRating} okText="Так" cancelText="Ні">
                <Button icon={<TeamOutlined />} className="rating-filter__button" type="primary">
                  Рейтинг договорів
                </Button>
              </Popconfirm>

              <Popconfirm title="Запустити розрахунок рейтингу дожимів?" onConfirm={handleCalcReachesRating} okText="Так" cancelText="Ні">
                <Button icon={<MergeCellsOutlined />} className="rating-filter__button" type="primary">
                  Рейтинг дожимів
                </Button>
              </Popconfirm>

              <Popconfirm
                title="Будь ласка, зверніть увагу"
                description="Перекидка дожимів відбувається згідно розрахованого рейтингу. Повернуті вручну, будуть знову переназначені"
                okText="Запуск"
                cancelText="Відміна"
                onConfirm={handleRotateReaches}
                icon={
                  <WarningOutlined
                    style={{
                      color: 'red',
                    }}
                  />
                }
              >
                <Button icon={<SyncOutlined />} className="rating-filter__button" type="primary">
                  Перекидка дожимів
                </Button>
              </Popconfirm>
            </Row>

            <Alert
              message="Будь ласка, зверніть увагу"
              description="Функція перекидки дожимів працює згідно рейтингу. Тобто, якщо після першої перекидки, якісь дожими були повернуті назад, через 'Дожими', то вони знову призначаться згідно рейтингу."
              type="warning"
              showIcon
            />
            <Row justify={'space-between'} style={{ margin: '14px 0' }}>
              <Row style={{ fontWeight: 'bold' }}>Автоматична перекидка дожимів</Row>
              <Switch
                loading={autoRotateReachesLoadingState}
                onChange={handleToggleAutoReachesRotate}
                checked={autoRotateReachesState}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Row>
          </Panel>
        </Collapse>
      )}
      <Tabs defaultActiveKey="1" items={items} />
    </Col>
  );
};

export default Rating;
