import { billSelectGeneralOfferedAmount, SelectGeneralOfferedAmount } from './SelectGeneralOfferedAmount'; //? Запропоновану суму
import { billSelectGeneralHellishnessReach, SelectGeneralHellishnessReach } from './SelectGeneralHellishnessReach'; //? Пекельність дожиму
import { billSelectGeneralTypeReach, SelectGeneralTypeReach } from './SelectGeneralTypeReach'; //? Тип дожиму
import { billSelectGeneralServesTheBlock, SelectGeneralServesTheBlock } from './SelectGeneralServesTheBlock'; //? Обслуговує блок
import { billSelectGeneralAssignedBlock, SelectGeneralAssignedBlock } from './SelectGeneralAssignedBlock'; //? Призначений блок
import { billSelectGeneralOfficeOrRemote, SelectGeneralOfficeOrRemote } from './SelectGeneralOfficeOrRemote'; //? Офіс/дист
import { billSelectGeneralHotlineAdvWhere, SelectGeneralHotlineAdvWhere } from './SelectGeneralHotlineAdvWhere'; //? Як дізнався / вид р
import { billSelectGeneralHotlineAdvWhereMore, SelectGeneralHotlineAdvWhereMore } from './SelectGeneralHotlineAdvWhereMore'; //? Що саме
import { billSelectGeneralKindOfLegalRelationship, SelectGeneralKindOfLegalRelationship } from './SelectGeneralKindOfLegalRelationship'; //? Вид правовідносин
import { billSelectGeneralTypeOfLegalRelationship, SelectGeneralTypeOfLegalRelationship } from './SelectGeneralTypeOfLegalRelationship'; //? Вид правовідносин (Що саме?)
import { billSelectGeneralTypeOfArchiving, SelectGeneralTypeOfArchiving } from './SelectGeneralTypeOfArchiving'; //? Тип архівації
import { SelectGeneralFil } from './SelectGeneralFil'; //? Філія
import { billSelectGeneralMeetingStatus, SelectGeneralMeetingStatus } from './SelectGeneralMeetingStatus'; //? Статус зустрічі
import { billSelectGeneralVip, SelectGeneralVip } from './SelectGeneralVip'; //? VIP консультація
import SelectGeneralAppointed from './SelectGeneralAppointed'; //? Призначено
import { billSelectGeneralConditionHotline, SelectGeneralConditionHotline } from './SelectGeneralConditionHotline'; //? Стан загальний
import { billSelectGeneralConditionReach, SelectGeneralConditionReach } from './SelectGeneralConditionReach'; //? Стан дожиму
import { billSelectGeneralFinalResult, SelectGeneralFinalResult } from './SelectGeneralFinalResult'; //? Кінцевий результат
import { billSelectGeneralTypeService, SelectGeneralTypeService } from './SelectGeneralTypeService'; //? Тип послуги
import { billSelectGeneralResultMeeting, SelectGeneralResultMeeting } from './SelectGeneralResultMeeting'; //? Результат зустрічі
import { billSelectGeneralBirthCity, SelectGeneralBirthCity } from './SelectGeneralBirthCity'; //? Місто народження
import { billSelectGeneralFormBusiness, SelectGeneralFormBusiness } from './SelectGeneralFormBusiness'; //? Форма бізнесу
import { billSelectGeneralSex, SelectGeneralSex } from './SelectGeneralSex'; //? Стать
import { billSelectGeneralColor, SelectGeneralColor } from './SelectGeneralColor'; //? Колір
import { billSelectGeneralJobs, SelectGeneralJobs } from './SelectGeneralJobs'; //? Професія
import { billSelectGeneralSocStatus, SelectGeneralSocStatus } from './SelectGeneralSocStatus'; //? Соц статус
import { billSelectGeneralWorkStatus, SelectGeneralWorkStatus } from './SelectGeneralWorkStatus'; //? Службове становище
import { billSelectGeneralHobbies, SelectGeneralHobbies } from './SelectGeneralHobbies'; //? Хоббі
import { billSelectGeneralCharacteristic, SelectGeneralCharacteristic } from './SelectGeneralCharacteristic'; //? Характеристик
import { billSelectGeneralWantsToSignContract, SelectGeneralWantsToSignContract } from './SelectGeneralWantsToSignContract'; //?  Мрія клієнта (анкета)

const billMain = [
  billSelectGeneralColor, //* Колір
  billSelectGeneralBirthCity, //* Місто народження
  billSelectGeneralFormBusiness, //* Форма бізнесу
  billSelectGeneralSex, //* Стать
  billSelectGeneralJobs, //* Професія
  billSelectGeneralSocStatus, //* Соц статус
  // billSelectGeneralFil, //* Філія
  billSelectGeneralWorkStatus, //* Службове становище
  billSelectGeneralHobbies, //* Хоббі
  billSelectGeneralCharacteristic, //* Характеристик
  billSelectGeneralOfferedAmount, //* Запропоновану суму
  billSelectGeneralTypeOfLegalRelationship, //* Вид правовідносин (Що саме?)
  billSelectGeneralKindOfLegalRelationship, //* Вид правовідносин
  billSelectGeneralWantsToSignContract, //*  Мрія клієнта (анкета)
  billSelectGeneralFinalResult, //* Кінцевий результат
  billSelectGeneralTypeOfArchiving, //* Тип архівації
  billSelectGeneralHotlineAdvWhereMore, //* Що саме
  billSelectGeneralHotlineAdvWhere, //* Як дізнався / вид р
  billSelectGeneralOfficeOrRemote, //* Офіс/дист
  billSelectGeneralConditionReach, //* Стан дожиму
  billSelectGeneralConditionHotline, //* Стан загальний
  billSelectGeneralVip, //* VIP консультація
  billSelectGeneralServesTheBlock, //* Обслуговує блок
  billSelectGeneralAssignedBlock, //* Призначений блок
  billSelectGeneralTypeService, //* Тип послуги
  billSelectGeneralTypeReach, //* Тип дожиму
  billSelectGeneralMeetingStatus, //* Статус зустрічі
  billSelectGeneralResultMeeting, //* Результат зустрічі
  billSelectGeneralHellishnessReach, //* Пекельність дожиму
]; // ? [[{body_info_1: body_info_1, ...}], [name_body_1, servant_1], [name_body_2, servant_2], ...]

export {
  SelectGeneralOfferedAmount,
  SelectGeneralHellishnessReach,
  SelectGeneralTypeReach,
  SelectGeneralServesTheBlock,
  SelectGeneralAssignedBlock,
  SelectGeneralOfficeOrRemote,
  SelectGeneralHotlineAdvWhere,
  SelectGeneralHotlineAdvWhereMore,
  SelectGeneralKindOfLegalRelationship,
  SelectGeneralTypeOfLegalRelationship,
  SelectGeneralTypeOfArchiving,
  SelectGeneralFil,
  SelectGeneralMeetingStatus,
  SelectGeneralVip,
  SelectGeneralAppointed,
  SelectGeneralConditionHotline,
  SelectGeneralConditionReach,
  SelectGeneralFinalResult,
  SelectGeneralTypeService,
  SelectGeneralResultMeeting,
  SelectGeneralBirthCity,
  SelectGeneralFormBusiness,
  SelectGeneralSex,
  SelectGeneralColor,
  SelectGeneralJobs,
  SelectGeneralSocStatus,
  SelectGeneralWorkStatus,
  SelectGeneralHobbies,
  SelectGeneralCharacteristic,
  SelectGeneralWantsToSignContract,
  /////////////////////////////
  billMain,
};
