import React, { useEffect, useState, useMemo } from 'react';
import { DatePicker, Row } from 'antd';
import ReactECharts from 'echarts-for-react';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import { request, error } from '../../tools';

import './StatUsersLogs.scss';

const dayNow = dayjs();

const StatClientLogs = () => {
  const [currentMonth, setCurrentMonth] = useState(dayNow);
  const [statUsersData, setStatUsersData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getLogsStat = async () => {
    setIsLoading(true);

    const reqBody = {
      year: currentMonth.format('YYYY'),
      month: currentMonth.format('M'),
    };

    await request.post(
      '/statistic/usersLogs',
      reqBody,
      ({ data }) => {
        setStatUsersData(data);
      },
      error,
    );

    setIsLoading(false);
  };

  useEffect(() => {
    getLogsStat();
  }, [currentMonth]);

  const pieChartOption = useMemo(
    () => ({
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '0',
        left: 'center',
      },
      series: [
        {
          name: '',
          type: 'pie',
          avoidLabelOverlap: false,
          legendHoverLink: false,
          stillShowZeroSum: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 20,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: statUsersData?.sort((a, b) => b.count - a.count)?.map((item) => ({ name: item.name, value: item.count })),
        },
      ],
    }),
    [statUsersData],
  );

  return (
    <div className="default_page stat-users-logs">
      <h2>Статистика логів користувачів</h2>
      <Row align="center" className="picker-wrapper">
        <DatePicker value={currentMonth} locale={locale} onChange={setCurrentMonth} placeholder="Оберіть місяць" picker="month" />
      </Row>
      <div className="charts-wrapper">
        <ReactECharts showLoading={isLoading} option={pieChartOption} notMerge={true} lazyUpdate={true} style={{ height: '700px' }} />
      </div>
    </div>
  );
};

export default StatClientLogs;
