import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentHotlineList, setModal } from '../../store/commonReducer';

import { request, success, error } from '../../tools';
import { MEET_STATUS, TEXT_VSMS_TO_REACH_HOTLINE } from 'common_constants/business';

import { Card, Typography, Select, Input, Button } from 'antd';
import { green } from '@ant-design/colors';
import { Box } from 'common_components';

const ReasonsReach = ({ data, toggleCloseBtn, setIsLoading }) => {
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.common.userAuth);
  const list = useSelector((state) => state.common.currentHotlineList);

  const [rdr, setRDR] = useState('');
  const [desReachError, setDesReachError] = useState('');
  const [rsm, setRSM] = useState();

  const { Text } = Typography;
  const { TextArea } = Input;

  const desReachChangeHandler = (e) => {
    setRDR(e.target.value);
    const error = e.target.value.length < 20 && e.target.value;
    setDesReachError(error ? 'error' : '');
  };

  const onReachMeet = () => {
    const body = { ...data, uristName: name, s: MEET_STATUS.WAS, rrh: { rdr, rsm, rsh: true, rih: 1 } };

    setIsLoading(true);

    request.post(
      '/hotline/confirmQue',
      body,
      () => {
        success();
        const _list = list.map((i) => {
          return i._id === data._id
            ? {
                ...i,
                uristName: name,
                s: MEET_STATUS.WAS,
                amm: data.amm,
                amp: data.amp,
                amr: data.amr,
                rrh: { rdr, rsm, rsh: true, rih: 1 },
                pravo: data.pravo,
                date: data.date,
                startT: data.startT,
                endT: data.endT,
              }
            : i;
        });
        setIsLoading(false);
        dispatch(setModal());
        dispatch(setCurrentHotlineList(_list));
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  return (
    <Card title="Ця зустріч попаде в дожим." className="reach-options">
      <Box mt={0}>
        <Text>Опис для дожиму (Не менше 20 символів).</Text>
        <TextArea
          aria-label="write reason for refusal hotline"
          placeholder="Що не так з клієнтом?"
          value={rdr}
          onChange={desReachChangeHandler}
          status={desReachError}
          autoSize
        />
      </Box>
      <Box mt={16}>
        <Text>Яку СМС відправити клієнту для його дожиму?</Text>
        <Select
          optionFilterProp="label"
          allowClear
          showSearch
          listHeight={175}
          className="select-input"
          options={TEXT_VSMS_TO_REACH_HOTLINE}
          value={rsm}
          placeholder="Оберіть відео для розсилки: "
          onChange={(v) => setRSM(v)}
        />
      </Box>
      <Box mt={24} className="group-button">
        <Button
          className=" reach-reasons-btn"
          type="primary"
          style={desReachError || !rdr || rsm === undefined ? {} : { backgroundColor: green.primary }}
          disabled={desReachError || !rdr || rsm === undefined}
          onClick={onReachMeet}
        >
          Зустріч відбулась
        </Button>
        <Button className="reach-reasons-btn" type="primary" onClick={toggleCloseBtn}>
          Назад
        </Button>
      </Box>
    </Card>
  );
};

export default ReasonsReach;
