import React, { useState } from 'react';
import { Timeline, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { requestFile, error } from '../../tools';

function getStartOfWeek(date) {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day;
  return new Date(d.setDate(diff));
}

function groupDatesByWeek(autoSendingData) {
  const weeks = {};

  autoSendingData.forEach((item) => {
    const dateStr = item.scheduledSendingDate;
    const startOfWeek = getStartOfWeek(dateStr);
    const key = startOfWeek.toISOString().split('T')[0];

    if (!weeks[key]) {
      weeks[key] = [];
    }
    weeks[key].push(item);
  });

  return Object.values(weeks);
}

const ShowScheduledAutoSending = ({ autoSendingTemplates, currentTemplates }) => {
  const mfos = useSelector((state) => state.common.mfo);
  const templates = useSelector((state) => state.common.templates);
  const modalData = useSelector((state) => state.common.modal.data);
  const [loadingButton, setLoadingButton] = useState(null);

  const getMfoName = (mfoId) => {
    return mfos?.find((item) => item._id === mfoId)?.name || 'Невідомо';
  };

  const getTemplateName = (templateId) => {
    return currentTemplates?.find((template) => template._id === templateId)?.n || 'Невідомо';
  };

  let sortedAutoSendingTemplates = [...autoSendingTemplates];
  sortedAutoSendingTemplates?.sort((a, b) => new Date(b.scheduledSendingDate) - new Date(a.scheduledSendingDate));

  const groupedAutoSendingTempaltes = groupDatesByWeek(sortedAutoSendingTemplates);

  const getFormattedTimelineItems = (timelineItems) => {
    return timelineItems?.map((item) => ({
      color: item?.isSended ? 'green' : 'blue',
      children: `${new Date(item?.scheduledSendingDate).toLocaleDateString('uk-UA', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })} - ${new Date(item?.scheduledSendingDate).toLocaleTimeString('uk-UA', { hour: '2-digit', minute: '2-digit' })} - ${getMfoName(
        item?.mfoId,
      )} - ${getTemplateName(item?.templateId)}`,
    }));
  };

  const onDownloadTemplateDocumentsByWeek = (index) => {
    const items = groupedAutoSendingTempaltes?.[index];

    if (!items || items?.length === 0) return error('', 'Відсутні документи на цей тиждень');

    for (let item of items) {
      setLoadingButton(index);
      const currentTemplate = templates?.find((template) => template._id === item.templateId);

      if (!currentTemplate?.L || !currentTemplate?._id) return error('', 'На жаль, даний документ неможливо завантажити');

      const requestData = {
        _id: currentTemplate._id,
        fileId: currentTemplate.L,
        fileName: currentTemplate?.n + '.' + currentTemplate?.f,
        isTemplate: currentTemplate?.tm,
        formatFile: currentTemplate?.f,
        data: {
          mfoId: item.mfoId,
          contractId: modalData.contractId,
        },
      };

      requestFile(
        '/library/downloadFileInGoogleDrive',
        requestData,
        () => {
          setLoadingButton(null);
        },
        (err) => {
          error('', 'Помилка при відправці файлу', err);
          setLoadingButton(null);
        },
      );
    }
  };

  return (
    <div className="timeline-wrapper">
      <Timeline>
        {groupedAutoSendingTempaltes?.map((timelineItems, index) => (
          <>
            {getFormattedTimelineItems(timelineItems)?.map((item, index) => (
              <Timeline.Item key={index} color={item?.color}>
                {item.children}
              </Timeline.Item>
            ))}
            <div className="timeline-btn-wrapper">
              <Button
                className="timeline-btn"
                size="small"
                type="primary"
                icon={<DownloadOutlined />}
                loading={loadingButton === index}
                onClick={() => onDownloadTemplateDocumentsByWeek(index)}
              >
                Завантажити
              </Button>
            </div>
          </>
        ))}
      </Timeline>
    </div>
  );
};

export default ShowScheduledAutoSending;
