import React from 'react';

import { Typography } from 'antd';

const CheckClientWallet = ({ userBalance, userBonus }) => {
  const { Text } = Typography;

  return (
    <div className="row" style={{ alignItems: 'center', color: '#1677ff', display: 'flex', flexWrap: 'wrap' }}>
      <b children="Баланс / Бонуси:" />
      &nbsp;
      <br />
      <div style={{ fontWeight: '900' }}>
        {userBalance ?? 0} / {userBonus}{' '}
      </div>
    </div>
  );
};

export default CheckClientWallet;
