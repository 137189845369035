import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, Button, InputNumber } from 'antd';
import { addPromocode, setModal, updatePromocode } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import { CloseCircleOutlined } from '@ant-design/icons';

const AddPromocode = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const timeoutRef = useRef(null);
  const { editItem } = useSelector((state) => state.common.modal);
  const [loading, setLoading] = useState(false);
  const [promocodeMessage, setPromocodeMessage] = useState('');
  const [localPromocode, setLocalPromocode] = useState(editItem ? editItem.promocode : '');

  useEffect(() => {
    if (editItem) {
      setLocalPromocode(editItem.promocode);
      form.setFieldsValue({
        promocode: editItem.promocode,
        discount: editItem.discount,
      });
    }
  }, [editItem, form]);

  const handleFormSubmit = async () => {
    setLoading(true);
    const data = {
      promocode: localPromocode,
      discount: form.getFieldValue('discount'),
    };

    const requestURL = editItem ? `/promocodes/${editItem._id}` : '/promocodes/addPromocode';

    await request.post(
      requestURL,
      data,
      ({ promocode }) => {
        const successMessage = editItem ? 'Промокод успішно відредагований.' : 'Промокод успішно доданий.';
        success('', successMessage);
        if (editItem) {
          dispatch(updatePromocode(promocode));
        } else {
          dispatch(addPromocode(promocode));
        }
        dispatch(setModal());
        setLoading(false);
      },
      () => {
        const errorMessage = editItem ? 'Помилка при редагуванні.' : 'Помилка при додаванні.';
        error('', errorMessage);
        setLoading(false);
      },
    );
  };

  const checkPromocode = (value) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (!value) return;
      request.post('/promocodes/checkPromocode', { promocode: value }, (res) => {
        if (res.exists) {
          setPromocodeMessage(res.message);
        } else {
          setPromocodeMessage('');
        }
      });
    }, 1000);
  };

  return (
    <Modal title={editItem ? 'Редагувати промокод' : 'Додати промокод'} open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleFormSubmit} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Form.Item label="Назва" name="promocode" rules={[{ required: true, message: 'Будь ласка, введіть назву промокоду!' }]}>
            <Input
              value={localPromocode}
              onChange={(e) => {
                const value = e.target.value;
                setLocalPromocode(value);
                form.setFieldsValue({ promocode: value });
                checkPromocode(value);
              }}
            />
            {promocodeMessage && (
              <div style={{ color: 'red', marginTop: 12 }}>
                <CloseCircleOutlined /> {promocodeMessage}
              </div>
            )}
          </Form.Item>

          <Form.Item label="Знижка" name="discount" rules={[{ required: true, message: 'Будь ласка, введіть знижку!' }]}>
            <InputNumber />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit">
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddPromocode;
