import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button } from 'antd';
import { CloseOutlined, FileAddOutlined } from '@ant-design/icons';

import Box from '../../components/Box';
import { setModal, setCurrentPsychologistHotline, setCurrentPsychologistHotlineCalendar } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';

import { error, info, request, success } from '../../tools';
import './style.scss';

const PsychologistHotlineRemove = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { _id, date, toDate, selectedDate } = useSelector((state) => state.common.modal);

  const handleUserBlock = () => {
    dispatch(setMainLoader(true));
    request.post(
      '/psychologistHotline/remove',
      { _id },
      () => {
        success();
        updateHotlineList();
        updatedpsychologistHotlineCalendar(selectedDate);
        dispatch(setMainLoader(false));
      },
      error,
    );
    dispatch(setModal());
  };
  const updateHotlineList = async () => {
    const requestData = {
      startDate: date,
      endDate: toDate,
    };

    await request.post(
      '/psychologistHotline/get',
      requestData,
      (req) => {
        dispatch(setCurrentPsychologistHotline(req.data));
      },
      (error) => {
        console.error('Error in request:', error);
      },
    );
  };
  const updatedpsychologistHotlineCalendar = (selectedDate) => {
    const requestData = {
      startDate: selectedDate.startOfWeek,
      endDate: selectedDate.endOfWeek,
      calendar: true,
    };

    const onSuccess = (req) => {
      dispatch(setCurrentPsychologistHotlineCalendar(req.data));
    };

    const onError = (_, __, { response }) => {
      const hotlineError = response?.data?.error;
      if (hotlineError) {
        error('Помилка!', hotlineError);
      }
      dispatch(setMainLoader(false));
    };

    request.post('/psychologistHotline/get', requestData, onSuccess, onError);
  };
  const onCancel = () => {
    dispatch(setModal());
    info('', 'Ви не згодні зі своїм рішенням.');
  };

  return (
    <Modal open className="block-add-user__remove" title={'Ви впевненні що хочете скасувати консультацію?'} onCancel={onCancel} footer={null}>
      <Form className="block-form" layout="vertical" onFinish={handleUserBlock} form={form}>
        <Box className="group-button">
          <Button className="group-button__btn" type="primary" danger htmlType="submit" style={{ marginRight: '15px' }}>
            <CloseOutlined />
            <p style={{ marginTop: '0px' }}>Скасувати консультацію</p>
          </Button>
          {/* <Button className="group-button__btn" type="primary" onClick={onCancel}>
            Відмінити
          </Button> */}
        </Box>
      </Form>
    </Modal>
  );
};
export default PsychologistHotlineRemove;
