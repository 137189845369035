import React from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import { useSelector } from 'react-redux';

import HoverableUser from '../HoverableUser/HoverableUser';

const HoverableUserWithCorrectName = ({ id }) => {
  const users = useSelector((state) => state.common.users);

  if (!users) return null;

  return (
    <Popover content={<HoverableUser id={id ?? '-'} />} arrow trigger="hover">
      <Link>{users[id]?.p ?? '-'}</Link>
    </Popover>
  );
};

export default HoverableUserWithCorrectName;
