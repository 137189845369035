import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';

import { request, error } from '../../tools';
import { getHorizontalBarChartOption } from './chartOption';

import './StatFilArchiveCandy.scss';

const StatArchiveCandy = () => {
  const filii = useSelector((state) => state.common.filii);

  const [isLoading, setIsLoading] = useState(false);
  const [candyStat, setCandyStat] = useState([]);

  const getArchiveCandyStat = async () => {
    setIsLoading(true);

    await request.post(
      '/statistic/archiveCandy',
      {},
      ({ data }) => {
        const { archivedContractsCountsPerFil, candyArchivedContractsCountsPerFil, candyExpensesSumPerFil } = data;

        const stat =
          filii
            ?.map((fil) => ({
              name: fil.name,
              archiveContractsCandyCount: candyArchivedContractsCountsPerFil?.find((item) => item.fil == fil.i)?.count ?? 0,
              archiveContractsCount: archivedContractsCountsPerFil?.find((item) => item.fil == fil.i)?.count ?? 0,
              candyExpensesTotalSum: candyExpensesSumPerFil?.find((item) => item.fil == fil.i)?.sum ?? 0,
            }))
            ?.map((item) => ({
              ...item,
              candyPrice: item.archiveContractsCandyCount == 0 ? 0 : Math.round(item.candyExpensesTotalSum / item.archiveContractsCandyCount) ?? 0,
            }))
            ?.sort((a, b) => b.name.localeCompare(a.name))
            .sort((a, b) => a.candyPrice - b.candyPrice) ?? [];

        setCandyStat(stat);
      },
      error,
    );

    setIsLoading(false);
  };

  useEffect(() => {
    getArchiveCandyStat();
  }, []);

  const archiveContractsPerFilChartOptions = getHorizontalBarChartOption(
    '',
    candyStat?.map((item) => `${item?.name} - ${item?.candyPrice ?? 0}`),
    [
      {
        title: 'З цукерками',
        data: candyStat?.map((item) => ({
          value: item?.archiveContractsCandyCount,
          candyExpensesTotalSum: item?.candyExpensesTotalSum,
          candyPrice: item?.candyPrice,
        })),
      },
      {
        title: 'Без цукерок',
        data: candyStat?.map((item) => ({ value: item?.archiveContractsCount - item?.archiveContractsCandyCount })),
      },
    ],
  );

  return (
    <section className="default_page stat-candy">
      <h2>Статистика обліку архівних цукерок 🍬</h2>
      <ReactECharts
        showLoading={isLoading}
        option={archiveContractsPerFilChartOptions}
        notMerge={true}
        lazyUpdate={true}
        style={{ minHeight: '1500px' }}
      />
    </section>
  );
};

export default StatArchiveCandy;
