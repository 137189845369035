export const chartOption = (title = '', dataSeries, dataNames, formatter) => {
  return {
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      ...(formatter ? { formatter } : {}),
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: 'category',
      data: dataNames,
    },
    series: [
      {
        type: 'bar',
        data: dataSeries,
      },
    ],
  };
};
