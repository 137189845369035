import { Modal } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { ROLES } from 'common_constants/business';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setModal } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { error, request } from '../../tools';

dayjs.extend(utc);

const PsychologistHotlineSubmit = () => {
  const user = useSelector((state) => state.common.userAuth || {});
  const data = useSelector((state) => state.common.modal.data);
  const filii = useSelector((state) => state.common.filii);
  const showData = filii.find((item) => item.i === +data.fil);
  const psychologistOnly = ROLES[user.role] === ROLES.psychologist;

  const [dispatch, history] = [useDispatch(), useHistory()];
  const { _id } = data;

  const onCancel = () => {
    dispatch(setModal({ name: '', data: data }));
  };

  const onSave = () => {
    dispatch(setMainLoader(true));
    const requestData = {
      ...data,
      date: dayjs(data.date).utc(),
      _id,
    };

    const onError = (_, __, { response }) => {
      const hotlineError = response?.data?.error;
      if (hotlineError) {
        error('Помилка!', hotlineError);
      }
      dispatch(setMainLoader(false));
    };

    request.post('/psychologistHotline/addNewPsychologistHotline', requestData, saveCallback, onError);
  };

  const saveCallback = () => {
    dispatch(setMainLoader(false));
    dispatch(setModal());
    history.push({
      pathname: '/hotlineSaved',
      state: { psychologist: true },
    });
  };

  const handleSubmit = () => {
    onSave();
  };

  return (
    <Modal open onCancel={onCancel} onOk={handleSubmit}>
      <h2>Перевір перед збереженням:</h2>
      <br />
      <b>Послуга:</b> {data?.type ?? '-'}
      <br />
      <b>Дата:</b> {dayjs(data.date).utc().format('DD.MM.YYYY') ?? '-'}
      <br />
      <b>Час:</b> {data.startT} - {data.endT}
      <br />
      <b>Ім'я кліента:</b> {data.clientName ?? '-'}
      <br />
      <b>Телефон кліента:</b> {data.clientPhone ?? '-'}
      <br />
      <b>Ім'я психолога:</b> {data.psychologistName ?? '-'}
      <br />
      <b>Телефон психолога:</b> {data.psychologistPhone ?? '-'}
      <br />
      <b>Філія:</b> {showData?.name ?? '-'}
    </Modal>
  );
};
export default PsychologistHotlineSubmit;
