import React from 'react';
import { useState, useEffect } from 'react';

import { Image, Card, Spin, Typography } from 'antd';
import { blue, green, red } from '@ant-design/colors';
import { WarningOutlined } from '@ant-design/icons';

const PreviewImage = ({ item }) => {
  const token = window.localStorage.getItem('accessToken');

  const { Text } = Typography;

  const core_megaState = {
      loading: true, //* Завантаження
      url: '',
      status: blue.primary,
    },
    [megaState, setMegaState] = useState(core_megaState);

  useEffect(() => {
    const timerId = setTimeout(() => {
      const fileId = item;

      fetch(process.env.REACT_APP_API + '/correspondence/getCorrespondenceDriveFile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ fileId }),
      })
        .then((response) => {
          if (!response.ok) {
            setMegaState((prev) => ({ ...prev, url: '', status: red.primary, err: true }));
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);

          setMegaState((prev) => ({ ...prev, url: url, status: green.primary, loading: false }));
        })
        .catch((error) => {
          console.error('Error fetching image from server:', error);
          setMegaState((prev) => ({ ...prev, url: '', status: red.primary, err: true }));
        });
    }, 2000);

    return () => clearTimeout(timerId);
  }, []);

  const imgDrive = megaState.loading ? (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '84px' }}>
      {megaState.err ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <WarningOutlined style={{ fontSize: '22px', color: megaState.status }} />
          <Text type="danger" style={{ textAlign: 'center', marginTop: '4px' }}>
            Помилка
          </Text>
        </div>
      ) : (
        <Spin tip="Завантаження" spinning={megaState.loading} />
      )}
    </div>
  ) : (
    <Image src={megaState.url} style={{ borderRadius: '8px', objectFit: 'cover', width: '100%', height: '84px' }} alt="Image" />
  );

  return (
    <Card
      style={{ maxWidth: '204px', minWidth: '100px', height: '100px', padding: '8px', border: `1px solid ${megaState.status}` }}
      cover={imgDrive}
    />
  );
};

export default PreviewImage;
