import { useSelector, useDispatch } from 'react-redux';
import { Modal, Card } from 'antd';
import { setModal } from '../../store/commonReducer';

import dayjs from 'dayjs';

import './AutopayInfoList.scss';

const AutopayInfoList = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.common.modal.data);
  const { autoPayTransactions, contractNumber } = data;
  const autoPayTransactionsToRender = [...autoPayTransactions].reverse();

  const onCancel = () => dispatch(setModal());
  return (
    <Modal open title={`Автооплати по договору № ${contractNumber}`} onCancel={onCancel} footer={null}>
      <Card className="autopay-card-list">
        {autoPayTransactionsToRender?.map((item, index) => (
          <div className="autopay-item" key={index}>
            <div className="date">{dayjs(item.date)?.format('DD-MM-YYYY HH:mm:ss') ?? '-'}</div>
            <div>{item.amount}₴</div>
          </div>
        ))}
      </Card>
    </Modal>
  );
};

export default AutopayInfoList;
