import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, DatePicker } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { lime } from '@ant-design/colors';
import dayjs from 'dayjs';

import Box from '../../components/Box';
import { setMainLoader } from '../../store/uiReducer';
import useGetUsers from '../../hooks/useGetUsers';
import { setModal } from '../../store/commonReducer';
import { error, info, request, success } from '../../tools';
import './style.scss';

const DismissalEmployee = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const getAllUsers = useGetUsers();

  const { data } = useSelector((state) => state.common.modal);

  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onCancel = () => {
    dispatch(setModal());
    info('', 'Ви не згодні зі своїм рішенням.');
  };

  const handleDismissalEmployee = async () => {
    console.log('selectedDate', selectedDate);
    dispatch(setMainLoader(true));
    request.post(
      '/auth/fireUser',
      { _id: data._id, dismissalDate: selectedDate },
      (res) => {
        if (res.status) success('', 'Користувача звільнено');
        else error({ message: 'Помилка звільнення' });
        getAllUsers();
        dispatch(setMainLoader(false));
      },
      error,
    );
    dispatch(setModal());
  };

  return (
    <Modal
      open
      className="dismissal-employee-md"
      title={`Ви іпевнені що хочете звільнити працівника ${data?.name}? `}
      onCancel={onCancel}
      footer={null}
    >
      <Form className="dismissal-employee-form" layout="vertical" onFinish={handleDismissalEmployee} form={form}>
        <h4>Оберіть дату звільнення</h4>
        <DatePicker className="DatePicker" onChange={handleDateChange} value={selectedDate} />

        <Box className="group-button">
          <Button className="group-button__btn" type="primary" style={{ backgroundColor: lime.primary, borderColor: lime.primary }} htmlType="submit">
            <CheckOutlined /> Звільнити
          </Button>
          <Button className="group-button__btn" type="primary" danger onClick={onCancel}>
            <CloseOutlined />
            Відмінити дію
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
export default DismissalEmployee;
