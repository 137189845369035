import React from 'react';
import { useSelector } from 'react-redux';
import { ASSIGNMENTS_SERVICES_TYPE } from 'common_constants/business';
import { Card } from 'antd';
import dayjs from 'dayjs';

const DublicateAssignmentInfo = ({ data }) => {
  const FILII = useSelector((state) => state.common.FILII);
  const users = useSelector((state) => state.common.users);

  return (
    <Card
      title={`№${data.i} - ${dayjs(data?.assignments?.createdAt).format('DD.MM.YYYY')}`}
      size="small"
      className="dublicate-info"
      style={{ borderColor: '#' + data?.color }}
    >
      <p>
        <b>Філія:</b> {FILII?.[data?.f] ?? '-'}
      </p>
      <p>
        <b>№ договору:</b> {data?.contractNumber}
      </p>
      <p>
        <b>Автор:</b> {users ? users[data?.authorId]?.name : '-'}
      </p>
      <p>
        <b>Час:</b> {data?.selectedTime}
      </p>
      <p>
        <b>Дата:</b> {dayjs(data?.date)?.format('DD.MM.YYYY' ?? '-')}
      </p>
      <p>
        <b>Послуга:</b> {ASSIGNMENTS_SERVICES_TYPE?.[data?.serviceType] ?? '-'}
      </p>
      <p>
        <b>Судова справа:</b> {data?.courtCase}
      </p>
      <p>
        <b>Сума для клієнта:</b> {data?.clientAmount}
      </p>
      <p>
        <b>Сума для адвоката:</b> {data?.amountForLawyer}
      </p>
    </Card>
  );
};

export default DublicateAssignmentInfo;
