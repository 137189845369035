import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';

import { request, error } from '../../tools';

import { setModal } from '../../store/commonReducer';
import InsuranceInfo from '../../pages/Insurance/InsuranceInfo';

const InfoInsurance = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const insuranceTypes = useSelector((state) => state.common.allInsuranceTypes);
  const insuranceId = useSelector((state) => state.common.modal.data);
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);

  const [insurance, setInsurance] = useState({});

  const getInsuranceDetails = (itemType) => {
    const merchedType = insuranceTypes.find((obj) => obj.i === itemType);
    return merchedType?.name ? merchedType.name : 'Не визначено';
  };

  const getInsuranceById = async () => {
    setLoading(true);

    await request.post(
      '/insurance/getInsuranceById',
      insuranceId,
      ({ insurance }) => {
        setInsurance(insurance);
      },
      error,
    );

    setLoading(false);
  };

  useEffect(() => {
    getInsuranceById();
  }, []);

  return (
    <Modal title={'Інформація про страховку'} open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <InsuranceInfo data={insurance} getInsuranceDetails={getInsuranceDetails} users={users} FILII={FILII} />
      </Spin>
    </Modal>
  );
};

export default InfoInsurance;
