import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Menu, Form, Input, Button, Checkbox, notification } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { ROUTES } from 'common_constants/routes';
import {
  setUserAuth,
  setUsers,
  setAppPrepared,
  setTopFilials,
  setCurrentAuthUserPersonalDate,
  setActualization,
  setPravoRating,
  setAllInsuranceTypes,
} from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { request, getTokenData, error, prepareUsers } from '../../tools';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import './Auth.scss';

const Auth = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onFinish = async (data) => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const { visitorId, components } = result;

    loginRequest({
      username: data.username,
      password: data.password,
      fingerprint: visitorId,
      platform: components.platform.value,
      timezone: components.timezone.value,
    });
  };

  const loginRequest = (data) => {
    request.post('/auth/login', data, loginReqCallback, error);
  };

  const loginReqCallback = (res) => {
    if (res.noAccess) {
      notification.info({
        message: 'Для отримання доступу треба звернутися до адміністратора.',
        style: { wordWrap: 'break-word' },
      });
    } else {
      window.localStorage.setItem('accessToken', res.accessToken);
      dispatch(setUserAuth(getTokenData(res.accessToken)));
      getInitialData();
      history.push(ROUTES.HOME_DASHBOARD);
    }
  };

  const getInitialData = () => {
    dispatch(setMainLoader(true));
    request.post('/universal/initial', {}, (res) => {
      const _users = prepareUsers(res.users);
      dispatch(setUsers(_users));
      dispatch(setTopFilials(res.topFilialsRating));
      dispatch(setCurrentAuthUserPersonalDate(res.userPersonalData || {}));
      dispatch(setActualization(res.actualization || {}));
      dispatch(setPravoRating(res.pravoRating));
      dispatch(setAllInsuranceTypes(res.insuranceTypes));
      dispatch(setAppPrepared(true));
      dispatch(setMainLoader(false));
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="auth_page default_page">
      <Menu
        className="menu"
        // onClick={onTypeClick}
        selectedKeys={['in']}
        mode="horizontal"
      >
        <Menu.Item key="in" icon={<MailOutlined />}>
          Login
        </Menu.Item>
        {/* <Menu.Item key="up" icon={<AppstoreOutlined />}>
        Sign Up
      </Menu.Item> */}
      </Menu>

      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="username"
          rules={[
            { required: true, message: 'Email обов`язкове поле' },
            { type: 'email', message: 'Email не корректний!' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Пароль обов`язкове поле' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Auth;
