import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Card } from 'antd';
import { StartPage, DialogueList } from '../index';
import { selectUsers, selectUserAuth } from '../../../../store/commonReducer';
import Placeholder from '../Placeholder/Placeholder';

const Body = (props) => {
  const { dialogues, messagesEndRef, selectedDialogueId, scrollToBottom } = props;
  const users = useSelector(selectUsers);
  const { _id: userId } = useSelector(selectUserAuth);
  const compareIds = ({ _id }) => _id === userId;
  const currentUser = users && Object.values(users)?.find(compareIds);

  const dialoguesProps = {
    dialogues,
    selectedDialogueId,
    currentUser,
    scrollToBottom,
  };

  return (
    <Row className="body" ref={messagesEndRef}>
      <Card className="inner">
        <StartPage selectedDialogueId={selectedDialogueId} />
        <Placeholder />
        <DialogueList {...dialoguesProps} />
      </Card>
    </Row>
  );
};

export default Body;
