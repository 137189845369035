import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, Spin, Modal, DatePicker, Typography } from 'antd';
import { CSVLink } from 'react-csv';
import { useResize } from 'common_components';

import { setModal } from '../../store/commonReducer';
import Excel from '../../images/excel_logo.svg';
import { request, error, success } from '../../tools';
import Box from '../../components/Box';

import dayjs from 'dayjs';
import locale from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';

import './styles.scss';

const ExportPayments = () => {
  const dispatch = useDispatch();
  const resize = useResize();
  const { RangePicker } = DatePicker;
  const { Text } = Typography;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [dateArr, setDateArr] = useState('_');

  const FILII = useSelector((state) => state.common.FILII);

  const onGenerate = async () => {
    setLoading(true);

    const transaction = {
      startDate: dateArr[0] ? dayjs(dateArr[0]) : null,
      endDate: dateArr[1] ? dayjs(dateArr[1]) : null,
      konsultations: true,
      contracts: true,
    };

    await request.post(
      '/spreadsheets/exportPayments',
      transaction,
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);
        setData(req.data);
      },
      error,
    );

    setLoading(false);
  };

  const csvData = [
    ['Номер оплати', 'Номер від фонді', 'Тип', 'Дата оплати', 'Дата консультації чи термін по договору', 'Сума', 'Філія', 'Клієнт', 'Телефон'],
  ];

  data?.contracts?.forEach((contract) => {
    csvData.push([
      contract.transactions.i,
      contract.transactions.payment_id,
      'Договір',
      dayjs(contract.transactions.order_time).format('DD.MM.YYYY HH:mm:ss'),
      dayjs(contract.sd).format('DD.MM.YYYY HH:mm:ss'),
      contract.transactions.amount,
      FILII[contract.f],
      contract.client?.n,
      contract.client?.ph,
    ]);
  });

  data?.hotlines?.forEach((konsultation) => {
    csvData.push([
      konsultation.transactions.i,
      konsultation.transactions.payment_id,
      'Консультація',
      dayjs(konsultation.transactions.order_time).format('DD.MM.YYYY HH:mm:ss'),
      dayjs(konsultation.date).format('DD.MM.YYYY HH:mm:ss'),
      konsultation.transactions.amount,
      FILII[konsultation.fil],
      konsultation.client?.n,
      konsultation.client?.ph,
    ]);
  });

  return (
    <Modal open className="export-hotline" title="Експорт оплат" onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="change">
          <Box className={resize[0] >= 500 ? 'exp-filter-box-pc' : 'exp-filter-box-mob'}>
            <RangePicker
              locale={locale}
              className="exp-filter"
              onChange={(value) => setDateArr(value ? [new Date(value?.[0]), new Date(value?.[1])] : '_')}
            />
            <Text className="exp-filter-box-text"> Дата оплати: </Text>
          </Box>
          <br />
          {data ? (
            <CSVLink asyncOnClick={true} data={csvData}>
              <Button className="excel">
                <img className="excel-img" src={Excel} alt="Excel" /> Скачати .csv
              </Button>
            </CSVLink>
          ) : (
            <Button onClick={onGenerate}>Генерувати</Button>
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

export default ExportPayments;
