import React from 'react';
import { FORM_OF_BUSINESS } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralFormBusiness = [FORM_OF_BUSINESS, ['Форма бізнесу по договору', 'u_C']];

const SelectGeneralFormBusiness = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть форму бізнесу"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі форми бізнесу</Option>
      {FORM_OF_BUSINESS.map((i, index) => (
        <Option key={i} value={String(index)}>
          {i}
        </Option>
      ))}
    </Select>
  );
};

export { billSelectGeneralFormBusiness, SelectGeneralFormBusiness };
