import { useDispatch, useSelector } from 'react-redux';
import { Modal, Typography, Popover } from 'antd';
import Link from 'antd/es/typography/Link';

import dayjs from 'dayjs';

import { setModal } from '../../store/commonReducer';
import { CURRENCIES, EXPENSE_CATEGORIES } from 'common_constants/business';
import HoverableUser from '../../components/HoverableUser';

import './style.scss';
import { useEffect } from 'react';

const InfoExpense = () => {
  const { Text } = Typography;

  const dispatch = useDispatch();

  const { expense } = useSelector((state) => state.common.modal.data);
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.filii);

  const expenseFil = Object.values(FILII).find((fil) => fil.i === expense.fil);

  const filName = expenseFil?.name || expense.fil;

  const categoryData = EXPENSE_CATEGORIES[expense.category];

  const categoryLabelToDisplay = categoryData
    ? expense.category === 10
      ? categoryData.adCategories.find((subcat) => subcat.value === expense.adCategory)?.label || categoryData.label
      : categoryData.label
    : expense.category;

  const subCategoryLabelToDisplay =
    expense.category === 0
      ? categoryData?.subcategories.find((subcat) => subcat.value === parseInt(expense.subCategory, 10))?.label || expense.subCategory
      : categoryData?.subcategories[expense.subCategory]?.label || expense.subCategory;

  return (
    <Modal title={'Інформація про витрату'} open onCancel={() => dispatch(setModal())} footer={null}>
      <div className="infoExpensePage">
        <span>
          <Text strong>Філія:</Text>&nbsp;
          <Text>{filName ?? '-'}</Text>
        </span>
        <span>
          <Text strong>№:</Text>&nbsp;
          <Text>{expense.i || '-'}</Text>
        </span>
        <span>
          <Text strong>Категорія:</Text>&nbsp;
          <Text>{categoryLabelToDisplay || '-'}</Text>
        </span>
        <span>
          <Text strong>Сума:</Text>&nbsp;
          <Text>
            {expense?.amount || '0'}
            {expense?.currency ? CURRENCIES[expense.currency].symbol : CURRENCIES[expenseFil.currency].symbol ?? '₴'}
          </Text>
        </span>
        <span>
          <Text strong>Підкатегорія:</Text>&nbsp;
          <Text>{subCategoryLabelToDisplay || '-'}</Text>
        </span>
        <span>
          <Text strong>Дата:</Text>&nbsp;
          <Text>{expense?.date !== undefined ? dayjs(expense?.date).format('DD.MM.YYYY') : '-'}</Text>
        </span>
        <span>
          <Text strong>Дата звіту:</Text>&nbsp;
          <Text>{expense?.createdAt !== undefined ? dayjs(expense?.createdAt).format('DD.MM.YYYY') : '-'}</Text>
        </span>
        {expense?.author && (
          <span>
            <Text strong>Автор:</Text>&nbsp;
            <Popover content={<HoverableUser id={users[expense?.author]?._id ?? '-'} />} arrow trigger="hover">
              <Link>{users[expense?.author]?.p ?? '-'}</Link>
            </Popover>
          </span>
        )}
        {expense?.sugarIndex && (
          <span>
            <Text strong>№ сахорка:</Text>&nbsp;
            <Text>{expense?.sugarIndex}</Text>
          </span>
        )}
        {expense?.toUser && (
          <span>
            <Text strong>Кому:</Text>&nbsp;
            <Popover content={<HoverableUser id={users[expense?.toUser]?._id ?? '-'} />} arrow trigger="hover">
              <Link>{users[expense?.toUser]?.p ?? '-'}</Link>
            </Popover>
          </span>
        )}
      </div>
    </Modal>
  );
};

export default InfoExpense;
