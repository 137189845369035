import React from 'react';
import { ROLES } from 'common_constants/business';
import { Form, Input, DatePicker, Typography, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { warn, request, success, error } from '../../tools';
import { editContract } from '../../store/commonReducer';
import SearchSelect from '../SearchSelect';

const { Item } = Form;
const { Text } = Typography;

const isBlankFields = (contract) => {
  const { pa, md, fp, installmentMonths, pm, monthlyFee, ea } = contract;
  if (contract?.editPMonceAccess === true) return true;
  if (
    pa !== undefined &&
    md !== undefined &&
    fp !== '' &&
    installmentMonths !== undefined &&
    pm !== undefined &&
    monthlyFee !== undefined &&
    ea !== undefined
  ) {
    return false;
  }
  return true;
};

const PaymentManagementForm = ({ contract, form, setForm, isEditContractModal, setLoading }) => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.common.userAuth);

  const inspectorOrHigher = ROLES[role] <= ROLES.inspector;

  const getDisabledDate = (d) => {
    if (!d) return true;

    if (inspectorOrHigher) return false;
    return d.isBefore(dayjs().subtract(1, 'week'));
  };

  const onSubmit = () => {
    setLoading(true);
    if (form?.fp && Number(form?.fp) > Number(form?.pa)) {
      warn('', 'Сума першого платежу не може бути більшою за суму гонорару');
      setLoading(false);
      return;
    }
    const onSuccess = () => {
      const data = { ...contract, ...form };
      dispatch(editContract(data));
      success('Менеджер платежів змінено');
      setLoading(false);
    };
    const onError = () => {
      error('Помилка, спробуйте ще раз');
      setLoading(false);
    };

    const paymentManagementForm = {
      pa: form?.pa,
      md: form?.editPMonceAccess ? dayjs() : dayjs(form?.md),
      fp: form?.fp,
      installmentMonths: form?.installmentMonths,
      pm: 13,
      monthlyFee: form?.monthlyFee,
      ea: form?.ea,
      ...(contract?.editPMonceAccess === true ? { editPMonceAccess: false } : {}),
    };

    request.post('/contracts/updatePaymentManagement', { _id: contract._id, paymentManagementForm }, onSuccess, onError);
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      {(isEditContractModal || contract?.pa === undefined || contract?.editPMonceAccess) && (
        <Item>
          <Text>Сума гонорару</Text>
          <Input
            onKeyDown={handleKeyPress}
            type="number"
            onWheel={(e) => e.target.blur()}
            min={0}
            disabled={contract?.editPMonceAccess === true ? false : !inspectorOrHigher && contract.pa !== undefined}
            aria-label="Сума гонорару"
            value={form.pa}
            onChange={(e) => setForm({ ...form, pa: Math.abs(e.target.value).toFixed(0).toString() })}
            status={+form?.pa < +form?.fp ? 'error' : ''}
          />
          {+form?.pa < +form?.fp ? <Text type="danger">Сума першого платежу не може бути більшою за суму гонорару!</Text> : null}
        </Item>
      )}

      {(isEditContractModal || contract?.md === undefined || contract?.editPMonceAccess) && (
        <Item>
          <Text>Дата 1п</Text>
          <DatePicker disabledDate={getDisabledDate} value={form.md} onChange={(v) => setForm({ ...form, md: v })} format="DD-MM-YYYY" />
        </Item>
      )}

      {(isEditContractModal || contract.fp === '' || contract.fp === undefined || contract?.editPMonceAccess) && (
        <Item>
          <Text>Перший платіж</Text>
          <Input
            onKeyDown={handleKeyPress}
            type="number"
            onWheel={(e) => e.target.blur()}
            min={0}
            aria-label="Перший платіж"
            value={form.fp}
            onChange={(e) => setForm({ ...form, fp: Math.abs(e.target.value).toFixed(0).toString() })}
          />
        </Item>
      )}
      {(isEditContractModal || contract.installmentMonths === undefined || contract?.editPMonceAccess) && (
        <Item>
          <Text>Кількість місяців в розстрочку</Text>
          <Input
            onKeyDown={handleKeyPress}
            disabled={contract?.editPMonceAccess === true ? false : !inspectorOrHigher && contract.installmentMonths !== undefined}
            aria-label="Кількість місяців в розстрочку"
            onWheel={(e) => e.target.blur()}
            type="number"
            value={form.installmentMonths}
            onChange={(e) => setForm({ ...form, installmentMonths: Number(e.target.value) })}
          />
        </Item>
      )}
      {(isEditContractModal || contract.pm === undefined || contract?.editPMonceAccess) && (
        <Item>
          <Text>Кількість місяців</Text>
          <SearchSelect
            disabled
            list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'Бескінечно']}
            value={form.pm}
            placeholder="Оберіть кількість місяців"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            // onChange={(v) => setForm({ ...form, pm: v })}
          />
        </Item>
      )}
      {(isEditContractModal || contract?.monthlyFee === undefined || contract?.editPMonceAccess) && (
        <Item>
          <Text>Щомісячна оплата</Text>
          <Input
            onKeyDown={handleKeyPress}
            disabled={contract?.editPMonceAccess === true ? false : !inspectorOrHigher && contract.monthlyFee !== undefined}
            type="number"
            min="0"
            onWheel={(e) => e.target.blur()}
            aria-label="Щомісячна оплата"
            value={form.monthlyFee}
            onChange={(e) => setForm({ ...form, monthlyFee: e.target.value })}
          />
        </Item>
      )}
      {(isEditContractModal || contract?.ea === undefined || contract?.editPMonceAccess) && (
        <Item>
          <Text>Вихід адвоката</Text>
          <Input
            onKeyDown={handleKeyPress}
            disabled={contract?.editPMonceAccess === true ? false : !inspectorOrHigher && contract.ea !== undefined}
            type="number"
            min="0"
            onWheel={(e) => e.target.blur()}
            aria-label="Вихід адвоката"
            value={form.ea}
            onChange={(e) => setForm({ ...form, ea: e.target.value })}
          />
        </Item>
      )}
      {contract?._id && !isEditContractModal && isBlankFields(contract) && (
        <div style={{ textAlign: 'right', margin: 20 }}>
          <Button onClick={onSubmit} type="primary">
            {contract?.editPMonceAccess ? 'Актуалізувати дані' : 'Змінити'}
          </Button>
        </div>
      )}
    </>
  );
};

export default PaymentManagementForm;
