import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, List, Typography, Empty, Input, Spin, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { setModal, setLibraryMiniForModal } from '../../store/commonReducer';
import { request, error } from '../../tools';
import LibraryCardItem from './LibraryCardItem';
import { PRAVO } from 'common_constants/business';
import { ROUTES } from 'common_constants/routes';

const { Text } = Typography;
const LIMIT = 10;

const LibraryMiniList = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const data = useSelector((state) => state.common.modal.data);
  const list = useSelector((state) => state.common.libraryMiniForModal);
  const [isLoading, setIsLoading] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [totalItems, setTotalItems] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const onCancel = () => dispatch(setModal());

  const getAllListLibrary = async (isSearch) => {
    setIsLoading(true);

    const nameModal =
      {
        [ROUTES.CONTRACTS]: ROUTES.CONTRACTS,
        [ROUTES.LIST]: ROUTES.LIST,
        [ROUTES.HOTLINE_REACH]: ROUTES.HOTLINE_REACH,
      }[pathname] || false;

    const transaction = { isMiniLib: true, nameModal, shoo: +data.shoo, page: isSearch ? 1 : currentPage, search: inputSearch };

    await request.post(
      '/library',
      transaction,
      (req) => {
        setTotalItems(req.totalItems);
        dispatch(setLibraryMiniForModal(req.library));
        setIsLoading(false);
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  const onSearch = () => {
    getAllListLibrary(true);
  };

  useEffect(() => {
    getAllListLibrary();
  }, [currentPage]);

  return (
    <Modal className="library-info-list" open title={`Міні бібліотека (Усьго ${totalItems ?? '0'} файли)`} onCancel={onCancel} footer={null}>
      <Text strong>Тема: {PRAVO[data?.shoo] ?? '-'}</Text>
      <Spin spinning={isLoading}>
        <div className="search-wrapper">
          <Input placeholder="Введіть назву файлу даної теми..." onChange={(e) => setInputSearch(e.target.value)} value={inputSearch} />
          <Button type="primary" onClick={onSearch}>
            <SearchOutlined />
          </Button>
        </div>

        {list?.length ? (
          <>
            <List
              itemLayout="horizontal"
              pagination={{
                hideOnSinglePage: true,
                align: 'end',
                defaultCurrent: 1,
                total: totalItems ?? 1,
                defaultPageSize: LIMIT,
                onChange: (page) => {
                  setCurrentPage(page);
                },
              }}
              dataSource={list ?? []}
              renderItem={(item) => <LibraryCardItem data={item} />}
            ></List>
          </>
        ) : (
          <Empty description={<Text>Немає файлів</Text>} />
        )}
      </Spin>
    </Modal>
  );
};

export default LibraryMiniList;
