import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Tabs, Pagination, Spin, Empty, Typography } from 'antd';
import { DATA_PAGE_SIZE } from 'common_constants/db';

import { setDublicates } from '../../store/commonReducer';
import { request, error } from '../../tools';
import DublicateSugarInfo from './DublicateSugarInfo';
import DublicateAssignmentInfo from './DublicateAssignmentInfo';
import DublicatePayrollInfo from './DublicatePayrollInfo';

import './Dublicates.scss';

const tabItems = [
  {
    key: 'sugars',
    label: 'Сахарки',
  },
  {
    key: 'payrolls',
    label: 'Пейроли',
  },
  {
    key: 'assignments',
    label: 'Виходи',
  },
];

const { Text } = Typography;
const DEFAULT_PAGE_SIZE = DATA_PAGE_SIZE;

const Dublicates = () => {
  const dispatch = useDispatch();

  const dublicates = useSelector((state) => state.common.dublicates);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState('sugars');
  const [loading, setLoading] = useState(false);
  const [currentDublicates, setCurrentDublicates] = useState(dublicates[activeTab]?.data?.[currentPage] ?? []);
  const [total, setTotal] = useState(dublicates[activeTab]?.total ?? 1);

  const handleTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
  };

  const getDublicates = () => {
    if (dublicates[activeTab]?.data[currentPage]?.length > 0) {
      setCurrentDublicates(dublicates[activeTab].data[currentPage]);
      setTotal(dublicates[activeTab].total ?? 1);
      return;
    }

    setLoading(true);

    const params = {
      type: activeTab,
      page: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
    };

    request.post(
      '/dublicates/getByType',
      params,
      ({ data, total }) => {
        dispatch(
          setDublicates({
            page: currentPage,
            key: activeTab,
            data,
            total,
          }),
        );
        setCurrentDublicates(data);
        setTotal(total);
        setLoading(false);
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  const handlePageChange = (val) => {
    setCurrentPage(val);
    getDublicates();
  };

  useEffect(() => {
    getDublicates();
  }, [currentPage, activeTab]);

  return (
    <section className="default_page dublicates">
      <Row align="middle" justify="center">
        <h2>Дублікати</h2>
      </Row>

      <Tabs className="tabs" activeKey={activeTab} onChange={handleTabChange} items={tabItems} />

      <div className="">
        <Spin spinning={loading}>
          <>
            {currentDublicates?.length ? (
              <div className="dublicates-wrapper">
                {currentDublicates?.map((item, index) =>
                  activeTab === 'sugars' ? (
                    <DublicateSugarInfo key={item._id} data={item} />
                  ) : activeTab === 'payrolls' ? (
                    <DublicatePayrollInfo key={index} data={item} />
                  ) : activeTab === 'assignments' ? (
                    <DublicateAssignmentInfo key={index} data={item} />
                  ) : null,
                )}
              </div>
            ) : (
              <Empty
                description={
                  <Text>
                    Немає дублікатів
                    {activeTab === 'sugars' ? ' сахарків' : activeTab === 'payrolls' ? ' пейролів' : activeTab === 'assignments' ? ' виходів' : ''}
                  </Text>
                }
              />
            )}
            <Pagination
              style={{ textAlign: 'center' }}
              current={currentPage}
              pageSize={DEFAULT_PAGE_SIZE}
              total={total}
              onChange={handlePageChange}
              hideOnSinglePage={true}
            />
          </>
        </Spin>
      </div>
    </section>
  );
};

export default Dublicates;
