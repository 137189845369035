const VOTE_BASE_ROUTE = '/contracts/vote/';

export const VOTE_ROUTES = {
  GET: VOTE_BASE_ROUTE + 'get',
  CREATE: VOTE_BASE_ROUTE + 'create',
  UPDATE: VOTE_BASE_ROUTE + 'update',
};

export const VOTING_STEPS = {
  RECEIVING_DATA: 0,
  VOTING: 1,
  ARCHIVE_CANDY: 2,
  BLOCK_RATING: 3,
  VOTING_COMPLETION: 4,
  PERSONAL_VOTING_RESULT: 5,
  CONTRACT_NOT_FOUND: 6,
};

export const MY_BRANCH = '8';
