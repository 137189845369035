const arrayCard = ({ mr, cards }) => {
  let listCard = `\n\n`;

  mr.forEach((item_props) => {
    let check_value = cards.find((e) => e.i === item_props)?.m,
      jump_value = check_value ? check_value + `\n\n` : '';

    listCard = listCard + jump_value;
  });

  return listCard;
};

export default arrayCard;
