import React, { useEffect, useRef, useState } from 'react';
import { Popover } from 'antd';
import { CoffeeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

function Timer({ time }) {
  const [isRunning, setIsRunning] = useState(true);
  const elapsedTimeRef = useRef(0);

  const currentTime = new Date().getTime();
  const targetTime = new Date(time).getTime();
  const diff = (currentTime - targetTime) / 1000;
  const needTimer = diff <= 14400; // 4h

  useEffect(() => {
    if (!needTimer) return;
    if (!isRunning) return;

    const updateTimer = () => {
      const currentTime = new Date().getTime();
      const newElapsedTime = (currentTime - targetTime) / 1000; // мілісекунди в секунди
      elapsedTimeRef.current = newElapsedTime;

      if (newElapsedTime >= 14400) {
        // 4h
        setIsRunning(false);
      }
    };

    updateTimer();

    const timerInterval = setInterval(updateTimer, 60000); // every minute

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  if (!isRunning) return null;
  if (!needTimer) return null;

  const hours = Math.floor(elapsedTimeRef.current / 3600);

  return (
    <div className="timer">
      <Popover placement="left" content={<span>Створено {`${hours === 0 ? 'менеше 1' : hours}`} години тому</span>}>
        <CoffeeOutlined />
      </Popover>
    </div>
  );
}

export default React.memo(Timer);
