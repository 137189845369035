import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Alert, Button, Input, InputNumber, Popover, Select, Spin, Typography, Checkbox } from 'antd';
import { PAID_CONSULTATION_GROUP, WHY_NOT_USE_APP } from 'common_constants/business';
import { Box } from 'common_components';

import SearchSelectCurrencies from '../../components/SearchSelect/SerchSelectCurrencies';
import { request } from '../../tools';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const Step2 = ({
  isPopoverVisible,
  setIsPopoverVisible,
  userName,
  pravoRating,
  getCurrentPravoRating,
  data,
  setData,
  notUseApplication,
  setNotUseApplication,
  notUseApplicationReason,
  setNotUseApplicationReason,
  ...props
}) => {
  const { Text } = Typography;
  const names = userName?.split(' ');
  const [lastName, ...restNames] = names || [];
  const secondName = restNames.length ? restNames.join(' ') : '';

  const filii = useSelector((state) => state.common.filii);
  const defaultCurrency = filii.find((item) => item.i === Number(data?.fil))?.currency || 'UAH';

  const [name, setName] = useState(data?.name || '');
  const [surname, setSurname] = useState(data?.surname || '');
  const [cost, setCost] = useState(data?.cost || '');
  const [noAppCost, setNoAppCost] = useState(data?.noAppCost || '');
  const [currency, setCurrency] = useState(defaultCurrency);
  const [promocodes, setPromocodes] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const comment = data?.comment || '';

  const changeCost = (item) => {
    if (item >= 0) {
      setCost(item);
      setData({ ...data, cost: item });
    }
  };

  const changeNoAppPrice = (item) => {
    if (item >= 0) {
      setNoAppCost(item);
      setData({ ...data, noAppCost: item });
    }
  };

  const handleChangeCurrency = (value) => {
    setCurrency(value);
    setData({ ...data, currency: value });
  };

  useEffect(() => {
    setCurrency(defaultCurrency);
    setData((prevData) => ({ ...prevData, currency: defaultCurrency }));
  }, [defaultCurrency]);

  useEffect(() => {
    setName(data.name);
    setSurname(data.surname);
  }, [data.surname, data.name]);

  const CustomPopoverContent = ({ handlePopoverClick, handlePopoverClose }) => {
    return (
      <div style={{ overflow: 'auto' }}>
        <span style={{ fontWeight: 'bold', fontSize: '16px', cursor: 'pointer' }} onClick={handlePopoverClick}>
          {<Button type="primary">Використати ім'я</Button>}
        </span>
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={handlePopoverClose}
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
        />
      </div>
    );
  };

  const getAllPromocodes = () => {
    setIsLoading(true);
    request
      .post('/promocodes/getPromocodes', {}, ({ promocodes }) => {
        setPromocodes(promocodes);
      })
      .finally(setIsLoading(false));
  };

  useEffect(() => {
    getAllPromocodes();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newData = { [name]: value };
    setData({ ...data, ...newData });
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  const currentPravoRating = getCurrentPravoRating(data.pravo);
  const lazyCase = currentPravoRating === 'Безкоштовна' && data?.lazy === true;
  const isFreeAtOffice = (currentPravoRating === 'Середня' || lazyCase) && data.type === '0';
  const isFree = (currentPravoRating === 'Безкоштовна' && data?.lazy !== true) || isFreeAtOffice;
  const pravoRatingPrice = pravoRating?.find((item) => item?.pravo === data?.pravo)?.price || 500;
  const vipMult = pravoRatingPrice * 2;
  let withAppPrice = data?.vip ? vipMult : isFree ? 0 : pravoRatingPrice;
  let noAppPrice = data?.vip ? vipMult + 200 : isFree ? pravoRatingPrice : pravoRatingPrice + 100;
  const usingAutoPrice = currency === 'UAH';
  const isPaid = PAID_CONSULTATION_GROUP.includes(data?.type);

  if (!isPaid) {
    withAppPrice = 0;
    noAppPrice = 0;
  }

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      cost: withAppPrice,
      costNoApp: noAppPrice,
    }));
  }, [data?.vip, data?.pravo, data?.type, data?.lazy]);

  return (
    <div className="step2">
      {props.tip && (
        <Alert
          message="Дані клієнта"
          description={
            <>
              <b>Добре, я записую Вас на прийом, назвіть будь ласка, своє прізвище, імʼя та по- батькові.</b>
            </>
          }
          type="info"
          showIcon
        />
      )}
      <Box>
        <Text strong>Ім'я, по батькові:</Text>

        <Popover
          placement="topLeft"
          content={
            <CustomPopoverContent
              userName={userName}
              handlePopoverClick={() => {
                setIsPopoverVisible(false);
                setName(secondName);
                setSurname(lastName);
                setData({ ...data, name: secondName, surname: lastName });
              }}
              handlePopoverClose={() => setIsPopoverVisible(false)}
            />
          }
          title={
            <div style={{ paddingRight: '24px' }}>
              Знайдено користувача: <span style={{ fontWeight: 'bold' }}>{userName}</span>
            </div>
          }
          trigger="click"
          open={isPopoverVisible && userName && userName !== name}
          getPopupContainer={(triggerNode) => triggerNode}
          overlayStyle={{ border: '1px solid #B4B4B4', borderRadius: '10px' }}
        >
          <Input
            name="name"
            placeholder=" Вказувати імʼя та по батькові"
            aria-label="write name client"
            style={{ marginBottom: 10 }}
            value={name}
            prefix={<UserOutlined />}
            onChange={(item) => {
              setName(item.target.value);
              setIsPopoverVisible(false);
            }}
            onBlur={(item) => handleChange(item)}
          />

          <Text strong>Прізвище:</Text>

          <Input
            name="surname"
            placeholder=" Вказувати тільки прізвище"
            aria-label="write surname client"
            value={surname}
            prefix={<UserOutlined />}
            onChange={(item) => {
              setSurname(item.target.value);
              setIsPopoverVisible(false);
            }}
            onBlur={(item) => handleChange(item)}
          />
        </Popover>
      </Box>
      <div>
        <Checkbox
          className="notUseApplication"
          style={{ width: '50%' }}
          checked={notUseApplication}
          onChange={(e) => {
            setNotUseApplication(e.target.checked);
            if (!e.target.checked) setData({ ...data, notUseApReason: null, changeUseApplication: true });
            setData({ ...data, notUseAp: e.target.checked, changeUseApplication: true });
          }}
        >
          <span>Не спроможний користуватись додатком єАдвокат</span>
        </Checkbox>
      </div>
      {notUseApplication && (
        <Select
          style={{ width: '200px' }}
          defaultValue={notUseApplicationReason}
          placeholder="Search to Select"
          optionFilterProp="children"
          onChange={(v) => {
            setNotUseApplicationReason(v);
            setData({ ...data, notUseApReason: v, changeUseApplication: true });
          }}
        >
          {WHY_NOT_USE_APP.map((i, index) => (
            <Select.Option key={i} value={index}>
              {i}
            </Select.Option>
          ))}
        </Select>
      )}
      {data.eAdvokat && <Alert message="Клієнт має єАдвокат" type="info" showIcon style={{ marginTop: 10 }} />}
      <p>
        Ціна по рейтингу права: <br />
        Оплата <b> з </b> єАдвокат <b> ₴{withAppPrice}</b> <br />
        Оплата <b> без </b> єАдвокат <b> ₴{noAppPrice}</b> <br />
      </p>
      <Box>
        <Spin spinning={isLoading}>
          <Text strong>Промокод:</Text>
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              placeholder={promocodes.length === 0 ? 'Промокоди відсутні' : 'Оберіть промокод'}
              style={{ width: 200 }}
              onChange={(value) => {
                const selectedPromocode = promocodes.find((item) => item._id === value);
                setData({ ...data, promocode: selectedPromocode?._id, am: 38, aw: 7 });
                setSelectedDiscount(selectedPromocode?.discount || null);
              }}
              optionLabelProp="label"
            >
              {promocodes.length > 0 &&
                promocodes
                  .filter((item) => item.isDeleted !== true)
                  .map((item) => (
                    <Select.Option value={item._id} key={item._id} label={item.promocode}>
                      {' '}
                      {item.promocode}
                    </Select.Option>
                  ))}
            </Select>

            {selectedDiscount !== null && <Text style={{ marginLeft: '10px' }}>Знижка: {selectedDiscount}%</Text>}
          </div>
          <br />
        </Spin>

        <Text strong>Ціна консультації:</Text>
        <div style={{ display: 'flex' }}>
          <InputNumber
            onKeyDown={handleKeyPress}
            placeholder="Ціну вказувати від нуля"
            value={usingAutoPrice ? withAppPrice : cost}
            disabled={usingAutoPrice}
            onChange={(item) => {
              changeCost(item);
            }}
            step={100}
            style={{ width: '50%', marginRight: '10px' }}
          />
          <InputNumber
            onKeyDown={handleKeyPress}
            placeholder="Ціну вказувати від нуля"
            value={usingAutoPrice ? noAppPrice : noAppCost}
            disabled={usingAutoPrice}
            onChange={(item) => {
              changeNoAppPrice(item);
            }}
            step={100}
            style={{ width: '50%', marginRight: '10px' }}
          />
          <SearchSelectCurrencies value={currency} onChange={handleChangeCurrency} />
        </div>
      </Box>
      <Box>
        <Text strong>Коментар:</Text>
        <Input
          name="comment"
          placeholder="Опишіть корисну інформацію"
          aria-label="write comment lawyer"
          value={comment}
          onChange={(item) => handleChange(item)}
        />
      </Box>
    </div>
  );
};

export default Step2;
