import React, { useState } from 'react';
import { Tabs } from 'antd';
import ProfileTwitter from './ProfileTwitter';
import HomeTwitter from './HomeTwitter';

import './Twitter.scss';

const { TabPane } = Tabs;

const Twitter = () => {
  const [activeTab, setActiveTab] = useState('1');

  const onTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="twitter_page default_page">
      <div className="tabs-container">
        <Tabs defaultActiveKey="1" activeKey={activeTab} onTabClick={onTabClick}>
          <TabPane tab="Спільнота" key="1" style={{ width: '100%' }}>
            <HomeTwitter requestType="allTweets" activeMainTab={activeTab} />
          </TabPane>
          <TabPane tab="Мій профіль" key="2">
            <ProfileTwitter requestType="userTweets" activeMainTab={activeTab} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Twitter;
