import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const RANGE = 10;
const SATURATION = 50;
const LIGHTNESS = 40;

const getChatNameByInitials = (user) => {
  if (!user) return '';

  const nameArr = user?.p?.split(' ');
  let name = '';
  for (let item of nameArr) {
    name += item?.[0];
  }
  return name?.toUpperCase() ?? '';
};

const getHashOfString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
  return [h, s, l];
};

const HSLtoString = (hsl) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
  return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getRange = (value, range) => {
  return [Math.max(0, value - range), Math.min(value + range, 100)];
};

const UserColorAvatar = ({ user, style, size, className, shape }) => {
  const imageName = user?.upa;

  const unknownURL = 'https://th.bing.com/th/id/OIP.NmdbjgnCEbGC6o4A43nhJgHaHs?pid=ImgDet&w=195&h=202&c=7&dpr=1.5';
  const userAvatarURL = imageName ? process.env.REACT_APP_API + `/avatars/${imageName}_200.jpeg` : null;

  const saturationRange = getRange(SATURATION, RANGE);
  const lightnessRange = getRange(LIGHTNESS, RANGE);

  return userAvatarURL ? (
    <Avatar style={style} shape={shape} className={className} size={size} icon={<UserOutlined />} src={user ? userAvatarURL : unknownURL} />
  ) : (
    <Avatar
      size="large"
      style={{
        flexShrink: '0',
        backgroundColor: generateColorHsl(getChatNameByInitials(user), saturationRange, lightnessRange),
      }}
    >
      {getChatNameByInitials(user)}
    </Avatar>
  );
};

export default UserColorAvatar;
