import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModal, setBankCardsList } from '../../store/commonReducer';

import { Input, Button, Typography, Card, List, Spin, Empty } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';

import { ADD_BANK_CARDS } from 'common_constants/modals';
import { useResize, Box, Row as MyRow } from 'common_components';
import { TYPES_OF_BANK_ACCOUNTS } from 'common_constants/business';

import BankCardsItem from './BankCardstem';
import { request, error } from '../../tools';
import './BankCards.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const BankCards = () => {
  const [formRef, resize, dispatch] = [useRef(null), useResize(), useDispatch()];
  const list = useSelector((state) => state.common.bankCards);
  const { Text } = Typography;
  const filii = useSelector((state) => state.common.filii);

  const core_megaState = {
      loading: false, //* Завантаження
      filterLibraryInput: '', //* Фільтр відмітки
    },
    [megaState, setMegaState] = useState(core_megaState);

  const filterName = (file) => {
    const filName = filii.find((fil) => fil.i === file.f)?.name;
    const [card_Fil_Name, card_Fil, card_Number, card_TextSMS, card_Title, carf_Spec] = [
      typeof filName === 'string' ? filName : '',
      typeof file.f === 'number' ? file.f : '',
      typeof file.i === 'number' ? file.i : '', //* Чекаєм номер порядковий
      typeof file.m === 'string' ? file.m.replace(/\n/g, ' ') : '', //* Чекаєм смс клієнтів
      typeof file.n === 'string' ? file.n : '', //* Чекаєм коротку назву картки
      +file.z >= 0 ? TYPES_OF_BANK_ACCOUNTS[+file.z]?.label : 'Bad code', //* Специфікація рахунку
    ];

    const text = [card_Fil_Name, card_Fil, card_Number, card_TextSMS, card_Title, carf_Spec].join(' '); //* Велике об'єднання

    const findArr = megaState.filterLibraryInput.split(' '); //* Трансмутація пошук-речення в масив слів

    const searchExecution = findArr.every((value) => {
      const regex = new RegExp(value, 'i'); //* 'i' - ігнорує регістр
      return regex.test(text);
    }); //* Перевірка на існування кожного слова пошуку в тексті

    const checkFilter =
      megaState.filterLibraryInput !== '' //* Перевірка на пустоту
        ? searchExecution
        : true; //* Базовий список показати

    return checkFilter;
  }; //? Мега пошук

  const listToRender = list?.filter((i) => filterName(i)) ?? []; //? Мега пошук

  const getAllListBankCards = async () => {
    setMegaState((prev) => ({ ...prev, loading: true }));

    await request.post('/bankCards', {}, (req) => dispatch(setBankCardsList(req.cardList)), error);

    setMegaState((prev) => ({ ...prev, loading: false }));
  };

  useEffect(() => getAllListBankCards(), []);

  return (
    <section className="default_page library-page">
      <div ref={formRef} />
      <div className="library-page__header">
        <h2 className="library-page__title">Рахунки та Картки</h2>

        <MyRow style={{ justifyContent: 'center' }}>
          <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_BANK_CARDS }))}>
            <FileAddOutlined /> Додати банківський рахунок
          </Button>
        </MyRow>

        <br />
      </div>

      <Card>
        <Box mt={0} className={'library-page__filter-main'}>
          <Box mt={0}>
            <Box className={resize[0] >= 500 ? 'reach-filter-box-pc' : 'reach-filter-box-mob'}>
              <Text strong className="reach-filter-box-text">
                Пошук у всіх напрямках:
              </Text>
              <Input
                aria-label="Поле для вводу будь-яких критеріїв"
                placeholder="Введіть будь-які критерії"
                onChange={(e) => setMegaState((prev) => ({ ...prev, filterLibraryInput: e.target.value }))}
              />
            </Box>
          </Box>
        </Box>
      </Card>

      <Box mt={16}>
        <Spin tip="Завантаження" spinning={megaState.loading} size="large">
          {listToRender.length ? (
            <List
              className={'library-page__list-main'}
              pagination={{
                hideOnSinglePage: true,
                position: 'both',
                align: 'end',
                defaultCurrent: 1,
                total: listToRender.length ?? 1,
                defaultPageSize: 20,
                showTotal: (total, range) => `${range[0]}-${range[1]} з ${total} карток`,
              }}
              dataSource={listToRender ?? []}
              renderItem={(item) => <BankCardsItem item={item} key={item._id} />}
            ></List>
          ) : (
            <Empty description={<Text>Нема карток</Text>} />
          )}
        </Spin>
      </Box>
    </section>
  );
};

export default BankCards;
