import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, Spin, Modal } from 'antd';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { INCOME_TYPES, REPEATS } from 'common_constants/business';

import { setModal } from '../../store/commonReducer';
import { request, error } from '../../tools';
import Excel from '../../images/excel_logo.svg';

const ExportIncomes = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);

  const [loading] = useState(false);
  const [data, setData] = useState();

  const csvData = [['№ Договору', 'ID ПІБ Клієнта', 'Дата договору', 'Вид доходу', 'Дата - дата', 'Сума', 'Вніс', 'Дата внесення']];

  const onGenerate = () => {
    request.post(
      '/spreadsheets/incomesExport',
      {},
      (req) => {
        setData(req.data);
      },
      error,
    );
  };

  data?.forEach((i) => {
    const client = i.client?.[0] ?? {};

    csvData.push([
      i.i ?? '',
      `${client?.n ?? '<без імені>'} ${FILII[i.f] ?? ''} ${REPEATS[i.tm] ?? ''} ${i.od === 1 ? 'Дист' : 'Офіс'} ${i.ov1 ?? ''} ${i.ov2 ?? ''} ${
        i.ov3 ?? ''
      }`,
      dayjs(i.sd)?.format('DD-MM-YYYY') ?? '',

      INCOME_TYPES[i.incomes.type] ?? '',
      `${i?.incomes?.date ? dayjs(i.incomes.date)?.format('DD-MM-YYYY') : ''} - ${
        i.incomes.date2 ? dayjs(i.incomes.date2)?.format('DD-MM-YYYY') : ''
      }`,
      i.incomes.amount ?? '',
      users[i.incomes.author].p ?? '',
      dayjs(i.incomes.created)?.format('DD-MM-YYYY') ?? '',
    ]);
  });

  return (
    <Modal open className="export-hotline" title="Експорт доходів" onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="change">
          <br />
          {data ? (
            <CSVLink asyncOnClick={true} data={csvData}>
              <Button className="excel">
                <img className="excel-img" src={Excel} alt="Excel" /> Скачати .csv
              </Button>
            </CSVLink>
          ) : (
            <Button onClick={onGenerate}>Генерувати</Button>
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

export default ExportIncomes;
