import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/uk_UA';

import store from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

if (process.env.NODE_ENV === "development") {
  const varList = ['REACT_APP_NAME', 'REACT_APP_API']
  const envVars = Object.keys(process.env);
  const varsExist = varList.every(item => envVars.includes(item));

  if (!varsExist) {
    alert(`Перевірте наявність .env файлу з перемінними проекту: \n${varList.join(', ')}`)
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={locale}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
