import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { request, error } from '../../tools';
import { Button, DatePicker, Card, Form, Pagination, Spin, Typography, Table, Divider, Empty, Tabs, Row as AntdRow, Switch } from 'antd';
import { TableOutlined, MenuOutlined, DownOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Box } from 'common_components';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
import { DATA_PAGE_SIZE } from 'common_constants/db';
import dayjs from 'dayjs';

// import TabPane from 'antd/es/tabs/TabPane';
import PageInfo from '../../components/PageInfo/PageInfo';
import FondyHotlineInfo from './FondyHotlineInfo';
import './FondyHotlines.scss';

const FondyHotlines = () => {
  const mob = useSelector((state) => state.ui.mob);
  const FILII = useSelector((state) => state.common.FILII);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({ findBySugars: false });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState('allPN');
  const [displayMode, setDisplayMode] = useState('list');
  const [pageSize, setPageSize] = useState(DATA_PAGE_SIZE);

  const { Item } = Form;
  const { Text } = Typography;
  const { RangePicker } = DatePicker;
  const getFondyHotlinesData = (limits, activeTab) => {
    setLoading(true);
    const params = { ...limits, ...currentFilters, activeTab };

    request.post(
      '/hotline/findHotlinePayroll',
      params,
      ({ data, total }) => {
        // dispatch(setPushNotification(data));
        setTotal(total);
        setData(data);
        setLoading(false);
        console.log(data);
      },
      error,
    );
  };
  const handleFilterChange = (values) => {
    setCurrentFilters({ ...currentFilters, ...values });
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getFondyHotlinesData({ page, pageSize, ...currentFilters });
  };

  // const handleTabChange = (key) => {
  //   setActiveTab(key);
  //   setCurrentPage(1);
  // };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const expandable = {
    expandRowByClick: true,
    expandedRowRender: (item) => <FondyHotlineInfo key={item._id} data={item} />,
  };

  const columns = mob
    ? [
        Table.EXPAND_COLUMN,
        {
          title: 'Філія',
          dataIndex: 'fil',
          key: 'fil',
          render: (fil) => FILII[fil],
        },
        {
          title: 'Дата консультації',
          dataIndex: 'date',
          key: 'date',
          render: (date) => dayjs(date).format('DD.MM.YYYY'),
        },
        {
          title: 'Клієнт',
          dataIndex: 'name',
          key: 'name',
        },
      ]
    : [
        Table.EXPAND_COLUMN,
        // {
        //   title: '№',
        //   dataIndex: 'i',
        //   key: 'i',
        //   defaultSortOrder: 'descend',
        //   sorter: {
        //     compare: (a, b) => a.i - b.i,
        //   },
        // },
        {
          title: 'Філія',
          dataIndex: 'fil',
          key: 'fil',
          render: (fil) => FILII[fil],
        },
        {
          title: 'Дата консультації',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => dayjs(date).format('DD.MM.YYYY'),
        },
        {
          title: 'Клієнт',
          dataIndex: 'name',
          key: 'name',
        },
      ];

  useEffect(() => {
    const limits = { page: currentPage, pageSize, ...currentFilters };
    getFondyHotlinesData(limits);
  }, [currentPage, currentFilters]);

  return (
    <div className="fondy_hotlines_page default_page">
      <AntdRow justify={'center'} align={'middle'}>
        <h2>Перевірка оплат фонді консультацій</h2>
        <PageInfo page="pushNotification" />
      </AntdRow>
      {/* <Tabs className="tabs" activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Всі" key="allPN"></TabPane>
        <TabPane tab={`${currentFilters?.findBySugars ? 'Сахарок' : 'Гаманець'} знайдено`} key="withPayroll"></TabPane>
        <TabPane tab={`${currentFilters?.findBySugars ? 'Сахарок' : 'Гаманець'} не знайдено`} key="withoutPayroll"></TabPane>
      </Tabs> */}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: 6 }} onClick={() => setDisplayMode('list')} disabled={displayMode === 'list'}>
          <MenuOutlined />
        </Button>
        <Button onClick={() => setDisplayMode('table')} disabled={displayMode === 'table'}>
          <TableOutlined />
        </Button>
      </div>
      <br />

      <div className="row align filters" onClick={() => setFiltersOpen(!filtersOpen)}>
        {filtersOpen ? <DownOutlined /> : <RightOutlined />} <h2>Пошук</h2>
      </div>

      {filtersOpen && (
        <Card className="filtersContainer">
          <Form onFinish={handleFilterChange} layout="inline">
            <div style={{ width: '320px' }}>
              <Divider children={'Фільтри:'} style={{ margin: 0 }} orientation="left" />
              <Box>
                <Text>Філія: </Text> &nbsp;
                <Item name="fil" children={<SearchSelectFil />} />
              </Box>

              <Box>
                <Text>Період: </Text> &nbsp;
                <Item name="dateRange" children={<RangePicker format="DD.MM.YYYY" />} />
              </Box>

              <Box>
                <Button style={{ width: 150 }} type="primary" htmlType="submit">
                  Пошук <SearchOutlined />
                </Button>
              </Box>
            </div>
          </Form>
          <Switch onChange={(v) => handleFilterChange({ findBySugars: v })} checkedChildren="Через гаманець" unCheckedChildren="Через сахарок" />
        </Card>
      )}

      <Spin spinning={loading} tip="Зачекайте" size="large">
        {displayMode === 'list' ? (
          <>
            {data?.length ? (
              data?.map((item) => <FondyHotlineInfo key={item._id} data={item} />)
            ) : (
              <Empty description={<Text>Немає консультацій</Text>} />
            )}
            <Pagination style={{ textAlign: 'center' }} current={currentPage} pageSize={DATA_PAGE_SIZE} total={total} onChange={handlePageChange} />
          </>
        ) : (
          <Table
            dataSource={data}
            columns={columns}
            rowKey={(item) => item._id}
            loading={loading}
            expandable={expandable}
            pagination={{ total, current: currentPage }}
            onChange={handleTableChange}
          />
        )}
      </Spin>
    </div>
  );
};

export default FondyHotlines;
