import { Alert, Col, Tabs, Typography } from 'antd';
import './Questionnaires.scss';
import TabPane from 'antd/es/tabs/TabPane';
import PageInfo from '../../components/PageInfo/PageInfo';
import { ContractQuestionnaires, HotlineQuestionnaires, InterimQualityAssessmentQuestionnaires } from './tabs';

const Questionnaires = () => {
  const handleTabChange = (key) => {
    console.log(key);
  };
  return (
    <Col className="questionnaires">
      <Typography.Title style={{ textAlign: 'center', marginBottom: '40px' }} level={4}>
        Оцінка якості послуг
        <PageInfo page="questionnaires" />
      </Typography.Title>
      <Alert message="Ми не кажемо клієнтам, що маємо доступ до інформації на цій сторінці." type="warning" showIcon style={{ margin: '20px' }} />
      <Tabs centered className="tabs" defaultActiveKey="0" type="card" onChange={handleTabChange}>
        <TabPane className="tab-pane" tab="Консультації" key="0">
          <HotlineQuestionnaires />
        </TabPane>
        <TabPane className="tab-pane" tab="Договори" key="1">
          <ContractQuestionnaires />
        </TabPane>
        <TabPane className="tab-pane" tab="Проміжне анкетування" key="2">
          <InterimQualityAssessmentQuestionnaires />
        </TabPane>
      </Tabs>
    </Col>
  );
};

export default Questionnaires;
