import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'antd';
import { ROLES, CHAT_TYPES } from 'common_constants/business';
import { CLIENT_INFO } from 'common_constants/modals';
import { setModal, setContractsWithoutBlockCount } from '../../store/commonReducer';
import { request } from '../../tools';
import { ContractsSearchByClient, ContractsSearch, ExportRow, ContractsWithoutBlockMsg, ContractsFilterByEAdvokatChanges } from './ContractsPartials';
import { getColumns, getAllContracts } from './ContractsHelpers';
import PageInfo from '../../components/PageInfo';
import ContractInfo from './ContractInfo';

import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

import './Contracts.scss';

const Contracts = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.common.contracts);
  const mob = useSelector((state) => state.ui.mob);
  const users = useSelector((state) => state.common.users || []);
  const FILII = useSelector((state) => state.common.FILII);
  const userAuth = useSelector((state) => state.common.userAuth);
  const unreadedMessagesData = useSelector((state) => state.common.unreadedMessagesData);
  const contractsWithoutBlockCount = useSelector((state) => state.common.contractsWithoutBlockCount);

  const inspector = ROLES[userAuth.role] <= ROLES.inspector;

  const [fil, setFil] = useState(inspector ? '' : +userAuth.fil);
  const [number, setNumber] = useState();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [filters, setFilters] = useState(true);
  const [filtersByClient, setFiltersByClient] = useState(false);
  const [isFiltersByEAdvokatChangesOpen, setIsFiltersByEAdvokatChangesOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mPaymentFilter, setMPaymentFilter] = useState(false);
  const [onlyArchived, setOnlyArchived] = useState(false);
  const [onlyWithVotes, setOnlyWithVotes] = useState(false);
  const [total, setTotal] = useState(0);
  const [tasksCountSort, setTasksCountSort] = useState(0);
  const [dateOfSigningSort, setDateOfSigningSort] = useState(-1);
  const [onlyRedButton, setOnlyRedButton] = useState(false);
  const [onlyNotActualized, setOnlyNotActualized] = useState(false);
  const [insuranceSort, setInsuranceSort] = useState(false);
  const [withoutAnAssignedBlock, setWithoutAnAssignedBlock] = useState(false);
  const [autoPayFilter, setAutoPayFilter] = useState(false);
  const [eAdvokat, setEAdvokat] = useState(false);
  const [noEAdvokat, setNoEAdvokat] = useState(false);
  const [clientSearched, setClientSearched] = useState(false);
  const [monthlyPaymentsDebt, setMonthlyPaymentsDebt] = useState(false);
  const [block, setBlock] = useState('');

  const [filtersByEAdvokatChanges, setFiltersByEAdvokatChanges] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const [sortingEAdvokatChanges, setSortingEAdvokatChanges] = useState({
    name: 0,
    email: 0,
    phone: 0,
  });

  const [contractsFilters, setContractsFilters] = useState({
    fil,
    block,
    page: 1,
    mPaymentFilter,
    onlyArchived,
    onlyWithVotes,
    searchByClient: false,
    tasksCountSort,
    dateOfSigningSort,
    onlyRedButton,
    onlyNotActualized,
    insuranceSort,
    withoutAnAssignedBlock,
    autoPayFilter,
    eAdvokat,
    noEAdvokat,
    clientSearched,
    monthlyPaymentsDebt,
    changeName: filtersByEAdvokatChanges.name,
    changeEmail: filtersByEAdvokatChanges.email,
    changePhone: filtersByEAdvokatChanges.phone,
    changeNameSorting: sortingEAdvokatChanges.name,
    changeEmailSorting: sortingEAdvokatChanges.email,
    changePhoneSorting: sortingEAdvokatChanges.phone,
    searchByEAdvokatChanges: false,
  });

  const handleGetAllContracts = () => {
    setContractsFilters({
      fil,
      block,
      number,
      page: 1,
      mPaymentFilter,
      onlyArchived,
      onlyWithVotes,
      tasksCountSort,
      dateOfSigningSort,
      onlyRedButton,
      onlyNotActualized,
      insuranceSort,
      withoutAnAssignedBlock,
      autoPayFilter,
      eAdvokat,
      noEAdvokat,
      clientSearched,
      monthlyPaymentsDebt,
    });
  };

  const handleGetContractsByClient = () => {
    setContractsFilters({
      phone,
      name,
      page: 1,
      searchByClient: true,
    });
  };

  const handleGetContractsByEAdvokatChanges = () => {
    setContractsFilters({
      changeName: filtersByEAdvokatChanges.name,
      changeEmail: filtersByEAdvokatChanges.email,
      changePhone: filtersByEAdvokatChanges.phone,
      changeNameSorting: sortingEAdvokatChanges.name,
      changeEmailSorting: sortingEAdvokatChanges.email,
      changePhoneSorting: sortingEAdvokatChanges.phone,
      searchByEAdvokatChanges: true,
    });
  };

  const handleGetContractsByClientInTable = (name, phone) => {
    setContractsFilters({
      phone,
      name,
      page: 1,
      searchByClient: true,
    });
  };

  const handleTableChange = (pagination) => {
    setContractsFilters({ ...contractsFilters, page: pagination.current });
  };

  const onClientBtnClick = (e, data) => {
    e.stopPropagation();
    data && dispatch(setModal({ name: CLIENT_INFO, data, fromContracts: true }));
  };

  const getContractUnreadedMessages = (contractId) => {
    return unreadedMessagesData?.find((item) => item.chatType === CHAT_TYPES.contractChat.key && item.chatId === contractId)?.total ?? 0;
  };

  const getContractChatWithClientUnreadedMessages = (contractId) => {
    return unreadedMessagesData?.find((item) => item.chatType === CHAT_TYPES.chatWithClient.key && item.chatId === contractId)?.total ?? 0;
  };

  useEffect(() => {
    getAllContracts(setLoading, contractsFilters, dispatch, setTotal);
  }, [contractsFilters]);

  useEffect(() => {
    if (inspector) {
      request.post('/contracts/getContractsWithoutBlockNumber', {}, ({ data }) => {
        dispatch(setContractsWithoutBlockCount(data));
      });
    }
  }, []);

  const columns = getColumns(
    onClientBtnClick,
    mob,
    users,
    FILII,
    getContractUnreadedMessages,
    getContractChatWithClientUnreadedMessages,
    handleGetContractsByClientInTable,
    setName,
    setPhone,
  );

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);

    setPhone(validPhoneNumber);
  };

  const sortedContracts = list?.map((i) => i);

  const blockNotification = inspector && !!contractsWithoutBlockCount && <ContractsWithoutBlockMsg numberWithoutBlock={contractsWithoutBlockCount} />;

  return (
    <div className="contracts_page default_page">
      <h2>
        Договори{' '}
        <div style={{ marginTop: '0.1vw' }}>
          <PageInfo />
        </div>
      </h2>

      <br />
      <ContractsSearch
        filters={filters}
        tasksCountSort={tasksCountSort}
        dateOfSigningSort={dateOfSigningSort}
        setFilters={setFilters}
        fil={fil}
        setFil={setFil}
        number={number}
        setNumber={setNumber}
        setMPaymentFilter={setMPaymentFilter}
        setOnlyArchived={setOnlyArchived}
        getAllContracts={handleGetAllContracts}
        setOnlyWithVotes={setOnlyWithVotes}
        setTasksCountSort={setTasksCountSort}
        setDateOfSigningSort={setDateOfSigningSort}
        setOnlyRedButton={setOnlyRedButton}
        setOnlyNotActualized={setOnlyNotActualized}
        setInsuranceSort={setInsuranceSort}
        setWithoutAnAssignedBlock={setWithoutAnAssignedBlock}
        setAutoPayFilter={setAutoPayFilter}
        setEAdvokat={setEAdvokat}
        setNoEAdvokat={setNoEAdvokat}
        setClientSearched={setClientSearched}
        setMonthlyPaymentsDebt={setMonthlyPaymentsDebt}
        block={block}
        setBlock={setBlock}
      />
      <ContractsSearchByClient
        filtersByClient={filtersByClient}
        setFiltersByClient={setFiltersByClient}
        name={name}
        setName={setName}
        phone={phone}
        handlePhoneNumberInput={handlePhoneNumberInput}
        searchByClient={handleGetContractsByClient}
      />
      <ContractsFilterByEAdvokatChanges
        isFiltersByEAdvokatChangesOpen={isFiltersByEAdvokatChangesOpen}
        setIsFiltersByEAdvokatChangesOpen={setIsFiltersByEAdvokatChangesOpen}
        filtersByEAdvokatChanges={filtersByEAdvokatChanges}
        setFiltersByEAdvokatChanges={setFiltersByEAdvokatChanges}
        sortingEAdvokatChanges={sortingEAdvokatChanges}
        setSortingEAdvokatChanges={setSortingEAdvokatChanges}
        searchByEAdvokatChanges={handleGetContractsByEAdvokatChanges}
      />
      {inspector && <ExportRow />}
      {blockNotification}
      <Table
        dataSource={sortedContracts}
        rowKey={(item) => item._id}
        loading={loading}
        columns={columns}
        e
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          expandedRowRender: (item, i, indent, expended) => expended && <ContractInfo data={item} withInsurance={item.insurance} />,
        }}
        pagination={{ total, current: contractsFilters.page }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Contracts;
