import React from 'react';
import { Select } from 'antd';

const billSelectGeneralConditionHotline = [
  { true: 'Наявні', disabled: 'Видалені', e_disabled: 'Відредаговані та видалені' },
  ['Стан загальний', 'statusH'],
  ['Стан загальний по консультації', 'statusH_H'],
];

const SelectGeneralConditionHotline = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть загальний стан"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі загальні стани</Option>
      <Option value="true">Наявні</Option>
      <Option value="disabled">Видалені</Option>
      <Option value="e_disabled">Відредаговані та видалені</Option>
    </Select>
  );
};

export { billSelectGeneralConditionHotline, SelectGeneralConditionHotline };
