import React from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';

import InsuranceTransactionModal from '../InsuranceTransactionModal';
import InsuranceTransactionHistory from '../InsuranceTransactionHistory';

const InsurancePart = (props) => {
  const insuranceTypes = useSelector((state) => state.common.allInsuranceTypes);

  const findInsurance = (transactionInsurance) => {
    const insuranceObject = insuranceTypes.find((item) => item.i === Number(transactionInsurance));
    return insuranceObject || { name: 'Не визначено', services: [{ name: 'Не визначено', cost: 0 }] };
  };

  const {
    contractId,
    clientId,
    insurances,
    handleChangeInsurances,
    insuranceTransactionState,
    handleChangeInsuranceTransactionState,
    insuranceTransactionHistoryState,
    handleChangeInsuranceTransactionHistoryState,
  } = props;
  return (
    <>
      <InsuranceTransactionModal
        contractId={contractId}
        clientId={clientId}
        insurances={insurances}
        findInsurance={findInsurance}
        insuranceTransactionState={insuranceTransactionState}
        handleChangeInsuranceTransactionState={handleChangeInsuranceTransactionState}
        handleChangeInsurances={handleChangeInsurances}
      />
      <InsuranceTransactionHistory
        insurance={insurances}
        findInsurance={findInsurance}
        insuranceTransactionHistoryState={insuranceTransactionHistoryState}
        handleChangeInsuranceTransactionHistoryState={handleChangeInsuranceTransactionHistoryState}
      />
    </>
  );
};

export default InsurancePart;
