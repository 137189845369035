import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Card } from 'antd';
import './BirthTable.scss';

const BirthTable = () => {
  const allUsers = useSelector((state) => state.common.users);

  const currentDate = dayjs();
  const currentYear = new Date().getFullYear();

  const users = useMemo(() => {
    return Object.values(allUsers)
      .filter((user) => {
        if (!user.birthDate || user.fired) return false;
        const diff = dayjs(user.birthDate).set('year', currentYear).diff(currentDate, 'day');
        return diff >= 0 && diff < 30;
      })
      .sort((a, b) => {
        const diffA = dayjs(a.birthDate).set('year', currentYear).diff(currentDate, 'day');
        const diffB = dayjs(b.birthDate).set('year', currentYear).diff(currentDate, 'day');
        return diffA - diffB;
      });
  }, [allUsers]);

  return (
    <Card title="Найближчі дні народження">
      {!users.length && <p>Днів народжень на найближчі 30 днів немає</p>}

      {users.map((item, i) => (
        <div className=" full" key={item._id}>
          {item.name}
          &nbsp;&nbsp;&nbsp;
          {dayjs(item.birthDate).format('DD MMM')}
        </div>
      ))}
    </Card>
  );
};

export default BirthTable;
