import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import ExportQuantityModal from '../../components/ExportQuantityModal';
import { request, warn, error, success } from '../../tools';

import { TYPES_OF_MAIL, LAW_BLOCKS, PRAVO, REPEATS, JOBS, SOC_STATUS, WORK_STATES, FORM_OF_BUSINESS } from 'common_constants/business';

const ExportCorrespondence = () => {
  const [users, contracts, FILII] = [
    useSelector((state) => state.common.users),
    useSelector((state) => state.common.contracts),
    useSelector((state) => state.common.FILII),
  ];

  const max = contracts[0]?.i;

  const [start, setStart] = useState(1),
    [end, setEnd] = useState(max),
    [loading, setLoading] = useState(false),
    [data, setData] = useState();

  const onGenerate = () => {
    const validation = () => {
      if (!start || !end) {
        warn('', 'Для експорту таблиці, потрібно вказати діапазон кількості елементів', { '!!start': !!start, '!!end': !!end });

        return;
      }

      return true;
    };

    if (!validation()) return;

    setLoading(true);

    const transaction = {
      start,
      end,
    };

    request.post(
      '/spreadsheets/exportCorrespondence',
      transaction,
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };

  const typePost = (value) => {
    const type = TYPES_OF_MAIL.find((item) => item.value === Number(value));

    return type ? type.label : TYPES_OF_MAIL[TYPES_OF_MAIL.length - 1].label;
  };

  const csvData = [
    [
      '№ Пошти',
      'Дата відправлення/отримання пошти',
      'Дата створення пошти в CRM',
      'Автор пошти',
      'Напрямок пошти',
      'Вид пошти',
      'Адресат',
      'Чек',
      'Фото чеку',
      'Трек-номер',
      'Cума на чеку',
      'Завантаженні файли (кількість)',
      'Коментар',
      'Мітка направлення в особистий кабінет клієнта',
      // ?
      '№ Договору',
      'Філія договору',
      'Вид правовідносин Що саме',
      'Уклав договір',
      'Призначений блок договору',
      'Призначені люди договору (Блок)',
      'Призначені люди договору (Філія)',
      '№ Архіву',
      // ?
      '№ Клієнта',
      'ID ПІБ Клієнта',
      'Телефон',
      'ФО/ФОП/ЮО',
      'Професія',
      'Соц. статус',
      'Службове становище',
    ],
  ];

  data?.forEach((i) => {
    const [contract, client] = [i.contract?.[0] ?? {}, i.contract?.[0]?.client?.[0] ?? {}];

    if (+contract.i < +start) return;
    if (+contract.i > +end) return;

    csvData.push([
      i.i >= 0 ? i.i : '', // * '№ Пошти'
      i.t ? dayjs(i.t)?.format('DD.MM.YYYY') ?? '' : '', // * 'Дата відправлення/отримання пошти'
      i.cr ? dayjs(i.cr)?.format('DD.MM.YYYY') ?? '' : '', // * 'Дата створення пошти в CRM'
      users && i.a ? `${users[i.a]?.p}${users[i.a]?.fired ? ' (Звільнений)' : ''}` : '', // * Автор пошти
      {
        1: 'Вихідна',
        2: 'Вхідна',
      }[i.o] || '-', //* Напрямок пошти
      i.p ? typePost(i.p) : '', //* Вид пошти
      i.h ?? '', //* Адресат
      i.j ? 'Є' : '', //* Чек
      i.ch?.rc?.L ? 'Є' : '', //* Фото чеку
      i.ch?.tn ?? '', //* Трек-номер
      i.ch?.b >= 0 ? i.ch?.b : '', //* Cума на чеку
      i.df?.length ?? '', //* Завантаженні файли (кількість)
      i.m ?? '', //* Коментар
      i.x ? 'Є' : '', //* Мітка направлення в особистий кабінет клієнта
      // ?
      contract.i >= 0 ? contract.i : '', // * '№ Договору'
      contract.f >= 0 ? FILII?.[contract.f] ?? '' : '', //* 'Філія договору'
      PRAVO[contract.prc] ?? '', //* Вид правовідносин Що саме
      users && contract.us ? `${users[contract.us]?.p}${users[contract.us]?.fired ? ' (Звільнений)' : ''}` : '', // * Уклав договір
      contract.block ? LAW_BLOCKS?.[contract.block] ?? '' : '', //* 'Призначений блок договору'
      contract.accessBlock && users
        ? Object.values(users)
            ?.filter((item) => contract.accessBlock?.includes(item?._id))
            ?.map((user) => `${user.p}${user.fired ? ' (Звільнений)' : ''}`)
            ?.join(', ') ?? ''
        : '', //* Призначені люди договору (Блок)
      contract.access && users
        ? Object.values(users)
            ?.filter((item) => contract.access?.includes(item?._id))
            ?.map((user) => `${user.p}${user.fired ? ' (Звільнений)' : ''}`)
            ?.join(', ') ?? ''
        : '', //* Призначені люди договору (Філія)
      contract.ad && contract.i ? 'a/' + dayjs(contract.ad)?.format('DD-MM-YYYY') + '/' + contract.i : '', // * В архіві договір?
      // ?
      client.i >= 0 ? client.i : '', // * '№ Клієнта'
      [
        client?.n ?? '<без імені>',
        FILII[contract.f] ?? '',
        REPEATS[contract.tm] ?? '',
        contract.od === 1 ? 'Дист' : 'Офіс',
        contract.ov1 ?? '',
        contract.ov2 ?? '',
        contract.ov3 ?? '',
      ]
        ?.filter((item) => item !== '')
        ?.join(', ') ?? '', // * 'ID ПІБ Клієнта'
      client.ph ?? '', // * 'Телефон'
      FORM_OF_BUSINESS[client.u] ?? '', // * ФО/ФОП/ЮО
      JOBS[client?.j] ?? '', //* Професія
      SOC_STATUS[client?.ss] ?? '', //* Соц статус
      WORK_STATES[client?.ws] ?? '', //* Службове становище
    ]);
  });

  return (
    <ExportQuantityModal
      title={'Експорт кореспонденції'}
      csvData={csvData}
      onGenerate={onGenerate}
      max={max}
      data={!!data}
      loading={loading}
      startA={start}
      setStartA={setStart}
      endA={end}
      setEndA={setEnd}
    />
  );
};

export default ExportCorrespondence;
