import { Button, Popconfirm } from 'antd';
import { error, info, request, success, warn } from '../../tools';
import { useSelector } from 'react-redux';
import { ROLES } from 'common_constants/business';

const BlockBtn = ({ data, phone, blocked, modal, updateRecord, myStyle }) => {
  const userAuth = useSelector((state) => state.common.userAuth);
  const rabfil = ROLES[userAuth.role] >= ROLES.rabfil;

  const cancel = () => {
    info('', 'Ви не згодні зі своїм рішенням.');
  };

  const handleUserUnblock = async () => {
    const updatedRecord = { ...data, bl: false };
    const validation = (_phone) => {
      if (!_phone) {
        warn('', 'Для розблокування телефону, повинен бути у клієнта вказаний номер телефону.', { _phone: !!_phone });
        return;
      }

      return true;
    };

    if (!validation(phone)) return;

    const transaction = {
      phone: phone,
      countryPhCode: data.countryPhCode,
    };

    // setLoading(true);

    await request.post(
      '/block/blockDelete',
      transaction,
      () => {
        updateRecord(updatedRecord);
        success('', `Телефон було успішно розблоковано.`);
      },
      error,
    );

    // setLoading(false);
  };

  return blocked ? (
    <Popconfirm title="Ви впевнені?" description={`Номер телефону буде розблоковано!`} onConfirm={handleUserUnblock} onCancel={cancel}>
      <Button className={`search-certificates ${myStyle ? myStyle : ''}`} type="primary" danger={!blocked}>
        Розблокувати телефон
      </Button>
    </Popconfirm>
  ) : (
    <Button disabled={rabfil} className={`search-certificates ${myStyle ? myStyle : ''}`} type="primary" danger={!blocked} onClick={modal}>
      Заблокувати телефон
    </Button>
  );
};
export default BlockBtn;
