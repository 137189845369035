import { Collapse } from 'antd';

import { DECLINE_REASONS, HOTLINE_RESULT, HOTLINE_STATUS } from 'common_constants/business';

const { Panel } = Collapse;

const HotlineMeetDetails = (props) => {
  const hotline = props.hotline ?? {};

  return (
    <>
      <Collapse className="journal-card-body__item" style={{ marginTop: '4px' }}>
        <Panel header="Деталі зустрічі">
          <div className="journal-card-body__meet-result">
            <div className="journal-card-body__item_meet">
              <span className="journal-card-body__subtitle">Статус: </span>
              {HOTLINE_STATUS[hotline.s] ?? '-'}
            </div>
            {hotline.s !== '2' ? (
              <div className="journal-card-body__item_meet">
                <span className="journal-card-body__subtitle">Договір: </span>
                {HOTLINE_RESULT[hotline.amr] ?? '-'}
              </div>
            ) : (
              false
            )}
            <div className="journal-card-body__item_meet">
              <span className="journal-card-body__subtitle">Названа сума: </span>
              {hotline.amp ?? '-'}
            </div>
            {hotline.s === '2' ? (
              <>
                <div className="journal-card-body__item_meet-reason">
                  <span className="journal-card-body__subtitle">Причина відмови:</span>
                  <div>
                    {hotline.meetDeclineReason.reason === 0 ? hotline.meetDeclineReason.ownReason : DECLINE_REASONS[hotline.meetDeclineReason.reason]}
                  </div>
                </div>
                <div className="journal-card-body__item_meet">
                  <span className="journal-card-body__subtitle">Чи перенесено зістріч: </span>
                  {hotline.meetDeclineReason.moved ? 'так' : 'ні'}
                </div>
              </>
            ) : (
              false
            )}
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default HotlineMeetDetails;
