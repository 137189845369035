import React from 'react';
import { SERVIS_OPTIONS } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralServesTheBlock = [SERVIS_OPTIONS, ['Обслуговує блок по договору', 'un_K']];

const SelectGeneralServesTheBlock = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть блок"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі блоки</Option>
      {SERVIS_OPTIONS.map((i, index) => (
        <Option key={i} value={String(index)}>
          {i}
        </Option>
      ))}
    </Select>
  );
};

export { billSelectGeneralServesTheBlock, SelectGeneralServesTheBlock };
