import { Select } from 'antd';
import { PRAVO } from 'common_constants/business';
import React from 'react';

export default function SelectPravo({ data, setData, pravoRating }) {
  return (
    <Select
      style={{ width: '100%', marginBottom: '10px' }}
      showSearch
      value={PRAVO[data.pravo]}
      placeholder="Оберіть зі списку вид консультації"
      optionFilterProp="children"
      onChange={(value) => {
        setData({ ...data, pravo: String(value) });
      }}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
    >
      {PRAVO.filter((i) => i !== '-').map((i) => {
        const originalIndex = PRAVO.indexOf(i);
        const isDisabled = pravoRating.find((item) => item.pravo === String(originalIndex))?.infoCenter ?? false;
        return (
          <Select.Option key={originalIndex} value={originalIndex} disabled={data.type === '6' && isDisabled}>
            {i}
          </Select.Option>
        );
      })}
    </Select>
  );
}
