import React from 'react';
import { CLUB_CARDS_TYPES } from 'common_constants/business';
import SearchSelect from './SearchSelect';

const SearchSelectClubCardType = ({ onChange, value, className, style }) => {
  return (
    <SearchSelect
      className={className}
      style={style}
      placeholder="Оберіть тип карти"
      list={Object.keys(CLUB_CARDS_TYPES)
        .filter((i) => i !== CLUB_CARDS_TYPES.SILVER)
        .map((i) => ({ value: i, label: i }))}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectClubCardType;
