import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Input, Spin, Button, Select, Popconfirm } from 'antd';
import { error, success, request } from '../../tools';
import ReactQuill, { Quill } from 'react-quill';
import { htmlEditButton } from 'quill-html-edit-button';
import 'react-quill/dist/quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';
import './NewsAddForm.scss';
import CustomImage from './TextEditor';
import NewsPhotoUpload from './NewsPhotoUpload';
import { CATEGORIES, SUBCATEGORIES, ROLES } from 'common_constants/business';
import { languageOptions } from 'common_constants/translation';
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register('modules/htmlEditButton', htmlEditButton);
Quill.register({ 'formats/image': CustomImage });

const NewsAddForm = ({ data, setWebsiteNews, websiteNews, setAddNews }) => {
  const userAuth = useSelector((state) => state.common.userAuth);
  const [disabled, setDisabled] = useState(!!data);
  const [value, setValue] = useState(data ? data.value : '');
  const [title, setTitle] = useState(data ? data.title : '');
  const [descr, setDescr] = useState(data ? data.descr : '');
  const [metaTitle, setMetaTitle] = useState(data ? data.metaTitle : '');
  const [metaDescr, setMetaDescr] = useState(data ? data.metaDescr : '');
  const [loading, setLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(data ? data.selectedCategories : []);
  const [selectedSubcategories, setSelectedSubcategories] = useState(data ? data.selectedSubcategories : []);
  const [imageUrl, setImageUrl] = useState(data?.image && data?.ext ? process.env.REACT_APP_API + `/websiteImages/${data.image}.${data.ext}` : null);
  const [file, setFile] = useState();
  const CategoriesOptions = Object.values(CATEGORIES);
  const filteredSubcategoriesOptions = SUBCATEGORIES.filter((item) => selectedCategories.includes(item.name));
  const { role } = userAuth;
  const admin = ROLES[role] === ROLES.admin;
  const [language, setLanguage] = useState(data ? data.language : 'ua');

  const validation = () => {
    if (!value || !title || !descr) {
      return false;
    }
    return true;
  };

  // const [action, setAction] = useState();
  const ruKeys = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'h',
    ґ: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'j',
    з: 'z',
    и: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'kh',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'shch',
    ы: 'y',
    э: 'e',
    ю: 'u',
    я: 'ya',
    й: 'i',
    ъ: '',
    ь: '',
    і: 'i',
    ї: 'yi',
    є: 'ye',
    a: 'a',
    b: 'b',
    c: 'c',
    d: 'd',
    e: 'e',
    f: 'f',
    g: 'g',
    h: 'h',
    i: 'i',
    j: 'j',
    k: 'k',
    l: 'l',
    m: 'm',
    n: 'n',
    o: 'o',
    p: 'p',
    q: 'q',
    r: 'r',
    s: 's',
    t: 't',
    u: 'u',
    v: 'v',
    w: 'w',
    x: 'x',
    y: 'y',
    z: 'z',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    0: '0',
  };

  function transliterate(word) {
    return word
      .split('')
      .map((letter) => {
        const lowLetter = letter.toLowerCase();
        const en = ruKeys[lowLetter] ?? '-';
        return en;
      })
      .join('');
  }

  const handleSaveNews = () => {
    if (!validation()) {
      error("Заповніть всі обов'язкові поля", ' ');
      return;
    }
    setLoading(true);
    const data = {
      value,
      title,
      descr,
      metaTitle,
      metaDescr,
      selectedCategories,
      selectedSubcategories,
      link: transliterate(title),
      author: userAuth._id,
      language,
    };
    request.post(
      '/websiteNews/post',
      data,
      (res) => {
        if (res.status) {
          if (file) {
            addNewsImage(file, res.data.insertedId, data, true);
          } else {
            setLoading(false);
            setWebsiteNews([{ ...data, _id: res.data.insertedId }, ...websiteNews]);
            setAddNews(false);
          }
        }
      },
      () => {
        error();
        setLoading(false);
      },
    );
    return;
  };

  const handleEditNews = () => {
    if (!validation()) {
      error("Заповніть всі обов'язкові поля", ' ');
      return;
    }
    setLoading(true);
    const updateData = {
      ...data,
      value,
      title,
      descr,
      metaTitle,
      metaDescr,
      selectedCategories,
      selectedSubcategories,
      link: transliterate(title),
      author: userAuth._id,
      published: false,
      language,
    };
    request.post(
      '/websiteNews/edit',
      updateData,
      (res) => {
        if (res.status) {
          //   success();
          setWebsiteNews(
            websiteNews.map((item) => {
              return item._id === updateData._id ? updateData : item;
            }),
          );
          setDisabled(true);
          if (file) {
            addNewsImage(file, updateData._id, data, false);
          } else {
            success();
            setLoading(false);
          }
        }
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  const options = filteredSubcategoriesOptions.map((category) => {
    return {
      label: category.name,
      options: category.subcategories.map((item) => {
        return { label: item, value: item };
      }),
    };
  });

  const addNewsImage = (file, id, data, createNew) => {
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('_id', id);
    if (data?.image) {
      formData.append('prevAvatarId', data.image);
      formData.append('extension', data.ext);
    }

    request.post(
      '/websiteNews/uploadImage',
      formData,
      (res) => {
        if (res.status) {
          success();
          setLoading(false);
          if (createNew) {
            setWebsiteNews([...websiteNews, { ...data, _id: id, ext: res.ext, image: res.image }]);
            setAddNews(false);
          } else {
            setWebsiteNews(websiteNews.map((item) => (item._id === res._id ? { ...item, ext: res.ext, image: res.image, published: false } : item)));
          }

          setImageUrl(process.env.REACT_APP_API + `/websiteImages/${res.image}.${res.ext}`);
          setFile('');
        }
      },
      (err) => {
        console.log(err);
        error('Сталася помилка');
        setLoading(false);
      },
    );
  };

  const publishNews = () => {
    setLoading(true);
    const updateData = {
      id: data._id,
      published: data?.published,
      userId: userAuth._id,
      language,
    };
    request.post(
      '/websiteNews/publishOrArchive',
      updateData,
      (res) => {
        if (res.status) {
          success();
          setLoading(false);
          setWebsiteNews(
            websiteNews.map((item) => {
              return item._id === updateData.id ? { ...item, published: !updateData?.published } : item;
            }),
          );
          setDisabled(true);
        }
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  const deleteNewsImage = (data) => {
    const onSuccess = () => {
      success('Успіх!', 'Фото новини видалено успішно!');
    };
    const onError = () => error('Помилка!', 'Схоже, не вдалося видалити фото новини!');
    request.post('/websiteNews/deleteImage', data, onSuccess, onError);
  };

  const handleDeleteNews = () => {
    deleteNewsImage({ id: data._id, userId: userAuth._id });
    deleteNewsContent();
  };

  const deleteNewsContent = () => {
    setLoading(true);
    const deleteData = {
      _id: data._id,
      userId: userAuth._id,
    };
    request.post(
      '/websiteNews/delete',
      deleteData,
      (res) => {
        if (res.status) {
          success();
          setLoading(false);
          setWebsiteNews(websiteNews.filter((item) => item._id !== deleteData._id));
        }
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    htmlEditButton: {},
    blotFormatter: {},
  };

  return (
    <Card className="news-add-form">
      <Spin spinning={loading} tip="Зачекайте" size="large">
        <h3>Виберіть мову новини</h3>
        <Select
          size="large"
          placeholder="Виберіть мову"
          value={language}
          onChange={setLanguage}
          style={{
            width: '100%',
          }}
          options={languageOptions}
        />
        <h3>
          Заголовок <span>(обов'язково)</span> {title?.length} / 75
        </h3>
        <Input.TextArea disabled={disabled} defaultValue={title} onChange={(e) => setTitle(e.target.value)} />
        <h3>Мета тег заголовок</h3>
        <Input.TextArea disabled={disabled} defaultValue={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
        <h3>Мета тег опис</h3>
        <Input.TextArea disabled={disabled} defaultValue={metaDescr} onChange={(e) => setMetaDescr(e.target.value)} />
        <h3>Фото</h3>
        <NewsPhotoUpload disabled={disabled} setFile={setFile} imageUrl={imageUrl} setImageUrl={setImageUrl} />
        <h3>
          Короткий опис <span>(обов'язково)</span> {descr?.length} / 85
        </h3>
        <Input.TextArea disabled={disabled} defaultValue={descr} onChange={(e) => setDescr(e.target.value)} />
        <h3>Категорії</h3>
        <Select
          mode="multiple"
          size="large"
          placeholder="Додайте категорії"
          value={selectedCategories}
          onChange={setSelectedCategories}
          style={{
            width: '100%',
          }}
          options={CategoriesOptions.map((item) => ({
            value: item,
            label: item,
          }))}
        />
        <h3>Підкатегорії</h3>
        <Select
          size="large"
          mode="multiple"
          placeholder="Додайте підкатегорії"
          value={selectedSubcategories}
          onChange={setSelectedSubcategories}
          style={{
            width: '100%',
          }}
          options={options}
        />
        <h3>
          Новина <span>(обов'язково)</span>
        </h3>
        <ReactQuill readOnly={disabled} style={{ background: 'gray' }} theme="snow" value={value} onChange={setValue} modules={modules} />
        <Popconfirm
          onConfirm={() => {
            if (data) {
              disabled ? setDisabled(false) : handleEditNews();
            } else {
              handleSaveNews();
            }
          }}
          okText="Так"
          cancelText="Ні"
          title="Редагування новини"
          description={`Ви дійсно хочете ${disabled ? 'редагувати новину?' : 'зберегти відредаговану новину?'}`}
        >
          <Button className="button button-send" type="primary">
            {disabled ? 'Редагувати' : 'Зберегти'}
          </Button>
        </Popconfirm>
        {data && (admin || userAuth?.siteEdit) && (
          <Popconfirm
            onConfirm={handleDeleteNews}
            okText="Так"
            cancelText="Ні"
            title="Видалення новини"
            description="Ви дійсно хочете видалити дану новину?"
          >
            <Button className="button button-delete" danger type="primary">
              Видалити
            </Button>
          </Popconfirm>
        )}

        {data && (admin || userAuth?.siteEdit) && (
          <Popconfirm
            onConfirm={publishNews}
            okText="Так"
            cancelText="Ні"
            title={data?.published ? 'Опублікувати' : 'Архівувати'}
            description={`Ви дійсно хочете ${data?.published ? 'архівувати новину?' : 'опублікувати новину?'}`}
          >
            <Button type="primary" className="button button-archive">
              {data?.published ? 'Архівувати' : 'Опублікувати'}
            </Button>
          </Popconfirm>
        )}
      </Spin>
    </Card>
  );
};

export default NewsAddForm;
