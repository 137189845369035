import { useState } from 'react';
import { Modal, Button, Select, List } from 'antd';
import dayjs from 'dayjs';

import { getFormattedDateWithRelativeDays } from 'common_components';

import './InsuranceTransactionHistory.scss';

const INSURANCE_PAGE_SIZE = 5;

const InsuranceTransactionHistory = (props) => {
  const { insurance, findInsurance, insuranceTransactionHistoryState, handleChangeInsuranceTransactionHistoryState } = props;
  const [currentInsurance, setCurrentInsurance] = useState(Object.keys(insurance)[0]);
  const [pageIndex, setPageIndex] = useState(0);

  const selectedInsurance = findInsurance(currentInsurance);

  const handleChandeCurrentInsurance = (value) => setCurrentInsurance(value);

  const dataSource = insurance[currentInsurance].transactions?.slice().reverse();

  return (
    <Modal
      title="Історія страхових транзакцій"
      open={insuranceTransactionHistoryState}
      onCancel={handleChangeInsuranceTransactionHistoryState}
      footer={[
        <Button key="back" onClick={handleChangeInsuranceTransactionHistoryState}>
          Закрити
        </Button>,
      ]}
    >
      <div className="insurance-transaction-title-inner">
        <span className="insurance-transaction__title">Страховка: </span>
        <Select
          style={{
            width: '60%',
          }}
          value={selectedInsurance.name}
          onChange={(value) => handleChandeCurrentInsurance(value)}
          options={Object.keys(insurance).map((key) => ({
            value: key,
            label: findInsurance(key).name,
          }))}
        />
      </div>

      <List
        className="insurance-transaction-list"
        pagination={{
          pageSize: INSURANCE_PAGE_SIZE,
          style: { textAlign: 'center' },
          onChange: (page) => setPageIndex(page * INSURANCE_PAGE_SIZE - INSURANCE_PAGE_SIZE),
        }}
        grid={{
          gutter: 16,
          column: 1,
        }}
        dataSource={dataSource}
        renderItem={(item, index) => {
          const itemDateBefore = dataSource[index + pageIndex - 1]?.date;
          return (
            <>
              <span className="insurance-transaction-list-item-date">
                {index === 0 || dayjs(itemDateBefore).format('DD-MM-YYYY') !== dayjs(item.date).format('DD-MM-YYYY')
                  ? getFormattedDateWithRelativeDays(item.date)
                  : ''}
              </span>
              <li className="insurance-transaction-list-item">
                <div className="insurance-transaction-list-item__date">{dayjs(item.date).format('DD.MM.YYYY HH:mm:ss')}</div>
                <div className="insurance-transaction-list-item__type">{selectedInsurance?.services[item.type]?.name || 'Не визначено'}</div>
                <div className="insurance-transaction-list-item__price">
                  <span>{item.price * item.multiplier}</span>
                  <span> грн.</span>
                </div>
              </li>
            </>
          );
        }}
      />
    </Modal>
  );
};

export default InsuranceTransactionHistory;
