import React from 'react';
import { Skeleton } from 'antd';

const ChatUserSkeleton = () => {
  return (
    <Skeleton
      avatar
      active
      round
      paragraph={{
        rows: 1,
      }}
    />
  );
};

export default ChatUserSkeleton;
