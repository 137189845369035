import { useForm } from 'react-hook-form';
import { useCreateSphereServiceCategoryMutation } from '../../store/apis/sphere-api';

const CreateCateogryForm = () => {
  const [createCategory] = useCreateSphereServiceCategoryMutation();
  const handleCreateCategory = async (data) => {
    await createCategory(data);
    reset();
  };

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      title: {
        ua: '',
        en: '',
        de: '',
      },
    },
  });
  return (
    <form className="form-container create-category-form" onSubmit={handleSubmit(handleCreateCategory)}>
      <div>
        <label htmlFor="title-ua">Назва (UA)</label>
        <input id="title-ua" {...register('title.ua', { required: true })} />
      </div>

      <div>
        <label htmlFor="title-en">Назва (EN)</label>
        <input id="title-en" {...register('title.en', { required: true })} />
      </div>

      <div>
        <label htmlFor="title-de">Назва (DE)</label>
        <input id="title-de" {...register('title.de', { required: true })} />
      </div>

      <button type="submit">Додати</button>
    </form>
  );
};

export default CreateCateogryForm;
