import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal, setCorrespondenceListModalForTheContract } from '../../store/commonReducer';

import { Button, Modal, Spin, Typography, List, Empty } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';

import { Row as MyRow } from 'common_components';
import { CORRESPONDENCE_ADD_DOCUMENT } from 'common_constants/modals';
import { request, error, warn } from '../../tools';
import CorrespondenceCardItem from './CorrespondenceCardItem';

import './styles.scss';

const CorrespondenceModalForTheContract = () => {
  const dispatch = useDispatch();

  const dataModal = useSelector((state) => state.common.modal),
    list = useSelector((state) => state.common.correspondenceModalForTheContract);

  const coreMegaState = {
      loading: false, //* Завантаження
      editItem: null, //*  Редагування файлу
      auditСase: false, //* Мітка справи Є?
      fileFormat: false, //* Формат документу
      sizeFile: false, //* Розмір файлу
      fileList: [], //* Файлу завантажений
      auditTypeDocument: undefined, //* Мітка типу документу
      auditFormDocument: undefined, //* Мітка форми документу
      page: 1, //* Номер пагінації
      total: 0, //* Кількість файлів пагінації
    },
    [megaState, setMegaState] = useState(coreMegaState);

  const { Text } = Typography;

  const onCancel = () => {
    dispatch(setModal());
  };

  const getAllListСorrespondence = useCallback(async () => {
    const validation = (__id) => {
      if (!__id) {
        warn('', 'Для отримання списку, потрібно щоб існував контракт.', { _id: !!__id });
        return;
      }

      return true;
    };

    if (!validation(dataModal.data?._id)) return;

    setMegaState((prev) => ({ ...prev, loading: true }));

    const transaction = { _id: dataModal.data?._id, i: dataModal.data?.i, page: megaState.page };

    await request.post(
      '/correspondence',
      transaction,
      ({ cor, total }) => {
        dispatch(setCorrespondenceListModalForTheContract(cor));

        setMegaState((prev) => ({ ...prev, total: total }));
      },
      error,
    );

    setMegaState((prev) => ({ ...prev, loading: false }));
  }, [megaState.page]);

  useEffect(() => {
    getAllListСorrespondence();
  }, [megaState.page]);

  return (
    <Modal open className="correspondenceModalForTheContract" title={'Кореспонденція'} onCancel={onCancel} footer={null}>
      <MyRow style={{ justifyContent: 'center' }}>
        <Button
          type="primary"
          onClick={() =>
            dispatch(
              setModal({
                name: CORRESPONDENCE_ADD_DOCUMENT,
                data: {
                  _id: dataModal.data?._id,
                  i: dataModal.data?.i,
                  f: dataModal.data?.f,
                },
              }),
            )
          }
        >
          <FileAddOutlined /> Додати пошту
        </Button>
      </MyRow>
      <Spin size="large" tip="Завантаження" spinning={megaState.loading}></Spin>

      {megaState.total ? (
        <List
          itemLayout="horizontal"
          pagination={{
            hideOnSinglePage: true,
            position: 'both',
            align: 'end',
            defaultCurrent: 1,
            defaultPageSize: 10,
            showTotal: (total, range) => `${range[0]}-${range[1]} з ${total} пошт${megaState.total === 1 ? 'и' : ''}`,
            onChange: (value) => setMegaState((prev) => ({ ...prev, page: value })),
            current: megaState.page,
            total: megaState.total,
          }}
          dataSource={list ?? []}
          renderItem={(item) => <CorrespondenceCardItem data={item} />}
        />
      ) : (
        <Empty description={<Text>Нема пошти</Text>} />
      )}
    </Modal>
  );
};

export default CorrespondenceModalForTheContract;
