import React from 'react';
import { Select } from 'antd';

const billSelectGeneralWantsToSignContract = [{ 1: 'Хоче укласти договір' }, ['Мрія клієнта по консультації', 'qh_qd_H'], ['Мрія клієнта', 'qh_qd']];

const SelectGeneralWantsToSignContract = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть мрію клієнта"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі мрії</Option>
      <Option value="1">Хоче укласти договір</Option>
    </Select>
  );
};

export { billSelectGeneralWantsToSignContract, SelectGeneralWantsToSignContract };
