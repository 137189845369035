import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'common_constants/routes';
import { Button, Typography } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { DOCUMENT_TEMPLATES, SEND_CONTRACT_DATA_MFO } from 'common_constants/modals';

import { setLibraryList, setLibraryMiniForModal, setTemplates } from '../../../store/commonReducer';
import { request, requestFile, error, success, warn } from '../../../tools';

import '../LibraryItem.scss';
import '../../../Modals/LibraryMiniList/styles.scss';

const LibraryDownloadFile = ({ item, loadingDownload, mfo }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const listInLibrary = useSelector((state) => state.common.library);
  const listInContract = useSelector((state) => state.common.libraryMiniForModal);
  const modalData = useSelector((state) => state.common.modal.data);
  const modalName = useSelector((state) => state.common.modal.name);
  const contracts = useSelector((state) => state.common.contracts);
  const templates = useSelector((state) => state.common.templates);

  const [currentContract, setCurrentContract] = useState();

  const { Link, Text } = Typography;

  const dynamic_list =
    modalName === DOCUMENT_TEMPLATES || modalName === SEND_CONTRACT_DATA_MFO
      ? templates
      : {
          [ROUTES.CONTRACTS]: listInContract,
          [ROUTES.HOTLINE_REACH]: listInContract,
          [ROUTES.LIST]: listInContract,
          [ROUTES.LIBRARY]: listInLibrary,
        }[pathname] || [];

  useEffect(() => {
    if (mfo) {
      const currentContract = contracts?.find((item) => item._id === modalData.contractId);
      setCurrentContract(currentContract);
    }
  }, [mfo, contracts]);

  const download_file_by_fetch = () => {
    const validation = (_L, __id, _n, _f) => {
      if (!_L) {
        warn('', 'Для завантаження документу, потрібно вказати дійсний файл.', { L: !_L });
        return;
      }

      if (!__id) {
        warn('', 'Для завантаження документу, потрібно вказати дійсний файл.', { _id: !__id });
        return;
      }

      if (!_n) {
        warn('', 'Для завантаження документу, потрібно вказати назву файлу.', { n: !_n });
        return;
      }

      if (!_f) {
        warn('', 'Для завантаження документу, потрібно вказати формат файлу.', { n: !_f });
        return;
      }

      return true;
    };

    if (!validation(item.L, item._id, item.n, item.f)) return;

    const data_transfer = {
      fileId: item.L,
      _id: item._id,
      fileName: item.n + '.' + item.f,
      isTemplate: item.tm,
      formatFile: item.f,
      data: modalData,
    };

    if (mfo) {
      const mfos = [];

      for (let item of currentContract.autoSendingTemplates) {
        if (!mfos.includes(item.mfoId)) {
          mfos.push(item.mfoId);
        }
      }

      const _list = dynamic_list.map((i) => (i._id === item._id ? { ...i, c: item.c + mfos?.length } : i));
      dispatch(setTemplates(_list));

      for (let mfoId of mfos) {
        data_transfer.data = { ...data_transfer.data, mfoId: mfoId };
        loadingDownload(true);

        requestFile(
          '/library/downloadFileInGoogleDrive',
          data_transfer,
          () => {
            success('', 'Документ успішно завантажено');
            loadingDownload(false);
          },
          (err) => {
            error('', 'Помилка при відправці файлу', err);
            loadingDownload(false);
            const _list = dynamic_list.map((i) => (i._id === item._id ? { ...i, c: item.c - mfos?.length } : i));
            dispatch(setTemplates(_list));

            return;
          },
        );
      }

      return;
    }

    loadingDownload(true);

    requestFile(
      '/library/downloadFileInGoogleDrive',
      data_transfer,
      () => {
        const _list = dynamic_list.map((i) => (i._id === item._id ? { ...i, c: item.c + 1 } : i));

        const dynamic_save_redux = () =>
          modalName === DOCUMENT_TEMPLATES || modalName === SEND_CONTRACT_DATA_MFO
            ? dispatch(setTemplates(_list))
            : {
                [ROUTES.CONTRACTS]: dispatch(setLibraryMiniForModal(_list)),
                [ROUTES.LIST]: dispatch(setLibraryMiniForModal(_list)),
                [ROUTES.HOTLINE_REACH]: dispatch(setLibraryMiniForModal(_list)),
                [ROUTES.LIBRARY]: dispatch(setLibraryList(_list)),
              }[pathname] || warn();

        dynamic_save_redux();

        success('', 'Документ успішно завантажено');

        loadingDownload(false);
      },
      (err) => {
        error('', 'Помилка при відправці файлу', err);

        loadingDownload(false);
      },
    );
  };

  const openNewTab = async () => {
    const validation = (__id, _p) => {
      if (!__id) {
        warn('', 'Для показу сайту, потрібно вказати дійсний файл.', { _id: !__id });
        return;
      }

      if (!_p) {
        warn('', 'Для показу сайту, потрібно вказати саму адресу посилання.', { _P: !_p });
        return;
      }

      return true;
    };

    if (!validation(item._id, item.p)) return;

    const data_transfer = { _id: item._id };

    loadingDownload(true);

    await request.post(
      '/library/recordingTheShow',
      data_transfer,
      () => {
        const _list = dynamic_list.map((i) => (i._id === item._id ? { ...i, c: item.c + 1 } : i));

        const dynamic_save_redux = () =>
          ({
            [ROUTES.CONTRACTS]: dispatch(setLibraryMiniForModal(_list)),
            [ROUTES.LIST]: dispatch(setLibraryMiniForModal(_list)),
            [ROUTES.HOTLINE_REACH]: dispatch(setLibraryMiniForModal(_list)),
            [ROUTES.LIBRARY]: dispatch(setLibraryList(_list)),
          }[pathname] || warn());

        dynamic_save_redux();

        success('', 'Сайт успішно відкрито');

        window.open(item.p, '_blank');
      },

      error,
    );

    loadingDownload(false);
  };

  const [body_open_site_start, body_func_start] = [async () => openNewTab(), async () => download_file_by_fetch()];

  const [body_component_btn, body_component_a] = [
    {
      0: (
        <Button
          disabled={mfo && currentContract?.autoSendingTemplates?.length < 1}
          size="small"
          style={{ paddingLeft: '8px' }}
          icon={<DownloadOutlined key="downloadRating" />}
          onClick={body_func_start}
          type="primary"
        >
          &nbsp;{item.c ?? '0'}
        </Button>
      ),
      1: (
        <Button size="small" style={{ paddingLeft: '8px' }} icon={<EyeOutlined key="downloadRating" />} onClick={body_open_site_start} type="primary">
          &nbsp;{item.c ?? '0'}
        </Button>
      ),
    }[item.z] || <Text mark>Bad code</Text>,
    {
      0: (
        <Link disabled={mfo && currentContract?.autoSendingTemplates?.length < 1} onClick={body_func_start}>
          <DownloadOutlined key="downloadRating" /> {item.c ?? '0'}
        </Link>
      ),
      1: (
        <Link onClick={body_open_site_start}>
          <EyeOutlined key="downloadRating" /> {item.c ?? '0'}
        </Link>
      ),
    }[item.z] || <Text mark>Bad code</Text>,
  ];

  const dynamic_components =
    {
      [ROUTES.CONTRACTS]: body_component_btn,
      [ROUTES.LIBRARY]: body_component_a,
    }[pathname] || body_component_btn;

  return dynamic_components;
};

export default LibraryDownloadFile;
