import React from 'react';
import { Select } from 'antd';

const billSelectGeneralConditionReach = [
  { '!rrh.rsh': 'Немає в дожимі', 'rrh.rsh': 'Є в дожимі', 'rrh.der': 'Видалені з дожиму' },
  ['Стан дожиму', 'statusR'],
  ['Стан дожиму по консультації', 'statusR_H'],
];

const SelectGeneralConditionReach = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть дожимний стан"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі дожимні стани</Option>
      <Option value="!rrh.rsh">Немає в дожимі</Option>
      <Option value="rrh.rsh">Є в дожимі</Option>
      <Option value="rrh.der">Видалені з дожиму</Option>
    </Select>
  );
};

export { billSelectGeneralConditionReach, SelectGeneralConditionReach };
