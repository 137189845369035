const DB = 'crm';

const COLLECTIONS = {
  HOTLINE: 'hotline',
  PSYCHOLOGIST_HOTLINE: 'psychologistHotline',
  CLIENTS: 'clients',
  PARTNERS: 'partners',
  CONTRACTS: 'contracts',
  BLOCKS: 'blocks',
  HARVEST: 'harvest',
  CLIENTS_TRANSACTIONS: 'clients_transactions',
  CABINETS: 'cabinets',
  GDUN: 'gdun',
  CRM_USERS: 'crm_users',
  COMMON_PARAMS: 'common_params',
  LAWYERS: 'lawyers',
  ADDRESSEES: 'addressees',
  JUDGES: 'judges',
  SENDER_HISTORY: 'sender_history',
  MISSED_CALLS: 'missed_calls',
  PLACE: 'place',
  COURT: 'court',
  FILII: 'filii',
  LIBRARY: 'library',
  BANK_CARDS: 'bank_cards',
  CORRESPONDENCE: 'correspondence',
  CONTRACTS_RATING: 'contracts_rating',
  REACHES_RATING: 'reaches_rating',
  FEE: 'fee',
  CHAT_PRIVATE: 'chat_private',
  COMPLAINT: 'complaint',
  CLIENT_FEEDBACK: 'client_feedback',
  WEBSITE_NEWS: 'website_news',
  TOP_FILIALS: 'top_filials',
  PERSONAL_RATING_BY_PRAVO: 'userrating_pravo',
  EXPENSES: 'expenses',
  TASKS: 'tasks',
  QUICK_CHAT: 'quick_chat',
  DOZHIMS_AI: 'dozhims_AI',
  SUGARS: 'sugars',
  PUSH_NOTIFICATION: 'push_notification',
  PRAVO_RATING: 'pravo_rating',
  TENDERS: 'tenders',
  INSURANCE: 'insurance',
  MFO: 'mfo',
  CONFLICTS: 'conflicts',
  TWITTER: 'twitter',
  AI_DOCUMENTS: 'ai_documents',
  PAYROLLS: 'payrolls',
  WEBINAR: 'webinar',
  AUTO_SENDING_TEMPLATES: 'auto_sending_templates',
  INSURANCE_TYPE: 'insurance_type',
  ACCOUNTANT_CHAT: 'accountant_chat',
  REGISTER: 'register',
  HOTLINE_CALLS: 'hotline_calls',
  SMS_SALES: 'sms_sales',
  SALES: 'sales',
  OLD_SMS_RECEIVERS: 'old_sms_receivers',
  CLIENT_PAGE_STATS: 'client_page_stats',
  QUESTIONNAIRES: 'questionnaires',
  SPHERE_SERVICE_CATEGORIES: 'sphere_service_categories',
  SPHERE_SERVICE_ITEMS: 'sphere_service_items',
  PROMOCODES: 'promocodes',
  PERSONAL_CONSULTANT_CHATS: 'personal_consultant_chats',
  PARTNERS_USERS: 'partners_users',
  PARTNERS_HOTLINES: 'partners_hotlines',
  PARTNERS_CONTRACTS: 'partners_contracts',
};

const OPERATION = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
};

const DATA_PAGE_SIZE = 10;
const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const FIL_AVARAGE_INCOME = {
  0: 21347, // Київ
  1: 12510, // Харків
  2: 14479, // Дніпро
  3: 12960, // Одеса
  4: 12936, // Львів
  5: 12960, // Одеса, пос. Котовського
  6: 14479, // Кривий Ріг
  7: 14510, // Запоріжжя
  8: 14382, // Миколаїв
  9: 12555, // Вінниця
  10: 13412, // Полтава
  11: 13358, // Рівне
  12: 15480, // Маріуполь
  13: 12311, // Черкаси
  14: 11853, // Чернігів
  15: 12119, // Суми
  16: 11658, // Кропивницький
  17: 12960, // Ізмаїл
  18: 12960, // Одеса, мікрорайон Таїрова
  19: 12352, // Хмельницький
  20: 12115, // Ужгород
  21: 11827, // Івано-Франківськ
  22: 15152, // Біла Церква
  23: 11455, // Тернопіль
  24: 11778, // Херсон
  25: 21347, // Київ, Печерський район
  26: 12123, // Чернівці
  27: 12311, // Житомир
  28: 21347, // Київ, Оболонський район
  29: 12311, // Умань
  30: 21347, // Київ, Дарницький район
  31: 13412, // Кременчук
  32: 15152, // Бровари
  33: 21347, // Київ, Голосіївський район
  34: 11735, // Луцьк
  35: 15152, // Ірпінь
};

const PROJECTIONS = {
  FIL_PROJECTION: {
    _id: 1,
    name: 1,
    address: 1,
    map: 1,
    feedback: 1,
    currency: 1,
    video: 1,
    zone: 1,
    address_description: 1,
    managerId: 1,
    states: 1,
    district: 1,
    full_name: 1,
    filPhoto: 1,
    ext: 1,
    image: 1,
    i: 1,
  },
  CONTRACT_PROJECTION: {
    _id: 1,
    n: 1,
    assignments: 1,
    us: 1,
    sd: 1,
    ad: 1,
    md: 1,
    sa: 1,
    qa: 1,
    cn: 1,
    tasks: 1,
    mg: 1,
    autoPay: 1,
    i: 1,
    rb: 1,
    autoPayTransactions: 1,
    us: 1,
    f: 1,
    C: 1,
    fbBonus: 1,
    paymentManagement: 1,
    monthlyPaymentsManagement: 1,
    fp: 1,
    pa: 1,
    totalSugar: 1,
    editPMonceAccess: 1,
    pr: 1,
    prc: 1,
    installmentMonths: 1,
    monthlyFee: 1,
    H: 1,
    ea: 1,
  },
  CLIENT_PROJECTION: {
    _id: 1,
    i: 1,
    n: 1,
    ph: 1,
    countryPhCode: 1,
    B: 1,
    notif: 1,
    address: 1,
    currency: 1,
    f: 1,
    upa: 1,
    partner_invitedClients: 1,
    b: 1,
    pt: 1,
    pn: 1,
    pdv: 1,
    pkv: 1,
    pdd: 1,
    'paymentCard.maskedPan': 1,
    partner_transactions: 1,
    partner_payments: 1,
    partner_balance: 1,
    clientBalance: 1,
    transactions: 1,
    push_notifications: 1,
    deviceTokens: 1,
    wanted: 1,
    inviteLinkToken: 1,
    webinars: 1,
    firstBonus: 1,
  },
};

module.exports = {
  DB,
  COLLECTIONS,
  OPERATION,
  DATA_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  FIL_AVARAGE_INCOME,
  PROJECTIONS,
};
