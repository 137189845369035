import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Input } from 'antd';
import { CHAT_TYPES } from 'common_constants/business';
import clsx from 'clsx';

import { setModal, updateChat } from '../../store/commonReducer';
import { request, error, success } from '../../tools';
import SelectMembers from '../../components/SelectMembers';
import GroupChatAddPhoto from './GroupChatAddPhoto';

import './CreateNewGroupChat.scss';

const CreateNewGroupChat = () => {
  const dispatch = useDispatch();

  const modalData = useSelector((state) => state.common.modal?.data);
  const chatsData = useSelector((state) => state.common.chatsData);

  const [step, setStep] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [groupChatName, setGroupChatName] = useState('');
  const [isValidGroupChatNameInput, setIsValidGroupChatNameInput] = useState(true);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const currentChat = chatsData?.[CHAT_TYPES.groupChat.key]?.find((chat) => chat._id === modalData?.chatId) || {};

  const onChangeStep = () => {
    if (step === 0 && !currentChat?._id) {
      const trimedGroupChatNameValue = groupChatName.trim();

      if (trimedGroupChatNameValue.length < 1) {
        setIsValidGroupChatNameInput(false);
        return;
      }

      setStep((prev) => prev + 1);

      return;
    }

    const formData = new FormData();
    formData.append('members', selectedMembers);
    formData.append('chatName', groupChatName);

    if (imageFile) {
      formData.append('file', imageFile.file);
    }

    if (imageFile && currentChat?.avatarImage) {
      formData.append('prevAvatarId', currentChat.avatarImage);
    }

    if (currentChat?._id) {
      formData.append('_id', currentChat._id);
    }

    setLoading(true);
    request.post(
      '/chatPrivate/createGroupChat',
      formData,
      ({ data }) => {
        setLoading(false);
        dispatch(updateChat({ chatType: CHAT_TYPES.groupChat.key, data: { ...data, type: CHAT_TYPES.groupChat.key, chatId: data._id } }));
        success('Групу створено успішно!');
        onCancel();
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  const getAvatarImageBase64 = (chatId, fileId, cb) => {
    const requestData = {
      type: CHAT_TYPES.groupChat.key,
      chatId: chatId,
      fileId: fileId,
    };

    request.post(
      '/chatPrivate/chatGetFileBase64',
      requestData,
      ({ image }) => {
        cb(image);
      },
      error,
    );
  };

  const changeSelectedMembers = (values) => {
    setSelectedMembers(values);
  };

  const onCancel = () => {
    dispatch(setModal());
  };

  useEffect(() => {
    if (currentChat?._id) {
      setGroupChatName(currentChat.chatName);

      if (currentChat?.avatarImage) {
        getAvatarImageBase64(currentChat._id, currentChat.avatarImage, (image) => setImageUrl('data:image/jpeg;charset=utf-8;base64, ' + image));
      }
    }
  }, []);

  return (
    <Modal
      open
      onCancel={onCancel}
      okText={currentChat?._id ? 'Зберегти' : step === 0 ? 'Далі' : 'Створити'}
      onOk={onChangeStep}
      className="create-new-group-chat-modal"
      width={400}
    >
      <Spin spinning={isLoading}>
        {step === 0 ? (
          <div>
            <div className="form-wrapper">
              <GroupChatAddPhoto imageUrl={imageUrl} setImageUrl={setImageUrl} onChangeAvatar={(file) => setImageFile(file)} />
              <div>
                <label className={clsx('group-chat-name-label', !isValidGroupChatNameInput && 'not-valid')}>Назва групи</label>
                <Input
                  className="group-chat-name-input"
                  status={isValidGroupChatNameInput ? '' : 'error'}
                  value={groupChatName}
                  onChange={(e) => setGroupChatName(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : (
          <SelectMembers selectedMembers={selectedMembers} setSelectedMembers={changeSelectedMembers} />
        )}
      </Spin>
    </Modal>
  );
};

export default CreateNewGroupChat;
