import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, Input, notification, Modal, Row, Select, Form, Col, Space, Switch } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { Box } from 'common_components/';
import { LAW_BLOCKS, ROLES } from 'common_constants/business';
import { DISMISSAL_EMPLOYEE } from 'common_constants/modals';
import { setModal } from '../../store/commonReducer';

import { request, error, success } from '../../tools';

import PageInfo from '../../components/PageInfo';
import { UserItem } from './UsersPartials';
import './Users.scss';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';

const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);
  const userAuthRole = ROLES[userAuth.role];
  const usersList = Object.values(users);

  const [filterP, setFilterP] = useState('');
  const [filterIC, setFilterIC] = useState(false);
  const [filterF, setFilterF] = useState(false);
  const [filterB, setFilterB] = useState('');
  const [filterFil, setFilterFil] = useState('');

  const onEdit = (data) => {
    history.push('/userEdit/' + data._id);
  };

  const onAdd = () => {
    history.push('/userAdd');
  };

  const onFireClick = (data) => {
    dispatch(setModal({ name: DISMISSAL_EMPLOYEE, data: data }));
  };

  return (
    <div className="users_page default_page">
      <h2>
        Користувачі
        <PageInfo />
      </h2>

      <Row style={{ justifyContent: 'center', marginBottom: 16, marginTop: 16 }}>
        <Button type="primary" icon={<PlusCircleFilled />} onClick={onAdd}>
          Додати користувача
        </Button>
      </Row>

      <Card className="change">
        <Form layout="vertical" style={{ marginTop: 16 }}>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Row gutter={16}>
              <Col>
                <Form.Item label="Фільтрувати за блоком: " style={{ marginBottom: 0 }}>
                  <Select value={filterB} onChange={(value) => setFilterB(value)}>
                    <Select.Option value="">Всі</Select.Option>
                    {Object.entries(LAW_BLOCKS).map(([key, value]) => (
                      <Select.Option key={key} value={key}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item className="label_row" label="Інформаційний центр:" style={{ marginBottom: 0 }}>
                <Switch checked={filterIC} onChange={(checked) => setFilterIC(checked)} />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item className="label_row" label="Показати звільнених: " style={{ marginBottom: 0 }}>
                <Switch checked={filterF} onChange={(checked) => setFilterF(checked)} />
              </Form.Item>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Знайти за псевдонімом: " style={{ marginBottom: 0 }}>
                  <Input aria-label="Write name client" value={filterP} onChange={(e) => setFilterP(e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Знайти за філією: ">
                  <SearchSelectFil value={filterFil} onChange={(value) => setFilterFil(value)} />
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </Card>

      <Card className="list" style={{ marginTop: 16 }}>
        {usersList
          .filter(
            (user) =>
              (filterP ? user.p.toLowerCase().includes(filterP.toLowerCase()) : true) &&
              (filterIC ? user.ic : true) &&
              (filterF ? true : !user.fired) &&
              (filterB ? filterB === user.block : true) &&
              (filterFil ? filterFil === user.fil : true),
          )
          .map((user, index) => (
            <UserItem key={user._id} user={user} index={index} onEdit={onEdit} onFireClick={onFireClick} userAuthRole={userAuthRole} />
          ))}
      </Card>
    </div>
  );
};

export default Users;
