import React from 'react';
import { Modal, List, Popover } from 'antd';
import dayjs from 'dayjs';
import { CURRENCIES, EXPENSE_CATEGORIES } from 'common_constants/business/';
import HoverableUser from '../../components/HoverableUser/HoverableUser';
import Link from 'antd/es/typography/Link';
import { useSelector } from 'react-redux';
import ExpenseImageButton from './ExpenseImageButton';

const ExpenseHistory = ({ open, onCancel, data, users }) => {
  const FILII = useSelector((state) => state.common.FILII);

  return (
    <Modal title="Історія змін" open={open} onCancel={onCancel} footer={null}>
      <List
        dataSource={data}
        renderItem={(item) => {
          const categoryData = EXPENSE_CATEGORIES[item?.category];

          const categoryLabelToDisplay = categoryData
            ? item?.category === 10
              ? categoryData.adCategories.find((subcat) => subcat.value === item.adCategory)?.label || categoryData.label
              : categoryData.label
            : item?.category;

          const subCategoryLabelToDisplay =
            item?.category === 0
              ? categoryData?.subcategories.find((subcat) => subcat.value === parseInt(item.subCategory, 10))?.label || item.subCategory
              : categoryData?.subcategories[item.subCategory]?.label || item?.subCategory;

          return (
            <List.Item>
              <div>
                <p>
                  <b>Філія:</b> {FILII[item.fil]}
                </p>
                <p>
                  <b>Автор витрати:</b>{' '}
                  <Popover content={<HoverableUser id={users[item.author]?._id ?? '-'} />} arrow trigger="hover">
                    <Link>{users[item.author]?.p ?? '-'}</Link>
                  </Popover>
                </p>
                <p>
                  <b>Дата:</b> {dayjs(item.date).format('DD.MM.YYYY HH:mm:ss')}
                </p>
                <p>
                  <b>Сума: </b>
                  {item.amount}
                  {item.currency ? CURRENCIES[item.currency].symbol || '₴' : '₴'}
                </p>
                <p>
                  <b>Категорія:</b> {categoryLabelToDisplay}
                </p>
                <p>
                  <b>Підкатегорія:</b> {subCategoryLabelToDisplay}
                </p>
                {item?.toUser && (
                  <p>
                    <b>Отримувач:</b> {users[item?.toUser]?.p} - {users[item?.toUser]?.name}
                  </p>
                )}
                {item?.sugarIndex && (
                  <p>
                    <b>№ сахорка:</b> {item?.sugarIndex}
                  </p>
                )}
                {item?.comment && (
                  <p>
                    <b>Коментар:</b> {item.comment}
                  </p>
                )}
                {item?.receipt && (
                  <p>
                    <b>Квитанція:</b> {item.receipt && <ExpenseImageButton fileId={item.receipt} />}
                  </p>
                )}

                <br />

                <p>
                  <b>Дата редагування:</b> {dayjs(item.editedAt).format('DD.MM.YYYY HH:mm:ss')}
                </p>
                <p>
                  <b>Автор редагування:</b>{' '}
                  <Popover content={<HoverableUser id={users[item.editedBy]?._id ?? '-'} />} arrow trigger="hover">
                    <Link>{users[item.editedBy]?.p ?? '-'}</Link>
                  </Popover>
                </p>
              </div>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default ExpenseHistory;
