import { Card, Row, Space, Col, Typography } from 'antd';
import {
  LOGICAL_STANDARD_CHOISE_2_OPTIONS,
  LOGICAL_STARTED_ON_TIME_CHOISE_3_OPTIONS,
  LOGICAL_WOULD_YOU_AGREE_CHOISE_3_OPTIONS,
} from 'common_constants/business';

const { Text } = Typography;

const HotlineQuestionnaire = ({ questionnaire: { qh, client, date, startT, endT } }) => {
  return (
    <Card
      className="questionnaire-card"
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <span style={{ marginRight: '10px' }}>{new Date(date)?.toLocaleDateString('uk-UA')}</span>
          <span>{`${startT} - ${endT}`}</span>
        </div>
      }
      bordered={true}
      style={{ margin: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
    >
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={12}>
            <Text strong> Клієнт: </Text>
          </Col>
          <Col span={12}>
            <Text>
              {client?.n} {`+${client?.countryPhCode}${client?.ph}`}
            </Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Оцінка якості: </Text>
          </Col>
          <Col span={12}>
            <Text>{qh?.qc}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Оцінка привітності персоналу: </Text>
          </Col>
          <Col span={12}>
            <Text>{qh?.qs}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Чи вчасно розпочалася консультація: </Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STARTED_ON_TIME_CHOISE_3_OPTIONS[qh?.qk]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Чи зрозумілою для Вас була консультація: </Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STANDARD_CHOISE_2_OPTIONS[qh?.qa]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Чи влаштувала Вас ціна консультації: </Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STANDARD_CHOISE_2_OPTIONS[qh?.qp]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Чи зрозумілою для Вас мовою проводилася консультація: </Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STANDARD_CHOISE_2_OPTIONS[qh?.ql]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Чи розповіли Вам про додаток «єАдвокат»: </Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STANDARD_CHOISE_2_OPTIONS[qh?.qv]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Чи уклали Ви договір з нашою компанією:</Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_WOULD_YOU_AGREE_CHOISE_3_OPTIONS[qh?.qd]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Місце для скарг чи пропозицій:</Text>
          </Col>
          <Col span={12}>
            <Text>{qh?.qt}</Text>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default HotlineQuestionnaire;
