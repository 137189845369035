import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Modal, Input, Spin } from 'antd';
import { setModal, setCurrentHotlineList, setClientsList, setCurrentReachList } from '../../store/commonReducer';
import { request, success, warn, error } from '../../tools';
import { ROUTES } from 'common_constants/routes';

import './styles.scss';

const NewClientTransaction = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userAuth = useSelector((state) => state.common.userAuth);
  const currentHotlineList = useSelector((state) => state.common.currentHotlineList);
  const currentReachList = useSelector((state) => state.common.currentReachList);
  const clients = useSelector((state) => state.common.clients);
  const { data, prev } = useSelector((state) => state.common.modal);

  const [price, setPrice] = useState(prev?.data?.name);
  const [bonuses, setBonuses] = useState();
  const [bonusBalance, setBonusBalance] = useState();
  const bonusTransaction = !(data.hotline?._id || data.contract?._id);
  const bonusCalculation = bonusBalance + parseInt(bonuses);

  const onSubmit = () => {
    const validation = (bonusTransaction, bonuses, bonusCalculation) => {
      if (bonusTransaction && !bonuses) {
        warn('', 'Заповніть поля.', { bonusTransaction: bonusTransaction, '!bonuses': !bonuses });
        return;
      }

      if (!bonusTransaction && !price) {
        warn('', 'Заповніть поле "Сума транзакції".', { '!price': !price });
        return;
      }

      if (bonusCalculation < 0) {
        warn('', 'Недостатньо бонусів.', { bonusCalculation: bonusCalculation < 0 });
        return;
      }

      if (Number(bonuses) === 0) {
        warn('', 'В цьому немає сенсу', { '!bonuses': !bonuses });
        return;
      }

      return true;
    };

    if (!validation(bonusTransaction, bonuses, bonusCalculation)) return;

    const transaction = {
      p: null,
      b: parseInt(bonuses),
      C: data.hotline?.C || data.C,
      K: data.hotline?.K || data.K,
      H: data.hotline?._id,
      B: (bonusBalance + Number(bonuses)).toFixed(2),
    };

    request.post(
      '/clientsTransactions/addByUrist',
      transaction,
      () => {
        success('', 'Транзакція пройшла успішно');
        if (data.hotline?._id) {
          const _currentHotlineList =
            pathname === ROUTES.HOTLINE_REACH
              ? currentReachList.map((i) => {
                  return i._id === data._id ? { ...i, T: true } : i;
                })
              : currentHotlineList.map((i) => {
                  return i._id === data._id ? { ...i, T: true } : i;
                });
          pathname === ROUTES.HOTLINE_REACH
            ? dispatch(setCurrentReachList(_currentHotlineList))
            : dispatch(setCurrentHotlineList(_currentHotlineList));
        }

        if (clients) {
          dispatch(setClientsList(clients.map((i) => (i._id === data.C ? { ...i, B: parseInt(i.B) + parseInt(bonuses) } : i))));
        }

        dispatch(setModal());
      },
      error,
    );
  };

  useEffect(() => {
    request.post(
      '/clients/getBonusBalanceForUrist',
      { _id: data.hotline?.C || data.C },
      (req) => {
        setBonusBalance(req.data?.B);
      },
      error,
    );
  }, []);

  return (
    <Modal open className="add-transaction" title="Додаємо транзакцію" onCancel={() => dispatch(setModal(prev))} footer={null}>
      {!bonusTransaction && (
        <>
          <b>Сума транзакції</b>
          <Input aria-label="Suma transaction" value={price} onChange={(e) => setPrice(e.target.value)} />
          <br />
          <br />
        </>
      )}
      <b>Сума бонусів</b> ({isNaN(bonusBalance) ? <Spin /> : bonusBalance} є на рахунку)
      <Input aria-label="Suma bonus" value={bonuses} onChange={(e) => setBonuses(e.target.value)} />
      {bonusCalculation < 0 && <span className="error">Недостатньо бонусів</span>}
      <br />
      <br />
      <b>Хто приймав транзакцію</b>
      <Input aria-label="Who accepted the transaction" value={`${userAuth.p} - ${userAuth.name}`} disabled />
      <br />
      <br />
      <Button type="primary" className="submit" onClick={onSubmit}>
        Додати транзакцію
      </Button>
    </Modal>
  );
};

export default NewClientTransaction;
