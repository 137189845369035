import React, { useState } from 'react';
import { Card, Popconfirm, Button, Popover, Typography } from 'antd';
import { EditOutlined, DeleteFilled, CommentOutlined, HeartFilled, HeartOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import HoverableUser from '../../../components/HoverableUser/HoverableUser';

import '../HomeTwitter/HomeTwitterItem.scss';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ProfileTwitterItem = ({ item, deleteTweet, editTweet, addToFavorites }) => {
  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);
  const isFavorite = item?.favorites?.includes(userAuth._id);
  const FILII = useSelector((state) => state.common.FILII);

  const [isHovered, setIsHovered] = useState(false);
  const [isTweetFavorite, setIsTweetFavorite] = useState(isFavorite);
  const [favoriteCount, setFavoriteCount] = useState(item.favorites?.length || 0);

  const userPopoverContent = (
    <span style={{ color: '#1890ff', fontSize: 18 }}>
      @
      <Popover content={<HoverableUser id={users[item.author]?._id ?? '-'} />} arrow placement="right" trigger="hover">
        <Typography.Link style={{}}>{users[item.author]?.p ?? '-'}</Typography.Link>
      </Popover>
    </span>
  );

  const onClickEditButtonHandle = () => {
    editTweet(item._id);
  };

  const onClickDeleteButtonHandle = async () => {
    await deleteTweet(item._id);
  };

  const onClickFavoriteButtonHandle = async () => {
    await addToFavorites(item._id);
    setIsTweetFavorite(!isTweetFavorite);
    setFavoriteCount((prevCount) => (isTweetFavorite ? prevCount - 1 : prevCount + 1));
  };

  return (
    <Card
      style={{ marginBottom: '12px', boxShadow: isHovered ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : 'none' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div>
        <div style={{ display: 'flex' }}>
          <Card.Meta title={userPopoverContent} />
          <span style={{ color: '#c7bcbc', marginLeft: 12 }}>{FILII[users[item.author]?.fil]}</span>
        </div>
        <br />
        <span style={{ fontSize: '18px', wordWrap: 'break-word' }}>{item.message}</span>
        <br />
        <br />
        <div style={{ fontSize: '10px', color: '#c7bcbc', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button
              type="link"
              icon={isTweetFavorite ? <HeartFilled style={{ color: 'red' }} /> : <HeartOutlined />}
              className="favoriteButton"
              onClick={onClickFavoriteButtonHandle}
            >
              {' '}
              {favoriteCount}
            </Button>
            <Link key={item._id} to={`/twitter/${item._id}`}>
              <Button type="link">
                <CommentOutlined /> {item.comments?.length}
              </Button>
            </Link>
          </div>
          {dayjs(item.createdAt).format('DD.MM.YYYY HH:mm:ss')}
        </div>
        <div style={{ position: 'absolute', right: '20px', top: 10 }}>
          {isHovered && (
            <>
              <Popconfirm
                title="Видалення"
                description="Ви дійсно хочете видалити твіт?"
                onConfirm={onClickDeleteButtonHandle}
                okText="Так"
                cancelText="Ні"
                className="centered-popconfirm"
              >
                <Button type="link" icon={<DeleteFilled />} className="deleteButton" />
                <Button type="link" icon={<EditOutlined />} className="editButton" onClick={onClickEditButtonHandle} />
              </Popconfirm>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProfileTwitterItem;
