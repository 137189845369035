import React, { useEffect, useState } from 'react';

import { USER_FUNCTIONS } from 'common_constants/business';

import { request, error } from '../../tools';
import PieChart from '../../components/PieChart';

import './styles.scss';

const FunctionSurvey = () => {
  const [clients, setClients] = useState([]);
  const [count, setCount] = useState({ was: 0, canceled: 0, all: 0, resasons: {}, moves: {}, reasonsLength: 0 });

  const getFunctionSurvey = () => {
    request.post(
      '/functionsNotUsed/getAll',
      {},
      ({ data }) => {
        setClients(data);

        const countTrueFalse = data.reduce(
          (acc, obj) => {
            Object.values(obj).forEach((value) => {
              if (value === true) {
                acc.trueCount++;
              } else if (value === false) {
                acc.falseCount++;
              }
            });
            return acc;
          },
          { trueCount: 0, falseCount: 0 },
        );

        const was = countTrueFalse.trueCount ?? 0;
        const canceled = countTrueFalse.falseCount ?? 0;
        const all = was + canceled;

        setCount({
          ...count,
          was,
          canceled,
          all,
        });
      },
      error,
    );
  };

  useEffect(() => {
    getFunctionSurvey();
  }, []);

  const data = Object.entries(USER_FUNCTIONS).map(([key, value]) => {
    const countTrue = clients.reduce((acc, client) => (client[key] === true ? acc + 1 : acc), 0);

    return {
      name: value,
      value: countTrue,
    };
  });

  return (
    <section className="function-survey_page default_page">
      <div>
        <PieChart data={data} all={count.all} height={600} />
      </div>
    </section>
  );
};

export default FunctionSurvey;
