import { useForm } from 'react-hook-form';
import { useCreateSphereServiceItemMutation, useGetSphereServiceCategoriesQuery } from '../../store/apis/sphere-api';
import { useState } from 'react';
import clsx from 'clsx';
import { Select } from 'antd';

const CreateServiceItemForm = ({ activeCategory }) => {
  const { data: categories } = useGetSphereServiceCategoriesQuery();
  const [createServiceItem] = useCreateSphereServiceItemMutation();
  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      categoryId: '',
      text: {
        ua: '',
        en: '',
        de: '',
      },
      url: '',
      isTitle: false,
      position: {
        x: 0,
        y: 0,
      },
    },
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleForm = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCreateServiceItem = async (data) => {
    await createServiceItem({ ...data, categoryId: activeCategory._id });
    reset();
  };

  return (
    <div className="create-service-item-form">
      <button className="expand-button" onClick={toggleForm}>
        {isExpanded ? 'Згорнути форму' : 'Розгорнути форму'}
      </button>
      <form className={clsx('form-container', { expanded: isExpanded })} onSubmit={handleSubmit(handleCreateServiceItem)}>
        <div>
          <label htmlFor="categoryType">Тип категорії</label>
          <Select className="select" id="categoryType" onChange={(value) => setValue('categoryId', value)}>
            {categories?.data.map((category) => (
              <Select.Option key={category._id} value={category._id}>
                {category.title.ua}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <label htmlFor="text-ua">Текст українською</label>
          <input id="text-ua" {...register('text.ua', { required: true })} placeholder="Податки" />
        </div>

        <div>
          <label htmlFor="text-en">Текст англійською</label>
          <input id="text-en" {...register('text.en', { required: true })} placeholder="Taxes" />
        </div>

        <div>
          <label htmlFor="text-de">Текст німецькою</label>
          <input id="text-de" {...register('text.de', { required: true })} placeholder="Steuer" />
        </div>

        <div>
          <label htmlFor="url">Посилання</label>
          <input id="url" {...register('url', { required: true })} placeholder="https://example.com" />
        </div>

        <div>
          <label htmlFor="position-x">Позиція X</label>
          <input id="position-x" type="number" step="any" {...register('position.x', { required: true })} placeholder="0.3" />
        </div>

        <div>
          <label htmlFor="position-y">Позиція Y</label>
          <input id="position-y" type="number" step="any" {...register('position.y', { required: true })} placeholder="-0.5" />
        </div>

        <div className="checkbox-wrapper">
          <label htmlFor="isTitle" className="checkbox-label">
            Is Title
            <input type="checkbox" id="isTitle" {...register('isTitle')} />
          </label>
        </div>

        <button type="submit">Створити сервіс</button>
      </form>
    </div>
  );
};

export default CreateServiceItemForm;
