import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Badge, Card, Popover } from 'antd';
import { Row, Box } from 'common_components';
import { URIST_ACTIVITY, URIST_ACTIVITY_REASONS } from 'common_constants/business';
import { request } from '../../tools';
import HoverableUser from '../../components/HoverableUser/HoverableUser';
const locale = require('dayjs/locale/uk');
dayjs.locale(locale);

const ExpendedItem = ({ user = {} }) => {
  const [groupedActivities, setGroupedActivities] = useState([]);

  useEffect(() => {
    request.post('/profile/getActivity', { _id: user._id, limit: 50 }, ({ activityJournal = [] }) => {
      const groupedActivities = activityJournal.reduce((accumulator, item) => {
        const date = dayjs(item.T).format('D MMMM');
        if (!accumulator[date]) accumulator[date] = [];
        accumulator[date].push(item);
        return accumulator;
      }, {});
      setGroupedActivities(groupedActivities);
    });
  }, []);

  return (
    <Card>
      <br />
      {Object.keys(groupedActivities).map((i) => (
        <React.Fragment key={i}>
          <h4>{i}</h4>
          {groupedActivities[i].map((item, index) => (
            <span key={index}>
              {dayjs(item.T).format('HH : mm')} - {item.value === 1 ? 'прийшов' : 'пішов -> '}
              {item.reason === 21 ? item.ownReason : URIST_ACTIVITY_REASONS[item.reason]?.label}
              {item.lastworker && <span style={{ color: 'red', fontSize: 18 }}> Пішов знаючи що останній!!!</span>}
              <br />
            </span>
          ))}
        </React.Fragment>
      ))}
    </Card>
  );
};

const ActivityItem = ({ user = {} }) => {
  const [open, setOpen] = useState(false);
  const { value, reason, ownReason } = user.activity || {};
  if(value === 0) {
    return URIST_ACTIVITY[0].label
  }
  const reasonLabel = value === 1 ? URIST_ACTIVITY[1].label : reason === 21 ? ownReason : URIST_ACTIVITY_REASONS[reason]?.label;
  const color = user.activity?.value === 1 ? 'green' : 'red';

  return (
    <Box mt={0}>
      <Popover content={<HoverableUser id={user._id} />} arrow placement="bottomLeft" trigger="hover">
        <Row onClick={() => setOpen(!open)} style={{ whiteSpace: 'nowrap' }}>
          <Badge dot color={color} size="big" />
          &nbsp; {user.p}&nbsp;
          {user.activity?.T ? dayjs(user.activity?.T).format('DD.MM.YYYY HH:mm') : 'ще не відмічалось'}
          <span style={{ marginLeft: 8, color }}>{reasonLabel}</span>
        </Row>
      </Popover>

      {open && <ExpendedItem user={user} />}
    </Box>
  );
};

export default React.memo(ActivityItem);
