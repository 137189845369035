import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import ExportQuantityModal from '../../components/ExportQuantityModal';
import { request, warn, error, success } from '../../tools';

const ExportSuggestionsAndComplaints = () => {
  const users = useSelector((state) => state.common.users);
  const suggestionsAndComplaintsMaxIndex = useSelector((state) => state.common.suggestionsAndComplaintsMaxIndex);
  const FILII = useSelector((state) => state.common.FILII);

  const max = suggestionsAndComplaintsMaxIndex;
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(max);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const onGenerate = () => {
    const validation = () => {
      if (!start || !end) {
        warn('', 'Для експорту таблиці, потрібно вказати діапазон кількості елементів', { '!!start': !!start, '!!end': !!end });
        return;
      }
      return true;
    };

    if (!validation()) return;

    setLoading(true);

    const transaction = { start, end };

    request.post(
      '/spreadsheets/exportAppSuggestionsAndComplaints',
      transaction,
      (res) => {
        success('', `Всього знайдено: ${res.length_data}`);
        setLoading(false);
        setData(res.data);
      },
      error,
    );
  };

  const formattedExportData = [
    ['№', 'Філія', 'Дата', 'Телефон', 'Клієнт', 'Куратор', 'Тип', 'Текст'],
    ...(data.length
      ? data.map((item) => [
          item.mi,
          FILII[item.f] || '',
          dayjs(item.date).format('DD.MM.YYYY'),
          item.phone || '',
          item.client || item.name || '',
          item.curator ? users[item.curator]?.name || '' : '',
          item.type || '',
          (item.text || item.suggestion || '').replace(/['"]/g, ''),
        ])
      : []),
  ];

  return (
    <ExportQuantityModal
      title={'Експорт скарг + пропозицій'}
      csvData={formattedExportData}
      onGenerate={onGenerate}
      max={max}
      data={!!data.length}
      loading={loading}
      startA={start}
      setStartA={setStart}
      endA={end}
      setEndA={setEnd}
    />
  );
};

export default ExportSuggestionsAndComplaints;
