import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentHotlineList, setModal } from '../../store/commonReducer';

import { request, success, error } from '../../tools';
import { DECLINE_REASONS, MEET_STATUS } from 'common_constants/business';

import { Card, Typography, Radio, Input, Button } from 'antd';
import SearchSelect from '../../components/SearchSelect';
import { Box } from 'common_components';

const ReasonsDecline = ({ data, toggleCloseBtn, setIsLoading }) => {
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.common.userAuth);
  const list = useSelector((state) => state.common.currentHotlineList);

  const [reason, setReason] = useState(0);
  const [ownReason, setOwnReason] = useState('');
  const [ownReasonError, setOwnReasonError] = useState('');
  const [moved, setMoved] = useState();

  const { TextArea } = Input;
  const { Text } = Typography;

  const ownReasonChangeHandler = (e) => {
    setOwnReason(e.target.value);
    const error = e.target.value.length < 20 && e.target.value;
    setOwnReasonError(error ? 'error' : '');
  };

  const onDeclineMeet = () => {
    const body = { ...data, uristName: name, s: MEET_STATUS.CANCELED, meetDeclineReason: { reason, ownReason, moved } };

    setIsLoading(true);

    request.post(
      '/hotline/confirmQue',
      body,
      () => {
        success();
        const _list = list.map((i) => {
          return i._id === data._id
            ? {
                ...i,
                uristName: name,
                s: MEET_STATUS.CANCELED,
                amm: data.amm,
                amp: data.amp,
                amr: data.amr,
                meetDeclineReason: { reason, ownReason, moved },
                pravo: data.pravo,
                date: data.date,
                startT: data.startT,
                endT: data.endT,
              }
            : i;
        });
        setIsLoading(false);
        dispatch(setModal);
        dispatch(setCurrentHotlineList(_list));
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  return (
    <Card title="Опис відмови від зустрічі." className="decline-options">
      <Box mt={0}>
        <Text>Оберіть причини відмови:</Text>
        {<SearchSelect list={DECLINE_REASONS} disabled={ownReason} value={reason} onChange={(v) => setReason(v)} />}
      </Box>
      <Box mt={8}>
        <Text>Інша причина (Не менше 20 символів)</Text>
        <TextArea
          aria-label="write reason for refusal hotline"
          placeholder="Свій варіант"
          value={ownReason}
          onChange={ownReasonChangeHandler}
          status={ownReasonError}
          autoSize
        />
      </Box>
      <Box mt={16}>
        <Text>Чи перенесено зустріч?</Text>
        <Radio.Group onChange={(e) => setMoved(e.target.value)} value={moved}>
          <Radio value={true}>Перенесено</Radio>
          <Radio value={false}>Не перенесено</Radio>
        </Radio.Group>
      </Box>
      <Box mt={24} className="group-button">
        <Button
          className="decline-reasons-btn"
          type="primary"
          danger
          disabled={ownReasonError || !(ownReason || reason) || moved === undefined}
          onClick={onDeclineMeet}
        >
          Зустріч не відбулась
        </Button>
        <Button className="decline-reasons-btn" type="primary" onClick={toggleCloseBtn}>
          Назад
        </Button>
      </Box>
    </Card>
  );
};

export default ReasonsDecline;
