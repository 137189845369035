import React, { useState } from 'react';
import { Button, Input, Popover, Row } from 'antd';
import { error, request, success } from '../../../../tools/index';

const DevButton = ({ icon, index, loading, setLoading, title, description, route, input, handleAlertShow, setAlertMessage }) => {
  const [open, setOpen] = useState(false);
  const [branchName, setBranchName] = useState();

  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const onSubmit = () => {
    const body = {};
    setLoading(index, true);

    if (branchName) {
      body.branchName = branchName;
    }

    request.post(
      route,
      body,
      (res) => {
        setLoading(index, false);
        handleAlertShow(true);
        setAlertMessage(res.data);
        setBranchName();
      },
      (_, __, axiosError) => {
        error(axiosError.response.data, ' ');
        setLoading(index, false);
        setBranchName();
      },
    );

    hide();
  };

  return (
    <Popover
      content={
        <Row justify={'space-between'}>
          {input ? <Input value={branchName} onChange={(event) => setBranchName(event.target.value)} /> : null}

          <Button type="default" onClick={onSubmit}>
            Так
          </Button>
          <Button type="default" danger onClick={hide}>
            Відміна
          </Button>
        </Row>
      }
      title={title}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button
        className="button"
        style={{ display: 'flex' }}
        loading={loading}
        icon={<img style={{ marginRight: '4px' }} src={icon} alt="RUSHJS" />}
        type="primary"
      >
        {description}
      </Button>
    </Popover>
  );
};

export default DevButton;
