import React, { useState } from 'react';

import { Typography, Row, Col, Card, Alert, Divider } from 'antd';
import RUSHJS from '../../images/rush_js.svg';
import REACT from '../../images/react.svg';
import NODE from '../../images/node.svg';
import GIT_HUB from '../../images/github.svg';
import ANT_DESIGN from '../../images/antdesign.svg';
import { DevButton } from './Partials';

import './Development.scss';

const devButtonsData = [
  {
    icon: GIT_HUB,
    description: 'branch',
    route: '/deploy/gitBranch',
    title: 'Переглянути список локальних гілок?',
  },
  {
    icon: GIT_HUB,
    description: 'pull',
    route: '/deploy/gitPull',
    title: 'Завантажити оновлення?',
  },
  {
    icon: GIT_HUB,
    description: 'checkout',
    route: '/deploy/gitCheckout',
    title: 'Пермкнутись на локальну гілку?',
    input: true,
  },
  {
    icon: GIT_HUB,
    description: 'checkout remote',
    route: '/deploy/gitCheckoutRemote',
    title: 'Завантажити віддалену гілку та перемкнутись на неї?',
    input: true,
  },
  {
    icon: GIT_HUB,
    description: 'branch delete',
    route: '/deploy/gitDeleteLocalBranch',
    title: 'Видалити локальну гілку?',
    input: true,
  },
  {
    icon: RUSHJS,
    description: 'install',
    route: '/deploy/rushInstall',
    title: 'Інсталювати залежності?',
  },
  {
    icon: RUSHJS,
    description: 'update',
    route: '/deploy/rushUpdate',
    title: 'Оновити залежності?',
  },
  {
    icon: ANT_DESIGN,
    description: 'build lib',
    route: '/deploy/commonComponentsBuild',
    title: 'Запустити білд компонентів?',
  },
  {
    icon: REACT,
    description: 'crm build',
    route: '/deploy/reactAppBuild',
    title: 'Запустити білд помічника?',
  },
  {
    icon: REACT,
    description: 'client build',
    route: '/deploy/reactClientBuild',
    title: 'Запустити білд "єАдвокат"?',
  },
  {
    icon: NODE,
    description: 'restart express',
    route: '/deploy/restartExpress',
    title: 'Перезапустити сервер?',
  },
];

const Development = () => {
  const [loadings, setLoadings] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState();

  const handleSetLoading = (buttonIndex, status) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[buttonIndex] = status;
      return newLoadings;
    });
  };

  const handleAlertClose = () => {
    setAlertVisible(false);
  };

  const handleAlertShow = () => {
    setAlertVisible(true);
  };

  const devButtons = devButtonsData.map((button, index) => (
    <DevButton
      key={index}
      index={index}
      icon={button.icon}
      description={button.description}
      route={button.route}
      title={button.title}
      loading={loadings[index]}
      setLoading={handleSetLoading}
      input={button.input}
      handleAlertShow={handleAlertShow}
      setAlertMessage={setAlertMessage}
    />
  ));

  return (
    <Col className="development_page">
      <Row justify={'center'}>
        <Card style={{ width: '100%', textAlign: 'center' }}>
          <Typography.Text variant="h1" style={{ fontSize: '26px' }}>
            Розробка
          </Typography.Text>
        </Card>
        {alertVisible && (
          <Alert
            style={{
              marginTop: '20px',
            }}
            description={
              <>
                <div style={{ whiteSpace: 'pre-wrap' }}>{alertMessage?.stdout}</div>
                <Divider />
                <div style={{ whiteSpace: 'pre-wrap' }}>{alertMessage?.stderr}</div>
              </>
            }
            showIcon={false}
            closable
            onClose={handleAlertClose}
          />
        )}
      </Row>

      <Row justify={'center'}>
        <Card className="buttons">{devButtons}</Card>
      </Row>
    </Col>
  );
};

export default Development;
