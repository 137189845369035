import { Alert } from 'antd';

export default function WhichDayAlert({ tip, fil, type }) {
  if (!tip || !fil || !type) return null;
  return (
    <Alert
      message="Запис"
      description={
        <>
          <b> На який день та час Вас записати на прийом?</b>
        </>
      }
      type="info"
      showIcon
    />
  );
}
