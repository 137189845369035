import { Collapse } from 'antd';
import { useSelector } from 'react-redux';
import {
  PRAVO,
  SERVIS_OPTIONS,
  REPEATS,
  HOTLINE_ADV_WHERE,
  HOTLINE_ADV_WHERE_MORE,
  PRAVO_TYPES,
  PAYMENT_FORMAT,
  FORM_OF_BUSINESS,
  TOWNS,
  JOBS,
  SOC_STATUS,
  WORK_STATES,
  HOBBIES,
  RELATIONS,
} from 'common_constants/business';

const { Panel } = Collapse;

const HotlineContractDetails = (props) => {
  const { contract, client } = props;
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);

  return (
    <>
      <Collapse className="journal-card-body__item">
        <Panel header="Договір">
          <div className="journal-card-body-order-inner">
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">ОВ: </span>
              {contract.ov1 ?? '-'} {contract.ov2 ?? '-'} {contract.ov3 ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Дата підписання: </span>
              {contract.sd ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Уклав: </span>
              {users ? users[contract.us]?.name : '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Хто буде обслуговувати: </span>
              {SERVIS_OPTIONS[contract.un] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Офіс/дист: </span>
              {contract.od === 1 ? 'Дист' : 'Офіс'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Повторно: </span>
              {REPEATS[contract.tm] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Як дізнався/вид: </span>
              {HOTLINE_ADV_WHERE[contract.aw] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Що саме: </span>
              {HOTLINE_ADV_WHERE_MORE[contract.am] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Дата 1п:</span>
              {contract.md ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Сума(1п?): </span>
              {contract.fp ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">№ ККАОЗ:</span>
              {contract.kk ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Вид правовідносин: </span>
              {PRAVO_TYPES[contract.pr] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Вид правовідносин Що саме:</span>
              {PRAVO[contract.prc] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Предмет договору: </span>
              {contract.ci ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Своїми словами: </span>
              {contract.cd ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Форма гонорару: </span>
              {PAYMENT_FORMAT[contract.pf] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Сума гонорару: </span>
              {contract.pa ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Кільк міс: </span>
              {contract.pm === 13 ? 'бескінечно' : contract.pm ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Вихід адв: </span>
              {contract.ea ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Оплатив: </span>
              {contract.P ?? '-'}
            </div>
          </div>
        </Panel>
      </Collapse>
      <Collapse>
        <Panel header="Клієнт">
          <div className="journal-card-body-client-inner">
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">ПІБ: </span>
              {client.n ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Стать: </span>
              {client.se === 1 ? 'ч' : 'ж'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">ФО/ФОП/ЮО: </span>
              {FORM_OF_BUSINESS[client.u] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">День народження: </span>
              {client.b ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Філія: </span>
              {FILII[client.f] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Місто народження: </span>
              {TOWNS[client.t] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Адреса: </span>
              {client.a ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Телефон: </span>
              {client.ph ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Пароль: </span>
              {client.p ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">E-mail: </span>
              {client.email ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Професія: </span>
              {JOBS[client.j] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Соц статус: </span>
              {SOC_STATUS[client.ss] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Службове становище: </span>
              {WORK_STATES[client.ws] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Хоббі: </span>
              {HOBBIES[client.h] ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Зв'язки: </span>
              {client.r?.map((ri) => `${RELATIONS[ri?.name] || '?'} - ${ri?.phone || '?'}  | `)}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Характеристики: </span>
              {client.ch1 ?? '-'} {client.ch2 ?? '-'} {client.ch3 ?? '-'} {client.ch4 ?? '-'} {client.ch5 ?? '-'} {client.ch6 ?? '-'}
            </div>
            <div className="journal-card-body__item">
              <span className="journal-card-body__subtitle">Бонусний рахунок: </span>
              {client.B ?? '-'}
            </div>
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default HotlineContractDetails;
