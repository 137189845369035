import React, { useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { TableOutlined, MenuOutlined, DownOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Pagination, Spin, Typography, Table, Divider, Empty, Tabs, Row as AntdRow } from 'antd';
import { Row, Box } from 'common_components';
import { CLUB_CARDS_TYPES } from 'common_constants/business';

import { ADD_CLUB_CARD } from 'common_constants/modals';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../store/commonReducer';
import { error, request } from '../../tools';
import ClubCardInfo from './ClubCardInfo';
import { ROLES } from 'common_constants/business';
import dayjs from 'dayjs';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
import SearchSelectClubCardType from '../../components/SearchSelect/SearchSelectClubCardType';

import PageInfo from '../../components/PageInfo/PageInfo';

import './ClubСards.scss';

const items = [
  { label: 'Усі', key: 'allCC' },
  { label: 'Активні', key: 'active' },
  { label: 'Архівні', key: 'archival' },
  { label: 'Майбутні', key: 'future' },
];

const DEFAULT_PAGE_SIZE = 10;

const ClubСards = () => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);
  const mob = useSelector((state) => state.ui.mob);

  const userAuth = useSelector((state) => state.common.userAuth);

  const manager = ROLES[userAuth.role] <= ROLES.manager;
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;

  const [total, setTotal] = useState(0);
  const [ClubCardsList, setClubCardsList] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});

  const [activeTab, setActiveTab] = useState('allCC');
  const [displayMode, setDisplayMode] = useState('list');

  const { Item } = Form;
  const { Text } = Typography;

  const getAllListClubCards = async (limits, activeTab) => {
    setLoading(true);
    await request.post(
      '/clubCards/getClubCardsList',
      { limits, activeTab },
      ({ data, total }) => {
        setClubCardsList(data ?? []);
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const limits = { page: currentPage, pageSize, ...currentFilters };
    getAllListClubCards(limits, activeTab);
  }, [currentPage, currentFilters, activeTab]);

  const handleFilterChange = (values) => {
    setCurrentFilters(values);
    setCurrentPage(1);
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const expandable = mob
    ? null
    : {
        expandRowByClick: true,
        expandedRowRender: (item) => <ClubCardInfo key={item.primeCards._id} data={item} />,
      };
  const rowClassName = (record, index) => {
    return record.primeCards.cardType === CLUB_CARDS_TYPES.PLATINUM ? 'platinum-row' : 'gold-row';
  };

  const columns = mob
    ? [
        Table.EXPAND_COLUMN,
        {
          title: 'Власник карти',
          dataIndex: 'n',
          key: 'n',
        },
        {
          title: 'Телефон:',
          dataIndex: 'ph',
          key: 'ph',
        },
        {
          title: 'Період дії (місяців):',
          dataIndex: 'primeCards',
          key: 'primeCards',
          render: ({ services }) => Object.keys(services).length,
        },
      ]
    : [
        Table.EXPAND_COLUMN,
        {
          title: 'Філія',
          dataIndex: 'f',
          key: 'f',
          render: (f) => FILII[f],
        },
        {
          title: 'Власник карти',
          dataIndex: 'n',
          key: 'n',
        },
        {
          title: 'Телефон:',
          dataIndex: 'ph',
          key: 'ph',
        },
        {
          title: 'Дата створення:',
          dataIndex: 'primeCards',
          key: 'primeCards',
          render: ({ createdAt }) => dayjs(createdAt).format('DD.MM.YYYY'),
        },
        {
          title: 'Дата закінчення дії:',
          dataIndex: 'primeCards',
          key: 'primeCards',
          render: ({ expiredAt }) => dayjs(expiredAt).format('DD.MM.YYYY'),
        },
        {
          title: 'Період дії (місяців):',
          dataIndex: 'primeCards',
          key: 'primeCards',
          render: ({ services }) => Object.keys(services).length,
        },
      ];

  if (!users) return null;

  return (
    <div className="club_card_page default_page">
      <AntdRow justify={'center'} align={'middle'}>
        <h2>Клубні карти</h2>
        <PageInfo page="pushNotification" />
      </AntdRow>
      {manager && (
        <Row justify={'center'} style={{ margin: '10px 0', justifyContent: 'center' }}>
          <Button
            type="primary"
            className="button"
            onClick={() => dispatch(setModal({ name: ADD_CLUB_CARD, data: { inspector: inspector, author: userAuth } }))}
          >
            <PlusCircleOutlined /> Додати
          </Button>
        </Row>
      )}
      <Tabs className="tabs" activeKey={activeTab} onChange={handleTabChange} items={items} />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: 6 }} onClick={() => setDisplayMode('list')} disabled={displayMode === 'list'}>
          <MenuOutlined />
        </Button>
        <Button onClick={() => setDisplayMode('table')} disabled={displayMode === 'table'}>
          <TableOutlined />
        </Button>
      </div>
      <br />

      <div className="row align filters" onClick={() => setFiltersOpen(!filtersOpen)}>
        {filtersOpen ? <DownOutlined /> : <RightOutlined />} <h2>Пошук</h2>
      </div>

      {filtersOpen && (
        <Card className="filtersContainer">
          <Form onFinish={handleFilterChange} layout="inline" initialValues={{ fil: inspector ? '' : userAuth.fil }}>
            <div style={{ width: '320px' }}>
              <Divider children={'Фільтри:'} style={{ margin: 0 }} orientation="left" />
              <Box>
                <Text>Філія: </Text> &nbsp;
                <Item name="fil" initialValue={!inspector ? userAuth.fil : undefined} children={<SearchSelectFil disabled={!inspector} />} />
              </Box>
              <Box>
                <Text>Власник карти: </Text> &nbsp;
                <Item name="name" children={<Input />} />
              </Box>
              <Box>
                <Text>Телефон власника карти: </Text> &nbsp;
                <Item name="ph" children={<Input />} />
              </Box>
              <Box>
                <Text>Тип карти: </Text> &nbsp;
                <Item name="cardType" children={<SearchSelectClubCardType />} />
              </Box>
              <br />
              <Box>
                <Button style={{ width: 150 }} type="primary" htmlType="submit">
                  Пошук <SearchOutlined />
                </Button>
              </Box>
            </div>
          </Form>
        </Card>
      )}

      <Spin spinning={loading} tip="Зачекайте" size="large">
        {displayMode === 'list' ? (
          <>
            {ClubCardsList?.length ? (
              ClubCardsList?.map((item) => {
                return <ClubCardInfo key={item.primeCards._id} data={item} />;
              })
            ) : (
              <Empty description={<Text>Немає клубних карт</Text>} />
            )}
            <Pagination
              style={{ textAlign: 'center' }}
              current={currentPage}
              pageSize={DEFAULT_PAGE_SIZE}
              total={total}
              onChange={handlePageChange}
            />
          </>
        ) : (
          <Table
            dataSource={ClubCardsList}
            columns={columns}
            rowClassName={rowClassName}
            rowKey={(item) => item.primeCards._id}
            loading={loading}
            expandable={expandable}
            pagination={{ total, current: currentPage }}
            onChange={handleTableChange}
          />
        )}
      </Spin>
    </div>
  );
};

export default ClubСards;
