import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editBankCardsItem, setModal } from '../../store/commonReducer';
import { useResize } from 'common_components';

import { Card, Tag, Typography, Spin, Popconfirm } from 'antd';
import { volcano } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { ADD_BANK_CARDS } from 'common_constants/modals';
import { Box } from 'common_components';
import { TYPES_OF_BANK_ACCOUNTS } from 'common_constants/business';
import './BankCards.scss';
import { request, error, success, warn, info } from '../../tools';

const BankCardstem = ({ item }) => {
  const filii = useSelector((state) => state.common.filii);
  const filName = filii.find((fil) => fil.i === item.f)?.name;
  const dispatch = useDispatch();
  const resize = useResize();
  const [{ Grid }, { Title, Text, Paragraph, Link }] = [Card, Typography];

  const core_megaState = {
      loading: false, //* Завантаження
    },
    [megaState, setMegaState] = useState(core_megaState);

  const [styleType, styleStandart] = [item.d ? { backgroundColor: volcano[2] } : {}, { width: resize[0] >= 500 ? 350 : 300, margin: 8 }],
    styleCardAll = { ...styleType, ...styleStandart };

  const deleteCard = async () => {
    const validation = (id) => {
      if (!id) {
        warn('', 'Для завантаження документу, потрібно вказати дійсний документ.', { id: !!id });
        return;
      }

      return true;
    };

    if (!validation(item._id)) return;

    setMegaState((prev) => ({ ...prev, loading: !core_megaState.loading }));

    await request.delete(
      '/bankCards/',
      item._id,
      () => {
        dispatch(editBankCardsItem({ _id: item._id, d: true }));
        success('', 'Рахунок успішно видалено!');
      },
      error,
    );

    setMegaState((prev) => ({ ...prev, loading: core_megaState.loading }));
  };

  const cancel = () => info('', 'Ви не згодні зі своїм рішенням.');

  return (
    <Grid className="lib-item">
      <Spin tip="Зачекайте, йде видалення рахунку." spinning={megaState.loading} size="large">
        <Card
          title={
            <Box mt={0}>
              <Tag
                color={+item.z >= 0 ? TYPES_OF_BANK_ACCOUNTS[+item.z]?.color : 'error'}
                children={+item.z >= 0 ? TYPES_OF_BANK_ACCOUNTS[+item.z]?.label : 'Bad code'}
              />
              {`#${item.i ?? ''}`}
            </Box>
          }
          style={styleCardAll}
          {...(item.d
            ? {}
            : {
                actions: [
                  <Link key="edit" onClick={() => dispatch(setModal({ name: ADD_BANK_CARDS, data: item }))}>
                    <EditOutlined />
                  </Link>,

                  <Popconfirm
                    key="deleted"
                    title="Підтвердження"
                    description="Ви впевнені, що хочете видалити цей рахунок?"
                    onConfirm={deleteCard}
                    onCancel={cancel}
                    okText="Гаразд"
                    cancelText="Скасувати"
                  >
                    <Link>
                      <DeleteOutlined />
                    </Link>
                  </Popconfirm>,
                ],
              })}
        >
          <Box mt={0} style={{ marginBottom: 0 }}>
            {item.d && <Title level={5} style={{ margin: 0, textAlign: 'center' }} type="danger" children="Видалено" />}
            {filName && (
              <div>
                <Text strong>Філія:</Text>
                <Paragraph>
                  <blockquote style={{ whiteSpace: 'pre-wrap' }}>{filName}</blockquote>
                </Paragraph>
              </div>
            )}

            <div>
              <Text strong>Назва рахунку:</Text>
              <Paragraph>
                <blockquote style={{ whiteSpace: 'pre-wrap' }}>{item.n}</blockquote>
              </Paragraph>
            </div>

            <div>
              <Text strong>Текст СМС:</Text>
              <Paragraph>
                <blockquote style={{ whiteSpace: 'pre-wrap' }}>{item.m}</blockquote>
              </Paragraph>
            </div>
          </Box>
        </Card>
      </Spin>
    </Grid>
  );
};

export default BankCardstem;
