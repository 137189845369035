import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin, Modal, DatePicker, Row, Col } from 'antd';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

import { setModal } from '../../store/commonReducer';
import Excel from '../../images/excel_logo.svg';
import { request, error, success, info } from '../../tools';
import { ASSIGNMENTS_SERVICES_TYPE } from 'common_constants/business';

import './ExportAssignments.scss';

const ExportAssignments = () => {
  const dispatch = useDispatch();
  const courtsList = useSelector((state) => state.common.courtsList);
  const FILII = useSelector((state) => state.common.FILII);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const dateFormat = 'YYYY.MM.DD';

  const onGenerate = () => {
    if (!fromDate || !toDate) {
      info('', 'Для вивантаження даних, потрібно обрати дати відбору.');
      return;
    }

    setLoading(true);
    request.post(
      '/spreadsheets/exportAssignments',
      { fromDate, toDate },
      (req) => {
        success('', `Всього знайдено: ${req.data.length}`);
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };

  const csvData = [
    [
      'Номер виходу',
      'Договір',
      'Філія',
      'Час',
      'Послуга',
      'Місце',
      'Суддя',
      'Суд',
      'Судова справа',
      'Адвокат',
      'Сума клієнта',
      'Сума для адвоката',
      'Оплата клієнта',
      'Статус виходу',
      'Коментар дял своїх',
      'Коментар дял клієнта',
      'Автор',
      'Дата створення',
      'Помилковий',
    ],
  ];

  data?.forEach((assignment) => {
    csvData.push([
      assignment.i ?? '-',
      assignment.contractNumber ?? '-',
      FILII[assignment.f] ?? '-',
      assignment.selectedTime ?? '-',
      ASSIGNMENTS_SERVICES_TYPE[assignment.serviceType] ?? '-',
      assignment?.place?.placeName ?? '-',
      assignment?.judge?.name ?? '-',
      courtsList.find((court) => assignment?.court === court.i)?.name ?? '-',
      assignment.courtCase ?? '-',
      assignment?.lawyer?.name ?? '-',
      assignment.clientAmount ?? '-',
      assignment.amountForLawyer ?? '-',
      assignment.harvests.amounts.length ? assignment.harvests.amounts.join(', ') : '0',
      assignment?.report?.assignmentStatus === '1' ? 'відбувся' : 'не відбувся',
      assignment.mainComment ?? '-',
      assignment.commentForClient ?? '-',
      assignment?.author?.name ?? '-',
      dayjs(assignment.createdAt)?.format('DD.MM.YYYY HH:mm:ss') ?? '-',
      assignment.incorrect ? 'помилковий' : '-',
    ]);
  });

  return (
    <Modal open className="export-assignments" title="Експорт виходів" onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Col>
          <Row className="export-assignments-dateRange-row" justify={'space-between'}>
            <Col className="export-assignments-dateRange-row-item">
              <Row>З</Row>
              <DatePicker onChange={(value) => setFromDate(value)} format={dateFormat} />
            </Col>

            <Col className="export-assignments-dateRange-row-item">
              <Row>По</Row>
              <DatePicker onChange={(value) => setToDate(value)} format={dateFormat} />
            </Col>
          </Row>

          <Row style={{ marginTop: '20px' }} justify={'center'}>
            {data ? (
              <CSVLink
                filename={`assignments_from_${dayjs(fromDate).format('DD_MM_YYYY')}_to_${dayjs(toDate).format('DD_MM_YYYY')}.csv`}
                asyncOnClick={true}
                data={csvData}
              >
                <Button className="excel">
                  <img className="excel-img" src={Excel} alt="Excel" /> Скачати .csv
                </Button>
              </CSVLink>
            ) : (
              <Button onClick={onGenerate}>Генерувати</Button>
            )}
          </Row>
        </Col>
      </Spin>
    </Modal>
  );
};

export default ExportAssignments;
