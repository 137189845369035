import { request } from '../../../tools';

const getPlaces = async (handleSetPlacesList, handleSetMainLoader) => {
  return new Promise((resolve, reject) => {
    request.post(
      '/place/get',
      {},
      (res) => {
        handleSetPlacesList(res.places);
        resolve();
      },
      (error) => {
        handleSetMainLoader(false);
        reject(error);
      },
    );
  });
};

export default getPlaces;
