import { Alert } from 'antd';
import React from 'react';

export default function DistantAlert({ type, tip }) {
  if (type !== 3 || !tip) return null;
  return (
    <Alert
      message="Дистанційна"
      description={
        <>
          <b>
            Якщо Вам не зручно отримання консультації в офісі, я можу записати Вас на дистанційну консультацію по телефону. Дистанційні консультації у
            нас платні. Вартість звичайної консультації юриста загальної практики складає 600 грн. Також у нас є Преміум консультації. Їх проводять
            фахові, галузеві спеціалісти, одні з найкращих адвокатів України. Вартістю Преміум консультації складає 1200 грн. Якщо Ви забронюєте час
            для консультації, оплативши 500 грн. за звичайну консультацію, або 1000 грн. за Преміум консультацію, протягом 2-х годин, то консультація
            буде з відповідною знижкою. На яку консультацію Вас записати, на звичайну чи Преміум?
          </b>{' '}
          <br />
          Записуємо його на звичайну або Преміум консультацію, за Вашим бажанням, на свою філію (у випадку коли у Вас вільно), або за списком в СРМ).
          Якщо людина відмовляється від запису, кажемо: <br />{' '}
          <b>
            Безкоштовно, я можу запропонувати Вам лише довідкову консультацію, у нашому Інформаційному центрі, яку проводять юристи початківці
            Адвокатського обʼєднання «Захист».{' '}
          </b>
          <br />
          Якщо людина погодилася, записуємо його консультацію до Інформаційного центру.
        </>
      }
      type="info"
      showIcon
    />
  );
}
