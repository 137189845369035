import React, { useEffect, useState } from 'react';
import { EXPENSE_CATEGORIES } from 'common_constants/business';
import { Select } from 'antd';

const SearchSelect = ({
  mode,
  className,
  list,
  value,
  onChange,
  disabled,
  placeholder,
  placeholder_topOption = true,
  valueName,
  labelName,
  customLabel,
  style,
}) => {
  return (
    <Select
      showSearch
      mode={mode}
      placeholder={placeholder}
      filterOption={(input, option) => option.children.toString()?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
      className={className}
      value={value === undefined ? '' : value}
      onChange={(v, re) => {
        onChange(v);
      }}
      disabled={disabled}
      style={style}
    >
      {placeholder_topOption && placeholder && <Select.Option value="">{placeholder}</Select.Option>}
      {Array.isArray(list)
        ? list?.map((i, index) => {
            const val = customLabel?.(i) ?? i[labelName] ?? i;
            if (['-', '_'].includes(val)) return null;
            return (
              <Select.Option key={i[valueName] ?? index} value={i[valueName] ?? index}>
                {val}
              </Select.Option>
            );
          })
        : Object.keys(list).map((e) => {
            const val = list[e];
            if (['-', '_'].includes(val)) return null;
            return (
              <Select.Option key={e} value={Number(e)}>
                {val}
              </Select.Option>
            );
          })}
    </Select>
  );
};

const SearchSelectSubCategory = ({ onChange, value, disabled, className, style, category }) => {
  const [internalValue, setInternalValue] = useState(value);
  const [categoryV, setCategoryV] = useState(category);

  useEffect(() => {
    if (category === categoryV) {
      setInternalValue(value);
    } else {
      setCategoryV(category);
      setInternalValue(undefined);
    }
  }, [value, category]);

  return (
    <SearchSelect
      className={className}
      style={{ minWidth: 200, ...style }}
      disabled={disabled}
      placeholder="Всі категорії"
      list={
        (category &&
          EXPENSE_CATEGORIES.find((i) => i.value === +category)?.subcategories.map((subCategory) => ({
            value: subCategory.value + '',
            label: subCategory.label,
          }))) ??
        []
      }
      value={internalValue ?? ''}
      valueName="value"
      labelName="label"
      onChange={(newValue) => {
        setInternalValue(newValue);
        onChange(newValue);
      }}
    />
  );
};

export default SearchSelectSubCategory;
