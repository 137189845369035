import React from 'react';
import { Select } from 'antd';

const billSelectGeneralFinalResult = [
  { 0: 'Немає договора та клієнта', 1: 'Є готовий договір та клієнт' },
  ['Кінцевий результат по консультації', 'finalR_H'],
  ['Кінцевий результат', 'finalR'],
];

const SelectGeneralFinalResult = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть кінцевий результат"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі результати</Option>
      <Option value="0">Немає договора та клієнта</Option>
      <Option value="1">Є готовий договір та клієнт</Option>
    </Select>
  );
};

export { billSelectGeneralFinalResult, SelectGeneralFinalResult };
