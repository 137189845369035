import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import UserAvatar from '../UserAvatar';

import './SelectMembers.scss';

const SelectMembers = ({ selectedMembers, setSelectedMembers, disabledUsers }) => {
  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);

  const [inputSearch, setInputSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usersArray, setUsersArray] = useState(false);

  useEffect(() => {
    if (!users) {
      return;
    }

    let allUsers = Object.values(users);
    allUsers = allUsers.filter((item) => !item?.fired && item?._id !== userAuth?._id)?.sort((a, b) => a?.p?.toLowerCase() > b?.p?.toLowerCase());

    setUsersArray(allUsers);
  }, [users]);

  useEffect(() => {
    let users = usersArray.length > 0 ? usersArray.filter((user) => user?.p.toLowerCase().includes(inputSearch.toLowerCase())) : [];
    setFilteredUsers(users);
  }, [inputSearch, usersArray]);

  const onChangeInputSearch = (e) => {
    setInputSearch(e.target.value);
  };

  const onClickUserHandle = (userId) => {
    if (disabledUsers?.includes(userId)) return;
    const usersFiltered = selectedMembers?.filter((item) => item !== userId) ?? [];

    if (selectedMembers?.includes(userId)) {
      setSelectedMembers(usersFiltered);
      return;
    }

    setSelectedMembers([...usersFiltered, userId]);
  };

  console.log('selectedMembers', selectedMembers);

  return (
    <div className="select-members">
      <div>
        <Input
          className="input-search-users"
          value={inputSearch}
          onChange={onChangeInputSearch}
          placeholder="Шукати користувачів"
          suffix={<SearchOutlined />}
        />
      </div>
      <div className="users-wrapper">
        {filteredUsers?.map((item, index) => (
          <div
            className={clsx('user-item', selectedMembers?.includes(item._id) && '_active', disabledUsers?.includes(item._id) && '_disabled')}
            onClick={() => onClickUserHandle(item._id)}
            key={index}
          >
            <div>
              <UserAvatar size="large" user={item} />
            </div>
            <p>{item.p}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectMembers;
