import React from 'react';
import { Button, Collapse, Typography } from 'antd';
import { useDispatch } from 'react-redux';

import {
  EXPORT_PAYMENT_QUICK_CHAT,
  EXPORT_ACTUALIZATIONS,
  EXPORT_CONTRACTS_CLIENTS,
  EXPORT_ARCHIVE_CONTRACTS,
  EXPORT_MONTHLY_PAYMENT,
  EXPORT_HOTLINE_QUESTIONNAIRES,
  EXPORT_ARCHIVAL_QUESTIONNAIRES,
  EXPORT_ALL_HOTLINE,
  EXPORT_INCOMES,
  EXPORT_CORRESPONDENCE,
  MOVE_TO_ARCHIVE,
  EXPORT_PAYMENTS,
  EXPORT_LIBRARY,
  EXPORT_CLIENTS_COSTS,
  EXPORT_LOAD,
  EXPORT_DISABLED_PEOPLE,
} from 'common_constants/modals';

import { setModal } from '../../../../store/commonReducer';

import Excel from '../../../../images/excel_logo.svg';
import Archive from '../../../../images/archive.png';

const ExportRow = () => {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const { Panel } = Collapse;
  return (
    <Collapse>
      <Panel header="Експорти / Імпорти" key="1">
        <div className="export_row">
          <div>
            <Button className="archiveBtn exportBtn" onClick={() => dispatch(setModal({ name: MOVE_TO_ARCHIVE }))}>
              <img src={Archive} alt="Excel" style={{ width: 44, left: 0 }} /> <Text>Імпорт архівних контрактів</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_ARCHIVE_CONTRACTS }))}>
              <img src={Excel} alt="Excel" width={24} />
              <Text className="exp-excel">Експорт архівних контрактів</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_CONTRACTS_CLIENTS }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт усіх контрактів</Text>
            </Button>
          </div>
          <hr style={{ marginBottom: 12 }} />
          <div>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_PAYMENT_QUICK_CHAT }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт списань швидкого чату</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_HOTLINE_QUESTIONNAIRES }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт анкет після консультацій</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_ARCHIVAL_QUESTIONNAIRES }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт анкет після контрактів</Text>
            </Button>
          </div>
          <hr style={{ marginBottom: 12 }} />
          <div>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_ACTUALIZATIONS }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт актуалізацій</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_MONTHLY_PAYMENT }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт жнив</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_INCOMES }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт доходів</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_PAYMENTS }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт оплат</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_CLIENTS_COSTS }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт витрат клієнтів</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_DISABLED_PEOPLE }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp;{' '}
              <Text className="exp-excel">Експорт клієнтів, які не можуть користуватися єАдвокат</Text>
            </Button>
          </div>
          <hr style={{ marginBottom: 12 }} />
          <div>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_ALL_HOTLINE }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт консультацій</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_CORRESPONDENCE }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт кореспонденції</Text>
            </Button>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_LIBRARY }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт бібліотеки</Text>
            </Button>
          </div>
          <hr style={{ marginBottom: 12 }} />
          <div>
            <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_LOAD }))}>
              <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт вигрузки навантаження</Text>
            </Button>
          </div>
          <hr style={{ marginBottom: 12 }} />
        </div>
      </Panel>
    </Collapse>
  );
};

export default ExportRow;
