import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { EXPENSE_CATEGORIES, ASSIGNMENTS_SERVICES_TYPE } from 'common_constants/business';
import Item from '../../pages/List/Item';
import SugarInfo from '../Sugar/SugarInfo';
import ExpenseInfo from '../Expense/ExpenseInfo';
import AssignmentItem from '../Assingment/AssignmentsPartials/AssignmentItem';
import HotlineRequestBody from './HotlineRequestBody';
import './JournalCardBody.scss';

const JournalCardBody = (props) => {
  const { log } = props;

  const FILII = useSelector((state) => state.common.FILII);

  const contract = log?.contract ?? {};
  const client = log?.client ?? {};
  const hotline = log?.hotline ?? {};
  const action = log?.action ?? '';
  const requestBody = log.reqBody;

  hotline.date = dayjs(hotline.date);
  hotline.crAt = dayjs(hotline.crAt);
  const formatValue = (key, value) => {
    let formattedValue;
    switch (key) {
      case 'expenseId':
        formattedValue = (
          <>
            Посилання на витрату: <span className="value">"{value}"</span>
          </>
        );
        break;
      case 'month':
        formattedValue = (
          <>
            Місяць: <span className="value">"{value}"</span>
          </>
        );
        break;
      case 'year':
        formattedValue = (
          <>
            Рік: <span className="value">"{value}"</span>
          </>
        );
        break;
      case 'name':
        formattedValue = (
          <>
            ПІБ: <span className="value">"{value}"</span>
          </>
        );
        break;
      case 'sortNumber':
        formattedValue = (
          <>
            sortNumber: <span className="value">"{value}"</span>
          </>
        );
        break;
      case 'sortDate':
        formattedValue = (
          <>
            sortDate: <span className="value">"{value}"</span>
          </>
        );
        break;
      case 'category':
        formattedValue = (
          <>
            Категорія витрати: <span className="value">"{EXPENSE_CATEGORIES[value].label}"</span>
          </>
        );
        break;
      case 'subCategory':
        formattedValue = (
          <>
            Підкатегорія витрати: <span className="value">"{EXPENSE_CATEGORIES[log.reqBody.category].subcategories[value].label}"</span>
          </>
        );
        break;

      case 'serviceTypeFilter':
        formattedValue = (
          <>
            Тип виходу: <span className="value">"{ASSIGNMENTS_SERVICES_TYPE[value]}"</span>
          </>
        );
        break;

      case 'payfor':
        formattedValue = (
          <>
            Послуга: <span className="value">"{value}"</span>
          </>
        );
        break;
      case 'dateRange':
        formattedValue = (
          <>
            Період:{' '}
            <span className="value">
              від {dayjs(value.$gte).format('DD.MM.YYYY')} до {dayjs(value.$lte).format('DD.MM.YYYY')}
            </span>
          </>
        );
        break;
      case 'assignmentId':
        formattedValue = (
          <>
            ID виходу: <span className="value">{value}</span>
          </>
        );
        break;
      case 'contractId':
        formattedValue = (
          <>
            ID контракту: <span className="value">{value}</span>
          </>
        );
        break;

      case 'contractIndex':
        formattedValue = (
          <>
            Номер договору: <span className="value">{value}</span>
          </>
        );
        break;
      case 'contractNumberFilter':
        formattedValue = (
          <>
            Номер договору: <span className="value">{value}</span>
          </>
        );
        break;
      case 'filFilter':
        formattedValue = (
          <>
            Філія: <span className="value">{FILII[value]}</span>
          </>
        );
        break;

      case 'fil':
        formattedValue = (
          <>
            Філія: <span className="value">{FILII[value]}</span>
          </>
        );
        break;
      case 'i':
        formattedValue = (
          <>
            Номер платежу: <span className="value">{value}</span>
          </>
        );
        break;
      case 'author':
        formattedValue = (
          <>
            Автор: <span className="value">{value}</span>
          </>
        );
        break;
      case 'tab':
        formattedValue = (
          <>
            Пошук по розділу: <span className="value">{value}</span>
          </>
        );
        break;
      case 'onlyArchived':
        formattedValue = (
          <>
            Архівні: <span className="value">{value ? 'так' : 'ні'}</span>
          </>
        );
        break;
      default:
        formattedValue = (
          <>
            <span className="value">{JSON.stringify(value)}</span>
          </>
        );
        break;
    }

    return formattedValue;
  };

  switch (action) {
    case 'Додав новий вихід':
      return <AssignmentItem key={log?.assignment?._id} assignment={log?.assignment} />;
    case 'Редагував вихід':
      return <AssignmentItem key={log?.assignment?._id} assignment={log?.assignment} />;
    case 'Пошук по виходам':
      return (
        <div key={log._id}>
          <h3>Критерії пошуку:</h3>
          <ul className="journal-search-sugar-list">
            {Object.entries(log.reqBody).map(([key, value]) => {
              if (!value || key === 'page' || key === 'pageSize' || key === 'myOnly' || key === 'all') return null;
              return (
                <li key={key} className="search-item">
                  {formatValue(key, value)}
                </li>
              );
            })}
          </ul>
        </div>
      );
    case 'Додав нову витрату':
      return <ExpenseInfo key={log?.expense?._id} data={log?.expense} />;
    case 'Редагував витрату':
      return <ExpenseInfo key={log?.expense?._id} data={log?.expense} />;
    case 'Пошук по витратам':
      return (
        <div key={log._id}>
          <h3>Критерії пошуку:</h3>
          <ul className="journal-search-sugar-list">
            {Object.entries(log.reqBody).map(([key, value]) => {
              if (!value || key === 'page' || key === 'pageSize') return null;
              return (
                <li key={key} className="search-item">
                  {formatValue(key, value)}
                </li>
              );
            })}
          </ul>
        </div>
      );
    case 'Додав новий сахарок':
      return <SugarInfo key={log.sugar._id} data={log.sugar} />;
    case 'Редагував сахарок':
      return <SugarInfo key={log.sugar._id} data={log.sugar} />;
    case 'Пошук по сахаркам':
      return (
        <div key={log._id}>
          <h3>Критерії пошуку:</h3>
          <ul className="journal-search-sugar-list">
            {Object.entries(log.reqBody).map(([key, value]) => {
              if (!value || key === 'page' || key === 'pageSize') return null;
              return (
                <li key={key} className="search-item">
                  {formatValue(key, value)}
                </li>
              );
            })}
          </ul>
        </div>
      );
    case 'Пошук по клієнтам':
      return (
        <div key={log._id}>
          <h3>Критерії пошуку:</h3>
          <ul className="journal-search-sugar-list">
            {Object.entries(log.reqBody).map(([key, value]) => {
              if (!value || key === 'page' || key === 'pageSize') return null;
              return (
                <li key={key} className="search-item">
                  {formatValue(key, value)}
                </li>
              );
            })}
          </ul>
        </div>
      );
    case 'Звітує про зустріч':
      return <Item i={hotline} isInJournal={true} meetReport={true} requestBody={requestBody} />;
    case 'Додав новий договір':
      return <Item i={hotline} client={client} contract={contract} isInJournal={true} changeContract={true} requestBody={requestBody} />;
    case 'Оновив договір':
      return <Item i={hotline} client={client} contract={contract} isInJournal={true} changeContract={true} requestBody={requestBody} />;
    case 'Встановив кількість кабінетів':
      return (
        <div className="journal-card-body__add-hotline">
          <div className="journal-card-body__item">
            <span className="journal-card-body__subtitle">Філія: </span>
            {FILII[log.fil || '-']}
          </div>
          <div className="journal-card-body__item">
            <span className="journal-card-body__subtitle">Кабінети: </span>
            {log.count || '-'}
          </div>
          <HotlineRequestBody requestBody={requestBody} />
        </div>
      );
    case 'Розіслав усім СМС':
      return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Розіслав усім СМС</div>;

    default:
      return <Item i={hotline} isInJournal={true} requestBody={requestBody} />;
  }
};

export default JournalCardBody;
