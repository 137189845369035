import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined, CloseSquareOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, Form, Input, InputNumber, Row, Space, Switch } from 'antd';
import { CURRENCIES, ROLES, UKRAINE_STATES } from 'common_constants/business';
import SearchSelect from '../../components/SearchSelect/SearchSelect';
import { addFilItem, editFilItem } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import FilPhotoAdd from './FilPhotoAdd';
import { setBankCardsList } from '../../store/commonReducer';
import SearchSelectCurrencies from '../../components/SearchSelect/SerchSelectCurrencies';

const defaultCurrency = 'UAH';

const FilForm = ({ filii, isEditForm, editItem = {}, setIsEditForm, setOpenFilForm, setEditItem }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);
  const [loading, setLoading] = useState(false);
  const [editableIndex, setEditableIndex] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    editItem?.image && editItem?.ext ? process.env.REACT_APP_API + `/filiiImages/${editItem.image}.${editItem.ext}` : null,
  );
  const [currentManager, setCurrentManager] = useState();
  const [currentUserId, setCurrentUserId] = useState(editItem?.managerId);
  const [currency, setCurrency] = useState(defaultCurrency);
  const [form] = Form.useForm();
  const data = { ...editItem, states: editItem.states || [] };
  const activeUsers = Object.values(users || {}).filter((item) => !item.fired);
  const currentManagerAvatar = process.env.REACT_APP_API + `/avatars/${currentManager?.upa}.jpeg`;
  const districtFilii = filii.filter((item) => item.isDistrict);
  const bankCards = useSelector((state) => state.common.bankCards);
  const userAuth = useSelector((state) => state.common.userAuth);

  const fopInitState = bankCards?.filter((item) => item.z === '2');
  const isInspector = ROLES[userAuth.role] <= ROLES.inspector;

  useEffect(() => {
    if (!bankCards) {
      request.post('/bankCards', {}, (res) => {
        dispatch(setBankCardsList(res.cardList));
      });
    }
  }, []);

  useEffect(() => {
    setCurrentManager(activeUsers.find((user) => user._id === currentUserId));
  }, [currentUserId]);
  const addFilToDB = (formdata) => {
    setLoading(true);
    request.post(
      '/filii',
      formdata,
      (res) => {
        addFilCallback(res);
        addFilImage(formdata.filPhoto, res.fil._id, res.fil, true);
      },
      error,
    );
  };

  const editFilToDB = (formdata) => {
    setLoading(true);
    request.post(
      '/filii/' + data._id,
      formdata,
      (res) => {
        editFilCallback(res);
        addFilImage(formdata.filPhoto, editItem._id, editItem);
      },
      error,
    );
  };

  const addFilCallback = (res) => {
    dispatch(addFilItem(res.fil));
    success('Успіх!', 'Філію успішно створено!');
    form.resetFields();
    setCurrentManager('');
    setLoading(false);
  };

  const editFilCallback = (res) => {
    dispatch(editFilItem(res.fil));
    success('Успіх!', 'Філію успішно редаговано!');
    form.resetFields();
    setIsEditForm(false);
    setOpenFilForm(false);
    setLoading(false);
  };

  const onFormSubmitHandle = (data) => {
    (isEditForm ? editFilToDB : addFilToDB)(data);
  };

  const addFilImage = (file, id, data, createNew) => {
    if (!file?.file?.type) return;
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('_id', id);
    if (data?.image) {
      formData.append('prevAvatarId', data.image);
      formData.append('extension', data.ext);
    }

    request.post(
      '/filiiAddPhoto/uploadImage',
      formData,
      (res) => {
        if (res.status) {
          success();
          setLoading(false);
          createNew ? setImageUrl('') : setImageUrl(process.env.REACT_APP_API + `/filiiImages/${res.image}.${res.ext}`);
          dispatch(editFilItem({ ...data, ext: res.ext, image: res.image }));
        }
      },
      () => {
        error('Сталася помилка');
        setLoading(false);
      },
    );
  };

  const onReset = () => {
    form.resetFields();
    setIsEditForm(false);
    setEditItem({});
  };

  const handleChangeCurrency = (value) => {
    setCurrency(value);
  };

  return (
    <Form className="fil-form" form={form} onFinish={onFormSubmitHandle} initialValues={data} layout="vertical">
      {/* add close from btn */}
      <div
        className="close-form"
        style={{ position: 'absolute', cursor: 'pointer', fontSize: 16, top: 96, right: 16 }}
        onClick={() => setOpenFilForm(false)}
      >
        <CloseOutlined />
      </div>
      <Row justify={'start'} align={'middle'} gutter={{ sm: 24 }}>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item label="Номер" name="i">
            <Input placeholder="#" disabled={!editableIndex} />
          </Form.Item>
        </Col>

        <Col sm={{ span: 18 }} xs={{ span: 24 }}>
          <Row justify={'start'} align={'middle'}>
            <Switch onChange={setEditableIndex} checked={editableIndex} style={{ marginRight: 24 }} />
            <Alert message="Зміна номеру може зламати логіку роботи з філіями" banner />
          </Row>
        </Col>
      </Row>
      <Row justify={'space-between'} gutter={{ sm: 24 }}>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item
            label="Назва: "
            name="name"
            rules={[
              { required: true, message: 'Назва обов`язкове поле' },
              { min: 3, message: 'Назва не корректна!' },
            ]}
          >
            <Input placeholder="Київ" />
          </Form.Item>
        </Col>
        <Col sm={{ span: 18 }} xs={{ span: 24 }}>
          <Form.Item label="Адреса: " name="address" rules={[{ required: true, message: 'Адреса обов`язкове поле' }]}>
            <Input placeholder="м. Київ,  вул. Володимирська 51/53, офіс 19" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Повна назва: " name="full_name">
        <Input placeholder="Повна назва філії" />
      </Form.Item>
      <Form.Item label="Зона впливу: " name="zone" rules={[{ required: true, message: 'Зона впливу обов`язкове поле' }]}>
        <Input placeholder="Правий берег м. Київа і Київської обл., (крім м. Біла Церква)" />
      </Form.Item>
      <Row justify={'space-between'} gutter={{ sm: 24 }}>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item label="Google map посилання: " name="map">
            <Input placeholder="https://goo.gl/maps/RjiNNABByLhgeix4A" />
          </Form.Item>
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item label="YouTube відео посилання: " name="video">
            <Input placeholder="https://www.youtube.com/embed/NnVhFOZlBsk" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Посилання на відгук у Google: " name="feedback">
        <Input placeholder="https://www.google.com/search?q=%D0%B0%D0%BE..." />
      </Form.Item>
      <Form.Item label="Опис адреси: " name="address_description">
        <Input.TextArea placeholder="м. Київ,  вул. Володимирська 51/53, офіс 19" />
      </Form.Item>
      {isInspector && (
        <Form.Item label="Нотатка: " name="note">
          <Input placeholder="Ваше повідомлення" />
        </Form.Item>
      )}
      <Form.List label="Номери гарячої лінії:" name="hotlinePhones">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className="phone-item-wrapper">
                <Form.Item {...restField} name={[name]} rules={[{ required: true, message: 'Заповніть, будь ласка, це поле' }]}>
                  <Input placeholder="0501234567" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </div>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {/* <Form.Item label="Мобільний номер: " name="mob_phone">
        <Input placeholder="0501234567" />
      </Form.Item> */}
      <h3>Фото</h3>
      <div className="fil-form-images">
        <div>
          Фото керівника філії:
          <div className="manager-image-block">
            {currentManager?.upa ? (
              <img className="manager-photo" src={currentManagerAvatar} alt="" />
            ) : (
              <div style={{ marginTop: 10, textAlign: 'center', padding: 5 }}>Керівник філії повинен самостійно додати фото через свою сторінку</div>
            )}
          </div>
        </div>
        <Form.Item label="Фото філії:" name="filPhoto">
          <FilPhotoAdd imageUrl={imageUrl} setImageUrl={setImageUrl} />
        </Form.Item>
      </div>
      <Form.Item label="Керівник філії:" name="managerId">
        <SearchSelect
          onChange={setCurrentUserId}
          list={activeUsers}
          customLabel={(i) => FILII[i.fil] + ' (' + i.p + ') ' + i.name}
          valueName={'_id'}
        />
      </Form.Item>
      <Form.Item label="Окружна філія:" name="isDistrict" valuePropName="checked">
        <Checkbox>
          <span>Відмітити якщо філія є окружною</span>
        </Checkbox>
      </Form.Item>
      <Form.Item label="Філія ГО:" name="isMainOfficeFilial" valuePropName="checked">
        <Checkbox>
          <span>Відмітити якщо філія є філією ГО</span>
        </Checkbox>
      </Form.Item>

      <Form.Item label="Валюта" name="currency">
        <SearchSelectCurrencies />
      </Form.Item>

      <Form.Item label="Області:" name="states">
        <SearchSelect list={UKRAINE_STATES} mode="multiple" value={[]} />
      </Form.Item>
      <Form.Item label="Округ" name="district">
        <SearchSelect list={districtFilii} valueName={'i'} labelName={'name'} />
      </Form.Item>
      {isEditForm ? (
        <Form.Item label="ФОП:" name="fopId">
          <SearchSelect value={data.fopId} list={fopInitState ?? {}} customLabel={(i) => i.n} valueName={'_id'} />
        </Form.Item>
      ) : null}
      <Form.Item label="Баланс на 01.03.2024 готівка" name="cashBalance">
        <InputNumber placeholder="грн" />
      </Form.Item>
      <Form.Item label="Баланс на 01.03.2024 карта" name="cardBalance">
        <InputNumber placeholder="грн" />
      </Form.Item>
      <Form.Item label="Баланс на 01.03.2024 ФОП" name="fopBalance">
        <InputNumber placeholder="грн" />
      </Form.Item>
      <Form.Item label="Середня зарплата" name="salary">
        <InputNumber placeholder="грн" />
      </Form.Item>
      <Form.Item label="Не доступно для нових записів" name="isDisabled" valuePropName="checked">
        <Checkbox>
          <CloseSquareOutlined style={{ color: 'red' }} /> &nbsp;
          <span>Відмітити якщо філія не доступна для нових записів</span>
        </Checkbox>
      </Form.Item>
      <Button loading={loading} type="primary" htmlType="submit" style={{ marginRight: '15px' }}>
        {isEditForm ? 'Редагувати' : 'Створити'}
      </Button>
      <Button htmlType="button" onClick={onReset}>
        Очистити
      </Button>
    </Form>
  );
};

export default FilForm;
