import { request } from '../../../tools';

const getLawyers = async (handleSetLawyersList, handleSetMainLoader) => {
  return new Promise((resolve, reject) => {
    request.post(
      '/lawyer/get',
      {},
      (res) => {
        handleSetLawyersList(res.lawyers);
        resolve();
      },
      (error) => {
        handleSetMainLoader(false);
        reject(error);
      },
    );
  });
};

export default getLawyers;
