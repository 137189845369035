import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Pagination, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTweetItem, setModal, setUserFavoriteTweets, setUserTweets, toggleFavoriteTweets } from '../../../store/commonReducer';
import { ADD_TWEET } from 'common_constants/modals';
import { error, request, success } from '../../../tools';
import ProfileTwitterItem from './ProfileTwitterItem';
import Title from 'antd/es/typography/Title';
import UserAvatar from '../../../components/UserAvatar';
import FavoriteItem from './FavoriteItem';

const DEFAULT_PAGE_SIZE = 10;

const ProfileTwitter = ({ requestType, activeMainTab }) => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.common.users);
  const tweets = useSelector((state) => state.common.userTweets || []);
  const favorites = useSelector((state) => state.common.userFavoriteTweets || []);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [activeTab, setActiveTab] = useState('myTweets');

  const { Text } = Typography;

  const getUserTweets = (limits) => {
    setLoading(true);

    const params = {
      ...limits,
      message: searchValue,
    };

    const endpoint = requestType === 'userTweets' ? '/twitter/getUserTweets' : '/twitter/getAllTweets';

    request.post(
      endpoint,
      params,
      ({ tweets, total }) => {
        dispatch(setUserTweets(tweets));
        setPageSize(pageSize);
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    if (activeTab === 'myTweets') {
      const limits = {
        page: currentPage,
        pageSize,
      };
      getUserTweets(limits);
    }
  }, [currentPage, pageSize, searchValue, activeMainTab]);

  const onChangeSearchInput = (e) => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteTweet = (id) => {
    const onSuccess = () => {
      dispatch(deleteTweetItem(id));
      success('Успіх!', 'Ваш твіт видалений успішно!');
    };
    const onError = () => error('Помилка!', 'Схоже, не вдалося видалити Ваш твіт!');

    request.delete('/twitter/', id, onSuccess, onError);
  };

  const editTweet = (id) => {
    const tweetToEdit = tweets.find((item) => item._id === id);
    dispatch(setModal({ name: ADD_TWEET, editItem: tweetToEdit }));
  };

  const addToFavorites = (id) => {
    const onSuccess = ({ isFavorite }) => {
      const updatedTweets = tweets.map((tweet) => {
        if (tweet._id === id) {
          return { ...tweet, isFavorite };
        }
        return tweet;
      });
      dispatch(toggleFavoriteTweets({ tweet: updatedTweets.find((tweet) => tweet._id === id), isFavorite }));
    };

    const onError = () => error('Помилка!', 'Не вдалося оновити улюблені твіти!');

    request.post(`/twitter/addToFavorites/${id}`, {}, onSuccess, onError);
  };

  const fetchFavoriteTweets = (limits) => {
    setLoading(true);

    request.post(
      '/twitter/getFavoriteTweets',
      limits,
      ({ favorites, total }) => {
        dispatch(setUserFavoriteTweets(favorites));
        setPageSize(pageSize);
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    if (activeTab === 'myFavorites') {
      const limits = {
        page: currentPage,
        pageSize,
      };
      fetchFavoriteTweets(limits);
    }
  }, [currentPage, pageSize, searchValue, activeTab]);

  useEffect(() => {
    if (activeMainTab === '1') setActiveTab('myTweets');
  }, [activeMainTab]);

  if (!users) return null;

  return (
    <div>
      <Row style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_TWEET }))}>
          <PlusCircleOutlined /> Створити твіт
        </Button>
      </Row>
      <br />

      <Row justify="center" align="middle">
        <Col span={24} style={{ textAlign: 'center', marginBottom: '16px' }}>
          <UserAvatar size={120} style={{ width: 140, height: 140 }} user={users?.[tweets[0]?.author]} />
        </Col>
        <Col span={24}>
          <Title level={3} style={{ fontSize: '32px', textAlign: 'center' }}>
            {users[tweets[0]?.author]?.p}
          </Title>
        </Col>
      </Row>
      <br />

      <div style={{ textAlign: 'center' }}>
        <Button type="link" onClick={() => setActiveTab('myTweets')}>
          Мої записи
        </Button>
        <Button type="link" onClick={() => setActiveTab('myFavorites')}>
          Збережені
        </Button>
      </div>

      <br />

      <Spin spinning={loading} tip="Зачекайте" size="large">
        {activeTab === 'myTweets' && (
          <>
            <Col>
              {tweets?.length ? (
                tweets.map((item) => (
                  <Col key={item._id}>
                    <ProfileTwitterItem
                      item={item}
                      deleteTweet={() => deleteTweet(item._id)}
                      editTweet={() => editTweet(item._id)}
                      addToFavorites={() => addToFavorites(item._id)}
                    />
                  </Col>
                ))
              ) : (
                <p className="filii_page__filtered-filii-not-found">
                  <Empty description={<Text>У вас ще немає публікацій</Text>} />
                </p>
              )}
            </Col>
            <br />
            {total > 0 && (
              <Pagination style={{ textAlign: 'center' }} current={currentPage} pageSize={pageSize} total={total} onChange={handlePageChange} />
            )}
          </>
        )}

        {activeTab === 'myFavorites' && (
          <>
            <Col>
              {favorites?.length ? (
                favorites.map((item) => (
                  <Col key={item._id}>
                    <FavoriteItem item={item} addToFavorites={() => addToFavorites(item._id)} />
                  </Col>
                ))
              ) : (
                <p className="filii_page__filtered-filii-not-found">
                  <Empty description={<Text>У вас ще немає збережених публікацій</Text>} />
                </p>
              )}
            </Col>
            <br />
            {total > 0 && (
              <Pagination style={{ textAlign: 'center' }} current={currentPage} pageSize={pageSize} total={total} onChange={handlePageChange} />
            )}
          </>
        )}
      </Spin>
    </div>
  );
};

export default ProfileTwitter;
