import { ASSIGNMENT_SERVICE } from 'common_constants/business';
import { request } from '../../../tools';

const getAssignments = async (
  handleSetAssignmentsList,
  handleSetMainLoader,
  setTotal,
  currentPage,
  dateRange,
  onlyArchived,
  serviceTypeFilter,
  filFilter,
  blockFilter,
  contractNumberFilter,
  courtFilter,
  tab,
) => {
  return new Promise((resolve, reject) => {
    const onSuccess = (res) => {
      handleSetMainLoader(false);

      if (res.assignments) {
        handleSetAssignmentsList(res.assignments);
      }
      setTotal(res.total);
      resolve();
    };

    const onError = (err) => {
      handleSetMainLoader(false);
      reject(err);
    };

    const body = { page: currentPage, dateRange, onlyArchived, serviceTypeFilter, filFilter, blockFilter, contractNumberFilter, [tab]: true };

    if (serviceTypeFilter === ASSIGNMENT_SERVICE.GOING_TO_COURT) {
      body.courtFilter = courtFilter;
    }

    handleSetMainLoader(true);
    request.post('/assignment/get', body, onSuccess, onError);
  });
};

export default getAssignments;
