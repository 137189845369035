import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Card, Typography, Spin, Space, Divider, Button, List, Popover, Popconfirm, Modal } from 'antd';
import { DownOutlined, DownloadOutlined, EditOutlined, DeleteOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { orange } from '@ant-design/colors';

import {
  setModal,
  deleteCorrespondencePageForItem,
  deleteCorrespondenceContractModalForItem,
  setCurrentCorrespondence,
} from '../../store/commonReducer';

import { Box, Row as MyRow } from 'common_components';
import { TYPES_OF_MAIL, ROLES } from 'common_constants/business';
import { requestFile, request, error, success, warn, info } from '../../tools';
import HoverableUser from '../../components/HoverableUser/HoverableUser';
import { ROUTES } from 'common_constants/routes';
import PreviewImage from './Core/PreviewImage';
import { CORRESPONDENCE_ADD_DOCUMENT } from 'common_constants/modals';

import LibraryAvatarFile from '../../components/LibraryItem/Core/LibraryAvatarFile';
import './styles.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const CorrespondenceCardItem = (data) => {
  const [dispatch, { pathname }] = [useDispatch(), useLocation()],
    [{ Text, Title, Link }, { Item }] = [Typography, List];

  const item = data.data,
    formatDate = (val) => dayjs(item.t)?.format(val);

  const [FILII, users, userAuth] = [
      useSelector((state) => state.common.FILII),
      useSelector((state) => state.common.users),
      useSelector((state) => state.common.userAuth),
    ],
    author = (users && users[item.a]?.p) ?? '';

  const [inspector, deputyManager, rabfil] = [
    ROLES[userAuth.role] <= ROLES.inspector,
    ROLES[userAuth.role] <= ROLES.deputyManager,
    ROLES[userAuth.role] <= ROLES.rabfil,
  ];

  const coreMegaState = {
      loading: false, //* Завантаження
      loadingText: 'Завантаження', //* Текст спіна в залежності від дії
      openModal: false, //* модалка інфо
    },
    [megaState, setMegaState] = useState(coreMegaState);

  const stylesText = { whiteSpace: 'pre-wrap', height: 'auto' };

  const item_left = {
      flex: 'start',
      componentDate: (
        <Button type="link" onClick={() => setMegaState((prev) => ({ ...prev, openModal: true }))}>
          <Text style={stylesText} className="author">
            {formatDate('DD.MM.YYYY HH:mm')} - {author}
            <Box mt={5}>
              <Link style={stylesText}>
                <DownOutlined />
                &nbsp;Вихідна: {item.h ?? 'Аноніма'}
              </Link>
            </Box>
          </Text>
        </Button>
      ),
    },
    item_right = {
      flex: 'end',
      componentDate: (
        <Button type="link" onClick={() => setMegaState((prev) => ({ ...prev, openModal: true }))}>
          <Text style={stylesText} className="author">
            {author} - {formatDate('HH:mm DD.MM.YYYY')}
            <Box mt={5}>
              <Link style={stylesText}>
                Вхідна: {item.h ?? 'Аноніма'}&nbsp;
                <DownOutlined />
              </Link>
            </Box>
          </Text>
        </Button>
      ),
    },
    dynamicSCSS =
      {
        1: item_left,
        2: item_right,
      }[item?.o] || item_left;

  const findLabelByValue = (value) => {
    const type = TYPES_OF_MAIL.find((item) => item.value === Number(value));

    return type ? type.label : TYPES_OF_MAIL[TYPES_OF_MAIL.length - 1].label;
  };

  const download_file_by_fetch_toArr = (arrDocs) => {
    if (!arrDocs?.length) return;

    for (let i = 0; i < arrDocs?.length; i++) {
      download_file_by_fetch(arrDocs[i], false);
    }
  };

  const download_file_by_fetch = async (itemDocs, thisOnly = true) => {
    const dynamicItem = itemDocs ?? item;

    const validation = (_L, __id, _n, _f) => {
      if (!_L) {
        warn('', 'Для завантаження документу, потрібно вказати дійсний файл.', { L: !_L });
        return;
      }

      if (!__id) {
        warn('', 'Для завантаження документу, потрібно вказати дійсний документ.', { _id: !__id });
        return;
      }

      if (!_n) {
        warn('', 'Для завантаження документу, потрібно вказати назву файлу.', { n: !_n });
        return;
      }

      if (!_f) {
        warn('', 'Для завантаження документу, потрібно вказати формат файлу.', { n: !_f });
        return;
      }

      return true;
    };

    const [valid_item_Only, valid_item_Arr] = [
      [dynamicItem?.ch?.rc?.L, item._id, dynamicItem?.ch?.rc?.nd, dynamicItem?.ch?.rc?.fd],
      [dynamicItem.L, item._id, dynamicItem.nd, dynamicItem.fd],
    ];

    if (!validation(...(thisOnly ? valid_item_Only : valid_item_Arr))) return;

    setMegaState({ ...megaState, loading: true, loadingText: 'Зачекайте, йде скачування файлів' });

    const data_transfer = thisOnly
      ? {
          fileId: dynamicItem?.ch?.rc?.L,
          fileName: dynamicItem?.ch?.rc?.nd + '.' + dynamicItem?.ch?.rc?.fd, //* для обробки даних в requestFile
        }
      : {
          fileId: dynamicItem.L,
          fileName: dynamicItem.nd + '.' + dynamicItem.fd, //* для обробки даних в requestFile
        };

    requestFile(
      '/correspondence/getCorrespondenceDriveFile',
      data_transfer,
      () => {
        success('', 'Документ успішно завантажено');
        setMegaState({ ...megaState, loading: coreMegaState.loading, loadingText: coreMegaState.loadingText });
      },
      (err) => {
        error('', 'Помилка при відправці файлу', err);
        setMegaState({ ...megaState, loading: coreMegaState.loading, loadingText: coreMegaState.loadingText });
      },
    );
  };
  const cancel = () => info('', 'Ви не згодні зі своїм рішенням.');

  const delete_file_by_fetch = async () => {
    const validation = (__id) => {
      if (!__id) {
        warn('', 'Для завантаження документу, потрібно вказати дійсний документ.', { _id: !!__id });
        return;
      }
      return true;
    };

    if (!validation(item._id)) return;

    setMegaState((prev) => ({
      ...prev,
      loading: true,
      loadingText: 'Зачекайте, йде видалення файлів',
    }));

    await request.delete(
      '/correspondence/',
      item._id,
      () => {
        const dynamic_deleted_redux = () =>
          ({
            [ROUTES.CONTRACTS]: dispatch(deleteCorrespondenceContractModalForItem(item._id)),
            [ROUTES.CORRESPONDENCE_PAGE]: dispatch(deleteCorrespondencePageForItem(item._id)),
          }[pathname] || warn());

        dynamic_deleted_redux();

        setMegaState((prev) => ({
          ...prev,
          openModal: coreMegaState.openModal,
        }));

        success('', 'Документ видалено успішно!');
      },
      error,
    );

    setMegaState((prev) => ({
      ...prev,
      loading: coreMegaState.loading,
      loadingText: coreMegaState.loadingText,
    }));
  };

  const onEdit = () => {
    dispatch(setCurrentCorrespondence(item));
    dispatch(setModal({ name: CORRESPONDENCE_ADD_DOCUMENT }));
  };

  const contentBtn = (
    <>
      {rabfil && (
        <Box className="group-button">
          <Button
            disabled={!item.j || !item?.ch?.rc?.L}
            icon={<DownloadOutlined />}
            className="group-button__btn"
            onClick={() => download_file_by_fetch()}
            type="primary"
          >
            Скачати чек
          </Button>
          <Button
            disabled={!item.df?.length}
            icon={<DownloadOutlined />}
            className="group-button__btn"
            onClick={() => download_file_by_fetch_toArr(item.df)}
            type="primary"
          >
            Скачати файли
          </Button>
        </Box>
      )}

      {deputyManager && (
        <Box className="group-button">
          <Button style={{ backgroundColor: orange.primary }} icon={<EditOutlined />} className="group-button__btn" type="primary" onClick={onEdit}>
            Редагувати
          </Button>

          {inspector && (
            <Popconfirm
              title="Підтвердження"
              description="Ви впевнені, що хочете видалити цю пошту?"
              onConfirm={() => delete_file_by_fetch()}
              onCancel={cancel}
              okText="Гаразд"
              cancelText="Скасувати"
            >
              <Button icon={<DeleteOutlined />} className="group-button__btn" type="primary" danger>
                Видалити
              </Button>
            </Popconfirm>
          )}
        </Box>
      )}
    </>
  );

  const contentBody = (
    <Spin tip={megaState.loadingText} spinning={megaState.loading} size="large">
      <Divider style={{ borderBottom: '1px solid #000', margin: '8px 0' }} orientation="center">
        Введені користувачем
      </Divider>
      <Box>
        <Space size={[0, 8]} wrap>
          <Text className="lib-item__text" strong>
            Напрямок пошти:&ensp;
          </Text>
          {{
            1: 'Вихідна',
            2: 'Вхідна',
          }[item.o] || '-'}
        </Space>
      </Box>
      <Box>
        <Space size={[0, 8]} wrap>
          <Text className="lib-item__text" strong>
            Вид пошти:&ensp;
          </Text>
          {findLabelByValue(item.p)}
        </Space>
      </Box>
      {(item.ik >= 0 || item.contract?.[0]?.i) && (
        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item__text" strong>
              Номер договору:&ensp;
            </Text>
            {item.ik >= 0 ? item.ik : item.contract?.[0]?.i ?? '-'}
          </Space>
        </Box>
      )}
      {(item.fk >= 0 || item.contract?.[0]?.f) && (
        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item__text" strong>
              Філія договору:&ensp;
            </Text>
            {FILII[item.fk >= 0 ? item.fk : item.contract?.[0]?.f] ?? '-'}
          </Space>
        </Box>
      )}
      {item.h && (
        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item__text" strong>
              Адресат:&ensp;
            </Text>
            {item.h ?? '-'}
          </Space>
        </Box>
      )}
      {ROUTES.CORRESPONDENCE_PAGE === pathname && item.contract?.[0]?.client?.[0]?.n && (
        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item__text" strong>
              Ім'я клієнта:&ensp;
            </Text>
            {item.contract?.[0]?.client?.[0]?.n ?? '-'}
          </Space>
        </Box>
      )}
      {item.j && (
        <Box>
          <Card
            title={
              <MyRow style={{ justifyContent: 'center' }}>
                <Title level={5} style={{ margin: 0 }}>
                  Чек
                </Title>
              </MyRow>
            }
            size="small"
          >
            {item?.ch?.rc?.L && (
              <Box mt={0}>
                <Space size={[0, 8]} wrap>
                  <Text className="lib-item__text" strong>
                    Фото чеку:&ensp;
                  </Text>
                  {{
                    pdf: <LibraryAvatarFile item={item?.ch?.rc?.fd} />,
                    png: <PreviewImage item={item?.ch?.rc?.L} />,
                    jpg: <PreviewImage item={item?.ch?.rc?.L} />,
                    jpeg: <PreviewImage item={item?.ch?.rc?.L} />,
                    img: <PreviewImage item={item?.ch?.rc?.L} />,
                  }[item?.ch?.rc?.fd] || 'Є'}
                </Space>
              </Box>
            )}

            {item.ch?.tn && (
              <Box>
                <Space size={[0, 8]} wrap>
                  <Text className="lib-item__text" strong>
                    Трек-номер:&ensp;
                  </Text>
                  {item.ch?.tn ?? '-'}
                </Space>
              </Box>
            )}

            {item.ch?.b >= 0 && (
              <Box>
                <Space size={[0, 8]} wrap>
                  <Text className="lib-item__text" strong>
                    Сума:&ensp;
                  </Text>
                  {item.ch?.b ?? '-'}
                </Space>
              </Box>
            )}
          </Card>
        </Box>
      )}
      <Box>
        <Space size={[0, 8]} wrap>
          <Text className="lib-item__text" strong>
            Передані документи:&ensp;
          </Text>
          {item.df?.length || 'Немає'}
        </Space>
      </Box>
      <Box>
        <Space size={[0, 8]} wrap>
          <Text className="lib-item__text" strong>
            Направлення в особистий кабінет клієнт:&ensp;
          </Text>
          {item.x ? 'Є' : 'Немає'}
        </Space>
      </Box>
      {item.m && (
        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item__text" strong>
              Коментар:&ensp;
            </Text>
            {item.m ?? '-'}
          </Space>
        </Box>
      )}
      <Divider style={{ borderBottom: '1px solid #000', margin: '8px 0' }} orientation="center">
        Службові дані
      </Divider>
      <Box>
        <Space size={[0, 8]} wrap>
          <Text className="lib-item__text" strong>
            Номер пошти:&ensp;
          </Text>
          {item.i ?? '-'}
        </Space>
      </Box>
      <Box>
        <Space size={[0, 8]} wrap>
          <Text className="lib-item__text" strong>
            Автор:&ensp;
          </Text>
          <Popover content={<HoverableUser id={item.a} />} arrow placement="topLeft" trigger="hover">
            <Link>{(users && users[item.a]?.name) ?? '-'}</Link>
          </Popover>
        </Space>
      </Box>
      <Box>
        <Space>
          <Text className="lib-item__text" strong>
            Дата створення:
          </Text>
          <Text>{dayjs(item.t)?.format('DD MMMM YYYY року') ?? '-'}</Text>
        </Space>
      </Box>
      {item.ac && (
        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item__text" strong>
              Автор редагування:&ensp;
            </Text>
            <Popover content={<HoverableUser id={item.ac} />} arrow placement="topLeft" trigger="hover">
              <Link>{(users && users[item.ac]?.name) ?? '-'}</Link>
            </Popover>
          </Space>
        </Box>
      )}
      {item.tc && (
        <Box>
          <Space>
            <Text className="lib-item__text" strong>
              Дата редагування:
            </Text>
            <Text>{dayjs(item.tc)?.format('DD MMMM YYYY року') ?? '-'}</Text>
          </Space>
        </Box>
      )}
      {contentBtn}
    </Spin>
  );

  const modalInfo = (
    <Modal
      open={megaState.openModal}
      className="correspondence-add-document"
      title={
        <Title style={{ margin: 0 }} level={4}>
          <InfoCircleTwoTone style={{ fontSize: '22px' }} />
          &nbsp;&nbsp;Інфо
        </Title>
      }
      onCancel={() => setMegaState((prev) => ({ ...prev, openModal: false }))}
      footer={null}
    >
      {contentBody}
    </Modal>
  );

  return (
    <Item
      size="small"
      style={{ justifyContent: `flex-${dynamicSCSS.flex}`, paddingLeft: '0px', paddingRight: '0px' }}
      className="correspondence-item"
      key={item._id}
    >
      <Spin tip="Завантаження" spinning={megaState.loading} size="large">
        {dynamicSCSS.componentDate}
        {modalInfo}
      </Spin>
    </Item>
  );
};

export default CorrespondenceCardItem;
