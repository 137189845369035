import React, { useState } from 'react';
import { Button, Card, Modal } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { CLIENT_INFO } from 'common_constants/modals';
import Link from 'antd/es/typography/Link';
import { setModal } from '../../store/commonReducer';
import './style.scss';

const ClientsDozhimsAIChatsItem = ({ data }) => {
  const dispatch = useDispatch();
  const FILII = useSelector((state) => state.common.FILII);

  const [visible, setVisible] = useState(false);

  const openClient = () => {
    dispatch(setModal({ name: CLIENT_INFO, data: data.client, fromContracts: true, interactionsBlocked: true }));
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  if (!data) {
    return 'Відсутня інформація';
  }

  return (
    <div className="item">
      <Card className="harvestInfo">
        <span>
          <b>ПІБ: </b>
          <>
            {data.client.n} &nbsp;
            {data.client && <Link onClick={openClient}>подробиці</Link>}
          </>
        </span>
        <span>
          <b>Філія:</b> {FILII[data.client.f]}
        </span>
        <Button type="primary" style={{ marginTop: '10px', width: '200px' }} onClick={showModal}>
          Переглянути чат клієнта
        </Button>
        <br />
        <span>
          <b>Дата створення чату:</b> {dayjs(data.createdAt).format('DD.MM.YYYY HH:mm:ss')}
        </span>
        <span>
          <b>Дата останнього повідомлення :</b> {dayjs(data.updatedAt).format('DD.MM.YYYY HH:mm:ss')}
        </span>
      </Card>

      <Modal title="AI дожими клієнт чат клієнта" open={visible} onCancel={handleCancel} footer={null}>
        <div>
          {data.chat.map((message, index) => (
            <div key={index} style={{ textAlign: message.sender === 'user' ? 'right' : 'left' }}>
              <div className={message.sender === 'user' ? 'user_message' : 'assistant_message'}>
                <span className="message-details">{message.sender === 'user' ? data.client.n : message.sender}</span>
                <p style={{ textAlign: message.sender === 'user' ? 'left' : 'left' }}>{message.message}</p>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default ClientsDozhimsAIChatsItem;
