import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';

import { request } from '../../tools';

const typeLabels = {
  12: 'Оплата за страховку',
  7: 'Оплата за запитання',
  11: 'Оплата за тривожну кнопку',
  15: 'Покупка клубної карти',
  16: 'Чайові',
  8: 'Автоматична оплата послуг',
};

const ITServices = () => {
  const [paymentData, setPaymentData] = useState({ dates: [], amounts: [] });
  const [paymentDataByType, setPaymentDataByType] = useState([]);

  const fetchData = () => {
    request.post('/statistic/itServices', null, ({ data }) => {
      const formattedData = formatData(data);
      setPaymentData({ weeks: formattedData.weeks, amounts: formattedData.amounts, groupByMonth: formattedData.groupByMonth });
    });
  };

  const fetchDataByType = () => {
    request.post('/statistic/itServicesByType', null, ({ data }) => {
      const formattedDataByType = formatDataByType(data);
      setPaymentDataByType(formattedDataByType);
    });
  };

  useEffect(() => {
    fetchData();
    fetchDataByType();
  }, []);

  const formatData = (data) => {
    const dateAmountMap = {};

    // Cycle for every week in the year
    for (let i = 0; i < 52; i++) {
      const startOfWeek = dayjs().startOf('week').subtract(i, 'week');
      const endOfWeek = startOfWeek.endOf('week');

      const weekKey = `${startOfWeek.format('DD.MM.YYYY')} - ${endOfWeek.format('DD.MM.YYYY')}`;
      dateAmountMap[weekKey] = 0;
    }

    data.forEach((payment) => {
      const paymentDate = dayjs(payment.date);
      const startOfWeek = paymentDate.startOf('week');
      const endOfWeek = paymentDate.endOf('week');

      const weekKey = `${startOfWeek.format('DD.MM.YYYY')} - ${endOfWeek.format('DD.MM.YYYY')}`;
      if (dateAmountMap[weekKey] !== undefined) {
        dateAmountMap[weekKey] += payment.amount;
      } else {
        dateAmountMap[weekKey] = payment.amount;
      }
    });

    const weeks = Object.keys(dateAmountMap).sort(
      (a, b) => dayjs(a.split(' - ')[0], 'DD.MM.YYYY').unix() - dayjs(b.split(' - ')[0], 'DD.MM.YYYY').unix(),
    );
    const amounts = weeks.map((week) => dateAmountMap[week]);

    const groupByMonth = [...data]
      .sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix())
      .reduce((acc, payment) => {
        const paymentDate = dayjs(payment.date);
        const month = paymentDate.format('MMMM');
        acc[month] = acc[month] ? acc[month] + payment.amount : payment.amount;
        return acc;
      }, {});

    return { weeks, amounts, groupByMonth };
  };

  const formatDataByType = (data) => {
    return data.map((typeData) => ({
      type: typeData.type,
      transactions: typeData.transactions.map((transaction) => ({
        ...transaction,
        date: new Date(transaction.date).toISOString().split('T')[0],
      })),
    }));
  };

  const weeklyOptions = {
    title: {
      text: 'По тижнях',
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: paymentData.weeks,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Сума:',
        type: 'line',
        areaStyle: {},
        data: paymentData.amounts,
      },
    ],
  };

  const monthlyOptions = {
    title: {
      text: 'По місяцях',
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: Object.keys(paymentData.groupByMonth || {}),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Сума:',
        type: 'line',
        areaStyle: {},
        data: Object.values(paymentData.groupByMonth || {}),
      },
    ],
  };

  const getOptionByType = (typeData) => {
    const { type, transactions } = typeData;
    const dates = transactions.map((entry) => entry.date);
    const amounts = transactions.map((entry) => entry.amount);

    return {
      title: {
        text: `${typeLabels[type]}`,
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: dates,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'Сума:',
          type: 'line',
          data: amounts,
        },
      ],
    };
  };

  const wholeAmount = Object.values(paymentData.groupByMonth || {}).reduce((acc, curr) => acc + curr, 0);

  return (
    <div>
      <h1>ІТ послуги</h1>
      <h2>Загальна сума: {wholeAmount?.toLocaleString('uk-UA') || 0} грн</h2>
      <ReactECharts option={weeklyOptions} style={{ height: '400px', width: '100%' }} />
      {paymentData.groupByMonth && <ReactECharts option={monthlyOptions} style={{ height: '400px', width: '100%' }} />}
      {paymentDataByType.map((typeData) => (
        <ReactECharts key={typeData.type} option={getOptionByType(typeData)} style={{ height: '400px', width: '100%' }} />
      ))}
    </div>
  );
};

export default ITServices;
