import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Row, Col, Switch, Popover } from 'antd';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';

import { request, success, warn } from '../../tools';
import { setMainLoader } from '../../store/uiReducer';
import HoverableUser from '../../components/HoverableUser/HoverableUser';

const AccessItem = ({ item, refreshCallback }) => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.common.users);

  const handleStatusToggle = async (checked) => {
    dispatch(setMainLoader(true));

    try {
      await request.post('/fingerprint/allowAccess', { id: item.accessId, status: checked });
      success('Доступ змінено успішно.');
      refreshCallback();
    } catch (error) {
      warn('Повторіть дію пізніше.');
    }

    dispatch(setMainLoader(false));
  };

  return (
    <Card>
      <Row>
        <Col><b>Дата запиту: </b>{dayjs(item.date).format('DD.MM.YYYY HH:mm:ss')}</Col>
      </Row>
      <Row>
        <Col>
          <b>Користувач: </b> 
          <Popover content={<HoverableUser id={(users[item._id]?._id) ?? 'Невідомо'} />} arrow trigger="hover">
            <Link>{(users[item._id]?.p) ?? 'Невідомо'}</Link>
          </Popover>
        </Col>
      </Row>
      
      <br />
      <Row>
        <Col><b>ОС: </b>{item.platform}</Col>
      </Row>
      <Row>
        <Col><b>Часовий пояс: </b>{item.timezone}</Col>
      </Row>
      <br />
      <Row>
        <Col>
          <b>Дозволити доступ: </b> 
          <Switch checked={item.status} onChange={(checked) => handleStatusToggle(checked)} />
        </Col>
      </Row>
    </Card>
  );
};

export default AccessItem;
