import { Modal, Input, Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModal, setClientsList } from '../../store/commonReducer';
import { useState } from 'react';
import { request, success, error } from '../../tools';

const AddMoneyToClientBalance = () => {
  const dispatch = useDispatch();
  const { data, prev } = useSelector((state) => state.common.modal);
  const clients = useSelector((state) => state.common.clients) || [];
  const [topUpSum, setTopUpSUm] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleTopUpBalance = () => {
    setLoading(true);

    const onSuccess = (res) => {
      const updatedClients = clients.map((i) =>
        i._id === data.C
          ? {
              ...i,
              clientBalance: res.clientBalance,
              transactions: [...i.transactions, res.transaction],
            }
          : i,
      );

      dispatch(setClientsList(updatedClients));
      dispatch(setModal());
      success('', 'Виконано успішно!');
      setLoading(false);
    };

    const onError = () => {
      setLoading(false);
      error('', 'Щось пішло не так !');
    };

    const body = {
      clientId: data.C,
      topUpSum: parseInt(topUpSum),
      transactionType: 22,
    };

    request.post('/clients/topUpClientBalance', body, onSuccess, onError);
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal open className="client-balance-transaction" title="Додати гроші на баланс" onCancel={() => dispatch(setModal(prev))} footer={null}>
      <Spin spinning={loading}>
        <div>
          <b>Баланс клієнта: </b>
          {data?.clientBalance?.toFixed(2) || 0}
        </div>
        <br />
        <div>
          <b>Сума поповнення: </b>
          <Input
            onKeyDown={handleKeyPress}
            onChange={(e) => setTopUpSUm(Math.abs(e.target.value).toFixed(0))}
            type="number"
            min={1}
            value={topUpSum}
          />
        </div>
        <br />

        <Button onClick={handleTopUpBalance} type="primary" className="submit">
          Додати гроші на баланс
        </Button>
      </Spin>
    </Modal>
  );
};

export default AddMoneyToClientBalance;
