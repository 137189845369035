import { Button, Card, Input, Popconfirm, Radio, Table } from 'antd';
import { CURRENCIES, ROLES, CLIENT_CATEGORIES } from 'common_constants/business/index';
import { ARCHIVE_CALENDAR } from 'common_constants/modals';
import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DownOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Box } from 'common_components';
import ClientInfo from '../../Modals/ClientInfo';

import SearchSelect from '../../components/SearchSelect';
import { setClientsList, setModal } from '../../store/commonReducer';
import { request } from '../../tools';

import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

import './Clients.scss';

const optionsSelfRegistered = [
  { label: 'Всі', value: undefined },
  { label: 'Самостійна', value: true },
  { label: 'Через юриста', value: false },
];

const Clients = (props) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.common.clients);
  const FILII = useSelector((state) => state.common.FILII);
  const mob = useSelector((state) => state.ui.mob);

  const columns = mob
    ? [
        Table.EXPAND_COLUMN,
        {
          title: '№',
          dataIndex: 'i',
          key: 'i',
          defaultSortOrder: 'descend',
          sorter: {
            compare: (a, b) => a.i - b.i,
          },
        },
        {
          title: 'Інфо',
          key: 'customColumn',
          render: (record) => {
            return (
              <div>
                <div>{record.n}</div>
                <div>{FILII[record.f]}</div>
                <div>{record.ph}</div>
                <div>
                  {' '}
                  {record.B ?? 0} Б <b>|</b> {record.clientBalance ?? 0} {CURRENCIES[record.currency]?.label ?? 'грн'}{' '}
                </div>
              </div>
            );
          },
        },
      ]
    : [
        Table.EXPAND_COLUMN,
        {
          title: '№',
          dataIndex: 'i',
          key: 'i',
          defaultSortOrder: 'descend',
          sorter: {
            compare: (a, b) => a.i - b.i,
          },
        },
        {
          title: 'Ім`я',
          dataIndex: 'n',
          key: 'n',
        },
        {
          title: 'Філія',
          dataIndex: 'f',
          key: 'f',
          render: (f) => FILII[f],
        },
        {
          title: 'Телефон',
          dataIndex: 'ph',
          key: 'ph',
        },
        {
          title: 'Бонуси',
          dataIndex: 'B',
          key: 'B',
          render: (B) => B ?? 0,
        },
      ];

  const admin = ROLES[props.role] === ROLES.admin;
  const inspector = ROLES[props.role] <= ROLES.inspector;

  const [fil, setFil] = useState(+props.fil);
  const [phone, setPhone] = useState();
  const [name, setName] = useState();
  const [selfRegistered, setSelfRegistered] = useState();
  // const [list, setList] = useState();
  const [filters, setFilters] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sendAllPop, setSendAllPop] = useState(false);
  const [sendAllPopConfirmLoading, setSendAllPopConfirmLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientCategoriesFileter, setClientCategoriesFileter] = useState();

  const updateRecordInList = (updatedRecord) => {
    const updatedList = list.map((item) => (item._id === updatedRecord._id ? updatedRecord : item));

    dispatch(setClientsList(updatedList));
  };

  const getAllClients = () => {
    setLoading(true);
    const body = { f: fil, ph: phone, n: name, page: currentPage, selfRegistered, clientCategory: clientCategoriesFileter };

    request.post('/clients/all', body, ({ data, total }) => {
      setTotal(total);
      dispatch(setClientsList(data));
      setLoading(false);
    });
  };

  const allSendBalanceTOViber = () => {
    const token = window.localStorage.getItem('accessToken');
    setSendAllPopConfirmLoading(true);

    fetch(process.env.REACT_APP_API + '/clients/allSendBalanceTOViber', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
    })
      .then((res) => res.json())
      .then(({ data }) => {
        setSendAllPop(false);
        setSendAllPopConfirmLoading(false);
      })
      .catch(() => {
        setSendAllPop(false);
        setSendAllPopConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setSendAllPop(false);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    getAllClients();
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);

    setPhone(validPhoneNumber);
  };

  useEffect(() => {
    getAllClients();
  }, [currentPage, selfRegistered]);

  return (
    <div className="clients_page default_page">
      <h2>Клієнти</h2>
      <br />
      {admin && (
        <Popconfirm
          title="Починаємо?"
          open={sendAllPop}
          onConfirm={allSendBalanceTOViber}
          okButtonProps={{ loading: sendAllPopConfirmLoading }}
          onCancel={handleCancel}
        >
          <Button className="btr-send-all btr-btr-margin" type="primary" onClick={() => setSendAllPop(true)}>
            Розіслати усім
          </Button>
        </Popconfirm>
      )}
      {inspector && (
        <>
          <Button className="ant-btr-margin btr-send-all" type="primary" onClick={() => dispatch(setModal({ name: ARCHIVE_CALENDAR }))}>
            Розіслати клієнтам з архіву
          </Button>
        </>
      )}
      <div className="row align filters" onClick={() => setFilters(!filters)}>
        {filters ? <DownOutlined /> : <RightOutlined />} <h2>Пошук:</h2>
      </div>
      {filters && (
        <Card className="filtersContainer">
          <div>
            <h4>Філія: </h4>
            <SearchSelect
              list={FILII}
              value={fil}
              disabled={ROLES[props.role] > ROLES.inspector}
              placeholder="Всі філії"
              onChange={(v) => setFil(v)}
            />
          </div>
          <div>
            <h4>Категорії клієнтів: </h4>
            <SearchSelect
              style={{ width: 280 }}
              list={Object.values(CLIENT_CATEGORIES)}
              placeholder="Всі категорії"
              valueName="value"
              labelName="label"
              value={clientCategoriesFileter}
              onChange={(v) => setClientCategoriesFileter(v)}
            />
          </div>
          <div>
            <h4>Ім'я: </h4>
            <Input value={name} placeholder="Ім'я" onChange={(e) => setName(e.target.value)} />
          </div>
          <div>
            <h4>Телефон (0501234567): </h4>
            <Input value={phone} placeholder="Телефон" onChange={(e) => handlePhoneNumberInput(e)} />
          </div>

          <Box>
            <h4>Реєстрація: </h4>

            <Radio.Group
              options={optionsSelfRegistered}
              onChange={(e) => setSelfRegistered(e.target.value)}
              value={selfRegistered}
              optionType="button"
              buttonStyle="solid"
            />
          </Box>

          <Button style={{ alignSelf: 'self-end', marginBottom: '5px', width: 150 }} type="primary" onClick={handleSearch}>
            Пошук <SearchOutlined />
          </Button>
        </Card>
      )}
      <Table
        dataSource={list}
        columns={columns}
        rowKey={(item) => item._id}
        loading={loading}
        rowClassName={(record) => (record.color ? 'client-row-' + record.color : '') + (record.bl ? ' blocked' : '')}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (item) => <ClientInfo dataClient={item} updateRecord={updateRecordInList} blocked={item.bl} />,
        }}
        pagination={{ total, current: currentPage }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Clients;
