import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, DatePicker, Button, Typography } from 'antd';
import { setModal } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import { ROLES } from 'common_constants/business';

import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
import SearchSelectPayTypeFilter from '../../components/SearchSelect/SearchSelectPayTypeFilter';

const CheckSugar = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;

  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [form] = Form.useForm();
  const [showTotalAmount, setShowTotalAmount] = useState(false);

  const { RangePicker } = DatePicker;

  const handleFormSubmit = async () => {
    setLoading(true);

    const sugarData = {
      fil: form.getFieldValue('fil'),
      dateRange: form.getFieldValue('dateRange'),
      sum: Number(form.getFieldValue('sum')),
      payBy: String(form.getFieldValue('payBy')),
    };

    await request.post(
      '/sugar/checkSugar',
      sugarData,
      ({ totalAmount }) => {
        setTotalAmount(totalAmount);

        if (totalAmount === sugarData.sum) {
          success('', 'Перевірка виконана успішно.');
          dispatch(setModal());
        } else {
          error('', 'Суми не збігаються. Будь ласка, перевірте ще раз.');
          setShowTotalAmount(true);
        }

        setLoading(false);
      },
      (err) => {
        error('', 'Помилка при перевірці.');
        setLoading(false);
      },
    );
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal title="Звірка суми за період" open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleFormSubmit} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Form.Item label="Філія" name="fil">
            <SearchSelectFil disabled={!inspector} />
          </Form.Item>

          <Form.Item label="Сума" name="sum">
            <Input onKeyDown={handleKeyPress} type="number" min={1} />
          </Form.Item>

          <Form.Item label="Спосіб оплати" name="payBy">
            <SearchSelectPayTypeFilter />
          </Form.Item>

          <Form.Item label="Період" name="dateRange">
            <RangePicker format="DD.MM.YYYY" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit">
              Перевірити
            </Button>
          </Form.Item>
        </Form>

        {showTotalAmount && <Typography.Paragraph>Сума за період: {totalAmount} грн</Typography.Paragraph>}
      </Spin>
    </Modal>
  );
};

export default CheckSugar;
