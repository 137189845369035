import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../store/commonReducer';

import './SphereEditCategory.scss';
import { useUpdateSphereServiceCategoryMutation } from '../../store/apis/sphere-api';

const SphereEditCategory = () => {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.common.modal.data);

  const [updateCategory] = useUpdateSphereServiceCategoryMutation();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      title: {
        ua: category.title.ua,
        en: category.title.en,
        de: category.title.de,
      },
    },
  });

  const handleUpdateCategory = async (data) => {
    await updateCategory({ ...data, id: category._id });
    dispatch(setModal());
  };
  return (
    <Modal open className="sphere-edit-category" title={'Редагування категорії'} onCancel={() => dispatch(setModal())} footer={null}>
      <form className="form-container" onSubmit={handleSubmit(handleUpdateCategory)}>
        <div>
          <label htmlFor="title-ua">Назва (UA)</label>
          <input id="title-ua" {...register('title.ua', { required: true })} />
        </div>

        <div>
          <label htmlFor="title-en">Назва (EN)</label>
          <input id="title-en" {...register('title.en', { required: true })} />
        </div>

        <div>
          <label htmlFor="title-de">Назва (DE)</label>
          <input id="title-de" {...register('title.de', { required: true })} />
        </div>

        <button type="submit">Оновити категорію</button>
      </form>
    </Modal>
  );
};

export default SphereEditCategory;
