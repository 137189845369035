import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'common_constants/routes';
import { HOTLINE_RESULT, HOTLINE_RESULT_REACH_FILTER } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralResultMeeting = [HOTLINE_RESULT, ['Результат зустрічі по консультації', 'amr_H'], ['Результат зустрічі', 'amr']];

const SelectGeneralResultMeeting = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;
  const { pathname } = useLocation();

  const list_select_dynamic =
    pathname === ROUTES.HOTLINE_REACH
      ? HOTLINE_RESULT_REACH_FILTER.map((i, index) => (
          <Option key={i} value={String(index)}>
            {i}
          </Option>
        ))
      : HOTLINE_RESULT.map((i, index) => (
          <Option key={i} value={String(index)}>
            {i}
          </Option>
        ));

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть результат зустрічі"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі результати зустрічів</Option>
      {list_select_dynamic}
    </Select>
  );
};

export { billSelectGeneralResultMeeting, SelectGeneralResultMeeting };
