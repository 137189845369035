import React, { useEffect } from 'react';

import { Popover, Typography, Timeline, Tag } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { cyan, gray, blue, green, orange } from '@ant-design/colors';
import { useSelector, useDispatch } from 'react-redux';
import { setPromocodes } from '../../../../../store/commonReducer';
import { request } from '../../../../../tools';

import {
  HOTLINE_ADV_WHERE,
  HOTLINE_ADV_WHERE_MORE,
  PRAVO,
  PAYMENT_FORMAT,
  SERVIS_OPTIONS,
  PRAVO_TYPES,
  REPEATS,
  INCOME_TYPES,
} from 'common_constants/business/';
import HoverableUser from '../../../../../components/HoverableUser/HoverableUser';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const ContractInfoTextPart = ({ data, users }) => {
  const dispatch = useDispatch();
  const { Text, Link } = Typography;
  const promocodes = useSelector((state) => state.common.promocodes);

  const getAllPromocodes = () => {
    request.post('/promocodes/getPromocodes', {}, ({ promocodes }) => {
      dispatch(setPromocodes(promocodes));
    });
  };

  const salaryTimeLine = () => {
    const dateNow = dayjs(),
      dateNowToFormat = dateNow.format('DD MMMM');

    const arr_create =
      data.incomes?.flatMap((income, index) => {
        const [type, amount, outdated] = [income.type, income.amount, income.outdated],
          salaryType = typeof type === 'number' ? INCOME_TYPES[type] ?? '' : '',
          notRegularPayment = type === 2,
          salaryAmount = amount ?? '',
          salaryAmountSpace = amount ? ' ' : '';

        const dateReaular = (element) => {
          const [daysDifference, mounthsDifference] = [dateNow.diff(element, 'day'), dateNow.diff(element, 'month')];

          const isWithin15Days = daysDifference >= -15 && daysDifference <= 15; //* Перевіряємо, чи пройшло менше ніж 15 дні з першої дати до другої

          const controler =
            !notRegularPayment && //* не повинно бути не регульованим
            !isWithin15Days; //* повинно пройти 15 днів від дати зараз, щоб перенести елемент на 15 днів перед дати зараз

          const elementNew = () => {
            const editingMonths = element.add(mounthsDifference, 'month');

            const daysDifferencee_ElementNew = dateNow.diff(editingMonths, 'day'); //* Різниця в днях

            const isWithin15Days_ElementNew = daysDifferencee_ElementNew <= 15; //* Перевіряємо, чи пройшло менше ніж 15 дні з першої дати до другої
            const isWithinNegative15Days_ElementNew = daysDifferencee_ElementNew >= -15;

            const editingMonthNow = !isWithin15Days_ElementNew
              ? editingMonths.add(1, 'month')
              : !isWithinNegative15Days_ElementNew
              ? editingMonths.subtract(1, 'month')
              : editingMonths;
            return editingMonthNow;
          };

          const finish = controler ? elementNew() : element;

          return finish;
        }; //* Логіка регулярних платежів. Кожен застарілий становиться новим елементом

        const [date1, date2] = [income.date, income.date2],
          [dateFirst, dateSecond] = [dayjs(date1), dayjs(date2)],
          [dateFirstReqular, dateSecondReqular] = [dateReaular(dateFirst), dateReaular(dateSecond)],
          dateFirstToFormat = date1 ? dateFirstReqular?.format('DD MMMM') ?? '' : '',
          dateSecondToFormat = date2 ? dateSecondReqular?.format('DD MMMM') ?? '' : '';

        const baseText = (
          <span key={index}>
            <Text mark>{salaryAmount}</Text>
            {salaryAmountSpace}
            <Text>{salaryType}</Text>
          </span>
        );

        const dynamic_color = (element) => {
          const daysDifference = dateNow.diff(element, 'day'), //* Різниця в днях між першою і другою датою
            [isWithin3Days, isWithin6Days] = [daysDifference <= 3, daysDifference <= 6], //* Перевіряємо, чи пройшло менше ніж 3|6 дні з першої дати до другої
            isWithin6DaysColor = isWithin6Days ? orange[5] : gray[0],
            isWithin3DaysColor = isWithin3Days ? green[5] : isWithin6DaysColor;

          const color = dateNow.isAfter(element) ? isWithin3DaysColor : blue[5];

          return color;
        }; //* Регулятор кольору на часовій лінії

        const pointDate_1 = {
            label: dateFirstToFormat,
            children: baseText,
            season: dateFirstReqular,
            color: dynamic_color(dateFirstReqular),
          },
          pointDate_2 = {
            label: dateSecondToFormat,
            children: baseText,
            season: dateSecondReqular,
            color: dynamic_color(dateSecondReqular),
          };

        const resultArr = [];

        if (!outdated && date1) resultArr.push(pointDate_1);
        if (!outdated && date2) resultArr.push(pointDate_2);

        return resultArr;
      }) ?? []; //* Створення списку доходів

    if (arr_create.length > 0) {
      const nowUserOfDate = {
        label: <Text strong>{dateNowToFormat}</Text>,
        children: <Text strong>Зараз</Text>,
        season: dateNow,
        color: cyan[4],
        dot: (
          <ClockCircleOutlined
            style={{
              fontSize: '16px',
            }}
          />
        ),
      };

      arr_create.push(nowUserOfDate);
    } //* Створення пункту - Зараз

    const arr_sort = arr_create.sort((a, b) => {
      const [dateA, dateB] = [a.season, b.season];

      if (dateA.isBefore(dateB)) {
        return -1;
      }
      if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    }); //* Сортування по даті

    return arr_sort.map((item, index) => (
      <Timeline.Item key={index} dot={item.dot} color={item.color}>
        <strong>{item.label}&nbsp;</strong>
        {item.children}
      </Timeline.Item>
    ));
  };
  const lastAutopayDate = data?.autoPayTransactions?.slice(-1)[0]?.date;

  useEffect(() => {
    getAllPromocodes();
  }, []);

  return (
    <>
      {data?.incomes && (
        <span>
          <Text strong>Доходи:</Text> {data.incomes.length === 0 ? '-' : <Timeline mode="left">{salaryTimeLine()}</Timeline>}
        </span>
      )}
      <div style={data.rb ? { border: '1px solid red', padding: '5px' } : {}}>
        <span>
          <Text strong style={{ color: 'red' }}>
            Тривожна кнопка:{' '}
          </Text>
          <Text>{data.rb ? 'Так' : 'Ні'}</Text>
        </span>
        <br />
        <span>
          <Text strong>Дата останнього автоплатежу: </Text>
          <Text>
            {lastAutopayDate ? dayjs(lastAutopayDate).format('DD-MM-YYYY') : dayjs(data?.sd).format('DD-MM-YYYY')}{' '}
            {!lastAutopayDate && '(Дата придбання)'}
          </Text>
        </span>
      </div>
      <span>
        <Text strong>ОВ:</Text>&nbsp;
        <Text>
          {data.ov1 ?? '-'} {data.ov2 ?? '-'} {data.ov3 ?? '-'}
          {data.ov1 === '' && data.ov2 === '' && data.ov3 === '' ? '-' : ''}
        </Text>
      </span>
      <span>
        <Text strong>Дата підписання:</Text>&nbsp;
        <Text>{dayjs(data.sd)?.format('DD-MM-YYYY') ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Уклав:</Text>&nbsp;
        <Popover content={<HoverableUser id={data.us} />} arrow placement="right" trigger="hover">
          <Link>{users[data.us]?.name ?? '-'}</Link>
        </Popover>
      </span>
      <span>
        <Text strong>Промокод з консультації:</Text>&nbsp;
        {data?.promocode ? (
          <Tag color="green" style={{ fontSize: '14px' }}>
            <b>{promocodes.find((promo) => promo._id === data?.promocode)?.promocode}:</b>
            знижка {promocodes.find((promo) => promo._id === data?.promocode)?.discount}%
          </Tag>
        ) : (
          '-'
        )}
      </span>
      <span>
        <Text strong>Хто буде обслуговувати:</Text>&nbsp;
        <Text>{SERVIS_OPTIONS[data.un] ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Офіс/дист:</Text>&nbsp;
        <Text>{data.od === 1 ? 'Дист' : 'Офіс'}</Text>
      </span>
      <span>
        <Text strong>Повторно:</Text>&nbsp;
        <Text>{REPEATS[data.tm] ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Як дізнався / вид р:</Text>&nbsp;
        <Text>{HOTLINE_ADV_WHERE[data.aw] ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Що саме:</Text>&nbsp;
        <Text>{HOTLINE_ADV_WHERE_MORE[data.am] ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Дата 1п:</Text>&nbsp;
        <Text>{dayjs(data.md)?.format('DD-MM-YYYY') ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Сума (1п?):</Text>&nbsp;
        <Text>{data.fp ?? '-'}</Text>
      </span>
      <span>
        <Text strong>№ ККАОЗ:</Text>&nbsp;
        <Text>{data.kk ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Вид правовідносин:</Text>&nbsp;
        <Text>{PRAVO_TYPES[data.pr] ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Вид правовідносин Що саме:</Text>&nbsp;
        <Text>{PRAVO[data.prc] ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Предмет договору:</Text>&nbsp;
        <Text>{data.ci ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Своїми словами:</Text>&nbsp;
        <Text>{data.cd ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Форма гонорару:</Text>&nbsp;
        <Text>{PAYMENT_FORMAT[data.pf] ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Сума гонорару:</Text>&nbsp;
        <Text>{data.pa ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Кількість місяців в розстрочку:</Text>&nbsp;
        <Text>{data.installmentMonths ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Кількість місяців:</Text>&nbsp;
        <Text>{data.pm === 13 ? 'Бескінечно' : data.pm ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Вихід адвоката:</Text>&nbsp;
        <Text>{data.ea ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Оплатив:</Text>&nbsp;
        <Text>{data.P ?? '-'}</Text>
      </span>
      <span>
        <b>Фабула справи:</b> {data?.shortQuestionnaire?.plotCase ?? '-'}
      </span>
      <span>
        <b>Що хоче клієнт:</b> {data?.shortQuestionnaire?.customerWants ?? '-'}
      </span>
      <span>
        <b>Що обіцяли клієнту:</b> {data?.shortQuestionnaire?.promiseClient ?? '-'}
      </span>
      <span>
        <b>Дата кінцевого результату:</b>{' '}
        {data?.shortQuestionnaire?.notDateFinalResult
          ? 'Термін не обговорювався'
          : data?.shortQuestionnaire?.dateFinalResult
          ? dayjs(data?.shortQuestionnaire?.dateFinalResult).format('DD-MM-YYYY')
          : '-'}
      </span>
      <span>
        <b>Примітка:</b> {data?.shortQuestionnaire?.note ?? '-'}
      </span>
      <span>
        <b>Завдання</b>
        <div>
          {data?.shortQuestionnaire?.tasks?.map((task, index) => (
            <div key={index} style={{ margin: '5px 0px 5px 10px' }}>
              <b>Завдання №{index + 1}</b> <br />
              <p style={{ marginLeft: '5px', marginTop: '5px' }}>
                <b>Опис:</b> {task.description} <br />
                <b>Термін:</b> {task.notDate ? 'Термін не обговорювався' : dayjs(task.date).format('DD-MM-YYYY')}
              </p>
            </div>
          ))}
        </div>
      </span>
    </>
  );
};

export default ContractInfoTextPart;
