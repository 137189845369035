import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { USER_TOKEN_TYPE } from 'common_constants/business';

import { setSocket } from '../store/commonReducer';

const useConnectSocket = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const socket = useSelector((state) => state.common?.socket);

  const connectSocket = (userToken) => {
    if (window?.ReactNativeWebView) return;
    const socket = io(process.env.REACT_APP_API, {
      query: {
        token: userToken,
        tokenType: USER_TOKEN_TYPE.crmUser,
      },
    });

    return socket;
  };

  useEffect(() => {
    if (window?.ReactNativeWebView) return;
    const token = window.localStorage.getItem('accessToken') ?? userAuth?.token;
    if (token) {
      const currentSocket = connectSocket(token);

      dispatch(setSocket(currentSocket));

      return () => currentSocket.close();
    } else {
      if (socket) {
        socket.close();
        dispatch(setSocket(null));
      }
    }
  }, [userAuth?._id]);
};

export default useConnectSocket;
