import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setMainLoader } from '../store/uiReducer';
import { setUsers } from '../store/commonReducer';
import { prepareUsers, request } from '../tools';

const useGetUsers = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    dispatch(setMainLoader(true));
    dispatch(setUsers({}));

    return request.post('/auth/getUsersCopy', {}, ({ data }) => {
      const _users = prepareUsers(data);

      dispatch(setUsers(_users));
      dispatch(setMainLoader(false));

      return _users;
    });
  }, []);
};

export default useGetUsers;
