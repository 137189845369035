import ReactECharts from 'echarts-for-react';

const PromocodeBarChart = ({ data, hotlines }) => {
  const countPercentage = (value) => {
    return ((value / hotlines) * 100).toFixed(2);
  };

  const option = {
    title: {
      text: 'Статистика промокодів',
      left: 'center',
      padding: [40, 0, 0, 0],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const item = data[params[0].dataIndex];
        return `Промокод: ${item.promocodeName}<br />Використано: ${item.usageCount}<br />Відсоток від всіх консультацій: ${countPercentage(
          item.usageCount,
        )}%`;
      },
    },
    legend: {},
    grid: {
      left: '4%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: data.map((i) => i.promocodeName),
    },
    series: [
      {
        name: 'Використання промокоду',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'none',
        },
        data: data.map((i) => i.usageCount || null),
      },
    ],
  };

  return (
    <>
      <p style={{ textAlign: 'center', fontSize: '16px' }}>Загальна кількість консультацій - {hotlines}</p>
      <ReactECharts option={option} notMerge={true} lazyUpdate={true} style={{ height: '1000px' }} theme={'theme_name'} />
    </>
  );
};

export default PromocodeBarChart;
