import React, { useState } from 'react';
import { Input, Button, Space } from 'antd';
import { SendOutlined } from '@ant-design/icons';

const InputField = ({ loading, onSendMessage }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = (e) => setQuery(e.target.value);
  const handleSend = () => {
    const preparedQuery = query.trim();
    if (preparedQuery) {
      onSendMessage(preparedQuery);
      setQuery('');
    }
  };

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input onPressEnter={handleSend} onInput={handleInputChange} value={query} />
      <Button onClick={handleSend} loading={loading} type="primary" icon={<SendOutlined />}></Button>
    </Space.Compact>
  );
};

export default InputField;
