/* eslint-disable no-template-curly-in-string */
import { useEffect, useState } from 'react';
import { Modal, Select, Button, Form, InputNumber, Spin } from 'antd';
import dayjs from 'dayjs';

import { warn, success, request, error } from '../../../../../tools';

const InsuranceTransactionModal = (props) => {
  const { insuranceTransactionState, handleChangeInsuranceTransactionState, contractId, insurances, findInsurance, handleChangeInsurances } = props;

  const [loading, setLoading] = useState(true);
  const [bonusesState, setBonusesState] = useState('Завантаження');

  const [temporaryBonusesState, setTemporaryBonusesState] = useState(0);
  const [transactionType, setTransactionType] = useState(0);
  const [transactionPrice, setTransactionPrice] = useState(0);
  const [transactionMultiplier, setTransactionMultiplier] = useState(1);
  const [transactionSum, setTransactionSum] = useState(0);
  const [transactionInsurance, setTransactionInsurance] = useState(Object.keys(insurances)[0]);
  const validUntil = dayjs(insurances[transactionInsurance].endDate, 'DD-MM-YYYY');
  const isValidInsurance = dayjs().isBefore(validUntil);

  const selectedInsurance = findInsurance(transactionInsurance);

  const [form] = Form.useForm();

  const handleChangeType = (value) => setTransactionType(value);

  const handleChangeTransactionInsurance = (value) => setTransactionInsurance(value);

  const handleChangeTemporaryBonusesState = (value) => setTemporaryBonusesState(value);

  const handleChangeBonusesState = (value) => setBonusesState(value);

  const onChangePrice = (value) => {
    setTransactionSum(value * transactionMultiplier);
    setTransactionPrice(value);
    const calcValue = bonusesState - value * transactionMultiplier;

    if (!value) return handleChangeTemporaryBonusesState(bonusesState);

    if (calcValue < 0) {
      warn('Не вистачає бонусів!', `Перевищено на ${calcValue * -1} грн!`);
      setTemporaryBonusesState(bonusesState);
      return;
    }

    if (value < 0) {
      warn('Введіть коректне значення!', "Ціна не може бути від'ємною!");
      handleChangeTemporaryBonusesState(bonusesState);
      return;
    }

    handleChangeTemporaryBonusesState(bonusesState - value * transactionMultiplier);
  };

  const onChangeMultiplier = (value) => {
    setTransactionSum(value * transactionPrice);
    setTransactionMultiplier(value);
    const calcValue = bonusesState - transactionPrice * value;

    if (!value) return handleChangeTemporaryBonusesState(bonusesState);

    if (calcValue < 0) {
      warn('Не вистачає бонусів!', `Перевищено на ${calcValue * -1} грн!`);
      setTemporaryBonusesState(bonusesState);
      return;
    }

    if (value < 0) {
      warn('Введіть коректне значення!', "Ціна не може бути від'ємною!");
      handleChangeTemporaryBonusesState(bonusesState);
      return;
    }

    handleChangeTemporaryBonusesState(bonusesState - transactionPrice * value);
  };

  const getInsuranceBalance = async () => {
    setLoading(true);
    await request.post(
      '/insurance/getInsuranceBalance',
      { contractId, insuranceType: transactionInsurance },
      (res) => {
        setBonusesState(res.balance);
        setTemporaryBonusesState(res.balance);
      },
      () => {
        error();
      },
    );

    setLoading(false);
  };

  useEffect(() => {
    getInsuranceBalance();
  }, []);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const validateMessages = {
    required: "Поле '${label}' обов'язкове до заповнення!",
    types: {
      number: "Значення поля '${label}' має бути числом!",
    },
    number: {
      range: 'Доступно ${max} бонусів!',
    },
  };

  const resetFields = () => {
    form.resetFields(['price']);
    form.resetFields(['multiplier']);
    setTransactionPrice(0);
    setTransactionMultiplier(0);
    setTransactionSum(0);
  };

  const onFinish = () => {
    if (!transactionPrice) {
      warn('Заповніть поле "Ціна"!', 'Сума транзакції не може бути нулем.');
      return;
    }

    if (transactionSum >= bonusesState) warn('Не вистачає бонусів!', 'Сума повинна бути менша за страховий рахунок.');

    const transactionBody = {
      date: dayjs().toISOString(),
      type: Number(transactionType),
      price: transactionPrice,
      multiplier: transactionMultiplier,
    };

    request.post(
      '/insurance/addTransaction',
      { contractId, transactionInsurance, transactionBody },
      (res) => {
        const { balance } = res;
        const updatedInsurunces = Object.assign({}, insurances[transactionInsurance], {
          transactions: [...insurances[transactionInsurance].transactions, transactionBody],
        });
        handleChangeInsurances((prevState) => ({
          ...prevState,
          [transactionInsurance]: updatedInsurunces,
        }));
        handleChangeBonusesState(balance);
        handleChangeTemporaryBonusesState(balance);
        resetFields();
        success('Страхову транзакцію успішно створено!');
      },
      (title, desc, res) => {
        const { balance } = res;
        error(res.error, `Актуальний баланс бонусів ${balance}`);
        handleChangeBonusesState(balance);
        handleChangeTemporaryBonusesState(balance);
        resetFields();
      },
    );
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      title="Страхова транзакція"
      open={insuranceTransactionState}
      onCancel={handleChangeInsuranceTransactionState}
      footer={[
        <Button key="back" onClick={handleChangeInsuranceTransactionState}>
          Закрити
        </Button>,
      ]}
    >
      <Spin spinning={loading} tip="Loading" size="large">
        <div className="content" />
      </Spin>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <span style={{ fontWeight: 'bold' }}>Страховий рахунок: </span>
          <span>{temporaryBonusesState}</span>
        </div>
        <span>
          <span style={{ fontWeight: 'bold' }}>Дійсна до:</span> {validUntil.format('DD-MM-YYYY')}
        </span>
      </div>
      <Form form={form} {...layout} name="nest-messages" onFinish={onFinish} style={{ maxWidth: 600 }} validateMessages={validateMessages}>
        <Form.Item name={['insurance']} label="Страховка" initialValue={selectedInsurance.name} rules={[{ type: 'insurance' }]}>
          <Select
            style={{
              width: '100%',
            }}
            value={selectedInsurance.name}
            onChange={handleChangeTransactionInsurance}
            options={Object.keys(insurances).map((key) => ({
              value: key,
              label: findInsurance(key).name,
            }))}
            rules={[{ required: true }]}
          />
        </Form.Item>
        <Form.Item initialValue={selectedInsurance.services[transactionType].name} name={['type']} label="Послуга" rules={[{ type: 'service' }]}>
          <Select
            style={{
              width: '100%',
            }}
            value={selectedInsurance.services[transactionType].name}
            onChange={handleChangeType}
            options={selectedInsurance.services.map((el, index) => ({
              value: index,
              label: el.name,
            }))}
            rules={[{ required: true }]}
          />
        </Form.Item>

        <Form.Item name={['price']} label="Ціна" rules={[{ type: 'number', min: 0, required: true }]}>
          <InputNumber onKeyDown={handleKeyPress} placeholder={selectedInsurance.services[transactionType].cost} disabled={!isValidInsurance} onChange={onChangePrice} />
        </Form.Item>

        <Form.Item name={['multiplier']} label="Кількість годин" rules={[{ type: 'number', required: true }]}>
          <InputNumber placeholder={1} min={1} disabled={!isValidInsurance} onChange={onChangeMultiplier} />
        </Form.Item>

        <Form.Item label="Сума">
          <InputNumber onKeyDown={handleKeyPress} value={transactionSum} disabled={true} />
        </Form.Item>

        {isValidInsurance ? (
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Підтвердити
            </Button>
          </Form.Item>
        ) : (
          <div style={{ textAlign: 'center', color: 'red' }}>Термін дії страховки закінчився!</div>
        )}
      </Form>
    </Modal>
  );
};

export default InsuranceTransactionModal;
