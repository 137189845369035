import React, { useEffect, useState } from 'react';
import { TableOutlined, MenuOutlined, DownOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Card, Form, Input, InputNumber, Pagination, Spin, Typography, Table, Divider, Empty, Tabs, Row as AntdRow } from 'antd';
import { ExcelBtn, Row, Box } from 'common_components';

import { useDispatch, useSelector } from 'react-redux';
import { setPushNotification } from '../../store/commonReducer';
import { error, request } from '../../tools';
import PushNotificationInfo from './PushNotificationInfo';
import { setMainLoader } from '../../store/uiReducer';
import { ROLES } from 'common_constants/business';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';

import TabPane from 'antd/es/tabs/TabPane';
import PageInfo from '../../components/PageInfo/PageInfo';

import './PushNotification.scss';

const DEFAULT_PAGE_SIZE = 10;

const PushNotification = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const pushNotification = useSelector((state) => state.common.pushNotification || []);
  const FILII = useSelector((state) => state.common.FILII);
  const mob = useSelector((state) => state.ui.mob);

  const userAuth = useSelector((state) => state.common.userAuth);
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const admin = ROLES[userAuth.role] === ROLES.admin;

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [exportData, setExportData] = useState(null);
  const [exportRange, setExportRange] = useState(null);
  const [activeTab, setActiveTab] = useState('allPN');
  const [displayMode, setDisplayMode] = useState('list');

  const { Item } = Form;
  const { Text } = Typography;
  const { RangePicker } = DatePicker;

  const getPushNotificationData = (limits, activeTab) => {
    setLoading(true);
    const params = { ...limits, ...currentFilters, activeTab };

    request.post(
      '/pushNotification/getAll',
      params,
      ({ data, total }) => {
        dispatch(setPushNotification(data));
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getPushNotificationData({ page, pageSize, ...currentFilters }, activeTab);
  };

  useEffect(() => {
    const limits = { page: currentPage, pageSize, ...currentFilters };
    getPushNotificationData(limits, activeTab);
  }, [currentPage, currentFilters, activeTab]);

  const handleFilterChange = (values) => {
    setCurrentFilters(values);
    setCurrentPage(1);
  };

  const loadExport = () => {
    dispatch(setMainLoader(true));

    request.post('/pushNotification/export', { exportRange }, ({ data }) => {
      setExportData(data);
      dispatch(setMainLoader(false));
    });
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const expandable = mob
    ? null
    : {
        expandRowByClick: true,
        expandedRowRender: (item) => <PushNotificationInfo key={item._id} data={item} />,
      };

  const columns = mob
    ? [
        Table.EXPAND_COLUMN,
        {
          title: 'Філія',
          dataIndex: 'fil',
          key: 'fil',
          render: (fil) => FILII[fil],
        },
        {
          title: 'Дата відправки',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (payday) => dayjs(payday).format('DD.MM.YYYY'),
        },
        {
          title: 'Отримувач',
          dataIndex: 'name',
          key: 'name',
        },
      ]
    : [
        Table.EXPAND_COLUMN,
        {
          title: '№',
          dataIndex: 'i',
          key: 'i',
          defaultSortOrder: 'descend',
          sorter: {
            compare: (a, b) => a.i - b.i,
          },
        },
        {
          title: 'Філія',
          dataIndex: 'fil',
          key: 'fil',
          render: (fil) => FILII[fil],
        },
        {
          title: 'Дата відправки',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (payday) => dayjs(payday).format('DD.MM.YYYY'),
        },
        {
          title: 'Отримувач',
          dataIndex: 'name',
          key: 'name',
        },

        {
          title: 'Тип',
          dataIndex: 'type',
          key: 'type',
        },
      ];

  const formatedExportData =
    exportData &&
    exportData
      .sort((a, b) => a.i - b.i)
      .map((item) => [
        item.i,
        item?.fil,
        dayjs(item.createdAt).format('DD.MM.YYYY'),
        item?.contract,
        item?.name,
        item?.phone,
        item?.title,
        item?.text,
        item?.type,
        item?.token,
        item?.chatId,
      ]);

  formatedExportData?.unshift([
    '№ інформації',
    'філія',
    'Дата відправки',
    '№договора',
    'Ім`я отримувача',
    'Телефон отримувача',
    'Заголовок повідомлення',
    'Текст повідомлення',
    'Тип',
    'Токен відпраки',
    'ID чату',
  ]);

  if (!users) return null;

  return (
    <div className="sugar_page default_page">
      <AntdRow justify={'center'} align={'middle'}>
        <h2>Пуш-повідомлення</h2>
        <PageInfo page="pushNotification" />
      </AntdRow>
      <Tabs className="tabs" activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Всі" key="allPN"></TabPane>
        <TabPane tab="Адвокатам" key="lawyerPN"></TabPane>
        <TabPane tab="Клієнтам" key="clientPN"></TabPane>
        <TabPane tab={mob ? 'За договором' : 'Клієнтам за договором'} key="contractPN"></TabPane>
      </Tabs>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: 6 }} onClick={() => setDisplayMode('list')} disabled={displayMode === 'list'}>
          <MenuOutlined />
        </Button>
        <Button onClick={() => setDisplayMode('table')} disabled={displayMode === 'table'}>
          <TableOutlined />
        </Button>
      </div>
      <br />
      {admin && (
        <Row style={{ justifyContent: 'center' }}>
          {exportData ? (
            <CSVLink asyncOnClick={true} data={exportData && formatedExportData}>
              <ExcelBtn style={{ width: 'auto' }}>Скачати файл</ExcelBtn>
            </CSVLink>
          ) : (
            <>
              <DatePicker.RangePicker style={{ marginRight: 16 }} onChange={(e) => setExportRange(e)} format="DD.MM.YYYY" />
              <ExcelBtn style={{ width: 'auto' }} onClick={loadExport}>
                Export
              </ExcelBtn>
            </>
          )}
        </Row>
      )}

      <div className="row align filters" onClick={() => setFiltersOpen(!filtersOpen)}>
        {filtersOpen ? <DownOutlined /> : <RightOutlined />} <h2>Пошук</h2>
      </div>

      {filtersOpen && (
        <Card className="filtersContainer">
          <Form onFinish={handleFilterChange} layout="inline" initialValues={{ fil: inspector ? '' : userAuth.fil }}>
            <div style={{ width: '320px' }}>
              <Divider children={'Фільтри:'} style={{ margin: 0 }} orientation="left" />
              <Box>
                <Text>Філія: </Text> &nbsp;
                <Item name="fil" children={<SearchSelectFil />} />
              </Box>

              <Box>
                <Text>№ договору: </Text> &nbsp;
                <Item name="contractIndex" children={<InputNumber />} />
              </Box>

              <Box>
                <Text>Отримувач: </Text> &nbsp;
                <Item name="name" children={<Input />} />
              </Box>

              <Box>
                <Text>Період: </Text> &nbsp;
                <Item name="dateRange" children={<RangePicker format="DD.MM.YYYY" />} />
              </Box>

              <Box>
                <Button style={{ width: 150 }} type="primary" htmlType="submit">
                  Пошук <SearchOutlined />
                </Button>
              </Box>
            </div>
          </Form>
        </Card>
      )}

      <Spin spinning={loading} tip="Зачекайте" size="large">
        {displayMode === 'list' ? (
          <>
            {pushNotification?.length ? (
              pushNotification?.map((item) => <PushNotificationInfo key={item._id} data={item} />)
            ) : (
              <Empty description={<Text>Немає пуш повідомлень</Text>} />
            )}
            <Pagination
              style={{ textAlign: 'center' }}
              current={currentPage}
              pageSize={DEFAULT_PAGE_SIZE}
              total={total}
              onChange={handlePageChange}
            />
          </>
        ) : (
          <Table
            dataSource={pushNotification}
            columns={columns}
            rowKey={(item) => item._id}
            loading={loading}
            expandable={expandable}
            pagination={{ total, current: currentPage }}
            onChange={handleTableChange}
          />
        )}
      </Spin>
    </div>
  );
};

export default PushNotification;
