import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button, Space, Tag, Typography, Modal, Divider, Popover } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import { gray } from '@ant-design/colors';

import { ROUTES } from 'common_constants/routes';
import { Box } from 'common_components';
import {
  PRAVO_TYPES_LIBRARY,
  EXACTLY_WHAT_LIBRARY,
  SERVIS_OPTIONS,
  TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY,
  FILE_TYPE_LIBRARY,
} from 'common_constants/business';
import { DOCUMENT_TEMPLATES, SEND_CONTRACT_DATA_MFO } from 'common_constants/modals';

import HoverableUser from '../../../components/HoverableUser/HoverableUser';
import '../LibraryItem.scss';
import '../../../Modals/LibraryMiniList/styles.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const LibraryInfoFile = ({ item }) => {
  const { pathname } = useLocation();

  const users = useSelector((state) => state.common.users);
  const modalData = useSelector((state) => state.common.modal);

  const { Text, Link } = Typography,
    [modal, contextHolder] = Modal.useModal();

  const core_megaState = {
      auditСase: false, //* Результат зустрічі
    },
    [megaState, setMegaState] = useState(core_megaState);

  useEffect(() => {
    if (item.h?.[0] === '1') setMegaState({ ...megaState, auditСase: true }); //* Контролер - Назва справи
  }, [item.h]);

  const config_modal_info = {
    title: 'Інфо',
    content: (
      <>
        <Divider style={{ borderBottom: '1px solid #000', margin: '8px 0' }} orientation="center">
          Введені користувачем
        </Divider>

        <Space>
          <Text className="lib-item-text" strong>
            Важливість:
          </Text>

          <Tag color={gray[item.o ? 3 : 7]}>{item.o ? "Необов'язковий" : "Обов'язковий"}</Tag>
        </Space>

        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Назва:&ensp;
            </Text>
            <Text>{item.n ?? '-'}</Text>
          </Space>
        </Box>

        {item.z === '1' && (
          <Box>
            <Space size={[0, 8]} wrap>
              <Text className="lib-item-text" strong>
                Адреса посилання:&ensp;
              </Text>
              <Link target="_blank" href={item.p ?? '-'}>
                {item.p ?? '-'}
              </Link>
            </Space>
          </Box>
        )}

        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Хештег:&ensp;
            </Text>
            {item.h?.map((va, step) => (
              <Tag key={step} color="cyan">
                {va}
              </Tag>
            )) ?? '-'}
          </Space>
        </Box>

        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Номер звітності:&ensp;
            </Text>
            {item.r ?? '-'}
          </Space>
        </Box>

        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Корисно для Блоку:&ensp;
            </Text>
            {item.j?.map((va, step) => (
              <Tag key={step} color="blue">
                {SERVIS_OPTIONS[va]}
              </Tag>
            )) ?? '-'}
          </Space>
        </Box>

        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Вид правовідносин:&ensp;
            </Text>
            {item.k?.map((va, step) => (
              <Tag key={step} color="geekblue">
                {PRAVO_TYPES_LIBRARY[va]}
              </Tag>
            )) ?? '-'}
          </Space>
        </Box>
        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Що саме:&ensp;
            </Text>
            {item.w?.map((va, step) => (
              <Tag key={step} color="purple">
                {EXACTLY_WHAT_LIBRARY[va]}
              </Tag>
            )) ?? '-'}
          </Space>
        </Box>

        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Вид файлу:&ensp;
            </Text>

            {(item.g && TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY?.[item.g]?.label) ?? '-'}
          </Space>
        </Box>

        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Форма файлу:&ensp;
            </Text>

            {(item.g && item.q && TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY?.[item.g]?.form?.[item.q]?.label) ?? '-'}
          </Space>
        </Box>

        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Коментар:&ensp;
            </Text>

            {item.m ?? '-'}
          </Space>
        </Box>

        <Divider style={{ borderBottom: '1px solid #000', margin: '8px 0' }} orientation="center">
          Службові дані
        </Divider>

        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Номер файлу:&ensp;
            </Text>
            {item.i ?? '-'}
          </Space>
        </Box>

        <Box>
          <Space size={[0, 8]} wrap>
            <Text className="lib-item-text" strong>
              Автор:&ensp;
            </Text>
            <Popover content={<HoverableUser id={item.a} />} arrow placement="topLeft" trigger="hover">
              <Link>{(users && users[item.a]?.name) ?? '-'}</Link>
            </Popover>
          </Space>
        </Box>

        {megaState.auditСase && (
          <Box>
            plain
            <Space>
              <Text className="lib-item-text" strong>
                Назва справи:
              </Text>
              <Text>{item.p ?? '-'}</Text>
            </Space>
          </Box>
        )}

        <Box>
          <Space>
            <Text className="lib-item-text" strong>
              Тип файлу:
            </Text>
            <Text>{FILE_TYPE_LIBRARY[item.z] ?? '-'}</Text>
          </Space>
        </Box>

        {item.z === '0' && (
          <Box>
            <Space size={[0, 8]} wrap>
              <Text className="lib-item-text" strong>
                Формат файлу:&ensp;
              </Text>
              {item.f ?? '-'}
            </Space>
          </Box>
        )}

        {item.z === '0' && (
          <Box>
            <Space>
              <Text className="lib-item-text" strong>
                Розмір файлу:
              </Text>
              <Text> {Math.round(item.s ?? 0 / 1024)} КБ</Text>
            </Space>
          </Box>
        )}
        <Box>
          <Space>
            <Text className="lib-item-text" strong>
              Дата створення:
            </Text>
            <Text>{dayjs(item.t)?.format('DD MMMM YYYY року') ?? '-'}</Text>
          </Space>
        </Box>
        <Box>
          <Space>
            <Text className="lib-item-text" strong>
              Кількість {item.z === '1' ? 'переглядів' : 'завантажень'}:
            </Text>
            <Text>{item.c ?? 0}</Text>
          </Space>
        </Box>
      </>
    ),
  };

  const body_func_start = async () => modal.info(config_modal_info),
    body_component_btn = (
      <Button size="small" style={{ paddingLeft: '8px' }} icon={<InfoOutlined />} onClick={body_func_start} type="primary">
        Інфо
      </Button>
    ),
    body_component_a = <Link onClick={body_func_start}>Інфо</Link>,
    dynamic_components =
      modalData?.name === DOCUMENT_TEMPLATES || modalData?.name === SEND_CONTRACT_DATA_MFO
        ? body_component_a
        : {
            [ROUTES.CONTRACTS]: body_component_btn,
            [ROUTES.LIST]: body_component_btn,
            [ROUTES.HOTLINE_REACH]: body_component_btn,
            [ROUTES.LIBRARY]: body_component_a,
          }[pathname] || body_component_btn;

  return (
    <>
      {dynamic_components}
      {contextHolder}
    </>
  );
};

export default LibraryInfoFile;
