import React from 'react';
import { SOC_STATUS } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralSocStatus = [SOC_STATUS, ['Соц статус по клієнту', 'ss_C']];

const SelectGeneralSocStatus = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть соц. статус"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі соц. статуси</Option>
      {SOC_STATUS.map((i, index) => (
        <Option key={i} value={String(index)}>
          {i}
        </Option>
      ))}
    </Select>
  );
};

export { billSelectGeneralSocStatus, SelectGeneralSocStatus };
