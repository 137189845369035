import { PlusCircleOutlined } from '@ant-design/icons';
import PageInfo from '../../components/PageInfo/PageInfo';
import { Button, Input, Pagination, Spin, Typography, Empty, Row as AntdRow, Row, Col } from 'antd';
import { setModal, setPromocodes } from '../../store/commonReducer';
import { ADD_PROMOCODE } from 'common_constants/modals';
import { useDispatch, useSelector } from 'react-redux';
import { error, request, success } from '../../tools';
import { useEffect, useState } from 'react';
import { PAGE_SIZE_OPTIONS } from 'common_constants/db';
import PromocodeItem from './PromocodeItem';

const Promocodes = () => {
  const dispatch = useDispatch();
  const promocodes = useSelector((state) => state.common.promocodes);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { Text } = Typography;

  const onChangeSearchInput = (e) => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
  };

  const getAllPromocodes = (limits) => {
    setLoading(true);
    const params = { ...limits, promocode: searchValue };

    request.post(
      '/promocodes/getPromocodes',
      params,
      ({ promocodes, total }) => {
        dispatch(setPromocodes(promocodes));
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    const limits = {
      page: currentPage,
      pageSize,
    };
    getAllPromocodes(limits);
  }, [currentPage, pageSize, searchValue]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deletePromocode = (id) => {
    const onSuccess = () => {
      const updatedPromocodes = promocodes.map((promo) => (promo._id === id ? { ...promo, isDeleted: true } : promo));
      dispatch(setPromocodes(updatedPromocodes));
      success('Успіх!', 'Промокод успішно відзначено як видалений!');
    };

    const onError = () => error('Помилка!', 'Схоже, не вдалося відзначити промокод як видалений!');

    request.post(`/promocodes/markAsDeleted/${id}`, null, onSuccess, onError);
  };

  const editPromocode = (id) => {
    const promocodeToEdit = promocodes.find((item) => item._id === id);
    dispatch(setModal({ name: ADD_PROMOCODE, editItem: promocodeToEdit }));
  };

  return (
    <div className="sugar_page default_page">
      <AntdRow justify={'center'} align={'middle'}>
        <h2>Промокоди</h2>
        <PageInfo page="promocodes" />
      </AntdRow>
      <br />
      <Input placeholder="Введіть назву для пошуку..." value={searchValue} onChange={onChangeSearchInput} />
      <br /> <br />
      <Row style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_PROMOCODE }))}>
          <PlusCircleOutlined /> Додати
        </Button>
      </Row>
      <br />
      <Spin spinning={loading} tip="Зачекайте" size="large">
        <Row gutter={[24, 16]}>
          {promocodes?.length ? (
            promocodes.map((item) => (
              <Col key={item._id} lg={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <PromocodeItem item={item} deletePromocode={deletePromocode} editPromocode={() => editPromocode(item._id)} />
              </Col>
            ))
          ) : (
            <p className="filii_page__filtered-filii-not-found">
              <Empty description={<Text>Немає даних</Text>} />
            </p>
          )}
        </Row>
        <br />
        <Pagination style={{ textAlign: 'center' }} current={currentPage} pageSize={pageSize} total={total} onChange={handlePageChange} />
      </Spin>
    </div>
  );
};

export default Promocodes;
