import React from 'react';
import { Modal } from 'antd';

const ProcessHotlineModal = ({ clientsHotlinesAll, showHotlineModal, setShowHotileModal }) => {
  return (
    <>
      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        title="Записи клієнтів"
        open={showHotlineModal}
        onOk={() => setShowHotileModal(false)}
      >
        <p>Наявні неопрацьовані самостійні записи клієнтів ({clientsHotlinesAll})</p>
      </Modal>
    </>
  );
};
export default ProcessHotlineModal;
