import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button, Typography } from 'antd';
import ReactECharts from 'echarts-for-react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';

import { request } from '../../tools';
import Excel from '../../images/excel_logo.svg';

import './StatFilActiveContracts.scss';

const StatFilActiveContracts = () => {
  const [contractsData, setContractsData] = useState([]);
  const FILII = useSelector((state) => state.common.FILII);
  const { Text } = Typography;

  const fetchContractsData = async () => {
    request.post(
      '/statistic/filActiveContracts',
      null,
      ({ activeContractsWithAppAndVerified, activeContractsWithoutApp, disabledPeopleContracts, allContracts, ratingLawyers }) => {
        const sortedData = activeContractsWithAppAndVerified
          .map((item) => {
            const allCount = allContracts.find((i) => i.f === item.f)?.count || 0;

            const activeContractsWithAppAndVerifiedCount = item.count;
            const activeContractsWithoutAppCount = allCount - activeContractsWithAppAndVerifiedCount;
            const disabledPeopleContractsCount = disabledPeopleContracts.find((i) => i.f === item.f)?.count || 0;
            const ratingLawyersPeopleContractsCount = ratingLawyers.find((i) => i.f === item.f)?.count || 0;

            const activeContractsWithAppAndVerifiedPercent = ((100 * activeContractsWithAppAndVerifiedCount) / allCount).toFixed(1);
            const activeContractsWithoutAppPercent = ((100 * activeContractsWithoutAppCount) / allCount).toFixed(1);
            const disabledPeopleContractsPercent = ((100 * disabledPeopleContractsCount) / allCount).toFixed(1);
            const ratingLawyersPeopleContractsPercent = ((100 * ratingLawyersPeopleContractsCount) / allCount).toFixed(1);
            return {
              f: item.f,
              all: allCount,
              activeContractsWithAppAndVerified: activeContractsWithAppAndVerifiedCount,
              activeContractsWithoutApp: activeContractsWithoutAppCount,
              disabledPeopleContracts: disabledPeopleContractsCount,
              ratingLawyersPeopleContracts: ratingLawyersPeopleContractsCount,
              activeContractsWithAppAndVerifiedPercent,
              activeContractsWithoutAppPercent,
              ratingLawyersPeopleContractsPercent,
              disabledPeopleContractsPercent,
            };
          })
          .sort((a, b) => a.activeContractsWithAppAndVerifiedPercent - b.activeContractsWithAppAndVerifiedPercent);

        const allOfAll = allContracts.reduce((acc, item) => acc + item.count, 0);

        const allActiveContractsWithAppAndVerified = activeContractsWithAppAndVerified.reduce((acc, item) => acc + item.count, 0);
        const allActiveContractsWithoutApp = allOfAll - allActiveContractsWithAppAndVerified;
        const allDisabledPeopleContracts = disabledPeopleContracts.reduce((acc, item) => acc + item.count, 0);
        const allRatingLawyers = ratingLawyers.reduce((acc, item) => acc + item.count, 0);

        const activeContractsWithAppAndVerifiedPercent = ((100 * allActiveContractsWithAppAndVerified) / allOfAll).toFixed(1);
        const activeContractsWithoutAppPercent = ((100 * allActiveContractsWithoutApp) / allOfAll).toFixed(1);
        const disabledPeopleContractsPercent = ((100 * allDisabledPeopleContracts) / allOfAll).toFixed(1);
        const ratingLawyersPeopleContractsPercent = ((100 * allRatingLawyers) / allOfAll).toFixed(1);

        sortedData.push({
          f: 'Всі філії',
          all: allOfAll,
          activeContractsWithAppAndVerified: allActiveContractsWithAppAndVerified,
          activeContractsWithoutApp: allActiveContractsWithoutApp,
          disabledPeopleContracts: allDisabledPeopleContracts,
          ratingLawyersPeopleContracts: allRatingLawyers,
          activeContractsWithAppAndVerifiedPercent,
          activeContractsWithoutAppPercent,
          ratingLawyersPeopleContractsPercent,
          disabledPeopleContractsPercent,
        });

        setContractsData(sortedData);
      },
    );
  };

  useEffect(() => {
    fetchContractsData();
  }, []);

  const option = {
    title: {
      text: 'Активні договори',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const item = contractsData[params[0].dataIndex];
        const filName = FILII[item.f] || item.f;
        return `${filName} <br />Всього: ${item.all} шт
         <br />З додатком та верифіковані: ${item.activeContractsWithAppAndVerified} шт  - ${item.activeContractsWithAppAndVerifiedPercent}%
         <br />Без додатку: ${item.activeContractsWithoutApp} шт - ${item.activeContractsWithoutAppPercent}%
         <br />Не спроможні: ${item.disabledPeopleContracts} шт - ${item.disabledPeopleContractsPercent}%
         <br />Є-адвокат: ${item.ratingLawyersPeopleContracts} шт - ${item.ratingLawyersPeopleContractsPercent}%`;
      },
    },
    legend: {
      data: ['With App and Verified', 'With App', 'Without App'],
      bottom: 0,
    },
    grid: {
      left: 103, // Adjust to provide more space for y-axis labels
      right: 0,
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        formatter: '',
      },
    },
    yAxis: {
      type: 'category',
      data: contractsData.map((contract) => FILII[contract.f] || contract.f),
      axisLabel: {
        formatter: (value) => {
          const str = FILII[value] || value;
          const words = str.split(/[\s-]/);
          const maxLength = 10; // Set the maximum length of each line
          let currentLine = '';
          const lines = [];

          words.forEach((word) => {
            if ((currentLine + word).length > maxLength) {
              lines.push(currentLine.trim());
              currentLine = '';
            }
            currentLine += `${word} `;
          });

          if (currentLine) {
            lines.push(currentLine.trim());
          }

          return lines.join('\n');
        },
      },
    },
    series: [
      {
        name: 'With App and Verified',
        type: 'bar',
        stack: 'total',
        data: contractsData.map((contract) => contract.activeContractsWithAppAndVerifiedPercent),
        itemStyle: {
          color: 'rgba(75, 192, 192, 0.6)',
        },
        label: {
          show: true,
          position: 'inside',
          formatter: (params) => {
            const item = contractsData[params.dataIndex];
            return `${item.activeContractsWithAppAndVerified} шт`;
          },
          color: '#000',
        },
      },
      {
        name: 'Не спроможні',
        type: 'bar',
        stack: 'total',
        data: contractsData.map((contract) => contract.disabledPeopleContractsPercent),
        itemStyle: {
          color: 'rgba(54, 162, 235, 0.6)',
        },
        label: {
          show: true,
          position: 'inside',
          formatter: (params) => {
            const item = contractsData[params.dataIndex];
            return `${item.disabledPeopleContracts} шт`;
          },
          color: '#000',
        },
      },
      {
        name: 'Without App',
        type: 'bar',
        stack: 'total',
        data: contractsData.map((contract) => contract.activeContractsWithoutAppPercent - contract.disabledPeopleContractsPercent),
        itemStyle: {
          color: 'rgba(255, 99, 132, 0.6)',
        },
        label: {
          show: true,
          position: 'inside',
          formatter: (params) => {
            const item = contractsData[params.dataIndex];
            return `${item.activeContractsWithoutApp} шт`;
          },
          color: '#000',
        },
      },
    ],
  };

  const contractsDataCsv = contractsData.map((item) => [
    FILII[item.f] || item.f,
    item.all,
    item.activeContractsWithAppAndVerified,
    item.activeContractsWithoutApp,
    item.disabledPeopleContracts,
  ]);
  const csvData = [['Філія', 'Всього', 'З додатком та верифіковані', 'Без додатку', 'Не спроможні'], ...contractsDataCsv];

  return (
    <div>
      <Row gutter={8}>
        <Col span={24}>
          <Card>
            <ReactECharts option={option} style={{ height: 1800 }} />
          </Card>
        </Col>
      </Row>
      <div className="export">
        <CSVLink data={csvData} filename="statFilActiveContracts.csv">
          <Button className="exportBtn">
            <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Скачати в .CSV</Text>
          </Button>
        </CSVLink>
      </div>
    </div>
  );
};

export default StatFilActiveContracts;
