import React, { useState } from 'react';
import { Card, Col, Row, Spin, Button } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { CONFIRM_ADD_CONTRACT } from 'common_constants/modals';
import { getContractByIndex } from '../../../Helpers';
import { setModal, setCurrentClient } from '../../../../../store/commonReducer';
import EditSugar from '../../../../../Modals/AddSugar/EditSugar';
import { request, error } from '../../../../../tools';

import './WalletItem.scss';

const WalletItem = ({ item }) => {
  const dispatch = useDispatch();

  const assignmentId = item?.assignmentId;
  let assignment = null;
  if (assignmentId) {
    assignment = item.contract?.[0]?.assignments?.find((a) => a._id === assignmentId);
  }
  const client = item?.hotline?.length ? item?.hotline[0] : item?.contract[0]?.client[0];

  const [editSugarModalVisible, setEditSugarModalVisible] = useState(false);
  const [selectedEditSugarObject, setSelectedEditSugarObject] = useState(null);

  const [loading, setLoading] = useState(false);

  const showEditSugarModal = async () => {
    setLoading(true);
    await request.post(
      '/sugar/getSugarByIdOrIndex',
      { sugarId: item.sugarId },
      ({ sugar }) => {
        setSelectedEditSugarObject(sugar);
      },
      error,
    );

    setLoading(false);
    setEditSugarModalVisible(true);
  };

  const hideEditSugarModal = (e) => {
    e.stopPropagation();
    setEditSugarModalVisible(false);
    setSelectedEditSugarObject(null);
  };

  const handleGetContractByIndex = async (e) => {
    e.stopPropagation();
    setLoading(true);

    await getContractByIndex({
      contractId: item?.contractId,
      sugarId: item?.sugarId,
      dispatch,
      setCurrentClient,
      setModal,
      CONFIRM_ADD_CONTRACT,
    });

    setLoading(false);
  };
  return (
    <Spin spinning={loading}>
      <Card className="walletInner" style={item?.sugarId ? { cursor: 'pointer' } : { cursor: 'default' }} onClick={showEditSugarModal}>
        <Row className="item" justify={'space-between'} align={'middle'}>
          <Col className="fields-inner" flex={'auto'}>
            <Row className="fields" justify={'space-between'}>
              <Col className="fields-item">
                <Row className="fields-row">
                  <Row className="fields-row__info">{dayjs(item.date).format('DD.MM.YYYY')}</Row>
                </Row>
              </Col>
              <Col className="fields-item">
                <Row className="fields-row">
                  <Row className="fields-row__info">{item.amount}</Row>
                </Row>
              </Col>
              <Col className="fields-item">
                <Row className="fields-row">
                  <Row className="fields-row__info">
                    {!item?.contract?.length && !item?.hotline?.length && item?.clientName
                      ? `K ${item?.clientName}`
                      : item.contract?.[0]?.i
                      ? 'Д'
                      : item.hotline?.[0]?.i
                      ? 'К'
                      : 'Невідомо'}{' '}
                    {item.contract?.[0]?.i} {item.hotline?.[0]?.i}
                    {assignment && `В ${assignment.i}`}
                    &nbsp;{client?.n}
                  </Row>
                </Row>
              </Col>
              <Col className="fields-item">
                <Row className="fields-row">
                  <Row className="fields-row__info">
                    <Button onClick={handleGetContractByIndex} size="middle" type="primary" shape="default" icon={<InfoOutlined />} />
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <EditSugar disabledForBalance={true} open={editSugarModalVisible} onCancel={hideEditSugarModal} data={selectedEditSugarObject} />
    </Spin>
  );
};

export default React.memo(WalletItem);
