import ReactECharts from 'echarts-for-react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'common_constants/routes';

const PieChart = ({ data, height }) => {
  const { pathname } = useLocation();
  const all = data.reduce((acc, i) => acc + i.value, 0);

  const dynamicPage = {
    [ROUTES.FUNCTION_SURVEY]: {
      title: 'Функціонал',
      formatter: (node) => {
        const { name, value, marker } = node;
        const percents = ((value * 100) / all).toFixed(1);

        let tooltipMarkup = `${marker}${name}: <b>${percents}% (${value})</b>`;

        return tooltipMarkup;
      },
    },
    [ROUTES.STATISTIC_MEET_CANCELATION_REASONS]: {
      title: 'Причини',
      formatter: (node) => {
        const { name, value, data, marker } = node;
        const percents = ((value * 100) / all).toFixed(1);

        let tooltipMarkup = `${marker}${name}: <b>${value} - ${percents}%</b><br />Перенесено: <b>${data.moved} з ${value}</b>`;

        return tooltipMarkup;
      },
    },
  }[pathname] || { title: '', formatter: '' };

  const option = {
    title: {
      text: dynamicPage.title,
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: dynamicPage.formatter,
    },

    series: [
      {
        top: -120,
        bottom: -120,
        name: 'Access From',
        type: 'pie',
        radius: '50%',
        data,
        emphasis: {
          itemStyle: {
            offcet: 0,
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  return <ReactECharts option={option} notMerge={true} lazyUpdate={true} theme={'theme_name'} style={{ height }} />;
};

export default PieChart;
