import { Card, Row, Space, Col, Typography } from 'antd';
import { LOGICAL_STANDARD_CHOISE_4_OPTIONS } from 'common_constants/business';

const { Text } = Typography;

const InterimQualityAssessmentQuiestionnaire = ({ questionnaire }) => {
  return (
    <Card
      className="questionnaire-card"
      title={`№ ${questionnaire.i}`}
      bordered={true}
      style={{ margin: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
    >
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Клієнт:</Text>
          </Col>
          <Col span={12}>
            <Text>
              {questionnaire?.client?.n} {`+${questionnaire?.client?.countryPhCode}${questionnaire?.client?.ph}`}
            </Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Оцінка якості:</Text>
          </Col>
          <Col span={12}>
            <Text>{questionnaire.sq}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Задоволення комунікацією:</Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STANDARD_CHOISE_4_OPTIONS[questionnaire.sc]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Довіра компанії:</Text>
          </Col>
          <Col span={12}>
            <Text>{LOGICAL_STANDARD_CHOISE_4_OPTIONS[questionnaire.ts]}</Text>
          </Col>
        </Row>

        <hr style={{ border: '1px solid #f0f0f0' }} />

        <Row gutter={16}>
          <Col span={12}>
            <Text strong>Пропозиції та скарги:</Text>
          </Col>
          <Col span={12}>
            <Text>{questionnaire.pc}</Text>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default InterimQualityAssessmentQuiestionnaire;
