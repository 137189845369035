import clsx from 'clsx';

const Column = ({ children, className, style }) => (
  <div
    className={clsx('column', className)}
    style={{
      display: 'flex',
      flexDirection: 'column',
      ...style,
    }}
  >
    {children}
  </div>
);

export default Column;
