import React from 'react';
import { Card, Popconfirm, Popover, Row, Col, Typography } from 'antd';
import { EditOutlined, DeleteFilled, CloseSquareOutlined, DollarCircleOutlined, UserOutlined, CalendarOutlined } from '@ant-design/icons';
import HoverableUser from '../../components/HoverableUser/HoverableUser';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const { Text } = Typography;

const PromocodeItem = ({ item, deletePromocode, editPromocode }) => {
  const users = useSelector((state) => state.common.users);

  const onClickEditButtonHandle = () => {
    editPromocode(item._id);
  };

  const onClickDeleteButtonHandle = async () => {
    await deletePromocode(item._id);
  };

  return (
    <Card
      className="fil-item"
      actions={[
        <EditOutlined key="edit" onClick={onClickEditButtonHandle} style={{ color: 'orange' }} />,
        <Popconfirm
          title="Видалення промокоду"
          description="Ви дійсно хочете видалити даний промокод?"
          onConfirm={onClickDeleteButtonHandle}
          okText="Так"
          cancelText="Ні"
        >
          <DeleteFilled key="delete" style={{ color: 'red' }} />
        </Popconfirm>,
      ]}
      style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <Card.Meta title={`№${item.i} ${item.promocode}`} />
        </Col>
        {item.isDeleted && (
          <Col>
            <Text type="danger" style={{ display: 'flex', alignItems: 'center' }}>
              <CloseSquareOutlined style={{ color: 'red', marginRight: '4px' }} />
              Видалено
            </Text>
          </Col>
        )}
      </Row>

      <Row gutter={16} style={{ marginTop: '16px' }}>
        <Col span={12}>
          <Text strong>
            <DollarCircleOutlined style={{ marginRight: '8px' }} />
            Знижка: {item.discount}%
          </Text>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '16px' }}>
        <Col span={24}>
          <Text strong>
            <UserOutlined style={{ marginRight: '8px' }} />
            Автор: {''}
            <Popover content={<HoverableUser id={users[item.author]?._id ?? '-'} />} arrow trigger="hover">
              <Link>{users[item.author]?.p ?? '-'}</Link>
            </Popover>
          </Text>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '4px' }}>
        <Col span={24}>
          <Text strong>
            <CalendarOutlined style={{ marginRight: '8px' }} />
            Створено: {dayjs(item.createdAt).format('DD.MM.YYYY HH:mm:ss')}
          </Text>
        </Col>
      </Row>
    </Card>
  );
};

export default PromocodeItem;
