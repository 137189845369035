import { setContractsList, setBankCardsList } from '../../../store/commonReducer';
import notify from './notify';

const getAllContracts = (setLoading, filters, dispatch, setTotal) => {
  const {
    fil,
    block,
    number,
    phone,
    name,
    page,
    mPaymentFilter,
    onlyArchived,
    onlyWithVotes,
    searchByClient,
    isExistInsurance,
    tasksCountSort,
    dateOfSigningSort,
    onlyRedButton,
    onlyNotActualized,
    insuranceSort,
    withoutAnAssignedBlock,
    autoPayFilter,
    eAdvokat,
    noEAdvokat,
    clientSearched,
    monthlyPaymentsDebt,
    changeName,
    changeEmail,
    changePhone,
    changeNameSorting,
    changeEmailSorting,
    changePhoneSorting,
    searchByEAdvokatChanges,
  } = filters;

  const token = window.localStorage.getItem('accessToken');

  if (searchByClient && !phone && !name) {
    notify('Пусті поля пошуку договору за клієнтом!');
    return;
  }

  setLoading(true);

  fetch(process.env.REACT_APP_API + '/contracts/get', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
    body: JSON.stringify({
      f: fil,
      block: block,
      i: number,
      ph: phone,
      n: name,
      page,
      mp: mPaymentFilter,
      onlyArchived,
      onlyWithVotes,
      searchByClient,
      isExistInsurance,
      tasksCountSort: tasksCountSort,
      contractIndex: dateOfSigningSort,
      onlyRedButton,
      onlyNotActualized,
      insuranceSort,
      withoutAnAssignedBlock,
      autoPayFilter,
      eAdvokat,
      noEAdvokat,
      clientSearched,
      monthlyPaymentsDebt,
      changeName,
      changeEmail,
      changePhone,
      changeNameSorting,
      changeEmailSorting,
      changePhoneSorting,
      searchByEAdvokatChanges,
    }),
  })
    .then((res) => res.json())
    .then(({ data, client, total, cards }) => {
      if (searchByClient && !client) notify('Клієнта не знайдено');
      else if (searchByClient && !data.length) notify('У Клієнта не знайдено контрактів');

      dispatch(setContractsList(data));
      dispatch(setBankCardsList(cards));
      setTotal(total);
      setLoading(false);
    });
};

export default getAllContracts;
