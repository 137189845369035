import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, Card, Table } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

import { request } from '../../tools/index';
import { setPartnersList } from '../../store/commonReducer';

import PartnerAddModal from './PartnerAddModal';
import PartnerInfo from './PartnerInfo';

import './Partners.scss';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

const columns = [
  // Table.EXPAND_COLUMN,
  // {
  //   title: 'Ім`я',
  //   dataIndex: 'name',
  //   key: 'n',
  // },
  // {
  //   title: 'Місто',
  //   dataIndex: 'city',
  //   key: 'city',
  // },
  {
    title: 'Телефон',
    dataIndex: 'ph',
    key: 'ph',
  },
  {
    title: 'Баланс',
    dataIndex: 'partner_balance',
    key: 'partner_balance',
    render: (B) => B ?? 0,
  },
  {
    title: 'Запрошені',
    dataIndex: 'partner_invitedClients',
    key: 'partner_invitedClients',
    render: (inv) => inv?.length ?? 0,
  },
];

const Partners = (props) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.common.partners);

  const [phone, setPhone] = useState();
  const [filters, setFilters] = useState(true);
  const [loading, setLoading] = useState(true);

  const [addPartnerModalState, setAddPartnerModalState] = useState(false);

  const getAllPartners = () => {
    request.post('/partners/all', { ph: phone }, ({ data }) => {
      dispatch(setPartnersList(data));
      setLoading(false);
    });
  };

  useEffect(() => {
    getAllPartners();
  }, []);

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);

    setPhone(validPhoneNumber);
  };

  return (
    <div className="partners_page default_page">
      <PartnerAddModal isOpen={addPartnerModalState} setAddPartnerModalState={setAddPartnerModalState} />
      <h2>Партнери</h2>
      <div className="row align filters" onClick={() => setFilters(!filters)}>
        {filters ? <DownOutlined /> : <RightOutlined />} <h2>Пошук:</h2>
      </div>
      {/* {inspector ? (
        <div className="add-partner">
          <Button type="primary" onClick={setAddPartnerModalState}>
            Додати партнера
          </Button>
        </div>
      ) : null} */}
      {filters && (
        <Card className="filtersContainer">
          <div>
            <h4>Телефон (0501234567): </h4>
            <Input value={phone} placeholder="Телефон" onChange={(e) => handlePhoneNumberInput(e)} />
          </div>
          <Button type="primary" onClick={getAllPartners}>
            Пошук
          </Button>
        </Card>
      )}
      <Table
        dataSource={list}
        rowKey={(item) => item._id}
        loading={loading}
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (item) => <PartnerInfo data={item} />,
        }}
      />
    </div>
  );
};

export default Partners;
