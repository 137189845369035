import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMainLoader } from '../../store/uiReducer';
import { error, request, warn } from '../../tools';
import AccessItem from './AccessItem';
import { Button, Card, Form, Input, Table, Typography } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Box } from 'common_components';

const AccessList = () => {
  const dispatch = useDispatch();

  const [fingerprint, setFingerprint] = useState([] || 0);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState('UNPROCESSED');
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { Item } = Form;
  const { Text } = Typography;

  const FILTERS = [
    { label: 'Не опрацьовані', value: 'UNPROCESSED' },
    { label: 'Опрацьовані', value: 'PROCESSED' },
    { label: 'Всі', value: 'ALL' },
  ];

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const handleFilterChange = (selectedFilter, searchValue) => {
    setCurrentPage(1);
    setFilter(selectedFilter);
    setSearchValue(searchValue);
  };

  const getFingerprintAccess = async (filter, searchValue) => {
    dispatch(setMainLoader(true));

    const validation = (_currentPage) => {
      if (typeof _currentPage !== 'number') {
        warn('', 'Для отримання списку записів, потрібно вказати номер сторінки списку', { _currentPage: !!_currentPage });
        return;
      }

      return true;
    };

    if (!validation(currentPage)) return;

    const valUndefined = (val) => val === undefined || val === null;

    const params = {
      ...(valUndefined(currentPage) ? {} : { page: currentPage }),
      filter,
      p: searchValue,
    };

    await request.post(
      '/fingerprint/getFingerprintAccess',
      params,
      ({ fingerprintAccess, total, currentPage }) => {
        setFingerprint(fingerprintAccess);
        setCurrentPage(currentPage);
        setTotal(total);
      },
      error,
    );

    dispatch(setMainLoader(false));
  };

  useEffect(() => {
    getFingerprintAccess(filter, searchValue);
  }, [currentPage, filter, searchValue]);

  const columns = [
    {
      title: 'Список запитів на доступ в систему',
      dataIndex: 'item',
      key: 'accessId',
      render: (_, record) => <AccessItem refreshCallback={() => getFingerprintAccess(filter, searchValue)} key={record.accessId} item={record} />,
    },
  ];

  return (
    <div>
      <h2 style={{ paddingLeft: 0 }}>Доступи</h2>

      <div className="row align filters default_page" onClick={() => setFiltersOpen(!filtersOpen)}>
        {filtersOpen ? <DownOutlined /> : <RightOutlined />} <h2>Пошук</h2>
      </div>

      {filtersOpen && (
        <Card className="filtersContainer">
          <Form onFinish={handleFilterChange} layout="inline">
            <div style={{ width: '320px' }}>
              <Box>
                <Text>По користувачам: </Text> &nbsp;
                <Item name="p" children={<Input onChange={(e) => setSearchValue(e.target.value)} />} />
              </Box>
            </div>
          </Form>
        </Card>
      )}

      <br />

      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'center' }}>
        <ButtonGroup>
          {FILTERS.map((filterOption) => (
            <Button
              key={filterOption.value}
              onClick={() => handleFilterChange(filterOption.value, searchValue)}
              type={filter === filterOption.value ? 'primary' : 'default'}
            >
              {filterOption.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      <Table
        rowKey={(record) => record.accessId}
        columns={columns}
        dataSource={fingerprint.filter((item) => {
          if (filter === 'ALL') return true;
          return item.status === (filter === 'PROCESSED');
        })}
        pagination={{ total, current: currentPage, style: { justifyContent: 'center' } }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default AccessList;
