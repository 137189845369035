import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHAT_TYPES } from 'common_constants/business';

import { updateChat, removeChat } from '../store/commonReducer';

const useListenGroups = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.common.socket);

  useEffect(() => {
    if (!socket) return;

    socket.on('addedToGroup', ({ groupData }) => {
      dispatch(updateChat({ chatType: CHAT_TYPES.lawyerChat.key, data: { ...groupData, type: CHAT_TYPES.lawyerChat.key } }));
    });

    socket.on('deletedFromGroup', ({ chatId }) => {
      dispatch(removeChat({ chatType: CHAT_TYPES.lawyerChat.key, chatId: chatId }));
    });

    socket.on('changingGroupData', ({ groupData }) => {
      dispatch(updateChat({ chatType: CHAT_TYPES.lawyerChat.key, data: { ...groupData, type: CHAT_TYPES.lawyerChat.key } }));
    });

    return () => {
      socket?.off('addedToGroup');
      socket?.off('deletedFromGroup');
      socket?.off('changingGroupData');
    };
  }, [socket]);
};

export default useListenGroups;
