import React from 'react';
import { Select } from 'antd';

const billSelectGeneralOfficeOrRemote = [{ 0: 'Офіс', 1: 'Дист' }, ['Офіс/дист по договору', 'od_K']];

const SelectGeneralOfficeOrRemote = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть місце укладення"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі місця укладень</Option>
      <Option value="0">Офіс</Option>
      <Option value="1">Дист</Option>
    </Select>
  );
};

export { billSelectGeneralOfficeOrRemote, SelectGeneralOfficeOrRemote };
