import React, { useState, useEffect } from 'react';
import './WebsiteNews.scss';
import { Button, Spin, Table } from 'antd';
import { useSelector } from 'react-redux';
import { Row } from 'common_components';
import { request, error } from '../../tools';
import dayjs from 'dayjs';
import NewsAddForm from './NewsAddForm';

const WebsiteNews = () => {
  const mob = useSelector((state) => state.ui.mob);
  const users = useSelector((state) => state.common.users);
  const [loading, setLoading] = useState(false);
  const [websiteNews, setWebsiteNews] = useState([]);
  const [addNews, setAddNews] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(0);

  const getWebsiteNews = () => {
    request.post(
      '/websiteNews/get',
      {
        page: currentPage,
      },
      (res) => {
        setWebsiteNews(res.data);
        setTotal(res.total);
        setPageSize(res.limit);
        setLoading(false);
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  useEffect(() => {
    getWebsiteNews();
  }, [currentPage]);

  const columns = [
    ...(mob
      ? [
          {
            title: 'Інфо',
            key: 'mobX3',
            render: (news) => (
              <Row style={{ alignItems: 'center' }}>
                <div style={{ marginRight: 8 }}>
                  <span>{dayjs(news.date).format('DD.MM.YYYY')}</span>
                  <br />
                  <span>{users[news.author].name}</span>
                </div>
              </Row>
            ),
          },
        ]
      : [
          {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (date) => dayjs(date).format('DD.MM.YYYY'),
          },
          {
            title: 'Автор',
            dataIndex: 'author',
            key: 'author',
            render: (author) => users[author].name,
          },
        ]),

    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
      render: (title) => title,
    },
  ];

  return (
    <div className="website-news_page default_page">
      <h2>Новини сайту</h2>
      <Button onClick={() => setAddNews(!addNews)}>Додати новину</Button>
      <Spin spinning={loading} tip="Зачекайте" size="large">
        {addNews && <NewsAddForm setWebsiteNews={setWebsiteNews} websiteNews={websiteNews} setAddNews={setAddNews} />}
        <Table
          dataSource={websiteNews}
          columns={columns}
          rowKey={(item) => item._id}
          rowClassName={(record) => (record.published ? '' : 'published')}
          expandable={{
            expandRowByClick: true,
            expandIconColumnIndex: -1,
            expandedRowRender: (item) => (
              <NewsAddForm data={item} setLoading={setLoading} setWebsiteNews={setWebsiteNews} websiteNews={websiteNews} />
            ),
          }}
          pagination={{ total: total, current: currentPage, pageSize: pageSize }}
          onChange={handleTableChange}
        />
      </Spin>
    </div>
  );
};

export default WebsiteNews;
