import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'antd';
import { UpOutlined, DownOutlined, EditOutlined, FireOutlined, LogoutOutlined } from '@ant-design/icons';
import { orange, red, geekblue } from '@ant-design/colors';
import dayjs from 'dayjs';

import { LAW_BLOCKS, ROLES } from 'common_constants/business';
import { Ribbon } from 'common_components';
import UserAvatar from '../../../../components/UserAvatar';
import { error, request, success } from '../../../../tools';

import './UserItem.scss';

const UserItem = ({ user, onEdit, onFireClick, userAuthRole }) => {
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);
  const [isLogout, setIsLogout] = useState(false);
  const userAuth = useSelector((state) => state.common.userAuth);
  const adminOnly = ROLES[userAuth.role] === ROLES.admin;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleUserLogout = async (userId) => {
    setIsLogout(true);
    const route = '/auth/logout';

    const onSuccess = ({ status }) => {
      if (status) {
        success('Користувача успішно вилучено з облікового запису');
      }
    };

    const onError = ({ status }) => {
      if (!status) {
        error('Користувача не вдалось вилучити з облікового запису');
      }
    };

    request.post(route, { _id: userId }, onSuccess, onError);

    setIsLogout(false);
  };

  const renderUserInfo = () => (
    <Row className="userItem-item-fields" justify={'space-between'}>
      <Col className=" userItem-item-fields-left">
        <UserAvatar className="avatar" user={users?.[user._id]} />
      </Col>
      {isExpanded ? (
        <>
          <Col className="userItem-item-fields-middle">
            <div>{user.username}</div>
            <div>{user.role}</div>
            <div>{user.p}</div>
          </Col>
          <Col className="userItem-item-fields-right">
            <div>{user.name}</div>
            <div>{FILII[user.fil]}</div>
            <div>{LAW_BLOCKS[user.block] ?? '-'}</div>
          </Col>
        </>
      ) : (
        <>
          <Col className="userItem-item-fields-middle">
            <div>
              {user.p} - {user.name}
            </div>
          </Col>
          <Col className="userItem-item-fields-right">
            <div>{FILII[user.fil]}</div>
          </Col>
        </>
      )}
    </Row>
  );

  const roleList = {
    admin: ROLES.admin,
    topInspector: ROLES.topInspector,
    inspector: ROLES.inspector,
  };

  const userItemRoleLevel = roleList[user.role];

  return (
    <Ribbon condition={user?.fired} text={`Звільнений ${user?.dismissalDate ? dayjs(user?.dismissalDate).format('DD.MM.YYYY') : ''}`} color={'red'}>
      <Card
        className={`userItem-item-inner ${user.role}`}
        onClick={(e) => {
          if (userItemRoleLevel < roleList.inspector) {
            if (userAuthRole <= userItemRoleLevel) {
              setIsExpanded((prev) => !prev);
            } else {
              e.preventDefault();
            }
          } else {
            setIsExpanded((prev) => !prev);
          }
        }}
      >
        <Row className={`userItem-item ${isExpanded ? 'expanded' : ''}`} justify={'space-between'} align={'middle'}>
          <Col className="userItem-item-fields-inner" flex={'auto'}>
            {renderUserInfo()}
          </Col>
          <Row className="userItem-item-buttons-inner" style={user.fired ? { marginTop: '28px' } : ''}>
            <Col className="userItem-item-buttons">
              {userItemRoleLevel < userAuthRole ? null : (
                <Button className="userItem-item__button">{isExpanded ? <UpOutlined /> : <DownOutlined />}</Button>
              )}
            </Col>
          </Row>
        </Row>
        {isExpanded && (
          <React.Fragment>
            <Row className="userItem-item-bottom-buttons" justify={'space-between'}>
              <Button
                style={{ background: `${orange[5]}`, borderColor: `${orange[5]}` }}
                className="userItem-item-bottom__button"
                icon={<EditOutlined />}
                onClick={() => onEdit(user)}
                type={'primary'}
              >
                Редагувати
              </Button>
              <Button
                className="userItem-item-bottom__button"
                onClick={() => onFireClick(user)}
                style={{ background: `${red[5]}`, borderColor: `${red[5]}` }}
                icon={<FireOutlined />}
                type={'primary'}
              >
                Звільнити
              </Button>
            </Row>
            {adminOnly && (
              <div className="logout-button-section">
                <Button
                  className="userItem-item-bottom__button"
                  disabled={isLogout}
                  onClick={() => handleUserLogout(user._id)}
                  style={{ background: `${geekblue[5]}`, borderColor: `${geekblue[5]}`, width: 'auto' }}
                  icon={<LogoutOutlined />}
                  type={'primary'}
                >
                  {user._id === userAuth._id ? 'Вийти з облікового запису' : 'Вилучити користувача з облікового запису'}
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </Card>
    </Ribbon>
  );
};

export default React.memo(UserItem);
