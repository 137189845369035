import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, InputNumber, DatePicker, Radio, Button, Spin, Select } from 'antd';
import { PRAVO } from 'common_constants/business';
import { request, error, success } from '../../tools';
import { setModal, addFeeToList, editFee } from '../../store/commonReducer';
import SearchSelect from '../../components/SearchSelect';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import './styles.scss';

const AddFee = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentFee, setCurrentFee] = useState({});
  const userAuth = useSelector((state) => state.common.userAuth);
  const modalData = useSelector((state) => state.common.modal);

  useEffect(() => {
    setLoading(true);
    if (modalData.fee) {
      const fee = { ...modalData.fee, feeday: dayjs(modalData.fee.feeday), pravoType: PRAVO[modalData.fee.pravoType] || modalData.fee.pravoType };
      setCurrentFee(fee);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [modalData]);

  const onCancel = () => {
    dispatch(setModal());
  };

  const onFinish = (values) => {
    const data = {
      _id: currentFee._id,
      author: userAuth._id,
      fil: userAuth.fil,
      pravoType: PRAVO.indexOf(values.pravoType) || values.pravoType,
      ...values,
    };

    request.post(
      '/fee/add',
      data,
      (res) => {
        if (res.status) {
          form.resetFields();
          success('Успіх', 'Гонорар додано успішно!');

          if (currentFee._id) {
            dispatch(editFee({ ...data }));
          } else {
            dispatch(addFeeToList({ ...data, _id: res.feeId }));
          }

          dispatch(setModal());
        }
      },
      error,
    );
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      open
      className="addFee-modal"
      title={modalData.fee ? 'Редагування звіту «Гонорар»' : 'Створення звіту «Гонорар»'}
      onCancel={onCancel}
      footer={null}
    >
      <Spin spinning={loading}>
        {(currentFee._id || !modalData.fee) && (
          <Form layout="vertical" initialValues={currentFee} onFinish={onFinish}>
            <Form.Item label="Дата гонорару" name="feeday" rules={[{ required: true, message: 'Будь ласка введіть дату гонорару!' }]}>
              <DatePicker locale={locale} format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item
              label="Офісна чи дистанційна консультація"
              name="typeOfConsultation"
              rules={[{ required: true, message: 'Будь ласка, виберіть форму консультації!' }]}
            >
              <Radio.Group>
                <Radio.Button value="office">Офісна</Radio.Button>
                <Radio.Button value="online">Дистанційна</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Вид правовідносин" name="pravoType" rules={[{ required: true, message: 'Будь ласка, виберіть вид правовідносин' }]}>
              <SearchSelect list={PRAVO} />
            </Form.Item>
            <Form.Item name="pravoDescription" label="Пояснення виду правовідносин">
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} placeholder="Опис виду правовідносин своїми словами..." />
            </Form.Item>
            <Form.Item
              label="Назва компанії / ПІБ адвоката"
              name="lawyerName"
              rules={[{ required: true, message: 'Будь ласка, введіть назву компанії або ПІБ адвоката чи юриста' }]}
            >
              <Input placeholder="Симоненко Симен Сименович" />
            </Form.Item>
            <Form.Item
              label="Телефон адвоката чи юридичної компанії"
              name="lawyerTelephone"
              rules={[{ required: true, message: 'Будь ласка, введіть номер телефону адвоката чи юридичної компанії' }]}
            >
              <Input htmlType="phone" type="number" placeholder="+3802222222222" />
            </Form.Item>
            <Form.Item label="Сума гонорару" name="amount" rules={[{ required: true, message: 'Будь ласка, введіть суму гонорару!' }]}>
              <InputNumber onKeyDown={handleKeyPress} addonAfter="₴" min={0} size="large" step={100} />
            </Form.Item>
            <Form.Item label="Вихід адвоката" name="lawyerWithdrawal" rules={[{ required: true, message: 'Будь ласка, введіть вихід адвоката!' }]}>
              <Input placeholder="Введіть вихід адвоката..." />
            </Form.Item>
            <Form.Item label="Примітка" name="comment">
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} placeholder="Примітка..." />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Зберегти
            </Button>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default AddFee;
