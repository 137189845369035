import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const sphereApi = createApi({
  reducerPath: 'sphereApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['categories', 'service-items'],
  endpoints: (builder) => ({
    getSphereServiceCategories: builder.query({
      query: () => `/sphere/getCategories`,
      providesTags: ['categories'],
    }),
    createSphereServiceCategory: builder.mutation({
      query: (body) => ({
        url: `/sphere/createCategory`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['categories'],
    }),
    deleteSphereServiceCategory: builder.mutation({
      query: (id) => ({
        url: `/sphere/deleteCategory`,
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: ['categories'],
    }),
    updateSphereServiceCategory: builder.mutation({
      query: (body) => ({
        url: `/sphere/updateCategory`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['categories'],
    }),
    getSphereServiceItems: builder.query({
      query: (category) => `/sphere/getServiceItems/${category}`,
      providesTags: ['service-items'],
    }),
    createSphereServiceItem: builder.mutation({
      query: (body) => ({
        url: `/sphere/createServiceItem`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['service-items'],
    }),
    deleteSphereServiceItem: builder.mutation({
      query: (id) => ({
        url: `/sphere/deleteServiceItem`,
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: ['service-items'],
    }),
    updateSphereServiceItem: builder.mutation({
      query: (body) => ({
        url: `/sphere/updateServiceItem`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['service-items'],
    }),
  }),
});

export const {
  useGetSphereServiceCategoriesQuery,
  useCreateSphereServiceCategoryMutation,
  useDeleteSphereServiceCategoryMutation,
  useUpdateSphereServiceCategoryMutation,
  useGetSphereServiceItemsQuery,
  useCreateSphereServiceItemMutation,
  useDeleteSphereServiceItemMutation,
  useUpdateSphereServiceItemMutation,
} = sphereApi;
