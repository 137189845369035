import { Button, Collapse, DatePicker, Form, Input, InputNumber, Modal, Select, Spin, Switch, Table, TimePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ASSIGNMENTS_SERVICES_TYPE, ASSIGNMENT_SERVICE } from 'common_constants/business';
import { setAssignmentsList, setCourtsList, setJudgesList, setLawyersList, setModal, setPlacesList } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import { getAddAssignmentInfoColumns, updateAssignmentsListWithData, updateJudgesListWithData } from '../ModalsHelpers';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Panel } = Collapse;

const format = 'HH:mm';

const AddAssignment = () => {
  const dispatch = useDispatch();
  const lawyersList = useSelector((state) => state.common.lawyersList);
  const judgesList = useSelector((state) => state.common.judgesList);
  const placesList = useSelector((state) => state.common.placesList);
  const courtsList = useSelector((state) => state.common.courtsList);
  const assignmentsList = useSelector((state) => state.common.assignmentsList);
  const { data, prev } = useSelector((state) => state.common.modal);

  const [courtState, setCourtState] = useState(data ? data.court : null);
  const judgesWithCurrentCourtListInitState = judgesList.filter((judge) => judge.courts.includes(courtState));
  const judgesWithOutCurrentCourtListInitState = judgesList.filter((judge) => !judge.courts.includes(courtState));

  const [loading, setLoading] = useState(false);
  const [isNewLawyer, setIsNewLawyer] = useState(false);
  const [isNewPlace, setIsNewPlace] = useState(false);
  const [serviceType, setServiceType] = useState(data ? data.serviceType : ASSIGNMENT_SERVICE.GOING_TO_COURT);
  const [assignmentFil, setAssignmentFil] = useState(data ? String(data.f) : null);
  const [currentAssignments, setCurrentAssignments] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isNewJudge, setIsNewJudge] = useState(false);
  const [isNewCourt, setIsNewCourt] = useState(false);

  const [judgesWithCurrentCourtList, setJudgesWithCurrentCourtList] = useState(judgesWithCurrentCourtListInitState);
  const [judgesWithOutCurrentCourtList, setJudgesWithOutCurrentCourtList] = useState(judgesWithOutCurrentCourtListInitState);

  const columns = getAddAssignmentInfoColumns(courtsList);

  const timeoutRef = useRef(null);

  // remove mandatory field completion
  const makeFieldsOptional = (text) => {
    return serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT ? [{ required: true, message: text }] : [];
  };

  const handleInputChange = (contractIndex) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      const onSuccess = (res) => {
        setCurrentAssignments(res.assignments);
        setAssignmentFil(String(res.fil));
        setLoading(false);
      };

      const onError = (_, __, axiosError) => {
        error(axiosError.response.data.message, ' ');
        setAssignmentFil(null);
        setLoading(false);
      };

      setLoading(true);

      request.post('/contracts/fil/get', { contractIndex }, onSuccess, onError);
    }, 1000);
  };

  const handleCollapseChange = () => setIsExpanded(!isExpanded);

  const onCancel = () => {
    dispatch(setModal(prev));
  };

  const onChangeIsNewLawyer = (checked) => setIsNewLawyer(checked);
  const onChangeIsNewJudge = (checked) => setIsNewJudge(checked);
  const onChangeIsNewCourt = (checked) => setIsNewCourt(checked);
  const onChangeIsNewPlace = (checked) => setIsNewPlace(checked);

  const onFinish = (values) => {
    const {
      contractNumber,
      date,
      lawyerId,
      court,
      isNewCourt,
      newCourt,
      clientAmount,
      amountForLawyer,
      time,
      newLawyer,
      courtCase,
      mainComment,
      commentForClient,
      isNewJudge,
      judgeId,
      newJudge,
      place,
      isNewPlace,
      newPlace,
    } = values;
    const selectedTime = time.format(format);

    const assignmentData = {
      contractNumber,
      courtCase,
      serviceType,
      date,
      selectedTime,
      clientAmount,
      amountForLawyer,
      court: serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT && !isNewCourt ? court : undefined,
      newCourt: serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT && isNewCourt ? newCourt : undefined,
      courtName: !isNewCourt ? courtsList.find((courtItem) => courtItem.i === court)?.name : undefined,
      lawyerId: !isNewLawyer ? lawyerId : undefined,
      newLawyer: isNewLawyer ? newLawyer : undefined,
      mainComment: mainComment ?? undefined,
      commentForClient: commentForClient ?? undefined,
      judgeId: !isNewJudge ? judgeId : undefined,
      newJudge: isNewJudge ? newJudge : undefined,
      place: !isNewPlace ? place : undefined,
      newPlace: isNewPlace ? newPlace : undefined,
      placeName: !isNewPlace ? placesList.find((placeItem) => placeItem._id === place)?.placeName : undefined,
    };

    const onSuccess = (res) => {
      if (res.insertedLawyer) {
        dispatch(setLawyersList([...lawyersList, res.insertedLawyer]));
      }

      if (res.insertedAssignment) {
        res.insertedAssignment.f = assignmentFil;
        res.insertedAssignment.sugar = { amounts: [], indexes: [] };
        dispatch(setAssignmentsList([res.insertedAssignment, ...assignmentsList]));
      }

      if (res.insertedJudge) {
        dispatch(setJudgesList([...judgesList, res.insertedJudge]));
      }

      if (res.insertedPlace) {
        dispatch(setPlacesList([...placesList, res.insertedPlace]));
      }

      if (res.insertedCourt) {
        dispatch(setCourtsList([...courtsList, res.insertedCourt]));
      }

      const currentJudge = judgesList.find((judge) => judge._id === judgeId);
      if (currentJudge && !currentJudge.courts.includes(courtState)) {
        const updatedJudges = updateJudgesListWithData(judgesList, currentJudge, courtState);
        dispatch(setJudgesList(updatedJudges));
      }

      success('Вихід успішно додано.', ' ');
      onCancel();
    };

    const onError = (_, __, axiosData) => {
      error(' ', axiosData.response.data.error);
      setLoading(false);
    };

    setLoading(true);
    request.post('/assignment/create', assignmentData, onSuccess, onError);
  };

  const onUpdate = (values) => {
    const {
      contractNumber,
      date,
      lawyerId,
      court,
      isNewCourt,
      newCourt,
      clientAmount,
      amountForLawyer,
      time,
      newLawyer,
      courtCase,
      mainComment,
      commentForClient,
      isNewJudge,
      judgeId,
      newJudge,
      place,
      isNewPlace,
      newPlace,
    } = values;
    const selectedTime = time.format(format);

    const assignmentData = {
      _id: data._id,
      contractNumber,
      courtCase,
      serviceType,
      date,
      selectedTime,
      clientAmount,
      amountForLawyer,
      court: serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT && !isNewCourt ? court : undefined,
      newCourt: serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT && isNewCourt ? newCourt : undefined,
      lawyerId: !isNewLawyer ? lawyerId : undefined,
      newLawyer: isNewLawyer ? newLawyer : undefined,
      mainComment: mainComment ?? undefined,
      commentForClient: commentForClient ?? undefined,
      judgeId: !isNewJudge ? judgeId : undefined,
      newJudge: isNewJudge ? newJudge : undefined,
      place: !isNewPlace ? place : undefined,
      newPlace: isNewPlace ? newPlace : undefined,
      i: data.i,
      createdAt: data.createdAt,
      amountForClient: data.amountForClient,
      authorId: data.authorId,
      report: data.report ? data.report : undefined,
    };

    const onSuccess = (res) => {
      if (res.insertedLawyer) {
        dispatch(setLawyersList([...lawyersList, res.insertedLawyer]));
      }

      if (res.insertedJudge) {
        dispatch(setJudgesList([...judgesList, res.insertedJudge]));
      }

      if (res.insertedPlace) {
        dispatch(setPlacesList([...placesList, res.insertedPlace]));
      }

      if (res.updatedAssignment) {
        const updatedData = res.updatedAssignment;
        const updatedAssignments = updateAssignmentsListWithData(assignmentsList, res.updatedAssignment._id, updatedData);
        dispatch(setAssignmentsList(updatedAssignments));
      }

      if (res.insertedCourt) {
        dispatch(setCourtsList([...courtsList, res.insertedCourt]));
      }

      const currentJudge = judgesList.find((judge) => judge._id === judgeId);

      if (currentJudge && !currentJudge.courts.includes(courtState)) {
        const updatedJudges = updateJudgesListWithData(judgesList, currentJudge, courtState);
        dispatch(setJudgesList(updatedJudges));
      }

      success('Вихід успішно оновлено.', ' ');
      onCancel();
    };

    const onError = (_, __, axiosData) => {
      error(' ', axiosData.response.data.error);
      setLoading(false);
    };

    setLoading(true);
    request.post('/assignment/put', assignmentData, onSuccess, onError);
  };

  useEffect(() => {
    setIsNewCourt(courtsList.length === 0);
    setIsNewLawyer(lawyersList.length === 0);
    setIsNewJudge(judgesList.length === 0);
  }, [courtsList, lawyersList, judgesList]);

  useEffect(() => {
    const updatedJudgesWithCurrentCourtList = judgesList.filter((judge) => judge.courts.includes(courtState));
    const updatedJudgesWithOutCurrentCourtList = judgesList.filter((judge) => !judge.courts.includes(courtState));

    setJudgesWithCurrentCourtList(updatedJudgesWithCurrentCourtList);
    setJudgesWithOutCurrentCourtList(updatedJudgesWithOutCurrentCourtList);
  }, [courtState]);

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal open className="" title={data ? 'Редагування виходу' : 'Новий вихід'} onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={loading}>
        <Form {...layout} name="nest-messages" onFinish={data ? onUpdate : onFinish} style={{ maxWidth: 600, overflow: 'hidden' }}>
          <Form.Item
            initialValue={data ? data.contractNumber : null}
            name={'contractNumber'}
            label="Номер договору"
            rules={[{ required: true, message: 'Будь ласка, вкажіть номер договору.' }]}
          >
            <InputNumber onChange={handleInputChange} min={1} />
          </Form.Item>

          <Collapse style={{ marginBottom: '20px' }} in={isExpanded} onChange={handleCollapseChange}>
            <Panel header={'Виходи по договору'} key={'assignments'}>
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={currentAssignments}
                pagination={{
                  pageSize: 3,
                  style: { justifyContent: 'center' },
                }}
              />
            </Panel>
          </Collapse>

          <Form.Item
            name={'courtCase'}
            label="Судова справа"
            initialValue={data ? data.courtCase : undefined}
            rules={[{ required: false, message: 'Будь ласка, вкажіть номер договору.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item initialValue={data ? data.serviceType : serviceType} name={'serviceType'} label="Тип послуги">
            <Select onSelect={(v) => setServiceType(v)}>
              {Object.keys(ASSIGNMENTS_SERVICES_TYPE).map((key) => (
                <Select.Option key={key} value={key}>
                  {ASSIGNMENTS_SERVICES_TYPE[key]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            initialValue={data ? dayjs(data.date) : dayjs()}
            name={'date'}
            label="Дата"
            rules={[{ required: true, message: 'Будь ласка, вкажіть дату.' }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            initialValue={data ? dayjs().hour(data.selectedTime.split(':')[0]).minute(data.selectedTime.split(':')[1]) : dayjs()}
            name={'time'}
            label="Час"
            rules={[{ required: true, message: 'Будь ласка, вкажіть час.' }]}
          >
            <TimePicker format={format} />
          </Form.Item>

          {serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT && (
            <Form.Item name={'isNewCourt'} label="Новий суд" initialValue={isNewCourt}>
              <Switch checked={isNewCourt} onChange={onChangeIsNewCourt} />
            </Form.Item>
          )}

          {!isNewCourt && serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT && (
            <Form.Item
              name={'court'}
              label="Суд"
              initialValue={data ? data?.court : null}
              rules={[{ required: serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT, message: 'Будь ласка, оберіть суд.' }]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                disabled={serviceType !== ASSIGNMENT_SERVICE.GOING_TO_COURT}
                onSelect={(v) => setCourtState(v)}
                placeholder="Виберіть суд"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                sorter={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {courtsList.map((court) => (
                  <Select.Option key={court._id} value={court.i}>
                    {court.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {isNewCourt && serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT && (
            <Form.Item name={'newCourt'} label="Новий суд" rules={[{ required: true, message: 'Будь ласка, введіть дані суду.' }]}>
              <Input placeholder="Введіть дані суду" />
            </Form.Item>
          )}

          {serviceType === ASSIGNMENT_SERVICE.OTHER && (
            <Form.Item name={'isNewPlace'} label="Нове місце" initialValue={isNewPlace}>
              <Switch checked={isNewPlace} onChange={onChangeIsNewPlace} />
            </Form.Item>
          )}
          {!isNewPlace && serviceType === ASSIGNMENT_SERVICE.OTHER && (
            <Form.Item
              name={'place'}
              label="Місце"
              initialValue={data ? data.place : null}
              rules={[{ required: true, message: 'Будь ласка, оберіть місце.' }]}
            >
              <Select
                showSearch
                style={{
                  width: '100%',
                }}
                placeholder="Оберіть місце"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                sorter={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {placesList.map((place) => (
                  <Select.Option key={place._id} value={place._id}>
                    {place.placeName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {isNewPlace && serviceType === ASSIGNMENT_SERVICE.OTHER && (
            <Form.Item name={'newPlace'} label="Нове місце" rules={[{ required: true, message: 'Будь ласка, додайте місце.' }]}>
              <Input placeholder="Введіть місце" />
            </Form.Item>
          )}

          {serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT && (
            <Form.Item name={'isNewJudge'} label="Новий суддя" initialValue={isNewJudge}>
              <Switch checked={isNewJudge} onChange={onChangeIsNewJudge} />
            </Form.Item>
          )}

          {!isNewJudge && serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT && (
            <Form.Item
              name={'judgeId'}
              label="Суддя"
              initialValue={data ? data.judgeId : null}
              rules={[{ required: true, message: 'Будь ласка, оберіть суддю.' }]}
            >
              <Select
                showSearch
                style={{
                  width: '100%',
                }}
                placeholder="Оберіть суддю"
                optionFilterProp="children"
                filterOption={(input, option) => option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                sorter={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {judgesWithCurrentCourtList.length && !isNewCourt && (
                  <Select.OptGroup key={'0'} label={'З цим судом працюють'}>
                    {judgesWithCurrentCourtList?.map((judge) => (
                      <Select.Option key={judge._id} value={judge._id}>
                        {judge.name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                )}

                {judgesWithCurrentCourtList.length && !isNewCourt && (
                  <Select.OptGroup key={'1'} label={'Інші судді'}>
                    {judgesWithOutCurrentCourtList?.map((judge) => (
                      <Select.Option key={judge._id} value={judge._id}>
                        {judge.name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                )}

                {(!judgesWithCurrentCourtList.length || isNewCourt) &&
                  judgesList?.map((judge) => (
                    <Select.Option key={judge._id} value={judge._id}>
                      {judge.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          {isNewJudge && serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT && (
            <Form.Item name={'newJudge'} label="Новий суддя" rules={[{ required: true, message: 'Будь ласка, додайте суддю.' }]}>
              <Input placeholder="Введіть ПІБ судді" />
            </Form.Item>
          )}

          <Form.Item name={'isNewLawyer'} label="Новий адвокат" initialValue={isNewLawyer}>
            <Switch checked={isNewLawyer} onChange={onChangeIsNewLawyer} />
          </Form.Item>

          {!isNewLawyer && (
            <Form.Item
              name={'lawyerId'}
              label="Адвокат"
              initialValue={data ? data.lawyerId : null}
              rules={makeFieldsOptional('Будь ласка, оберіть адвоката.')}
            >
              <Select
                showSearch
                style={{
                  width: '100%',
                }}
                placeholder="Оберіть адвоката"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                sorter={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {lawyersList?.map((lawyer) => (
                  <Select.Option key={lawyer._id} value={lawyer._id}>
                    {lawyer.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {isNewLawyer && (
            <Form.Item name={'newLawyer'} label="Новий адвокат" rules={makeFieldsOptional('Будь ласка, додайте адвоката.')}>
              <Input placeholder="Введіть ПІБ адвоката" />
            </Form.Item>
          )}
          <Form.Item
            name={'clientAmount'}
            label="Сума клієнта"
            initialValue={data ? data.clientAmount : 0}
            rules={makeFieldsOptional('Будь ласка, вкажіть суму клієнта.')}
          >
            <InputNumber min={0} onKeyDown={handleKeyPress} />
          </Form.Item>
          <Form.Item
            name={'amountForLawyer'}
            label="Сума для адвоката"
            initialValue={data ? data.amountForLawyer : 0}
            rules={makeFieldsOptional('Будь ласка, вкажіть суму для адвоката.')}
          >
            <InputNumber min={0} onKeyDown={handleKeyPress} />
          </Form.Item>
          <Form.Item name={'mainComment'} initialValue={data ? data.mainComment : null} label="Коментар для своїх">
            <Input />
          </Form.Item>
          <Form.Item name={'commentForClient'} initialValue={data ? data.commentForClient : null} label="Коментар для клієнта">
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              {data ? 'Оновити' : 'Відправити'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddAssignment;
