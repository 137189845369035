import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, InputNumber, Input } from 'antd';
import { ROLES } from 'common_constants/business';

import SelectFil from '../../components/SearchSelect/SearchSelectFil';
import SelectAuthor from '../../components/SearchSelect/SearchSelectAuthor';
import SelectMembers from '../../components/SelectMembers';
import { setModal, setTasksFilter } from '../../store/commonReducer';

import './styles.scss';

const TasksFilter = () => {
  const dispatch = useDispatch();
  const tasksFilter = useSelector((state) => state.common.tasksFilter);
  const userAuth = useSelector((state) => state.common.userAuth);
  const [contractClientName, setContractClientName] = useState(tasksFilter.contractClientName);

  const isBlockManager = ROLES[userAuth.role] <= ROLES.blockManager;
  const isInspector = ROLES[userAuth.role] <= ROLES.inspector;

  const onChangeFilterFil = (val) => {
    dispatch(setTasksFilter({ ...tasksFilter, fil: val }));
  };

  const onChangeFilterAuthor = (val) => {
    dispatch(setTasksFilter({ ...tasksFilter, author: val }));
  };

  const onChangeFilterContractIndex = (val) => {
    dispatch(setTasksFilter({ ...tasksFilter, contractIndex: val }));
  };

  const onBlurFilterClientName = () => {
    dispatch(setTasksFilter({ ...tasksFilter, contractClientName }));
  };

  const onClickUserHandle = (values) => {
    dispatch(setTasksFilter({ ...tasksFilter, appointed: values }));
  };

  const onCancel = () => dispatch(setModal());

  return (
    <Modal open title="Фільтр завдань" className="tasks-filter" width={350} footer={null} mask={false} maskClosable={false} onCancel={onCancel}>
      {isInspector ? (
        <div className="row-wrapper">
          <p>Філія:</p>
          <SelectFil value={tasksFilter.fil} onChange={onChangeFilterFil} />
        </div>
      ) : null}
      <div className="row-wrapper">
        <p>Автор:</p>
        <SelectAuthor value={tasksFilter.author} onChange={onChangeFilterAuthor} />
      </div>
      <div className="row-wrapper">
        <p>Номер договору:</p>
        <InputNumber value={tasksFilter.contractIndex} onChange={onChangeFilterContractIndex} />
      </div>
      <div className="row-wrapper">
        <p>Ім'я клієнта по договору:</p>
        <Input value={contractClientName} onChange={(e) => setContractClientName(e.target.value)} onBlur={onBlurFilterClientName} />
      </div>
      {isBlockManager ? (
        <div className="">
          <p>Учасники:</p>
          <div className="users-wrapper">
            <SelectMembers selectedMembers={tasksFilter.appointed} setSelectedMembers={onClickUserHandle} />
          </div>
        </div>
      ) : null}
    </Modal>
  );
};

export default TasksFilter;
