import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputNumber, Button, Card } from 'antd';

import SearchSelect from '../../components/SearchSelect/SearchSelect';
import './Cabinets.scss';

const Cabinets = () => {
  const FILII = useSelector((state) => state.common.FILII);

  const [fil, setFil] = useState('');
  const [count, setCount] = useState();
  const [list, setList] = useState([]);

  // todo: use new request func
  const onSave = () => {
    const token = window.localStorage.getItem('accessToken');

    fetch(process.env.REACT_APP_API + '/cabinets/add', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
      body: JSON.stringify({ fil: String(fil), count }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        getAllCabinets();
      });
  };

  const getAllCabinets = () => {
    const token = window.localStorage.getItem('accessToken');

    fetch(process.env.REACT_APP_API + '/cabinets/all', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
      // body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => setList(data.result));
  };

  const filListForSelect = FILII.map((fil, index) => `(${index})${fil}`);

  useEffect(() => {
    getAllCabinets();
  }, []);

  return (
    <div className="cabinets_page default_page">
      <h2>Кабінети</h2>
      <Card className="change">
        <h3>Змінити кількість:</h3>
        <div className="cabinet-form">
          <SearchSelect className="fil" style={{ width: 200 }} list={filListForSelect} value={fil} onChange={(value) => setFil(value)} />
          <div className="quantity-input-group">
            <span>Кількість:</span>
            <InputNumber value={count} onChange={(v) => setCount(v)} />
          </div>
          <Button onClick={onSave}> Зберегти </Button>
        </div>
      </Card>

      <Card className="list">
        {list.map((i) => (
          <div key={i.fil} className="item">
            {FILII[i.fil]} - {i.count}
          </div>
        ))}
      </Card>
    </div>
  );
};

export default Cabinets;
