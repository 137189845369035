import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import ExportQuantityModal from '../../components/ExportQuantityModal';
import { request, warn, error, success } from '../../tools';

const ExportPaymentQuickChat = () => {
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(1);
  const [max, setMax] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    countTransactions();
  }, []);

  const countTransactions = () => {
    request.post(
      '/quickChat/countPaymentQuickChat',
      {},
      ({ count }) => {
        setEnd(count);
        setMax(count);
      },
      error,
    );
  };

  const onGenerate = () => {
    const validation = () => {
      if (!start || !end) {
        warn('', 'Для експорту таблиці, потрібно вказати діапазон кількості елементів', { '!!start': !!start, '!!end': !!end });
        return;
      }

      return true;
    };

    if (!validation()) return;

    setLoading(true);

    const transaction = {
      start,
      end,
    };

    request.post(
      '/spreadsheets/exportPaymentQuickChat',
      transaction,
      (res) => {
        success('', `Всього знайдено: ${res.length_data}`);
        setLoading(false);
        setData(res.data);
      },
      error,
    );
  };

  const csvData = [[`Ім'я`, 'Дата оплати', 'Кількість грн', 'Айді користувача']];

  data?.forEach((i) => {
    const date = dayjs(i.transactions.date)?.format('DD-MM-YYYY');

    csvData.push([i.n ?? '', date ?? '', i.transactions?.amount ?? '', i.transactions?.userId ?? '']);
  });

  return (
    <ExportQuantityModal
      title={'Експорт списань швидкого чату'}
      csvData={csvData}
      onGenerate={onGenerate}
      max={max}
      data={!!data}
      loading={loading}
      startA={start}
      setStartA={setStart}
      endA={end}
      setEndA={setEnd}
    />
  );
};

export default ExportPaymentQuickChat;
