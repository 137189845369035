import { useEffect, useMemo, useState } from 'react';
import { Button, Input, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ADD_HOTLINE_CALLS_REPORT } from 'common_constants/modals';
import { SyncOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';

import { error, request } from '../../tools';
import { setHotlineCalls, setModal } from '../../store/commonReducer';
import CurrentCall from './Partials/Calls/CurrentCall';

const INTERVALS = [1, 13, 25, 37, 49];
const DEFAULT_PAGE_SIZE = 5;

export default function ActiveCalls({ current, choose, removeCall, data }) {
  const dispatch = useDispatch();

  const mob = useSelector((state) => state.ui.mob);
  const hotlineCalls = useSelector((state) => state.common.hotlineCalls);
  const userFilIndex = useSelector((state) => state.common.userAuth.fil);
  const filii = useSelector((state) => state.common.filii);

  const [loading, setLoading] = useState(false);
  const [callsLoading, setCallsLoading] = useState(false);
  const [calls, setCalls] = useState([]);
  const [searchByPhone, setSearchByPhone] = useState('');

  const userFil = useMemo(() => filii.find((f) => f.i === Number(userFilIndex)), [userFilIndex, filii]);

  const handleAddHotlineCallsReport = (e, item) => {
    e.stopPropagation();
    if (data?.name) {
      if (item.client) {
        item.client = { ...item.client, n: `${data.name} ${data.surname ?? ''}` };
      } else {
        item.n = `${data.name} ${data.surname ?? ''}`;
      }
    }
    dispatch(setModal({ name: ADD_HOTLINE_CALLS_REPORT, data: { ...item, fil: userFilIndex } }));
  };

  const getActiveCalls = () => {
    setLoading(true);

    const reqBody = {};

    request.post(
      '/hotlineCalls/getActiveCalls',
      reqBody,
      ({ data, total }) => {
        dispatch(setHotlineCalls(data || []));
        setLoading(false);
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  const getCalls = () => {
    setCallsLoading(true);

    const reqBody = {
      date: [dayjs().toDate(), dayjs().add(1, 'day').toDate()],
      pageSize: 50,
      noStatus: true,
    };

    request.post(
      '/hotlineCalls/get',
      reqBody,
      ({ data }) => {
        setCalls(data);
        setCallsLoading(false);
      },
      () => {
        error();
        setCallsLoading(false);
      },
    );
  };

  useEffect(() => {
    getActiveCalls();
    getCalls();

    const interval = setInterval(() => {
      const currentTime = new Date().getSeconds();
      if (INTERVALS.includes(currentTime)) {
        getActiveCalls();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const filteredByFil = userFil?.hotlinePhones
    ? hotlineCalls?.filter((call) => !!userFil.hotlinePhones.find((ph) => call?.to?.slice(call.to.length - 9) === ph?.slice(ph.length - 9)))
    : [];

  const activeCalls = filteredByFil;

  const foundCalls = calls.filter((call) => ('380' + call.from).includes(searchByPhone));

  if (current && !activeCalls.find((elem) => current?._id === elem._id) && !calls.find((elem) => current?._id === elem._id)) {
    activeCalls.push(current);
  }

  const columns = [
    {
      title: "Ім'я",
      dataIndex: 'client',
      key: 'client',
      render: (client) => {
        return <>{client?.n ? client?.n : <span>Невідомо</span>}</>;
      },
    },
    {
      title: 'Номер телефону',
      key: 'from',
      render: ({ from, client }) => (
        <>
          <a href={`tel:0${from}`}>380{from}</a>
        </>
      ),
    },
    !mob && {
      title: 'Тривалість',
      dataIndex: 'total_seconds',
      key: 'total_seconds',
      align: 'center',
      render: (total_seconds) => <>{total_seconds ?? '-'}</>,
    },
    !mob && {
      title: 'Час очікування',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (start_time) => <>{dayjs(start_time).format('HH:mm') ?? '-'}</>,
    },
  ].filter((item) => item);

  const RefreshBtn = () => (
    <Button type="primary" onClick={getActiveCalls}>
      <SyncOutlined />
    </Button>
  );

  return (
    <div style={{ marginBottom: 15 }}>
      <h3>Непрозвітовані за день</h3>
      <Input value={searchByPhone} onChange={(e) => setSearchByPhone(e.target.value)} placeholder="380501234567" />
      <Table
        dataSource={foundCalls}
        columns={columns}
        rowKey={(item) => item._id}
        onRow={(record) => ({
          onClick: () => {
            choose(record);
          },
        })}
        loading={callsLoading}
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
          total: foundCalls.length,
          responsive: true,
        }}
        className="active_calls"
      />
      <h3>
        Активні дзвінки <RefreshBtn />
      </h3>
      <Table
        dataSource={activeCalls}
        columns={columns}
        rowKey={(item) => item.callId}
        onRow={(record) => ({
          onClick: () => {
            choose(record);
          },
        })}
        loading={loading}
        pagination={false}
        className="active_calls"
      />
      <hr style={{ border: '2px solid #1777ff' }} />
      <CurrentCall current={current} handleAddHotlineCallsReport={handleAddHotlineCallsReport} mob={mob} remove={removeCall} />
    </div>
  );
}
