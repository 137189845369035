import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { LAW_BLOCKS, PRAVO, REPEATS } from 'common_constants/business';

import { request, warn, error, success } from '../../tools';

import ExportQuantityModal from '../../components/ExportQuantityModal';

const ExportContracts = () => {
  const users = useSelector((state) => state.common.users);
  const contractArchiveMaxIndex = useSelector((state) => state.common.contractArchiveMaxIndex);
  const FILII = useSelector((state) => state.common.FILII);

  const max = contractArchiveMaxIndex;
  const [startA, setStartA] = useState(1);
  const [endA, setEndA] = useState(max);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const onGenerate = () => {
    const validation = () => {
      if (!startA || !endA) {
        warn('', 'Для експорту таблиці, потрібно вказати діапазон кількості елементів', { '!!start': !!startA, '!!end': !!endA });

        return;
      }

      return true;
    };

    if (!validation()) return;

    setLoading(true);

    const transaction = {
      startA,
      endA,
      ad: { $ne: null },
      sort: { ai: 1 },
    };

    request.post(
      '/spreadsheets/exportAllContract',
      transaction,
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };

  const csvData = [
    [
      'Номерація',
      '№ Договору', //
      'ID ПІБ Клієнта',
      'Архів',
      'П',
      'дата архівування',
      '№ Архіву',
      'Філія',
      'Куратор',
      'Дата укладання',
      'Блок',
      'ПІБ',
      'Телефон',
      'Що саме',
      'Предмет договору',
    ],
  ];

  data?.forEach((i) => {
    const client = i.client?.[0] ?? {};
    // timecode - ready to use
    // const archiveCode = dayjs(i.ad)?.diff(dayjs('01-01-1900'), 'day') + 2;
    const date = dayjs(i.ad)?.format('DD-MM-YYYY');

    csvData.push([
      i.ai,
      i.i ?? '', // * '№ Договору'
      `${client?.n ?? '<без імені>'} ${FILII[i.f] ?? ''} ${REPEATS[i.tm] ?? ''} ${i.od === 1 ? 'Дист' : 'Офіс'} ${i.ov1 ?? ''} ${i.ov2 ?? ''} ${
        i.ov3 ?? ''
      }`,
      'a',
      'п/с',
      date ?? '-',
      'a/' + date + '/' + i.i,
      FILII[i.f] ?? '',
      users[i.us]?.p ?? '',
      dayjs(i.d)?.format('DD-MM-YYYY') ?? '-',
      LAW_BLOCKS[i.b] ?? '',
      client?.n ?? '-',
      client?.ph ?? '-',
      PRAVO[i.prc] ?? '',
      i.ci?.replace(/"/g, "'") ?? '',
    ]);
  });

  return (
    <ExportQuantityModal
      title={'Експорт архівних контрактів (за нумерацією в архіві)'}
      csvData={csvData}
      onGenerate={onGenerate}
      max={max}
      data={!!data}
      loading={loading}
      startA={startA}
      setStartA={setStartA}
      endA={endA}
      setEndA={setEndA}
    />
  );
};

export default ExportContracts;
