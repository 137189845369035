import ReactECharts from 'echarts-for-react';

const BarChart = ({ data }) => {
  const option = {
    title: {
      text: 'Заповнено договорів',
      left: 'center',
      padding: [40, 0, 0, 0],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const item = data[params[0].dataIndex];
        return `${item.fil}<br />Заповнено: ${item.filledContractsPercent}% (${item.filledContracts} шт.)<br />Не заповнено: ${item.contractsToFilPercent}% (${item.contractsToFil} шт.)`;
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: data.map((i) => i.fil),
    },
    series: [
      {
        name: 'Заповнено',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'none',
        },
        data: data.map((i) => i.filledContractsPercent),
      },
      {
        name: 'Не заповнено',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'none',
        },
        data: data.map((i) => i.contractsToFilPercent),
      },
    ],
  };

  return <ReactECharts option={option} notMerge={true} lazyUpdate={true} style={{ height: '1500px' }} theme={'theme_name'} />;
};

export default BarChart;
