import { request } from '../../../tools';

const getCourts = async (handleSetCourtsList, handleSetMainLoader) => {
  return new Promise((resolve, reject) => {
    request.post(
      '/court/get',
      {},
      (res) => {
        handleSetCourtsList(res.courts);
        resolve();
      },
      (error) => {
        handleSetMainLoader(false);
        reject(error);
      },
    );
  });
};

export default getCourts;
