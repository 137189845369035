import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, Spin, Modal } from 'antd';
import { CSVLink } from 'react-csv';

import { setModal } from '../../store/commonReducer';
import { request, error } from '../../tools';
import Excel from '../../images/excel_logo.svg';

const ExportRatingLawyers = () => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const csvData = [['Юрист', 'Філія', 'Кл-ть активних договорів', 'Кліенти єАдвокат']];

  const onGenerate = () => {
    setLoading(true);
    request.post(
      '/spreadsheets/exportRatingLawyers',
      {},
      (req) => {
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };

  if (data) {
    Object.keys(users).forEach((crm_user) => {
      if (users[crm_user].fired) return;
      const allActivContracts = [];
      const contractsWithClientsEAdvokat = [];

      data?.forEach((contract) => {
        if (contract.us === crm_user) {
          allActivContracts.push(contract);
          if (contract.client[0]?.deviceTokens?.some((tokenObject) => tokenObject.token)) {
            contractsWithClientsEAdvokat.push(contract);
          }
        }
      });
      if (allActivContracts.length > 0)
        csvData.push([
          users[crm_user].p,
          FILII[users[crm_user].fil],
          allActivContracts.length,
          contractsWithClientsEAdvokat.length,
          // allActivContracts?.length > 0 ? (contractsWithClientsEAdvokat.length * 100) / allActivContracts.length : 0,
        ]);
    });
  }

  return (
    <Modal open className="export-hotline" title="Відсоток єАдвокат" onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="change">
          <br />
          {data ? (
            <CSVLink asyncOnClick={true} data={csvData}>
              <Button className="excel">
                <img className="excel-img" src={Excel} alt="Excel" /> Скачати .csv
              </Button>
            </CSVLink>
          ) : (
            <Button onClick={onGenerate}>Генерувати</Button>
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

export default ExportRatingLawyers;
