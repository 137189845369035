import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card } from 'antd';

import { ROLES } from 'common_constants/business/';

import PartnerInvitationsManagement from './PartnerInvitationsManagement';
import PartnerBalanceManagement from './PartnerBalanceManagement';

import './Partners.scss';

const PartnerInfo = ({ data }) => {
  const { _id, partner_transactions = [], partner_payments, partner_balance, n: name, ph: phone, p: password } = data;
  const { role } = useSelector((state) => state.common.userAuth);

  const requestPaymentListInitState = partner_transactions?.filter((transction) => transction.requestPayment);

  const inspector = ROLES[role] <= ROLES.inspector;
  const [paymentBalance, setPaymentBalance] = useState(partner_payments ?? 0);
  const [invitationsManagementState, setInvitationsManagementState] = useState(false);
  const [balanceManagementState, setBalanceManagementState] = useState(false);
  const [requestPaymentList, setRequestPaymentList] = useState(requestPaymentListInitState);

  if (!data) return 'Відсутня інформація';

  return (
    <Card className="partnerInfo">
      <span>
        <b>ПІБ:</b> {name}
      </span>
      <span>
        <b>Телефон:</b> {phone}
      </span>
      <span>
        <b>Пароль:</b> {password}
      </span>
      <span>
        <b>Баланс:</b> {partner_balance ?? 0}
      </span>
      <span>
        <b>Баланс на виплату:</b> {paymentBalance}
      </span>

      {inspector ? (
        <div className="partnerInfo-buttons">
          <Button className="partnerInfo__button" type="primary" onClick={setInvitationsManagementState}>
            Запрошення
          </Button>
          <Button className="partnerInfo__button" type="primary" onClick={setBalanceManagementState}>
            Баланс
          </Button>
        </div>
      ) : null}
      <PartnerInvitationsManagement
        partnerId={_id}
        invitationsManagementState={invitationsManagementState}
        setInvitationsManagementState={setInvitationsManagementState}
        bonusBalance={data.partner_balance}
      />
      <PartnerBalanceManagement
        partnerId={_id}
        paymentCard={data.paymentCard}
        balanceManagementState={balanceManagementState}
        setBalanceManagementState={setBalanceManagementState}
        bonusBalance={data.partner_balance ?? 0}
        paymentBalance={paymentBalance}
        setPaymentBalance={setPaymentBalance}
        requestPaymentList={requestPaymentList}
        setRequestPaymentList={setRequestPaymentList}
      />
    </Card>
  );
};

export default PartnerInfo;
