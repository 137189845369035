import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Pagination, Row, Spin } from 'antd';
import { ADD_BLOCK } from 'common_constants/modals';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClientsList, setModal } from '../../store/commonReducer';
import { request } from '../../tools';
import './Blocked.scss';
import Item from './Item';

const DEFAULT_PAGE_SIZE = 10;

const Blocked = () => {
  const dispatch = useDispatch();
  const blockedList = useSelector((state) => state.common.clients);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);

  const limits = {
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(true);

    request.post('/block/blockedGet', limits, ({ blockedUsers, totalPages }) => {
      dispatch(setClientsList(blockedUsers));
      setTotalPages(totalPages);
      setLoading(false);
    });
  }, [currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <section className="clients_page default_page">
      <Spin tip="Завантаження" spinning={loading} size="large">
        <h1>Заблоковані користувачі</h1>
        <Row justify="center">
          <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_BLOCK, data: {} }))}>
            <PlusCircleOutlined /> Заблокувати
          </Button>
        </Row>

        {blockedList && blockedList.length !== 0 ? (
          <>
            <div className="list-page default-page">
              {blockedList.map((item) => (
                <Item key={item._id} data={item} />
              ))}
            </div>

            <Pagination
              style={{ textAlign: 'center' }}
              current={currentPage}
              pageSize={pageSize}
              total={totalPages * pageSize}
              onChange={handlePageChange}
              onShowSizeChange={handlePageChange}
            />
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </section>
  );
};

export default Blocked;
