import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Spin, Popconfirm, Select, Space, Input, Checkbox, Collapse } from 'antd';
import { setModal } from '../../store/commonReducer';
import { MONTHS_SMALL } from 'common_constants/business';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';

import './styles.scss';
import { request, warn, error, info, success } from '../../tools';
import Message from '../../components/Chat/Message';

const { Panel } = Collapse;

const ChatWithAccountant = () => {
  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState([]);
  const data = useSelector((state) => state.common.modal.data);
  const bankCards = useSelector((state) => state.common.bankCards);
  const [AI, setAI] = useState(true);
  const [message, setMessage] = useState();
  const [sugar, setSugar] = useState();
  const [remainingMoney, setRemainingMoney] = useState(0);
  const [chat, setChat] = useState([]);

  const [loading, setLoading] = useState(false);

  const [dataCardState, setDataCardState] = useState(() => {
    const found = bankCards.find((card) => card.f === data.f)?.i;

    if (found) return [found];
    return [];
  });

  const onCancel = () => {
    dispatch(setModal());
  };

  const onConfirmPopconfirm = async () => {
    const validation = () => {
      if (!message.length) {
        warn('', 'Введіть повідомлення');
        return false;
      }

      return true;
    };

    if (!validation()) return;

    setLoading(true);

    await request.post(
      '/contracts/sendDebtMessage',
      {
        C: data.C,
        message,
        _id: data._id,
        AI,
        i: data.i,
        bankCards: dataCardState,
      },
      () => {
        success();
        dispatch(setModal());
      },
      error,
    );

    setLoading(false);
  };

  const countRemainingMoney = () => {
    setLoading(true);

    const reqData = {
      contractI: data?.i,
    };

    request.post(
      '/contracts/paymentManagementRemainingMoneyCrm',
      reqData,
      ({ data }) => {
        setRemainingMoney(data?.remainingMoney);
        setLoading(false);
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  useLayoutEffect(() => {
    let date = dayjs();
    const creation = dayjs(data.sd);

    if (date.date() > creation.date()) {
      date = date.add(1, 'month');
    }
    const daysInMonth = date.daysInMonth();
    date = date.set('date', Math.min(daysInMonth, creation.date()));

    const cards = dataCardState.map((card) => bankCards.find((c) => c.i === card)?.m).join('\n\n');
    const m = `Ви маєте здійснити оплату наших послуг у сумі ${-remainingMoney} грн. до ${date.date()}-го ${
      MONTHS_SMALL[date.month()]
    }.\n\nАктуальні реквізити для оплати:\n${cards}\n\nПісля оплати, просимо надіслати квитанцію Вашому юристу`;
    setMessage(m);
  }, [data, bankCards, dataCardState, remainingMoney]);

  const getSugarForClient = () => {
    setLoading(true);

    const params = { contractIndex: data?.i, md: data?.md };

    request.post(
      '/sugar/getContractSugar',
      params,
      ({ sugar }) => {
        setSugar(sugar);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    getSugarForClient();
    request.post('/accountantChat/getForCRM', { contractId: data._id }, ({ data }) => {
      setChat(data.chat);
    });
  }, []);

  useEffect(() => {
    countRemainingMoney();
  }, [sugar, data]);

  const cardArr =
    bankCards
      ?.map((i) => (i = { value: i.i, label: i.n }))
      ?.slice()
      ?.sort((a, b) => {
        const [labelA, labelB] = [a.n?.toLowerCase(), b.n?.toLowerCase()];
        return labelA?.localeCompare(labelB);
      }) || [];

  return (
    <Modal open className="monthly-payment-contract" title={'Жнивування в єАдвокаті + Viber'} onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={loading}>
        <Space direction="vertical" className="space">
          <Collapse activeKey={activeKey} accordion onChange={(key) => setActiveKey((prevKey) => (prevKey.includes(key) ? [] : [key]))}>
            <Panel header="Історія чату" key="1">
              <div className="account_chat_history" style={{ display: 'flex', flexDirection: 'column' }}>
                {chat.map((elem) => (
                  <Message item={{ ...elem, isSentByCurrentUser: !elem?.fromUser }} key={elem._id} />
                ))}
              </div>
            </Panel>
          </Collapse>
          <div className="list-row">
            Реквізити юриста:
            <br />
            <Select
              optionFilterProp="label"
              mode="multiple"
              allowClear
              className="select-input"
              options={cardArr}
              value={dataCardState}
              placeholder="Оберіть реквізити: "
              onChange={(v) => setDataCardState(v)}
            />
          </div>
          <div>
            <br />
            <span>Згенероване повідомлення:</span>
            <Input.TextArea
              autoSize={{
                minRows: 6,
              }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <Checkbox checked={AI} onChange={(e) => setAI(e.target.checked)} defaultChecked>
            Обробка через AI
          </Checkbox>
        </Space>
        <div className="group-button">
          <Popconfirm
            title="Підтвердження"
            description="Ви впевнені, що хочете надіслати повідомлення?"
            onConfirm={() => onConfirmPopconfirm()}
            okText="Гаразд"
            cancelText="Відміна"
            disabled={!dataCardState.length}
          >
            <Button disabled={!dataCardState.length} type="primary" className="monthly-payment-btn">
              Надіслати
            </Button>
          </Popconfirm>
          <Button danger type="primary" className="monthly-payment-btn" onClick={onCancel}>
            Закрити
          </Button>
        </div>
        <br />
      </Spin>
    </Modal>
  );
};

export default ChatWithAccountant;
