import { Form, Button } from 'antd';

const PartnerAddSubmitBtn = () => {
  return (
    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Button className="invite-submit-btn" type="primary" htmlType="submit">
        Створити
      </Button>
    </Form.Item>
  );
};

export default PartnerAddSubmitBtn;
