import React, { useState } from 'react';

import { Card, Typography, Spin, Space, Badge } from 'antd';
import { gray } from '@ant-design/colors';

import LibraryAvatarFile from '../../components/LibraryItem/Core/LibraryAvatarFile';
import LibraryInfoFile from '../../components/LibraryItem/Core/LibraryInfoFile';
import LibraryDownloadFile from '../../components/LibraryItem/Core/LibraryDownloadFile';

import './styles.scss';

const LibraryCardItem = (data) => {
  const [{ Ribbon }, { Text }] = [Badge, Typography];

  const item = data.data;

  const core_megaState = {
      loading: false, //* Завантаження
    },
    [megaState, setMegaState] = useState(core_megaState);

  return (
    <Ribbon text={item.o ? "Необов'язковий" : "Обов'язковий"} color={gray[item.o ? 3 : 7]}>
      <Card size="small" className="library-card-item" key={item._id}>
        <Spin tip="Зачекайте, йде завантаження файлів" spinning={megaState.loading} size="large">
          <Space className="body">
            <Space className="text">
              <LibraryAvatarFile item={{ 0: item.f, 1: 'web' }[item.z]} /> <Text>{item.n ?? '-'}</Text>
            </Space>
            <Space>
              &nbsp;
              <LibraryInfoFile item={item} />
              <LibraryDownloadFile item={item} loadingDownload={(check) => setMegaState({ ...megaState, loading: check })} /> &nbsp;
            </Space>
          </Space>
        </Spin>
      </Card>
    </Ribbon>
  );
};

export default LibraryCardItem;
