import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Table } from 'antd';
import { ExcelBtn, Row } from 'common_components';
import { ROLES } from 'common_constants/business/index';
import { ADD_FEE } from 'common_constants/modals';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';

import UserAvatar from '../../components/UserAvatar';
import { setFeeList, setModal } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { error, request } from '../../tools';
import FeeExplorationInfo from './FeeExplorationInfo';

import './FeeExploration.scss';

const csvHeaders = [
  '№',
  'Дата внесення',
  'Хто вніс',
  'Дата гонорару',
  'Філія внесача',
  'Вид консультації',
  'Вид правовідносин',
  'Пояснення виду правовідносин',
  'Назва компанії або ПІБ адвоката чи юриста',
  'Телефон адвоката, юриста чи юридичної компанії',
  'Сума гонорару',
  'Вихід адвоката',
  'Примітка',
];

const FeeExploration = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);
  const feeList = useSelector((state) => state.common.feeList);
  const FILII = useSelector((state) => state.common.FILII);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportData, setExportData] = useState(null);
  const [exportRange, setExportRange] = useState(null);

  const admin = ROLES[userAuth.role] === ROLES.admin;

  const columns = users && [
    {
      title: 'Інфо',
      key: 'mobX3',
      render: (fee) => (
        <Row style={{ alignItems: 'center' }}>
          <div style={{ textAlign: 'center', marginRight: 8 }}>
            <UserAvatar user={users?.[fee.author]} />
            <br />
            <span style={{ textTransform: 'uppercase' }}>{users?.[fee.author]?.p}</span>
          </div>
          <div>
            {dayjs(fee.feeday).format('DD.MM.YYYY')} <br /> {FILII[fee.fil] || '-'} <br /> {/* {LAW_BLOCKS[harvest.contractBlock?.[0]] || '-'} */}
          </div>
        </Row>
      ),
    },

    {
      title: 'Компанія/адвокат/юрист',
      dataIndex: 'lawyerName',
      key: 'lawyerName',
      render: (lawyerName) => lawyerName,
    },
  ];

  const loadExport = () => {
    dispatch(setMainLoader(true));

    request.post('/fee/export', { exportRange }, ({ data }) => {
      setExportData(data);
      dispatch(setMainLoader(false));
    });
  };

  const getAllFees = () => {
    dispatch(setMainLoader(true));

    request.post(
      '/fee/get',
      { page: currentPage },
      (result) => {
        if (result.status) {
          dispatch(setFeeList(result.data));
          setTotal(result.total);
        }
        dispatch(setMainLoader(false));
      },
      error,
    );
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  useEffect(() => {
    getAllFees();
  }, [currentPage]);

  const formatedExportData = exportData?.map((item) => [
    item.i,
    item.createdAt && dayjs(item.createdAt).format('DD.MM.YYYY HH:mm'),
    users[item.author].p,
    dayjs(item.payday).format('DD.MM.YYYY'),
    FILII[item.fil],
    item.typeOfConsultation,
    item.pravoType,
    item.pravoDescription,
    item.lawyerName,
    item.lawyerTelephone,
    item.amount,
    item.lawyerWithdrawal,
    item.comment?.replace(/"/g, "'").replace(/\n/g, '') ?? '',
  ]);
  formatedExportData?.unshift(csvHeaders);

  return (
    <section className="clients_page default_page fee-exploration-page">
      <h2>Розвідка гонорару</h2>
      <Row style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_FEE }))}>
          <PlusCircleOutlined /> Звітувати
        </Button>
      </Row>
      <br />
      {admin && (
        <Row style={{ justifyContent: 'center' }}>
          {exportData ? (
            <CSVLink asyncOnClick={true} data={exportData && formatedExportData}>
              <ExcelBtn style={{ width: 'auto' }}>Скачати файл</ExcelBtn>
            </CSVLink>
          ) : (
            <>
              <DatePicker.RangePicker style={{ marginRight: 16 }} onChange={(e) => setExportRange(e)} format="DD.MM.YYYY" />
              <ExcelBtn style={{ width: 'auto' }} onClick={loadExport}>
                Export
              </ExcelBtn>
            </>
          )}
        </Row>
      )}

      <Table
        style={{ marginTop: '50px' }}
        rowClassName={(fee) => (fee.removed ? 'fee-item removed' : 'fee-item')}
        dataSource={feeList}
        columns={columns}
        rowKey={(item) => item._id}
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          expandedRowRender: (item) => <FeeExplorationInfo data={item} />,
        }}
        pagination={{ total, current: currentPage }}
        onChange={handleTableChange}
      />
    </section>
  );
};

export default FeeExploration;
