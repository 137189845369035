import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal, Form, Input, Typography, Button, Select, Row } from 'antd';
import { CloseOutlined, FileAddOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { COUNTRY_PHONE_CODES } from 'common_constants/business';

import CountryPhCode from '../../components/CountryPhCode';
import Box from '../../components/Box';
import { setClientsList, setCurrentHotlineList, setCurrentReachList, setCurrentSearchList, setModal } from '../../store/commonReducer';
import { error, info, request, success, warn } from '../../tools';
import './style.scss';

const AddBlock = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const { username } = useSelector((state) => state.common.userAuth);
  const { userName, phoneNumber, email } = useSelector((state) => state.common.modal.data);
  const clients = useSelector((state) => state.common.clients);
  const hotlines = useSelector((state) => state.common.currentHotlineList);
  const reached = useSelector((state) => state.common.currentReachList);
  const searched = useSelector((state) => state.common.currentSearchList);

  const [phone, setPhone] = useState(phoneNumber ?? '');
  const [countryPhCode, setCountryPhCode] = useState(COUNTRY_PHONE_CODES.UA);

  const [comment, setComment] = useState('');
  const { Item } = Form;

  const validatePhone = (rule, value) => {
    return new Promise((resolve, reject) => {
      if (!value) {
        reject('Номер телефону - обов`язкове поле!');
      } else if (!/^[0-9]+$/.test(value)) {
        reject('Номер телефону повинен містити лише цифри.');
      } else if (value.length < 9 || value.length > 11) {
        reject('Номер телефону повинен містити від 9 до 11 цифр.');
      } else {
        resolve();
      }
    });
  };

  const handleUserBlock = async () => {
    if (location.pathname === '/clients' || location.pathname === '/blocked') {
      const updatedClients = clients.map((client) => (client.ph === phone ? { ...client, bl: true } : client));

      dispatch(setClientsList(updatedClients));
    } else if (location.pathname === '/list') {
      const updatedHotlines = hotlines.map((hotline) => (hotline.phone === phone ? { ...hotline, bl: true } : hotline));

      dispatch(setCurrentHotlineList(updatedHotlines));
    } else if (location.pathname === '/search') {
      const updatedHotlines = searched.map((hotline) => (hotline.phone === phone ? { ...hotline, bl: true } : hotline));

      dispatch(setCurrentSearchList(updatedHotlines));
    } else if (location.pathname === '/hotlineReach') {
      const updateReached = reached.map((item) => (item.phone === phone ? { ...item, bl: true } : item));
      dispatch(setCurrentReachList(updateReached));
    }

    const validation = (_phone, _username) => {
      if (!_phone) {
        warn('', 'Для блокування телефону, повинен бути у клієнта вказаний номер телефону.', { _phone: !!_phone });
        return;
      }

      if (!_username) {
        warn('', 'Для блокування телефону, повинен бути чинний користувач.', { chn: !!_username });
        return;
      }

      return true;
    };

    if (!validation(phone, username)) return;

    const transaction = {
      name: userName,
      email: email,
      phone,
      countryPhCode,
      blockedBy: username,
      comment,
    };

    await request.post(
      '/block/blockAdd',
      transaction,
      () => {
        success('', `Телефон було успішно заблоковано.`);
        dispatch(setModal());
      },
      () => {
        error('', `Телефон вже заблоковано.`);
      },
    );
  };
  const onCancel = () => {
    dispatch(setModal());
    info('', 'Ви не згодні зі своїм рішенням.');
  };
  const { Text } = Typography;

  return (
    <Modal open className="block-add-user" title={'Заблокувати користувача'} onCancel={onCancel} footer={null}>
      <Form className="block-form" layout="vertical" onFinish={handleUserBlock} form={form} initialValues={{ phone, countryPhCode }}>
        <Box mt={0}>
          <Text strong>
            Номер телефону: <span style={{ color: 'red' }}>*</span>
          </Text>
          <br />

          <Row>
            <Item
              preserve={true}
              name="countryPhCode"
              rules={[{ required: true, message: 'Код країни - обов`язкове поле!' } /* { validator: validatePhone } */]}
            >
              <CountryPhCode value={countryPhCode} onChange={setCountryPhCode} />
            </Item>
            &nbsp;
            <Item
              preserve={true}
              name="phone"
              rules={[{ required: true, message: 'Номер телефону - обов`язкове поле!' }, { validator: validatePhone }]}
            >
              <Input
                defaultValue={phone}
                placeholder="Введіть номер телефону"
                type="number"
                inputMode="numeric"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </Item>
          </Row>
        </Box>

        <Box mt={0}>
          <Text strong>Коментар:</Text>
          <br />
          <Item onChange={(e) => setComment(e.target.value)} preserve={true} name="comment">
            <TextArea autoSize aria-label="We need to enter the name of our future template document" placeholder="Введіть коментар" />
          </Item>
        </Box>

        <Box className="group-button">
          <Button className="group-button__btn" type="primary" htmlType="submit" style={{ marginRight: '15px' }}>
            <FileAddOutlined /> Заблокувати
          </Button>
          <Button className="group-button__btn" danger type="primary" onClick={onCancel}>
            <CloseOutlined />
            Скасувати
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
export default AddBlock;
