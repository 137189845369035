import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Card, Form, Popover, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import HoverableUser from '../../../components/HoverableUser';
import { addTwitterComment, setModal } from '../../../store/commonReducer';
import { error, request, success } from '../../../tools';
import TextArea from 'antd/es/input/TextArea';

const TweetPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();

  const users = useSelector((state) => state.common.users);
  const tweets = useSelector((state) => state.common.allTweets || []);
  const FILII = useSelector((state) => state.common.FILII);

  const [currentTweet, setCurrentTweet] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  if (tweets.length < 1) {
    history.push('/twitter');
  }

  useEffect(() => {
    setIsLoading(true);
    const tweet = tweets.find((item) => item._id === id);
    if (tweet) {
      setCurrentTweet(tweet);
      setIsLoading(false);
    }
  }, [tweets, id]);

  const handleSubmitComment = async () => {
    setIsLoading(true);

    const data = {
      comment: form.getFieldValue('comment'),
    };

    await request.post(
      `/twitter/get/${id}/comment`,
      data,
      ({ comment }) => {
        success('', 'Коментар успішно додано');
        dispatch(addTwitterComment({ tweetId: id, comment }));
        dispatch(setModal());

        form.resetFields();
        setIsLoading(false);
      },
      () => {
        error('', `Помилка при додаванні.`);
        setIsLoading(false);
      },
    );
  };

  return (
    <div className="twitter_page default_page">
      <Spin spinning={isLoading} tip="Зачекайте" size="large">
        {currentTweet ? (
          <>
            <Card>
              <Typography.Title level={3} style={{ color: '#1890ff', fontSize: 18 }}>
                @
                <Popover content={<HoverableUser id={users[currentTweet.author]?._id ?? '-'} />} arrow placement="right" trigger="hover">
                  <Typography.Link>{users[currentTweet.author]?.p ?? '-'}</Typography.Link>
                </Popover>
                <span style={{ fontSize: 14, color: '#c7bcbc', marginLeft: 12 }}>{FILII[users[currentTweet.author]?.fil]}</span>
              </Typography.Title>

              <Typography.Paragraph> {currentTweet.message}</Typography.Paragraph>
              <Typography.Paragraph style={{ fontSize: '10px', color: '#c7bcbc' }}>
                {dayjs(currentTweet.createdAt).format('DD.MM.YYYY HH:mm:ss')}
              </Typography.Paragraph>
            </Card>

            <br />

            <Form form={form} onFinish={handleSubmitComment}>
              <Form.Item
                name="comment"
                rules={[
                  {
                    required: true,
                    message: 'Коментар не може бути порожнім!',
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Напишіть коментар..." />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Додати коментар
                </Button>
              </Form.Item>
            </Form>

            <div>
              <h3>Коментарі:</h3>
              {currentTweet?.comments?.map((comment) => {
                return (
                  <div key={comment.id}>
                    <Card>
                      <Typography.Title level={3} style={{ color: '#1890ff', fontSize: 18 }}>
                        @
                        <Popover content={<HoverableUser id={users[comment.user]?._id ?? '-'} />} arrow placement="right" trigger="hover">
                          <Typography.Link>{users[comment.user]?.p ?? '-'}</Typography.Link>
                        </Popover>
                        <span style={{ fontSize: 14, color: '#c7bcbc', marginLeft: 12 }}>{FILII[users[comment.user]?.fil]}</span>
                      </Typography.Title>
                      <p>{comment.comment}</p>
                      <p style={{ fontSize: '10px', color: '#c7bcbc' }}>{dayjs(comment.createdAt).format('DD.MM.YYYY HH:mm:ss')}</p>
                    </Card>
                    <br />
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </Spin>
    </div>
  );
};

export default TweetPage;
