import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tag, Radio } from 'antd';
import { Box, Row, ExcelBtn } from 'common_components';
import { EXPORT_ACTIVITY } from 'common_constants/modals';
import { ROLES } from 'common_constants/business';

import ActivityItem from './ActivityItem';
import Responsibilities from './Responsibilities';
import { setModal } from '../../store/commonReducer';
import useGetUsers from '../../hooks/useGetUsers';

const tabs = [
  { label: 'Компакт', value: 'comp' },
  { label: 'Повна', value: 'full' },
  { label: 'Групи реагування', value: 'resp' },
];

const Activity = () => {
  const loadUsers = useGetUsers();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);
  const [tab, setTab] = useState('comp');

  const admin = ROLES[userAuth.role] === ROLES.admin;
  let activeUsers = [];

  if (users) {
    activeUsers = Object.values(users)?.filter((user) => !user.fired);
  }

  useEffect(() => {
    loadUsers();
  }, [tab]);

  const sortedUsers = activeUsers
    .sort((a, b) => {
      if (a.activity?.T && b.activity?.T) {
        return 0;
      } else return a.activity?.T ? -1 : 1;
    })
    .sort((a, b) => new Date(b.activity?.T) - new Date(a.activity?.T))
    .sort((a, b) => {
      return a.activity?.value - b.activity?.value;
    });

  return (
    <Box style={{ padding: 8 }}>
      <Row style={{ justifyContent: 'center', marginBottom: 8 }}>
        <Radio.Group options={tabs} optionType="button" value={tab} onChange={(e) => setTab(e.target.value)} />
        {admin && (
          <ExcelBtn style={{ width: 'auto', marginLeft: '10px' }} onClick={() => dispatch(setModal({ name: EXPORT_ACTIVITY }))}>
            Експорт
          </ExcelBtn>
        )}
      </Row>
      {tab === 'comp' &&
        Object.values(activeUsers).map((user) => {
          return (
            <Tag key={user._id} color={user.activity?.value === 1 ? 'green' : 'red'}>
              {user.p}
            </Tag>
          );
        })}

      {tab === 'full' && sortedUsers.map((user) => <ActivityItem key={user._id} user={user} />)}
      {tab === 'resp' && <Responsibilities />}
    </Box>
  );
};

export default React.memo(Activity);
