import React from 'react';
import { useSelector } from 'react-redux';

const TrafficLight = ({ data, correspondence }) => {
  const users = useSelector((state) => state.common.users);

  if (!data) return null;
  if (!correspondence) return null;

  const filterByUsers = (item) => {
    const usersArr = Object.values(users || {});

    return usersArr.filter((user) => user._id === item.sender).length > 0 ? false : true;
  };

  const fileMessages = data?.chatWithClient?.filter((item) => item?.fileId) || [];
  const nonfileMessages = data?.chatWithClient?.filter((item) => !item?.fileId) || [];
  const nonfileFromClent = nonfileMessages.filter(filterByUsers).length;
  const fileFromClent = fileMessages.filter(filterByUsers).length;
  const fileFromUsers = fileMessages.length - fileFromClent;
  const nonfileFromUsers = nonfileMessages.length - nonfileFromClent;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>
        <div>Виходи: {data?.assignments?.length || 0}</div>
        <div>Кореспонденція: {correspondence.length || 0}</div>
        <div>Файли від клієнта: {fileFromClent || 0}</div>
        <div>Файли від юристів: {fileFromUsers || 0}</div>

        <div>Повідомлення від клієнта: {nonfileFromClent || 0}</div>
        <div>Повідомлення від юристів: {nonfileFromUsers || 0}</div>
      </div>
    </div>
  );
};

export default React.memo(TrafficLight);
