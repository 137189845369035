import React, { useState, useEffect } from 'react';
import { ROLES } from 'common_constants/business';
import { SearchOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Input, Modal, Spin, Switch } from 'antd';
import clsx from 'clsx';

import UserAvatar from '../../components/UserAvatar';

const Members = ({ setOpenMembers, task, setCurrentTask }) => {
  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);
  const filii = useSelector((state) => state.common.filii);

  const [inputSearch, setInputSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [usersArray, setUsersArray] = useState(false);

  const isInspector = ROLES[userAuth.role] <= 4;
  const isBlockManagerOnly = ROLES[userAuth.role] === ROLES.blockManager;
  const isDeputyManager = ROLES[userAuth.role] <= ROLES.deputyManager;

  useEffect(() => {
    if (!users) {
      return;
    }

    let allUsers = Object.values(users);
    allUsers = allUsers.filter((item) => !item?.fired);

    if (isInspector) {
      setUsersArray(allUsers);
      return;
    }

    if (isDeputyManager) {
      let filteredUsers = allUsers.filter((user) => ['worker', 'rabfil', 'blockManager'].includes(user.role));
      setUsersArray(filteredUsers);
      return;
    }

    if (isBlockManagerOnly) {
      let filteredUsers = allUsers.filter((user) => ['worker', 'rabfil'].includes(user.role));
      setUsersArray(filteredUsers);
      return;
    }
  }, [users]);

  useEffect(() => {
    let users = usersArray.length > 0 ? usersArray.filter((user) => user?.p.toLowerCase().includes(inputSearch.toLowerCase())) : [];

    setFilteredUsers(users);
  }, [inputSearch, usersArray]);

  const onClickUserHandle = (userId) => {
    let filteredMembers = task?.appointed?.filter((item) => item !== userId);

    if (filteredMembers?.length === task?.appointed?.length) {
      setCurrentTask((prev) => ({ ...prev, appointed: [userId, ...prev.appointed] }));
      return;
    }

    setCurrentTask((prev) => ({ ...prev, appointed: [...filteredMembers] }));
  };

  const onChangeAssignSwitchHandle = (val) => {
    if (val) {
      setCurrentTask((prev) => ({ ...prev, appointed: [...usersArray] }));
      return;
    }

    setCurrentTask((prev) => ({ ...prev, appointed: [] }));
  };

  const getFil = (user) => filii.find((fil) => +fil.i === +user.fil)?.name || user.fil;

  const checkIfUserIsIncluded = (user) => task?.appointed?.some((item) => item === user?._id);

  const onChangeInputSearch = (e) => setInputSearch(e.target.value);

  const onClickCancel = () => setOpenMembers(false);

  return (
    <Modal className="members-modal" title="Учасники" mask={false} width={400} open footer={null} onCancel={onClickCancel}>
      <Spin spinning={isLoading}>
        <Input
          className="input-search"
          value={inputSearch}
          onChange={onChangeInputSearch}
          placeholder="Шукати користувачі"
          suffix={<SearchOutlined />}
        />
        <div>
          <div className="assign-all-switch">
            <p>Призначити усім:</p>
            <Switch onChange={onChangeAssignSwitchHandle} />
          </div>
          <div className="users-wrapper">
            {filteredUsers?.map((user) => (
              <div
                className={clsx('user', checkIfUserIsIncluded(user) ? 'appointed' : '')}
                onClick={() => onClickUserHandle(user._id)}
                key={user._id}
              >
                <div className="user-content">
                  <UserAvatar user={user} />
                  <p>
                    {user.p} - {getFil(user)}
                  </p>
                </div>
                {checkIfUserIsIncluded(user) ? <CheckOutlined /> : null}
              </div>
            ))}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default Members;
