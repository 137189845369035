import { configureStore } from '@reduxjs/toolkit';

import commonReducer from './commonReducer';
import uiReducer from './uiReducer';
import aiReducer from './aiReducer';
import { sphereApi } from './apis/sphere-api';
import { monobankApi } from './apis/monobankApi';

export const store = configureStore(
  {
    reducer: {
      common: commonReducer,
      ui: uiReducer,
      ai: aiReducer,
      [sphereApi.reducerPath]: sphereApi.reducer,
      [monobankApi.reducerPath]: monobankApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(sphereApi.middleware, monobankApi.middleware),
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
