import { React, useEffect, useState } from 'react';
import { error, request } from '../../tools';
import { Empty, Pagination, Spin, Typography } from 'antd';
import ClientsDozhimsAIChatsItem from './ClientsDozhimsAIChatsItem';

const DEFAULT_PAGE_SIZE = 10;

const ClientsDozhimsAIChats = () => {
  const [dozhimsAIChats, setDozhimsAIChats] = useState([]);

  const { Text } = Typography;

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const getAllDozhimsAIChats = (limits) => {
    setLoading(true);

    const params = { ...limits };

    request.post(
      '/dozhimsAI/getDozhimsAIChats',
      params,
      ({ dozhimsAIChats, total }) => {
        setDozhimsAIChats(dozhimsAIChats);
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    const limits = {
      page: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
    };
    getAllDozhimsAIChats(limits);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="default_page">
      <h2>AI дожими клієнтів</h2>
      <br />
      <Spin spinning={loading} tip="Зачекайте" size="large">
        {dozhimsAIChats?.length ? (
          dozhimsAIChats?.map((item) => <ClientsDozhimsAIChatsItem key={item._id} data={item} />)
        ) : (
          <Empty description={<Text>Немає даних</Text>} />
        )}

        <Pagination style={{ textAlign: 'center' }} current={currentPage} pageSize={DEFAULT_PAGE_SIZE} total={total} onChange={handlePageChange} />
      </Spin>
    </div>
  );
};

export default ClientsDozhimsAIChats;
