import React from 'react';
import { Col, Row, Button, Typography } from 'antd';
import { CheckSquareTwoTone } from '@ant-design/icons';

import './VotingComplection.scss';

const { Title } = Typography;

const VotingCompletion = ({ onCancel }) => (
  <Col className="votingCompletion">
    <Row justify={'center'}>
      <Title level={5}>Дякуємо! Ваш голос враховано.</Title>
    </Row>

    <Row justify={'center'} className="votingCompletion-logo">
      <CheckSquareTwoTone twoToneColor="#52c41a" className="votingCompletion-logo__item" />
    </Row>
    <Row justify={'center'}>
      <Button onClick={onCancel}>Закрити</Button>
    </Row>
  </Col>
);
export default VotingCompletion;
