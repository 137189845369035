import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Spin, Modal } from 'antd';
import { CSVLink } from 'react-csv';

import { setModal } from '../../store/commonReducer';
import { request, error } from '../../tools';
import Excel from '../../images/excel_logo.svg';
import dayjs from 'dayjs';

const csvDataTitles = ['№ договору', 'Філія', 'Дата архівації', 'Дата укладення'];
const ExportLoad = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([csvDataTitles]);
  const [generate, setGenerate] = useState(true);

  const onGenerate = async () => {
    setLoading(true);
    await request.post(
      '/spreadsheets/exportLoad',
      {},
      (res) => {
        setLoading(false);
        const cvsData = [csvDataTitles];
        res.data?.forEach((contract) => {
          const d = dayjs(contract.sd);
          cvsData.push([
            contract.i,
            contract.filia[0]?.name,
            contract.ad ? dayjs(contract.ad).format('DD-MM-YYYY') : 'не архівний',
            `${d.date()}.${d.month() + 1}.${d.year()}`,
          ]);
        });
        setData(cvsData.sort((a, b) => a.i - b.i));
        setGenerate(false);
      },
      error,
    );
  };

  return (
    <Modal open className="export-hotline" title="Експорт вигрузки навантаження" onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="change">
          <br />
          {generate ? (
            <Button onClick={onGenerate}>Генерувати</Button>
          ) : (
            <CSVLink filename={'contracts-load.csv'} asyncOnClick={true} data={data}>
              <Button className="excel">
                <img className="excel-img" src={Excel} alt="Excel" /> Завантажити contracts-load.csv
              </Button>
            </CSVLink>
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

export default ExportLoad;
