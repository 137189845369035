import React from 'react';
import { Card, Col, Row, List } from 'antd';
import WalletFilialsItem from '../WalletFilialsItem';

import './WalletFilialsList.scss';

const WalletFilialsList = ({ filials, exchangeRates }) => (
  <>
    <Card className="walletFilialsInner">
      <Row className="item" justify={'space-between'} align={'middle'}>
        <Col className="fields-inner" flex={'auto'}>
          <Row className="fields" justify={'space-between'}>
            <Col className="fields-item">
              <Row className="fields-row">
                <Row className="title">Філія</Row>
                <Row className="info"></Row>
              </Row>
            </Col>
            <Col className="fields-item">
              <Row className="fields-row">
                <Row className="title">Баланс</Row>
                <Row className="info"></Row>
              </Row>
            </Col>
            <Col className="fields-item">
              <Row className="fields-row">
                <Row className="title">Баланс за попередній місяць</Row>
                <Row className="info"></Row>
              </Row>
            </Col>
            <Col className="fields-item">
              <Row className="fields-row">
                <Row className="title">Неприйняті передасти</Row>
                <Row className="info"></Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
    <List
      itemLayout="vertical"
      size="large"
      dataSource={filials}
      renderItem={(fil) => <WalletFilialsItem exchangeRates={exchangeRates} fil={fil} />}
    />
  </>
);

export default React.memo(WalletFilialsList);
