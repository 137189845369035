import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModal, setLibraryList } from '../../store/commonReducer';
import { LIBRARY_ADD_DOCUMENT } from 'common_constants/modals';
import { useResize } from 'common_components';

import { Input, Button, Typography, Card, List, Spin, Empty } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';

import LibraryItem from '../../components/LibraryItem/LibraryItem';
import {
  PRAVO_TYPES_LIBRARY,
  EXACTLY_WHAT_LIBRARY,
  SERVIS_OPTIONS,
  TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY,
  FILE_TYPE_LIBRARY,
  MONTHS_CHANGE_OF_WORDS,
} from 'common_constants/business';
import { request, error } from '../../tools';
import { Box, Row as MyRow } from 'common_components';
import PageInfo from '../../components/PageInfo';

import './Library.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const Library = () => {
  const formRef = useRef(null);
  const resize = useResize();
  const dispatch = useDispatch();

  const list = useSelector((state) => state.common.library),
    users = useSelector((state) => state.common.users);

  const { Text } = Typography;

  const core_megaState = {
      loading: false, //* Завантаження
      filterLibraryInput: '', //* Фільтр відмітки
    },
    [megaState, setMegaState] = useState(core_megaState);

  const filterName = (file) => {
    const data_Name = file.n ?? '', //* Чекаєм назву
      data_Hashteg = file.h && Array.isArray(file.h) ? file.h.join(' ') : '', //* Чекаєм хештеги
      data_Reporting_Number = file.r || file.r === 0 ? file.r : '', //* Чекаєм номер звітності
      data_Document_Number = file.i || file.i === 0 ? file.i : '', //* Чекаєм номер документу
      data_Useful_For_Block = file.j?.map((va) => SERVIS_OPTIONS[va])?.join(' ') ?? '', //* Чекаєм корисно для Блоку
      data_Type_Of_Legal_Relationship = file.k?.map((va) => PRAVO_TYPES_LIBRARY[va])?.join(' ') ?? '', //* Чекаєм вид правовідносин
      data_What_Exactly = file.w?.map((va) => EXACTLY_WHAT_LIBRARY[va])?.join(' ') ?? '', //* Чекаєм що саме
      data_Document_Type = (file.g && TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY?.[file.g]?.label) ?? '', //* Чекаєм вид документу
      data_Document_Form = (file.g && file.q && TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY?.[file.g]?.form?.[file.q]?.label) ?? '', //* Чекаєм форму документу
      data_File_Type = (file.z && FILE_TYPE_LIBRARY[file.z]) ?? '', //* Чекаєм тип файлу
      data_File_Format = file.f ?? '-', //* Чекаєм формат файлу
      data_User_Name = (users && users[file.a]?.name) ?? '', //* Чекаєм ім'я автора
      data_User_P = (users && users[file.a]?.p) ?? '', //* Чекаєм псевдонім автора
      data_User_Username = (users && users[file.a]?.username) ?? '', //* Чекаєм пошту автора
      data_Of_Creation_Date =
        (file.t &&
          dayjs(file.t)?.format(
            `D DD M MM MMM MMMM ${MONTHS_CHANGE_OF_WORDS[dayjs(file.t)?.$M]?.join(' ') ?? ''} YYYY року рік році роком рокові`,
          )) ??
        '', //* Чекаєм дату створення
      data_Of_Nesessary_Document = (file.o ? "Необов'язковий" : "Обов'язковий") ?? '', //* Чекаєм тип файла (НО\О)
      data_Type_Library = FILE_TYPE_LIBRARY[file.o] ?? '', //* Тип файлу
      data_Link_Address = file.z === '1' && file.p ? file.p : '', //* Адреса посилання
      data_Comments = file.m ?? ''; //* Коментар

    const text = [
      data_Name,
      data_Hashteg,
      data_Reporting_Number,
      data_Document_Number,
      data_Useful_For_Block,
      data_Type_Of_Legal_Relationship,
      data_What_Exactly,
      data_Document_Type,
      data_Document_Form,
      data_File_Type,
      data_File_Format,
      data_User_Name,
      data_User_P,
      data_User_Username,
      data_Of_Creation_Date,
      data_Of_Nesessary_Document,
      data_Type_Library,
      data_Link_Address,
      data_Comments,
    ].join(' '); //* Велике об'єднання

    const findArr = megaState.filterLibraryInput.split(' '); //* Трансмутація пошук-речення в масив слів

    const searchExecution = findArr.every((value) => {
      const regex = new RegExp(value, 'i'); //* 'i' - ігнорує регістр
      return regex.test(text);
    }); //* Перевірка на існування кожного слова пошуку в тексті

    const checkFilter =
      megaState.filterLibraryInput !== '' //* Перевірка на пустоту
        ? searchExecution
        : true; //* Базовий список показати

    return checkFilter;
  }; //? Мега пошук

  const listToRender =
    list?.filter((i) => filterName(i)) ?? //? Мега пошук
    [];

  const getAllListLibrary = async () => {
    setMegaState({ ...megaState, loading: true });
    await request.post(
      '/library',
      {},
      (req) => {
        dispatch(setLibraryList(req.library));
      },
      error,
    );
    setMegaState({ ...megaState, loading: false });
  };

  useEffect(() => {
    getAllListLibrary();
  }, []);

  const filter_static = (
    <Card>
      <Box mt={0} className={'library-page__filter-main'}>
        <Box mt={0}>
          <Box className={resize[0] >= 500 ? 'reach-filter-box-pc' : 'reach-filter-box-mob'}>
            <Text strong className="reach-filter-box-text">
              Пошук у всіх напрямках:
            </Text>
            <Input
              aria-label="Поле для вводу будь-яких критеріїв"
              placeholder="Введіть будь-які критерії"
              onChange={(e) => setMegaState({ ...megaState, filterLibraryInput: e.target.value })}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );

  return (
    <section className="default_page library-page">
      <div ref={formRef} />
      <div className="library-page__header">
        <h2 className="library-page__title">
          Бібліотека <PageInfo />
        </h2>

        <MyRow style={{ justifyContent: 'center' }}>
          <Button type="primary" onClick={() => dispatch(setModal({ name: LIBRARY_ADD_DOCUMENT }))}>
            <FileAddOutlined /> Додати файл/посилання
          </Button>
        </MyRow>

        <br />
      </div>

      {filter_static}

      <Box mt={16}>
        <Spin tip="Завантаження" spinning={megaState.loading} size="large">
          {listToRender.length ? (
            <List
              className={'library-page__list-main'}
              pagination={{
                hideOnSinglePage: true,
                position: 'both',
                align: 'end',
                defaultCurrent: 1,
                total: listToRender.length ?? 1,
                defaultPageSize: 20,
                showTotal: (total, range) => `${range[0]}-${range[1]} з ${total} файлів`,
              }}
              dataSource={listToRender ?? []}
              renderItem={(item) => <LibraryItem item={item} key={item._id} />}
            ></List>
          ) : (
            <Empty description={<Text>Нема файлів</Text>} />
          )}
        </Spin>
      </Box>
    </section>
  );
};

export default Library;
