import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout, ConfigProvider } from 'antd';
import clsx from 'clsx';

import RouterSwitch from './RouterSwitch';
import MainMenu from './components/Menu';
import AuthControl from './components/AuthControl';
import MainLoader from './components/MainLoader';
import Modals from './Modals';
import { useAuthData, useControllView, useUserInfo } from './tools/hooks';
import {
  useConnectSocket,
  useListenMessages,
  useListenOnlineUsers,
  useListenGroups,
  useGetUserUnreadedMessages,
  useListenContractChats,
} from './hooks';
import ShowUnreadedMessagesNotification from './notifications/ShowUnreadedMessagesNotification';

import theme from './theme';

export default function App() {
  const userAuth = useSelector((state) => state.common.userAuth);
  const appPrepared = useSelector((state) => state.common.appPrepared);
  const { mobSidebar, mainLoader, universalLoader } = useSelector((state) => state.ui);

  useControllView();
  useUserInfo();
  useAuthData();
  useConnectSocket();
  useGetUserUnreadedMessages();
  useListenMessages();
  useListenOnlineUsers();
  useListenGroups();
  useListenContractChats();

  if (!appPrepared && userAuth)
    return (
      <Router>
        <AuthControl />
        <MainLoader show={true} />
      </Router>
    );

  return (
    <ConfigProvider theme={theme}>
      <Router>
        <AuthControl />
        <Layout hasSider className={clsx('layout', userAuth && 'userAuth')}>
          {userAuth?._id && <MainMenu />}
          <Layout className={clsx(mobSidebar && userAuth && 'mobDisabled')}>
            <Layout.Content>
              <RouterSwitch />
            </Layout.Content>
          </Layout>
        </Layout>

        <MainLoader show={mainLoader || universalLoader} />
        <Modals />
        {/* <ShowUnreadedMessagesNotification /> */}
      </Router>
    </ConfigProvider>
  );
}
