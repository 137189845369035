import React, { useEffect, useState } from 'react';
import { USER_COURT_PRACTICE } from 'common_constants/modals';
import { PlusOutlined, MinusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Button, Select, Input, Col, Row, Form, Upload, message } from 'antd';

import { success, request, error } from '../../tools';
import { setModal, setUserPersonalData } from '../../store/commonReducer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setMainLoader } from '../../store/uiReducer';
import UserAchievementImage from './UserAchievementImage';
import UserCourtPracticeItem from './UserCourtPracticeItem';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

// import './CurrentUserPersonalInfo.scss';

const { Item, List } = Form;
const { TextArea } = Input;

const options = [
  { value: 'Батько', label: 'Батько' },
  { value: 'Мати', label: 'Мати' },
  { value: 'Брат', label: 'Брат' },
  { value: 'Сестра', label: 'Сестра' },
  { value: 'Хлопець', label: 'Хлопець' },
  { value: 'Дівчина', label: 'Дівчина' },
  { value: 'Чоловік', label: 'Чоловік' },
  { value: 'Дружина', label: 'Дружина' },
  { value: 'Інший знайомий', label: 'Інший знайомий' },
];

const validation = (personalPhone, workPhone, confidantName) => {
  if (!personalPhone) {
    error('', 'Введіть особистий номер телефону.');
    return;
  }

  if (!workPhone) {
    error('', 'Введіть робочий номер телефону.');
    return;
  }

  if (!confidantName) {
    error('', 'Введіть ПІБ Довіреної особи.');
    return;
  }

  return true;
};

const UserPersonalInfo = ({ user }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const [achievementFileList, setAchievementFileList] = useState([]);
  const [achievementUploading, setAchievementUploading] = useState(false);

  const [userInfo, setUserInfo] = useState({
    lawyerStatus: user?.userPersonalData?.lawyerStatus || false,
    personalPhone: user?.userPersonalData?.personalPhone || '',
    workPhone: user?.userPersonalData?.workPhone || '',
    confidant: user?.userPersonalData?.confidant || 'Довірена особа',
    confidantName: user?.userPersonalData?.confidantName || '',
    confidantPhone: user?.userPersonalData?.confidantPhone || '',
    education: user?.userPersonalData?.education || '',
    registrationAddress: user?.userPersonalData?.registrationAddress || '',
    addressOfResidence: user?.userPersonalData?.addressOfResidence || '',
    passportData: user?.userPersonalData?.passportData || '',
    achievements: user?.userPersonalData?.achievements || [],
    extraEducation: user?.userPersonalData?.extraEducation || [],
    practice: user?.userPersonalData?.practice || [],
  });

  useEffect(() => {
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      practice: user?.userPersonalData?.practice || [],
    }));
  }, [user?.userPersonalData?.practice]);

  const handleUserInfoChange = (field, value) => {
    setUserInfo((prevUserInfo) => {
      const updatedUserInfo = { ...prevUserInfo };

      if (prevUserInfo[field] && prevUserInfo[field]._id) {
        updatedUserInfo[field] = { ...prevUserInfo[field], _id: prevUserInfo[field]._id };
      }

      const fieldName = field.toLowerCase();

      if (fieldName.includes('phone')) {
        const validPhoneNumber = phoneNumberValidator(value);
        updatedUserInfo[field] = validPhoneNumber;
      } else {
        updatedUserInfo[field] = value;
      }

      form.setFieldsValue({
        [field]: value,
      });

      return updatedUserInfo;
    });
  };

  const handleUpload = () => {
    const formData = new FormData();
    setAchievementUploading(true);

    achievementFileList.forEach((file) => {
      formData.append('file', file);
      formData.append('_id', user._id);
    });

    request.post(
      '/profile/achievementsEdit',
      formData,
      (res) => {
        setAchievementFileList([]);
        message.success('Фото успішно завантажено');

        const editedUser = {
          ...userInfo,
          achievements: [...userInfo.achievements, { fileId: res.data.fileId }],
        };
        setUserInfo(editedUser);
        setAchievementUploading(false);
      },
      () => {
        message.error('Сталася помилка');
        setAchievementUploading(false);
      },
    );
  };

  const props = {
    onRemove: () => {
      setAchievementFileList([]);
    },
    beforeUpload: (file) => {
      const isJpg = file.type === 'image/jpeg';

      if (!isJpg) {
        message.error('Можливо завантажити фото тільки в JPG форматі!');
        setAchievementFileList([]);
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Розмір файлу повинен бути менше 2 мегабайт!');
        setAchievementFileList([]);
      }
      if (!isJpg || !isLt2M) {
        return false;
      }
      setAchievementFileList([file]);
      return false;
    },
    achievementFileList,
  };

  const onSave = async () => {
    if (!validation(userInfo.personalPhone, userInfo.workPhone, userInfo.confidantName)) {
      return;
    }

    dispatch(setMainLoader(true));

    const userId = user._id;

    try {
      const updatedUserInfo = { ...userInfo };

      const formsValue = form.getFieldsValue('');

      for (let item of Object.keys(formsValue)) {
        if (formsValue[item]) {
          updatedUserInfo[item] = formsValue[item];
        }
      }

      request.post(`/profile/updateUserPersonalData/${userId}`, updatedUserInfo, () => {
        dispatch(setUserPersonalData({ userId, userPersonalData: updatedUserInfo }));
        success();
        history.push('/users');
        dispatch(setMainLoader(false));
      });
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const onClickAddCourtPractice = () => {
    dispatch(setModal({ name: USER_COURT_PRACTICE, user: user }));
  };

  return (
    <div className="currentUserPersonalInfo">
      <Row className="currentUserPersonalInfo__row" gutter={[16, 20]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          Ім'я:
          <Input value={user.name} disabled />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          Особистий телефон:
          <Input value={userInfo?.personalPhone} placeholder="0950472323" onChange={(e) => handleUserInfoChange('personalPhone', e)} />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          Робочий телефон:
          <Input value={userInfo?.workPhone} placeholder="0950472222" onChange={(e) => handleUserInfoChange('workPhone', e)} />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          Довірена особа:
          <Select
            className="currentUserPersonalInfo__select"
            value={userInfo?.confidant || 'Довірена особа'}
            onChange={(value) => handleUserInfoChange('confidant', value)}
            options={options}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          ПІБ Довіреної особи:
          <Input
            value={userInfo?.confidantName}
            placeholder="Петренко Артем Дмитрович"
            onChange={(e) => handleUserInfoChange('confidantName', e.target.value)}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          Телефон Довіреної особи:
          <Input value={userInfo?.confidantPhone} placeholder="0950471111" onChange={(e) => handleUserInfoChange('confidantPhone', e)} />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          Освіта:
          <Input value={userInfo?.education} placeholder="Вища юридична освіта" onChange={(e) => handleUserInfoChange('education', e.target.value)} />
        </Col>
        <Col span={24}>
          Адреса реєстрації:
          <Input
            value={userInfo?.registrationAddress}
            placeholder="Україна, Одеська область, Березівський район, смт. Березівка, вул. Петровська, 23, кв. 2"
            onChange={(e) => handleUserInfoChange('registrationAddress', e.target.value)}
          />
        </Col>
        <Col span={24}>
          Адреса проживання:
          <Input
            value={userInfo?.addressOfResidence}
            placeholder="Україна, Одеська область, Березівський район, смт. Березівка, вул. Івановська, 21, кв. 3"
            onChange={(e) => handleUserInfoChange('addressOfResidence', e.target.value)}
          />
        </Col>
        <Col span={24}>
          Паспортні дані:
          <Input
            value={userInfo?.passportData}
            placeholder="Паспорт серії ЕН 111223, виданий Березівським РВ ОМУ УМВС України в Одеській області 23.12.2007р."
            onChange={(e) => handleUserInfoChange('passportData', e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Row className="currentUserPersonalInfo__court-practice-top" wrap={true} align="center" justify={'space-between'}>
          <p className="currentUserPersonalInfo__form-title">Cудова практика</p>
          <Button onClick={onClickAddCourtPractice} type="primary" icon={<PlusOutlined />}>
            Додати практику
          </Button>
        </Row>
        <Row className="currentUserPersonalInfo__court-practice-wrapper" gutter={[16, 20]}>
          {userInfo?.practice?.map((item, index) => (
            <UserCourtPracticeItem data={item} user={user} key={index} />
          ))}
        </Row>
      </Row>
      <Row gutter={[16, 20]}>
        <Col className="currentUserPersonalInfo__extra-education" md={{ span: 24 }} lg={{ span: 8 }}>
          <p className="currentUserPersonalInfo__form-title">Додаткова освіта</p>

          <Form initialValues={userInfo} layout="vertical" form={form}>
            <List name="extraEducation">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ field, name, key }, index) => (
                    <Item className="currentUserPersonalInfo__form-item" required key={index}>
                      <MinusCircleOutlined className="currentUserPersonalInfo__delete-form-item" onClick={() => remove(name)} />
                      <Item
                        name={[name, 'name']}
                        validateTrigger={['onChange', 'onBlur']}
                        className="currentUserPersonalInfo__input-wrapper"
                        label="Назва курсу:"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Будь ласка, введіть назву курсу!',
                          },
                        ]}
                      >
                        <Input placeholder="Юридичний форум правників України" />
                      </Item>
                      <Item label="Місто, рік проходження:" name={[name, 'location']} className="currentUserPersonalInfo__input-wrapper">
                        <Input placeholder="м. Київ, 2023р." />
                      </Item>
                      <Item name={[name, 'result']} label="Результати:" className="currentUserPersonalInfo__input-wrapper">
                        <TextArea placeholder="За результатами форуму була нагороджна грамотою" />
                      </Item>
                    </Item>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                      Додати
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </List>
          </Form>
        </Col>
        <Col className="currentUserPersonalInfo__achievements" md={{ span: 24 }} lg={{ span: 16 }}>
          <p className="currentUserPersonalInfo__form-title">Здобутки</p>
          <Upload {...props} maxCount={1}>
            <Button icon={<UploadOutlined />}>Завантажити</Button>
          </Upload>
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={achievementFileList.length === 0}
            loading={achievementUploading}
            style={{
              marginTop: 16,
            }}
          >
            {achievementUploading ? 'Завантаження' : 'Завантажити'}
          </Button>
          <div className="currentUserPersonalInfo__achievements-wrapper">
            <Row gutter={[16, 16]}>
              {user?.userPersonalData?.achievements?.map((item, index) => (
                <UserAchievementImage item={item} user={user} key={index} />
              ))}
            </Row>
          </div>
        </Col>
      </Row>
      <Button type="primary" onClick={onSave}>
        Зберегти
      </Button>
    </div>
  );
};

export default UserPersonalInfo;
