import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select, Spin, Modal, Input, Card, Alert } from 'antd';
import { CheckOutlined, CloseOutlined, PhoneOutlined } from '@ant-design/icons';

import { CURRENCIES, HOTLINE_RESULT } from 'common_constants/business';
import { Box } from 'common_components';

import { warn, success, request, error } from '../../tools';
import { setCurrentHotlineList, setModal } from '../../store/commonReducer';
import ReasonsDecline from './ReasonsDecline';
import ReasonsReach from './ReasonsReach';

import './styless.scss';

const HotlineQue = () => {
  const dispatch = useDispatch();

  const modalData = useSelector((state) => state.common.modal.data);
  const list = useSelector((state) => state.common.currentHotlineList);
  const users = useSelector((state) => state.common.users);
  const { username } = useSelector((state) => state.common.userAuth);

  const [showDeclineOptions, setShowDeclineOptions] = useState(false);
  const [showReachOptions, setShowReachOptions] = useState(false);
  const [ammError, setAmmError] = useState(false);
  const [startClose, setStartClose] = useState(false);
  const [amp, setAmp] = useState(modalData.amp || '');
  const [amr, setAmr] = useState(modalData.amr || '0');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentReason, setPaymentReason] = useState('');
  const [sugarForHotline, setSugarForHotline] = useState([]);

  const amm = 'Звітує:  ' + window?.users?.[username]?.p + ' - ' + window.users?.[username]?.name;
  const _data = { ...modalData, amm: window?.users?.[username]?._id, amp, amr };

  const getSugar = () => {
    request.post('/sugar/getSugarById', { sugarId: modalData.sugarId }, ({ sugar }) => {
      setSugarForHotline(sugar);
    });
  };

  useEffect(() => {
    getSugar();
  }, []);

  const toggleDeclineOptions = () => {
    setShowReachOptions(false);
    setShowDeclineOptions(true);
  };

  const toggleReacheOptions = () => {
    setShowReachOptions(true);
    setShowDeclineOptions(false);
  };

  const toggleCloseBtn = () => {
    setShowReachOptions(false);
    setShowDeclineOptions(false);
  };

  const strongLogic = () => {
    const startJustAmr2 = () => {
      return amm ? confirmQue(_data, '1') : setAmmError(true);
    };
    const startMainAmr = () => {
      return amr === '2' && !showDeclineOptions && !showReachOptions ? startJustAmr2() : toggleReacheOptions();
    };
    startMainAmr();
  };

  const confirmQue = (data, val) => {
    let validation = (data, val) => {
      let boolenVal = true;
      const arrayVal = {
        'і дані клієнта': data,
        'і дані клієнта (чинного)': data._id,
        'ий статус зустрічі': val,
        'і дані адвоката': data.amm,
        'ий телефон': data.phone,
        'ий тип': data.type,
      };

      for (let item in arrayVal) {
        let value = !arrayVal[item];

        if (value) {
          warn('', `Для звітування консультації потрібно обрати правильн${item}`, { value: value });
          boolenVal = false;
        }
      }

      if (resultAmount !== modalData?.sugarAmount && !paymentReason && !(resultAmount === 0 && !modalData?.sugarAmount)) {
        warn('', 'Будь ласка, вкажіть причину неоплати або неспівпадіння суми.', { value: true });
        boolenVal = false;
      }

      if (!boolenVal) return;
      return true;
    };

    if (!validation(data, val)) return;

    setIsLoading(true);

    const transaction = {
      _id: data._id,
      s: val,
      amm: data.amm,
      amp: data.amp,
      amr: data.amr,
      uristName: (window.users && window.users[data.amm]?.name) || users[data.amm]?.name,
      phone: data.phone,
      type: data.type,
      chn: data.chn,
      vip: data.vip,
      currency: data.currency,
      pravo: data.pravo,
      date: data.date,
      startT: data.startT,
      endT: data.endT,
      eAdvokat: data?.hotlineMatch?.phoneVerified,
      paymentReason: modalData?.cost !== modalData?.sugarAmount ? paymentReason : '',
    };

    request.post(
      '/hotline/confirmQue',
      transaction,
      () => {
        success();
        const _list = list.map((i) => {
          return i._id === data._id ? { ...i, s: val, amm: data.amm, amp: data.amp, amr: data.amr } : i;
        });
        setIsLoading(false);
        dispatch(setModal({}));
        dispatch(setCurrentHotlineList(_list));
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (!startClose) return setStartClose(true);

    amr === '2' ? toggleCloseBtn() : toggleReacheOptions();
  }, [amr]);

  const ControlBtns = () => (
    <div className="btn-row">
      <Button className="green" onClick={strongLogic}>
        <CheckOutlined
          style={{
            color: '#fff',
            fontWeight: 'bold',
          }}
        />
      </Button>
      <Button type="primary" danger onClick={toggleDeclineOptions}>
        <CloseOutlined
          style={{
            color: '#fff',
            fontWeight: 'bold',
          }}
        />
      </Button>
      <Button type="primary" onClick={() => (amm ? confirmQue(_data, '3') : setAmmError(true))}>
        <PhoneOutlined
          style={{
            color: '#fff',
            fontWeight: 'bold',
          }}
        />
      </Button>
    </div>
  );

  const resultAmount = modalData.hotlineMatch?.phoneVerified ? modalData.cost : modalData.costNoApp;

  return (
    <Modal title="Підтвердіть проведення зустрічі" className="hotline-que-modal" open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin tip="Завантаження" spinning={isLoading}>
        {ammError && <span className="error"> Не обрано відповідального</span>}
        <Input aria-label="write id user" className="amm" value={amm} />
        <Input
          aria-label="write fee amount to future contract"
          className="amp"
          placeholder="Яка була названа сума?"
          value={amp}
          onChange={(e) => setAmp(e.target.value)}
        />
        <Select
          showSearch
          className={'amr'}
          defaultValue={'0'}
          onChange={(value) => {
            setAmr(value);
          }}
        >
          {HOTLINE_RESULT.map((i, index) => (
            <Select.Option key={String(index)} value={String(index)}>
              {i}
            </Select.Option>
          ))}
        </Select>
        (Анкетування відправиться автоматично після підтвердження)
        <br />
        <br />
        {(resultAmount === modalData?.sugarAmount || (resultAmount === 0 && !modalData?.sugarAmount)) && (
          <Card style={{ marginBottom: '20px', backgroundColor: '#e6ffed', border: '1px solid #b7eb8f' }}>
            <Alert message="Сума сахорку і послуги співпадає" type="success" showIcon />
            <div style={{ marginTop: 12 }}>
              <strong>№ сахорку:</strong> {sugarForHotline?.i ?? 'Відсутній'} |<strong> Сума:</strong> {modalData?.sugarAmount ?? 'Відсутня'}{' '}
              {CURRENCIES[sugarForHotline?.currency]?.symbol ?? ''}
            </div>
          </Card>
        )}
        {resultAmount !== modalData?.sugarAmount && !(resultAmount === 0 && !modalData?.sugarAmount) && (
          <Card style={{ marginBottom: '20px', backgroundColor: '#fff2f0', border: '1px solid #ff4d4f' }}>
            <Alert message="Сума сахорку і послуги не співпадає" type="error" showIcon />
            <div style={{ marginTop: 12 }}>
              <strong>№ сахорку:</strong> {sugarForHotline?.i ?? '-'} |<strong> Сума:</strong> {modalData?.sugarAmount ?? '-'}{' '}
              {CURRENCIES[sugarForHotline?.currency]?.symbol ?? ''}
              {' ≠ '} {resultAmount}
              {CURRENCIES[sugarForHotline?.currency]?.symbol ?? ''}
            </div>
            <Input
              aria-label="write payment reason"
              className="payment-reason"
              placeholder="Вкажіть причину неоплати або неспівпадіння суми"
              value={paymentReason}
              onChange={(e) => setPaymentReason(e.target.value)}
            />
          </Card>
        )}
        {showDeclineOptions && (
          <Box mt={20}>
            <ReasonsDecline data={_data} toggleCloseBtn={toggleCloseBtn} setIsLoading={setIsLoading} />
          </Box>
        )}
        {showReachOptions && (
          <Box mt={20}>
            <ReasonsReach data={_data} toggleCloseBtn={toggleCloseBtn} setIsLoading={setIsLoading} />
          </Box>
        )}
        {!(showReachOptions && showDeclineOptions) && (
          <Box mt={20}>
            <ControlBtns />
          </Box>
        )}
      </Spin>
    </Modal>
  );
};

export default HotlineQue;
