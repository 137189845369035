import { Form, Input, DatePicker } from 'antd';

import PHONE_VALIDATION from './phoneValidationConstant';

const PartnerAddInput = (props) => {
  const { label, name, required, isDateField, isPhoneField } = props;

  const DEFAULT_VALIDATION = [
    {
      required,
      message: `Заповніть поле ${label}!`,
    },
  ];

  return (
    <Form.Item label={label} name={name} rules={isPhoneField ? PHONE_VALIDATION : DEFAULT_VALIDATION}>
      {isDateField ? <DatePicker /> : <Input />}
    </Form.Item>
  );
};

export default PartnerAddInput;
