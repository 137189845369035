import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { JOURNAL_BADGE_COLORS } from 'common_constants/business';

import UserAvatar from '../../components/UserAvatar/UserAvatar';
import JournalCardBody from './JournalCardBody';

import './JournalCard.scss';

const JournalCard = (props) => {
  const { date, log } = props;
  const FILII = useSelector((state) => state.common.FILII);

  const userRole = log.user?.role;

  const hours = date.hour();
  const minutes = date.minute();
  const seconds = date.second();

  const time = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;

  let userRoleBadgeColor;

  switch (userRole) {
    case 'worker':
      userRoleBadgeColor = JOURNAL_BADGE_COLORS.GREEN;
      break;
    case 'rabfil':
      userRoleBadgeColor = JOURNAL_BADGE_COLORS.ORANGE;
      break;
    case 'manager':
      userRoleBadgeColor = JOURNAL_BADGE_COLORS.MAGENTA;
      break;
    case 'deputyManager':
      userRoleBadgeColor = JOURNAL_BADGE_COLORS.RED;
      break;
    case 'inspector':
      userRoleBadgeColor = JOURNAL_BADGE_COLORS.GEEKBLUE;
      break;
    case 'admin':
      userRoleBadgeColor = JOURNAL_BADGE_COLORS.PURPLE;
      break;

    default:
      break;
  }

  return (
    <div className="journal-card-wrapper">
      <div className="journal-card-time">{time}</div>
      <div className="journal-card">
        <div className="journal-card-user-info">
          <div className="journal-card-avatar-inner">
            <UserAvatar user={log.user} size={50} className="journaj-card-avatar" />
          </div>
          {log.user ? (
            <div className="journal-card-user">
              <div className="journal-card-user__name">{log.user.name}</div>
              <div className="journal-card-user__role">
                <Tag color={userRoleBadgeColor} style={{ margin: '0' }}>
                  {log.user.role}
                </Tag>
              </div>
              <div className="journal-card-user__fil">{FILII[log.user.fil]} </div>
            </div>
          ) : (
            <div className="journal-card-user">Користувача не знайдено</div>
          )}
          <div className="journal-card-action">
            {log.action} {log?.approved && <span className="status">(Змінив статус)</span>}
          </div>
        </div>
        <JournalCardBody log={log} />
      </div>
    </div>
  );
};

export default JournalCard;
