const updateAssignmentsListWithData = (assignmentsList, assignmentId, updatedData, sugarData) => {
  const foundIndex = assignmentsList.findIndex((assignment) => assignment._id === assignmentId);
  if (foundIndex === -1) {
    return assignmentsList;
  }
  const currentAssignment = assignmentsList[foundIndex];
  const updatedAssignment = {
    ...currentAssignment,
    ...updatedData,
    sugar: {
      amounts: sugarData ? [...currentAssignment.sugar.amounts, sugarData.amount] : currentAssignment.sugar.amounts,
      indexes: sugarData ? [...currentAssignment.sugar.indexes, sugarData.index] : currentAssignment.sugar.indexes,
    },
  };
  const updatedAssignments = [...assignmentsList];
  updatedAssignments[foundIndex] = updatedAssignment;

  return updatedAssignments;
};

export default updateAssignmentsListWithData;
