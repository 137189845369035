import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import clsx from 'clsx';

const CategoriesListItem = ({ handleCategoryClick, handleUpdateCategory, handleDeleteCategory, category, isSelected }) => {
  return (
    <div key={category.i} className={clsx('list-item', { selected: isSelected })} onClick={(e) => handleCategoryClick(e, category)}>
      <Typography.Text className="item-title">{category.title.ua}</Typography.Text>
      <div className="item-actions">
        <Button className="edit" onClick={(e) => handleUpdateCategory(e, category)}>
          <EditOutlined />
        </Button>
        <Button className="delete" onClick={(e) => handleDeleteCategory(e, category._id)}>
          <DeleteOutlined />
        </Button>
      </div>
    </div>
  );
};

export default CategoriesListItem;
