import React, { useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setModal,
  addCorrespondenceItemPage,
  addCorrespondenceItemModalForTheContract,
  addArrAddressees,
  setCurrentCorrespondence,
  editCorrespondenceItemPage,
  editCorrespondenceItemModalForTheContract,
} from '../../store/commonReducer';

import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  Modal,
  Spin,
  Typography,
  InputNumber,
  Radio,
  Switch,
  Card,
  DatePicker,
  Popconfirm,
  Alert,
  Space,
} from 'antd';
import { FileAddOutlined, CloseCircleOutlined, PlusOutlined, EditOutlined, EditTwoTone, FileAddTwoTone } from '@ant-design/icons';
import { orange } from '@ant-design/colors';

import { TYPES_OF_MAIL, ROLES } from 'common_constants/business';
import { request, error, success, warn, info } from '../../tools';
import { Box } from 'common_components';
import { ROUTES } from 'common_constants/routes';
import LibraryAvatarFile from '../../components/LibraryItem/Core/LibraryAvatarFile';
import PreviewImage from '../CorrespondenceModalForTheContract/Core/PreviewImage';

import './styles.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const CorrespondenceAddDocument = () => {
  const [dispatch, { pathname }, formRef, timeoutRef] = [useDispatch(), useLocation(), useRef(null), useRef(null)],
    [{ data, prev }, userAuth, arrAddressees, currentCorr, FILII] = [
      useSelector((state) => state.common.modal),
      useSelector((state) => state.common.userAuth),
      useSelector((state) => state.common.arrAddressees),
      useSelector((state) => state.common.currentCorrespondence),
      useSelector((state) => state.common.FILII),
    ];

  const styleFormDynamic = (st) => ({
    labelCol: { span: st ? 0 : 7 },
    wrapperCol: { span: st ? 0 : 14 },
  });

  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const currentClientName = currentCorr?.contract?.[0]?.client?.[0]?.n;
  const currentContractNumber = currentCorr?.ik >= 0;
  const iFromContractCard = data?.i >= 0;
  const currentContractfil = currentCorr?.fk;
  const fFromContractCard = data?.f;

  const core_megaState = {
      loading: false, //* Overall loading
      loadingAuditContract: false, //* Loading contract existence check result
      editItem: null, //* File editing
      auditСase: false, //* Case mark Exists?
      fileListReceipt: [], //* Receipt photo
      fileListDocument: [], //* List of files
      auditTypeDocument: undefined, //* Document type label
      auditFormDocument: undefined, //* Document form label
      auditPaymentReceipt: currentCorr?.j ? true : false, //* Receipt opening label
      auditReferral: currentCorr?.x ?? true, //* Label for directing to the client
      auditContractI: (currentContractNumber || iFromContractCard) ?? null, //* Contract existence check mark
      auditNameClient: currentClientName ?? null, //* Loaded client name
      auditFilContract: (currentContractfil || fFromContractCard) ?? null, //* Loaded client fil
      addresseesList: arrAddressees ?? [], //* List of addressees
      isNewAddressees: currentCorr?.h ? true : false, //* Dynamics label between list and creating a new addressee
    },
    [megaState, setMegaState] = useState(core_megaState);

  const [{ Item }, { Title, Text }, { TextArea }, [form]] = [Form, Typography, Input, Form.useForm()];

  const cancel = () => info('', 'Ви не згодні зі своїм рішенням.'),
    onCancel = (check) => {
      if (check !== 'success') cancel();
      dispatch(setModal(prev));
      dispatch(setCurrentCorrespondence(null));
    };

  const config_file_document = {
    multiple: true,
    onRemove: (fileToRemove) => {
      setMegaState((prevState) => ({
        ...prevState,
        fileListDocument: prevState.fileListDocument.filter((file) => file.uid !== fileToRemove.uid),
      }));
    },
    beforeUpload: async (file) => {
      const formatFile = file.name.split('.').pop(),
        validFormats = ['pdf', 'doc', 'docx', 'rtf', 'odt', 'png', 'jpg', 'jpeg', 'img'],
        isValidFormat = validFormats.includes(formatFile),
        isDocumentFormat = ['doc', 'docx', 'rtf', 'odt'].includes(formatFile);

      if (!isValidFormat) {
        warn('', 'Файл не вірного формату. Для запису дозволено тільки такі формати: pdf, doc, docx, rtf, odt, png, jpg, jpeg, img');
        return false;
      }

      if (isDocumentFormat && file.size > 2 * 1024 * 1024) {
        warn('', 'Розмір файлу повинен бути менше 2 мегабайт!');
        return false;
      }

      if (!file.name.includes('.')) {
        warn('', 'У файлі немає конкретного формату. Запис неможливий в такому випадку');
        return false;
      }

      await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          setMegaState((prevState) => ({
            ...prevState,
            fileListDocument: [...prevState.fileListDocument, file],
          }));
          resolve();
        };
        reader.readAsDataURL(file);
      });

      return false;
    },
  };

  const config_file_receipt = {
    maxCount: 1,
    onRemove: () => {
      setMegaState((prevState) => ({
        ...prevState,
        fileListReceipt: [],
      }));
    },
    beforeUpload: (file) => {
      const formatFile = file.name.split('.').pop();
      const validFormats = ['pdf', 'png', 'jpg', 'jpeg', 'img'];
      const isValidFormat = validFormats.includes(formatFile);

      if (!isValidFormat) {
        warn('', 'Файл не вірного формату. Для запису дозволено тільки такі формати: png, jpg, jpeg, img, pdf');
        return false;
      }

      if (!file.name.includes('.')) {
        warn('', 'У файлі немає конкретного формату. Запис неможливий в такому випадку');
        return false;
      }

      setMegaState((prevState) => ({
        ...prevState,
        fileListReceipt: [file],
      }));

      return false;
    },
  };

  const add_document = async (dataProps) => {
    const validation = (_o, _p) => {
      if (!_o) {
        warn('', 'Для додавання пошти, потрібно вказати напрямок пошти', { _o: !!_o });
        return;
      }

      if (!_p) {
        warn('', 'Для додавання пошти, потрібно вказати вид пошти.', { _p: !!_p });
        return;
      }

      return true;
    };

    const { b, o, j, p, tn, t, h, x, ik, m } = dataProps;

    const formData = new FormData();

    const [transferOldOrNewDataContractByEdit, transferNewDataContractByAddInPageCorrespondence, transferOldDataContractByAddInPageContract] = [
      { ik: currentCorr?.ik, ...(typeof currentCorr?.fk === 'number' ? { fk: currentCorr?.fk } : {}) },
      {
        ik: ik,
        ...(typeof megaState.auditFilContract === 'number' ? { fk: megaState.auditFilContract } : {}),
      },
      { ik: data?.i, fk: data?.f },
    ];

    const data_transfer = {
      ...(currentCorr //* Is this editing or main adding of mail?
        ? ik >= 0 //* Is there a data contract in the request check contract?
          ? transferNewDataContractByAddInPageCorrespondence
          : transferOldOrNewDataContractByEdit
        : data?.a //* Is page contract or page correspondence?
        ? transferNewDataContractByAddInPageCorrespondence
        : transferOldDataContractByAddInPageContract),
      b, //* Amount on the check
      x, //* Label for directing to the customer's personal account
      o, //* Mail direction
      a: currentCorr ? currentCorr.a : userAuth._id, //* Mail author
      ...(currentCorr ? { ac: userAuth._id } : {}), //* Editing author
      t: t ? t.toDate() : new Date(), //* Date of sending/receiving mail
      ...(currentCorr ? { tc: new Date() } : {}), //* Editing date
      j, //* Label - has a check
      p, //* Type of mail
      tn, //* Tracking number
      m, //* Comment
      h, //* Recipient
      checkReceipt: megaState.fileListReceipt.length, //* Number of uploaded checks
      checkDocument: megaState.fileListDocument.length, //* Number of uploaded documents
      checkAdressee: megaState.isNewAddressees, //* Label for type of recipient input
      currentCorr: JSON.stringify(currentCorr), //* Editing data (if available)
    };

    const check = () => {
      Object.entries(data_transfer).forEach(([key, value]) => {
        if (value === undefined) return;

        if (Array.isArray(value))
          return value.forEach((heg) => {
            formData.append(key, heg);
          });

        formData.append(key, value);
      });
    };

    if (megaState.fileListReceipt.length !== 0)
      megaState.fileListReceipt.forEach((file) => {
        formData.append(`files`, file);
      });

    if (megaState.fileListDocument.length !== 0)
      megaState.fileListDocument.forEach((file) => {
        formData.append(`files`, file);
      });

    if (megaState.fileListDocument.length <= 0 && !currentCorr) {
      warn('', 'Для додавання пошти, потрібно завантажити документи');
      return;
    }

    check();

    if (!validation(o, p)) return;

    setMegaState({ ...megaState, loading: true });

    await request.post(
      '/correspondence/addMail',
      formData,
      ({ cor, addr }) => {
        success('Успіх!', 'Документ успішно створено!');
        if (cor) {
          const corNew = megaState.auditNameClient ? { ...cor, contract: [{ client: [{ n: megaState.auditNameClient }] }] } : cor;

          currentCorr
            ? dispatch(
                {
                  [ROUTES.CORRESPONDENCE_PAGE]: editCorrespondenceItemPage({ ...corNew, _id: currentCorr?._id }),
                  [ROUTES.CONTRACTS]: editCorrespondenceItemModalForTheContract({ ...corNew, _id: currentCorr?._id }),
                }[pathname] || (() => {}),
              )
            : data?.a
            ? dispatch(addCorrespondenceItemPage(corNew))
            : dispatch(addCorrespondenceItemModalForTheContract(corNew));
        }
        if (addr) dispatch(addArrAddressees(addr));
        setMegaState({ ...megaState, fileListReceipt: [] });
        form.resetFields();
        const dynamic_modal = () =>
          ({
            [ROUTES.CONTRACTS]: dispatch(setModal(prev)),
            [ROUTES.CORRESPONDENCE_PAGE]: dispatch(setModal()),
          }[pathname] || dispatch(setModal()));
        dynamic_modal();
        onCancel('success');
      },
      (_, __, axiosResponse) => {
        error('Помилка!', axiosResponse.response.data.error);
      },
    );

    setMegaState({ ...megaState, loading: false });
  };

  const onContractId = useCallback((contractIndex) => {
    const funcLoad = (boolean) =>
      setMegaState((prevState) => ({
        ...prevState,
        loadingAuditContract: boolean,
      }));

    funcLoad(true);

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(async () => {
      if (typeof contractIndex !== 'number') return funcLoad(false);

      await request.post(
        '/correspondence/getContractByIndex',
        { contractIndex: contractIndex },
        ({ exists, nameClient, filContract }) => {
          setMegaState((prev) => ({ ...prev, auditContractI: exists, auditFilContract: filContract, auditNameClient: nameClient }));
          funcLoad(false);
        },
        (err) => {
          funcLoad(false);
          error(err);
        },
      );
    }, 1000);
  }, []);

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      open
      className="correspondence-add-document"
      title={
        <Title style={{ margin: 0 }} level={4}>
          {currentCorr ? <EditTwoTone style={{ fontSize: '22px' }} /> : <FileAddTwoTone style={{ fontSize: '22px' }} />}&nbsp;&nbsp;Форма для&nbsp;
          {currentCorr ? 'змінення' : 'додавання'} пошти
        </Title>
      }
      onCancel={onCancel}
      footer={null}
    >
      <Spin
        size="large"
        tip={megaState.fileListReceipt.length !== 0 ? 'Зачекайте, йде завантаження файлів' : 'Завантаження'}
        spinning={megaState.loading}
      >
        <Form {...styleFormDynamic()} name="validate_other" form={form} onFinish={add_document} ref={formRef}>
          {currentCorr && (
            <Item {...styleFormDynamic('standart')}>
              <Box>
                <Alert message="В цьому режимі не можна редагувати файли" type="info" showIcon />
              </Box>
            </Item>
          )}
          <Item
            name="o"
            label="Напрямок пошти:"
            {...(currentCorr ? { initialValue: +currentCorr.o } : {})}
            preserve={true}
            rules={[{ required: true, message: 'Направлення транзиту - обов`язкове поле!' }]}
          >
            <Radio.Group>
              <Radio value={1}>Вихідна</Radio>
              <Radio value={2}>Вхідна</Radio>
            </Radio.Group>
          </Item>
          <Item
            name="p"
            label="Вид пошти:"
            {...(currentCorr ? { initialValue: +currentCorr.p } : {})}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Вид пошти - обов`язкове поле!',
              },
            ]}
          >
            <Select optionFilterProp="label" allowClear options={TYPES_OF_MAIL} placeholder="Оберіть вид: " />
          </Item>
          {(data?.a || currentCorr?.ik) && (
            <Item
              name="ik"
              label="Номер договору:"
              {...(currentCorr ? { initialValue: +currentCorr.ik } : {})}
              preserve={true}
              rules={[{ required: true, message: 'Направлення транзиту - обов`язкове поле!' }]}
            >
              <InputNumber
                placeholder="2223"
                aria-label="We need to enter the input number contract of our future template document"
                min={0}
                step={100}
                style={{ width: '100%' }}
                onChange={onContractId}
              />
            </Item>
          )}
          {megaState.loadingAuditContract ? (
            <Spin className="spin-audit-contract" spinning={megaState.loadingAuditContract} />
          ) : (
            (megaState.auditContractI !== null || currentCorr?.ik) &&
            !data?.i && (
              <Alert
                style={{ marginBottom: '20px' }}
                message={`Договір${megaState.auditContractI ? '' : ' не'} знайдено`}
                description={
                  <>
                    {megaState.auditNameClient && (
                      <div>
                        <span>
                          <Text strong>ПІБ клієнта:</Text>&nbsp;
                          <Text>{megaState.auditNameClient}</Text>
                        </span>
                      </div>
                    )}
                    {typeof megaState.auditFilContract === 'number' && (
                      <div>
                        <span>
                          <Text strong>Філія договору:</Text>&nbsp;
                          <Text>{FILII[megaState.auditFilContract]}</Text>
                        </span>
                      </div>
                    )}
                  </>
                }
                type={megaState.auditContractI ? 'success' : 'warning'}
                showIcon
              />
            )
          )}
          <Item name={'isNewLawyer'} label="Новий адресат" initialValue={megaState.isNewAddressees}>
            <Switch checked={megaState.isNewAddressees} onChange={(val) => setMegaState((prev) => ({ ...prev, isNewAddressees: val }))} />
          </Item>
          <Item name="h" label="Адресат:" {...(currentCorr?.h ? { initialValue: currentCorr.h } : {})} preserve={true}>
            {{
              true: (
                <TextArea
                  placeholder="Створіть адресата"
                  autoSize
                  aria-label="We need to enter the other side on the check of our future template document"
                />
              ),
              false: (
                <Select
                  showSearch
                  style={{
                    width: '100%',
                  }}
                  placeholder="Оберіть адресата"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  sorter={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  allowClear
                >
                  {megaState.addresseesList?.map((value, step) => (
                    <Select.Option key={step} value={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              ),
            }[megaState.isNewAddressees] || 'Bad code'}
          </Item>
          <Item name="t" label="Дата:" {...(currentCorr?.t ? { initialValue: dayjs(currentCorr?.t) } : {})}>
            <DatePicker disabledDate={(current) => current && current > dayjs().endOf('day')} format={'DD MMMM YYYY року'} />
          </Item>
          <Item
            name="j"
            label="Є чек?"
            initialValue={megaState.auditPaymentReceipt}
            style={{
              marginTop: 8,
            }}
            valuePropName="checked"
          >
            <Switch checked={megaState.auditPaymentReceipt} onChange={(value) => setMegaState({ ...megaState, auditPaymentReceipt: value })} />
          </Item>
          {megaState.auditPaymentReceipt && (
            <Item {...styleFormDynamic('standart')}>
              <Card title="Чек" size="small">
                {!currentCorr ? (
                  <Item
                    label={<span style={{ whiteSpace: 'normal' }}>Завантажити фото чека</span>}
                    valuePropName="fileListReceipt"
                    getValueFromEvent={(e) => {
                      if (Array.isArray(e)) return e;
                      return e?.fileListReceipt;
                    }}
                    {...styleFormDynamic()}
                  >
                    <Upload {...(currentCorr ? { disabled: true } : {})} {...config_file_receipt} listType="picture-card">
                      <div>
                        <PlusOutlined />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Завантажити
                        </div>
                      </div>
                    </Upload>
                  </Item>
                ) : (
                  currentCorr?.ch?.rc?.L && (
                    <Item label="Фото чеку:" {...styleFormDynamic()}>
                      <Space size={[0, 8]} wrap>
                        {{
                          pdf: <LibraryAvatarFile item={currentCorr?.ch?.rc?.fd} />,
                          png: <PreviewImage item={currentCorr?.ch?.rc?.L} />,
                          jpg: <PreviewImage item={currentCorr?.ch?.rc?.L} />,
                          jpeg: <PreviewImage item={currentCorr?.ch?.rc?.L} />,
                          img: <PreviewImage item={currentCorr?.ch?.rc?.L} />,
                        }[currentCorr?.ch?.rc?.fd] || 'Є'}
                      </Space>
                    </Item>
                  )
                )}

                <Item
                  name="tn"
                  label="Трек-номер:"
                  {...(currentCorr?.ch?.tn ? { initialValue: currentCorr?.ch?.tn } : {})}
                  preserve={true}
                  {...styleFormDynamic()}
                >
                  <Input
                    placeholder="TBA094490876000"
                    aria-label="We need to enter the track number of our future template document"
                    style={{ width: '100%' }}
                  />
                </Item>
                <Item
                  name="b"
                  label="Сума:"
                  {...(currentCorr?.ch?.b ? { initialValue: +currentCorr?.ch?.b } : {})}
                  preserve={true}
                  {...styleFormDynamic()}
                >
                  <InputNumber
                    onKeyDown={handleKeyPress}
                    disabled={currentCorr && !inspector} //* При редагування, для менеджерів повинно бути заблоковано
                    placeholder="300"
                    aria-label="We need to enter the check amount of our future template document"
                    min={0}
                    step={100}
                    style={{ width: '100%' }}
                  />
                </Item>
              </Card>
            </Item>
          )}
          {!currentCorr ? (
            <Item
              label={
                <span style={{ whiteSpace: 'normal' }}>
                  Завантажити документи <span style={{ color: 'red' }}>*</span>
                </span>
              }
              valuePropName="fileListDocument"
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) return e;
                return e && e.fileListDocument ? e.fileListDocument : [];
              }}
            >
              <Upload {...config_file_document}>
                <Button
                  {...(currentCorr ? { disabled: true } : {})}
                  style={{
                    marginTop: 8,
                  }}
                  icon={<PlusOutlined />}
                >
                  Завантажити
                </Button>
              </Upload>
            </Item>
          ) : (
            <Item label="Передані документи:">
              <Text>&nbsp;{currentCorr.df?.length || 'Немає'}</Text>
            </Item>
          )}
          <Item name="m" label="Коментар:" {...(currentCorr?.m ? { initialValue: currentCorr?.m } : {})} preserve={true}>
            <TextArea
              placeholder="Введіть коментар"
              autoSize
              aria-label="We need to enter the other side on the check of our future template document"
            />
          </Item>
          <Item
            name="x"
            label={<span style={{ whiteSpace: 'normal' }}>Направлення в особистий кабінет клієнта</span>}
            initialValue={megaState.auditReferral}
          >
            <Switch disabled checked={megaState.auditReferral} onChange={(value) => setMegaState({ ...megaState, auditReferral: value })} />
          </Item>
          <Box className="group-button">
            <Popconfirm
              title="Підтвердження"
              description="Ви впевнені, що хочете записати цю пошту?"
              onConfirm={() => formRef.current.submit()}
              onCancel={cancel}
              className="group-button__btn"
              okText="Гаразд"
              cancelText="Скасувати"
              disabled={data?.a && !megaState.auditContractI} //* Сторінка кореспонденція && не зайшов договір
            >
              <Button
                disabled={(data?.a || currentCorr) && !megaState.auditContractI} //* Сторінка кореспонденція && не зайшов договір
                className="group-button__btn"
                type="primary"
                style={{
                  marginRight: '15px',
                  ...(currentCorr && megaState.auditContractI ? { backgroundColor: orange.primary } : {}),
                }}
                icon={currentCorr ? <EditOutlined /> : <FileAddOutlined />}
              >
                {currentCorr ? 'Змінити' : 'Створити'}
              </Button>
            </Popconfirm>
            <Button icon={<CloseCircleOutlined />} className="group-button__btn" danger type="primary" onClick={onCancel}>
              Скасувати
            </Button>
          </Box>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CorrespondenceAddDocument;
