import React from 'react';
import { Card, Input, Button } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

const ContractsSearchByClient = (props) => {
  const { filtersByClient, setFiltersByClient, name, setName, phone, handlePhoneNumberInput, searchByClient } = props;

  return (
    <Card className="filtersContainer">
      <div className="row align no_selection filters" onClick={() => setFiltersByClient((prev) => !prev)}>
        {filtersByClient ? <DownOutlined /> : <RightOutlined />} <h2>Пошук за клієнтом:</h2>
      </div>
      {filtersByClient && (
        <>
          <div className="row">
            <div>
              <h4>ПІБ: </h4>
              <Input aria-label="write name client" value={name} placeholder="ПІБ" onChange={(e) => setName(e.target.value)} />
            </div>

            <div>
              <h4>Телефон (0501234567): </h4>
              <Input aria-label="write phone client" value={phone} placeholder="Номер" onChange={(e) => handlePhoneNumberInput(e)} />
            </div>
          </div>
          <Button type="primary" onClick={searchByClient} style={{ marginLeft: 16, marginBottom: 16 }}>
            Пошук
          </Button>
        </>
      )}
    </Card>
  );
};

export default ContractsSearchByClient;
