import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Spin, Popconfirm, InputNumber, Select, DatePicker, Space } from 'antd';
import { setModal, setContractsList } from '../../store/commonReducer';
import SearchSelect from '../../components/SearchSelect';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import './styles.scss';
import { request, warn, error, info, success } from '../../tools';

const MonthlyPaymentContract = () => {
  const dispatch = useDispatch();

  const [data, list, bankCards] = [
    useSelector((state) => state.common.modal.data),
    useSelector((state) => state.common.contracts),
    useSelector((state) => state.common.bankCards),
  ];

  const [date, setDate] = useState(dayjs(new Date()));
  const [loading, setLoading] = useState(false);
  const [numberOfMonthsState, setNumberOfMonthsState] = useState(data.pm ? data.pm : ''); // Кількість місяців Вибрані
  const [feeAmountState, setFeeAmountState] = useState(data.pa ? Number(data.pa) : 0); // Сума гонорару Вибрані
  const [dataCardState, setDataCardState] = useState(data.mp ? data.mr : []); // Реквізити Вибрані

  const onCancel = () => {
    dispatch(setModal());
    cancel();
  };

  const onDateChange = (value) => setDate(value);

  const ononConfirmPopconfirm = async () => {
    const validation = () => {
      if (!date.$D || !date.$d) {
        warn('', 'Для розсилки потрібно вказати дату початку цієї розсилки.', { '!date.$D': !date.$D, '!date.$d': !date.$d });
        return;
      }

      if (!numberOfMonthsState || numberOfMonthsState === '') {
        warn('', 'Для розсилки потрібно вказати кількість місяців більше за 0.', {
          '!numberOfMonthsState': !numberOfMonthsState,
          'numberOfMonthsState === "" ': numberOfMonthsState === '',
        });
        return;
      }

      if (feeAmountState <= 0) {
        warn('', 'Для розсилки потрібно вказати суму гонорару більше за 0.', { 'feeAmountState <= 0': feeAmountState <= 0 });
        return;
      }

      if (!dataCardState || dataCardState === '' || dataCardState.length === 0) {
        warn('', 'Для розсилки потрібно вказати хоча б 1 реквізит.', {
          '!dataCardState': !dataCardState,
          'dataCardState === ""': dataCardState === '',
          'dataCardState.length === 0': dataCardState.length === 0,
        });
        return;
      }

      return true;
    };

    if (!validation()) return;

    setLoading(true);

    await request.post(
      '/contracts/toMonthlyPayment',
      {
        _id: data._id, // id контракта
        C: data.C, // id клієнта
        mp: true, // мітка розсики 30 днів
        mpD: date.$D, // день початку
        mpd: date.$d, // дата початку
        mg: feeAmountState, // місячна сума гонорару
        mr: dataCardState, // реквізити
        pm: numberOfMonthsState, // кількість місяців
      },
      () => {
        success();

        dispatch(
          setContractsList(
            list.map((i) =>
              i._id === data._id
                ? {
                    ...i,
                    mp: true, // мітка розсики 30 днів
                    mpD: date.$D, // день початку
                    mpd: date.$d, // дата початку
                    pm: numberOfMonthsState, // кількість місяців гонорару
                    mg: feeAmountState, // місячна сума
                    mr: dataCardState, // реквізити
                  }
                : i,
            ),
          ),
        );
        dispatch(setModal());
        numberOfMonthsState < 13
          ? info(
              '',
              `Розсилка буде працювати лише ${
                numberOfMonthsState + (numberOfMonthsState <= 1 ? ' місяць' : numberOfMonthsState <= 4 ? ' місяця' : ' місяців')
              }`,
            )
          : info('', 'Розсилка буде працювати нескінченно');
      },

      error,
    );

    setLoading(false);
  };

  const cancel = () => info('', 'Ви не згодні зі своїм рішенням.');

  const cardArr =
    bankCards
      ?.map((i) => (i = { value: i.i, label: i.n }))
      ?.slice()
      ?.sort((a, b) => {
        const [labelA, labelB] = [a.n?.toLowerCase(), b.n?.toLowerCase()];
        return labelA?.localeCompare(labelB);
      }) || [];

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      open
      className="monthly-payment-contract"
      title={'Запуск щомісячної розсилки про щомісячну сплату по договору'}
      onCancel={onCancel}
      footer={null}
    >
      <Spin tip="Завантаження" spinning={loading}>
        <Space direction="vertical" className="space">
          <div>
            Обрати дату початку розсилки: <br />
            <DatePicker locale={locale} defaultValue={dayjs(date, 'DD/MM/YYYY')} fullscreen={false} format={'DD/MM/YYYY'} onSelect={onDateChange} />
          </div>
          <div className="list-row">
            Кількість місяців:
            <br />
            <SearchSelect
              className="select-input"
              list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'Бескінечно']}
              value={numberOfMonthsState}
              placeholder="Оберіть кількість місяців: "
              onChange={(v) => setNumberOfMonthsState(v)}
            />
          </div>
          <div className="list-row">
            Сума гонорару:
            <br />
            <InputNumber
              onKeyDown={handleKeyPress}
              className="select-input input-number"
              min={0}
              step={1000}
              defaultValue={feeAmountState}
              onChange={(v) => setFeeAmountState(v)}
            />
          </div>
          <div className="list-row">
            Реквізити юриста:
            <br />
            <Select
              optionFilterProp="label"
              mode="multiple"
              allowClear
              className="select-input"
              options={cardArr}
              value={dataCardState}
              placeholder="Оберіть реквізити: "
              onChange={(v) => setDataCardState(v)}
            />
          </div>
          <br />
        </Space>
        <div className="group-button">
          <Popconfirm
            title="Підтвердження"
            description="Ви впевнені, що хочете запустити місячну розсилку?"
            onConfirm={() => ononConfirmPopconfirm()}
            onCancel={cancel}
            okText="Гаразд"
            cancelText="Скасувати"
          >
            <Button
              disabled={
                !numberOfMonthsState ||
                numberOfMonthsState === '' ||
                !date.$D ||
                !date.$d ||
                feeAmountState <= 0 ||
                !dataCardState ||
                dataCardState === '' ||
                !dataCardState.length
                  ? true
                  : false
              }
              type="primary"
              className="monthly-payment-btn"
            >
              Створити розсилку
            </Button>
          </Popconfirm>
          <Button danger type="primary" className="monthly-payment-btn" onClick={onCancel}>
            Скасувати
          </Button>
        </div>
        <br />
        <h6>
          *Кнопка розсилки буде неактивною, поки не будуть заповнені всі поля правильно. Будьте уважні, бо введені зараз дані редагують дані
          контракту.
        </h6>
        <h6>
          **Для скасовування розсилки, треба додати договір до архіву, або натиснути кнопку "Відмінна щомісячної розсилки". Розсилка також перестає
          працювати, якщо кількість розсилок перевалила кількість вказаних місяців для неї.
        </h6>
      </Spin>
    </Modal>
  );
};

export default MonthlyPaymentContract;
