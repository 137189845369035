import React, { useMemo } from 'react';
import { Row } from 'antd';
import './WalletTotalAmount.scss';
import { CURRENCY_CODE_MAP } from 'common_constants/business';

const options = { style: 'currency', currency: 'UAH' };

const WalletTotalAmount = ({ filials = [], exchangeRates = [] }) => {
  const convertToUAH = (amount, currency) => {
    if (currency === 'UAH') return amount;
    const ourExchange = exchangeRates.find((rate) => CURRENCY_CODE_MAP[currency] === rate.currencyCodeA);
    return ourExchange ? amount * (ourExchange.rateBuy || ourExchange.rateCross) : amount;
  };

  const total = useMemo(() => {
    return filials.reduce((sum, fil) => {
      const totalBalanceInUAH = convertToUAH(fil?.totalBalance?.total || 0, fil?.currency || 'UAH');
      return sum + totalBalanceInUAH;
    }, 0);
  }, [filials, exchangeRates]);

  return (
    <Row className="walletTotalAmount">
      <Row></Row>
      <Row>{total.toLocaleString('uk-UA', options)}</Row>
      <Row></Row>
    </Row>
  );
};

export default React.memo(WalletTotalAmount);
