import { SearchOutlined } from '@ant-design/icons';
import { Button, Collapse, DatePicker, InputNumber, Row, Select, Switch } from 'antd';
import { ASSIGNMENTS_SERVICES_TYPE, ASSIGNMENT_SERVICE, LAW_BLOCKS, ROLES } from 'common_constants/business';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './AssignmentSearch.scss';
import Item from 'antd/es/list/Item';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const AssignmentsSearch = ({
  dateRange,
  setDateRange,
  onlyArchived,
  setOnlyArchived,
  filFilter,
  blockFilter,
  onChangeFil,
  onChangeBlock,
  courtFilter,
  onChangeCourt,
  serviceTypeFilter,
  setServiceTypeFilter,
  contractNumberFilter,
  setContractNumberFilter,
  handleClearFilters,
  handleSearchAssignments,
  onChangePage,
}) => {
  const courtsList = useSelector((state) => state.common.courtsList);
  const userAuth = useSelector((state) => state.common.userAuth);
  const FILII = useSelector((state) => state.common.FILII);

  const [isExpanded, setIsExpanded] = useState(false);

  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const handleCollapseChange = () => setIsExpanded(!isExpanded);

  const handleSearch = () => {
    onChangePage(1);
    handleSearchAssignments();
  };

  return (
    <Collapse className="assignment-filter-colapse" in={isExpanded} onChange={handleCollapseChange}>
      <Panel header="Пошук" key={'search'}>
        <Row className="assignment-filter-items" justify={'space-between'}>
          <Row className="assignment-filter__item" align={'middle'}>
            <Row>Філія:&nbsp;</Row>
            <Select
              disabled={!inspector}
              showSearch
              filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={filFilter}
              onChange={onChangeFil}
            >
              <Select.Option value="">Всі філії</Select.Option>
              {FILII.map((i, index) => (
                <Select.Option key={i} value={String(index)}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Row>

          {/* <Row className="assignment-filter__item" align={'middle'}>
            <Row>Блок:&nbsp;</Row>
            <Select
              disabled={!inspector}
              showSearch
              filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={blockFilter}
              onChange={onChangeBlock}
            >
              <Select.Option value="">Усі Блоки</Select.Option>
              {Object.keys(LAW_BLOCKS).map((key) => (
                <Select.Option key={key} value={key}>
                  {LAW_BLOCKS[key]}
                </Select.Option>
              ))}
            </Select>
          </Row> */}

          <Row className="assignment-filter__item" align={'middle'}>
            <Row>Тип послуги:&nbsp;</Row>
            <Select value={serviceTypeFilter} onSelect={(v) => setServiceTypeFilter(v)}>
              <Select.Option value="">Всі</Select.Option>
              {Object.keys(ASSIGNMENTS_SERVICES_TYPE).map((key) => (
                <Select.Option key={key} value={key}>
                  {ASSIGNMENTS_SERVICES_TYPE[key]}
                </Select.Option>
              ))}
            </Select>
          </Row>

          {/* <Row className="assignment-filter__item" align={'middle'}>
            <Row>Місце:&nbsp;</Row>
            <Select
              showSearch
              disabled={serviceTypeFilter !== ASSIGNMENT_SERVICE.GOING_TO_COURT}
              value={courtFilter}
              onSelect={onChangeCourt}
              placeholder="Виберіть суд"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              sorter={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
              <Select.Option value="">Всі</Select.Option>
              {courtsList?.map((court) => (
                <Select.Option key={court._id} value={court.i}>
                  {court.name}
                </Select.Option>
              ))}
            </Select>
          </Row> */}

          <Row className="assignment-filter__item" align={'middle'}>
            <Row>Номер договору: &nbsp;</Row>
            <InputNumber value={contractNumberFilter} onChange={(v) => setContractNumberFilter(v)} />
          </Row>
        </Row>

        <br />

        <Row className="assignment-filter__item" justify={'center'} align={'middle'}>
          <Row>Шукати серед архівних: </Row>
          <Switch value={onlyArchived} onChange={(v) => setOnlyArchived(v)} checkedChildren="Фільтр увімкнено" unCheckedChildren="Фільтр вимкнено" />
        </Row>

        <br />

        <Row className="assignment-filter__item" justify={'center'} align={'middle'}>
          <Row>Період: </Row> &nbsp;
          <RangePicker value={dateRange} onChange={setDateRange} format="DD.MM.YYYY" />
        </Row>

        <br />

        <Row justify={'center'}>
          <Button className="assignment-filter__button" onClick={handleClearFilters}>
            Очистити
          </Button>
          <Button icon={<SearchOutlined />} className="assignment-filter__button" type="primary" onClick={handleSearch}>
            Пошук
          </Button>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default AssignmentsSearch;
