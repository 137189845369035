import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Button, InputNumber } from 'antd';
import { setModal, editContract } from '../../store/commonReducer';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { error, request, success } from '../../tools';

import SearchSelect from '../../components/SearchSelect';
const ContractPayrollPercents = () => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.common.users);
  const activeUsers = Object.values(users).filter((user) => !user.fired);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { data } = useSelector((state) => state.common.modal);
  const contract = data?.contract;
  const manager = activeUsers.find((user) => +user?.fil === contract?.f && user?.role === 'manager');

  const onFieldValueChange = () => {
    const updatedValues = form.getFieldsValue();
    updatedValues.usersPercents = updatedValues.usersPercents.map((item) => {
      if (item.user === manager?._id) {
        let newCashPercent = manager?.cashPercent;
        let newFopPercent = manager?.fopPercent;
        updatedValues.usersPercents.forEach(({ user, cashPercent, fopPercent }) => {
          if (user !== item?.user) {
            newCashPercent -= cashPercent || 0;
            newFopPercent -= fopPercent || 0;
          }
        });
        return {
          ...item,
          cashPercent: newCashPercent,
          fopPercent: newFopPercent,
        };
      }
      return item;
    });
    form.setFieldsValue(updatedValues);
  };

  const calculatePercents = (manager = {}) => {
    if (contract?.usersPercents) {
      return contract?.usersPercents;
    }
    if (contract?.us === manager?._id) {
      return [{ user: manager._id, cashPercent: manager.cashPercent, fopPercent: manager.fopPercent }];
    }
    const userSign = activeUsers.find((i) => i._id === contract?.us);
    const managerCashPercent = (manager?.cashPercent || 0) - (userSign?.cashPercent || 0);
    const managerFopPercent = (manager?.fopPercent || 0) - (userSign?.fopPercent || 0);
    return [
      { user: manager._id, cashPercent: managerCashPercent, fopPercent: managerFopPercent },
      { user: userSign._id, cashPercent: userSign.cashPercent, fopPercent: userSign.fopPercent },
    ];
  };

  const fields = calculatePercents(manager);

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      contractId: contract._id,
      managerId: manager._id,
      ...values,
    };

    request.post(
      '/contracts/setUsersPercents',
      data,
      (res) => {
        if (res.status) {
          success('Успіх', 'Відсотки сплачені успішно!');
          dispatch(setModal());
          dispatch(editContract({ ...contract, ...values }));
          setLoading(false);
        }
      },
      () => {
        error('Помилка, спробуйте ще раз');
        setLoading(false);
      },
    );
  };

  return (
    <Modal open title={'Менеджмент відсотків'} onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          initialValues={{
            usersPercents: fields,
          }}
          autoComplete="off"
          form={form}
          onChange={onFieldValueChange}
          layout="vertical"
        >
          <Form.List name="usersPercents">
            {(data, { add, remove }) => (
              <>
                {data.map(({ key, name, ...restField }, index) => (
                  <div style={{ border: '1px solid #d9d9d9', borderRadius: 5, margin: 5, padding: 20 }} key={key}>
                    <Form.Item
                      {...restField}
                      label="Користувач"
                      name={[name, 'user']}
                      rules={[
                        {
                          required: true,
                          message: 'Оберіть користувача',
                        },
                      ]}
                    >
                      <SearchSelect
                        list={activeUsers.map((i) => ({ value: i._id, label: `${i.name} - ${i.p} ` }))}
                        placeholder="Користувач"
                        valueName="value"
                        labelName="label"
                        disabled={manager?._id === fields[index]?.user}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="% Готівка, карта передаст та ФОП"
                      name={[name, 'cashPercent']}
                      type="number"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          message: 'Не вірні значення',
                          validator: (_, value) => {
                            if (+value >= 0 && +value <= +manager?.cashPercent) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject();
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        value={fields[index]?.cashPercent}
                        style={{ width: '100%' }}
                        min={0}
                        placeholder="%"
                        disabled={manager?._id === fields[index]?.user}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="% Карта ГО, БН"
                      name={[name, 'fopPercent']}
                      type="number"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          message: 'Не вірні значення',
                          validator: (_, value) => {
                            if (+value >= 0 && +value <= +manager?.fopPercent) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject();
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        value={fields[index]?.fopPercent}
                        style={{ width: '100%' }}
                        min={0}
                        placeholder="%"
                        disabled={manager?._id === fields[index]?.user}
                      />
                    </Form.Item>
                    {manager?._id !== fields[index]?.user && (
                      <Button
                        type="dashed"
                        danger
                        onClick={() => {
                          remove(name);
                          onFieldValueChange();
                        }}
                        block
                        icon={<MinusCircleOutlined />}
                      >
                        Видалити користувача
                      </Button>
                    )}
                  </div>
                ))}
                <Form.Item style={{ marginTop: 20 }}>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Додати користувача
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ContractPayrollPercents;
