import React, { useState, useEffect } from 'react';
import { Button, Divider } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { PaymentManagementBox } from 'common_components';
import { CURRENCIES, PAYMENT_STATUS } from 'common_constants/business';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { editContract } from '../../store/commonReducer';
import { request, error, success } from '../../tools';

import './PaymentManagementComponent.scss';
import { translation } from 'common_constants/translation';

const isPastDate = (date) => {
  return dayjs(date).startOf('day').isBefore(dayjs());
};

const PaymentManagementComponent = ({
  paymentManagement,
  setPaymentManagement,
  monthlyPayments,
  setMonthlyPayments,
  totalInstallment,
  setTotalInstallment,
  paymentManagementForm,
  contract,
  cancelEdit,
  setLoading,
  isNewContract,
  setRemainingMoney,
  loading,
  client,
  setTotalPayByClient = () => {},
  currentCurrency,
}) => {
  const dispatch = useDispatch();
  const [isPaidStatus, setIsPaidStatus] = useState({
    firstPayment: false,
    monthlyPayments: [],
    paymentManagement: [],
    assignments: [],
  });

  const assignments = contract?.assignments?.filter((item) => !item?.incorrect && dayjs(item?.date).startOf('day') > dayjs(contract?.md)) || [];

  // const generatePaymentManagement = () => {
  //   const leftToPay = paymentManagementForm?.pa - (paymentManagementForm?.fp || 0);
  //   const months = paymentManagementForm?.installmentMonths;
  //   const monthlyPayment = leftToPay / months;
  //   const paymentManagement = [];
  //   for (let i = 1; i <= months; i++) {
  //     if (!leftToPay || leftToPay < 0) return;

  //     const paymentManagementItem = {
  //       amount: Math.round(monthlyPayment),
  //       date: dayjs(paymentManagementForm?.md).add(i, 'month').toDate(),
  //     };

  //     paymentManagement.push(paymentManagementItem);
  //   }

  //   setPaymentManagement(paymentManagement);
  // };

  // const generateMonthlyPaymentsManagement = () => {
  //   const paymentsArr = [];
  //   if (paymentManagementForm?.monthlyFee) {
  //     const length = dayjs().diff(dayjs(paymentManagementForm?.md).startOf('month'), 'month');
  //     for (let i = 1; i <= length + 3; i++) {
  //       paymentsArr.push({ date: dayjs(paymentManagementForm?.md).add(i, 'month').toDate(), amount: Number(paymentManagementForm?.monthlyFee) });
  //     }
  //   }

  //   setMonthlyPayments(paymentsArr);
  // };

  const countTotalInstallment = () => {
    const paymentSum = paymentManagement.reduce((acc, item) => acc + +item.amount, 0);
    const sum = (+paymentManagementForm?.pa || 0) - (paymentManagementForm?.fp || 0) - paymentSum;
    setTotalInstallment(sum);
  };

  const fillPaymentManagement = (regenerate = false) => {
    setLoading(true);
    const onSuccess = (res) => {
      if (res.paymentManagement) {
        setPaymentManagement(res.paymentManagement);
        dispatch(editContract({ ...contract, paymentManagement: res.paymentManagement }));
        success('Менеджер платежів заповнено');
        setLoading(false);
      }
    };
    const onError = () => {
      error('Помилка, спробуйте ще раз');
      setLoading(false);
    };
    request.post('/contracts/fillPaymentManagement', { _id: contract._id, regenerate }, onSuccess, onError);
  };

  const addMonth = () => {
    setPaymentManagement([
      ...paymentManagement,
      {
        amount: 0,
        date: dayjs(paymentManagement[paymentManagement.length - 1].date)
          .add(1, 'month')
          .toISOString(),
      },
    ]);
  };

  const editPaymentManagement = () => {
    setLoading(true);
    const onSuccess = () => {
      const data = { ...contract, paymentManagement };
      dispatch(editContract(data));
      success('Менеджер платежів змінено');
      setLoading(false);
      countRemainingMoney();
    };
    const onError = (err) => {
      error('Помилка, спробуйте ще раз');
      setLoading(false);
    };
    request.post('/contracts/editPaymentManagement', { _id: contract._id, paymentManagement }, onSuccess, onError);
  };

  const firstPaymentBox = () => {
    if (!paymentManagementForm?.fp || +paymentManagementForm?.fp === 0) return null;
    const firstPayment = Number(paymentManagementForm?.fp) || 0;
    const firstPaymentDate = paymentManagementForm?.md || paymentManagementForm?.sd;
    const isPastItemDate = isPastDate(firstPaymentDate);
    let style =
      isPaidStatus.firstPayment && isPastItemDate
        ? { background: 'lightgreen' }
        : !isPaidStatus.firstPayment && isPastItemDate
        ? { background: 'lightcoral' }
        : {};

    return <PaymentManagementBox key={firstPaymentDate} amount={firstPayment} date={firstPaymentDate} style={style} disabled />;
  };

  const countRemainingMoney = () => {
    setLoading(true);

    const reqData = {
      contractI: contract?.i,
    };

    request.post(
      '/contracts/paymentManagementRemainingMoneyCrm',
      reqData,
      ({ data }) => {
        setIsPaidStatus(data?.paymentManagementPaid);
        setRemainingMoney(data?.remainingMoney);
        setTotalPayByClient(data?.totalClientPaid);
        setLoading(false);
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  useEffect(() => {
    countTotalInstallment();
  }, [paymentManagement, monthlyPayments, paymentManagementForm?.pa]);

  useEffect(() => {
    if (!contract?.i) return;

    countRemainingMoney();
  }, [contract]);

  const firstPaymentBoxRender = firstPaymentBox();

  const [selectedMonthlyPaymentsBoxes, setSelectedMonthlyPaymentsBoxess] = useState([]);
  const [selectedAssignmentsBoxes, setSelectedAssignmentsBoxes] = useState([]);
  const [toPay, setToPay] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const addToPay = (amount) => {
    setToPay((prev) => prev + amount);
  };
  const removeToPay = (amount) => {
    setToPay((prev) => prev - amount);
  };

  const pay = async () => {
    setLoading(true);

    let totalAmount = 0;
    totalAmount += selectedMonthlyPaymentsBoxes.reduce((total, box) => total + Number(box.amount), 0);
    const withCashback = selectedMonthlyPaymentsBoxes.every((box) => dayjs().isBefore(dayjs(box.date).startOf('day')));

    const paymentData = {
      clientId: contract?.C,
      amount: totalAmount,
      contractId: contract?._id,
      contractIndex: contract?.i,
      fil: contract?.f,
      withCashback,
      currency: currentCurrency,
      payFor: {
        monthlyPayments: selectedMonthlyPaymentsBoxes,
        assignments: selectedAssignmentsBoxes,
      },
    };

    const handlePaymentStatus = (res, timerId) => {
      if (res?.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        clearInterval(timerId);
        setLoading(false);
        countRemainingMoney();
        success(translation.paymentSuccess['ua']);
      } else if (res?.paymentStatus === PAYMENT_STATUS.FAILURE) {
        clearInterval(timerId);
        setLoading(false);
        error(translation.paymentError['ua']);
      }
    };

    const checkPaymentStatus = (invoiceId) => {
      const timerId = setInterval(() => {
        request.post('/payment/mono/status', { invoiceId }, (res) => handlePaymentStatus(res, timerId));
      }, 1000);
    };

    const onPaymentSuccess = (res) => {
      if (res?.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          countRemainingMoney();
          setLoading(false);
          success(translation.paymentSuccess['ua']);
        }, 2000);
      } else if (res?.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        checkPaymentStatus(res.invoiceId);
      } else if (res?.paymentStatus === PAYMENT_STATUS.FAILURE) {
        setLoading(false);
        error(translation.paymentError['ua']);
      } else {
        success(translation.paymentCheckoutUrlGenerated['ua']);
        setLoading(false);
      }
    };

    try {
      await request.post('/payment/mono/payContractInAdvance/link', paymentData, onPaymentSuccess);
    } catch (err) {
      error(translation.paymentError['ua']);
    } finally {
      setToPay(0);
      setSelectedMonthlyPaymentsBoxess([]);
    }
  };
  return (
    <div>
      <Divider orientation="left" plain>
        <b>Розстрочка</b>
      </Divider>
      {contract?.installmentMonths ? (
        <div className="payment-management">
          <div style={{ textAlign: 'right', margin: 20 }}>
            <Button disabled={isNewContract} type="primary" onClick={() => fillPaymentManagement(true)}>
              Перегенерувати
            </Button>
          </div>
          <div className="payment-management-block">
            {paymentManagement?.map((item, index) => {
              const isPaid = isPaidStatus.paymentManagement?.[index];
              const isPastItemDate = isPastDate(item?.date);

              return (
                <PaymentManagementBox
                  key={item.date}
                  amount={item.amount}
                  index={index}
                  date={item.date}
                  paymentManagement={paymentManagement}
                  setPaymentManagement={setPaymentManagement}
                  style={isPaid && isPastItemDate ? { background: 'lightgreen' } : !isPaid && isPastItemDate ? { background: 'lightcoral' } : null}
                />
              );
            })}
            <div onClick={addMonth} className="plus-button">
              <PlusCircleOutlined />
            </div>
          </div>
          <div style={{ textAlign: 'right', fontSize: 20, height: 30, marginTop: 20 }}>
            {totalInstallment !== 0 && (
              <>
                {totalInstallment < 0 ? (
                  <>
                    Переплата:
                    <b style={{ color: 'green' }}>
                      {Math.abs(totalInstallment)}
                      {CURRENCIES[currentCurrency].symbol}
                    </b>
                  </>
                ) : (
                  <>
                    Розподіліть ще:
                    <b style={{ color: 'red' }}>
                      {totalInstallment}
                      {CURRENCIES[currentCurrency].symbol}
                    </b>
                  </>
                )}
              </>
            )}
          </div>
          <div style={{ textAlign: 'right', margin: 20 }}>
            <Button style={{ marginRight: 10 }} onClick={cancelEdit}>
              Відмінити
            </Button>
            <Button disabled={isNewContract || totalInstallment !== 0} type="primary" onClick={editPaymentManagement}>
              Зберегти
            </Button>
          </div>
        </div>
      ) : (
        <div>Немає даних</div>
      )}
      <Divider orientation="left" plain>
        <b>Перший платіж:</b>
      </Divider>
      <div className="payment-management">
        <div className="payment-management-block">{firstPaymentBoxRender}</div>
        {assignments?.length > 0 ? (
          <>
            <Divider orientation="left" plain>
              <b>Виходи</b>
            </Divider>
            <div className="payment-management">
              <div className="payment-management-block">
                {assignments?.map((item, index) => (
                  <PaymentManagementBox
                    key={index}
                    amount={item.clientAmount}
                    index={index}
                    date={item.date}
                    style={isPaidStatus.assignments?.[index] ? { background: 'lightgreen' } : { background: 'lightcoral' }}
                    disabled
                  />
                ))}
              </div>
            </div>
          </>
        ) : null}
        {monthlyPayments?.length > 0 ? (
          <>
            <Divider orientation="left" plain>
              <b>Щомісячні платежі</b>
            </Divider>
            <div className="payment-management">
              <div className="payment-management-block">
                <MonthlyPayments
                  monthlyPayments={monthlyPayments}
                  isPaidStatus={isPaidStatus}
                  addToPay={addToPay}
                  client={client}
                  removeToPay={removeToPay}
                  selectedBoxes={selectedMonthlyPaymentsBoxes}
                  setSelectedBoxes={setSelectedMonthlyPaymentsBoxess}
                />
                {/* {monthlyPayments?.map((item, index) => {
                const isPaid = isPaidStatus.monthlyPayments?.[index];
                const isPastItemDate = isPastDate(item?.date);
                const isPaidInAdvance = item?.isPaidInAdvance;
                return (
                  <PaymentManagementBox
                    key={item.date}
                    amount={item.amount}
                    index={index}
                    date={item.date}
                    style={
                      isPaid && (isPastItemDate || isPaidInAdvance)
                        ? { background: 'lightgreen' }
                        : !isPaid && isPastItemDate
                        ? { background: 'lightcoral' }
                        : null
                    }
                    disabled
                  />
                );
              })} */}
              </div>
            </div>
          </>
        ) : null}
        {toPay !== 0 && (
          <div className="payment-management-btn">
            <Button onClick={pay} disabled={loading}>
              {translation.payBtn['ua']}: {toPay}
              {CURRENCIES[currentCurrency].symbol}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const MonthlyPayments = ({ client, selectedBoxes, setSelectedBoxes, monthlyPayments, isPaidStatus, addToPay, removeToPay }) => {
  const totalAmount = selectedBoxes.reduce((total, box) => total + Number(box.amount), 0);
  const handleSelect = (item, isChecked) => {
    if (isChecked) {
      addToPay(item.amount);
      setSelectedBoxes([...selectedBoxes, item]);
    } else {
      removeToPay(item.amount);
      setSelectedBoxes(selectedBoxes.filter((box) => box.date !== item.date));
    }
  };

  return (
    <>
      {monthlyPayments?.map((item, index) => {
        const isPaid = isPaidStatus.monthlyPayments?.[index];
        const isPastItemDate = isPastDate(item?.date);
        const isPaidInAdvance = item?.isPaidInAdvance;
        const isSelected = selectedBoxes?.some((box) => box.date === item.date);
        const isEnabled = client?.paymentCard?.cardToken || client?.clientBalance >= item.amount;
        return (
          <PaymentManagementBox
            key={item.date}
            amount={item.amount}
            index={index}
            date={item.date}
            showCheckbox={!isPaid && isEnabled}
            style={
              isPaid
                ? { background: 'lightgreen' }
                : !isPaid && isPastItemDate
                ? { background: 'lightcoral' }
                : isSelected
                ? { background: 'lightblue' }
                : null
            }
            isSelected={isSelected}
            onSelect={(e) => handleSelect(item, e.target.checked)}
            disabled
          />
        );
      })}
    </>
  );
};
export default PaymentManagementComponent;
