import './ChatSpinner.scss';

const ChatSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

export default ChatSpinner;
