import React from 'react';
import { Col, Row, Button } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';

import './ContractNotFound.scss';

const ContractNotFound = ({ onCancel }) => (
  <Col className="votingContractNotFound">
    <Row justify={'center'} className="votingContractNotFound__title">
      Запис не знайдено
    </Row>
    <Row justify={'center'}>
      <CloseCircleTwoTone twoToneColor="red" className="votingContractNotFound__icon" />
    </Row>
    <Row justify={'center'}>
      <Button onClick={onCancel} className="votingContractNotFound__closeButton">
        Закрити
      </Button>
    </Row>
  </Col>
);
export default ContractNotFound;
