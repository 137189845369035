import React from 'react';
import { CURRENCIES } from 'common_constants/business';
import SearchSelect from './SearchSelect';

const SearchSelectCurrencies = ({ onChange, value, className, style, disabledState }) => {
  return (
    <SearchSelect
      disabled={disabledState}
      className={className}
      style={style}
      placeholder="Оберіть валюту"
      list={Object.values(CURRENCIES)}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectCurrencies;
