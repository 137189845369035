import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Card, Input, Typography, Empty, Spin, Divider, Col, Row } from 'antd';
import { Box } from 'common_components';
import { info, success, warn } from '../../tools';
import { ROLES } from 'common_constants/business';

import clsx from 'clsx';
import './PersonnelDirectory.scss';
import { SelectGeneralFil } from '../../components/SelectGeneral';
import { UserAvatar } from '../../components';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';
dayjs.locale('uk');
dayjs.extend(isSameOrAfter);

const PersonnelDirectory = () => {
  const FILII = useSelector((state) => state.common.FILII);
  const usersRedux = useSelector((state) => state.common.users) ?? {};

  const coreMegaState = {
      phone: '',
      listUsers: [],
      loading: false,
      fil: null,
    },
    [megaState, setMegaState] = useState(coreMegaState);

  const { Text } = Typography,
    { Search } = Input;

  const onFind = async ({ isSearchViaPhone, isSearchViaFil, fil }) => {
    if (isSearchViaPhone) {
      const validation = (_phone) => {
        if (!_phone) {
          warn('', 'Для пошуку робітника, потрібно вказати номер телефону робітника.', { phone: _phone });
          return;
        }

        return true;
      };

      if (!validation(megaState.phone)) return;

      setMegaState((prev) => ({ ...prev, fil: coreMegaState.fil }));
    } else if (isSearchViaFil) {
      const validation = (_fil) => {
        if (!_fil) {
          warn('', 'Для пошуку робітника, потрібно вказати філію робітника.', { fil: _fil });
          return;
        }

        return true;
      };

      if (!validation(fil)) return;

      setMegaState((prev) => ({ ...prev, fil: String(fil), phone: coreMegaState.phone }));
    }

    const transaction = {
      ...(isSearchViaPhone ? { phoneUser: megaState.phone } : {}),
      ...(isSearchViaFil ? { filUser: String(fil) } : {}),
    };

    setMegaState((prev) => ({ ...prev, loading: true }));

    const arrUsers = Object.values(usersRedux) ?? [];

    const filterUsers = (users, phoneUser, filUser) => {
      const filteredUsers = users.filter((user) => {
        const [matchPhone, matchFil, matchBadUser] = [
            typeof phoneUser === 'string' ? buildMatchUserViaPhone(user, phoneUser) : true,
            typeof filUser === 'string' ? buildMatchUserViaFil(user, filUser) : true,
            buildMatchBadUser(user),
          ],
          resultFilter = matchPhone && matchFil && matchBadUser && !user.fired;

        return resultFilter;
      });
      return filteredUsers;
    };

    const generateAllPhoneFormats = (phoneNumber) => {
      if (typeof phoneNumber !== 'string') {
        return [];
      }
      const cleanedPhone = phoneNumber.replace(/[\s,]+/g, '');
      const phoneFormats = [];

      const isValidLengthForMobilePhone = (str) => {
        const length = str.length;
        return length >= 9 && length <= 13;
      };

      if (isValidLengthForMobilePhone(phoneNumber)) {
        const renumber =
          cleanedPhone.startsWith('+380') && cleanedPhone.match(/^.{13}$/)
            ? cleanedPhone.substring(4)
            : cleanedPhone.startsWith('380') && cleanedPhone.match(/^.{12}$/)
            ? cleanedPhone.substring(3)
            : cleanedPhone.startsWith('80') && cleanedPhone.match(/^.{11}$/)
            ? cleanedPhone.substring(2)
            : cleanedPhone.startsWith('0') && cleanedPhone.match(/^.{10}$/)
            ? cleanedPhone.substring(1)
            : cleanedPhone;

        phoneFormats.push(renumber);
        phoneFormats.push('0' + renumber);
        phoneFormats.push('80' + renumber);
        phoneFormats.push('380' + renumber);
        phoneFormats.push('+380' + renumber);
        return phoneFormats;
      } else {
        phoneFormats.push(cleanedPhone);
        return phoneFormats;
      }
    };

    const buildMatchUserViaPhone = (user, phoneUser) => {
      if (!user.userPersonalData) return false;

      const [personalSet, workSet] = [
          generateAllPhoneFormats(user.userPersonalData.personalPhone) ?? [],
          generateAllPhoneFormats(user.userPersonalData.workPhone) ?? [],
        ],
        combinedSet = [...personalSet, ...workSet],
        resultBuild = combinedSet.includes(phoneUser);

      return resultBuild;
    };

    const buildMatchUserViaFil = (user, filUser) => {
      return user.fil === filUser;
    };

    const buildMatchBadUser = (user) => {
      return user.fired !== true;
    };

    const projectMatchSecureViewData = (user) => {
      return {
        _id: user._id,
        fil: user.fil,
        name: user.name,
        role: user.role,
        birthDate: user.birthDate,
        workPhone: user.userPersonalData?.workPhone,
        personalPhone: user.userPersonalData?.personalPhone,
        p: user.p,
        jobPosition: user.jobPosition,
      };
    };

    const result = filterUsers(arrUsers, transaction.phoneUser, transaction.filUser)?.map(projectMatchSecureViewData) ?? [];

    const defaultAction = () => warn('', '');

    if (isSearchViaPhone) {
      const messageMap = {
        0: () => info('', 'Такого користувача не знайдено'),
        1: () => success('', 'Користувача знайдено'),
        2: () => info('', 'Двоє користувачів мають однаковий номер телефону'),
      };

      (messageMap[result?.length] || defaultAction)();
    } else if (isSearchViaFil) {
      const messageMap = {
        0: () => info('', 'У даній філії користувачів не знайдено'),
        1: () => success('', 'Користувача знайдено'),
      };

      const manyAction = () => info('', 'Знайдено декілька користувачів');

      const dataLength = result?.length;

      if (dataLength !== undefined) {
        if (dataLength >= 2) {
          manyAction();
        } else {
          const messageFunction = messageMap[dataLength] || defaultAction;
          messageFunction();
        }
      } else {
        defaultAction();
      }
    }

    setMegaState((prev) => ({ ...prev, loading: false, listUsers: result ?? [] }));
  };

  const changeFilandFindUsers = (_value) => {
    if (_value) {
      onFind({ isSearchViaFil: true, fil: _value });
    } else {
      setMegaState((prev) => ({ ...prev, fil: coreMegaState.fil, phone: coreMegaState.phone }));
    }
  };

  const findUserViaPhone = () => {
    onFind({ isSearchViaPhone: true });
  };

  const calculateBirthdayInfo = (birthDate) => {
    const now = dayjs();
    let nextBirthday = dayjs(birthDate).year(now.year());
    if (now.isSameOrAfter(nextBirthday)) {
      nextBirthday = nextBirthday.add(1, 'year');
    }
    const daysUntilBirthday = nextBirthday.diff(now, 'days');

    const birthDateText = dayjs(birthDate).format('D MMMM');

    let soonLabel = birthDateText;
    if (daysUntilBirthday <= 7) {
      soonLabel = `${birthDateText} (дуже скоро)`;
    } else if (daysUntilBirthday <= 30) {
      soonLabel = `${birthDateText} (скоро)`;
    }

    return soonLabel;
  };

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);
    setMegaState((prev) => ({ ...prev, phone: validPhoneNumber, fil: coreMegaState.fil }));
  };

  return (
    <div className="cabinets_page default_page">
      <h2>Довідник</h2>

      <Card className="component-find">
        <Divider orientation="left" children="Пошук:" />
        <div className="path-phone-find">
          <Text className="path-phone-find-text" children="По телефону:" />
          <Search
            className="path-phone-find-input"
            placeholder="Введіть номер телефону"
            onSearch={findUserViaPhone}
            value={megaState.phone}
            enterButton
            onChange={(e) => handlePhoneNumberInput(e)}
          />
        </div>
        <Box mt={14} />
        <div className="path-fil-find">
          <Text className="path-fil-find-text" children="По філії:" />
          <SelectGeneralFil
            value={megaState.fil === 'undefined' ? null : megaState.fil}
            className="path-phone-find-input"
            onChange={(value) => changeFilandFindUsers(value)}
          />
        </div>
      </Card>

      <Spin size="large" tip="Зачекайте, йде процес завантаження." spinning={megaState.loading}>
        {megaState.listUsers?.length === 0 ? (
          <Empty description={<Text>Не знайдено</Text>} />
        ) : (
          megaState.listUsers
            ?.sort((a, b) => (ROLES[a.role] ?? Number.MAX_SAFE_INTEGER) - (ROLES[b.role] ?? Number.MAX_SAFE_INTEGER))
            ?.map((i, index) => {
              return (
                <Box key={index} mt={15}>
                  <Card className={clsx('card', { fired: i.fired })}>
                    <Row justify="start" align="middle">
                      <Col>
                        <UserAvatar size={80} shape="square" className="avatar" user={usersRedux?.[i._id]} />
                      </Col>
                      <Col>&nbsp;&nbsp;</Col>
                      <Col className="info-col">
                        <div className="row">
                          <b>Філія:</b> {FILII[i.fil] ?? '-'}
                        </div>
                        <div className="row">
                          <b>Ім'я:</b> {i.name ?? '-'}
                        </div>
                        <div className="row">
                          <b>Псевдонім:</b> {i.p ?? '-'}
                        </div>
                        <div className="row">
                          <b>{i.personalPhone && !i.workPhone ? 'Особистий' : 'Робочий'} телефон:</b> {i.workPhone ?? i.personalPhone ?? '-'}
                        </div>

                        {i.fired && <div style={{ color: 'red' }}>Звільнено</div>}
                      </Col>
                      <Col className="info-col" style={{ flexBasis: '20%' }}>
                        <div className="row">
                          <b>Посада:</b> {i.jobPosition ?? '-'}
                        </div>
                        <div className="row">
                          <b>Роль:</b> {i.role ?? '-'}
                        </div>
                        <div className="row">
                          <b>Дата народження:</b>&nbsp;
                          {dayjs(i.birthDate).isValid() ? calculateBirthdayInfo(i.birthDate) : '-'}
                        </div>
                        {i.fired && <div style={{ color: 'red' }}>Звільнено</div>}
                      </Col>
                    </Row>
                  </Card>
                </Box>
              );
            })
        )}
      </Spin>
    </div>
  );
};

export default PersonnelDirectory;
