import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, Button } from 'antd';
import { addMfo, setModal, updateMfoItem } from '../../store/commonReducer';
import { error, request, success } from '../../tools';

const AddMfo = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { editItem } = useSelector((state) => state.common.modal);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editItem) {
      form.setFieldsValue({
        name: editItem.name,
        fullName: editItem.fullName,
        email: editItem.email,
        address: editItem.address,
        requisites: editItem.requisites,
      });
    }
  }, [editItem, form]);

  const handleFormSubmit = async () => {
    setLoading(true);

    const data = {
      name: form.getFieldValue('name'),
      fullName: form.getFieldValue('fullName'),
      email: form.getFieldValue('email'),
      address: form.getFieldValue('address'),
      requisites: form.getFieldValue('requisites'),
    };

    const requestURL = editItem ? `/mfo/${editItem._id}` : '/mfo/addMfo';

    await request.post(
      requestURL,
      data,
      ({ mfo }) => {
        const successMessage = editItem ? 'МФО успішно відредагована.' : 'МФО успішно додана.';
        success('', successMessage);
        if (editItem) {
          dispatch(updateMfoItem(mfo));
        } else {
          dispatch(addMfo(mfo));
        }
        dispatch(setModal());
        setLoading(false);
      },
      () => {
        const errorMessage = editItem ? 'Помилка при редагуванні.' : 'Помилка при додаванні.';
        error('', errorMessage);
        setLoading(false);
      },
    );
  };

  return (
    <Modal title={editItem ? 'Редагувати МФО' : 'Додати МФО'} open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleFormSubmit} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Form.Item label="Назва" name="name" rules={[{ required: true, message: 'Будь ласка, введіть назву МФО!' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Повна назва" name="fullName" rules={[{ required: true, message: 'Будь ласка, введіть повну назву МФО!' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Будь ласка, введіть email!' },
              {
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                message: 'Будь ласка, ввведіть валідний email!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Фізична адреса" name="address" rules={[{ required: true, message: 'Будь ласка, введіть фізичну адресу!' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Реквізити" name="requisites" rules={[{ required: true, message: 'Будь ласка, введіть реквізити!' }]}>
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit">
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddMfo;
