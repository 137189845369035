import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'common_constants/routes';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

const SelectGeneralFil = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const filii = useSelector((state) => state.common.filii);
  const { pathname } = useLocation();
  const { Option } = Select;

  const isNotPersonnelDirectory = pathname !== ROUTES.PERSONNEL_DIRECTORY;
  const sortedList = [...filii].sort((a, b) => a.i - b.i);
  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть філію"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {isNotPersonnelDirectory && <Option value="_" children="Усі філії" />}
      {sortedList.map((fil) => (
        <Option key={fil.i} value={`${fil.i}`} children={fil.name} />
      ))}
    </Select>
  );
};

export { SelectGeneralFil };
