import React from 'react';
import { RIBBON_VALUE_REACH } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralHellishnessReach = [
  RIBBON_VALUE_REACH.map((item) => (item = item.text)),
  ['Пекельність дожиму по консультації', 'rih_H'],
  ['Пекельність дожиму', 'rih'],
];

const SelectGeneralHellishnessReach = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть рівень пекельності"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі рівні пекельності</Option>
      {RIBBON_VALUE_REACH.map((i, index) => (
        <Option key={i.text} value={String(index)}>
          {i.text}
        </Option>
      ))}
    </Select>
  );
};

export { billSelectGeneralHellishnessReach, SelectGeneralHellishnessReach };
