import { Row, Pagination, Modal, Spin, List, Card, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from '../../store/commonReducer';
import { error } from '../../tools';

import './AssignmentDocuments.scss';

const REGISTER_URL = process.env.REACT_APP_REGISTER_URL;
const OPENDATABOT_URL = 'https://opendatabot.ua/';

const fetchDocuments = async (body, callbacks) => {
  const { caseNumber, currentPage } = body;
  const offset = currentPage * 12 - 12;
  const { setLoading, setDocumentsList, setTotalPages } = callbacks;
  let url = `${REGISTER_URL}cause?number=${caseNumber}`;
  if (offset) {
    url += `&offset=${offset}`;
  }
  setLoading(true);
  const response = await fetch(url, {
    method: 'GET',
  });
  if (!response.ok) {
    error('', 'Не вдалось отримати дані!');
    setLoading(false);
    return;
  }
  const res = await response.json();
  setDocumentsList(res.data);
  setTotalPages(res.count / 12);
  setLoading(false);
};

const AssignmentDocuments = () => {
  const dispatch = useDispatch();
  const { data, prev } = useSelector((state) => state.common.modal);
  const [loading, setLoading] = useState(false);
  const [documentsList, setDocumentsList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [iframeVisible, setIframeVisible] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');

  const onCancel = () => {
    dispatch(setModal(prev));
  };

  useEffect(() => {
    const body = { caseNumber: data?.courtCase, currentPage };
    const callbacks = {
      setLoading,
      setDocumentsList,
      setTotalPages,
    };
    fetchDocuments(body, callbacks);
  }, []);

  useEffect(() => {
    const body = { caseNumber: data?.courtCase, currentPage };
    const callbacks = {
      setLoading,
      setDocumentsList,
      setTotalPages,
    };
    fetchDocuments(body, callbacks);
  }, [currentPage]);

  const handleCardClick = (url) => {
    setIframeUrl(url);
    setIframeVisible(true);
  };

  const handleIframeClose = () => {
    setIframeVisible(false);
    setIframeUrl('');
  };

  return (
    <>
      <Modal open className="assignmentDocuments-modal" title={`Судові документи по справі ${data?.courtCase}`} onCancel={onCancel} footer={null}>
        <Spin tip="Завантаження" spinning={loading}>
          <List
            className="list"
            itemLayout="vertical"
            size="large"
            dataSource={documentsList}
            renderItem={(item) => (
              <Card
                className="list-item"
                onClick={() => {
                  const documentUrl = `${OPENDATABOT_URL}court/${item.id}`;
                  handleCardClick(documentUrl);
                }}
              >
                <Row>{item?.title}</Row>
                <Row>{item?.type}</Row>
                <Row>{item?.court}</Row>
                <Row>{item?.judge}</Row>
                <Row>Дата розгляду {item?.date}</Row>
              </Card>
            )}
          />
          <Row>
            <Pagination
              showSizeChanger={false}
              total={totalPages * 12}
              onChange={(value) => setCurrentPage(value)}
              pageSize={12}
              current={currentPage}
            />
          </Row>
        </Spin>
      </Modal>

      {iframeVisible && (
        <div className="iframe-container">
          <Button className="iframe-close-btn" onClick={handleIframeClose}>
            X
          </Button>
          <iframe src={iframeUrl} className="iframe-fullscreen" />
        </div>
      )}
    </>
  );
};

export default AssignmentDocuments;
