import { Button, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { EXPORT_HOTLINE_CALLS } from 'common_constants/modals';
import { ROLES } from 'common_constants/business';

import { setModal } from '../../store/commonReducer';

import Excel from '../../images/excel_logo.svg';

export default function ExportHotlineCalls() {
  const dispatch = useDispatch();
  const { Text } = Typography;

  const userAuth = useSelector((state) => state.common.userAuth);

  const admin = ROLES[userAuth.role] === ROLES.admin;

  if (!admin) return null;

  return (
    <>
      <hr style={{ marginBottom: 12 }} />
      <div>
        <Button className="exportBtn" onClick={() => dispatch(setModal({ name: EXPORT_HOTLINE_CALLS }))}>
          <img src={Excel} alt="Excel" width={24} /> &nbsp; <Text className="exp-excel">Експорт дзвінків</Text>
        </Button>
      </div>
    </>
  );
}
