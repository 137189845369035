import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { green, red } from '@ant-design/colors';

import { date2VSMSMPayment, arrayCard } from '../../ContractInfoHelpers';

import dayjs from 'dayjs';

const MonthlySMSInfo = ({ data, breakMPayment }) => {
  const bankCards = useSelector((state) => state.common.bankCards);

  return (
    <>
      <Divider className="divider-contract-item-info" />
      <span style={{ color: green[6] }}>
        <b>Місячний гонорар:</b> {data.mg}
      </span>
      <span style={{ color: green[6] }}>
        <b>Дата початку розсилання СМС о місячній сплаті:</b> {dayjs(data.mpd)?.format('DD.MM.YYYY')}
      </span>
      <span style={data.mb ? { color: red[5] } : { color: green[6] }}>
        {{
          true: <b>В цьому місяці розсилки не буде!</b>,
        }[breakMPayment] || (
          <span>
            <b>В цьому місяці розсилання буде:</b> {date2VSMSMPayment(-3, data) + ' та ' + date2VSMSMPayment(0, data)}
          </span>
        )}
      </span>
      <span style={{ color: green[6] }} className="array-card">
        {data?.mr && (
          <>
            <b>Реквізити адвоката:</b> {arrayCard({ mr: data.mr, cards: bankCards || [] })}
          </>
        )}
      </span>
    </>
  );
};

export default MonthlySMSInfo;
