import { useState } from 'react';
import { Card, Typography, Popover, Collapse } from 'antd';
import dayjs from 'dayjs';

import HoverableUser from '../../../components/HoverableUser/HoverableUser';

import { ContractInfoTextPart } from '../../../pages/Contracts/ContractInfo/ContractInfoPartials';

import { INSURANCE_PAYMENTS } from 'common_constants/business';

const { Panel } = Collapse;

const InsuranceInfo = ({ data, users, FILII, getInsuranceDetails }) => {
  const { Text } = Typography;

  const [activeKey, setActiveKey] = useState([]);

  return (
    <Card className="insurancePage-insuranceInfo">
      <span>
        <Text strong>Клієнт:</Text>&nbsp;
        <Text>{(data.clientNameForInsurance || data.clientName) ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Тип страхування:</Text>&nbsp;
        <Text>{getInsuranceDetails(data.type) ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Дата початку страховки:</Text>&nbsp;
        <Text>{dayjs(data.dateReceiving)?.format('DD-MM-YYYY') ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Дата закінчення страховки:</Text>&nbsp;
        <Text>{dayjs(data.dateExpiration)?.format('DD-MM-YYYY') ?? '-'}</Text>
      </span>
      <span>
        <Text strong>Коментар:</Text>&nbsp;
        <Text>{data.comment || '-'}</Text>
      </span>
      <span>
        <Text strong>Статус:</Text>&nbsp;
        <Text>{data.contractId ? <span style={{ color: 'green' }}>Активовано</span> : <span style={{ color: 'red' }}>Не активовано</span>}</Text>
      </span>
      <span>
        <Text strong>Дата активації:</Text>&nbsp;
        <Text>{data.dateActivation !== undefined ? dayjs(data.dateActivation)?.format('DD-MM-YYYY') : '-'}</Text>
      </span>
      <span>
        <Text strong>Філія:</Text>&nbsp;
        <Text>{FILII[data.f] ?? '-'}</Text>
      </span>
      <span>
        <Popover content={<HoverableUser id={data.author} />} arrow placement="bottomLeft" trigger="hover">
          <Text strong>Автор:</Text>&nbsp;
          <Text>{users[data.author]?.name ?? '-'}</Text>
        </Popover>
      </span>
      <span>
        <Text strong>Ким оплачено:</Text>&nbsp;
        <Text>{INSURANCE_PAYMENTS[data.payment] ?? '-'}</Text>
      </span>
      {data?.contractId && (
        <Collapse activeKey={activeKey} accordion onChange={(key) => setActiveKey((prevKey) => (prevKey.includes(key) ? [] : [key]))}>
          <Panel header="Інформація про контракт" key="1">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ContractInfoTextPart data={data.contract} users={users} />
            </div>
          </Panel>
        </Collapse>
      )}
    </Card>
  );
};

export default InsuranceInfo;
