import { Col, Typography } from 'antd';
import { useState } from 'react';

import CreateCategoryForm from './CreateCategoryForm';
import CategoriesList from './CategoriesList';
import CreateServiceItemForm from './CreateServiceItemForm';
import ServiceItemsList from './ServiceItemsList';

import './Sphere.scss';
import './FormStyles.scss';
import './ListStyles.scss';
import PageInfo from './../../components/PageInfo/PageInfo';

const SpherePage = () => {
  const [activeCategory, setActiveCategory] = useState(null);

  return (
    <Col className="sphere">
      <Typography.Title style={{ textAlign: 'center', marginBottom: '40px' }} level={4}>
        3д сфера
        <PageInfo />
      </Typography.Title>
      <Typography.Title level={4}>Категорії: </Typography.Title>
      <section className="categories">
        <CreateCategoryForm />
        <CategoriesList activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
      </section>

      <section className="category-elements">
        <Typography.Title level={4}>Сервіси по категорії ({activeCategory?.title.ua}): </Typography.Title>
        {activeCategory ? (
          <>
            <ServiceItemsList activeCategory={activeCategory} />
          </>
        ) : (
          <Typography.Text>Виберіть категорію</Typography.Text>
        )}
        <Typography.Title level={4}>Додати сервіс: </Typography.Title>
        <CreateServiceItemForm activeCategory={activeCategory} />
      </section>
    </Col>
  );
};

export default SpherePage;
