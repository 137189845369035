import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Form, Button, Row, Typography } from 'antd';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';

import { setModal, setAssignmentsList } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import { updateAssignmentsListWithData } from '../ModalsHelpers';

const { Title } = Typography;

const AssignmentMarkAsIncorrect = () => {
  const dispatch = useDispatch();
  const assignmentsList = useSelector((state) => state.common.assignmentsList);
  const { data, prev } = useSelector((state) => state.common.modal);

  const [loading, setLoading] = useState(false);
  const onCancel = () => {
    dispatch(setModal(prev));
  };

  const onFinish = () => {
    const reportBody = {
      contractIndex: data.contractNumber,
      assignmentId: data._id,
    };

    const onSuccess = (res) => {
      const updatedData = { incorrect: true };

      const updatedAssignments = updateAssignmentsListWithData(assignmentsList, data._id, updatedData);
      dispatch(setAssignmentsList(updatedAssignments));

      request.post(
        '/sugar/editManySugarsPayrolls',
        {
          sugars: data.sugar.ids.map((elem) => ({
            _id: elem,
            amount: 0,
          })),
        },
        () => {
          success('Гаманець оновлен', ' ');
        },
        () => {
          error(' ', 'Не вдалось оновити гаманець');
        },
      );

      success('Вихід позначено, як помилковий.', ' ');
      onCancel();
    };

    const onError = (_, __, axiosData) => {
      error(' ', axiosData.response.data.error);
      setLoading(false);
    };

    setLoading(true);
    request.post('/assignment/markAsIncorrect', reportBody, onSuccess, onError);
  };

  return (
    <Modal open className="" title={'Помилковий вихід'} onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={loading}>
        <Title style={{ textAlign: 'center', marginBottom: '40px' }} level={4}>
          Позначити вихід, як помилковий?
        </Title>
        <Form name="nest-messages" onFinish={onFinish} style={{ maxWidth: 600, overflow: 'hidden' }}>
          <Form.Item>
            <Row justify={'space-between'}>
              <Button icon={<CheckOutlined />} type="primary" htmlType="submit" style={{ width: '45.5%' }}>
                {'Так'}
              </Button>
              <Button icon={<StopOutlined />} onClick={onCancel} style={{ width: '45.5%' }}>
                {'Відміна'}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AssignmentMarkAsIncorrect;
