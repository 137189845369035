import { gray, orange } from '@ant-design/colors';
import { CloseCircleOutlined, DownOutlined, EditOutlined, SnippetsOutlined, UpOutlined, ContainerOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from '../../../../store/commonReducer';

import { ASSIGNMENTS_SERVICES_TYPE, ASSIGNMENT_PAYMENT_STATUS, ASSIGNMENT_SERVICE, ASSIGNMENT_STATUS } from 'common_constants/business';
import { ASSIGNMENT_DOCUMENTS } from 'common_constants/modals';
// import { Ribbon } from 'common_components';

import './AssignmentItem.scss';

const AssignmentItem = ({ assignment, handleAddReport, handleAddAssignment, handleMarkAsIncorrect, userId, admin, deputyManager }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const judgesList = useSelector((state) => state.common.judgesList);
  const lawyersList = useSelector((state) => state.common.lawyersList);
  const placesList = useSelector((state) => state.common.placesList);
  const courtsList = useSelector((state) => state.common.courtsList);
  const FILII = useSelector((state) => state.common.FILII);

  const [isExpanded, setIsExpanded] = useState(false);

  if (!isExpanded) {
    return (
      // <Ribbon condition={assignment.incorrect} text={'Помилковий'} color={'red'}>
      <Card className={`assignment-item-inner${assignment?.incorrect ? ' _incorrect' : ''}`} onClick={() => setIsExpanded(true)}>
        <Row className="assignment-item" justify={'space-between'} align={'middle'}>
          <Col className="assignment-item-fields-inner" flex={'auto'}>
            <Row className="assignment-item-fields" justify={'space-between'}>
              <Col className="assignment-item-fields-left">
                <Row className="assignment-item-fields-row">
                  <Row className="assignment-item-fields-row__title">Філія:&nbsp;</Row>
                  <Row className="assignment-item-fields-row__info">{FILII[assignment.f]}</Row>
                </Row>
                <Row className="assignment-item-fields-row">
                  <Row className="assignment-item-fields-row__title">Автор:&nbsp;</Row>
                  <Row className="assignment-item-fields-row__info">{users ? users[assignment?.authorId]?.name : '-'}</Row>
                </Row>
              </Col>
              <Col className="assignment-item-fields-right">
                <Row className="assignment-item-fields-row">
                  <Row className="assignment-item-fields-row__title">Сума:&nbsp;</Row>
                  <Row className="assignment-item-fields-row__info">{assignment.amountForLawyer}</Row>
                </Row>
                <Row className="assignment-item-fields-row">
                  <Row className="assignment-item-fields-row__title">Статус:&nbsp;</Row>
                  <Row className="assignment-item-fields-row__info">
                    {assignment?.report
                      ? assignment?.report.assignmentStatus === ASSIGNMENT_STATUS.HAPPEN
                        ? 'відбувся'
                        : 'не відбувся'
                      : 'звіт відсутній'}
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>

          <Row className="assignment-item-buttons-inner" style={assignment.incorrect ? { marginTop: '25px' } : ''}>
            <Col className="assignment-item-buttons">
              <Button className="assignment-item__button">
                <DownOutlined />
              </Button>
            </Col>
          </Row>
        </Row>
      </Card>
      // </Ribbon>
    );
  }

  return (
    // <Ribbon condition={assignment.incorrect} text={'Помилковий'} color={'red'}>
    <Card className={`assignment-item-inner${assignment.incorrect ? ' _incorrect' : ''}`}>
      <Row className="assignment-item expanded" justify={'space-between'} align={'middle'}>
        <Col className="assignment-item-fields-inner" flex={'auto'}>
          <Row className="assignment-item-fields" justify={'space-between'}>
            <Col className="assignment-item-fields-left">
              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Номер виходу:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{assignment.i}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">ПІБ клієнта:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{assignment.C}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Договір:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{assignment.contractNumber}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Філія:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{FILII[assignment.f]}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Час:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{assignment.selectedTime}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Послуга:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{ASSIGNMENTS_SERVICES_TYPE[assignment.serviceType]}</Row>
              </Row>
              {assignment.serviceType === ASSIGNMENT_SERVICE.OTHER ? (
                <Row className="assignment-item-fields-row">
                  <Row className="assignment-item-fields-row__title">Місце:&nbsp;</Row>
                  <Row className="assignment-item-fields-row__info">
                    {placesList?.find((place) => place._id === assignment.place)?.placeName ?? '-'}
                  </Row>
                </Row>
              ) : null}

              {assignment.serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT ? (
                <Row className="assignment-item-fields-row">
                  <Row className="assignment-item-fields-row__title">Суддя:&nbsp;</Row>
                  <Row className="assignment-item-fields-row__info">{judgesList?.find((judge) => judge._id === assignment.judgeId)?.name ?? '-'}</Row>
                </Row>
              ) : null}

              {courtsList && assignment.serviceType === ASSIGNMENT_SERVICE.GOING_TO_COURT ? (
                <Row className="assignment-item-fields-row">
                  <Row className="assignment-item-fields-row__title">Суд:&nbsp;</Row>
                  <Row className="assignment-item-fields-row__info">{courtsList.find((court) => assignment?.court === court.i)?.name}</Row>
                </Row>
              ) : null}

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Судова справа:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{JSON.stringify(assignment.courtCase) ?? '-'}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Дата виходу:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{dayjs(assignment.date).format('DD.MM.YYYY')}</Row>
              </Row>
            </Col>

            <Col className="assignment-item-fields-right">
              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Адвокат:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">
                  {lawyersList?.find((lawyer) => lawyer._id === assignment.lawyerId)?.name ?? '-'}
                </Row>
              </Row>
              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Сума клієнта:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{assignment.clientAmount}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Сума для адвоката:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{assignment.amountForLawyer}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Оплата клієнта:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">
                  {assignment?.sugar?.amounts?.length > 0 ? assignment.sugar.amounts.reduce((a, b) => a + ', ' + b) : 0}
                </Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Статус оплати:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">
                  {assignment?.report
                    ? assignment.report.paymentStatus === ASSIGNMENT_PAYMENT_STATUS.PAYED
                      ? 'оплачено'
                      : 'не оплачено'
                    : 'звіт відсутній'}
                </Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Статус виходу:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">
                  {assignment?.report
                    ? assignment.report.assignmentStatus === ASSIGNMENT_STATUS.HAPPEN
                      ? 'відбувся'
                      : 'не відбувся'
                    : 'звіт відсутній'}
                </Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Коментар для своїх:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{assignment.mainComment ?? '-'}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Коментар для клієнта:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{assignment.commentForClient ?? '-'}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Автор:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{users[assignment.authorId]?.name ?? '-'}</Row>
              </Row>

              <Row className="assignment-item-fields-row">
                <Row className="assignment-item-fields-row__title">Дата створення:&nbsp;</Row>
                <Row className="assignment-item-fields-row__info">{dayjs(assignment.createdAt).format('DD.MM.YYYY')}</Row>
              </Row>
            </Col>
          </Row>
        </Col>

        <Row className="assignment-item-buttons-inner" style={assignment.incorrect ? { marginTop: '25px' } : ''}>
          <Col className="assignment-item-buttons">
            <Button className="assignment-item__button" onClick={() => setIsExpanded(false)}>
              <UpOutlined />
            </Button>

            {(assignment.authorId === userId || deputyManager) && !assignment.report ? (
              <Button type="primary" className="assignment-item__button expanded" onClick={() => handleAddReport(assignment)}>
                <SnippetsOutlined />
              </Button>
            ) : null}

            <Button
              type="primary"
              className="assignment-item__button expanded"
              onClick={() => {
                dispatch(setModal({ name: ASSIGNMENT_DOCUMENTS, data: { courtCase: assignment?.courtCase } }));
              }}
            >
              <ContainerOutlined />
            </Button>

            {admin && (
              <Button
                style={{ background: `${orange[5]}`, borderColor: `${orange[5]}`, color: gray[1] }}
                type="primary"
                className="assignment-item__button expanded"
                onClick={() => handleAddAssignment(assignment)}
              >
                <EditOutlined />
              </Button>
            )}
            {!assignment.incorrect && (
              <Button type="primary" danger className="assignment-item__button expanded" onClick={() => handleMarkAsIncorrect(assignment)}>
                <CloseCircleOutlined />
              </Button>
            )}
          </Col>
        </Row>
      </Row>

      <Row className="assignment-item-bottom-buttons" justify={admin && !assignment.report ? 'space-between' : 'center'}>
        {(assignment.authorId === userId || deputyManager) && !assignment.report ? (
          <Button className="assignment-item-bottom__button" icon={<SnippetsOutlined />} onClick={() => handleAddReport(assignment)} type={'primary'}>
            Звітувати
          </Button>
        ) : null}
        {admin && (
          <Button
            className="assignment-item-bottom__button"
            onClick={() => handleAddAssignment(assignment)}
            style={{ background: `${orange[5]}`, borderColor: `${orange[5]}`, color: gray[1] }}
            icon={<EditOutlined />}
          >
            Редагувати
          </Button>
        )}
      </Row>
    </Card>
    // </Ribbon>
  );
};

export default AssignmentItem;
