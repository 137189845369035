import React, { useState, useLayoutEffect, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Select, Checkbox } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { MONTHS_SMALL } from 'common_constants/business';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';

import { addNewMessageToChat, setBankCardsList } from '../../store/commonReducer';
import { request, success, error, warn } from '../../tools';

const AccountantChatInputWrapper = ({ chatId, chatType }) => {
  const dispatch = useDispatch();
  const bankCards = useSelector((state) => state.common.bankCards);
  const chatsData = useSelector((state) => state.common.chatsData);

  const [AI, setAI] = useState(true);
  const [contractData, setContractData] = useState({});
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState('');
  const [sugar, setSugar] = useState();
  const [remainingMoney, setRemainingMoney] = useState(0);
  const [dataCardState, setDataCardState] = useState(() => {
    const found = bankCards?.find((card) => card.f === contractData.f)?.i;

    return found ? [found] : [];
  });

  const currentChat = useMemo(() => chatsData?.[chatType]?.find((item) => item._id === chatId) ?? {}, [chatsData, chatId]);

  const getContractData = () => {
    if (!currentChat.contractId) return;

    request.post(
      '/contracts/get',
      {
        _id: currentChat.contractId,
      },
      ({ data, cards }) => {
        dispatch(setBankCardsList(cards));
        setContractData(data?.[0]);
      },
      error,
    );
  };

  const onSend = async () => {
    const validation = () => {
      if (!message.length) {
        warn('', 'Введіть повідомлення');
        return false;
      }

      return true;
    };

    if (!validation()) return;

    setLoading(true);

    await request.post(
      '/contracts/sendDebtMessage',
      {
        C: contractData.C,
        message,
        _id: contractData._id,
        AI,
        i: contractData.i,
        bankCards: dataCardState,
      },
      ({ data }) => {
        success();
        setMessage('');
        dispatch(addNewMessageToChat({ chatId, chatType, data }));
      },
      error,
    );

    setLoading(false);
  };

  const countRemainingMoney = () => {
    if (!contractData?.i) return;

    setLoading(true);

    const reqData = {
      contractI: contractData?.i,
    };

    request.post(
      '/contracts/paymentManagementRemainingMoneyCrm',
      reqData,
      ({ data }) => {
        setRemainingMoney(data?.remainingMoney);
        setLoading(false);
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  const getSugarForClient = () => {
    setLoading(true);

    const params = { contractIndex: contractData?.i, md: contractData?.md };

    request.post(
      '/sugar/getContractSugar',
      params,
      ({ sugar }) => {
        setSugar(sugar);
        setLoading(false);
      },
      error,
    );
  };

  useLayoutEffect(() => {
    let date = dayjs();
    const creation = dayjs(contractData.sd);

    if (date.date() > creation.date()) {
      date = date.add(1, 'month');
    }
    const daysInMonth = date.daysInMonth();
    date = date.set('date', Math.min(daysInMonth, creation.date()));

    const cards = dataCardState.map((card) => bankCards.find((c) => c.i === card)?.m).join('\n\n');
    const m = `Ви маєте здійснити оплату наших послуг у сумі ${-remainingMoney} грн. до ${date.date()}-го ${
      MONTHS_SMALL[date.month()]
    }.\n\nАктуальні реквізити для оплати:\n${cards}\n\nПісля оплати, просимо надіслати квитанцію Вашому юристу`;
    setMessage(m);
  }, [contractData, bankCards, dataCardState, remainingMoney]);

  useEffect(() => {
    countRemainingMoney();
  }, [sugar, contractData]);

  useEffect(() => {
    getSugarForClient();
  }, [contractData]);

  useEffect(() => {
    getContractData();
  }, [currentChat]);

  const cardArr =
    bankCards
      ?.map((i) => (i = { value: i.i, label: i.n }))
      ?.slice()
      ?.sort((a, b) => {
        const [labelA, labelB] = [a.n?.toLowerCase(), b.n?.toLowerCase()];
        return labelA?.localeCompare(labelB);
      }) || [];

  return (
    <div className="accountant-chat-input-wrapper">
      <div className="parameters-wrapper">
        <div className="requisites">
          <Select
            optionFilterProp="label"
            mode="multiple"
            allowClear
            className="select-input cards-select"
            options={cardArr}
            value={dataCardState}
            placeholder="Оберіть реквізити: "
            onChange={(v) => setDataCardState(v)}
          />
        </div>
        <Checkbox checked={AI} onChange={(e) => setAI(e.target.checked)} defaultChecked className="checkbox-ai">
          Обробка через AI
        </Checkbox>
      </div>
      <div className="input-wrapper">
        <Input.TextArea
          autoSize={{
            minRows: 6,
          }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="input"
        />
        <Button type="primary straight-left" onClick={onSend} loading={loading} icon={<SendOutlined />} disabled={!dataCardState.length} />
      </div>
    </div>
  );
};

export default AccountantChatInputWrapper;
