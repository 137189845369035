import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, Select, DatePicker, Button, Upload, AutoComplete, InputNumber } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { editExpense, setModal } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import { EXPENSE_CATEGORIES, ROLES } from 'common_constants/business';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';
import SearchSelectAuthor from '../../components/SearchSelect/SearchSelectAuthor';

const { Option } = Select;

const EditExpense = ({ open, onCancel, data }) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const admin = ROLES[userAuth.role] <= ROLES.admin;
  const FILII = useSelector((state) => state.common.FILII);
  const filName = FILII[data?.fil] || data?.fil;
  const users = useSelector((state) => state.common.users);
  const activeUsers = Object.values(users).filter((user) => !user.fired);

  const [form] = Form.useForm();
  const fileSpanRef = useRef(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAdCategory, setSelectedAdCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [date, setDate] = useState(data?.date ? dayjs(data?.date) : null);
  const [fileList, setFileList] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchCategory, setSearchCategory] = useState(null);

  const dateFormat = 'DD.MM.YYYY';

  useEffect(() => {
    setSelectedCategory(data?.category);
    setSelectedSubCategory(data?.subCategory);
  }, [data]);
  const getDate = (value) => {
    if (value) {
      setDate(value);
    } else {
      setDate(null);
    }
  };

  const initialValues = {
    fil: filName,
    date: dayjs(data?.date),
    amount: data?.amount,
    currency: data?.currency,
    author: data?.author,
    category: EXPENSE_CATEGORIES[data?.category]?.value,
    adCategory: EXPENSE_CATEGORIES[data?.adCategory]?.value,
    subCategory: EXPENSE_CATEGORIES[data?.subCategory]?.value,
    comment: data?.comment,
    receipt: data?.receipt,
    toUser: data?.toUser,
    sugarIndex: data?.sugarIndex,
  };

  const setInitialValues = (form) => {
    const categoryData = EXPENSE_CATEGORIES[data?.category];

    const categoryLabelToDisplay = categoryData
      ? data?.category === 10
        ? categoryData.adCategories.find((subcat) => subcat.value === data.adCategory)?.label || categoryData.label
        : categoryData.label
      : data?.category;

    const subCategoryLabelToDisplay =
      data?.category === 0
        ? categoryData?.subcategories.find((subcat) => subcat.value === parseInt(data.subCategory, 10))?.label || data.subCategory
        : categoryData?.subcategories[data.subCategory]?.label || data?.subCategory;

    if (data) {
      form.setFieldsValue({
        fil: filName,
        date: dayjs(data.date),
        amount: data.amount,
        currency: data.currency,
        author: data.author,
        category: categoryLabelToDisplay,
        adCategory: EXPENSE_CATEGORIES[data.adCategory]?.value,
        subCategory: subCategoryLabelToDisplay,
        comment: data.comment,
        receipt: data.receipt,
        toUser: data.toUser,
        sugarIndex: data.sugarIndex,
      });
    }
  };

  useEffect(() => {
    setInitialValues(form);
  }, [data, form]);

  const handleAdCategoryChange = (value) => {
    setSelectedAdCategory(value);
  };

  const handleFileChange = (file) => {
    setFileList([file.file]);
  };

  const handleUploadImage = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('receipt', fileList[0]);

    await request.post(
      '/expense/uploadImage',
      formData,
      ({ file }) => {
        success('', `Квитанція була успішно додана.`);
        setFileList([]);
        setUploadedFileName(file.name);
        const expenseData = {
          ...form.getFieldsValue(),
          receipt: file.id,
        };
        form.setFieldsValue(expenseData);
        setLoading(false);
      },
      () => {
        error('', `Помилка при додаванні.`);
      },
    );
  };

  useEffect(() => {
    if (fileList.length > 0) {
      handleUploadImage();
    }
  }, [fileList]);

  useEffect(() => {}, [selectedCategory, selectedSubCategory, form.getFieldValue('subCategory')]);

  const handleFormSubmit = async () => {
    setLoading(true);

    const expenseData = {
      fil: Number(form.getFieldValue('fil')) || data?.fil,
      date: form.getFieldValue('date') || data?.date,
      amount: form.getFieldValue('amount'),
      currency: data?.currency,
      author: form.getFieldValue('author') || data?.author,
      category: Number(form.getFieldValue('category')) || data?.category,
      adCategory: selectedCategory === 10 ? (selectedAdCategory !== null ? Number(selectedAdCategory) : null) : data?.adCategory,
      subCategory: selectedSubCategory !== null ? Number(selectedSubCategory) : data?.subCategory,
      comment: form.getFieldValue('comment') || data?.comment,
      receipt: form.getFieldValue('receipt') || data?.receipt,
    };

    if (data?.toUser && selectedCategory !== 8 && selectedSubCategory !== 2) {
      expenseData.toUser = null;
    }

    if (selectedCategory === 8 && selectedSubCategory === 2) {
      expenseData.toUser = form.getFieldValue('toUser') || data?.toUser;
    }

    if (data?.sugarIndex && selectedCategory !== 6 && selectedSubCategory !== 0) {
      expenseData.sugarIndex = null;
    }

    if (selectedCategory === 6 && selectedSubCategory === 0) {
      expenseData.sugarIndex = form.getFieldValue('sugarIndex') || data?.sugarIndex;
    }

    if (!inspector) {
      expenseData.amount = form.getFieldValue('amount') || data?.amount;
    }

    await request.post(
      `/expense/editExpense/${data?._id}`,
      expenseData,
      ({ expense }) => {
        dispatch(
          editExpense({
            id: data?._id,
            updatedData: expense,
            historyData: {
              transactionType: 'edit',
              additionalInfo: '...',
            },
          }),
        );

        success('', 'Витрата була успішно відредагована.');
        dispatch(setModal());
        setLoading(false);
        onCancel();
      },
      () => {
        error('', 'Помилка при редагуванні.');
      },
    );
  };

  const EXPENSE_CATEGORIES_FILTERED = EXPENSE_CATEGORIES.filter((category) => category.value !== 1 && category.value !== 0 && category.value !== 12);

  const ALL_SUBCATEGORIES = EXPENSE_CATEGORIES_FILTERED.map((category) => ({
    label: category.label,
    options: category.subcategories.map((subCategory) => ({ label: subCategory.label, value: subCategory.label })),
  }));

  const handleSearchCategory = (value) => {
    const findSubcategory = ALL_SUBCATEGORIES.map((category) => ({
      ...category,
      options: category.options.filter((subcategory) => subcategory.label.toLowerCase().includes(value.toLowerCase())),
    })).filter((category) => category.options.length > 0);

    setSearchCategory(findSubcategory);
  };

  const onSelectSearchCategory = (value) => {
    const category = EXPENSE_CATEGORIES_FILTERED.filter((category) => category.subcategories.find((subcategory) => subcategory.label === value));
    const subCategory = category[0].subcategories.find((subcategory) => subcategory.label === value);

    setSelectedCategory(() => category[0].value);
    setSelectedSubCategory(() => subCategory.value);

    form.setFieldsValue({
      category: category[0].value,
      subCategory: subCategory.value,
    });
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal className="add_expense_modal" title="Редагувати витрату" open={open} onCancel={onCancel} footer={null}>
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          initialValues={{ fil: inspector ? '' : filName, ...initialValues }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          encType="multipart/form-data"
        >
          <Form.Item label="Філія" name="fil">
            <SearchSelectFil disabled={!inspector} />
          </Form.Item>

          {inspector && (
            <Form.Item label="Автор витрати" name="author" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
              <SearchSelectAuthor />
            </Form.Item>
          )}

          <Form.Item label="Дата" name="date" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <DatePicker onChange={(e) => getDate(e)} locale={locale} value={date} format={dateFormat} />
          </Form.Item>

          <Form.Item label="Сума" name="amount" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <InputNumber onWheel={(e) => e.preventDefault()} onKeyDown={handleKeyPress} disabled={!inspector} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name="search" className="search">
            <AutoComplete
              options={searchCategory}
              onSearch={(text) => handleSearchCategory(text)}
              onSelect={(value) => onSelectSearchCategory(value)}
              status="warning"
            >
              <Input.Search className="no_margin_deep" placeholder="Пошук ..." enterButton />
            </AutoComplete>
          </Form.Item>

          <Form.Item label="Категорія" name="category" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <Select
              onChange={(value) => {
                setSelectedCategory(value);
                setSelectedSubCategory(null);
                setSelectedAdCategory(null);
              }}
            >
              {EXPENSE_CATEGORIES_FILTERED.map((category) => (
                <Option key={category.value} value={category.value} disabled={!inspector && category.value === 9}>
                  {category.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectedCategory === 10 && (
            <Form.Item label="Категорія" name="adCategory">
              <Select onChange={handleAdCategoryChange}>
                {EXPENSE_CATEGORIES.find((category) => category.value === 10).adCategories.map((adCategory) => (
                  <Option key={adCategory.value} value={adCategory.value}>
                    {adCategory.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label="Підкатегорія" name="subCategory">
            <Select
              onChange={(value) => {
                setSelectedSubCategory(value);
              }}
            >
              {selectedCategory &&
                EXPENSE_CATEGORIES.find((category) => category.value === selectedCategory)?.subcategories.map((subCategory) => (
                  <Option key={subCategory.value} value={subCategory.value}>
                    {subCategory.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          {((selectedCategory === 8 && selectedSubCategory === 2) || (selectedCategory === 8 && selectedSubCategory === 5)) && (
            <Form.Item label="Кому" name="toUser" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
              <Select
                showSearch
                placeholder="Оберіть користувача"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {activeUsers
                  .filter((user) => user._id !== userAuth._id)
                  .map((user, i) => (
                    <Option value={user._id} key={i}>
                      {user.name + '-' + user.p}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          {selectedCategory === 6 && selectedSubCategory === 0 && (
            <Form.Item label="№ сахорка" name="sugarIndex" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
              <InputNumber />
            </Form.Item>
          )}

          <Form.Item label="Коментар" name="comment">
            <TextArea
              placeholder="Введіть коментар"
              autoSize
              aria-label="We need to enter the other side on the check of our future template document"
            />
          </Form.Item>

          <Form.Item label="Квитанція" name="receipt">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Upload customRequest={() => {}} showUploadList={false} beforeUpload={() => false} fileList={fileList} onChange={handleFileChange}>
                <Button type="dashed" icon={<UploadOutlined />} style={{ width: '200px' }}>
                  Вибрати файл
                </Button>
              </Upload>
              {uploadedFileName && <span>{uploadedFileName}</span>}
            </div>
            {fileList.length > 0 && (
              <>
                <br />
                <span ref={fileSpanRef}>{fileList[0].name}</span>
              </>
            )}
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit">
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditExpense;
