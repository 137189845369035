import { notification } from 'antd';

const notify = (message) => {
  notification['warning']({
    message: 'Увага!',
    description: message,
  });
};

export default notify;
