import React from 'react';
import { Col, Row, Rate, Button, Typography } from 'antd';

import './BlockRating.scss';

const { Title } = Typography;

const BlockRating = ({ rating, setRating, sendVote }) => {
  const handleChangeRating = (value) => setRating(value);

  return (
    <Col className="blockRating">
      <Row justify={'center'} className="blockRating__rate" align={'middle'}>
        <Rate onChange={handleChangeRating} value={rating} />
      </Row>
      <Row justify={'center'}>
        <Title level={4}>Оцінка Діяльності блоку</Title>
      </Row>
      <Row justify={'center'} className="blockRating-successButton-inner">
        <Button type="primary" onClick={sendVote}>
          Відправити
        </Button>
      </Row>
    </Col>
  );
};

export default BlockRating;
