import { Modal, Select } from 'antd';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../store/commonReducer';

import './SphereEditServiceItem.scss';
import clsx from 'clsx';
import { useGetSphereServiceCategoriesQuery, useUpdateSphereServiceItemMutation } from '../../store/apis/sphere-api';

const SphereEditServiceItem = () => {
  const dispatch = useDispatch();
  const serviceItem = useSelector((state) => state.common.modal.data);

  const { data: categories } = useGetSphereServiceCategoriesQuery();
  const [updateServiceItem] = useUpdateSphereServiceItemMutation();

  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      categoryId: serviceItem.categoryId,
      text: {
        ua: serviceItem.text.ua,
        en: serviceItem.text.en,
        de: serviceItem.text.de,
      },
      url: serviceItem.url,
      isTitle: serviceItem.isTitle,
      position: {
        x: serviceItem.position.x,
        y: serviceItem.position.y,
      },
    },
  });
  const categoryId = watch('categoryId');

  const handleUpdateServiceItem = async (data) => {
    await updateServiceItem({ ...data, id: serviceItem._id });
    dispatch(setModal());
  };
  return (
    <Modal open className="sphere-edit-service-item" title={'Редагування сервісу'} onCancel={() => dispatch(setModal())} footer={null}>
      <form className={clsx('form-container', { expanded: true })} onSubmit={handleSubmit(handleUpdateServiceItem)}>
        <div>
          <label htmlFor="categoryType">Тип категорії</label>
          <Select className="select" id="categoryType" value={categoryId} onChange={(value) => setValue('categoryId', value)}>
            {categories?.data.map((category) => (
              <Select.Option key={category._id} value={category._id}>
                {category.title.ua}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <label htmlFor="text-ua">Текст українською</label>
          <input id="text-ua" {...register('text.ua', { required: true })} placeholder="Податки" />
        </div>

        <div>
          <label htmlFor="text-en">Текст англійською</label>
          <input id="text-en" {...register('text.en', { required: true })} placeholder="Taxes" />
        </div>

        <div>
          <label htmlFor="text-de">Текст німецькою</label>
          <input id="text-de" {...register('text.de', { required: true })} placeholder="Takses" />
        </div>

        <div>
          <label htmlFor="url">URL</label>
          <input id="url" {...register('url', { required: true })} placeholder="https://example.com" />
        </div>

        <div>
          <label htmlFor="position-x">Position X</label>
          <input id="position-x" type="number" step="any" {...register('position.x', { required: true })} placeholder="0.3" />
        </div>

        <div>
          <label htmlFor="position-y">Position Y</label>
          <input id="position-y" type="number" step="any" {...register('position.y', { required: true })} placeholder="-0.5" />
        </div>

        <div className="checkbox-wrapper">
          <label htmlFor="isTitle" className="checkbox-label">
            Is Title
            <input type="checkbox" id="isTitle" {...register('isTitle')} />
          </label>
        </div>

        <button type="submit">Оновити сервіс</button>
      </form>
    </Modal>
  );
};

export default SphereEditServiceItem;
