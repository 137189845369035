import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { PlusCircleOutlined, DeleteFilled, EditFilled, CaretRightOutlined } from '@ant-design/icons';

import { ADD_WEBINAR } from 'common_constants/modals';
import { setModal, setWebinars, deleteWebinars, editWebinars } from '../../store/commonReducer';
import { error, request } from '../../tools';
import { WEBINAR_STATUSES } from 'common_constants/business';
import './Webinars.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Europe/Kiev');

const getCutLink = (link) => {
  return link.length > 30 ? link.slice(0, 30) + '...' : link;
};

const Webinars = () => {
  const dispatch = useDispatch();
  const webinars = useSelector((state) => state.common.webinars);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Інформація',
      key: 'info',
      width: 200,
      render: (record) => {
        const webinarDate = dayjs(record.startDateWithTime).tz().format('DD.MM');
        const webinarTime = dayjs(record.startDateWithTime).tz().format('HH:mm');
        return (
          <div className="webinarInfo">
            <span>
              <b>Назва: </b> {record.name}
            </span>
            <br />
            <span>
              <b>Ціна: </b> {record.price}
            </span>
            <br />
            <span>
              <b>Дата: </b> {webinarDate + ' - ' + webinarTime}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render: (record) => {
        return (
          <div>
            <div>
              <Tag
                key={record._id}
                color={record.status === WEBINAR_STATUSES.STARTED ? 'green' : record.status === WEBINAR_STATUSES.PLANNED ? 'yellow' : 'red'}
              >
                {record.status === WEBINAR_STATUSES.PLANNED
                  ? 'Запланований'
                  : record.status === WEBINAR_STATUSES.FINISHED
                  ? 'Завершено'
                  : 'В процесі'}
              </Tag>
            </div>
            <br />
            {record.status !== WEBINAR_STATUSES.FINISHED && (
              <Button
                type="primary"
                disabled={record.disabled}
                onClick={() => {
                  handleWebinarStatus(record._id, record.status);
                }}
              >
                {record.status === WEBINAR_STATUSES.STARTED ? 'Завершити' : 'Розпочати'}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: 'Дії',
      key: 'actions',
      width: 80,
      render: (record) => {
        return (
          <div className="btns" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Button
              className="edit"
              disabled={record.disabled}
              type="primary"
              onClick={() => {
                dispatch(setModal({ name: ADD_WEBINAR, data: record }));
              }}
            >
              <EditFilled />
            </Button>
            <Button className="remove" disabled={record.disabled} type="primary" onClick={() => deleteWebinar(record._id)}>
              <DeleteFilled />
            </Button>
          </div>
        );
      },
    },
  ];

  const getAllWebinars = () => {
    setLoading(true);

    request.post(
      '/webinars',
      {},
      ({ data }) => {
        dispatch(setWebinars(data));
        setLoading(false);
      },
      error,
    );
  };

  const deleteWebinar = (id) => {
    if (!id) {
      error('', 'Будь ласка, перезавантажте сторінку і спробуйте знову!');
    }

    request.post(
      '/webinars/remove',
      { _id: id },
      () => {
        dispatch(deleteWebinars(id));
      },
      error,
    );
  };

  const handleWebinarStatus = (id, status) => {
    if (!id) {
      error('', 'Будь ласка, перезавантажте сторінку і спробуйте знову!');
    }

    const newStatus = Number(status) === WEBINAR_STATUSES.STARTED ? WEBINAR_STATUSES.FINISHED : WEBINAR_STATUSES.STARTED;

    const body = { _id: id, status: newStatus };

    request.post(
      '/webinars/updateStatus',
      body,
      () => {
        dispatch(editWebinars(body));
      },
      error,
    );
  };

  useEffect(() => {
    getAllWebinars();
  }, []);

  return (
    <div className="webinarsPage default_page">
      <h1>Вебінари</h1>

      <Button type="primary" className="button" onClick={() => dispatch(setModal({ name: ADD_WEBINAR }))}>
        <PlusCircleOutlined /> Створити
      </Button>

      <Table
        dataSource={webinars}
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          expandedRowRender: (item) => <WebinarInfo data={item} />,
          expandedRowClassName: (record) => (record.disabled ? 'deleted' : ''),
        }}
        rowClassName={(record) => (record.disabled ? 'deleted' : '')}
        rowKey={(item) => item._id}
        loading={loading}
        style={{ marginTop: '10px' }}
        resizable
      />
    </div>
  );
};

export default Webinars;

const WebinarInfo = ({ data }) => {
  return (
    <div className="webinar-expanded">
      <div>
        <div>
          <b>Стрім: </b>
          <div>{getCutLink(data.link)}</div>
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = data.link;
            }}
          >
            <CaretRightOutlined />
          </Button>
        </div>
        <div>
          <b>Тизер: </b>
          <div>{data.teaser && getCutLink(data.link)}</div>
        </div>
        {data.teaser ? (
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = data.teaser;
            }}
          >
            <CaretRightOutlined />
          </Button>
        ) : (
          'Тизер не додано'
        )}
      </div>
      <div className="image-wrapper">
        {data.teaserImg && <img src={process.env.REACT_APP_API + `/webinarImages/${data.teaserImg}.${data.extension}`} alt="preview" />}
      </div>
    </div>
  );
};
