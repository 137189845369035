import React, { useState } from 'react';
import { Divider, Tag, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { request, error, success } from '../../../../../tools';

const ArchiveInfo = ({ data }) => {
  const history = useHistory();

  const [isLoadingConflicts, setIsLoadingConflicts] = useState(false);

  const rates = data.archiveVotes?.map((i) => i.rating).filter((i) => i) ?? [];
  const rate = rates.reduce((a, b) => a + b, 0) / rates.length;
  const archiveCode = dayjs(data.ad)?.diff(dayjs('01-01-1900'), 'day') + 2;

  const onComplain = (item) => {
    if (!item?.votedUserId) {
      return;
    }

    setIsLoadingConflicts(true);

    const requestData = {
      secondSideId: item?.votedUserId,
    };

    request.post(
      '/conflicts/add',
      requestData,
      (req) => {
        setIsLoadingConflicts(false);
        success('Успіх!', 'Конфлікт створено.');
        history.push('/conflicts', { shallow: true });
      },
      () => {
        setIsLoadingConflicts(false);
        error();
      },
    );
  };

  return (
    <>
      <Divider className="divider-contract-item-info" />
      <span className="orange">
        <b>Архів:</b> a/{archiveCode}/{data.i},
      </span>
      <span className="orange">
        <b>Дата переведення в архів:</b> {dayjs(data.ad)?.format('DD.MM.YYYY')}
      </span>
      <span className="orange">
        <b>Середея оцінка діяльності блоку:</b> <Tag color="green">{rate ? rate.toFixed(2) : 'Не оцінено'}</Tag>
      </span>
      {data?.archiveVotes?.map((item, index) => (
        <span key={index}>
          <p>
            Оцінка: <b>{item.rating}</b>
            <Button size="small" onClick={() => onComplain(item)} loading={isLoadingConflicts}>
              Оскаржити
            </Button>
          </p>
        </span>
      ))}
      <br />
    </>
  );
};

export default ArchiveInfo;
