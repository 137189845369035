import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slider';

import { setModal, editConflict } from '../../store/commonReducer';
import { request, error } from '../../tools';

import './styles.scss';

const MAX_POINT = 100;

const ConflictComplete = () => {
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.common.modal);
  const users = useSelector((state) => state.common.users);

  const [points, setPoints] = useState([0, MAX_POINT]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onCancel = () => {
    dispatch(setModal());
  };

  const handleSliderChange = (newValues) => {
    setPoints(newValues);
  };

  const onSaveConflictResults = () => {
    setIsButtonLoading(true);

    const requestData = {
      points: [points[0], MAX_POINT - points[1]],
    };

    request.post(
      `/conflicts/setResults/${modalData?.data?._id}`,
      requestData,
      (req) => {
        dispatch(editConflict(req.data));
        setIsButtonLoading(false);
        dispatch(setModal());
      },
      () => {
        error();
        setIsButtonLoading(false);
      },
    );
  };

  return (
    <Modal open className="conflict-complete" title="Завершення конфлікту" footer={null} onCancel={onCancel}>
      <div>
        <div className="conflict-complete-slider-labels">
          <p>{users[modalData?.data?.firstSide]?.name}</p>
          <p>{users[modalData?.data?.secondSide]?.name}</p>
        </div>
        <Slider
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={[0, MAX_POINT]}
          ariaLabel={['Lower thumb', 'Upper thumb']}
          ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
          renderThumb={(props, state) => <div {...props}>{state.index === 0 ? state.valueNow : MAX_POINT - state.valueNow}</div>}
          onChange={handleSliderChange}
          pearling
          minDistance={0}
        />

        <div>
          <Button loading={isButtonLoading} type="primary" className="btn-save" onClick={onSaveConflictResults}>
            Зберегти
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConflictComplete;
