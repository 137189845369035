import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBankCardsItem, setModal, editBankCardsItem } from '../../store/commonReducer';

import { Form, Input, Button, Select, Modal, Spin, Typography, Space, Popconfirm } from 'antd';
import { FileAddOutlined, CloseCircleOutlined, FileAddTwoTone, EditOutlined, EditTwoTone } from '@ant-design/icons';
import { orange } from '@ant-design/colors';

import { TYPES_OF_BANK_ACCOUNTS } from 'common_constants/business';
import { request, error, success, warn, info } from '../../tools';
import { Box } from 'common_components';

import './styles.scss';

const AddBankCards = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const filii = useSelector((state) => state.common.filii);
  const userAuth = useSelector((state) => state.common.userAuth);
  const currentData = useSelector((state) => state.common.modal);

  const currentItem = currentData?.data;

  const [{ Item }, { Option }, { Text, Title }, { TextArea }, [form]] = [Form, Select, Typography, Input, Form.useForm()];

  const coreMegaState = {
      loading: false, //* Завантаження
      filial: currentItem?.f ?? undefined,
      specification: currentItem?.z ?? undefined, //* Тип рахунку
      nameCard: currentItem?.n ?? undefined, //* Назва картки
      textSMS: currentItem?.m ?? undefined, //* Текст СМС
      merchantId: currentItem?.merchantId ?? undefined,
      secretKey: currentItem?.secretKey ?? undefined,
    },
    [megaState, setMegaState] = useState(coreMegaState);

  const cancel = () => info('', 'Ви не згодні зі своїм рішенням.'),
    onCancel = (check) => {
      if (check !== 'success') cancel();
      dispatch(setModal());
    };

  const add_document = async (data) => {
    const validation = (_nameCard, _textSMS, _specification, _filial, _userAuth_id, _merchantId, _secretKey) => {
      const valBasic = (v) => !v || v === '';

      const individualEntrepreneurAccountType = _specification === '2';

      if (valBasic(_nameCard) || _nameCard?.length < 10) {
        warn('', 'Для додавання рахунку необхідно вказати його назву в потрібному полі.', { _nameCard: !!_nameCard });
        return;
      }

      if (valBasic(_textSMS) || _textSMS?.length < 10) {
        warn('', 'Для додавання рахунку потрібно написати текст повідомлення (SMS)', { _textSMS: !!_textSMS });
        return;
      }

      if (valBasic(_specification) || typeof _specification !== 'string') {
        warn('', 'Для додавання рахунку потрібно вказати його специфікацію.', { _specification: !!_specification });
        return;
      }
      if (valBasic(_filial) || typeof _filial !== 'number') {
        warn('', 'Для додавання рахунку потрібно вказати філію.', { _filial: !!_filial });
        return;
      }

      if (!_userAuth_id) {
        warn('', 'Несанкціонований доступ. Зверніться до адміністратора компанії АО Захисту.', { _id: !!_userAuth_id });
        return;
      }

      if ((individualEntrepreneurAccountType && !_merchantId) || (individualEntrepreneurAccountType && _merchantId?.length < 6)) {
        warn('', 'Будь ласка, вкажіть merchant id.', { _merchantId: !!_merchantId });
        return;
      }

      if ((individualEntrepreneurAccountType && !_secretKey) || (individualEntrepreneurAccountType && _secretKey?.length < 10)) {
        warn('', 'Будь ласка, вкажіть secret key.', { _secretKey: !!_secretKey });
        return;
      }

      return true;
    };

    const { nameCard, textSMS } = data;

    const data_transfer = {
      f: megaState.filial,
      z: megaState.specification,
      n: nameCard,
      m: textSMS,
      merchantId: megaState.merchantId,
      secretKey: megaState.secretKey,
      ...(!currentItem ? { a: userAuth._id } : { a: currentItem.a }), //* Автор картки
      ...(!currentItem ? {} : { _id: currentItem._id, ac: userAuth._id }), //* id картки //* Автор редагування
    };

    if (!validation(nameCard, textSMS, megaState.specification, megaState.filial, userAuth._id, megaState.merchantId, megaState.secretKey)) return;

    setMegaState((prev) => ({ ...prev, loading: true }));

    await request.post(
      '/bankCards/addBankCard',
      data_transfer,
      (req) => {
        success('Успіх!', `Рахунок успішно ${currentItem ? 'змінено' : 'створено'}!`);

        dispatch(currentItem ? editBankCardsItem(req.dateCard) : addBankCardsItem(req.dateCard));

        form.resetFields();

        dispatch(setModal());
      },
      error,
    );

    setMegaState((prev) => ({ ...prev, loading: false }));
  };

  const redStart = <span style={{ color: 'red' }}>*</span>;
  const optionsSpase = { direction: 'vertical', size: 0, style: { display: 'flex' } };

  const dynamicText = {
    0: {
      _nameCard: 'м. Київ, Моно, РН',
      _textSMS: `Моно Банк\n5375 4141 3130 1375\nОтримувач – Цієр Раїса Миколаївна`,
    },
    1: {
      _nameCard: 'Розрахунковий рахунок від Райффайзен банку',
      _textSMS: `Отримувач: ТОВ "Адвокатське об\`єднання Захист"\nП/р: UA593808050000000026002816922\nАТ "РАЙФФАЙЗЕН БАНК" (код банку 300335)\nКод ЄДРПОУ: 44575399`,
    },
  }[megaState.specification] || { _nameCard: '', _textSMS: '' };

  return (
    <Modal
      open
      className="library-add-document"
      title={
        <Title style={{ margin: 0 }} level={4}>
          {currentItem ? <EditTwoTone style={{ fontSize: '22px' }} /> : <FileAddTwoTone style={{ fontSize: '22px' }} />}&nbsp;&nbsp;Форма для&nbsp;
          {currentItem ? 'змінення' : 'додавання'} банківського рахунку
        </Title>
      }
      onCancel={onCancel}
      footer={null}
    >
      <Spin size="large" tip={'Зачекайте, йде завантаження'} spinning={megaState.loading}>
        <Form className="lib-form" form={form} ref={formRef} onFinish={add_document} layout="vertical">
          <Space {...optionsSpase}>
            <Text strong>Філія: {redStart}</Text>
            <Item preserve={true} name="fil" initialValue={megaState.filial}>
              <Select
                showSearch
                allowClear
                value={megaState.filial}
                onChange={(value) => setMegaState((prev) => ({ ...prev, filial: value }))}
                placeholder="Оберіть філію"
                filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {Object.values(filii).map((i) => (
                  <Option key={i.i} value={i.i}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Space>
          <Space {...optionsSpase}>
            <Text strong>Специфікація рахунку: {redStart}</Text>
            <Item preserve={true} name="spec_doc" initialValue={megaState.specification}>
              <Select
                showSearch
                allowClear
                value={megaState.specification}
                onChange={(value) => setMegaState((prev) => ({ ...prev, specification: value }))}
                placeholder="Виберіть спеціфікацію"
                filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {TYPES_OF_BANK_ACCOUNTS.map((i, index) => (
                  <Option key={index} value={String(index)}>
                    {i.label}
                  </Option>
                ))}
              </Select>
            </Item>
          </Space>

          {megaState.specification === '2' ? (
            <Space {...optionsSpase}>
              <Text strong>Merchant id: {redStart}</Text>

              <Item
                preserve={true}
                name="merchantId"
                rules={[
                  { required: megaState.specification === '2', message: 'Merchant id - обов`язкове поле' },
                  { min: 6, message: 'Не коректне поле Merchant id!' },
                ]}
                initialValue={megaState.merchantId}
                onInput={(e) => setMegaState((prev) => ({ ...prev, merchantId: e.target.value }))}
              >
                <TextArea
                  autoSize
                  showCount={{ formatter: ({ count }) => `${count} символів` }}
                  aria-label="We need to enter the name of our future template document"
                  placeholder={dynamicText._merchantId}
                />
              </Item>
            </Space>
          ) : null}

          {megaState.specification === '2' ? (
            <Space {...optionsSpase}>
              <Text strong>Secret key: {redStart}</Text>

              <Item
                preserve={true}
                name="secretKey"
                rules={[
                  { required: megaState.specification === '2', message: 'Secret key - обов`язкове поле' },
                  { min: 10, message: 'Не коректний secret key!' },
                ]}
                initialValue={megaState.secretKey}
                onInput={(e) => setMegaState((prev) => ({ ...prev, secretKey: e.target.value }))}
              >
                <TextArea
                  autoSize
                  showCount={{ formatter: ({ count }) => `${count} символів` }}
                  aria-label="We need to enter the name of our future template document"
                  placeholder={dynamicText._secretKey}
                />
              </Item>
            </Space>
          ) : null}
          <Space {...optionsSpase}>
            <Text strong>Назва рахунку: {redStart}</Text>
            <Text type="secondary">
              Ця назва рахунку буде використовуватися в списку фільтрів місячної розсилки CRM для зручності вибору картки юриста. В назві неповинно
              бути менше ніж 10 символів.
            </Text>

            <Item
              preserve={true}
              name="nameCard"
              rules={[
                { required: true, message: 'Назва рахунку - обов`язкове поле' },
                { min: 10, message: 'Назва рахунку не корректне!' },
              ]}
              initialValue={megaState.nameCard}
            >
              <TextArea
                autoSize
                showCount={{ formatter: ({ count }) => `${count} символів` }}
                aria-label="We need to enter the name of our future template document"
                placeholder={dynamicText._nameCard}
              />
            </Item>
          </Space>

          <Space {...optionsSpase}>
            <Text strong>Текст СМС: {redStart}</Text>
            <Text type="secondary">
              Цей текст буде використано для відправлення СМС клієнту по програмі місячної розсилки. В тексті неповинно бути менше ніж 10 символів.
            </Text>
            <Item
              preserve={true}
              name="textSMS"
              rules={[
                { required: true, message: 'Текст СМС - обов`язкове поле' },
                { min: 10, message: 'Текст СМС не корректне!' },
              ]}
              initialValue={megaState.textSMS}
            >
              <TextArea
                autoSize={{ minRows: 4 }}
                showCount={{ formatter: ({ count }) => `${count} символів` }}
                aria-label="We need to enter the other side on the check of our future template document"
                placeholder={dynamicText._textSMS}
              />
            </Item>
          </Space>

          <Box className="group-button">
            <Popconfirm
              title="Підтвердження"
              description={`Ви впевнені, що хочете ${currentItem ? 'змінити' : 'створити'} цей рахунок?`}
              onConfirm={() => formRef.current.submit()}
              onCancel={cancel}
              className="group-button__btn"
              okText="Гаразд"
              cancelText="Скасувати"
              disabled={!megaState.specification}
            >
              <Button
                disabled={!megaState.specification}
                className="group-button__btn"
                type="primary"
                htmlType="submit"
                icon={currentItem ? <EditOutlined /> : <FileAddOutlined />}
                style={{
                  marginRight: '15px',
                  ...(currentItem && megaState.specification ? { backgroundColor: orange.primary } : {}),
                }}
              >
                {currentItem ? 'Змінити' : 'Створити'}
              </Button>
            </Popconfirm>
            <Button className="group-button__btn" danger type="primary" onClick={onCancel}>
              <CloseCircleOutlined />
              Скасувати
            </Button>
          </Box>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddBankCards;
