import React from 'react';
import { PAY_TYPES, FONDY_PAY_TYPE } from 'common_constants/business';
import SearchSelect from './SearchSelect';

const SearchSelectPayTypeSugar = ({ onChange, value, className, style, allPayTypes, isInspector }) => {
  let filteredPayTypes;
  if (allPayTypes || isInspector) {
    filteredPayTypes = Object.values(PAY_TYPES).filter((payType) => payType.value !== 51 && payType.value !== FONDY_PAY_TYPE);
  } else {
    filteredPayTypes = Object.values(PAY_TYPES).filter(
      (payType) =>
        payType.value === 1 ||
        payType.value === 2 ||
        payType.value === 18 ||
        payType.value === 26 ||
        payType.value === 50 ||
        payType.value === 'other',
    );
  }

  return (
    <SearchSelect
      className={className}
      style={style}
      placeholder="Оберіть куди платив"
      list={filteredPayTypes}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectPayTypeSugar;
