import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row, TimePicker, Select, Space, Typography, Divider, List, Spin } from 'antd';
import dayjs from 'dayjs';
import { MONTHS, ROLES, USER_ACTIONS } from 'common_constants/business';

import SearchSelectUsersMultiple from '../../components/SearchSelect/SearchSelectUsersMultiple';
import { request, error } from '../../tools';
import JournalCard from './JournalCard';
import TimeLine from '../../components/TimeLine/TimeLine';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
const { Title } = Typography;

const AUTOUPDATE_DELAY = 120000;

export const JournalItem = () => {
  const FILII = useSelector((state) => state.common.FILII);

  const { fileName } = useParams();
  const [logsList, setLogsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const currentDate = dayjs(fileName.split('.')[0]);
  const currentDay = currentDate.date();
  const currentMonth = currentDate.month();
  const currentYear = currentDate.year();

  const [filterTimeStart, setFilterTimeStart] = useState(0);
  const [filterTimeEnd, setFilterTimeEnd] = useState(86340);

  const [filterUserAction, setFilterUserAction] = useState('all');
  const [filterUserFil, setFilterUserFil] = useState('all');
  const [filterUserRole, setFilterUserRole] = useState('all');
  const [filterUserName, setFilterUserName] = useState('all');

  const [listToRender, setListToRender] = useState([]);

  const filterUserRoleOptions = useMemo(() => {
    return Object.keys(ROLES).map((e) => ({
      value: e,
      label: e,
    }));
  }, []);

  const getUserNameFilterOptions = () => {
    const userNameFilterOptionsArray = Array.from(new Set(logsList.filter((e) => e.log.user).map((e) => e.log.user.name)));
    const userNameFilterOptions = userNameFilterOptionsArray.map((e) => ({ value: e, label: e }));
    return userNameFilterOptions;
  };

  const handleChangeUserAction = useCallback((value) => (value ? setFilterUserAction(value) : setFilterUserAction('all')), []);

  const handleChangeUserFil = useCallback((value) => (value ? setFilterUserFil(value) : setFilterUserFil('all')), []);

  const handleChangeUserRole = useCallback((value) => setFilterUserRole(value === undefined ? 'all' : value), []);

  const handleChangeUserName = useCallback((value) => {
    return value ? setFilterUserName(value) : setFilterUserName('all');
  }, []);

  const handleOnTimeRangeChange = useCallback((value) => {
    if (value) {
      const startTime = value[0];
      const endTime = value[1];
      const startTimeToSeconds = 3600 * startTime.hour() + 60 * startTime.minute() + startTime.second();
      const endTimeToSeconds = 3600 * endTime.hour() + 60 * endTime.minute() + endTime.second();
      setFilterTimeStart(startTimeToSeconds);
      setFilterTimeEnd(endTimeToSeconds);
      return;
    }
    setFilterTimeStart(0);
    setFilterTimeEnd(86340);
  }, []);

  const getLogsList = useCallback(() => {
    setLoading(true);
    request.post(
      '/journal/getUserLogItemDetails',
      { fileName },
      (res) => {
        setLogsList(res.data);
        setLoading(false);
      },
      error,
    );
  }, [fileName]);

  useEffect(() => {
    const filteredList = logsList.filter((e) => {
      const currentElemTime = dayjs(e.date);
      const currentElemTimeToSeconds = 3600 * currentElemTime.hour() + 60 * currentElemTime.minute() + currentElemTime.second();

      const compareTimeRange = currentElemTimeToSeconds >= filterTimeStart && currentElemTimeToSeconds <= filterTimeEnd;
      const compareUserActions =
        filterUserAction === USER_ACTIONS.FINANCIAL_OPERATION
          ? e.log.action === USER_ACTIONS.ADD_NEW_SUGAR ||
            e.log.action === USER_ACTIONS.ADD_NEW_EXPENSE ||
            e.log.action === USER_ACTIONS.EDIT_EXPENSE ||
            e.log.action === USER_ACTIONS.EDIT_SUGAR
          : e.log.action === filterUserAction || filterUserAction === 'all';
      const compareUserFil = e.log.user?.fil === filterUserFil || filterUserFil === 'all';
      const compareUserRole = e.log.user?.role === filterUserRole || filterUserRole === 'all';
      const compareUserName = e.log.user?.name === filterUserName || filterUserName === 'all';

      return compareTimeRange && compareUserActions && compareUserFil && compareUserRole && compareUserName ? true : false;
    });

    const filteredItems = filteredList
      .map((e, index) => {
        const date = dayjs(e.date);
        const log = e.log;
        return <JournalCard key={index} date={date} log={log} />;
      })
      .reverse();
    setListToRender(filteredItems);
  }, [logsList, filterTimeStart, filterTimeEnd, filterUserAction, filterUserFil, filterUserRole, filterUserName]);

  useEffect(() => {
    getUserNameFilterOptions();
  }, []);

  useEffect(() => {
    getLogsList();

    const interval = setInterval(getLogsList, AUTOUPDATE_DELAY);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ position: 'relative', padding: '10px' }}>
      <Row>
        <Col span={24}>
          <Title level={4}>Журнал активності користувачів</Title>
        </Col>
      </Row>
      <Divider orientation="left" plain>
        {`${currentDay} ${MONTHS[currentMonth]} ${currentYear}`}
      </Divider>
      <div className="journal-filters-inner">
        <div className="journal-filters-item">
          <Title level={5}>Часовий інтервал</Title>
          <TimePicker.RangePicker placeholder={['Від', 'До']} onChange={handleOnTimeRangeChange} />
        </div>
        <div className="journal-filters-item">
          <Title level={5}>Дія користувача</Title>
          <Space wrap>
            <Select
              placeholder={'Всі'}
              allowClear={true}
              style={{
                width: 200,
              }}
              onChange={handleChangeUserAction}
              options={Object.values(USER_ACTIONS).map((i) => ({ value: i, label: i }))}
            />
          </Space>
        </div>
        <div className="journal-filters-item">
          <Title level={5}>Філія</Title>
          <Space wrap>
            <SearchSelectFil onChange={handleChangeUserFil} value={filterUserFil} />
          </Space>
        </div>
        <div className="journal-filters-item">
          <Title level={5}>Роль користувача</Title>
          <Space wrap>
            <Select
              placeholder={'Всі'}
              allowClear={true}
              style={{
                width: 200,
              }}
              onChange={handleChangeUserRole}
              options={filterUserRoleOptions}
            />
          </Space>
        </div>
        <div className="journal-filters-item">
          <Title level={5}>Ім'я користувача</Title>
          <Space wrap>
            <Select
              placeholder={'Всі'}
              allowClear={true}
              style={{
                width: 200,
              }}
              onChange={handleChangeUserName}
              options={logsList.length ? getUserNameFilterOptions() : null}
            />
          </Space>
        </div>
        {/* <div className="journal-filters-item">
          <Space wrap>
            <SearchSelectUsersMultiple
              onChange={handleChangeUserName}
              users={logsList}
              placeholder={'Всі'}
              allowClear={true}
              style={{
                width: 200,
              }}
            />
          </Space>
        </div> */}
      </div>
      <Divider />
      <TimeLine period={120000} />
      <Divider />
      <Spin size="large" spinning={loading} tip="Завантаження" style={{ position: 'absolute', zIndex: '1', left: '48%', top: '320px' }} />
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          pageSize: 10,
          style: { textAlign: 'center' },
        }}
        dataSource={listToRender}
        renderItem={(item) => item}
      />
    </div>
  );
};
