import { useDeleteSphereServiceItemMutation, useGetSphereServiceItemsQuery } from '../../store/apis/sphere-api';
import ServiceItemsListItem from './ServiceItemsListItem';
import { useDispatch } from 'react-redux';
import { setModal } from '../../store/commonReducer';
import { SPHERE_EDIT_SERVICE_ITEM } from 'common_constants/modals';
import { Typography } from 'antd';

const ServiceItemsList = ({ activeCategory }) => {
  const dispatch = useDispatch();

  const { data: serviceItems, isSuccess } = useGetSphereServiceItemsQuery(activeCategory?._id);
  const [deleteServiceItem] = useDeleteSphereServiceItemMutation();

  const handleUpdateCategory = (e, serviceItem) => {
    e.stopPropagation();
    dispatch(setModal({ name: SPHERE_EDIT_SERVICE_ITEM, data: serviceItem }));
  };
  const handleDeleteCategory = async (e, id) => {
    e.stopPropagation();
    if (!window.confirm('Ви точно хочете видалити елемент ?')) return;

    await deleteServiceItem(id);
  };

  return (
    <div className="list-container service-items-list">
      {isSuccess && serviceItems.data.length > 0 ? (
        serviceItems.data.map((serviceItem) => (
          <ServiceItemsListItem
            key={serviceItem._id}
            serviceItem={serviceItem}
            handleUpdateCategory={handleUpdateCategory}
            handleDeleteCategory={handleDeleteCategory}
          />
        ))
      ) : (
        <Typography.Title level={4}>Немає елементів в категорії</Typography.Title>
      )}
    </div>
  );
};

export default ServiceItemsList;
