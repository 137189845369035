export const getHorizontalBarChartOption = (title, yAxisData, series) => {
  return {
    title: {
      text: title,
      left: 'center',
      padding: [40, 0, 0, 0],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const [item1, item2] = params;
        const { dataIndex } = item1;

        const candyExpensesTotalSum = series[0]?.data?.[dataIndex]?.candyExpensesTotalSum ?? 0;
        const candyPrice = series[0]?.data?.[dataIndex]?.candyPrice ?? 0;

        return `
          <b>${item1?.axisValue}</b>
          <br />
          ${item1?.seriesName}: ${item1?.data}
          <br />
          ${item2?.seriesName}: ${item2?.data}
          <br />
          Загальна сума куплених цукерок: ${candyExpensesTotalSum}
          <br />
          Ціна цукерки: ${candyPrice ?? 0}
        `;
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
    },
    series: series.map(({ title, data }) => ({
      name: title,
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
      },
      emphasis: {
        focus: 'none',
      },
      data: data?.map(({ value }) => value),
    })),
  };
};
