import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useResize, Ribbon } from 'common_components';

import { gold, green, volcano } from '@ant-design/colors';
import { CheckOutlined, CloseOutlined, CrownOutlined, DiffOutlined, PhoneOutlined, UpOutlined } from '@ant-design/icons';
import { Card, Divider, Tag, Button } from 'antd';

import { HOTLINE_MEET, HOTLINE_RESULT, POSLUGI, PRAVO, TEXT_VSMS_TO_REACH_HOTLINE } from 'common_constants/business';

import { setCurrentReachBellStatus } from '../../store/commonReducer';

import clsx from 'clsx';
import './FailureToPay.scss';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';

const FailureToPayItem = ({ i, index, blocked }) => {
  const dispatch = useDispatch();
  const resize = useResize();

  const users = useSelector((state) => state.common.users);

  const konsultationOrInfoCentr = i.type === '0' || i.type === '3' || i.type === '6';

  const relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime).locale('uk');
  const now = dayjs()
    .set('hour', 0 + 2)
    .set('minute', 30)
    .set('second', 0); // * start date to 03:00:00 GMT+0300
  const nowEnd = dayjs()
    .set('hour', 0 + 20)
    .set('minute', 0)
    .set('second', 0);

  const bellStatus = useSelector((state) => state.common.currentReachBellStatus);
  const FILII = useSelector((state) => state.common.FILII);

  const [expand, setExpand] = useState(false);

  const [date, setDate] = useState(dayjs(i?.rrh?.bel ?? false));
  const [derResch] = useState(i?.rrh?.der ?? false);

  useEffect(() => {
    if (!bellStatus) return;

    setDate(i._id === bellStatus.bellId ? bellStatus.bellDate : date);
    dispatch(setCurrentReachBellStatus(false));
  }, [!!bellStatus]);

  const style_type = derResch
    ? { backgroundColor: volcano[2] }
    : i.K
    ? { backgroundColor: green[2] }
    : now.unix() < date.unix() && date.unix() < nowEnd.unix()
    ? { backgroundColor: gold[2] }
    : {};

  const style_wants = i.qh?.qd === 2 ? { border: '3px solid #52c41a' } : {};

  let ribbonText;
  let ribbonColor;

  if (!i.cost) {
    ribbonText = 'Безкоштовна';
    ribbonColor = 'blue';
  } else if (!i.transactions?.length) {
    ribbonText = 'Не оплачена';
    ribbonColor = 'red';
  } else if (i.transactions?.length) {
    ribbonColor = 'green';
    const hotlineCreatedAt = i.crAt;
    const transactionCreatedAt = dayjs(i?.transactions[0]?.order_time);
    const hoursDiff = transactionCreatedAt.diff(hotlineCreatedAt, 'hour');
    ribbonText = hoursDiff < 2 ? 'Оплата до 2 год' : 'Оплата після 2 год';
  }

  if (!expand)
    return (
      <Ribbon condition={true} text={ribbonText} color={ribbonColor}>
        <Card
          style={{ ...style_type, ...style_wants }}
          key={index}
          className={clsx({ 'card hotline-reach-item two_columns': true, disabled: i.disabled, edited: i.e, error: blocked })}
          onClick={() => setExpand(true)}
        >
          <div className="row item_failure">
            <span className="item-white-space">
              <b>Філія:</b> {FILII[i.fil] ?? '-'}
            </span>
          </div>
          <div className="row item_failure">
            <span className="item-white-space">
              <b>Послуга:</b> {POSLUGI[i.type] ?? '-'}
            </span>
          </div>
          <div className="row item_failure">
            <b>Час:</b> {i.startT ?? '-'} - {i.endT ?? '-'}
          </div>
          <div className="row item_failure">
            <span className="item-white-space">
              <b>Вид:</b> {PRAVO[i.pravo] ?? '-'}
            </span>
          </div>
        </Card>
      </Ribbon>
    );

  return (
    <>
      <Card
        style={{ ...style_type, ...style_wants }}
        key={index}
        className={clsx({ 'card hotline-reach-item two_columns': true, disabled: i.disabled, edited: i.e, error: blocked })}
      >
        {blocked && (
          <div className="block-wrapper">
            <Tag color="error">Користувач заблокований</Tag>
          </div>
        )}
        {i.vip && (
          <div className="row full vip_block">
            <span>
              VIP <CrownOutlined style={{ color: 'gold', width: '16px', marginLeft: 8 }} />
            </span>
          </div>
        )}
        {i.vip && <div className="row full vip_block"></div>}

        <div className="row item_failure">
          <span className="item-white-space">
            <b>Філія:</b> {FILII[i.fil] ?? '-'}
          </span>
        </div>
        <div className="row item_failure">
          <span className="item-white-space">
            <b>Послуга:</b> {POSLUGI[i.type] ?? '-'}
          </span>
        </div>
        <div className="row item_failure">
          <b>Дата:</b> {dayjs(i.date)?.format('DD.MM.YYYY') ?? '-'}
        </div>
        <div className="row item_failure">
          <span className="item-white-space">
            <b>Ім'я:</b> {i.name ?? '-'}
          </span>
        </div>
        <div className="row item_failure">
          <b>Час:</b> {i.startT ?? '-'} - {i.endT ?? '-'}
        </div>
        <div className="row item_failure">
          <b>{resize[0] >= 500 ? 'Телефон:' : 'Тел.:'}</b> {i.phone ?? '-'}
        </div>
        <div className="row item_failure">
          <span className="item-white-space">
            <b>Вид:</b> {PRAVO[i.pravo] ?? '-'}
          </span>
        </div>
        <div className="row item_failure">
          <b>Сума:</b> {i.cost ?? '-'}
        </div>
        <div className="row item_failure">
          <span className="item-white-space">
            <b>&nbsp;</b>
          </span>
        </div>
        {konsultationOrInfoCentr ? (
          <div className="row item_failure">
            <span className="item-white-space">
              <b>Коментар:</b> {i.comment ?? '-'}
            </span>
          </div>
        ) : (
          <div className="row item_failure"></div>
        )}

        <div className="row item_failure">&nbsp;</div>
        <Divider className="divider-hotline-item-info" />

        <div className="row item_failure">
          <b>Автор:</b> {(window.users && window.users[i.author]?.p) ?? '-'}
        </div>
        <div className="row item_failure">
          <b>Створено:</b> {dayjs(i.crAt)?.format('DD.MM.YYYY HH:mm:ss') ?? '-'}
        </div>
        {konsultationOrInfoCentr ? (
          <div className="row item_failure">
            <b>{resize[0] >= 500 ? 'Призначено:' : 'При-но:'}</b> {(users && users[i.amm]?.p) ?? '-'}
          </div>
        ) : (
          <div className="row item_failure"></div>
        )}
        {konsultationOrInfoCentr ? (
          <div className="row item_failure">
            <b>Запр{resize[0] >= 500 ? 'опонована' : '.'} сума:</b> {i.amp ?? '-'}
          </div>
        ) : (
          <div className="row item_failure"></div>
        )}
        <div className="row item_failure">
          <span className="item-white-space">
            <b>{resize[0] >= 500 ? 'Результат' : 'Рез.'} зустрічі: </b> {konsultationOrInfoCentr ? HOTLINE_RESULT[i.amr] ?? '-' : HOTLINE_MEET[i.s]}
          </span>
        </div>
        {i.s ? (
          <div className="row item_failure">
            <b>Анкетування:</b>
            {i.s === '1' ? <CheckOutlined /> : i.s === '2' ? <CloseOutlined /> : i.s === '3' ? <PhoneOutlined /> : <DiffOutlined />}
          </div>
        ) : (
          <div className="row item_failure"></div>
        )}

        <div className="row item_failure"></div>
        <div className="row item_failure">
          <span className="item-white-space"></span>
        </div>
        <div className="row item_failure">
          <span className="item-white-space">
            <b>Опис звіту:</b> {i?.rrh?.rdr ?? '-'}
          </span>
        </div>
        <div className="row item_failure"></div>

        <div className="row item_failure full controls">
          {derResch && <div style={{ color: 'red' }}>Видалено</div>}
          {i.K !== undefined && <div style={{ color: 'green' }}>Дожали</div>}
          {i.topBell === 1 && <div style={{ color: volcano[5] }}>Є нагадування</div>}
          {i.qh?.qd === 2 && <div style={{ color: 'green' }}>Хоче укласти з нами договір</div>}
        </div>

        <div className="btns">
          <Button className="expand" onClick={() => setExpand(false)}>
            <UpOutlined />
          </Button>
        </div>
      </Card>
    </>
  );
};

export default FailureToPayItem;
