import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import ExportQuantityModal from '../../components/ExportQuantityModal';
import { request, warn, error, success } from '../../tools';
import { LANGUAGE_OPTIONS } from 'common_constants/business';

const ExportActualizations = () => {
  const users = useSelector((state) => state.common.users);

  const max = Object.keys(users).length;
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(max);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const onGenerate = () => {
    const validation = () => {
      if (!start || !end) {
        warn('', 'Для експорту таблиці, потрібно вказати діапазон кількості елементів', { '!!start': !!start, '!!end': !!end });
        return;
      }

      return true;
    };

    if (!validation()) return;

    setLoading(true);

    const transaction = {
      start,
      end,
    };

    request.post(
      '/spreadsheets/exportActualizations',
      transaction,
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);
        setLoading(false);
        setData(req.data);
      },
      error,
    );
  };

  const csvData = [
    [
      `Ім'я`,
      `Готовність до переїзду у зв'язку з підвищенням`,
      'Готовність до переїзду в іншу країну',
      'Країна переїзду',
      'Мови',
      'Рівень володіння',
      'Ідея щодо вдосконалення роботи',
      'Скарги та пропозиції',
      'На скільки Вас влаштовують умови праці',
    ],
  ];

  data?.forEach((i) => {
    const date = dayjs(i.actualization?.date)?.format('DD-MM-YYYY');

    const languages = i.actualization?.languages
      ?.map((languageId) => {
        const language = LANGUAGE_OPTIONS.find((lang) => lang.value === languageId);
        return language ? language.label : '';
      })
      .join(', ');

    csvData.push([
      i.name ?? '',
      i.actualization?.relocation ?? '',
      i.actualization?.relocationToCountry ?? '',
      i.actualization?.selectedCountry ?? '',
      languages,
      i.actualization?.languages
        ?.map((languageId) => {
          const proficiencyKey = `languageProficiency_${languageId}`;
          const proficiencyLevel = i.actualization[proficiencyKey];
          const languageLabel = LANGUAGE_OPTIONS.find((lang) => lang.value === languageId)?.label;
          return `${languageLabel}: ${proficiencyLevel ?? ''}`;
        })
        .join(', '),
      i.actualization?.idea ?? '',
      i.actualization?.complaints ?? '',
      i.actualization?.working_conditions ?? '',
      date ?? '',
    ]);
  });

  return (
    <ExportQuantityModal
      title={'Експорт актуалізацій'}
      csvData={csvData}
      onGenerate={onGenerate}
      max={max}
      data={!!data}
      loading={loading}
      startA={start}
      setStartA={setStart}
      endA={end}
      setEndA={setEnd}
    />
  );
};

export default ExportActualizations;
