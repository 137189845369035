import { Box, Ribbon } from 'common_components';
import dayjs from 'dayjs';
import React from 'react';
import { Button, Card } from 'antd';
import { RedoOutlined, EditOutlined, FileTextOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import './Item.scss';
import { ROLES } from 'common_constants/business';

const Item = ({
  clientId,
  clientName,
  clientPhone,
  crAt,
  date,
  endT,
  fil,
  i,
  psychologisPhone,
  psychologistId,
  psychologistName,
  startT,
  type,
  _id,
  role,
  user,
  removeHotline,
  reportingHotline,
  editHotline,
  confirmDate,
  result,
}) => {
  const psychologistOnly = ROLES[role] === ROLES.psychologist;
  const admin = ROLES[role] === ROLES.admin;
  const manager = ROLES[role] <= ROLES.manager;
  const isClient = clientId === user._id;
  const FILII = useSelector((state) => state.common.FILII);

  return (
    <Card
      key={_id}
      className="item-card"
      style={{
        width: admin ? '100%' : '',
        marginBottom: '16px',
      }}
    >
      <div className="card-content">
        <div className="info-column">
          <div className="row">
            <span>
              <b>Ім'я:</b> {clientName ?? '-'}
            </span>
          </div>
          <div className="row">
            <span>
              <b>Телефон:</b> {clientPhone ?? '-'}
            </span>
          </div>
        </div>

        <div className="time-info">
          <div className="row">
            <span>
              <b>Час:</b> {`${startT ?? '-'} - ${endT ?? '-'}`}
            </span>
          </div>
        </div>
      </div>
      {confirmDate ? (
        <div className={`${result ? 'report' : 'cancelHtl'} archive-wrapper`}>
          {' '}
          Архівна консультація
          <span>{result ? 'Консультація пройшла успішно' : 'Консультація не відбулася'}</span>
        </div>
      ) : (
        <div className="actions">
          {(psychologistOnly || admin) && (
            <>
              <Button
                className="action-button cancelHtl "
                type="default"
                disabled={!(isClient || manager || psychologistOnly)}
                onClick={() => removeHotline(_id)}
              >
                <RedoOutlined /> Скасувати консультацію
              </Button>
              <Button className="action-button edit" type="default" onClick={() => editHotline(_id, date, endT, fil, startT, clientId)}>
                <EditOutlined /> Редагувати консультацію
              </Button>
            </>
          )}
          {!psychologistOnly && (
            <Button className="action-button report" type="default" disabled={!(isClient || manager)} onClick={() => reportingHotline(_id)}>
              <FileTextOutlined /> Звітувати по консультації
            </Button>
          )}
        </div>
      )}
    </Card>
  );
};

export default Item;
