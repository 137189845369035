import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Typography, Badge, Dropdown, Menu, Collapse, Switch } from 'antd';
import { red } from '@ant-design/colors';
import {
  CarryOutOutlined,
  EditOutlined,
  MoneyCollectOutlined,
  TabletFilled,
  FileAddOutlined,
  AreaChartOutlined,
  EllipsisOutlined,
  PlusCircleOutlined,
  FieldTimeOutlined,
  MailOutlined,
  ReadOutlined,
  InteractionOutlined,
  LineChartOutlined,
  BarChartOutlined,
  BankOutlined,
} from '@ant-design/icons';
import ChatWithAccountantButton from './ContractInfoPartials/ChatWithAccountantButton';

import { Box } from 'common_components';
import { ROLES, CHAT_TYPES } from 'common_constants/business';
import {
  INCOMES,
  ASSIGNMENTS_INFO_LIST,
  BUTTON_CONTAINER,
  CONTRACT_TASKS_CRM,
  NEW_CONTRACT,
  CONTRACT_LIST_FILES,
  AUTOPAY_INFO_LIST,
  PAYMENT_MANAGEMENT,
  CONTRACT_PAYROLL_PERCENTS,
  CORRESPONDENCE_MODAL_FOR_THE_CONTRACT,
  LIBRARY_MINI_LIST,
  HARVEST_INFO_LIST,
  DOCUMENT_TEMPLATES,
  SEND_CONTRACT_DATA_MFO,
} from 'common_constants/modals';
import {
  ArchiveInfo,
  BlockManagement,
  ClientInfoRow,
  ContractInfoTextPart,
  InsurancePart,
  MonthlyNewsletter,
  MonthlySMSInfo,
  VotingButton,
} from './ContractInfoPartials';
import SearchSelectUsersMultiple from '../../../components/SearchSelect/SearchSelectUsersMultiple';
import { setCurrentClient, setModal, editContract } from '../../../store/commonReducer';
import { setMainLoader } from '../../../store/uiReducer';
import { error, request, success, warn } from '../../../tools';
import TrafficLight from './TrafficLight';

import './styles.scss';

const { Panel } = Collapse;

const ContractInfo = ({ data, withInsurance }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const { role, _id } = useSelector((state) => state.common.userAuth);

  const [inspector, deputyManager, deputyManagerOnly, managerOnly, blockManager, blockManagerOnly, rabfil] = [
    ROLES[role] <= ROLES.inspector,
    ROLES[role] <= ROLES.deputyManager,
    ROLES[role] === ROLES.deputyManager,
    ROLES[role] === ROLES.manager,
    ROLES[role] <= ROLES.blockManager,
    ROLES[role] === ROLES.blockManager,
    ROLES[role] <= ROLES.rabfil,
  ];

  const [insuranceTransactionState, setInsuranceTransactionState] = useState(false);
  const [insuranceTransactionHistoryState, setInsuranceTransactionHistoryState] = useState(false);
  const [insurances, setInsurances] = useState(data.insurance);
  const [openMPaymen, setOpenMPaymen] = useState(false);
  const [breakMPayment, setBreackMPayment] = useState(data.mb);
  const [correspondence, setCorrespondence] = useState([]);
  const [activeKey, setActiveKey] = useState([]);
  const [isGenerateContractLoading, setIsGenerateContractLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [poorDataFilling, setPoorDataFilling] = useState(data?.poorDataFilling?.mark ?? false);

  const { Text, Title } = Typography;

  const isNotFired = (user) => user?.fired !== true;
  const filterFil = (user) => Number(user.fil) === Number(data.f);
  const isUserAssigned = (user) => data?.access && data.access.includes(user._id);
  const filterUsers = (user) => (isNotFired(user) && filterFil(user)) || isUserAssigned(user);
  const preparedUsers = Object.values(users).filter(filterUsers);

  const onEdit = () => {
    dispatch(setCurrentClient(data.client[0]));
    dispatch(setModal({ name: NEW_CONTRACT, data, fromContracts: true }));
  };

  const handleChangeAccess = async (value) => {
    const validation = (_value) => {
      if (!Array.isArray(_value)) {
        warn('', 'Для призначення, потрібно вказати адвоката.', { value: 'not array' });
        return false;
      }
      if ((managerOnly || deputyManagerOnly) && data.f === undefined) {
        warn('', "Щоб призначити людину, треба спочатку щоб контракт був прив'язаний за філією.", { f: 'undefined' });
        return false;
      }
      return true;
    };

    if (!validation(value)) return;

    dispatch(setMainLoader(true));

    const isAddedLawyer = !data.access || data.access?.length < value.length;
    await request.post(
      '/contracts/setRabfilAccess',
      { contractId: data._id, access: value },
      (res) => {
        dispatch(editContract({ _id: data._id, access: value }));
        success('', isAddedLawyer ? 'Було призначено помічнику цей договір' : '');
      },
      error,
    );

    if (isAddedLawyer) {
      const findUser = (user) => user._id === value[value.length - 1];
      const assignedUser = preparedUsers.find(findUser);

      const messageData = {
        chatId: data._id,
        sender: assignedUser._id,
        message: `Доброго дня, мене звуть ${assignedUser?.name} ${
          assignedUser?.userPersonalData?.workPhone ? `, мій номер телефону ${assignedUser.userPersonalData.workPhone}` : ''
        }. Мене додано юристом по вашій справі.
        Залюбки допоможу вирішити Ваші питання!`,
        automated: true,
        type: CHAT_TYPES.chatWithClient.key,
      };

      request.post('/chatPrivate/chatSendMessage', messageData, () => success('Клієнту було надіслано повідомлення про ваше призначення'), error);
    }

    dispatch(setMainLoader(false));
  };

  const getCorrespondence = (contract) => {
    setMainLoader(true);
    request.post(
      '/correspondence',
      { _id: contract._id, i: contract.i },
      (res) => {
        setCorrespondence(res.cor);
        setMainLoader(false);
      },
      error,
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (data._id) {
      getCorrespondence(data);
    }
  }, [data]);

  if (!data) return 'Відсутня інформація';

  const onGenerateDocument = () => {
    setIsGenerateContractLoading(true);

    const requestData = {
      contractId: data._id,
      clientId: data?.client[0]._id,
    };

    dispatch(setModal({ name: DOCUMENT_TEMPLATES, data: requestData }));
  };

  const menu = (
    <Menu style={{ maxWidth: isMobile ? '100%' : '70%', marginLeft: 'auto' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Menu.Item key="voting">
          <VotingButton contract={data} correspondence={correspondence} />
        </Menu.Item>

        <Menu.Item key="accountant">
          <ChatWithAccountantButton data={data} />
        </Menu.Item>
        <Menu.Item key="chat">
          <Button
            type="primary"
            onClick={() => dispatch(setModal({ name: BUTTON_CONTAINER, data: { data: data, title: 'Виберіть чат', chat: true } }))}
          >
            <TabletFilled /> Чат &nbsp;
          </Button>
        </Menu.Item>

        {/* {rabfil && (
           <MonthlyNewsletter
             data={data}
             breakMPayment={breakMPayment}
             openMPaymen={openMPaymen}
             setOpenMPaymen={setOpenMPaymen}
             setBreackMPayment={setBreackMPayment}
           />
         )} */}

        {withInsurance && (
          <Menu.Item key="insurance">
            <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setInsuranceTransactionState(true)}>
              Додати страхову транзакцію
            </Button>
          </Menu.Item>
        )}

        {withInsurance && (
          <Menu.Item key="insurance">
            <Button type="primary" icon={<FieldTimeOutlined />} onClick={() => setInsuranceTransactionHistoryState(true)}>
              Історія страхових транзакцій
            </Button>
          </Menu.Item>
        )}

        {withInsurance && (
          <InsurancePart
            contractId={data._id}
            clientId={data.C}
            insurances={insurances}
            handleChangeInsurances={(value) => setInsurances(value)}
            insuranceTransactionState={insuranceTransactionState}
            handleChangeInsuranceTransactionState={() => setInsuranceTransactionState(!insuranceTransactionState)}
            insuranceTransactionHistoryState={insuranceTransactionHistoryState}
            handleChangeInsuranceTransactionHistoryState={() => setInsuranceTransactionHistoryState(!insuranceTransactionHistoryState)}
          />
        )}

        <Menu.Item key="assignments" onClick={() => dispatch(setModal({ name: ASSIGNMENTS_INFO_LIST, data: { contract: data } }))}>
          <Button type="primary" icon={<BankOutlined />}>
            Виходи
          </Button>
        </Menu.Item>

        <Menu.Item key="tasks" onClick={() => dispatch(setModal({ name: CONTRACT_TASKS_CRM, data: { contract: data } }))}>
          <Button type="primary" icon={<CarryOutOutlined />}>
            Завдання &nbsp;
            {data.uncompletedTasks > 0 && <Badge color={red.primary} count={data.uncompletedTasks} style={{ marginBottom: '2.5px' }} />}
          </Button>
        </Menu.Item>

        <Menu.Item key="files" onClick={() => dispatch(setModal({ name: CONTRACT_LIST_FILES, data: { _id: data._id, i: data.i } }))}>
          <Button type="primary" icon={<FileAddOutlined />}>
            Додати файл
          </Button>
        </Menu.Item>

        {/* <Menu.Item
          key="autopay"
          onClick={() =>
            dispatch(setModal({ name: AUTOPAY_INFO_LIST, data: { contractNumber: data.i, autoPayTransactions: data.autoPayTransactions } }))
          }
        >
          <Button disabled={!data?.autoPayTransactions?.length} type="primary" icon={<AreaChartOutlined />}>
            Автооплати
          </Button>
        </Menu.Item> */}

        <Menu.Item key="incomes" onClick={() => dispatch(setModal({ name: INCOMES, data: { contractForm: data, self: true } }))}>
          <Button type="primary" icon={<MoneyCollectOutlined />}>
            Доходи
          </Button>
        </Menu.Item>

        <Menu.Item key="library" onClick={() => dispatch(setModal({ name: LIBRARY_MINI_LIST, data: { shoo: data.prc } }))}>
          <Button icon={<ReadOutlined />} type="primary">
            Міні бібліотека
          </Button>
        </Menu.Item>

        <Menu.Item
          key="correspondence"
          onClick={() =>
            dispatch(
              setModal({
                name: CORRESPONDENCE_MODAL_FOR_THE_CONTRACT,
                data: {
                  _id: data._id,
                  i: data.i,
                  f: data.f,
                },
              }),
            )
          }
        >
          <Button icon={<MailOutlined />} type="primary">
            Кореспонденція
          </Button>
        </Menu.Item>

        <Menu.Item key="harvest" onClick={() => dispatch(setModal({ name: HARVEST_INFO_LIST, data: { contractNumber: data.i } }))}>
          <Button icon={<MoneyCollectOutlined />} type="primary">
            Жнива
          </Button>
        </Menu.Item>

        <Menu.Item key="gendoc" onClick={onGenerateDocument} loading={isGenerateContractLoading}>
          <Button icon={<FileAddOutlined />} type="primary">
            Генерувати документ
          </Button>
        </Menu.Item>

        <Menu.Item
          key="mfo"
          onClick={() => dispatch(setModal({ name: SEND_CONTRACT_DATA_MFO, data: { contractId: data?._id } }))}
          loading={isGenerateContractLoading}
        >
          <Button icon={<InteractionOutlined />} type="primary">
            Вибрати МФО
          </Button>
        </Menu.Item>
      </div>
    </Menu>
  );

  const handlePoorDataFilling = (e) => {
    request.post(
      '/contracts/changePoorDataFilling',
      { poorDataFilling: e, contractId: data._id },
      () => {
        setPoorDataFilling(e);
      },
      error,
    );
  };

  const canEditPoorDataFilling = () => {
    if (data?.poorDataFilling?.userId === undefined) {
      return false;
    }

    return !(data.poorDataFilling.userId === _id);
  };

  return (
    <Card
      className="contractInfo"
      actions={[
        <Button
          className="contractInfo__button"
          style={{ color: '#0958d9' }}
          key="paymentManagement"
          icon={<LineChartOutlined />}
          onClick={() => dispatch(setModal({ name: PAYMENT_MANAGEMENT, data: { contract: data } }))}
        >
          Менеджмент платежів
        </Button>,
        deputyManager && (
          <Button
            className="contractInfo__button"
            style={{ color: '#0958d9' }}
            key="payrollPercents"
            onClick={() => dispatch(setModal({ name: CONTRACT_PAYROLL_PERCENTS, data: { contract: data } }))}
            icon={<BarChartOutlined />}
          >
            Менеджмент відсотків
          </Button>
        ),
        <Dropdown className="contractInfo__button" overlay={menu} trigger={['click']} style={{ color: '#0958d9' }}>
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>,
      ]}
    >
      <Row gutter={16}>
        <Col style={{ display: 'flex', flexDirection: 'column' }} span={18}>
          <ClientInfoRow data={data} />
          <br />
        </Col>
        <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className="assignment-item-buttons-inner">
            <Row className="assignment-item-buttons-inner">
              <Col className="assignment-item-buttons">
                {inspector && (
                  <Button type="primary" className="assignment-item__button expanded" style={{ backgroundColor: 'orange' }} onClick={onEdit}>
                    <EditOutlined />
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <Collapse activeKey={activeKey} accordion onChange={(key) => setActiveKey((prevKey) => (prevKey.includes(key) ? [] : [key]))}>
            <Panel header="Інформація про контракт" key="1">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ContractInfoTextPart data={data} users={users} />
                {data.ad && <ArchiveInfo data={data} />}
                {!data.ad && data.mp && <MonthlySMSInfo data={data} breakMPayment={breakMPayment} />}
                <div className="switch__data-incorrect">
                  <Switch checked={poorDataFilling} disabled={canEditPoorDataFilling()} style={{ marginRight: 8 }} onChange={handlePoorDataFilling} />{' '}
                  Дані заповнені погано
                </div>
              </div>
            </Panel>
          </Collapse>
          <br />
          <TrafficLight data={data} correspondence={correspondence} />
          <br />
        </Col>
      </Row>
      {blockManager && <BlockManagement data={data} />}
      {deputyManager && !blockManagerOnly && (
        <Card>
          {inspector && (
            <Title level={2} style={{ margin: 0 }}>
              Філія
            </Title>
          )}
          <Text strong>Призначити людей:</Text>
          <SearchSelectUsersMultiple
            disabled={(managerOnly || deputyManagerOnly) && data.f === undefined}
            onChange={handleChangeAccess}
            users={preparedUsers}
            value={data.access}
            style={{ width: '100%' }}
          />
          {(managerOnly || deputyManagerOnly) && data.f === undefined && (
            <Box>
              <Text type="warning" className="sender-filter-box-text">
                Щоб призначити людину, треба спочатку щоб контракт був прив'язаний за філією.
              </Text>
            </Box>
          )}
        </Card>
      )}
    </Card>
  );
};

export default ContractInfo;
