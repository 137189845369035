import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { CloseOutlined, PushpinOutlined } from '@ant-design/icons';

import { request, error } from '../../tools';
import { clearPinnedMessages } from '../../store/commonReducer';

const PinnedMessages = ({ pinnedMessages, currentChatId, chatType, onClick }) => {
  const dispatch = useDispatch();
  const chatsData = useSelector((state) => state.common.chatsData);

  const [isClearingLoading, setIsClearingLoading] = useState(false);

  const currentChat = useMemo(() => chatsData?.[chatType]?.find((item) => item._id === currentChatId) ?? {}, [chatsData, currentChatId]);

  const pinnedFullMessages = useMemo(
    () => pinnedMessages?.map((item) => ({ ...item, ...currentChat?.messages?.find((message) => message._id === item.messageId) })),
    [pinnedMessages, currentChat],
  );

  const lastPinnedMessage = pinnedFullMessages?.[0];

  const handleClosePinnedMessages = async (e) => {
    e.stopPropagation();

    setIsClearingLoading(true);

    const reqBody = {
      chatId: currentChatId,
      chatType: chatType,
    };

    await request.post(
      '/chatPrivate/clearPinnedMessages',
      reqBody,
      () => {
        dispatch(clearPinnedMessages({ chatId: currentChatId, chatType }));
      },
      error,
    );

    setIsClearingLoading(false);
  };

  return (
    <div className="pinned-messages-wrapper" onClick={onClick}>
      <div className="content">
        <PushpinOutlined style={{ fontSize: '20px' }} />
        <div className="texts-wrapper">
          <p className="title">Прикріплене повідомлення</p>
          <p className="pinned-message">
            {lastPinnedMessage?.removed ? 'Видалене повідомлення' : lastPinnedMessage?.message ? lastPinnedMessage.message : 'Файлове повідомлення'}
          </p>
        </div>
      </div>
      <Button type="ghost" className="close-btn" onClick={handleClosePinnedMessages} icon={<CloseOutlined />} loading={isClearingLoading} />
    </div>
  );
};

export default PinnedMessages;
