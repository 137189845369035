import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Spin, Modal } from 'antd';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';

import { setModal } from '../../store/commonReducer';
import Excel from '../../images/excel_logo.svg';
import { request, error, success } from '../../tools';

import './styles.scss';
import { TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY } from 'common_constants/business';

const ExportLibrary = () => {
  const dispatch = useDispatch();

  const [users, FILII] = [useSelector((state) => state.common.users), useSelector((state) => state.common.FILII)];

  const [loading, setLoading] = useState(false),
    [data, setData] = useState();

  const onGenerate = async () => {
    setLoading(true);

    await request.post(
      '/spreadsheets/exportLibrary',
      {},
      (req) => {
        success('', `Всього знайдено: ${req.length_data}`);

        setData(req.data);
      },
      error,
    );

    setLoading(false);
  };

  const csvData = [['Дата створення', 'Філія автора', 'Автор', 'Номер звітності', 'Номер документа', 'Важливість', 'Вид документа', 'Назва']];

  data?.forEach((i) => {
    csvData.push([
      i.t ? dayjs(i.t)?.format('DD.MM.YYYY') ?? '' : '', //* Дата створення
      users && FILII && i.a ? FILII?.[users?.[i.a]?.fil] ?? '' : '', //* Філія автора
      users && i.a ? `${users[i.a]?.p}${users[i.a]?.fired ? ' (Звільнений)' : ''}` : '', //* Автор
      i.r ?? '', //* Номер звітності
      i.i ?? '', //* Номер файла
      i.o ? 'НО' : 'О', //* Важливість
      (i.g && TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY?.[i.g]?.label) ?? '', //* Вид документа
      i.n ?? '', //* Назва
    ]);
  });

  return (
    <Modal open className="export-l" title="Експорт Бібліотеки." onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="change">
          {data ? (
            <CSVLink asyncOnClick={true} data={csvData}>
              <Button className="excel">
                <img className="excel-img" src={Excel} alt="Excel" /> Скачати .csv
              </Button>
            </CSVLink>
          ) : (
            <Button onClick={onGenerate}>Генерувати</Button>
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

export default ExportLibrary;
