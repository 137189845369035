const languageOptions = [
  {
    value: 'ua',
    label: 'UA',
  },
  {
    value: 'cs',
    label: 'CS',
  },
  {
    value: 'en',
    label: 'EN',
  },
  {
    value: 'de',
    label: 'DE',
  },
  {
    value: 'ru',
    label: 'RU',
  },
  {
    value: 'fr',
    label: 'FR',
  },
  {
    value: 'es',
    label: 'ES',
  },
];

const translation = {
  _appTitle: {
    ua: 'єАдвокат',
    en: 'eLawyer',
    de: 'eAnwalt',
    ru: 'еАдвокат',
    fr: 'eAvocat',
    es: 'eAbogado',
    cs: 'eAdvokát',
  },
  _companyName: {
    ua: 'Адвокатське об’єднання «Захист»',
    en: 'Advocacy Association «Zahist»',
    de: 'Anwaltsverband «Zahist»',
    ru: 'Адвокатское объединение «Защита»',
    fr: "Association d'avocats «Zahist»",
    es: 'Asociación de Abogados «Zahist»',
    cs: 'Advokátní sdružení «Zahist»',
  },
  _companyDescription: {
    ua: 'міжнародна юридична компанія',
    en: 'international law company',
    de: 'internationale Anwaltskanzlei',
    ru: 'международная юридическая компания',
    fr: "cabinet d'avocats international",
    es: 'firma de abogados internacional',
    cs: 'mezinárodní právní společnost',
  },
  _appDownload1: {
    ua: 'Завантажте мобільний додаток',
    en: 'Download the mobile app',
    de: 'Laden Sie die mobile App herunter',
    ru: 'Скачайте мобильное приложение',
    fr: "Téléchargez l'application mobile",
    es: 'Descargue la aplicación móvil',
    cs: 'Stáhněte si mobilní aplikaci',
  },
  _appDownload2: {
    ua: 'на свій смартфон та отримайте:',
    en: 'on your phone and get:',
    de: 'auf Ihr Smartphone und erhalten Sie:',
    ru: 'на свой телефон и получите:',
    fr: 'sur votre téléphone et obtenez :',
    es: 'en su teléfono y obtenga:',
    cs: 'do svého telefonu a získejte:',
  },
  _appBenefit1: {
    ua: 'Скидку на консультацію;',
    en: 'Discount on consultation;',
    de: 'Rabatt auf Beratung;',
    ru: 'Скидку на консультацию;',
    fr: 'Réduction sur la consultation ;',
    es: 'Descuento en consulta;',
    cs: 'Sleva na konzultaci;',
  },
  _appBenefit2: {
    ua: 'Повернення суми за консультацію на баланс;',
    en: 'Refund of the consultation amount to the balance;',
    de: 'Rückerstattung des Beratungsbetrags auf das Guthaben;',
    ru: 'Возврат суммы за консультацию на баланс;',
    fr: 'Remboursement du montant de la consultation sur le solde ;',
    es: 'Reembolso del importe de la consulta al saldo;',
    cs: 'Vrácení částky za konzultaci na zůstatek;',
  },
  _appBenefit3: {
    ua: 'Бонус у сумі 300 грн;',
    en: 'Bonus of 300 UAH;',
    de: 'Bonus von 300 UAH;',
    ru: 'Бонус в размере 300 грн;',
    fr: 'Bonus de 300 UAH ;',
    es: 'Bono de 300 UAH;',
    cs: 'Bonus 300 UAH;',
  },
  _learnMore: {
    ua: 'Дізнатися більше про компанію',
    en: 'Learn more about the company',
    de: 'Erfahren Sie mehr über das Unternehmen',
    ru: 'Узнать больше о компании',
    fr: 'En savoir plus sur la société',
    es: 'Más información sobre la empresa',
    cs: 'Zjistit více o společnosti',
  },
  _checkPortfolio: {
    ua: 'Переглянути портфоліо адвоката',
    en: "View lawyer's portfolio",
    de: 'Anwaltsportfolio anzeigen',
    ru: 'Посмотреть портфолио адвоката',
    fr: "Voir le portfolio de l'avocat",
    es: 'Ver el portafolio del abogado',
    cs: 'Zobrazit portfolio advokáta',
  },
  _learnMoreApp: {
    ua: 'Дізнатися більше про єАдвокат',
    en: 'Learn more about eAdvokat',
    de: 'Erfahren Sie mehr über eAdvokat',
    ru: 'Узнать больше о еАдвокате',
    fr: 'En savoir plus sur eAvocat',
    es: 'Más información sobre eAdvokat',
    cs: 'Zjistit více o eAdvokátovi',
  },
  // common phrases
  info: {
    ua: 'Інформація',
    en: 'Information',
    de: 'Information',
    ru: 'Информация',
    fr: 'Information',
    es: 'Información',
    cs: 'Informace',
  },
  rate: {
    ua: 'Оцінити',
    en: 'Rate',
    de: 'Bewerten',
    ru: 'Оценить',
    fr: 'Évaluer',
    es: 'Calificar',
    cs: 'Hodnotit',
  },
  name: {
    ua: 'Ім`я',
    en: 'Name',
    de: 'Name',
    ru: 'Имя',
    fr: 'Nom',
    es: 'Nombre',
    cs: 'Jméno',
  },
  phone: {
    ua: 'Телефон:',
    en: 'Phone:',
    de: 'Telefon:',
    ru: 'Телефон:',
    fr: 'Téléphone :',
    es: 'Teléfono:',
    cs: 'Telefon:',
  },
  date: {
    ua: 'Дата',
    en: 'Date',
    de: 'Datum',
    ru: 'Дата',
    fr: 'Date',
    es: 'Fecha',
    cs: 'Datum',
  },
  sum: {
    ua: 'Сума',
    en: 'Amount',
    de: 'Betrag',
    ru: 'Сумма',
    fr: 'Montant',
    es: 'Monto',
    cs: 'Částka',
  },
  file: {
    ua: 'Файл',
    en: 'File',
    de: 'Datei',
    ru: 'Файл',
    fr: 'Fichier',
    es: 'Archivo',
    cs: 'Soubor',
  },
  term: {
    ua: 'Термін',
    en: 'Term',
    de: 'Begriff',
    ru: 'Срок',
    fr: 'Terme',
    es: 'Plazo',
    cs: 'Doba',
  },
  proposal: {
    ua: 'Пропозиція',
    en: 'Proposal',
    de: 'Vorschlag',
    ru: 'Предложение',
    fr: 'Proposition',
    es: 'Propuesta',
    cs: 'Návrh',
  },
  result: {
    ua: 'Результат',
    en: 'Result',
    de: 'Ergebnis',
    ru: 'Результат',
    fr: 'Résultat',
    es: 'Resultado',
    cs: 'Výsledek',
  },
  agree: {
    ua: 'Погодитися',
    en: 'Agree',
    de: 'Einverstanden',
    ru: 'Согласиться',
    fr: 'Accepter',
    es: 'Aceptar',
    cs: 'Souhlasit',
  },
  chat: {
    ua: 'Чат',
    en: 'Chat',
    de: 'Chat',
    ru: 'Чат',
    fr: 'Chat',
    es: 'Chat',
    cs: 'Chat',
  },
  send: {
    ua: 'Надіслати',
    en: 'Send',
    de: 'Senden',
    ru: 'Отправить',
    fr: 'Envoyer',
    es: 'Enviar',
    cs: 'Odeslat',
  },
  transactions: {
    ua: 'Транзакції',
    en: 'Transactions',
    de: 'Transaktionen',
    ru: 'Транзакции',
    fr: 'Transactions',
    es: 'Transacciones',
    cs: 'Transakce',
  },
  enter: {
    ua: 'Увійти',
    en: 'Enter',
    de: 'Eingeben',
    ru: 'Войти',
    fr: 'Entrer',
    es: 'Entrar',
    cs: 'Vstoupit',
  },
  register: {
    ua: 'Зареєструватися',
    en: 'Register',
    de: 'Registrieren',
    ru: 'Зарегистрироваться',
    fr: "S'inscrire",
    es: 'Registrarse',
    cs: 'Registrovat se',
  },
  password: {
    ua: 'Пароль',
    en: 'Password',
    de: 'Passwort',
    ru: 'Пароль',
    fr: 'Mot de passe',
    es: 'Contraseña',
    cs: 'Heslo',
  },
  confirmPassword: {
    ua: 'Підтвердіть пароль',
    en: 'Confirm Password',
    de: 'Bestätigen Passwort',
    ru: 'Подтвердите пароль',
    fr: 'Confirmer le mot de passe',
    es: 'Confirmar contraseña',
    cs: 'Potvrďte heslo',
  },
  notMessages: {
    ua: 'Немає повідомлень',
    en: 'No messages',
    de: 'Keine Nachrichten',
    ru: 'Нет сообщений',
    fr: 'Pas de messages',
    es: 'No hay mensajes',
    cs: 'Žádné zprávy',
  },

  // page titles
  home: {
    ua: 'Головна',
    en: 'Homepage',
    de: 'Hauptseite',
    ru: 'Главная',
    fr: 'Accueil',
    es: 'Inicio',
    cs: 'Domů',
  },
  homePage: {
    ua: 'Головна сторінка',
    en: 'Homepage',
    de: 'Hauptseite',
    ru: 'Главная страница',
    fr: "Page d'accueil",
    es: 'Página principal',
    cs: 'Hlavní stránka',
  },
  hotline: {
    ua: 'Запис на консультацію',
    en: 'Appointment for consultation',
    de: 'Termin zur Beratung',
    ru: 'Запись на консультацию',
    fr: 'Rendez-vous pour une consultation',
    es: 'Cita para consulta',
    cs: 'Schůzka na konzultaci',
  },
  myConsultations: {
    ua: 'Мої консультації',
    en: 'My consultations',
    de: 'Meine Beratungen',
    ru: 'Мои консультации',
    fr: 'Mes consultations',
    es: 'Mis consultas',
    cs: 'Moje konzultace',
  },
  clientChats: {
    ua: 'Чати',
    en: 'Chats',
    de: 'Chats',
    ru: 'Чаты',
    fr: 'Chats',
    es: 'Chats',
    cs: 'Chaty',
  },
  quickQuestion: {
    ua: 'Швидке питання',
    en: 'Quick question',
    de: 'Schnelle Frage',
    ru: 'Быстрый вопрос',
    fr: 'Question rapide',
    es: 'Pregunta rápida',
    cs: 'Rychlá otázka',
  },
  myBusiness: {
    ua: 'Мої справи',
    en: 'My affairs',
    de: 'Meine Angelegenheiten',
    ru: 'Мои дела',
    fr: 'Mes affaires',
    es: 'Mis asuntos',
    cs: 'Moje záležitosti',
  },
  tenders: {
    ua: 'Тендери',
    en: 'Tenders',
    de: 'Ausschreibungen',
    ru: 'Тендеры',
    fr: "Appels d'offres",
    es: 'Licitaciones',
    cs: 'Zakázky',
  },
  insuranceCard: {
    ua: 'Юридична страховка',
    en: 'Legal insurance',
    de: 'Rechtsschutzversicherung',
    ru: 'Юридическая страховка',
    fr: 'Assurance juridique',
    es: 'Seguro legal',
    cs: 'Právní pojištění',
  },
  balance: {
    ua: 'Баланс',
    en: 'Balance',
    de: 'Balance',
    ru: 'Баланс',
    fr: 'Solde',
    es: 'Saldo',
    cs: 'Zůstatek',
  },
  inviteFriend: {
    ua: 'Запросити друга',
    en: 'Invite friend',
    de: 'Freund einladen',
    ru: 'Пригласить друга',
    fr: 'Inviter un ami',
    es: 'Invitar a un amigo',
    cs: 'Pozvat přítele',
  },
  inviteClient: {
    ua: 'Запросити клієнта',
    en: 'Invite client',
    de: 'Kunden einladen',
    ru: 'Пригласить клиента',
    fr: 'Inviter un client',
    es: 'Invitar a un cliente',
    cs: 'Pozvat klienta',
  },
  otherWayToInvite: {
    ua: 'Інший спосіб запрошення',
    en: 'Another way to invite',
    de: 'Andere Möglichkeit einzuladen',
    ru: 'Другой способ приглашения',
    fr: "Autre moyen d'inviter",
    es: 'Otra forma de invitar',
    cs: 'Jiný způsob pozvání',
  },
  partnerCabinet: {
    ua: 'Кабінет партнера',
    en: 'Partner cabinet',
    de: 'Partnerkabinett',
    ru: 'Кабинет партнера',
    fr: 'Cabinet partenaire',
    es: 'Gabinete de socio',
    cs: 'Partner kabinet',
  },
  contacts: {
    ua: 'Контакти',
    en: 'Contacts',
    de: 'Kontakte',
    ru: 'Контакты',
    fr: 'Contacts',
    es: 'Contactos',
    cs: 'Kontakty',
  },
  reviews: {
    ua: 'Відгуки',
    en: 'Feedback',
    de: 'Rückmeldungen',
    ru: 'Отзывы',
    fr: 'Commentaires',
    es: 'Reseñas',
    cs: 'Recenze',
  },
  buyAlarmButton: {
    ua: 'Тривожна кнопка',
    en: 'Alarm button',
    de: 'Alarmknopf',
    ru: 'Тревожная кнопка',
    fr: "Bouton d'alarme",
    es: 'Botón de alarma',
    cs: 'Tlačítko alarmu',
  },
  buyButton: {
    ua: 'Купити кнопку',
    en: 'Buy button',
    de: 'Kaufen Sie den Button',
    ru: 'Купить кнопку',
    fr: 'Acheter le bouton',
    es: 'Comprar botón',
    cs: 'Koupit tlačítko',
  },
  bonusCard: {
    ua: 'Бонусна карта',
    en: 'Bonus card',
    de: 'Bonuskarte',
    ru: 'Бонусная карта',
    fr: 'Carte bonus',
    es: 'Tarjeta de bonificación',
    cs: 'Bonusová karta',
  },
  bonusMainTitle: {
    ua: 'Інформація про бонуси',
    en: 'Information about bonuses',
    de: 'Informationen über Boni',
    ru: 'Информация о бонусах',
    fr: 'Informations sur les bonus',
    es: 'Información sobre bonificaciones',
    cs: 'Informace o bonusech',
  },
  bonusHowToUseTitle: {
    ua: 'Як використати бонуси ?',
    en: 'How to use bonuses',
    de: 'Wie benutzt man Boni?',
    ru: 'Как использовать бонусы?',
    fr: 'Comment utiliser les bonus ?',
    es: 'Cómo usar bonificaciones',
    cs: 'Jak využít bonusy?',
  },
  bonusHowToUse: {
    ua: 'Як Ви можете використати наявні бонуси?',
    en: 'How can you use available bonuses?',
    de: 'Wie können Sie verfügbare Boni nutzen?',
    ru: 'Как вы можете использовать доступные бонусы?',
    fr: 'Comment pouvez-vous utiliser les bonus disponibles ?',
    es: '¿Cómo puedes utilizar las bonificaciones disponibles?',
    cs: 'Jak můžete využít dostupné bonusy?',
  },
  bonusButton: {
    ua: 'Для того, щоб скористатись бонусами, необхідно натиснути на кнопку',
    en: 'To use bonuses, you need to press the button',
    de: 'Um Boni zu nutzen, müssen Sie auf die Schaltfläche drücken',
    ru: 'Чтобы воспользоваться бонусами, нажмите кнопку',
    fr: 'Pour utiliser les bonus, appuyez sur le bouton',
    es: 'Para usar bonificaciones, presione el botón',
    cs: 'Chcete-li použít bonusy, stiskněte tlačítko',
  },
  bonusLimits: {
    ua: 'Що варто враховувати при використанні бонусів ?',
    en: 'What should you consider when using bonuses?',
    de: 'Was sollten Sie bei der Nutzung von Boni beachten?',
    ru: 'Что следует учитывать при использовании бонусов?',
    fr: "Que faut-il prendre en compte lors de l'utilisation des bonus ?",
    es: '¿Qué debes considerar al usar bonificaciones?',
    cs: 'Co byste měli vzít v úvahu při používání bonusů?',
  },
  bonusLimit1: {
    ua: 'З моменту транзакції має пройти не більше ',
    en: 'No more than ',
    de: 'Seit der Transaktion dürfen nicht mehr als ',
    ru: 'С момента транзакции должно пройти не более ',
    fr: "Il ne doit pas s'écouler plus de ",
    es: 'No más de ',
    cs: 'Od transakce by nemělo uplynout více než ',
  },
  bonusLimit1Days: {
    ua: '30 днів',
    en: '30 days',
    de: '30 Tage',
    ru: '30 дней',
    fr: '30 jours',
    es: '30 días',
    cs: '30 dní',
  },
  bonusLimit2: {
    ua: 'Бонусів має вистачати щоб повернути',
    en: 'There must be enough bonuses to cover',
    de: 'Es müssen genügend Boni vorhanden sein, um zurückzuzahlen',
    ru: 'Бонусов должно хватать, чтобы покрыть',
    fr: 'Il doit y avoir suffisamment de bonus pour couvrir',
    es: 'Debe haber suficientes bonificaciones para cubrir',
    cs: 'Musí být dostatek bonusů na pokrytí',
  },
  bonusLimit2Percents: {
    ua: '30% від транзакції',
    en: '30% of the transaction',
    de: '30% der Transaktion',
    ru: '30% от транзакции',
    fr: '30% de la transaction',
    es: '30% de la transacción',
    cs: '30% transakce',
  },
  bonusLimit3: {
    ua: 'Кешбек може бути отриманий по наступним ',
    en: 'Cashback can be earned on the following ',
    de: 'Cashback kann für die folgenden ',
    ru: 'Кешбэк может быть получен по следующим ',
    fr: 'Le cashback peut être obtenu sur les transactions suivantes :',
    es: 'El reembolso puede obtenerse en las siguientes ',
    cs: 'Cashback lze získat na následujících ',
  },
  bonusLimit3Transactions: {
    ua: 'транзакціях',
    en: 'transactions',
    de: 'Transaktionen',
    ru: 'транзакциям',
    fr: 'transactions',
    es: 'transacciones',
    cs: 'transakcích',
  },

  createTender: {
    ua: 'Створити тендер',
    en: 'Create tender',
    de: 'Eine Ausschreibung erstellen',
    ru: 'Создать тендер',
    fr: "Créer un appel d'offres",
    es: 'Crear licitación',
    cs: 'Vytvořit výběrové řízení',
  },
  tenderDetails: {
    ua: 'Деталі тендеру',
    en: 'Tender details',
    de: 'Ausschreibungsdetails',
    ru: 'Детали тендера',
    fr: "Détails de l'appel d'offres",
    es: 'Detalles de la licitación',
    cs: 'Podrobnosti výběrového řízení',
  },
  portfolio: {
    ua: 'Портфоліо',
    en: 'Portfolio',
    de: 'Portfolio',
    ru: 'Портфолио',
    fr: 'Portfolio',
    es: 'Portafolio',
    cs: 'Portfolio',
  },
  registerPage: {
    ua: 'Реєстри',
    en: 'Registers',
    de: 'Register',
    ru: 'Реестры',
    fr: 'Registres',
    es: 'Registros',
    cs: 'Registry',
  },

  // modals
  topUpYourBalance: {
    ua: 'Поповнити баланс',
    en: 'Recharge balance',
    de: 'Guthaben aufladen',
    ru: 'Пополнить баланс',
    fr: 'Recharger le solde',
    es: 'Recargar saldo',
    cs: 'Dobít zůstatek',
  },
  scanQRcode: {
    ua: 'QR code',
    en: 'QR code',
    de: 'QR-Code',
    ru: 'QR-код',
    fr: 'Code QR',
    es: 'Código QR',
    cs: 'QR kód',
  },
  scanQRcodeModalTitle: {
    ua: 'Сканування QR code',
    en: 'QR code scanning',
    de: 'QR-Code-Scannen',
    ru: 'Сканирование QR-кода',
    fr: 'Scanner le code QR',
    es: 'Escaneo de código QR',
    cs: 'Skenování QR kódu',
  },
  scanQRcod: {
    ua: 'Скануйте QR-код',
    en: 'Scan QR Code',
    de: 'QR-Code scannen',
    ru: 'Сканируйте QR-код',
    fr: 'Scannez le code QR',
    es: 'Escanee el código QR',
    cs: 'Naskenujte QR kód',
  },

  startModal: {
    ua: 'Потрібен адвокат?',
    en: 'Need a lawyer?',
    de: 'Brauchen Sie einen Anwalt?',
    ru: 'Нужен адвокат?',
    fr: "Besoin d'un avocat ?",
    es: '¿Necesita un abogado?',
    cs: 'Potřebujete advokáta?',
  },
  startModal1: {
    ua: 'Подзвонити на гарячу лінію',
    en: 'Call the hotline',
    de: 'Bei der Hotline anrufe',
    ru: 'Позвонить на горячую линию',
    fr: 'Appeler la hotline',
    es: 'Llamar a la línea directa',
    cs: 'Zavolat na horkou linku',
  },
  startModal2: {
    ua: 'Записатися власноруч',
    en: 'Sign up for an appointment manually',
    de: 'Sich manuell für einen Termin anmelden',
    ru: 'Записаться самостоятельно',
    fr: 'Prendre un rendez-vous manuellement',
    es: 'Registrarse manualmente',
    cs: 'Zaregistrujte se ručně',
  },
  startModal3: {
    ua: 'Задати швидке питання',
    en: 'Ask a quick question.',
    de: 'Eine schnelle Frage stellen',
    ru: 'Задать быстрый вопрос',
    fr: 'Poser une question rapide',
    es: 'Hacer una pregunta rápida',
    cs: 'Položit rychlou otázku',
  },
  startModal4: {
    ua: 'Створити тендер',
    en: 'Create a tender',
    de: 'Eine Ausschreibung erstellen',
    ru: 'Создать тендер',
    fr: "Créer un appel d'offres",
    es: 'Crear una licitación',
    cs: 'Vytvořit výběrové řízení',
  },
  modalWindow: {
    ua: 'Дані не додані',
    en: 'Data not added',
    de: 'Daten nicht hinzugefügt',
    ru: 'Данные не добавлены',
    fr: 'Données non ajoutées',
    es: 'Datos no añadidos',
    cs: 'Data nebyla přidána',
  },
  payContractDebt: {
    ua: 'Заборгованість по договору успішно оплачена',
    en: 'Contract debt successfully paid',
    de: 'Verpflichtungsbetrag erfolgreich bezahlt',
    ru: 'Долг по договору успешно оплачен',
    fr: 'Dette contractuelle payée avec succès',
    es: 'Deuda de contrato pagada con éxito',
    cs: 'Dluh ze smlouvy byl úspěšně zaplacen',
  },
  debtName: {
    ua: 'Заборгованість',
    en: 'Debt',
    de: 'Verschuldung',
    ru: 'Задолженность',
    fr: 'Dette',
    es: 'Deuda',
    cs: 'Dluh',
  },
  debtContentContract: {
    ua: 'За договором',
    en: 'Under contract',
    de: 'Unter Vertrag',
    ru: 'По договору',
    fr: 'Sous contrat',
    es: 'Bajo contrato',
    cs: 'Pod smlouvou',
  },
  consultaionSelectBranch: {
    ua: 'Спочатку потрібно обрати філію',
    en: 'First, you need to select a branch',
    de: 'Zuerst müssen Sie eine Filiale auswählen',
    ru: 'Сначала выберите филиал',
    fr: "D'abord, vous devez sélectionner une agence",
    es: 'Primero, debe seleccionar una sucursal',
    cs: 'Nejprve musíte vybrat pobočku',
  },

  // ClientHotlineSubmitBtn

  title: {
    ua: 'Записатись?',
    en: 'Sign up?',
    de: 'Anmelden?',
    ru: 'Записаться?',
    fr: "S'inscrire ?",
    es: '¿Inscribirse?',
    cs: 'Přihlásit se?',
  },
  branch: {
    ua: 'Філія: ',
    en: 'Branch: ',
    de: 'Zweigstelle: ',
    ru: 'Филиал: ',
    fr: 'Agence : ',
    es: 'Sucursal: ',
    cs: 'Pobočka: ',
  },
  service: {
    ua: 'Послуга: ',
    en: 'Service: ',
    de: 'Dienstleistung: ',
    ru: 'Услуга: ',
    fr: 'Service : ',
    es: 'Servicio: ',
    cs: 'Služba: ',
  },
  from: {
    ua: 'З: ',
    en: 'From: ',
    de: 'Von: ',
    ru: 'От: ',
    fr: 'De : ',
    es: 'De: ',
    cs: 'Od: ',
  },
  toClient: {
    ua: 'По: ',
    en: 'To: ',
    de: 'Bis: ',
    ru: 'До: ',
    fr: 'À : ',
    es: 'Hasta: ',
    cs: 'Do: ',
  },
  confirm: {
    ua: 'Підтвердити',
    en: 'Confirm',
    de: 'Bestätigen',
    ru: 'Подтвердить',
    fr: 'Confirmer',
    es: 'Confirmar',
    cs: 'Potvrdit',
  },
  cancel: {
    ua: 'Скасувати',
    en: 'Cancel',
    de: 'Stornieren',
    ru: 'Отмена',
    fr: 'Annuler',
    es: 'Cancelar',
    cs: 'Zrušit',
  },
  signUp: {
    ua: 'Записатись',
    en: 'Sign up',
    de: 'Anmelden',
    ru: 'Записаться',
    fr: "S'inscrire",
    es: 'Registrarse',
    cs: 'Přihlásit se',
  },

  contractConditions: {
    ua: 'Умови договору юридичної страховки',
    en: 'Legal Insurance Contract Terms',
    de: 'Bedingungen des Rechtsschutzvertrags',
    ru: 'Условия договора юридической страховки',
    fr: "Conditions du contrat d'assurance juridique",
    es: 'Términos del contrato de seguro legal',
    cs: 'Podmínky právní pojistné smlouvy',
  },
  definitionOfTerms: {
    ua: 'Визначення термінів',
    en: 'Definition of Terms',
    de: 'Begriffsbestimmungen',
    ru: 'Определение терминов',
    fr: 'Définition des termes',
    es: 'Definición de términos',
    cs: 'Definice pojmů',
  },
  legalInsurance: {
    ua: 'Юридична страховка',
    en: 'Legal Insurance',
    de: 'Rechtsschutzversicherung',
    ru: 'Юридическая страховка',
    fr: 'Assurance juridique',
    es: 'Seguro legal',
    cs: 'Právní pojištění',
  },
  executor: {
    ua: 'Виконавець',
    en: 'Executor',
    de: 'Ausführender',
    ru: 'Исполнитель',
    fr: 'Exécuteur',
    es: 'Ejecutor',
    cs: 'Výkonavatel',
  },
  customer: {
    ua: 'Замовник',
    en: 'Customer',
    de: 'Kunde',
    ru: 'Заказчик',
    fr: 'Client',
    es: 'Cliente',
    cs: 'Zákazník',
  },
  insuranceCase: {
    ua: 'Страховий випадок',
    en: 'Insurance Case',
    de: 'Versicherungsfall',
    ru: 'Страховой случай',
    fr: "Cas d'assurance",
    es: 'Caso de seguro',
    cs: 'Pojistná událost',
  },
  insuranceAction: {
    ua: 'Дія юридичної страховки',
    en: 'Legal Insurance Action',
    de: 'Rechtsschutzversicherungsdauer',
    ru: 'Действие юридической страховки',
    fr: "Durée de l'assurance juridique",
    es: 'Duración del seguro legal',
    cs: 'Doba právního pojištění',
  },
  insuranceAmount: {
    ua: 'Страхова сума',
    en: 'Insurance Amount',
    de: 'Versicherungssumme',
    ru: 'Страховая сумма',
    fr: "Montant de l'assurance",
    es: 'Monto del seguro',
    cs: 'Pojistná částka',
  },
  insurancePayment: {
    ua: 'Страховий платіж',
    en: 'Insurance Payment',
    de: 'Versicherungsbeitrag',
    ru: 'Страховой платеж',
    fr: "Paiement de l'assurance",
    es: 'Pago de seguro',
    cs: 'Pojistné',
  },
  insuranceTerm: {
    ua: 'Страховий строк',
    en: 'Insurance Term',
    de: 'Versicherungsdauer',
    ru: 'Срок страхования',
    fr: "Durée de l'assurance",
    es: 'Plazo del seguro',
    cs: 'Doba pojištění',
  },
  priceList: {
    ua: 'Прейскурант цін',
    en: 'Price List',
    de: 'Preisliste',
    ru: 'Прейскурант',
    fr: 'Liste de prix',
    es: 'Lista de precios',
    cs: 'Ceník',
  },
  activation: {
    ua: 'Активація юридичної страховки',
    en: 'Legal Insurance Activation',
    de: 'Aktivierung der Rechtsschutzversicherung',
    ru: 'Активация юридической страховки',
    fr: "Activation de l'assurance juridique",
    es: 'Activación del seguro legal',
    cs: 'Aktivace právního pojištění',
  },
  cases: {
    ua: 'Страхові випадки',
    en: 'Insurance Cases',
    de: 'Versicherungsfälle',
    ru: 'Страховые случаи',
    fr: "Cas d'assurance",
    es: 'Casos de seguro',
    cs: 'Pojistné případy',
  },
  insuranceCost: {
    ua: 'Вартість юридичної страховки',
    en: 'Legal Insurance Cost',
    de: 'Kosten der Rechtsschutzversicherung',
    ru: 'Стоимость юридической страховки',
    fr: "Coût de l'assurance juridique",
    es: 'Costo del seguro legal',
    cs: 'Cena právního pojištění',
  },
  saving: {
    ua: 'Економія',
    en: 'Saving',
    de: 'Ersparnis',
    ru: 'Экономия',
    fr: 'Économies',
    es: 'Ahorro',
    cs: 'Úspora',
  },
  months6: {
    ua: '6 місяців',
    en: '6 months',
    de: '6 Monate',
    ru: '6 месяцев',
    fr: '6 mois',
    es: '6 meses',
    cs: '6 měsíců',
  },
  year1: {
    ua: '1 рік',
    en: '1 year',
    de: '1 Jahr',
    ru: '1 год',
    fr: '1 an',
    es: '1 año',
    cs: '1 rok',
  },
  years3: {
    ua: '3 роки',
    en: '3 years',
    de: '3 Jahre',
    ru: '3 года',
    fr: '3 ans',
    es: '3 años',
    cs: '3 roky',
  },
  perHour: {
    ua: 'грн за годину',
    en: 'UAH per hour',
    de: 'UAH pro Stunde',
    ru: 'грн за час',
    fr: 'UAH par heure',
    es: 'UAH por hora',
    cs: 'UAH za hodinu',
  },
  activationText: {
    ua: 'У разі активації юридичної страховки, Виконавець бере на себе зобов’язання надати Замовнику юридичні послуги, направлені на вирішення правової проблеми, спричиненої страховим випадком, вартість яких визначена прейскурантом цін, в межах страхової суми.',
    en: 'Upon activation of legal insurance, the Executor undertakes to provide the Customer with legal services aimed at resolving the legal problem caused by the insured event, the cost of which is determined by the price list, within the insurance amount.',
    de: 'Im Falle der Aktivierung der Rechtsschutzversicherung verpflichtet sich der Ausführende, dem Kunden Rechtsdienstleistungen zur Lösung des durch das Versicherungsereignis verursachten rechtlichen Problems zu erbringen, deren Kosten gemäß der Preisliste innerhalb der Versicherungssumme festgelegt sind.',
    ru: 'В случае активации юридической страховки Исполнитель обязуется предоставить Заказчику юридические услуги, направленные на решение правовой проблемы, вызванной страховым случаем, стоимость которых определяется прейскурантом, в пределах страховой суммы.',
    fr: "En cas d'activation de l'assurance juridique, l'Exécuteur s'engage à fournir au Client des services juridiques visant à résoudre le problème juridique causé par l'événement assuré, dont le coût est déterminé par la liste des prix, dans la limite de la somme assurée.",
    es: 'Al activar el seguro legal, el Ejecutor se compromete a proporcionar al Cliente servicios jurídicos destinados a resolver el problema legal causado por el siniestro asegurado, cuyo costo está determinado por la lista de precios, dentro del monto asegurado.',
    cs: 'V případě aktivace právního pojištění se Výkonný zavazuje poskytnout zákazníkovi právní služby zaměřené na řešení právního problému způsobeného pojistnou událostí, jejichž náklady jsou určeny ceníkem, v rámci pojistné částky.',
  },
  legalInsuranceDescription: {
    ua: ' спеціальна пропозиція Адвокатського об’єднання «Захист», яка полягає у наданні безкоштовних юридичних послуг Клієнту при настанні страхового випадку.',
    en: ' a special offer by the Law Firm "Protection" that involves providing free legal services to the Client in the event of an insurance case.',
    de: ' ein spezielles Angebot der Anwaltskanzlei „Schutz“, das die Bereitstellung kostenloser Rechtsdienstleistungen an den Kunden im Falle eines Versicherungsfalls umfasst.',
    ru: ' специальное предложение адвокатской компании «Защита», которое предусматривает предоставление бесплатных юридических услуг клиенту в случае страхового случая.',
    fr: " une offre spéciale de la société d'avocats «Protection», qui consiste à fournir des services juridiques gratuits au client en cas de sinistre assuré.",
    es: ' una oferta especial del bufete de abogados "Protección" que incluye la prestación de servicios jurídicos gratuitos al Cliente en caso de siniestro.',
    cs: ' speciální nabídka advokátní kanceláře „Ochrana“, která zahrnuje poskytování bezplatných právních služeb klientovi v případě pojistné události.',
  },

  // FillInfo.jsx
  branchInfo: {
    en: 'Branch Information',
    de: 'Filialinformationen',
    ua: 'Інформація про філію',
    ru: 'Информация о филиале',
    fr: "Informations sur l'agence",
    es: 'Información de la sucursal',
    cs: 'Informace o pobočce',
  },
  address: {
    en: 'Address:',
    de: 'Adresse:',
    ua: 'Адреса:',
    ru: 'Адрес:',
    fr: 'Adresse :',
    es: 'Dirección:',
    cs: 'Adresa:',
  },
  zone: {
    en: 'Zone:',
    de: 'Zone:',
    ua: 'Території:',
    ru: 'Зона:',
    fr: 'Zone :',
    es: 'Zona:',
    cs: 'Zóna:',
  },
  landmark: {
    ua: 'Орієнтир:',
    en: 'Landmark:',
    de: 'Wahrzeichen:',
    ru: 'Ориентир:',
    fr: 'Point de repère :',
    es: 'Punto de referencia:',
    cs: 'Orientace:',
  },
  // HotLineTimePicker.jsx
  clear: {
    en: 'Clear',
    de: 'Löschen',
    ua: 'Очистити',
    ru: 'Очистить',
    fr: 'Effacer',
    es: 'Borrar',
    cs: 'Vymazat',
  },

  // Modals - by folders

  // AddInsurance
  addInsuranceWarn: {
    ua: 'Додавання страховки',
    en: 'Adding insurance',
    de: 'Versicherung hinzufügen',
    ru: 'Добавление страховки',
    fr: "Ajout d'assurance",
    es: 'Agregar seguro',
    cs: 'Přidání pojištění',
  },
  addInsuranceWarn1: {
    ua: 'Потрібно обрати період',
    en: 'Period must be selected',
    de: 'Zeitraum muss ausgewählt werden',
    ru: 'Необходимо выбрать период',
    fr: 'La période doit être sélectionnée',
    es: 'Debe seleccionarse un período',
    cs: 'Musíte vybrat období',
  },
  addInsuranceWarn2: {
    ua: 'Потрібно обрати тип',
    en: 'Type must be selected',
    de: 'Typ muss ausgewählt werden',
    ru: 'Необходимо выбрать тип',
    fr: 'Le type doit être sélectionné',
    es: 'Debe seleccionarse un tipo',
    cs: 'Musíte vybrat typ',
  },
  addInsuranceWarnName: {
    ua: `Потрібно вказати ім'я`,
    en: 'Name is required',
    de: 'Name ist erforderlich',
    ru: 'Имя обязательно',
    fr: 'Le nom est requis',
    es: 'Se requiere el nombre',
    cs: 'Jméno je povinné',
  },
  addInsuranceSuccess: {
    ua: 'Страховка успішно додана!',
    en: 'Insurance successfully added!',
    de: 'Versicherung erfolgreich hinzugefügt!',
    ru: 'Страховка успешно добавлена!',
    fr: 'Assurance ajoutée avec succès !',
    es: 'Seguro agregado con éxito',
    cs: 'Pojištění úspěšně přidáno!',
  },
  addInsuranceError: {
    ua: 'Помилка оплати!',
    en: 'Payment error!',
    de: 'Zahlungsfehler!',
    ru: 'Ошибка оплаты!',
    fr: 'Erreur de paiement !',
    es: 'Error de pago',
    cs: 'Chyba platby!',
  },
  addInsuranceTitle: {
    ua: 'Замовити юридичну страховку',
    en: 'Order legal insurance',
    de: 'Rechtsschutzversicherung bestellen',
    ru: 'Заказать юридическую страховку',
    fr: 'Commander une assurance juridique',
    es: 'Solicitar seguro legal',
    cs: 'Objednat právní pojištění',
  },
  addInsuranceTypeTitle: {
    ua: 'Тип страховки',
    en: 'Insurance type',
    de: 'Versicherungstyp',
    ru: 'Тип страховки',
    fr: "Type d'assurance",
    es: 'Tipo de seguro',
    cs: 'Typ pojištění',
  },
  addInsuranceTypePlaceholder: {
    ua: 'Тип',
    en: 'Type',
    de: 'Typ',
    ru: 'Тип',
    fr: 'Type',
    es: 'Tipo',
    cs: 'Typ',
  },
  addInsurancePeriodTitle: {
    ua: 'Період',
    en: 'Period',
    de: 'Zeitraum',
    ru: 'Период',
    fr: 'Période',
    es: 'Período',
    cs: 'Období',
  },
  addInsurancePeriodPlaceholder: {
    ua: 'Термін',
    en: 'Term',
    de: 'Laufzeit',
    ru: 'Срок',
    fr: 'Durée',
    es: 'Plazo',
    cs: 'Doba',
  },
  addInsuranceText1: {
    ua: 'Збільшити страхову суму',
    en: 'Increase insurance coverage',
    de: 'Versicherungssumme erhöhen',
    ru: 'Увеличить страховую сумму',
    fr: "Augmenter la couverture d'assurance",
    es: 'Aumentar cobertura de seguro',
    cs: 'Zvýšit pojistné krytí',
  },
  addInsuranceText2: {
    ua: 'Вартість не може бути меньше мінімальної',
    en: 'Cost cannot be less than minimum',
    de: 'Kosten dürfen nicht weniger als das Minimum sein',
    ru: 'Стоимость не может быть меньше минимальной',
    fr: 'Le coût ne peut pas être inférieur au minimum',
    es: 'El costo no puede ser menor que el mínimo',
    cs: 'Cena nesmí být nižší než minimální',
  },
  addInsuranceCost: {
    ua: 'Вартість:',
    en: 'Cost:',
    de: 'Kosten:',
    ru: 'Стоимость:',
    fr: 'Coût :',
    es: 'Costo:',
    cs: 'Cena:',
  },
  addInsuranceProfit: {
    ua: 'Вигода:',
    en: 'Benefit:',
    de: 'Nutzen:',
    ru: 'Выгода:',
    fr: 'Avantage :',
    es: 'Beneficio:',
    cs: 'Výhoda:',
  },
  addClientNameForInsurance: {
    ua: `Ваше прізвище ім'я по батькові`,
    en: 'Insured name',
    de: 'Name des Versicherten',
    ru: 'Имя застрахованного',
    fr: "Nom de l'assuré",
    es: 'Nombre del asegurado',
    cs: 'Jméno pojištěného',
  },
  addClientNameForInsurancePlaceholder: {
    ua: `Введіть ім'я клієнта`,
    en: 'Enter the insured name',
    de: 'Geben Sie den Namen des Versicherten ein',
    ru: 'Введите имя застрахованного',
    fr: "Entrez le nom de l'assuré",
    es: 'Ingrese el nombre del asegurado',
    cs: 'Zadejte jméno pojištěného',
  },

  addTipsModalTitle: {
    ua: 'Залиште чайові',
    en: 'Leave a tip',
    de: 'Trinkgeld geben',
    ru: 'Оставить чаевые',
    fr: 'Laisser un pourboire',
    es: 'Dejar propina',
    cs: 'Nechat spropitné',
  },
  addTipsModalPlaceholder: {
    ua: 'Введіть суму чайових',
    en: 'Enter the tip amount',
    de: 'Geben Sie den Trinkgeldbetrag ein',
    ru: 'Введите сумму чаевых',
    fr: 'Entrez le montant du pourboire',
    es: 'Ingrese el monto de la propina',
    cs: 'Zadejte částku spropitného',
  },
  addTipsModalButton: {
    ua: 'Сплатити',
    en: 'Pay',
    de: 'Bezahlen',
    ru: 'Оплатить',
    fr: 'Payer',
    es: 'Pagar',
    cs: 'Zaplatit',
  },
  addTipsModalSuccess: {
    ua: 'Чайові відправлено',
    en: 'Tip sent',
    de: 'Trinkgeld gesendet',
    ru: 'Чаевые отправлены',
    fr: 'Pourboire envoyé',
    es: 'Propina enviada',
    cs: 'Spropitné odesláno',
  },
  autoPay: {
    ua: 'Автооплати',
    en: 'Auto Payments',
    de: 'Automatische Zahlungen',
    ru: 'Автоплатежи',
    fr: 'Paiements automatiques',
    es: 'Pagos automáticos',
    cs: 'Automatické platby',
  },
  autoPayGood_1: {
    ua: 'Автоматичні оплати увімкнені!',
    en: 'Automatic payments are enabled!',
    de: 'Automatische Zahlungen sind aktiviert!',
    ru: 'Автоматические платежи включены!',
    fr: 'Les paiements automatiques sont activés !',
    es: '¡Pagos automáticos habilitados!',
    cs: 'Automatické platby jsou povoleny!',
  },
  autoPayGood_2: {
    ua: 'Ви заощаджуєте:',
    en: 'You are saving:',
    de: 'Sie sparen:',
    ru: 'Вы экономите:',
    fr: 'Vous économisez :',
    es: 'Está ahorrando:',
    cs: 'Šetříte:',
  },
  autoPayBad_1_1: {
    ua: 'Увімкніть автоматичні оплати та отримайте',
    en: 'Enable automatic payments and receive',
    de: 'Aktivieren Sie automatische Zahlungen und erhalten Sie',
    ru: 'Включите автоматические платежи и получите',
    fr: 'Activez les paiements automatiques et recevez',
    es: 'Habilite los pagos automáticos y reciba',
    cs: 'Povolte automatické platby a získejte',
  },
  autoPayBad_1_2: {
    ua: 'кешбеку з кожного платежу.',
    en: 'cashback on each payment.',
    de: 'Cashback auf jede Zahlung.',
    ru: 'кешбэк с каждого платежа.',
    fr: 'du cashback sur chaque paiement.',
    es: 'reembolso en cada pago.',
    cs: 'cashback z každé platby.',
  },
  autoPayBad_2: {
    ua: 'Ви можете заощадити:',
    en: 'You can save:',
    de: 'Sie können sparen:',
    ru: 'Вы можете сэкономить:',
    fr: 'Vous pouvez économiser :',
    es: 'Puede ahorrar:',
    cs: 'Můžete ušetřit:',
  },
  autoPayModalSuccess: {
    ua: 'Зміни збережено',
    en: 'Changes saved',
    de: 'Änderungen gespeichert',
    ru: 'Изменения сохранены',
    fr: 'Modifications enregistrées',
    es: 'Cambios guardados',
    cs: 'Změny uloženy',
  },
  error: {
    ua: 'Помилка',
    en: 'Error',
    de: 'Fehler',
    ru: 'Ошибка',
    fr: 'Erreur',
    es: 'Error',
    cs: 'Chyba',
  },
  paymentSuccess: {
    ua: 'Оплата пройшла успішно',
    en: 'The payment was successful',
    de: 'Die Zahlung war erfolgreich',
    ru: 'Оплата прошла успешно',
    fr: 'Le paiement a été effectué avec succès',
    es: 'El pago fue exitoso',
    cs: 'Platba byla úspěšná',
  },
  paymentError: {
    ua: 'Помилка оплати',
    en: 'Payment error',
    de: 'Zahlungsfehler',
    ru: 'Ошибка оплаты',
    fr: 'Erreur de paiement',
    es: 'Error de pago',
    cs: 'Chyba platby',
  },
  paymentCheckoutUrlGenerated: {
    ua: 'Згенеровано посилання для оплати',
    en: 'A payment link has been generated',
    de: 'Es wurde ein Zahlungslink generiert',
    ru: 'Создана ссылка для оплаты',
    fr: 'Un lien de paiement a été généré',
    es: 'Se ha generado un enlace de pago',
    cs: 'Byl vygenerován odkaz na platbu',
  },
  autoPayModalTitle: {
    ua: 'Автоматична оплата послуг',
    en: 'Automatic service payment',
    de: 'Automatische Servicezahlung',
    ru: 'Автоматическая оплата услуг',
    fr: 'Paiement automatique des services',
    es: 'Pago automático de servicios',
    cs: 'Automatická platba za služby',
  },
  autoPayModalText1: {
    ua: 'Сумма щомісячної оплати:',
    en: 'Monthly payment amount:',
    de: 'Monatlicher Zahlungsbetrag:',
    ru: 'Сумма ежемесячной оплаты:',
    fr: 'Montant du paiement mensuel :',
    es: 'Monto de pago mensual:',
    cs: 'Výše měsíční platby:',
  },
  autoPayModalText2: {
    ua: 'Потрібно звернутись до свого юриста для отримання можливості автоматичної оплати.',
    en: 'You need to contact your lawyer to enable automatic payment.',
    de: 'Sie müssen sich an Ihren Anwalt wenden, um die automatische Zahlung zu aktivieren.',
    ru: 'Необходимо связаться с вашим юристом для включения автоплатежа.',
    fr: 'Vous devez contacter votre avocat pour activer le paiement automatique.',
    es: 'Debe contactar a su abogado para habilitar el pago automático.',
    cs: 'Musíte kontaktovat svého právníka pro povolení automatických plateb.',
  },
  autoPayModalText3: {
    ua: 'Якщо у вас виникли питання стосовно суми зверніться до свого куратора.',
    en: 'If you have any questions about the amount, please contact your curator.',
    de: 'Wenn Sie Fragen zum Betrag haben, wenden Sie sich bitte an Ihren Kurator.',
    ru: 'Если у вас есть вопросы по сумме, обратитесь к вашему куратору.',
    fr: 'Si vous avez des questions sur le montant, contactez votre responsable.',
    es: 'Si tiene preguntas sobre el monto, comuníquese con su encargado.',
    cs: 'Pokud máte otázky ohledně částky, kontaktujte svého kurátora.',
  },
  autoPayModalText4: {
    ua: 'Дозволити щомісячну оплату:',
    en: 'Enable monthly payment:',
    de: 'Monatliche Zahlung aktivieren:',
    ru: 'Разрешить ежемесячную оплату:',
    fr: 'Activer le paiement mensuel :',
    es: 'Habilitar pago mensual:',
    cs: 'Povolit měsíční platbu:',
  },
  autoPayModalText5: {
    ua: 'Для автоматичної оплати послуг необхідно прив`язати карту:',
    en: 'To enable automatic payment for services, you need to link a card:',
    de: 'Um die automatische Zahlung für Dienstleistungen zu aktivieren, müssen Sie eine Karte verknüpfen:',
    ru: 'Для автоматической оплаты услуг необходимо привязать карту:',
    fr: 'Pour activer le paiement automatique des services, vous devez lier une carte :',
    es: 'Para habilitar el pago automático de servicios, necesita vincular una tarjeta:',
    cs: 'Pro povolení automatických plateb za služby je nutné propojit kartu:',
  },
  autoPayModalText6: {
    ua: 'Буде проведено оплату на 1грн.',
    en: 'A payment of 1 UAH will be made.',
    de: 'Eine Zahlung von 1 UAH wird erfolgen.',
    ru: 'Будет произведен платеж на 1 грн.',
    fr: 'Un paiement de 1 UAH sera effectué.',
    es: 'Se realizará un pago de 1 UAH.',
    cs: 'Bude provedena platba 1 UAH.',
  },
  autoPayModalText7: {
    ua: 'Кошти повернуться назад.',
    en: 'The funds will be returned.',
    de: 'Das Geld wird zurückgegeben.',
    ru: 'Средства будут возвращены.',
    fr: 'Les fonds seront remboursés.',
    es: 'Los fondos serán devueltos.',
    cs: 'Prostředky budou vráceny.',
  },
  autoPayModalText8: {
    ua: 'При кожній автоматичній оплаті на Ваш баланс буде зараховано ',
    en: 'With each automatic payment, a cashback of ',
    de: 'Bei jeder automatischen Zahlung erhalten Sie einen Cashback von ',
    ru: 'С каждым автоматическим платежом на ваш баланс будет зачислен кэшбэк ',
    fr: 'À chaque paiement automatique, un cashback de ',
    es: 'Con cada pago automático, se le otorgará un reembolso de ',
    cs: 'S každou automatickou platbou obdržíte cashback ',
  },
  autoPayModalText8_1: {
    ua: 'кешбек у розмірі',
    en: 'cashback in the amount of',
    de: 'Cashback in Höhe von',
    ru: 'в размере',
    fr: 'de',
    es: 'en el monto de',
    cs: 'v částce',
  },
  autoPayModalText8_2: {
    ua: ' від суми списання.',
    en: ' from the deduction amount.',
    de: ' vom Abzugsbetrag.',
    ru: ' от суммы списания.',
    fr: ' du montant de la déduction.',
    es: ' del monto deducido.',
    cs: ' z odečtené částky.',
  },
  autoPayModalText9: {
    ua: 'Для зміни суми регулярного платежу зверніться до свого юриста.',
    en: 'To change the amount of the regular payment, contact your lawyer.',
    de: 'Um die Höhe der regelmäßigen Zahlung zu ändern, wenden Sie sich an Ihren Anwalt.',
    ru: 'Для изменения суммы регулярного платежа обратитесь к вашему юристу.',
    fr: 'Pour modifier le montant du paiement régulier, contactez votre avocat.',
    es: 'Para cambiar el monto del pago regular, comuníquese con su abogado.',
    cs: 'Pro změnu výše pravidelné platby kontaktujte svého právníka.',
  },
  autoPayModalText10_1: {
    ua: 'Увімкнути автооплату для всіх контрактів:',
    en: 'Enable autopay for all contracts:',
    de: 'Autopay für alle Verträge aktivieren:',
    ru: 'Включить автоплатеж для всех контрактов:',
    fr: 'Activer le paiement automatique pour tous les contrats :',
    es: 'Habilitar autopago para todos los contratos:',
    cs: 'Povolit automatické platby pro všechny smlouvy:',
  },
  autoPayModalText10_2: {
    ua: 'Ви можете додатково заощаджувати',
    en: 'You can additionally save',
    de: 'Sie können zusätzlich sparen',
    ru: 'Вы можете дополнительно экономить',
    fr: 'Vous pouvez économiser en plus',
    es: 'Además, puede ahorrar',
    cs: 'Můžete navíc ušetřit',
  },
  autoPayModalText10_3: {
    ua: 'кожного місяця',
    en: 'every month',
    de: 'jeden Monat',
    ru: 'каждый месяц',
    fr: 'chaque mois',
    es: 'cada mes',
    cs: 'každý měsíc',
  },
  autoPayModalText10_4: {
    ua: 'Автооплати для всіх контрактів увімкнено!',
    en: 'Autopay for all contracts is enabled!',
    de: 'Autopay für alle Verträge ist aktiviert!',
    ru: 'Автоплатеж для всех контрактов включен!',
    fr: 'Le paiement automatique pour tous les contrats est activé !',
    es: '¡Autopago para todos los contratos habilitado!',
    cs: 'Automatické platby pro všechny smlouvy jsou povoleny!',
  },
  autoPayModalText10_5: {
    ua: 'Ви заощаджуєте',
    en: 'You save',
    de: 'Sie sparen',
    ru: 'Вы экономите',
    fr: 'Vous économisez',
    es: 'Usted ahorra',
    cs: 'Šetříte',
  },
  chatUnverifiedPhoneOverlay: {
    ua: 'Будь ласка, підтвердьте свій номер телефону для безпеки та повного доступу до наших послуг. Це займе лише кілька хвилин. Дякуємо за розуміння.',
    en: 'Please verify your phone number to ensure security and full access to our services. It will only take a few minutes. Thank you for your understanding.',
    de: 'Bitte bestätigen Sie Ihre Telefonnummer, um Sicherheit und vollen Zugang zu unseren Diensten zu gewährleisten. Dies dauert nur wenige Minuten. Vielen Dank für Ihr Verständnis.',
    ru: 'Пожалуйста, подтвердите свой номер телефона для безопасности и полного доступа к нашим услугам. Это займет всего несколько минут. Спасибо за понимание.',
    fr: "Veuillez vérifier votre numéro de téléphone pour garantir la sécurité et l'accès complet à nos services. Cela ne prendra que quelques minutes. Merci de votre compréhension.",
    es: 'Por favor, verifique su número de teléfono para garantizar la seguridad y el acceso completo a nuestros servicios. Solo tomará unos minutos. Gracias por su comprensión.',
    cs: 'Potvrďte prosím své telefonní číslo pro zajištění bezpečnosti a plný přístup k našim službám. Zabere to jen pár minut. Děkujeme za pochopení.',
  },
  chatForContractError1: {
    ua: 'Пусте повідомлення',
    en: 'Empty message',
    de: 'Leere Nachricht',
    ru: 'Пустое сообщение',
    fr: 'Message vide',
    es: 'Mensaje vacío',
    cs: 'Prázdná zpráva',
  },
  chatForContractErrorCallback: {
    ua: 'Не вказана функція callback',
    en: 'Callback function not specified',
    de: 'Callback-Funktion nicht angegeben',
    ru: 'Не указана функция callback',
    fr: 'Fonction de rappel non spécifiée',
    es: 'Función de devolución no especificada',
    cs: 'Funkce zpětného volání není specifikována',
  },
  chatForContractError2: {
    ua: 'Відсутні дані для відправки',
    en: 'No data to send',
    de: 'Keine Daten zum Senden',
    ru: 'Нет данных для отправки',
    fr: 'Pas de données à envoyer',
    es: 'No hay datos para enviar',
    cs: 'Žádná data k odeslání',
  },
  chatForContractError3: {
    ua: 'Відсутній ID файлу',
    en: 'File ID is missing',
    de: 'Datei-ID fehlt',
    ru: 'Отсутствует ID файла',
    fr: 'ID de fichier manquant',
    es: 'Falta el ID de archivo',
    cs: 'Chybí ID souboru',
  },
  chatForContractError4: {
    ua: 'Без імені',
    en: 'Nameless',
    de: 'Namenslos',
    ru: 'Без имени',
    fr: 'Sans nom',
    es: 'Sin nombre',
    cs: 'Bez jména',
  },
  chatForContractError5: {
    ua: 'Відсутнє ID договору',
    en: 'Contract ID is missing',
    de: 'Vertrags-ID fehlt',
    ru: 'Отсутствует ID договора',
    fr: 'ID du contrat manquant',
    es: 'Falta el ID del contrato',
    cs: 'Chybí ID smlouvy',
  },
  chatForTenderTitle: {
    ua: 'Чат по тендеру',
    en: 'Tender Chat',
    de: 'Ausschreibungschat',
    ru: 'Чат по тендеру',
    fr: "Chat d'appel d'offres",
    es: 'Chat de licitación',
    cs: 'Chat k zakázce',
  },
  chatForDozhimsAITitle: {
    ua: 'Чат з техпідтримкою',
    en: 'Tech support chat',
    de: 'Technischer Support Chat',
    ru: 'Чат с техподдержкой',
    fr: 'Chat de support technique',
    es: 'Chat de soporte técnico',
    cs: 'Chat s technickou podporou',
  },
  chatAccountant: {
    ua: 'Чат з бухгалтером',
    en: 'Chat with an accountant',
    de: 'Chat mit einem Buchhalter',
    ru: 'Чат с бухгалтером',
    fr: 'Chat avec un comptable',
    es: 'Chat con un contador',
    cs: 'Chat s účetním',
  },
  personalConsultantChatTitle: {
    ua: 'Особистий консультант',
    en: 'Personal consultant',
    de: 'Persönlicher Berater',
    ru: 'Личный консультант',
    fr: 'Consultant personnel',
    es: 'Consultor personal',
    cs: 'Osobní konzultant',
  },
  confirmEmailAnonimTitle: {
    ua: 'Підтвердження пошти',
    en: 'Email Confirmation',
    de: 'E-Mail-Bestätigung',
    ru: 'Подтверждение почты',
    fr: "Confirmation de l'e-mail",
    es: 'Confirmación de correo electrónico',
    cs: 'Potvrzení e-mailu',
  },
  contractTasksTitle1: {
    ua: 'Назва завдання',
    en: 'Task Title',
    de: 'Aufgabentitel',
    ru: 'Название задачи',
    fr: 'Titre de la tâche',
    es: 'Título de la tarea',
    cs: 'Název úkolu',
  },
  contractTasksTitle2: {
    ua: 'Завдання по договору',
    en: 'Contract Tasks',
    de: 'Vertragsaufgaben',
    ru: 'Задачи по договору',
    fr: 'Tâches du contrat',
    es: 'Tareas del contrato',
    cs: 'Úkoly podle smlouvy',
  },
  contractTasksText1: {
    ua: 'Відхилено',
    en: 'Rejected',
    de: 'Abgelehnt',
    ru: 'Отклонено',
    fr: 'Rejeté',
    es: 'Rechazado',
    cs: 'Odmítnuto',
  },
  contractTasksText2: {
    ua: 'Дедлайн:',
    en: 'Deadline:',
    de: 'Frist:',
    ru: 'Срок:',
    fr: 'Date limite :',
    es: 'Fecha límite:',
    cs: 'Uzávěrka:',
  },
  contractTasksText3: {
    ua: 'Додати завдання',
    en: 'Add Task',
    de: 'Aufgabe hinzufügen',
    ru: 'Добавить задачу',
    fr: 'Ajouter une tâche',
    es: 'Agregar tarea',
    cs: 'Přidat úkol',
  },
  contractTasksTextSaveTask: {
    ua: 'Зберегти завдання',
    en: 'Save Task',
    de: 'Aufgabe speichern',
    ru: 'Сохранить задачу',
    fr: 'Enregistrer la tâche',
    es: 'Guardar tarea',
    cs: 'Uložit úkol',
  },
  contractTasksTextFilter: {
    ua: 'Фільтрувати по даті',
    en: 'Filter by date',
    de: 'Nach Datum filtern',
    ru: 'Фильтр по дате',
    fr: 'Filtrer par date',
    es: 'Filtrar por fecha',
    cs: 'Filtrovat podle data',
  },
  contractTasksInputPlaceholder: {
    ua: 'Опишіть завдання...',
    en: 'Describe the task...',
    de: 'Beschreiben Sie die Aufgabe...',
    ru: 'Опишите задачу...',
    fr: 'Décrivez la tâche...',
    es: 'Describa la tarea...',
    cs: 'Popište úkol...',
  },
  contractTasksText4: {
    ua: 'Виконано',
    en: 'Done',
    de: 'Erledigt',
    ru: 'Выполнено',
    fr: 'Fait',
    es: 'Hecho',
    cs: 'Hotovo',
  },
  taskDoneConfirmText: {
    ua: 'Бажаєте відмітити задачу як виконану?',
    en: 'Do you want to mark the task as completed?',
    de: 'Möchten Sie die Aufgabe als erledigt markieren?',
    ru: 'Хотите отметить задачу как выполненную?',
    fr: 'Voulez-vous marquer la tâche comme terminée ?',
    es: '¿Quiere marcar la tarea como completada?',
    cs: 'Chcete označit úkol jako dokončený?',
  },
  taskRejectConfirmText: {
    ua: 'Бажаєте скасувати задачу?',
    en: 'Do you want to cancel the task?',
    de: 'Möchten Sie die Aufgabe stornieren?',
    ru: 'Хотите отменить задачу?',
    fr: 'Voulez-vous annuler la tâche ?',
    es: '¿Quiere cancelar la tarea?',
    cs: 'Chcete úkol zrušit?',
  },
  firstLoginAutoPayText1: {
    ua: 'Бажаєте підключити автоматичну оплату по договору',
    en: 'Would you like to enable automatic payment for the contract',
    de: 'Möchten Sie die automatische Zahlung für den Vertrag aktivieren',
    ru: 'Хотите включить автоматическую оплату по договору',
    fr: 'Souhaitez-vous activer le paiement automatique pour le contrat',
    es: '¿Le gustaría habilitar el pago automático para el contrato?',
    cs: 'Chcete povolit automatickou platbu za smlouvu',
  },
  firstLoginAutoPayText2: {
    ua: 'від',
    en: 'from',
    de: 'von',
    ru: 'от',
    fr: 'de',
    es: 'de',
    cs: 'od',
  },
  leaveSuggestionModalWarn: {
    ua: 'Ваша пропозиція має бути довжиною не менше 20 символів',
    en: 'Your suggestion must be at least 20 characters long',
    de: 'Ihr Vorschlag muss mindestens 20 Zeichen lang sein',
    ru: 'Ваше предложение должно быть не менее 20 символов',
    fr: 'Votre suggestion doit comporter au moins 20 caractères',
    es: 'Su sugerencia debe tener al menos 20 caracteres',
    cs: 'Váš návrh musí mít alespoň 20 znaků',
  },
  leaveSuggestionModalSuccess: {
    ua: 'Ваша пропозиція відправлена',
    en: 'Your suggestion has been sent',
    de: 'Ihr Vorschlag wurde gesendet',
    ru: 'Ваше предложение отправлено',
    fr: 'Votre suggestion a été envoyée',
    es: 'Su sugerencia ha sido enviada',
    cs: 'Váš návrh byl odeslán',
  },
  leaveSuggestionModalError: {
    ua: 'Помилка, Спробуйте ще раз',
    en: 'Error, Please try again',
    de: 'Fehler, Bitte versuchen Sie es erneut',
    ru: 'Ошибка, попробуйте еще раз',
    fr: 'Erreur, veuillez réessayer',
    es: 'Error, intente de nuevo',
    cs: 'Chyba, zkuste to prosím znovu',
  },
  leaveSuggestionModalTitle: {
    ua: 'Залиште Ваші пропозиції',
    en: 'Leave Your Suggestions',
    de: 'Hinterlassen Sie Ihre Vorschläge',
    ru: 'Оставьте свои предложения',
    fr: 'Laissez vos suggestions',
    es: 'Deje sus sugerencias',
    cs: 'Nechte své návrhy',
  },
  leaveSuggestionModalText1: {
    ua: 'Якщо у Вас є скарги або пропозиції для вдосконалення роботи додатку єАдвокат - напишіть їх сюди, та',
    en: 'If you have any complaints or suggestions for improving the work of the app єАдвокат - write them here, and',
    de: 'Wenn Sie Beschwerden oder Vorschläge zur Verbesserung der App єАдвокат haben - schreiben Sie sie hier hin, und',
    ru: 'Если у вас есть жалобы или предложения по улучшению работы приложения єАдвокат - напишите их здесь, и',
    fr: "Si vous avez des réclamations ou suggestions pour améliorer l'application єАдвокат - écrivez-les ici, et",
    es: 'Si tiene quejas o sugerencias para mejorar la aplicación єАдвокат - escríbalas aquí, y',
    cs: 'Pokud máte stížnosti nebo návrhy na zlepšení aplikace єАдвокат - napište je sem, a',
  },
  leaveSuggestionModalText2: {
    ua: 'отримайте бонусну транзакцію у сумі 50грн.',
    en: 'receive a bonus transaction of 50 UAH.',
    de: 'erhalten Sie eine Bonus-Transaktion von 50 UAH.',
    ru: 'получите бонусную транзакцию в размере 50 грн.',
    fr: 'recevez une transaction bonus de 50 UAH.',
    es: 'reciba una transacción de bonificación de 50 UAH.',
    cs: 'obdržíte bonusovou transakci ve výši 50 UAH.',
  },
  notNowBtn: {
    ua: 'Не зараз',
    en: 'Not now',
    de: 'Nicht jetzt',
    ru: 'Не сейчас',
    fr: 'Pas maintenant',
    es: 'No ahora',
    cs: 'Teď ne',
  },
  // FunctionsNotUsedModal
  functionsNotUsedModalTitle: {
    ua: 'Яким функціоналом Ви не користувалися?',
    en: 'Which functionality have you not used?',
    de: 'Welche Funktionen haben Sie nicht genutzt?',
    ru: 'Каким функционалом вы не пользовались?',
    fr: "Quelles fonctionnalités n'avez-vous pas utilisées ?",
    es: '¿Qué funcionalidad no has utilizado?',
    cs: 'Kterou funkci jste nepoužili?',
  },
  functionsNotUsedModalText: {
    ua: 'Позначте функціонал, яким Ви не користувалися, та',
    en: `Mark the functionality you haven't used, and`,
    de: 'Markieren Sie den Funktionsumfang, den Sie nicht genutzt haben, und',
    ru: 'Отметьте функционал, которым вы не пользовались, и',
    fr: "Marquez les fonctionnalités que vous n'avez pas utilisées, et",
    es: 'Marca la funcionalidad que no has usado, y',
    cs: 'Označte funkce, které jste nepoužili, a',
  },
  functionsNotUsedModalSuccess: {
    ua: 'Ваша відповідь відправлена',
    en: 'Your response has been sent',
    de: 'Ihre Antwort wurde gesendet',
    ru: 'Ваш ответ отправлен',
    fr: 'Votre réponse a été envoyée',
    es: 'Tu respuesta ha sido enviada',
    cs: 'Vaše odpověď byla odeslána',
  },

  // Notification
  notificationTitle: {
    ua: 'Прив`язку пошти завершено!',
    en: 'Email Confirmation Completed!',
    de: 'E-Mail-Bestätigung abgeschlossen!',
    ru: 'Подтверждение электронной почты завершено!',
    fr: "Confirmation de l'email terminée!",
    es: '¡Confirmación de correo completada!',
    cs: 'Potvrzení e-mailu dokončeno!',
  },
  notificationText: {
    ua: 'До вашого облікового запису успішно прив`язано пошту',
    en: 'Your email has been successfully linked to your account',
    de: 'Ihre E-Mail-Adresse wurde erfolgreich mit Ihrem Konto verknüpft',
    ru: 'Ваш email успешно привязан к аккаунту',
    fr: 'Votre email a été lié avec succès à votre compte',
    es: 'Tu correo ha sido vinculado exitosamente a tu cuenta',
    cs: 'Váš e-mail byl úspěšně připojen k vašemu účtu',
  },

  // PaymentFrame
  paymentFrameType1: {
    ua: 'Оплата за записом',
    en: 'Appointment Payment',
    de: 'Terminzahlung',
    ru: 'Оплата за запись',
    fr: 'Paiement pour le rendez-vous',
    es: 'Pago de cita',
    cs: 'Platba za schůzku',
  },
  paymentFrameType2: {
    ua: 'Оплата за записом',
    en: 'Appointment Payment',
    de: 'Terminzahlung',
    ru: 'Оплата за запись',
    fr: 'Paiement pour le rendez-vous',
    es: 'Pago de cita',
    cs: 'Platba za schůzku',
  },
  paymentFrameType3: {
    ua: 'Поповнення балансу',
    en: 'Balance Top-up',
    de: 'Kontostand aufladen',
    ru: 'Пополнение баланса',
    fr: 'Recharge de solde',
    es: 'Recarga de saldo',
    cs: 'Doplnění zůstatku',
  },
  paymentFrameType4: {
    ua: 'Оплата за договором',
    en: 'Contract Payment',
    de: 'Vertragszahlung',
    ru: 'Оплата по договору',
    fr: 'Paiement pour le contrat',
    es: 'Pago de contrato',
    cs: 'Platba za smlouvu',
  },
  paymentFrameType5: {
    ua: 'Оплата за тривожну кнопку',
    en: 'Panic Button Payment',
    de: 'Panikknopfzahlung',
    ru: 'Оплата тревожной кнопки',
    fr: 'Paiement pour le bouton de panique',
    es: 'Pago del botón de pánico',
    cs: 'Platba za tlačítko paniky',
  },
  paymentFrameType6: {
    ua: 'Оплата за питання',
    en: 'Question Payment',
    de: 'Fragezahlung',
    ru: 'Оплата за вопрос',
    fr: 'Paiement pour la question',
    es: 'Pago de pregunta',
    cs: 'Platba za otázku',
  },
  paymentFrameType7: {
    ua: 'Подяка юристу',
    en: 'Lawyer Gratuity',
    de: 'Anwalt Trinkgeld',
    ru: 'Благодарность юристу',
    fr: "Gratification pour l'avocat",
    es: 'Gratitud al abogado',
    cs: 'Odměna právníkovi',
  },
  // Questionnaires
  questionnaireSuccess: {
    ua: 'Дякуюємо за відповідь !',
    en: 'Thank you for your response !',
    de: 'Danke für Ihre Antwort !',
    ru: 'Спасибо за ваш ответ!',
    fr: 'Merci pour votre réponse !',
    es: '¡Gracias por tu respuesta!',
    cs: 'Děkujeme za vaši odpověď!',
  },
  questionnaireError: {
    ua: 'Помилка збереження відповіді !',
    en: 'Error saving response !',
    de: 'Fehler beim Speichern der Antwort !',
    ru: 'Ошибка при сохранении ответа!',
    fr: 'Erreur de sauvegarde de la réponse !',
    es: 'Error al guardar la respuesta !',
    cs: 'Chyba při ukládání odpovědi!',
  },
  // QuestionnaireArchive
  questionnaireArchiveWarn1: {
    ua: 'Несанкціонований доступ. Система вас не упізнає.',
    en: 'Unauthorized access. The system does not recognize you.',
    de: 'Nicht autorisierter Zugriff. Das System erkennt Sie nicht.',
    ru: 'Несанкционированный доступ. Система вас не узнает.',
    fr: 'Accès non autorisé. Le système ne vous reconnaît pas.',
    es: 'Acceso no autorizado. El sistema no te reconoce.',
    cs: 'Neoprávněný přístup. Systém vás nepoznává.',
  },
  questionnaireArchiveWarn2: {
    ua: 'Для анкетування потрібно вказати оцінку якості.',
    en: 'To fill out the questionnaire, you need to provide a quality rating.',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine Qualitätsbewertung angeben.',
    ru: 'Для заполнения анкеты необходимо указать оценку качества.',
    fr: 'Pour remplir le questionnaire, vous devez fournir une note de qualité.',
    es: 'Para completar el cuestionario, debe proporcionar una calificación de calidad.',
    cs: 'Pro vyplnění dotazníku musíte uvést hodnocení kvality.',
  },
  questionnaireArchiveWarn3: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Задоволенні роботою адвоката?"',
    en: 'To fill out the questionnaire, you need to choose one of the options for the question: "Satisfaction with the lawyer\'s work?"',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine der Optionen für die Frage angeben: "Zufriedenheit mit der Arbeit des Anwalts?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Удовлетворены работой адвоката?"',
    fr: 'Pour remplir le questionnaire, vous devez choisir une des options pour la question : "Satisfaction avec le travail de l\'avocat ?"',
    es: 'Para completar el cuestionario, debe elegir una de las opciones para la pregunta: "¿Satisfacción con el trabajo del abogado?"',
    cs: 'Pro vyplnění dotazníku musíte vybrat jednu z možností k otázce: "Spokojenost s prací právníka?"',
  },
  questionnaireArchiveWarn4: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Задоволенні комунікацією з нашим адвокатом?"',
    en: 'To fill out the questionnaire, you need to choose one of the options for the question: "Satisfaction with communication with our lawyer?"',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine der Optionen für die Frage angeben: "Zufriedenheit mit der Kommunikation mit unserem Anwalt?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Удовлетворены ли вы общением с нашим адвокатом?"',
    fr: 'Pour remplir le questionnaire, vous devez choisir une des options pour la question : "Satisfaction de la communication avec notre avocat ?"',
    es: 'Para completar el cuestionario, debe elegir una de las opciones para la pregunta: "¿Satisfacción con la comunicación con nuestro abogado?"',
    cs: 'Pro vyplnění dotazníku musíte vybrat jednu z možností k otázce: "Spokojenost s komunikací s naším právníkem?"',
  },
  questionnaireArchiveWarn5: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Звернулися б ще до нашої компанії?"',
    en: 'To fill out the questionnaire, you need to choose one of the options for the question: "Would you turn to our company again?"',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine der Optionen für die Frage angeben: "Würden Sie sich wieder an unser Unternehmen wenden?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Обратились бы снова в нашу компанию?"',
    fr: 'Pour remplir le questionnaire, vous devez choisir une des options pour la question : "Reviendriez-vous vers notre entreprise ?"',
    es: 'Para completar el cuestionario, debe elegir una de las opciones para la pregunta: "¿Volverías a nuestra empresa?"',
    cs: 'Pro vyplnění dotazníku musíte vybrat jednu z možností k otázce: "Obrátili byste se znovu na naši společnost?"',
  },
  questionnaireArchiveWarn6: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Порадили комусь нашу компанію?"',
    en: 'To fill out the questionnaire, you need to choose one of the options for the question: "Have you recommended our company to anyone?"',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine der Optionen für die Frage angeben: "Haben Sie unser Unternehmen jemandem empfohlen?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Рекомендовали ли вы нашу компанию кому-нибудь?"',
    fr: 'Pour remplir le questionnaire, vous devez choisir une des options pour la question : "Avez-vous recommandé notre entreprise à quelqu\'un ?"',
    es: 'Para completar el cuestionario, debe elegir una de las opciones para la pregunta: "¿Ha recomendado nuestra empresa a alguien?"',
    cs: 'Pro vyplnění dotazníku musíte vybrat jednu z možností k otázce: "Doporučili jste naši společnost někomu?"',
  },
  questionnaireArchiveWarn7: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Довіряєте нашій компанії?"',
    en: 'To fill out the questionnaire, you need to choose one of the options for the question: "Do you trust our company?"',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine der Optionen für die Frage angeben: "Vertrauen Sie unserem Unternehmen?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Доверяете ли вы нашей компании?"',
    fr: 'Pour remplir le questionnaire, vous devez choisir une des options pour la question : "Faites-vous confiance à notre entreprise ?"',
    es: 'Para completar el cuestionario, debe elegir una de las opciones para la pregunta: "¿Confía en nuestra empresa?"',
    cs: 'Pro vyplnění dotazníku musíte vybrat jednu z možností k otázce: "Důvěřujete naší společnosti?"',
  },
  questionnaireArchiveWarn8: {
    ua: 'Для анкетування потрібно дати свою пропозицію по покращенню або скаргу.',
    en: 'To participate in the survey, you need to provide your suggestion for improvement or complaint.',
    de: 'Um an der Umfrage teilzunehmen, müssen Sie Ihren Verbesserungsvorschlag oder Ihre Beschwerde abgeben.',
    ru: 'Для участия в опросе необходимо предоставить предложение по улучшению или жалобу.',
    fr: "Pour participer au sondage, vous devez fournir une suggestion d'amélioration ou une plainte.",
    es: 'Para participar en la encuesta, debe proporcionar una sugerencia de mejora o una queja.',
    cs: 'Pro účast v průzkumu musíte poskytnout návrh na zlepšení nebo stížnost.',
  },
  questionnaireArchiveWarn9: {
    ua: 'Для анкетування потрібно написати текст пропозиції довше за 20 символів.',
    en: 'To participate in the survey, you need to write a suggestion text longer than 20 characters.',
    de: 'Um an der Umfrage teilzunehmen, müssen Sie einen Vorschlagstext schreiben, der länger als 20 Zeichen ist.',
    ru: 'Для участия в опросе необходимо написать предложение длиннее 20 символов.',
    fr: 'Pour participer au sondage, vous devez écrire un texte de suggestion de plus de 20 caractères.',
    es: 'Para participar en la encuesta, debe escribir un texto de sugerencia de más de 20 caracteres.',
    cs: 'Pro účast v průzkumu musíte napsat návrh delší než 20 znaků.',
  },
  questionnaireArchiveInfo: {
    ua: 'Дякуємо за приділений час. Ваш бонусний рахунок поповнено на 500 грн.',
    en: 'Thank you for your time. Your bonus account has been credited with 500 UAH.',
    de: 'Vielen Dank für Ihre Zeit. Ihr Bonuskonto wurde mit 500 UAH aufgeladen.',
    ru: 'Спасибо за уделенное время. Ваш бонусный счет пополнен на 500 грн.',
    fr: 'Merci pour votre temps. Votre compte bonus a été crédité de 500 UAH.',
    es: 'Gracias por su tiempo. Su cuenta de bonificación ha sido acreditada con 500 UAH.',
    cs: 'Děkujeme za váš čas. Váš bonusový účet byl navýšen o 500 UAH.',
  },
  questionnaireArchiveText: {
    ua: '1 - найнижчий показник, 10 - найвищий показник',
    en: '1 - lowest score, 10 - highest score',
    de: '1 - niedrigste Punktzahl, 10 - höchste Punktzahl',
    ru: '1 - самый низкий балл, 10 - самый высокий балл',
    fr: '1 - score le plus bas, 10 - score le plus élevé',
    es: '1 - puntuación más baja, 10 - puntuación más alta',
    cs: '1 - nejnižší skóre, 10 - nejvyšší skóre',
  },
  // QuestionnaireArchive
  questionnaireArchivePlaceholder: {
    ua: 'Оберіть оцінку:',
    en: 'Choose rating:',
    de: 'Bewertung wählen:',
    ru: 'Выберите оценку:',
    fr: 'Choisissez une note :',
    es: 'Elija una calificación:',
    cs: 'Vyberte hodnocení:',
  },
  placeholderChooseOption: {
    ua: 'Оберіть один із варіантів:',
    en: 'Choose one of the options:',
    de: 'Wählen Sie eine der Optionen:',
    ru: 'Выберите один из вариантов:',
    fr: 'Choisissez une des options :',
    es: 'Elija una de las opciones:',
    cs: 'Vyberte jednu z možností:',
  },
  questionnaireArchiveAdvertisingInfo: {
    ua: 'Ви можете переглянути цю інформацію знову в кабінеті партнера.',
    en: 'You can review this information again in the partner’s account.',
    de: 'Sie können diese Informationen erneut im Partnerkonto einsehen.',
    ru: 'Вы можете снова просмотреть эту информацию в кабинете партнера.',
    fr: 'Vous pouvez revoir ces informations dans le compte partenaire.',
    es: 'Puede revisar esta información nuevamente en la cuenta del socio.',
    cs: 'Tyto informace si můžete znovu prohlédnout v partnerském účtu.',
  },
  questionnaireArchiveAdvertisingText1: {
    ua: 'Дякуємо за вашу позитивну оцінку',
    en: 'Thank you for your positive feedback',
    de: 'Danke für Ihr positives Feedback',
    ru: 'Спасибо за вашу положительную оценку',
    fr: 'Merci pour votre avis positif',
    es: 'Gracias por su comentario positivo',
    cs: 'Děkujeme za vaše pozitivní hodnocení',
  },
  questionnaireArchiveAdvertisingText2: {
    ua: 'СПЕЦІАЛЬНА ПРОПОЗИЦІЯ',
    en: 'SPECIAL OFFER',
    de: 'SONDERANGEBOT',
    ru: 'СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ',
    fr: 'OFFRE SPÉCIALE',
    es: 'OFERTA ESPECIAL',
    cs: 'SPECIÁLNÍ NABÍDKA',
  },
  questionnaireArchiveAdvertisingText3: {
    ua: 'Отримуйте',
    en: 'Get',
    de: 'Erhalte',
    ru: 'Получайте',
    fr: 'Recevez',
    es: 'Obtenga',
    cs: 'Získejte',
  },
  questionnaireArchiveAdvertisingText3_1: {
    ua: 'за рекомендацію',
    en: 'for recommendation',
    de: 'für Empfehlung',
    ru: 'за рекомендацию',
    fr: 'pour recommandation',
    es: 'por recomendación',
    cs: 'za doporučení',
  },
  questionnaireArchiveAdvertisingText4_1: {
    ua: 'Пропонуємо Вам стати нашим',
    en: 'We offer you to become our',
    de: 'Wir bieten Ihnen an, unser',
    ru: 'Предлагаем вам стать нашим',
    fr: 'Nous vous proposons de devenir notre',
    es: 'Le ofrecemos convertirse en nuestro',
    cs: 'Nabízíme vám stát se naším',
  },
  questionnaireArchiveAdvertisingText4_2: {
    ua: 'Партнером',
    en: 'Partner',
    de: 'Partner',
    ru: 'партнером',
    fr: 'Partenaire',
    es: 'Socio',
    cs: 'Partnerem',
  },
  questionnaireArchiveAdvertisingText4_3: {
    ua: 'та отримувати',
    en: 'and receive',
    de: 'und erhalten',
    ru: 'и получать',
    fr: 'et recevoir',
    es: 'y recibir',
    cs: 'a dostávat',
  },
  questionnaireArchiveAdvertisingText4_4: {
    ua: 'пасивний дохід',
    en: 'passive income',
    de: 'passives Einkommen',
    ru: 'пассивный доход',
    fr: 'un revenu passif',
    es: 'ingresos pasivos',
    cs: 'pasivní příjem',
  },
  questionnaireArchiveAdvertisingText4_5: {
    ua: 'за добрі рекомендації про нашу компанію.',
    en: 'for good recommendations about our company.',
    de: 'für gute Empfehlungen über unser Unternehmen.',
    ru: 'за хорошие рекомендации о нашей компании.',
    fr: 'pour de bonnes recommandations sur notre entreprise.',
    es: 'por buenas recomendaciones sobre nuestra empresa.',
    cs: 'za dobrá doporučení naší společnosti.',
  },
  questionnaireArchiveAdvertisingText5: {
    ua: 'Відео презентація Партнерської програми АО «Захист»:',
    en: 'Video presentation of the Partnership Program of LLC "Zakhyst":',
    de: 'Video-Präsentation des Partnerschaftsprogramms der GmbH "Zakhyst":',
    ru: 'Видео-презентация Партнерской программы ООО "Защита":',
    fr: 'Présentation vidéo du programme de partenariat de LLC "Zakhyst" :',
    es: 'Presentación en vídeo del Programa de Asociación de LLC "Zakhyst":',
    cs: 'Videoprezentace partnerského programu společnosti LLC "Zakhyst":',
  },
  learnMoreBtn: {
    ua: 'Дізнатися більше',
    en: 'Learn more',
    de: 'Erfahren Sie mehr',
    ru: 'Узнать больше',
    fr: 'En savoir plus',
    es: 'Saber más',
    cs: 'Zjistit více',
  },
  // QuestionnaireHotline
  questionnaireHotlineWarn1: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Оцініть якість консультації?"',
    en: 'To complete the survey, you need to select one of the options for the question: "Rate the quality of the consultation?"',
    de: 'Um die Umfrage abzuschließen, müssen Sie eine der Optionen für die Frage auswählen: "Bewerten Sie die Qualität der Beratung?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Оцените качество консультации?"',
    fr: 'Pour compléter le sondage, vous devez sélectionner une des options pour la question : "Évaluez la qualité de la consultation ?"',
    es: 'Para completar la encuesta, debe seleccionar una de las opciones para la pregunta: "¿Califique la calidad de la consulta?"',
    cs: 'Pro dokončení průzkumu musíte vybrat jednu z možností k otázce: "Ohodnoťte kvalitu konzultace?"',
  },
  questionnaireHotlineWarn2: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Оцініть привітність персоналу АО "Захист"?"',
    en: 'To complete the survey, you need to select one of the options for the question: "Rate the friendliness of the staff of the "Zakhyst" company?"',
    de: 'Um die Umfrage abzuschließen, müssen Sie eine der Optionen für die Frage auswählen: "Bewerten Sie die Freundlichkeit des Personals der Firma "Zakhyst"?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Оцените приветливость персонала АО "Защита"?"',
    fr: 'Pour compléter le sondage, vous devez sélectionner une des options pour la question : "Évaluez la convivialité du personnel de la société "Zakhyst" ?"',
    es: 'Para completar la encuesta, debe seleccionar una de las opciones para la pregunta: "¿Califique la amabilidad del personal de la empresa "Zakhyst"?"',
    cs: 'Pro dokončení průzkumu musíte vybrat jednu z možností k otázce: "Ohodnoťte přívětivost personálu společnosti "Zakhyst"?"',
  },
  questionnaireHotlineWarn3: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Чи вчасно розпочалася консультація?"',
    en: 'To complete the survey, you need to select one of the options for the question: "Did the consultation start on time?"',
    de: 'Um die Umfrage abzuschließen, müssen Sie eine der Optionen für die Frage auswählen: "Hat die Beratung pünktlich begonnen?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Консультация началась вовремя?"',
    fr: 'Pour compléter le sondage, vous devez sélectionner une des options pour la question : "La consultation a-t-elle commencé à l\'heure ?"',
    es: 'Para completar la encuesta, debe seleccionar una de las opciones para la pregunta: "¿La consulta comenzó a tiempo?"',
    cs: 'Pro dokončení průzkumu musíte vybrat jednu z možností k otázce: "Začala konzultace včas?"',
  },
  questionnaireHotlineWarn4: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Чи влаштувала Вас ціна консультації?"',
    en: 'To complete the survey, you need to select one of the options for the question: "Was the consultation fee satisfactory for you?"',
    de: 'Um die Umfrage abzuschließen, müssen Sie eine der Optionen für die Frage auswählen: "War die Beratungsgebühr für Sie zufriedenstellend?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Вас устроила стоимость консультации?"',
    fr: 'Pour compléter le sondage, vous devez sélectionner une des options pour la question : "Le prix de la consultation vous a-t-il satisfait ?"',
    es: 'Para completar la encuesta, debe seleccionar una de las opciones para la pregunta: "¿Fue satisfactoria la tarifa de la consulta?"',
    cs: 'Pro dokončení průzkumu musíte vybrat jednu z možností k otázce: "Byla cena konzultace pro vás uspokojivá?"',
  },
  questionnaireHotlineWarn5: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Чи зрозумілою для Вас була консультація?"',
    en: 'To complete the survey, you need to select one of the options for the question: "Was the consultation understandable for you?"',
    de: 'Um die Umfrage abzuschließen, müssen Sie eine der Optionen für die Frage auswählen: "War die Beratung für Sie verständlich?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Консультация была понятной для вас?"',
    fr: 'Pour compléter le sondage, vous devez sélectionner une des options pour la question : "La consultation était-elle compréhensible pour vous ?"',
    es: 'Para completar la encuesta, debe seleccionar una de las opciones para la pregunta: "¿Fue comprensible la consulta para usted?"',
    cs: 'Pro dokončení průzkumu musíte vybrat jednu z možností k otázce: "Byla konzultace pro vás srozumitelná?"',
  },
  // QuestionnaireHotline
  questionnaireHotlineInfo2: {
    ua: 'Дякуємо за приділений час. Вам ще залишилося завершити реєстрацію.',
    en: 'Thank you for your time. You still need to complete the registration.',
    de: 'Vielen Dank für Ihre Zeit. Sie müssen Ihre Registrierung noch abschließen.',
    ru: 'Спасибо за уделенное время. Вам нужно завершить регистрацию.',
    fr: "Merci pour votre temps. Vous devez encore terminer l'inscription.",
    es: 'Gracias por su tiempo. Aún necesita completar el registro.',
    cs: 'Děkujeme za váš čas. Musíte ještě dokončit registraci.',
  },
  questionnaireHotlineInfo3: {
    ua: 'Дякуємо за приділений час.',
    en: 'Thank you for your time.',
    de: 'Vielen Dank für Ihre Zeit.',
    ru: 'Спасибо за уделенное время.',
    fr: 'Merci pour votre temps.',
    es: 'Gracias por su tiempo.',
    cs: 'Děkujeme za váš čas.',
  },
  questionnaireHotlineInfo4: {
    ua: 'Будь ласка, потім заповніть анкету. Нам важлива ваша думка.',
    en: 'Please fill out the questionnaire afterwards. Your opinion is important to us.',
    de: 'Bitte füllen Sie anschließend den Fragebogen aus. Ihre Meinung ist uns wichtig.',
    ru: 'Пожалуйста, заполните анкету позже. Нам важно ваше мнение.',
    fr: 'Veuillez remplir le questionnaire par la suite. Votre opinion est importante pour nous.',
    es: 'Por favor, complete el cuestionario más tarde. Su opinión es importante para nosotros.',
    cs: 'Vyplňte prosím dotazník později. Na vašem názoru nám záleží.',
  },
  questionnaireHotlineTitle: {
    ua: 'Оцінити якість консультації',
    en: 'Evaluate the quality of consultation',
    de: 'Bewerten Sie die Qualität der Beratung',
    ru: 'Оценить качество консультации',
    fr: 'Évaluer la qualité de la consultation',
    es: 'Evaluar la calidad de la consulta',
    cs: 'Ohodnoťte kvalitu konzultace',
  },
  questionnaireHotlineText1: {
    ua: 'Анкета є конфіденційною та не підлягає розголошенню перед адвокатами та юристами!',
    en: 'The questionnaire is confidential and will not be disclosed to lawyers and attorneys!',
    de: 'Der Fragebogen ist vertraulich und wird nicht an Anwälte und Juristen weitergegeben!',
    ru: 'Анкета является конфиденциальной и не подлежит разглашению перед адвокатами и юристами!',
    fr: 'Le questionnaire est confidentiel et ne sera pas divulgué aux avocats et juristes!',
    es: 'El cuestionario es confidencial y no se divulgará a abogados y juristas!',
    cs: 'Dotazník je důvěrný a nebude zveřejněn právníkům a advokátům!',
  },
  questionnaireHotlineText2: {
    ua: 'Оцініть якість консультації',
    en: 'Evaluate the quality of consultation',
    de: 'Bewerten Sie die Qualität der Beratung',
    ru: 'Оцените качество консультации',
    fr: 'Évaluez la qualité de la consultation',
    es: 'Evalúe la calidad de la consulta',
    cs: 'Ohodnoťte kvalitu konzultace',
  },
  questionnaireHotlineText3: {
    ua: 'Оцініть привітність персоналу',
    en: 'Rate the friendliness of the staff',
    de: 'Bewerten Sie die Freundlichkeit des Personals',
    ru: 'Оцените приветливость персонала',
    fr: 'Évaluez la convivialité du personnel',
    es: 'Califique la amabilidad del personal',
    cs: 'Ohodnoťte přívětivost personálu',
  },
  questionnaireHotlineText4: {
    ua: 'Чи вчасно розпочалася консультація?',
    en: 'Did the consultation start on time?',
    de: 'Hat die Beratung pünktlich begonnen?',
    ru: 'Консультация началась вовремя?',
    fr: "La consultation a-t-elle commencé à l'heure ?",
    es: '¿La consulta comenzó a tiempo?',
    cs: 'Začala konzultace včas?',
  },
  questionnaireHotlineText5: {
    ua: 'Чи влаштувала Вас ціна консультації?',
    en: 'Was the consultation fee satisfactory for you?',
    de: 'Hat Sie die Beratungsgebühr zufriedengestellt?',
    ru: 'Вас устроила стоимость консультации?',
    fr: 'Le prix de la consultation vous a-t-il satisfait ?',
    es: '¿Fue satisfactoria la tarifa de la consulta?',
    cs: 'Byla cena konzultace pro vás uspokojivá?',
  },
  questionnaireHotlineText6: {
    ua: 'Чи зрозумілою для Вас була консультація?',
    en: 'Was the consultation understandable for you?',
    de: 'War die Beratung für Sie verständlich?',
    ru: 'Консультация была понятной для вас?',
    fr: 'La consultation était-elle compréhensible pour vous ?',
    es: '¿Fue comprensible la consulta para usted?',
    cs: 'Byla konzultace pro vás srozumitelná?',
  },
  questionnaireHotlineText7: {
    ua: 'Чи зрозумілою для Вас мовою проводилася консультація?',
    en: 'Was the consultation conducted in a language that was clear to you?',
    de: 'Wurde die Beratung in einer für Sie verständlichen Sprache durchgeführt?',
    ru: 'Консультация проводилась на понятном для вас языке?',
    fr: 'La consultation a-t-elle été réalisée dans une langue que vous compreniez ?',
    es: '¿La consulta se realizó en un idioma comprensible para usted?',
    cs: 'Byla konzultace vedena v jazyce, kterému rozumíte?',
  },
  questionnaireHotlineText8: {
    ua: 'Чи розповіли Вам про додаток «єАдвокат»?',
    en: 'Were you informed about the "eLawyer" application?',
    de: 'Wurden Sie über die "eLawyer"-Anwendung informiert?',
    ru: 'Вам рассказали о приложении «еАдвокат»?',
    fr: 'Vous a-t-on informé de l\'application "eLawyer" ?',
    es: '¿Le informaron sobre la aplicación "eLawyer"?',
    cs: 'Byli jste informováni o aplikaci "eLawyer"?',
  },
  questionnaireHotlineText9: {
    ua: 'Чи уклали Ви договір з нашою компанією?',
    en: 'Did you sign a contract with our company?',
    de: 'Haben Sie einen Vertrag mit unserem Unternehmen unterzeichnet?',
    ru: 'Вы заключили договор с нашей компанией?',
    fr: 'Avez-vous signé un contrat avec notre entreprise ?',
    es: '¿Firmó un contrato con nuestra empresa?',
    cs: 'Podepsali jste smlouvu s naší společností?',
  },
  questionnaireHotlineText10: {
    ua: 'Місце для скарг чи пропозицій:',
    en: 'Place for complaints or suggestions:',
    de: 'Platz für Beschwerden oder Vorschläge:',
    ru: 'Место для жалоб или предложений:',
    fr: 'Espace pour les plaintes ou suggestions :',
    es: 'Espacio para quejas o sugerencias:',
    cs: 'Místo pro stížnosti nebo návrhy:',
  },
  questionnaireHotlinePlaceholder: {
    ua: 'Як нам стати краще?',
    en: 'How can we improve?',
    de: 'Wie können wir uns verbessern?',
    ru: 'Как нам стать лучше?',
    fr: 'Comment pouvons-nous nous améliorer ?',
    es: '¿Cómo podemos mejorar?',
    cs: 'Jak se můžeme zlepšit?',
  },
  leaveForLaterBtn: {
    ua: 'Залишити на потім',
    en: 'Leave for later',
    de: 'Später verlassen',
    ru: 'Оставить на потом',
    fr: 'Laisser pour plus tard',
    es: 'Dejar para más tarde',
    cs: 'Nechat na později',
  },
  // RedButtonContactModal
  specialist: {
    ua: 'Фахівець',
    en: 'Specialist',
    de: 'Fachmann',
    ru: 'Специалист',
    fr: 'Spécialiste',
    es: 'Especialista',
    cs: 'Specialista',
  },
  // redButtonModalCall: {
  //   ua: 'Терміновий дзвінок',
  //   en: 'Urgent call',
  //   de: 'Wichtiger Anruf',
  // },
  redButtonModalMessage: {
    ua: 'Написати повідомлення',
    en: 'Send a message',
    de: 'Eine Nachricht senden',
    ru: 'Отправить сообщение',
    fr: 'Envoyer un message',
    es: 'Enviar un mensaje',
    cs: 'Odeslat zprávu',
  },
  // redButtonModalFile: {
  //   ua: 'Відправити файл',
  //   en: 'Send an urgent file',
  //   de: 'Eine dringende Datei senden',
  // },
  redButtonModalTitle: {
    ua: 'Оберіть термінову послугу',
    en: 'Select an urgent service',
    de: 'Wählen Sie einen dringenden Service',
    ru: 'Выберите срочную услугу',
    fr: 'Choisissez un service urgent',
    es: 'Seleccione un servicio urgente',
    cs: 'Vyberte naléhavou službu',
  },
  // rulesModal ============
  consultation_button_text_1: {
    ua: 'Оплатити консультацію клубною картою',
    en: 'Pay for consultation with a club card',
    de: 'Beratung mit einer Clubkarte bezahlen',
    ru: 'Оплатить консультацию клубной картой',
    fr: 'Payer la consultation avec une carte de club',
    es: 'Pague la consulta con una tarjeta de club',
    cs: 'Zaplatit konzultaci klubovou kartou',
  },
  consultation_button_text_2: {
    ua: 'Оплатити VIP консультацію клубною картою',
    en: 'Pay for VIP consultation with a club card',
    de: 'VIP-Beratung mit einer Clubkarte bezahlen',
    ru: 'Оплатить VIP-консультацию клубной картой',
    fr: 'Payer une consultation VIP avec une carte de club',
    es: 'Pague una consulta VIP con una tarjeta de club',
    cs: 'Zaplatit VIP konzultaci klubovou kartou',
  },
  consultation_button_text_3: {
    ua: 'Оплатити зустріч з адвокатом клубною картою',
    en: 'Pay for a meeting with a lawyer using a club card',
    de: 'Treffen mit einem Anwalt mit einer Clubkarte bezahlen',
    ru: 'Оплатить встречу с адвокатом клубной картой',
    fr: 'Payer une rencontre avec un avocat avec une carte de club',
    es: 'Pague una reunión con un abogado con una tarjeta de club',
    cs: 'Zaplatit schůzku s právníkem klubovou kartou',
  },

  buy_club_card_btn: {
    ua: 'Купити клубну карту',
    en: 'Buy a club card',
    de: 'Clubkarte kaufen',
    ru: 'Купить клубную карту',
    fr: 'Acheter une carte de club',
    es: 'Comprar una tarjeta de club',
    cs: 'Koupit klubovou kartu',
  },
  buy_club_card_slogan: {
    ua: 'Придбай клубну карту, та заощаджуй свої гроші',
    en: 'Buy a club card and save your money',
    de: 'Kaufe eine Clubkarte und spare dein Geld',
    ru: 'Купите клубную карту и экономьте деньги',
    fr: "Achetez une carte de club et économisez de l'argent",
    es: 'Compre una tarjeta de club y ahorre dinero',
    cs: 'Kupte si klubovou kartu a ušetřete peníze',
  },

  // Need to fix paragraphs with currency

  rulesModalTitle: {
    ua: 'Правила використання бонусного рахунку',
    en: 'Rules for using the bonus account',
    de: 'Regeln für die Verwendung des Bonuskontos',
    ru: 'Правила использования бонусного счета',
    fr: "Règles pour l'utilisation du compte bonus",
    es: 'Reglas para usar la cuenta de bonificación',
    cs: 'Pravidla používání bonusového účtu',
  },
  rulesModal1: {
    ua: '1 бонус = 1 гривня',
    en: '1 bonus = 1 hryvnia',
    de: '1 Bonus = 1 Griwna',
    ru: '1 бонус = 1 гривня',
    fr: '1 bonus = 1 hryvnia',
    es: '1 bono = 1 hryvnia',
    cs: '1 bonus = 1 hřivna',
  },

  rulesModal2: {
    ua: 'На що можна витрачати бонуси?',
    en: 'What can bonuses be spent on?',
    de: 'Wofür können Boni ausgegeben werden?',
    ru: 'На что можно потратить бонусы?',
    fr: 'À quoi peuvent servir les bonus?',
    es: '¿En qué se pueden gastar los bonos?',
    cs: 'Na co lze utratit bonusy?',
  },
  rulesModal3: {
    ua: 'Ви можете використовувати бонусний рахунок для оплати послуг Адвокатського об’єднання «Захист»',
    en: 'You can use the bonus account to pay for services of the Advocacy Association "Zahist"',
    de: 'Sie können das Bonuskonto verwenden, um die Dienste des Anwaltsverbandes "Zahist" zu bezahlen',
    ru: 'Вы можете использовать бонусный счет для оплаты услуг адвокатского объединения «Защит»',
    fr: 'Vous pouvez utiliser le compte bonus pour payer les services de l\'Association de défense "Zahist"',
    es: 'Puede usar la cuenta de bonificación para pagar los servicios de la Asociación de Defensa "Zahist"',
    cs: 'Bonusový účet můžete použít k úhradě služeb advokátního sdružení „Zahist“',
  },
  rulesModal4: {
    ua: 'Порядок розрахунку бонусами',
    en: 'Calculation order for bonuses',
    de: 'Berechnungsreihenfolge für Boni',
    ru: 'Порядок расчета бонусами',
    fr: 'Ordre de calcul des bonus',
    es: 'Orden de cálculo para bonos',
    cs: 'Pořadí výpočtu bonusů',
  },
  rulesModal5: {
    ua: 'Бонуси покривають 30% Вашої оплати наших послуг.',
    en: 'Bonuses cover 30% of your payment for our services.',
    de: 'Boni decken 30% Ihrer Zahlung für unsere Dienstleistungen ab.',
    fr: 'Les bonus couvrent 30% de votre paiement pour nos services.',
    es: 'Los bonos cubren el 30% de su pago por nuestros servicios.',
    cs: 'Bonusy pokrývají 30 % vaší platby za naše služby.',
  },
  rulesModal6: {
    ua: 'Наприклад: якщо на Вашому бонусному рахунку 3000 грн., при цьому Ви бажаєте здійснити оплату послуг АО «Захист» на суму 1000 грн., то збонусного рахунку Ви можете списати 300 грн. (30% від 1000 грн.), решту 700 грн. Ви оплачуєте готівкою або безготівковим переказом. У такомувипадку на бонусному рахунку у Вас залишиться 2700 грн.',
    en: 'For example: if you have 3000 UAH on your bonus account, and you want to pay for services of the "Zahist" Advocacy Association in the amount of 1000 UAH, then from the bonus account you can deduct 300 UAH (30% of 1000 UAH), the rest of 700 UAH you pay in cash or by bank transfer. In this case, you will have 2700 UAH left on the bonus account.',
    de: 'Beispiel: Wenn Sie 3000 UAH auf Ihrem Bonuskonto haben und Sie die Dienste des Anwaltsverbands "Zahist" in Höhe von 1000 UAH bezahlen möchten, können Sie vom Bonuskonto 300 UAH abziehen (30% von 1000 UAH), die restlichen 700 UAH zahlen Sie bar oder per Banküberweisung. In diesem Fall haben Sie noch 2700 UAH auf dem Bonuskonto.',
    ru: 'Например: если на вашем бонусном счете 3000 грн, и вы хотите оплатить услуги адвокатского объединения «Защита» на сумму 1000 грн, то с бонусного счета вы можете списать 300 грн (30% от 1000 грн), оставшиеся 700 грн вы оплачиваете наличными или безналичным переводом. В этом случае на бонусном счете у вас останется 2700 грн.',
    fr: 'Par exemple : si vous avez 3000 UAH sur votre compte bonus et que vous souhaitez payer les services de l\'association de défense "Zahist" pour un montant de 1000 UAH, alors vous pouvez déduire 300 UAH (30 % de 1000 UAH) du compte bonus, et le reste de 700 UAH est payé en espèces ou par virement bancaire. Dans ce cas, il vous restera 2700 UAH sur le compte bonus.',
    es: 'Por ejemplo: si tienes 3000 UAH en tu cuenta de bonificación y deseas pagar los servicios de la Asociación de Defensa "Zahist" por un monto de 1000 UAH, puedes deducir 300 UAH (30 % de 1000 UAH) de la cuenta de bonificación, y el resto de 700 UAH se paga en efectivo o mediante transferencia bancaria. En este caso, te quedarán 2700 UAH en la cuenta de bonificación.',
    cs: 'Například: pokud máte na svém bonusovém účtu 3000 Kč a chcete zaplatit za služby advokátního sdružení "Zahist" částku 1000 Kč, pak z bonusového účtu můžete odepsat 300 Kč (30 % z 1000 Kč), zbytek 700 Kč zaplatíte v hotovosti nebo bankovním převodem. V tomto případě vám na bonusovém účtu zůstane 2700 Kč.',
  },

  rulesModal7: {
    ua: 'Куди тиснути щоб оплатити послуги бонусами?',
    en: 'Where to click to pay for services with bonuses?',
    de: 'Wo klicken, um Dienstleistungen mit Boni zu bezahlen?',
    ru: 'Куда нажать, чтобы оплатить услуги бонусами?',
    fr: 'Où cliquer pour payer les services avec des bonus?',
    es: '¿Dónde hacer clic para pagar los servicios con bonos?',
    cs: 'Kam kliknout pro zaplacení služeb pomocí bonusů?',
  },

  rulesModal8: {
    ua: '1. Натисніть на кнопку «Оплатити бонусами» на головному екрані;',
    en: '1. Click on the «Pay with bonuses» button on the main screen;',
    de: '1. Klicken Sie auf die Schaltfläche «Mit Boni bezahlen» auf dem Hauptbildschirm;',
    ru: '1. Нажмите кнопку «Оплатить бонусами» на главном экране;',
    fr: "1. Cliquez sur le bouton «Payer avec des bonus» sur l'écran principal;",
    es: '1. Haga clic en el botón «Pagar con bonos» en la pantalla principal;',
    cs: '1. Klikněte na tlačítko «Platba pomocí bonusů» na hlavní obrazovce;',
  },

  rulesModal9: {
    ua: '2. Введіть в центральне поле суму, яку Ви бажаєте сплатити (по аналогії з вище приведеним прикладом - 1000 грн.);',
    en: '2. Enter the amount you want to pay in the central field (similar to the example above - 1000 UAH);',
    de: '2. Geben Sie den Betrag ein, den Sie bezahlen möchten, in das zentrale Feld ein (ähnlich dem obigen Beispiel - 1000 UAH);',
    ru: '2. Введите сумму, которую вы хотите оплатить, в центральное поле (по аналогии с вышеуказанным примером - 1000 грн.);',
    fr: "2. Entrez le montant que vous souhaitez payer dans le champ central (similaire à l'exemple ci-dessus - 1000 UAH);",
    es: '2. Ingrese la cantidad que desea pagar en el campo central (similar al ejemplo anterior - 1000 UAH);',
    cs: '2. Zadejte částku, kterou chcete zaplatit, do centrálního pole (podobně jako v uvedeném příkladu - 1000 Kč);',
  },

  rulesModal10: {
    ua: '3. Нижче, система вирахує максимальну суму списання бонусів з урахуванням Вашого балансу (по аналогії з вище приведеним прикладом 300 грн.);',
    en: '3. Below, the system will calculate the maximum amount of bonus deduction taking into account your balance (similar to the example above - 300 UAH);',
    de: '3. Darunter wird das System den maximalen Betrag der Bonusabbuchung unter Berücksichtigung Ihres Kontostands berechnen (ähnlich dem obigen Beispiel - 300 UAH);',
    ru: '3. Ниже система рассчитает максимальную сумму списания бонусов с учетом вашего баланса (по аналогии с вышеуказанным примером - 300 грн.);',
    fr: "3. En dessous, le système calculera le montant maximal de déduction des bonus en fonction de votre solde (similaire à l'exemple ci-dessus - 300 UAH);",
    es: '3. A continuación, el sistema calculará la cantidad máxima de deducción de bonos teniendo en cuenta su saldo (similar al ejemplo anterior - 300 UAH);',
    cs: '3. Níže systém vypočítá maximální částku odečtu bonusů s ohledem na váš zůstatek (podobně jako v uvedeném příkladu - 300 Kč);',
  },

  rulesModal11: {
    ua: '4. Натисніть кнопку «Сплатити бонусами». Після цього з Вашого бонусного рахунку спишеться сума бонусів, що відобразиться в історії транзакцій;',
    en: '4. Click the «Pay with bonuses» button. After that, the amount of bonuses will be debited from your bonus account, which will be reflected in the transaction history;',
    de: '4. Klicken Sie auf die Schaltfläche «Mit Boni bezahlen». Danach wird der Betrag der Boni von Ihrem Bonuskonto abgebucht, was im Transaktionsverlauf angezeigt wird;',
    ru: '4. Нажмите кнопку «Оплатить бонусами». После этого с вашего бонусного счета спишется сумма бонусов, что отразится в истории транзакций;',
    fr: "4. Cliquez sur le bouton «Payer avec des bonus». Ensuite, le montant des bonus sera débité de votre compte bonus, ce qui sera reflété dans l'historique des transactions;",
    es: '4. Haga clic en el botón «Pagar con bonos». Después de eso, la cantidad de bonos se debitará de su cuenta de bonificación, lo que se reflejará en el historial de transacciones;',
    cs: '4. Klikněte na tlačítko «Platba pomocí bonusů». Poté bude z vašeho bonusového účtu odečtena částka bonusů, což se zobrazí v historii transakcí;',
  },

  rulesModal12: {
    ua: '5. Сплатіть решту суми готівкою або безготівковим переказом (по аналогії з вище приведеним прикладом 700 грн.) та не забудьте сповістити про це свого куратора по справі.',
    en: "5. Pay the remaining amount in cash or by bank transfer (similar to the example above - 700 UAH) and don't forget to notify your case curator.",
    de: '5. Zahlen Sie den Restbetrag bar oder per Banküberweisung (ähnlich dem obigen Beispiel - 700 UAH) und vergessen Sie nicht, Ihren Fallbetreuer zu benachrichtigen.',
    ru: '5. Оплатите оставшуюся сумму наличными или банковским переводом (по аналогии с вышеуказанным примером - 700 грн) и не забудьте уведомить вашего куратора по делу.',
    fr: "5. Payez le montant restant en espèces ou par virement bancaire (similaire à l'exemple ci-dessus - 700 UAH) et n'oubliez pas de prévenir votre tuteur de dossier.",
    es: '5. Pague el monto restante en efectivo o mediante transferencia bancaria (similar al ejemplo anterior - 700 UAH) y no olvide notificar a su curador de caso.',
    cs: '5. Zaplaťte zbývající částku v hotovosti nebo bankovním převodem (podobně jako v uvedeném příkladu - 700 Kč) a nezapomeňte informovat svého správce případu.',
  },

  // ClubCardGiftModal
  clubCardGiftValidity: {
    ua: 'Термін дії',
    en: 'Validity',
    de: 'Gültigkeit',
    ru: 'Срок действия',
    fr: 'Validité',
    es: 'Validez',
    cs: 'Platnost',
  },

  clubCardGiftAccept: {
    ua: 'Прийняти',
    en: 'Accept',
    de: 'Akzeptieren',
    ru: 'Принять',
    fr: 'Accepter',
    es: 'Aceptar',
    cs: 'Přijmout',
  },
  ClubCardGift: {
    ua: 'Даруємо Вам клубну карту',
    en: 'We give you a club card',
    de: 'Wir geben Ihnen eine Clubkarte',
    ru: 'Мы дарим вам клубную карту',
    fr: 'Nous vous offrons une carte de club',
    es: 'Le regalamos una tarjeta de club',
    cs: 'Darujeme vám klubovou kartu',
  },

  // FirstLoginBonusModal

  firstLoginBonusTitle: {
    ua: 'Вітаємо з першим входом',
    en: 'Congratulations on your first login',
    de: 'Herzlichen Glückwunsch zum ersten Login',
    ru: 'Поздравляем с первым входом',
    fr: 'Félicitations pour votre première connexion',
    es: 'Felicidades por su primer inicio de sesión',
    cs: 'Gratulujeme k prvnímu přihlášení',
  },
  firstLoginBonusText1: {
    ua: 'Вам нараховано 300 бонусів',
    en: 'You have been credited with 300 bonuses',
    de: 'Ihnen wurden 300 Boni gutgeschrieben',
    ru: 'Вам начислено 300 бонусов',
    fr: 'Vous avez été crédité de 300 bonus',
    es: 'Se le han acreditado 300 bonos',
    cs: 'Bylo vám připsáno 300 bonusů',
  },
  firstLoginAccept: {
    ua: 'Прийняти',
    en: 'Accept',
    de: 'Akzeptieren',
    ru: 'Принять',
    fr: 'Accepter',
    es: 'Aceptar',
    cs: 'Přijmout',
  },

  // FirstHotlineBonusModal

  firstHotlineBonusTitle: {
    ua: 'Вам повернено гроші за консультацію',
    en: 'You have been refunded for the consultation',
    de: 'Sie haben eine Rückerstattung für die Beratung erhalten',
    ru: 'Вам возвращены деньги за консультацию',
    fr: 'Vous avez été remboursé pour la consultation',
    es: 'Se le ha reembolsado por la consulta',
    cs: 'Byly vám vráceny peníze za konzultaci',
  },
  firstHotlineBonusText1: {
    ua: 'На баланс буде нараховано:',
    en: 'The balance will be credited with:',
    de: 'Der Kontostand wird gutgeschrieben mit:',
    ru: 'На баланс будет зачислено:',
    fr: 'Le solde sera crédité de :',
    es: 'El saldo será acreditado con:',
    cs: 'Na zůstatek bude připsáno:',
  },
  firstHotlineAccept: {
    ua: 'Прийняти',
    en: 'Accept',
    de: 'Akzeptieren',
    ru: 'Принять',
    fr: 'Accepter',
    es: 'Aceptar',
    cs: 'Přijmout',
  },
  // RulesBalance
  rulesBalanceTitle: {
    ua: 'Правила використання балансу',
    en: 'Balance usage rules',
    de: 'Regeln zur Verwendung des Guthabens',
    ru: 'Правила использования баланса',
    fr: "Règles d'utilisation du solde",
    es: 'Reglas de uso del saldo',
    cs: 'Pravidla používání zůstatku',
  },
  rulesBalanceText1: {
    ua: 'Правила безпеки:',
    en: 'Security rules:',
    de: 'Sicherheitsregeln:',
    ru: 'Правила безопасности:',
    fr: 'Règles de sécurité :',
    es: 'Reglas de seguridad:',
    cs: 'Bezpečnostní pravidla:',
  },
  rulesBalanceText2: {
    ua: 'Автентифікація:',
    en: 'Authentication:',
    de: 'Authentifizierung:',
    ru: 'Аутентификация:',
    fr: 'Authentification :',
    es: 'Autenticación:',
    cs: 'Autentizace:',
  },
  rulesBalanceText3: {
    ua: 'Вимагайте автентифікації користувачів перед здійсненням фінансових транзакцій. Використовуйте паролі, біометрію, аутентифікацію двофакторним методом тощо.',
    en: 'Require user authentication before performing financial transactions. Use passwords, biometrics, two-factor authentication, etc.',
    de: 'Verlangen Sie die Authentifizierung von Benutzern vor der Durchführung von Finanztransaktionen. Verwenden Sie Passwörter, Biometrie, Zwei-Faktor-Authentifizierung usw.',
    ru: 'Требуйте аутентификацию пользователей перед выполнением финансовых транзакций. Используйте пароли, биометрию, двухфакторную аутентификацию и т.д.',
    fr: "Exigez l'authentification des utilisateurs avant d'effectuer des transactions financières. Utilisez des mots de passe, la biométrie, l'authentification à deux facteurs, etc.",
    es: 'Requiere autenticación de usuario antes de realizar transacciones financieras. Usa contraseñas, biometría, autenticación de dos factores, etc.',
    cs: 'Vyžadujte autentizaci uživatelů před prováděním finančních transakcí. Používejte hesla, biometriku, dvoufaktorovou autentizaci atd.',
  },
  rulesBalanceText4: {
    ua: 'Шифрування:',
    en: 'Encryption:',
    de: 'Verschlüsselung:',
    ru: 'Шифрование:',
    fr: 'Cryptage :',
    es: 'Cifrado:',
    cs: 'Šifrování:',
  },
  rulesBalanceText5: {
    ua: 'Захищайте дані користувачів, використовуючи шифрування для передачі і зберігання даних.',
    en: 'Protect user data by using encryption for data transmission and storage.',
    de: 'Schützen Sie Benutzerdaten durch Verschlüsselung für die Datenübertragung und -speicherung.',
    ru: 'Защищайте данные пользователей, используя шифрование для передачи и хранения данных.',
    fr: 'Protégez les données des utilisateurs en utilisant le cryptage pour la transmission et le stockage des données.',
    es: 'Proteja los datos de los usuarios utilizando cifrado para la transmisión y almacenamiento de datos.',
    cs: 'Chraňte uživatelská data pomocí šifrování pro přenos a ukládání dat.',
  },
  rulesBalanceText6: {
    ua: 'Моніторинг:',
    en: 'Monitoring:',
    de: 'Überwachung:',
    ru: 'Мониторинг:',
    fr: 'Surveillance :',
    es: 'Monitoreo:',
    cs: 'Monitorování:',
  },

  rulesBalanceText7: {
    ua: 'Встановіть систему моніторингу для виявлення незвичайних або підозрілих транзакцій.',
    en: 'Set up a monitoring system to detect unusual or suspicious transactions.',
    de: 'Richten Sie ein Überwachungssystem ein, um ungewöhnliche oder verdächtige Transaktionen zu erkennen.',
    ru: 'Настройте систему мониторинга для обнаружения необычных или подозрительных транзакций.',
    fr: 'Mettez en place un système de surveillance pour détecter les transactions inhabituelles ou suspectes.',
    es: 'Configure un sistema de monitoreo para detectar transacciones inusuales o sospechosas.',
    cs: 'Nastavte monitorovací systém pro detekci neobvyklých nebo podezřelých transakcí.',
  },
  rulesBalanceText8: {
    ua: 'Захист від шахраїв:',
    en: 'Protection against fraud:',
    de: 'Schutz vor Betrug:',
    ru: 'Защита от мошенничества:',
    fr: 'Protection contre la fraude :',
    es: 'Protección contra fraudes:',
    cs: 'Ochrana proti podvodům:',
  },
  rulesBalanceText9: {
    ua: 'Розробіть стратегії для захисту від шахраїв, такі як визначення підозрілих активностей та блокування їх.',
    en: 'Develop strategies to protect against fraud, such as identifying suspicious activities and blocking them.',
    de: 'Entwickeln Sie Strategien zum Schutz vor Betrug, wie z.B. Identifizierung verdächtiger Aktivitäten und deren Blockierung.',
    ru: 'Разработайте стратегии защиты от мошенничества, такие как выявление подозрительных активностей и их блокировка.',
    fr: "Développez des stratégies pour vous protéger contre la fraude, telles que l'identification des activités suspectes et leur blocage.",
    es: 'Desarrolle estrategias para protegerse contra el fraude, como identificar actividades sospechosas y bloquearlas.',
    cs: 'Vypracujte strategie na ochranu proti podvodům, jako je identifikace podezřelých aktivit a jejich blokování.',
  },
  rulesBalanceText10: {
    ua: 'Обмеження сум і частоти:',
    en: 'Limits on amounts and frequency:',
    de: 'Grenzen für Beträge und Häufigkeit:',
    ru: 'Ограничения по суммам и частоте:',
    fr: 'Limites sur les montants et la fréquence :',
    es: 'Límites en cantidades y frecuencia:',
    cs: 'Limity na částky a frekvenci:',
  },
  rulesBalanceText11: {
    ua: 'Встановіть ліміти на максимальну суму грошей, яку користувач може витратити за однією транзакцією або протягом певного періоду.',
    en: 'Set limits on the maximum amount of money a user can spend in a single transaction or within a certain period.',
    de: 'Legen Sie Grenzen für den maximalen Betrag fest, den ein Benutzer in einer einzelnen Transaktion oder innerhalb eines bestimmten Zeitraums ausgeben kann.',
    ru: 'Установите лимиты на максимальную сумму денег, которую пользователь может потратить за одну транзакцию или в течение определенного периода.',
    fr: "Fixez des limites sur le montant maximum d'argent qu'un utilisateur peut dépenser en une seule transaction ou sur une période donnée.",
    es: 'Establezca límites en la cantidad máxima de dinero que un usuario puede gastar en una sola transacción o dentro de un período determinado.',
    cs: 'Stanovte limity na maximální částku, kterou může uživatel utratit při jedné transakci nebo v určitém období.',
  },
  rulesBalanceText12: {
    ua: 'Встановіть обмеження на частоту транзакцій, щоб уникнути великої кількості малих транзакцій.',
    en: 'Set limits on the frequency of transactions to avoid a large number of small transactions.',
    de: 'Legen Sie Grenzen für die Häufigkeit von Transaktionen fest, um eine große Anzahl von kleinen Transaktionen zu vermeiden.',
    ru: 'Установите ограничения на частоту транзакций, чтобы избежать большого количества мелких транзакций.',
    fr: 'Fixez des limites sur la fréquence des transactions pour éviter un grand nombre de petites transactions.',
    es: 'Establezca límites en la frecuencia de las transacciones para evitar una gran cantidad de transacciones pequeñas.',
    cs: 'Nastavte limity na frekvenci transakcí, aby se zabránilo velkému počtu malých transakcí.',
  },

  // RulesBonuses
  rulesBonusesTitle: {
    ua: 'Правила використання бонусів',
    en: 'Bonus Usage Rules',
    de: 'Regeln zur Verwendung von Bonuspunkten',
    ru: 'Правила использования бонусов',
    fr: "Règles d'utilisation des bonus",
    es: 'Reglas de uso de bonos',
    cs: 'Pravidla používání bonusů',
  },
  rulesBonusesText1: {
    ua: '1 бонус = 1 гривня',
    en: '1 bonus = 1 hryvnia',
    de: '1 Bonuspunkt = 1 Hrywnja',
    ru: '1 бонус = 1 гривня',
    fr: '1 bonus = 1 hryvnia',
    es: '1 bono = 1 hryvnia',
    cs: '1 bonus = 1 hřivna',
  },
  rulesBonusesText2: {
    ua: 'На що можна витрачати бонуси?',
    en: 'What can bonuses be spent on?',
    de: 'Wofür können Bonuspunkte ausgegeben werden?',
    ru: 'На что можно потратить бонусы?',
    fr: 'À quoi peuvent servir les bonus?',
    es: '¿En qué se pueden gastar los bonos?',
    cs: 'Na co lze utratit bonusy?',
  },
  rulesBonusesText3: {
    ua: 'Ви можете використовувати бонусний рахунок для оплати послуг Адвокатського об’єднання «Захист»',
    en: 'You can use your bonus account to pay for the services of the «Zakhyst» Advocacy Association',
    de: 'Sie können Ihr Bonuskonto verwenden, um die Dienste des Anwaltsverbandes «Zakhyst» zu bezahlen',
    ru: 'Вы можете использовать бонусный счет для оплаты услуг адвокатского объединения «Защита»',
    fr: "Vous pouvez utiliser le compte bonus pour payer les services de l'Association de défense «Zakhyst»",
    es: 'Puede usar la cuenta de bonificación para pagar los servicios de la Asociación de Defensa «Zakhyst»',
    cs: 'Bonusový účet můžete použít k úhradě služeb advokátního sdružení „Zakhyst“',
  },
  rulesBonusesText4: {
    ua: 'Порядок розрахунку бонусами',
    en: 'Procedure for calculating bonuses',
    de: 'Verfahren zur Berechnung von Bonuspunkten',
    ru: 'Порядок расчета бонусами',
    fr: 'Ordre de calcul des bonus',
    es: 'Orden de cálculo para bonos',
    cs: 'Pořadí výpočtu bonusů',
  },
  rulesBonusesText5: {
    ua: 'Бонуси покривають 30% Вашої оплати наших послуг.',
    en: 'Bonuses cover 30% of your payment for our services.',
    de: 'Bonuspunkte decken 30% Ihrer Zahlung für unsere Dienstleistungen ab.',
    ru: 'Бонусы покрывают 30% вашей оплаты за наши услуги.',
    fr: 'Les bonus couvrent 30% de votre paiement pour nos services.',
    es: 'Los bonos cubren el 30% de su pago por nuestros servicios.',
    cs: 'Bonusy pokrývají 30 % vaší platby za naše služby.',
  },
  rulesBonusesText6: {
    ua: 'Наприклад: якщо на Вашому бонусному рахунку 3000 грн., при цьому Ви бажаєте здійснити оплату послуг АО «Захист» на суму 1000 грн., то з бонусного рахунку Ви можете списати 300 грн. (30% від 1000 грн.), решту 700 грн. Ви оплачуєте готівкою або безготівковим переказом. У такому випадку на бонусному рахунку у Вас залишиться 2700 грн.',
    en: 'For example: if you have 3000 UAH on your bonus account, and you want to pay for the services of the «Zakhyst» Advocacy Association in the amount of 1000 UAH, then from the bonus account you can deduct 300 UAH (30% of 1000 UAH), the rest 700 UAH you pay in cash or by bank transfer. In this case, you will have 2700 UAH left on the bonus account.',
    de: 'Beispiel: Wenn Sie 3000 UAH auf Ihrem Bonuskonto haben und die Dienste des Anwaltsverbandes «Zakhyst» in Höhe von 1000 UAH bezahlen möchten, können Sie vom Bonuskonto 300 UAH abziehen (30% von 1000 UAH), die restlichen 700 UAH zahlen Sie bar oder per Banküberweisung. In diesem Fall haben Sie noch 2700 UAH auf dem Bonuskonto.',
    ru: 'Например: если на вашем бонусном счете 3000 грн, и вы хотите оплатить услуги адвокатского объединения «Защита» на сумму 1000 грн, то с бонусного счета вы можете списать 300 грн (30% от 1000 грн), оставшиеся 700 грн вы оплачиваете наличными или безналичным переводом. В этом случае на бонусном счете у вас останется 2700 грн.',
    fr: 'Par exemple : si vous avez 3000 UAH sur votre compte bonus et que vous souhaitez payer les services de l\'association de défense "Zakhyst" pour un montant de 1000 UAH, alors vous pouvez déduire 300 UAH (30 % de 1000 UAH) du compte bonus, et le reste de 700 UAH est payé en espèces ou par virement bancaire. Dans ce cas, il vous restera 2700 UAH sur le compte bonus.',
    es: 'Por ejemplo: si tienes 3000 UAH en tu cuenta de bonificación y deseas pagar los servicios de la Asociación de Defensa "Zakhyst" por un monto de 1000 UAH, puedes deducir 300 UAH (30 % de 1000 UAH) de la cuenta de bonificación, y el resto de 700 UAH se paga en efectivo o mediante transferencia bancaria. En este caso, te quedarán 2700 UAH en la cuenta de bonificación.',
    cs: 'Například: pokud máte na svém bonusovém účtu 3000 Kč a chcete zaplatit za služby advokátního sdružení „Zakhyst“ částku 1000 Kč, pak z bonusového účtu můžete odepsat 300 Kč (30 % z 1000 Kč), zbytek 700 Kč zaplatíte v hotovosti nebo bankovním převodem. V tomto případě vám na bonusovém účtu zůstane 2700 Kč.',
  },
  rulesBonusesText7: {
    ua: 'Куди тиснути щоб оплатити послуги бонусами?',
    en: 'Where to click to pay for services with bonuses?',
    de: 'Wo klicken Sie, um Dienstleistungen mit Bonuspunkten zu bezahlen?',
    ru: 'Куда нажать, чтобы оплатить услуги бонусами?',
    fr: 'Où cliquer pour payer les services avec des bonus?',
    es: '¿Dónde hacer clic para pagar los servicios con bonos?',
    cs: 'Kam kliknout pro zaplacení služeb pomocí bonusů?',
  },
  rulesBonusesText8: {
    ua: ' 1. Натисніть на кнопку «Оплатити бонусами» на головному екрані;',
    en: '1. Click on the «Pay with Bonuses» button on the main screen;',
    de: '1. Klicken Sie auf die Schaltfläche «Mit Boni bezahlen» auf dem Hauptbildschirm;',
    ru: '1. Нажмите кнопку «Оплатить бонусами» на главном экране;',
    fr: "1. Cliquez sur le bouton «Payer avec des bonus» sur l'écran principal;",
    es: '1. Haga clic en el botón «Pagar con bonos» en la pantalla principal;',
    cs: '1. Klikněte na tlačítko «Platba pomocí bonusů» na hlavní obrazovce;',
  },
  rulesBonusesText9: {
    ua: '2. Введіть в центральне поле суму, яку Ви бажаєте сплатити (по аналогії з вище приведеним прикладом - 1000 грн.);',
    en: '2. Enter the amount you want to pay in the central field (similar to the example above - 1000 UAH);',
    de: '2. Geben Sie den Betrag ein, den Sie bezahlen möchten, in das zentrale Feld ein (ähnlich wie im obigen Beispiel - 1000 UAH);',
    ru: '2. Введите сумму, которую вы хотите оплатить, в центральное поле (по аналогии с вышеуказанным примером - 1000 грн.);',
    fr: "2. Entrez le montant que vous souhaitez payer dans le champ central (similaire à l'exemple ci-dessus - 1000 UAH);",
    es: '2. Ingrese la cantidad que desea pagar en el campo central (similar al ejemplo anterior - 1000 UAH);',
    cs: '2. Zadejte částku, kterou chcete zaplatit, do centrálního pole (podobně jako v uvedeném příkladu - 1000 Kč);',
  },
  rulesBonusesText10: {
    ua: '3. Нижче, система вирахує максимальну суму списання бонусів з урахуванням Вашого балансу (по аналогії з вище приведеним прикладом 300 грн.);',
    en: '3. Below, the system will calculate the maximum amount of bonus deduction taking into account your balance (similar to the example above - 300 UAH);',
    de: '3. Darunter berechnet das System den maximalen Betrag für die Bonusabhebung unter Berücksichtigung Ihres Kontostands (ähnlich wie im obigen Beispiel - 300 UAH);',
    ru: '3. Ниже система рассчитает максимальную сумму списания бонусов с учетом вашего баланса (по аналогии с вышеуказанным примером - 300 грн.);',
    fr: "3. En dessous, le système calculera le montant maximal de déduction des bonus en fonction de votre solde (similaire à l'exemple ci-dessus - 300 UAH);",
    es: '3. A continuación, el sistema calculará la cantidad máxima de deducción de bonos teniendo en cuenta su saldo (similar al ejemplo anterior - 300 UAH);',
    cs: '3. Níže systém vypočítá maximální částku odečtu bonusů s ohledem na váš zůstatek (podobně jako v uvedeném příkladu - 300 Kč);',
  },
  rulesBonusesText11: {
    ua: '4. Натисніть кнопку «Сплатити бонусами». Після цього з Вашого бонусного рахунку спишеться сума бонусів, що відобразиться в історії транзакцій;',
    en: '4. Click the «Pay with Bonuses» button. After that, the amount of bonuses will be debited from your bonus account, which will be reflected in the transaction history;',
    de: '4. Klicken Sie auf die Schaltfläche «Mit Boni bezahlen». Danach wird der Betrag der Bonusabhebung von Ihrem Bonuskonto abgebucht, was im Transaktionsverlauf angezeigt wird;',
    ru: '4. Нажмите кнопку «Оплатить бонусами». После этого с вашего бонусного счета спишется сумма бонусов, что отразится в истории транзакций;',
    fr: "4. Cliquez sur le bouton «Payer avec des bonus». Ensuite, le montant des bonus sera débité de votre compte bonus, ce qui sera reflété dans l'historique des transactions;",
    es: '4. Haga clic en el botón «Pagar con bonos». Después de eso, la cantidad de bonos se debitará de su cuenta de bonificación, lo que se reflejará en el historial de transacciones;',
    cs: '4. Klikněte na tlačítko «Platba pomocí bonusů». Poté bude z vašeho bonusového účtu odečtena částka bonusů, což se zobrazí v historii transakcí;',
  },
  rulesBonusesText12: {
    ua: '5. Сплатіть решту суми готівкою або безготівковим переказом (по аналогії з вище приведеним прикладом 700 грн.) та не забудьте сповістити про це свого куратора по справі.',
    en: '5. Pay the rest of the amount in cash or by bank transfer (similar to the example above - 700 UAH) and do not forget to notify your case manager about this.',
    de: '5. Zahlen Sie den Restbetrag bar oder per Banküberweisung (ähnlich wie im obigen Beispiel - 700 UAH) und vergessen Sie nicht, Ihren Sachbearbeiter darüber zu informieren.',
    ru: '5. Оплатите оставшуюся сумму наличными или банковским переводом (по аналогии с вышеуказанным примером - 700 грн) и не забудьте уведомить вашего куратора по делу.',
    fr: "5. Payez le montant restant en espèces ou par virement bancaire (similaire à l'exemple ci-dessus - 700 UAH) et n'oubliez pas de prévenir votre tuteur de dossier.",
    es: '5. Pague el monto restante en efectivo o mediante transferencia bancaria (similar al ejemplo anterior - 700 UAH) y no olvide notificar a su curador de caso.',
    cs: '5. Zaplaťte zbývající částku v hotovosti nebo bankovním převodem (podobně jako v uvedeném příkladu - 700 Kč) a nezapomeňte informovat svého správce případu.',
  },

  // calculation of the limit
  clientIDRulesCalculationLimit: {
    ua: 'Вам нараховується бонусний ліміт',
    en: 'You are entitled to a bonus limit',
    de: 'Es wird Ihnen ein Bonuslimit gutgeschrieben',
    ru: 'Вам начисляется бонусный лимит',
    fr: 'Vous avez droit à une limite de bonus',
    es: 'Tiene derecho a un límite de bonificación',
    cs: 'Máte nárok na bonusový limit',
  },
  // Terms Rules (almost everything from the previous rules)
  termsRulesPanelTitle1: {
    ua: 'Вартість юридичної страховки?',
    en: 'Cost of legal insurance?',
    de: 'Kosten der Rechtsversicherung?',
  },
  termsRulesPanelTitle2: {
    ua: 'Прайс',
    en: 'Price',
    de: 'Preis',
  },
  termsRulesPanelText1_1: {
    ua: 'При оформлені юридичної страховки, Адвокатське об’єднання «Захист» видає Вам',
    en: 'When applying for legal insurance, the Advocacy Association "Protection" issues you a',
    de: 'Bei Abschluss einer Rechtsschutzversicherung stellt Ihnen der Anwaltsverband "Schutz" eine',
  },
  termsRulesPanelText1_2: {
    ua: 'Клубну карту, на яку нараховує кошти',
    en: 'Club card, on which funds are credited',
    de: 'Clubkarte, auf die Gelder gutgeschrieben werden',
  },
  termsRulesPanelText1_3: {
    ua: 'для оплати юридичних послуг при ДТП, або складенні протоколу по факту іншого порушення ПДР.',
    en: 'for payment of legal services in case of an accident or drafting a protocol for another traffic violation.',
    de: 'zur Zahlung von Rechtsdienstleistungen im Falle eines Unfalls oder zur Erstellung eines Protokolls für eine andere Verkehrsverletzung.',
  },
  termsRulesPanelText2: {
    ua: 'Вказаними сумами Ви маєте право розраховуватися за послуги Адвокатського об’єднання «Захист» у випадку настання ДТП, або складення протоколу по факту іншого порушення ПДР, яке мало місце не раніше наступного календарного дня після укладання Договору юридичної страховки та до закінчення відповідного строку дії Договору.',
    en: 'With the specified amounts, you have the right to settle for the services of the Advocacy Association "Protection" in case of an accident or drafting a protocol for another traffic violation, which occurred no earlier than the next calendar day after concluding the legal insurance contract and before the end of the respective contract period.',
    de: 'Mit den angegebenen Beträgen haben Sie das Recht, sich für die Dienstleistungen des Anwaltsverbandes "Schutz" im Falle eines Unfalls oder der Erstellung eines Protokolls für eine andere Verkehrsverletzung zu entscheiden, die nicht früher als der nächste Kalendertag nach Abschluss des Rechtsschutzversicherungsvertrags und vor Ablauf der jeweiligen Vertragslaufzeit aufgetreten ist.',
  },
  termsRulesPanelText3: {
    ua: 'На підставі Договору, ми надаємо Вам юридичні послуги щодо захисту Ваших прав та законних інтересів, в процесі чого з Вашого рахунку Клубної карти відбувається грошове списання відповідно до Прайс-листа, зазначеного нижче.',
    en: 'Based on the Agreement, we provide you with legal services to protect your rights and legitimate interests, during which money is debited from your Club Card account according to the Price List specified below.',
    de: 'Auf der Grundlage des Vertrags bieten wir Ihnen rechtliche Dienstleistungen zum Schutz Ihrer Rechte und legitimen Interessen an, bei denen Geld von Ihrem Clubkartenkonto gemäß der unten angegebenen Preisliste abgebucht wird.',
  },

  // bonusesModal ============
  bonusesModalTitle: {
    ua: 'Використати бонуси',
    en: 'Use Bonuses',
    de: 'Bonusse verwenden',
  },
  bonusesModalText: {
    ua: 'Для використання бонусів потрібно стати Клієнтом АО «Захист»',
    en: 'To use bonuses, you need to become a client of the «Zahist» Advocacy Association',
    de: 'Um Boni zu verwenden, müssen Sie Kunde des «Zahist» Anwaltsverbandes werden',
  },
  // PriceList Modal ============

  // ---------- local utils
  to: {
    ua: 'до',
    en: 'to',
    de: 'zu',
  },
  costsForTransport: {
    ua: 'витрати на транспорт',
    en: 'transportation costs',
    de: 'Transportkosten',
  },
  // -------------
  priceListModalTitle: {
    ua: 'Прайс лист',
    en: 'Price List',
    de: 'Preisliste',
  },

  priceListModalText1: {
    ua: 'Консультація адвоката по ДТП',
    en: 'Consultation with a lawyer on road accidents',
    de: 'Beratung durch einen Anwalt bei Verkehrsunfällen',
  },
  priceListModalText2: {
    ua: 'Вивчення документів та формування правової позиції по справі',
    en: 'Study of documents and formation of a legal position on the case',
    de: 'Studium von Dokumenten und Bildung einer rechtlichen Position zum Fall',
  },
  priceListModalText3: {
    ua: 'Ознайомлення з матеріалами справи',
    en: 'Familiarization with case materials',
    de: 'Vertrautmachen mit Fallmaterialien',
  },
  priceListModalText4_1: {
    ua: 'Досудове врегулювання спору – від',
    en: 'Pre-trial settlement of the dispute - from',
    de: 'Außergerichtliche Beilegung des Streits - ab',
  },
  // to
  priceListModalText4_2: {
    ua: 'в залежності від складності справи;',
    en: 'depending on the complexity of the case;',
    de: 'abhängig von der Komplexität des Falles;',
  },
  priceListModalText5_1: {
    ua: 'Фіксований гонорар за ведення адміністративної справи по ДТП',
    en: 'Fixed fee for handling administrative proceedings on road accidents',
    de: 'Feste Gebühr für die Bearbeitung von Verwaltungsverfahren bei Verkehrsunfällen',
  },
  priceListModalText6_1: {
    ua: 'Фіксований гонорар за ведення кримінальної справи по ДТП',
    en: 'Fixed fee for handling criminal proceedings on road accidents',
    de: 'Feste Gebühr für die Bearbeitung von Strafverfahren bei Verkehrsunfällen',
  },
  priceListModalText56_2: {
    ua: 'на місяць на поштово-канцелярські витрати;',
    en: 'per month for postage and stationery expenses;',
    de: 'pro Monat für Porto- und Büromaterialkosten;',
  },
  priceListModalText7_1: {
    ua: 'Фіксований гонорар за ведення адміністративної справи щодо оскарження протоколу по факту іншого порушення ПДР',
    en: 'Fixed fee for handling administrative proceedings challenging the protocol of another traffic violation',
    de: 'Feste Gebühr für die Bearbeitung von Verwaltungsverfahren zur Anfechtung des Protokolls einer anderen Verkehrsverletzung',
  },
  priceListModalText7_2: {
    ua: 'на місяць на поштово-канцелярські витрати;',
    en: 'per month for postage and stationery expenses;',
    de: 'pro Monat für Porto- und Büromaterialkosten;',
  },
  priceListModalText8_1: {
    ua: 'Виїзд адвоката на місце пригоди',
    en: `Lawyer's visit to the scene of the accident`,
    de: 'Besuch des Anwalts am Unfallort',
  },
  // costsForTransport
  priceListModalText9_1: {
    ua: 'Підготовка юридичного документу – від',
    en: 'Preparation of legal document – from',
    de: 'Vorbereitung des Rechtsdokuments - ab',
  },
  priceListModalText9_2: {
    ua: 'в залежності від складності;',
    en: 'depending on the complexity;',
    de: 'abhängig von der Komplexität;',
  },
  priceListModalText10: {
    ua: 'Підготовка позовної заяви',
    en: 'Preparation of statement of claim',
    de: 'Vorbereitung der Klageschrift',
  },
  priceListModalText11: {
    ua: 'Підготовка апеляційної скарги',
    en: 'Preparation of appellate complaint',
    de: 'Vorbereitung der Berufungsbegründung',
  },
  priceListModalText12: {
    ua: 'Підготовка касаційної скарги',
    en: 'Preparation of cassation complaint',
    de: 'Vorbereitung der Revisionsschrift',
  },
  priceListModalText13: {
    ua: 'Вихід адвоката (до поліції, в суд, тощо)',
    en: `Lawyer's appearance (to the police, to court, etc.)`,
    de: 'Auftritt des Anwalts (bei der Polizei, vor Gericht usw.)',
  },
  // costsForTransport
  priceListModalText14: {
    ua: 'Проведення претензійної роботи з опонентом',
    en: 'Conducting claim work with the opponent',
    de: 'Durchführung von Anspruchsarbeit mit dem Gegner',
  },
  priceListModalText15_1: {
    ua: 'Участь адвоката в слідчих діях',
    en: `Lawyer's participation in investigative actions`,
    de: 'Teilnahme des Anwalts an Ermittlungshandlungen',
  },
  priceListModalText15_2: {
    ua: 'у годину;',
    en: 'per hour;',
    de: 'pro Stunde;',
  },
  priceListModalText16: {
    ua: 'Інші послуги надаються та тарифікуються за згодою сторін.',
    en: 'Other services are provided and charged upon agreement of the parties.',
    de: 'Andere Dienstleistungen werden auf Vereinbarung der Parteien erbracht und berechnet.',
  },
  // costsForTransport

  // buttons
  sendButton: {
    ua: 'Відправити',
    en: 'Send',
    de: 'Senden',
  },
  confirmButton: {
    ua: 'Підтвердити',
    en: 'Confirm',
    de: 'Bestätigen',
  },
  changePhoneButton: {
    ua: 'Змінити номер',
    en: 'Change number',
    de: 'Nummer ändern',
  },
  consultationButton: {
    ua: 'Записатись на консультацію',
    en: 'Book a consultation',
    de: 'Einen Beratungstermin vereinbaren',
  },
  consultationButton2: {
    ua: 'Записатись на консультацію в додатку',
    en: 'Book a consultation in the application',
    de: 'Beratungstermin vereinbaren in der Anwendung',
  },
  viewCert: {
    ua: 'Переглянути',
    en: 'Review',
    de: 'Sehen',
  },
  payBtn: {
    ua: 'Оплатити',
    en: 'Pay',
    de: 'Bezahlen',
  },
  payWithBonusesBtn: {
    ua: 'Оплатити бонусами',
    en: 'Pay with bonuses',
    de: 'Bezahlen Sie mit Boni',
  },
  cnlBtn: {
    ua: 'Скасувати',
    en: 'Cancel',
    de: 'Stornieren',
  },
  callBtn: {
    ua: 'Подзвонити',
    en: 'Call',
    de: 'Anrufen',
  },
  sendFileBtn: {
    ua: 'Відправити файл',
    en: 'Send file',
    de: 'Datei senden',
  },
  uploadBtn: {
    ua: 'Завантажити',
    en: 'Upload',
    de: 'Hochladen',
  },
  changePhoneBtn: {
    ua: 'Змінити телефон',
    en: 'Change phone',
    de: 'Telefonnummer ändern',
  },
  changeEmailBtn: {
    ua: 'Змінити email',
    en: 'Change email',
    de: 'E-Mail ändern',
  },
  uploadFileBtn: {
    ua: 'Завантажити файл',
    en: 'Upload file',
    de: 'Datei hochladen',
  },
  deleteBtn: {
    ua: 'Видалити',
    en: 'Delete',
    de: 'Löschen',
  },
  agreeBtn: {
    ua: 'Погодитись',
    en: 'Agree',
    de: 'Zustimmen',
  },
  inviteBtn: {
    ua: 'Запросити',
    en: 'Invite',
    de: 'Einladen',
  },
  // clientButtons
  clientBtn1: {
    ua: 'Правила',
    en: 'Rules',
    de: 'Regeln',
  },
  clientBtn2_1: {
    ua: 'Розрахунок бонусами',
    en: 'Bonus Calculation',
    de: 'Bonusberechnung',
  },
  clientBtn2_2: {
    ua: 'Прайс лист',
    en: 'Price List',
    de: 'Preisliste',
  },
  clientBtn3: {
    ua: 'Телефон',
    en: 'Phone',
    de: 'Telefon',
  },

  // navigation
  backToTheSite: {
    ua: 'Повернутися на сайт',
    en: 'Return to website',
    de: 'Zurück zur Website',
  },
  exitTheApplication: {
    ua: 'Вийти з додатку',
    en: 'Exit the application',
    de: 'Die Anwendung verlassen',
  },
  back: {
    ua: 'Назад',
    en: 'Back',
    de: 'Zurück',
  },

  // home page
  clubCard: {
    ua: 'Клубна карта',
    en: 'Club card',
    de: 'Clubkarte',
  },
  myConsultations: {
    ua: 'Мої консультації',
    en: 'My consultations',
    de: 'Meine Beratungen',
  },
  news: {
    ua: 'Новини',
    en: 'News',
    de: 'Nachrichten',
  },
  webinars: {
    ua: 'Вебінари',
    en: 'Webinars',
    de: 'Webinare',
  },
  freeConsultations: {
    ua: 'Безкоштовні консультації',
    en: 'Free Consultations',
    de: 'Kostenlose Beratung',
  },
  office: {
    ua: 'Офіси',
    en: 'Offices',
    de: 'Büros',
  },

  //wanted block
  WantedSearchedStatus: {
    ua: 'Ваше ім’я в розшуку!',
    en: 'Your name is wanted!',
    de: 'Ihr Name wird gesucht!',
  },
  WantedNotSearchedStatus: {
    ua: 'Ви не в розшуку!',
    en: 'You are not wanted!',
    de: 'Sie werden nicht gesucht!',
  },
  Region: {
    ua: 'Регіон',
    en: 'Region',
    de: 'Region',
  },
  LostDate: {
    ua: 'Дата зникнення',
    en: 'Date of disappearance',
    de: 'Datum des Verschwindens',
  },
  Category: {
    ua: 'Категорія',
    en: 'Category',
    de: 'Kategorie',
  },
  Article: {
    ua: 'Стаття',
    en: 'Article',
    de: 'Artikel',
  },
  Updated: {
    ua: 'Оновлено',
    en: 'Updated',
    de: 'Aktualisiert',
  },

  // notifications
  PrivacyPolicySuccess: {
    ua: 'Політика конфіденційності успішно підтверджена',
    en: 'Privacy policy successfully confirmed',
    de: 'Datenschutzrichtlinie erfolgreich bestätigt',
  },
  PrivacyPolicyFailure: {
    ua: 'Помилка підтвердження політики конфіденційності',
    en: 'Privacy Policy Confirmation Error',
    de: 'Fehler bei der Bestätigung der Datenschutzrichtlinie',
  },
  RegistrationSuccess: {
    ua: 'Дякуємо за реєстрацію',
    en: 'Thank you for registration',
    de: 'Danke für die Registrierung',
  },
  changedConsultation: {
    ua: 'Зміни у вашій консультації',
    en: 'Changes in your consultation',
    de: 'Änderungen in Ihrer Beratung',
  },
  newConsultation: {
    ua: 'Призначена консультація',
    en: 'Appointed consultation',
    de: 'Bestellte Beratung',
  },
  newContract: {
    ua: 'У Вас новий договір',
    en: 'You have a new contract',
    de: 'Sie haben einen neuen Vertrag',
  },

  //MY CONSULTATIONS
  noConsultation: {
    ua: 'Консультації не призначені',
    en: 'Consultations not appointed',
    de: 'Beratungen nicht geplant',
  },
  typeConsultation: {
    ua: 'Запис на',
    en: 'Booking for',
    de: 'Termin für',
  },
  okConsultation: {
    ua: 'Консультація відбулась',
    en: 'The consultation took place',
    de: 'Die Beratung fand statt',
  },
  missedConsultation: {
    ua: 'Консультація не відбулась',
    en: 'Consultation did not take place',
    de: 'Die Beratung hat nicht stattgefunden',
  },
  inProgressConsultation: {
    ua: 'В опрацюванні',
    en: 'In process',
    de: 'In Bearbeitung',
  },
  payConsultation: {
    ua: 'Оплатити консультацію',
    en: 'Pay for consultation',
    de: 'Beratung bezahlen',
  },
  payedConsultation: {
    ua: 'Консультація оплачена',
    en: 'Consultation paid',
    de: 'Beratungsgebühr bezahlt',
  },
  payedTag: {
    ua: 'Оплачено',
    en: 'Paid',
    de: 'Bezahlt',
  },
  quality: {
    ua: 'Оцінити якість консультації',
    en: 'Evaluate the quality of consultation',
    de: 'Die Qualität der Beratung bewerten',
  },
  qualityOk: {
    ua: 'Дякуємо за Ваш відгук!',
    en: 'Thank you for your feedback!',
    de: 'Vielen Dank für Ihr Feedback!',
  },
  tipsToTheLawyerTitle: {
    ua: 'Подяка юристу',
    en: 'Thank you to the lawyer',
    de: 'Danke an den Anwalt',
  },
  giveTipsToLawyer: {
    ua: 'Залишити подяку юристу',
    en: 'Thank the lawyer',
    de: 'Dem Anwalt danken',
  },

  //QuestionnaireHotlineCertificate
  cert0: {
    ua: 'У вас {chn} сертифікати на \n безкоштовну консультацію',
    en: 'You have {chn} certificates for \n a free consultation',
    de: 'Sie haben {chn} Gutscheine \n für eine kostenlose Beratung',
  },
  cert1: {
    ua: 'Вам було надано подарунковий сертифікат на',
    en: 'You have been provided with a gift certificate for ',
    de: 'Ihnen wurde ein Geschenkgutschein für ',
  },
  cert2: {
    ua: '3 безкоштовних юридичних консультацій.',
    en: '3 free legal consultations.',
    de: '3 kostenlose Rechtsberatungen zur Verfügung gestellt.',
  },
  cert3: {
    ua: 'Даним сертифікатом встановлюється право на отримання трьох безкоштовних юридичних консультацій в Адвокатському об`єднанні «Захист»',
    en: 'This certificate entitles the holder to receive three free legal consultations at the Advocates Association «Zahist»',
    de: 'Dieses Zertifikat berechtigt zur Inanspruchnahme von drei kostenlosen Rechtsberatungen bei der Anwaltsvereinigung «Zahist»',
  },
  cert4: {
    ua: 'з сімейних, кредитних, земельних правовідносин, та правовідносин, які виникають у зв`язку з дорожньо-транспортною пригодою.',
    en: 'on matters relating to family, credit, land rights, and issues arising from road traffic accidents.',
    de: 'zu Fragen des Familien-, Kredit-, Grundstücksrechts sowie zu Angelegenheiten, die im Zusammenhang mit Verkehrsunfällen stehen.',
  },
  cert5: {
    ua: 'Цей сертифікат можна самому використати, або подарувати будь-якій іншій особі. Рідні, друзі, знайомі тощо.',
    en: 'This certificate can be used by oneself or given as a gift to any other person. Family, friends, acquaintances, etc',
    de: 'Dieses Zertifikat kann von sich selbst verwendet oder als Geschenk an jede andere Person weitergegeben werden. Familie, Freunde, Bekannte, usw.',
  },
  cert6: {
    ua: 'Порядок та умови використання сертифіката описані в самому сертифікаті',
    en: 'The terms and conditions of use of the certificate are described within the certificate itself.',
    de: 'Die Bedingungen und Konditionen zur Verwendung des Zertifikats sind im Zertifikat selbst beschrieben.',
  },
  cert7: {
    ua: 'Переглянути сертифікат',
    en: 'View certificate',
    de: 'Zertifikat anzeigen',
  },

  //quickQuestion
  assistant: {
    ua: 'Помічник',
    en: 'Assistant',
    de: 'Assistent',
  },
  alert: {
    ua: 'Увага!',
    en: 'Attention!',
    de: 'Achtung!',
  },
  inputQuickQuestion: {
    ua: 'Напишіть повідомлення...',
    en: 'Write a message...',
    de: 'Schreiben Sie eine Nachricht...',
  },
  answerQuickQuestion: {
    ua: 'Оператор відповідає...',
    en: 'The operator is answering...',
    de: 'Der Operator antwortet...',
  },
  quickQuestionTitle1: {
    ua: 'Для використання чату завершіть реєстрацію.',
    en: 'To use the chat, complete the registration.',
    de: 'Um den Chat zu verwenden, vervollständigen Sie die Registrierung.',
  },
  quickQuestionsIntroTitle: {
    ua: 'Задайте своє питання!',
    en: 'Ask your question!',
    de: 'Stellen Sie Ihre Frage!',
  },
  quickQuestionsIntroText: {
    ua: 'А наш асистент на базі штучного інтелекту допоможе вам знайти найкращу відповідь.',
    en: 'And our assistant, based on ai intelligence, will help you find the best answer.',
    de: 'Und unser KI-gestützter Assistent hilft Ihnen, die beste Antwort zu finden.',
  },

  //chatDozhimsAI
  chatDozhimsAIFirstMessage: {
    ua: `Дякуємо Вам за співпрацю з АО "Захист". Ми щиро цінуємо, що Ви довірили нам ведення Вашої справи. Нам було приємно допомогти Вам досягти успішного результату.
    Ми пишаємося тим, що змогли допомогти Вам, і сподіваємося, що Ви залишилися задоволені нашою роботою. Зважаючи на це, хотіли б запропонувати Вам продовжити співпрацю з нами та порекомендувати нашу компанію Вашим знайомим.`,
    en: `Thank you for cooperating with "Zahist" LLC. We sincerely appreciate that you entrusted us with handling your case. It was a pleasure to assist you in achieving a successful outcome.
    We take pride in being able to help you and hope that you are satisfied with our work. Considering this, we would like to offer you continue working with us and recommend our company to your acquaintances.`,
    de: `Vielen Dank für die Zusammenarbeit mit der "Zahist" GmbH. Wir schätzen es aufrichtig, dass Sie uns mit der Bearbeitung Ihres Falls betraut haben. Es war uns eine Freude, Ihnen bei der Erreichung eines erfolgreichen Ergebnisses zu helfen. Wir sind stolz darauf, Ihnen geholfen zu haben, und hoffen, dass Sie mit unserer Arbeit zufrieden sind. In Anbetracht dessen möchten wir Ihnen Folgendes anbieten setzen Sie die Zusammenarbeit mit uns fort und empfehlen Sie unser Unternehmen Ihren Bekannten.`,
  },
  chatDozhimsAIFirstMessageConsultation: {
    ua: `Дякуємо за ваше звернення до Адвокатського об'єднання "Захист". Ми завжди готові надати Вам правову допомогу та консультації. Зверніть увагу, що для подальшої роботи з нами необхідно укласти договір. Будь ласка, зв'яжіться з нами для узгодження умов та деталей.
    З повагою,
    Адвокатське об'єднання "Захист"`,
    en: 'Thank you for contacting the Law Association "Zakhyst". We are always ready to provide you with legal assistance and consultations. Please note that to continue working with us, it is necessary to conclude an agreement. Please contact us to agree on the terms and details. Sincerely, Law Association "Zakhyst"',
    de: 'Vielen Dank für Ihre Anfrage beim Anwaltsverband "Zakhyst". Wir sind stets bereit, Ihnen rechtliche Unterstützung und Beratungen zu bieten. Bitte beachten Sie, dass für die weitere Zusammenarbeit mit uns ein Vertrag abgeschlossen werden muss. Bitte kontaktieren Sie uns, um die Bedingungen und Details zu vereinbaren. Mit freundlichen Grüßen, Anwaltsverband "Zakhyst"',
  },
  chatDozhimsAIApologiesMessage: {
    ua: `Вибачте, звертайтесь на гарячу лінію.`,
    en: 'Sorry, please contact the hotline.',
    de: 'Entschuldigung, bitte wenden Sie sich an die Hotline.',
  },
  //cronDozhimsAI
  chatDozhimsAIFirstArchiveMessage: {
    ua: `Хочу висловити щиру вдячність за те, що Ви обрали Адвокатське об’єднання «Захист» для вирішення Вашої справи.
    Раді, що ми змогли досягти бажаного результату. Будь ласка, не соромтеся звертатися до нас у майбутньому.
    З повагою!
    Старший інспектор відділу оцінки якості обслуговування Клієнтів Адвокатського об’єднання «Захист»
    Гацелюк Андрій Володимирович
    `,
    en: `I want to express sincere gratitude for choosing the Law Association "Zakhyst" to handle your case.
    We are glad that we were able to achieve the desired result.
    Please, do not hesitate to contact us in the future.
    With respect, Senior Inspector of the Customer Service Quality Assessment Department of the Law Association "Zakhyst" Hatseliuk Andrii Volodymyrovych`,
    de: `Ich möchte Ihnen aufrichtigen Dank aussprechen, dass Sie sich für den Anwaltsverband "Zakhyst" entschieden haben, um Ihre Angelegenheit zu klären.
    Wir freuen uns, dass wir das gewünschte Ergebnis erzielen konnten. Bitte zögern Sie nicht, uns auch in Zukunft zu kontaktieren.
    Mit freundlichen Grüßen, Seniorinspektor des Kundenservicequalitätsbewertungsabteilung des Anwaltsverbands "Zakhyst" Hatseliuk Andrii Volodymyrovych`,
  },

  chatDozhimsAISecondArchiveMessage: {
    ua: `Вас вітає старший інспектор відділу оцінки якості обслуговування Клієнтів Адвокатського об’єднання «Захист» Гацелюк Андрій Володимирович.
    Якщо у Вас знову виникли юридичні питання або потреба в юридичних послугах, не соромтеся звертатися до нас.
    Безкоштовна гаряча лінія: (0800) 40-33-03
    Ми завжди готові допомогти Вам!`,
    en: `You are greeted by the Senior Inspector of the Customer Service Quality Assessment Department of the Law Association "Zakhyst" Hatseliuk Andrii Volodymyrovych.
    If you have legal questions again or need legal services, do not hesitate to contact us. Toll-free hotline: (0800) 40-33-03 We are always ready to help you!`,
    de: `Sie werden vom leitenden Inspektor des Kundenbetreuungsqualitätsbewertungsabteilungs des Anwaltsverbandes "Zakhyst" Hatseliuk Andrii Volodymyrovych begrüßt.
    Wenn Sie erneut rechtliche Fragen haben oder rechtliche Dienstleistungen benötigen, zögern Sie nicht, uns zu kontaktieren.
    Kostenlose Hotline: (0800) 40-33-03 Wir sind immer bereit, Ihnen zu helfen!`,
  },

  chatDozhimsAIFirstBirthdayMessage: {
    ua: `від імені Адвокатського об’єднання «Захист», щиро вітаємо Вас з днем народження!
    Нехай цей день буде наповнений радістю, успіхами та великими досягненнями. Бажаємо Вам міцного здоров’я та безмежного щастя!
    З повагою!
    Адміністрація АО «Захист»`,
    en: `On behalf of the Law Association "Zakhyst", we sincerely congratulate you on your birthday! May this day be filled with joy, success, and great achievements.
    We wish you strong health and boundless happiness! With respect, Administration of the Law Association "Zakhyst"`,
    de: `Im Namen des Anwaltsverbandes "Zakhyst" gratulieren wir Ihnen herzlich zum Geburtstag! Möge dieser Tag voller Freude, Erfolg und großer Errungenschaften sein.
    Wir wünschen Ihnen beste Gesundheit und unermessliches Glück! Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
  },

  chatDozhimsAISecondBirthdayMessage: {
    ua: `прийміть наші вітання з днем народження!
    З нагоди Вашого святкового дня, хочемо висловити нашу щиру вдячність за довіру та співпрацю.
    Бажаємо Вам найяскравіших моментів у цей особливий день, а також незламної сили духу, яка буде вас супроводжувати на шляху досягнення усіх Ваших цілей.
    З повагою!
    Адміністрація АО «Захист»`,
    en: `Accept our birthday greetings! On the occasion of your special day, we want to express our sincere gratitude for your trust and cooperation.
    We wish you the brightest moments on this special day, as well as unwavering strength of spirit to accompany you on the path to achieving all your goals.
    With respect, Administration of the Law Association "Zakhyst"`,
    de: `Akzeptieren Sie unsere Geburtstagsgrüße! Zu diesem besonderen Anlass möchten wir Ihnen unseren aufrichtigen Dank für Ihr Vertrauen und Ihre Zusammenarbeit aussprechen.
    Wir wünschen Ihnen die schönsten Momente an diesem besonderen Tag sowie eine unerschütterliche geistige Stärke, die Sie auf dem Weg zur Erreichung all Ihrer Ziele begleiten wird.
    Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
  },

  chatDozhimsAIFirstNewYearMessage: {
    ua: `Вітаємо Вас з Новим роком 🎉🎊
    Бажаємо, щоб цей рік для Вас був успішним, стабільним та процвітаючим.
    Нехай новий рік принесе Вам лише позитивні емоції та нові можливості.
    Вирішення усіх тимчасових негараздів доручіть нам!
    З повагою!
    Адміністрація АО «Захист»`,
    en: `Congratulations on the New Year! 🎉🎊 We wish that this year will be successful, stable, and prosperous for you.
    May the new year bring you only positive emotions and new opportunities.
    Entrust the resolution of all temporary troubles to us! With respect, Administration of the Law Association "Zakhyst"`,
    de: `Herzlichen Glückwunsch zum neuen Jahr! 🎉🎊 Wir wünschen Ihnen, dass dieses Jahr erfolgreich, stabil und blühend für Sie sein wird.
    Möge das neue Jahr Ihnen nur positive Emotionen und neue Möglichkeiten bringen.
    Überlassen Sie uns die Lösung aller vorübergehenden Probleme! Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
  },

  chatDozhimsAISecondNewYearMessage: {
    ua: `З Новим роком!
    Бажаємо Вам, неймовірних можливостей, щастя, здоров’я та великих досягнень у новому році! Нехай цей рік принесе Вам багато яскравих моментів та добробуту Вашій сім’ї!
    З повагою!
    Адміністрація АО «Захист»`,
    en: `Happy New Year! We wish you incredible opportunities, happiness, health, and great achievements in the new year!
    May this year bring you many bright moments and prosperity to your family! With respect, Administration of the Law Association "Zakhyst"`,
    de: `Frohes neues Jahr! Wir wünschen Ihnen unglaubliche Möglichkeiten, Glück, Gesundheit und große Erfolge im neuen Jahr! Möge dieses Jahr Ihnen viele schöne Momente und Wohlstand für Ihre Familie bringen!
    Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
  },

  chatDozhimsAIFirstIndependenceDayMessage: {
    ua: `Слава Україні!
    Вітаємо Вас з Днем Незалежності України.
    Бажаємо мирного неба, щасливих сердець і нескінченного процвітання для Вас і для усіх українців!
    З повагою!
    Адміністрація АО «Захист»`,
    en: `Glory to Ukraine! Congratulations on Ukraine's Independence Day.
    We wish you a peaceful sky, happy hearts, and endless prosperity for you and all Ukrainians! With respect, Administration of the Law Association "Zakhyst"`,
    de: `Ruhm der Ukraine! Herzlichen Glückwunsch zum Unabhängigkeitstag der Ukraine.
    Wir wünschen Ihnen einen friedlichen Himmel, glückliche Herzen und endlosen Wohlstand für Sie und alle Ukrainer! Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
  },

  chatDozhimsAISecondIndependenceDayMessage: {
    ua: `Слава Україні!
    З найщирішими почуттями вітаємо Вас з Днем Незалежності України.
    Нехай Україна процвітає, а душі українців завжди наповнюються гордістю за свою незалежну та мужню країну!
    З повагою!
    Адміністрація АО «Захист»`,
    en: `Glory to Ukraine! With the sincerest feelings, we congratulate you on Ukraine's Independence Day.
    May Ukraine prosper, and may the hearts of Ukrainians always be filled with pride for their independent and courageous country!
    With respect, Administration of the Law Association "Zakhyst"`,
    de: `Ruhm der Ukraine! Mit den aufrichtigsten Gefühlen gratulieren wir Ihnen zum Unabhängigkeitstag der Ukraine.
    Möge die Ukraine gedeihen, und die Herzen der Ukrainer mögen immer mit Stolz auf ihr unabhängiges und mutiges Land erfüllt sein!
    Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
  },

  chatDozhimsAIFirstChristmasMessage: {
    ua: `З Різдвом Христовим!
    Нехай ця святкова пора наповнить Ваше серце теплом, радістю та благополуччям!
    З повагою!
    Адміністрація АО «Захист»`,
    en: `Merry Christmas! May this festive season fill your heart with warmth, joy, and well-being! With respect, Administration of the Law Association "Zakhyst"`,
    de: `Frohe Weihnachten! Möge diese festliche Zeit Ihr Herz mit Wärme, Freude und Wohlstand erfüllen! Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
  },

  chatDozhimsAISecondChristmasMessage: {
    ua: `З Різдвом Христовим!
    Нехай зірка Різдва освітить Ваш шлях світлом і надихне на нові досягнення у наступному році!
    Зичимо Вам радості, миру і щастя у ці святкові дні та завжди!
    З повагою!
    Адміністрація АО «Захист»`,
    en: `Merry Christmas! May the Christmas star light your way and inspire new achievements in the coming year!
    We wish you joy, peace, and happiness during these festive days and always! With respect, Administration of the Law Association "Zakhyst"`,
    de: `Frohe Weihnachten! Möge der Weihnachtsstern Ihren Weg erleuchten und zu neuen Erfolgen im kommenden Jahr inspirieren!
    Wir wünschen Ihnen Freude, Frieden und Glück in diesen Festtagen und immer! Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
  },

  chatDozhimsAIFirstDayConstitutionMessage: {
    ua: `З Днем Конституції України!
    Нехай наша конституція завжди буде гарантом прав і свобод кожного громадянина та джерелом національної єдності!
    З повагою!
    Адміністрація АО «Захист»`,
    en: `Happy Constitution Day of Ukraine! May our constitution always be a guarantor of the rights and freedoms of every citizen and a source of national unity!
    With respect, Administration of the Law Association "Zakhyst"`,
    de: `Herzlichen Glückwunsch zum Tag der Verfassung der Ukraine!
    Möge unsere Verfassung immer ein Garant für die Rechte und Freiheiten jedes Bürgers und eine Quelle nationaler Einheit sein!
    Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
  },

  chatDozhimsAISecondDayConstitutionMessage: {
    ua: `З Днем Конституції!
    Щиро вітаю Вас з цим важливим святом! Нехай принципи демократії, правової держави та гарантовані конституційні права завжди керують нашою країною на шляху до процвітання та благополуччя!
    З повагою!
    Адміністрація АО «Захист»`,
    en: `Happy Constitution Day! I sincerely congratulate you on this important holiday!
    May the principles of democracy, the rule of law, and guaranteed constitutional rights always guide our country on the path to prosperity and well-being!
    With respect, Administration of the Law Association "Zakhyst"`,
    de: `Herzlichen Glückwunsch zum Tag der Verfassung! Ich gratuliere Ihnen herzlich zu diesem wichtigen Feiertag!
    Mögen die Prinzipien der Demokratie, des Rechtsstaates und der garantierten verfassungsmäßigen Rechte immer unser Land auf dem Weg zu Wohlstand und Wohlergehen leiten!
    Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
  },

  //QuestionnaireNotification
  startQuestionnaire: {
    ua: 'Анкетування якості обслуговування',
    en: 'Customer Service Survey',
    de: 'Kundendienstumfrage',
  },
  btnStartQuestionnaire: {
    ua: 'Розпочати анкетування',
    en: 'Start the survey',
    de: 'Die Umfrage starten',
  },
  btnCancelQuestionnaire: {
    ua: 'Залишити на потім',
    en: 'Leave for later',
    de: 'Für später lassen',
  },
  QuestionnaireInfo: {
    ua: 'Будь ласка, потім заповніть анкету. Нам важлива ваша думка.',
    en: 'Please, fill out the questionnaire. Your opinion is important to us.',
    de: 'Bitte füllen Sie dann den Fragebogen aus. Ihre Meinung ist uns wichtig.',
  },
  questionnaire1: {
    ua: 'Оцініть, будь ласка, якість обслуговування після останнього завершення договору з нами та отримайте на ваш бонусний рахунок',
    en: 'Please evaluate the quality of service following your most recent contract completion with us and receive',
    de: 'Bewerten Sie bitte die Qualität des Service nach Abschluss Ihres letzten Vertrags mit uns und erhalten Sie ',
  },
  questionnaire2: {
    ua: 'грн.',
    en: 'UAH on your bonus account.',
    de: 'UAH auf Ihr Bonuskonto.',
  },

  //QuestionnaireArchive
  qualityArchiveBtn: {
    ua: 'Оцінити якість обслуговування',
    en: 'Evaluate the quality of service',
    de: 'Die Qualität des Service bewerten',
  },
  QuestionnaireArchive1: {
    ua: 'Анкета є конфіденційною та не підлягає розголошенню перед адвокатами та юристами!',
    en: 'The questionnaire is confidential and shall not be disclosed to lawyers and attorneys!',
    de: 'Der Fragebogen ist vertraulich und darf nicht an Anwälte und Rechtsanwälte weitergegeben werden!',
  },
  QuestionnaireArchive2: {
    ua: 'Оцініть якість обслуговування за 10-ти бальною шкалою',
    en: 'Please rate the quality of service on a 10-point scale.',
    de: 'Bitte bewerten Sie die Servicequalität auf einer 10-Punkte-Skala.',
  },
  QuestionnaireArchive3: {
    ua: 'Чи задоволені Ви результатом роботи?',
    en: 'Are you satisfied with the result of the work?',
    de: 'Sind Sie mit dem Ergebnis der Arbeit zufrieden?',
  },
  QuestionnaireArchive4: {
    ua: 'Чи задоволені Ви комунікацією з юристами?',
    en: 'Are you satisfied with communication with lawyers?',
    de: 'Sind Sie mit der Kommunikation mit den Anwälten zufrieden?',
  },
  QuestionnaireArchive5: {
    ua: 'Чи звернулися би Ви до нас ще раз?',
    en: 'Would you turn to us again?',
    de: 'Würden Sie sich wieder an uns wenden?',
  },
  QuestionnaireArchive6: {
    ua: 'Чи порадили би Ви нашу компанію своїм знайомим?',
    en: 'Would you recommend our company to your acquaintances?',
    de: 'Würden Sie unser Unternehmen Ihren Bekannten empfehlen?',
  },
  QuestionnaireArchive7: {
    ua: 'Чи довіряєте Ви нашій компанії?',
    en: 'Do you trust our company?',
    de: 'Vertrauen Sie unserem Unternehmen?',
  },
  QuestionnaireArchive8: {
    ua: 'Місце для скарг чи пропозицій:',
    en: 'Place for complaints or suggestions:',
    de: 'Ort für Beschwerden oder Vorschläge:',
  },
  QuestionnaireArchive9: {
    ua: 'Не менше 20 символів',
    en: 'At least 20 characters',
    de: 'Mindestens 20 Zeichen',
  },
  QuestionnaireArchive10: {
    ua: 'Як нам стати краще?',
    en: 'How can we become better?',
    de: 'Wie können wir besser werden?',
  },
  QuestionnaireArchive11: {
    ua: 'Чи сподобався подарунок?',
    en: 'Did you like the gift?',
    de: 'Hat Ihnen das Geschenk gefallen?',
  },

  //registration
  regStep1: {
    ua: 'Завершення реєстрації, крок',
    en: 'Registration completion, step',
    de: 'Registrierung abschließen, Schritt',
  },
  regStep1Descr: {
    ua: 'Будь ласка, введіть вашу електронну адресу для завершення процесу реєстрації.\n Ми відправимо Вам листа для підтвердження.\nДякуємо!',
    en: 'Please enter your email address to complete the registration process.\n We will send you a confirmation email.\nThank you!',
    de: 'Bitte geben Sie Ihre E-Mail-Adresse ein, um den Registrierungsprozess abzuschließen.\n Wir werden Ihnen eine Bestätigungs-E-Mail senden.\nDanke!',
  },
  regStep1Confirm1: {
    ua: 'Ми відправили Вам листа для підтвердження вашої електронної пошти. Будь ласка, перевірте свою скриньку вхідних повідомлень та натисніть на посилання для завершення процесу реєстрації. Якщо ви не отримали листа, перевірте папку «Спам».',
    en: "We have sent you an email to confirm your email address. Please check your inbox and click on the link to complete the registration process. If you haven't received the email, please check 'your «Spam» folder.",
    de: 'Wir haben Ihnen eine E-Mail zur Bestätigung Ihrer E-Mail-Adresse geschickt. Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf den Link, um den Registrierungsprozess abzuschließen. Wenn Sie die E-Mail nicht erhalten haben, überprüfen Sie bitte Ihren «Spam»-Ordner.',
  },
  regStep1Confirm2: {
    ua: 'Перейдіть за посиланням, для підтвердження пошти, яке відправлено на Ваш email ',
    en: 'Click on the link to confirm your email, which was sent to your email address ',
    de: 'Klicken Sie auf den Link, um Ihre E-Mail-Adresse zu bestätigen, die an Ihre E-Mail-Adresse ',
  },
  regStep1Confirm3: {
    ua: ', або введіть код активації вручну.',
    en: ', or enter the activation code manually.',
    de: ' gesendet wurde, oder geben Sie den Aktivierungscode manuell ein.',
  },
  regStep1Confirm4: {
    ua: 'Код верифікації',
    en: 'Verification code',
    de: 'Verifizierungscode',
  },
  regStep1Confirm5: {
    ua: 'Не бачите листа? Перевірте папку "Спам" або ',
    en: 'Don\'t see the email? Check your "Spam" folder or ',
    de: 'Sie sehen die E-Mail nicht? Überprüfen Sie Ihren "Spam"-Ordner oder ',
  },
  regStep1Confirm6: {
    ua: 'почніть спочатку',
    en: 'start over',
    de: 'beginnen Sie von vorne',
  },
  regStep1ConfirmWarn1: {
    ua: 'Цей email вже зареєстрований!',
    en: 'This email is already registered!',
    de: 'Diese E-Mail ist bereits registriert!',
  },
  regStep1ConfirmSuccess: {
    ua: 'Email підтверджено',
    en: 'Email confirmed',
    de: 'E-Mail bestätigt',
  },
  letterSentMessage: {
    ua: 'Лист відправлено',
    en: 'Email sent',
    de: 'E-Mail gesendet',
  },

  regStep2Confirm1: {
    ua: 'Будь ласка, введіть ваш номер телефону для завершення процесу реєстрації.',
    en: 'Please enter your phone number to complete the registration process.',
    de: 'Bitte geben Sie Ihre Telefonnummer ein, um den Registrierungsprozess abzuschließen.',
  },
  regStep2Confirm2: {
    ua: 'Номер телефону',
    en: 'Phone number',
    de: 'Telefonnummer',
  },
  regStep2Confirm3: {
    ua: 'На цей номер буде відправлено смс з кодом для підтвердження номера телефону.',
    en: 'On this number, an SMS with a confirmation code will be sent.',
    de: 'An diese Nummer wird eine SMS mit einem Bestätigungscode gesendet.',
  },
  regStep2Confirm4: {
    ua: 'Ми відправили Вам смс повідомлення на номер ',
    en: 'We have sent you an SMS message to the number ',
    de: 'Wir haben Ihnen eine SMS-Nachricht an die Nummer ',
  },
  regStep2Confirm5: {
    ua: 'для підтвердження вашого номера телефону. Будь ласка, перевірте повідомлення та введіть код з смс для завершення реєстрації.',
    en: ' to confirm your phone number. Please check the message and enter the code from the SMS to complete the registration.',
    de: ' gesendet, um Ihre Telefonnummer zu bestätigen. Bitte überprüfen Sie die Nachricht und geben Sie den Code aus der SMS ein, um die Registrierung abzuschließen.',
  },
  regStep2Confirm6: {
    ua: 'Відправити смс повторно',
    en: 'Resend SMS',
    de: 'SMS erneut senden',
  },
  regStep2Confirm7: {
    ua: 'Перед повторною зміною номеру потрібно почекати',
    en: 'Before resending the phone number, wait',
    de: 'Bevor wir das Telefonnummer erneut senden, warten',
  },
  regStep3Confirm1: {
    ua: 'Я підтверджую що ознайомлений з ',
    en: 'I confirm that I have read the ',
    de: 'Ich bestätige, dass ich die ',
  },
  regStep3Confirm2: {
    ua: 'політикою конфіденційності',
    en: 'privacy policy',
    de: 'Datenschutzrichtlinie',
  },

  redButton: {
    ua: 'Тривожна кнопка',
    en: 'Alarm button',
    de: 'Alarmknopf',
  },

  redButtonDebt: {
    ua: 'Наявний борг',
    en: 'Existing debt',
    de: 'Bestehende Schulden',
  },

  // Transactions

  // Need to fix paragraphs with currency

  transactionsRejected: {
    ua: '(відхилено)',
    en: '(rejected)',
    de: '(abgelehnt)',
  },
  transactionsPending: {
    ua: '(на підтвердженні)',
    en: '(pending)',
    de: '(ausstehend)',
  },
  transactionsReferralBonus: {
    ua: 'Бонус запрошення',
    en: 'Referral Bonus',
    de: 'Empfehlungsbonus',
  },
  transactionsBonusTr: {
    ua: 'Бонусова транзакція',
    en: 'Bonus Transaction',
    de: 'Bonus-Transaktion',
  },
  transactionsBalance: {
    ua: 'залишок',
    en: 'balance',
    de: 'Guthaben',
  },
  transactionsBlUnknown: {
    ua: 'невідомо',
    en: 'unknown',
    de: 'unbekannt',
  },
  transactionsAccrualBonuses: {
    ua: 'Нарахування бонусу',
    en: 'Bonus accrual',
    de: 'Bonusgutschrift',
  },
  transactionsCashbackForBonuses: {
    ua: 'Оплата бонусами',
    en: 'Payment with bonuses',
    de: 'Zahlung mit Boni',
  },
  // transactionsBonuses: {
  //   ua: 'Бонусові транзакції',
  //   en: 'Bonus Transactions',
  //   de: 'Bonus Transaktionen',
  // },
  replenishmentBalance: {
    ua: 'Поповнення балансу',
    en: 'Replenishment of the balance',
    de: 'Kontostand aufladen',
  },
  paymenttoContract: {
    ua: 'Оплата гонорару за договором',
    en: 'Payment for the contract fee',
    de: 'Zahlung für die Vertragsgebühr',
  },
  unknownOperation: {
    ua: 'Невідома операція',
    en: 'Unknown operation',
    de: 'Unbekannte Operation',
  },

  // Pages

  // ClientChangePass Page
  clientChangePassWarn1: {
    ua: 'Користувача з таким email не знайдено!',
    en: 'User with such email not found!',
    de: 'Benutzer mit dieser E-Mail wurde nicht gefunden!',
  },
  clientChangePassWarn2_1: {
    ua: 'Ви перевищили ліміт спроб!',
    en: 'You have exceeded the limit of attempts!',
    de: 'Sie haben das Limit der Versuche überschritten!',
  },
  clientChangePassWarn2_2: {
    ua: 'Спробуйте завтра!',
    en: 'Try again tomorrow!',
    de: 'Versuchen Sie es morgen erneut!',
  },
  clientChangePassWarn3_1: {
    ua: 'Неможливо відновити пароль!',
    en: 'Unable to recover password!',
    de: 'Passwort kann nicht wiederhergestellt werden!',
  },
  clientChangePassWarn3_2: {
    ua: 'Будь ласка, зверніться на гарячу лінію!',
    en: 'Please contact the hotline!',
    de: 'Bitte wenden Sie sich an die Hotline!',
  },
  clientChangePassSuccess: {
    ua: 'Пароль відправлено на ваш телефон!',
    en: 'Password sent to your phone!',
    de: 'Passwort an Ihr Telefon gesendet!',
  },
  clientChangePassMenuItem: {
    ua: 'Відновлення паролю',
    en: 'Password Recovery',
    de: 'Passwortwiederherstellung',
  },
  clientCPIsEmailSended: {
    ua: 'Інструкцію щодо відновлення паролю, відправлено на пошту!',
    en: 'Instructions for password recovery have been sent to your email!',
    de: 'Anweisungen zur Passwortwiederherstellung wurden an Ihre E-Mail gesendet!',
  },
  clientCPEmailMessage1: {
    ua: 'Неправильний формат email',
    en: 'Invalid email format',
    de: 'Ungültiges E-Mail-Format',
  },
  clientCPEmailMessage2: {
    ua: 'Введіть ваш email',
    en: 'Enter your email',
    de: 'Geben Sie Ihre E-Mail-Adresse ein',
  },
  clientCPSubmitBtn: {
    ua: 'Надіслати',
    en: 'Send',
    de: 'Senden',
  },
  clientCPPhoneLabel: {
    ua: 'Номер телефону',
    en: 'Phone Number',
    de: 'Telefonnummer',
  },

  clientCPPhonePlaceholder: {
    ua: 'Телефон',
    en: 'Phone',
    de: 'Telefon',
  },
  clientCPPhoneBtn: {
    ua: 'Відновити через телефон',
    en: 'Recover via phone',
    de: 'Wiederherstellen per Telefon',
  },
  clientCPQuestionLink: {
    ua: 'Згадали пароль?',
    en: 'Remembered your password?',
    de: 'Passwort erinnert?',
  },

  // ClientConfirmNewPassword Page
  clientConfirmNPTitle: {
    ua: 'Кабінет клієнта',
    en: 'Client Dashboard',
    de: 'Kunden-Dashboard',
  },
  clientConfirmNPSuccess: {
    ua: 'Пароль успішно змінено',
    en: 'Password successfully changed',
    de: 'Passwort erfolgreich geändert',
  },
  clientConfirmNPLabel: {
    ua: 'Новий пароль',
    en: 'New Password',
    de: 'Neues Passwort',
  },
  clientConfirmNPUpdatePBtn: {
    ua: 'Змінити пароль',
    en: 'Change Password',
    de: 'Passwort ändern',
  },

  // ClientContactUs Page
  clientContactUsTitle1: {
    ua: `Зв'яжіться з нами`,
    en: 'Contact Us',
    de: 'Kontaktieren Sie uns',
  },
  clientContactUsTitle2: {
    ua: 'Безкоштовна гаряча лінія',
    en: 'Toll-Free Hotline',
    de: 'Kostenlose Hotline',
  },
  clientContactUsDelimiter1: {
    ua: 'Контакти',
    en: 'Contacts',
    de: 'Kontakte',
  },
  clientContactUsDelimiter2: {
    ua: 'Консультація',
    en: 'Consultation',
    de: 'Beratungstermin',
  },
  clientContactUsInfo1: {
    ua: 'Адреса:',
    en: 'Address:',
    de: 'Adresse:',
  },
  Ukraine: {
    ua: 'Україна',
    en: 'Ukraine',
    de: 'Ukraine',
    ru: 'Украина',
    fr: 'Ukraine',
    es: 'Ucrania',
    cs: 'Ukrajina',
  },
  Poland: {
    ua: 'Польща',
    en: 'Poland',
    de: 'Polen',
    ru: 'Польша',
    fr: 'Pologne',
    es: 'Polonia',
    cs: 'Polska',
  },
  CzechRepublic: {
    ua: 'Чехія',
    en: 'Czech Republic',
    de: 'Tschechische Republik',
    ru: 'Чехия',
    fr: 'République tchèque',
    es: 'República Checa',
    cs: 'Česko',
  },
  Germany: {
    ua: 'Німеччина',
    en: 'Germany',
    de: 'Deutschland',
    ru: 'Германия',
    fr: 'Allemagne',
    es: 'Alemania',
    cs: 'Německo',
  },
  clientContactUsInfo2: {
    ua: 'Вся Україна',
    en: 'All Ukraine',
    de: 'Ganz Ukraine',
  },
  clientContactUsInfo3: {
    ua: 'Щодня:',
    en: 'Every Day:',
    de: 'Jeden Tag:',
  },
  clientContactUsInfo4: {
    ua: 'Телефон:',
    en: 'Phone:',
    de: 'Telefon:',
  },
  clientContactUsTitle3: {
    ua: 'Слідкуйте за нами в соцмережах',
    en: 'Follow Us on Social Media',
    de: 'Folgen Sie uns in sozialen Medien',
  },

  // ClientContractInfo Page
  clientContractInfoChat: {
    ua: 'Чат для договору ',
    en: 'Contract Chat',
    de: 'Vertragschat',
  },
  clientCInfDownloadFileWarn1: {
    ua: 'Для завантаження документу, потрібно вказати дійсний файл.',
    en: 'To download the document, a valid file is required.',
    de: 'Um das Dokument herunterzuladen, wird eine gültige Datei benötigt.',
  },
  clientCInfDownloadFileWarn2: {
    ua: 'Для завантаження документу, потрібно вказати назву файлу.',
    en: 'To download the document, a file name is required.',
    de: 'Um das Dokument herunterzuladen, wird ein Dateiname benötigt.',
  },
  clientCInfDownloadFileWarn3: {
    ua: 'Для завантаження документу, потрібно вказати формат файлу.',
    en: 'To download the document, a file format is required.',
    de: 'Um das Dokument herunterzuladen, wird ein Dateiformat benötigt.',
  },
  clientCInfDownloadFileSuccess: {
    ua: 'Документ успішно завантажено',
    en: 'Document successfully downloaded',
    de: 'Dokument erfolgreich heruntergeladen',
  },
  clientCInfDownloadFileError: {
    ua: 'Помилка при отриманні файлу',
    en: 'Error receiving file',
    de: 'Fehler beim Empfangen der Datei',
  },
  clientCInfOnGetFileError1: {
    ua: 'Відсутній ID файлу',
    en: 'File ID missing',
    de: 'Datei-ID fehlt',
  },
  // Contract Details
  clientCHotlineInf: {
    ua: 'Інформація про консультацію:',
    en: 'Information on the consultation:',
    de: 'Informationen über die Konsultation:',
  },
  clientCHotlineDate: {
    ua: 'Дата:',
    en: 'Date:',
    de: 'Datum:',
  },
  clientCHotlineConducted: {
    ua: 'Проводив:',
    en: 'Conducted:',
    de: 'Durchgeführt:',
  },
  clientCInf: {
    ua: 'Інформація про договір:',
    en: 'Contract information:',
    de: 'Informationen über den Vertrag:',
  },
  clientCNum: {
    ua: 'Номер:',
    en: 'Number:',
    de: 'Nummer:',
  },
  clientCDate: {
    ua: 'Дата укладання:',
    en: 'Date of conclusion:',
    de: 'Datum des Abschlusses:',
  },
  clientCFil: {
    ua: 'Філія:',
    en: 'Branch:',
    de: 'Niederlassung:',
  },
  clientCPravo: {
    ua: 'Вид правовідносин:',
    en: 'Type of legal relationship:',
    de: 'Art des Rechtsverhältnisses:',
  },
  clientCPravoExactly: {
    ua: 'Що саме:',
    en: 'What exactly:',
    de: 'Was genau:',
  },
  clientInfo: {
    ua: 'Дані клієнта:',
    en: 'Customer information:',
    de: 'Informationen für Kunden',
  },
  birthTitle: {
    ua: 'День народження',
    en: 'Birthday',
    de: 'Geburtstag',
  },
  clientPassportInf: {
    ua: 'Паспортні дані',
    en: 'Passport details',
    de: 'Geburtstag',
  },
  type: {
    ua: 'Тип',
    en: 'Type',
    de: 'Typ',
  },
  number: {
    ua: 'Номер',
    en: 'Number',
    de: 'Nummer',
  },
  clientPassportIssued: {
    ua: 'Виданий:',
    en: 'Issued:',
    de: 'Ausgestellt:',
  },
  clientPassportIssueDate: {
    ua: 'Дата видачі:',
    en: 'Issue date:',
    de: 'Ausgabedatum:',
  },
  clientPassportValidDate: {
    ua: 'Дійсний до:',
    en: 'Valid until:',
    de: 'Gültig bis:',
  },

  // contract payment management
  paymentManagement: {
    ua: 'Менеджмент платежів',
    en: 'Payment management',
    de: 'Verwaltung der Zahlungen',
  },
  fullFee: {
    ua: 'Повний гонорар',
    en: 'Full fee',
    de: 'volle Gebühr',
  },
  firstPaymentDate: {
    ua: 'Дата першого платежу',
    en: 'First payment date',
    de: 'Erstes Zahlungsdatum',
  },
  exitsOfLawyer: {
    ua: 'Виходи адвоката',
    en: 'Exits of a lawyer',
    de: 'Ausgänge eines Anwalts',
  },
  numberOfMonths: {
    ua: 'Кількість місяців',
    en: 'Number of months',
    de: 'Anzahl der Monate',
  },
  totalPaid: {
    ua: 'Всього оплачено',
    en: 'Total paid',
    de: 'Insgesamt bezahlt',
  },
  monthlyPayment: {
    ua: 'Щомісячний платіж',
    en: 'Monthly payment',
    de: 'Monatliche Zahlung',
  },
  assignments: {
    ua: 'Виходи',
    en: 'Assignments',
    de: 'Zuweisungen',
  },
  debt: {
    ua: 'Борг',
    en: 'Debt',
    de: 'Schulden',
  },
  overpayment: {
    ua: 'Переплата',
    en: 'Overpayment',
    de: 'Überzahlung',
  },

  contractTransactions: {
    ua: 'Транзакції по договору',
    en: 'Contract transactions',
    de: 'Transaktionen über den Vertrag',
  },

  // consult
  clientCInfConsultResult1: {
    ua: 'Консультація відбулася',
    en: 'Consultation took place',
    de: 'Beratung hat stattgefunden',
  },
  clientCInfConsultResult2: {
    ua: 'Консультація не відбулася',
    en: 'Consultation did not take place',
    de: 'Beratung hat nicht stattgefunden',
  },
  clientCInfConsultTitle: {
    ua: 'Запис на консультацію',
    en: 'Consultation Booking',
    de: 'Beratungsanmeldung',
  },
  // qualityArchiveBtn
  // FuncCorrecpondence FuncChat
  clientCInfCorrecpondenceType1: {
    ua: 'Вихідна ',
    en: 'Outgoing ',
    de: 'Ausgehend ',
  },
  clientCInfCorrecpondenceType2: {
    ua: 'Вхідна ',
    en: 'Incoming ',
    de: 'Eingehend ',
  },
  clientCInfCorrecpondenceText1: {
    ua: 'Скачати файл',
    en: 'Download file',
    de: 'Datei herunterladen',
  },
  clientCInfCorrecpondenceText2: {
    ua: 'и',
    en: 'and',
    de: 'und',
  },
  clientCInfCorrecpondenceText3: {
    ua: 'з пошти',
    en: 'from email',
    de: 'von E-Mail',
  },
  clientCInfCorrecpondenceText4: {
    ua: 'шт.',
    en: 'pcs.',
    de: 'Stk.',
  },
  clientInComponentPreview: {
    ua: 'Перегляд',
    en: 'Preview',
    de: 'Vorschau',
  },
  clientCInfCorrecpondenceFileType1: {
    ua: 'Ви відправили файл куратору.',
    en: 'You sent a file to the curator.',
    de: 'Sie haben dem Kurator eine Datei gesendet.',
  },
  clientCInfCorrecpondenceFileType2: {
    ua: 'Ви отримали файл від куратора.',
    en: 'You received a file from the curator.',
    de: 'Sie haben eine Datei vom Kurator erhalten.',
  },
  clientCInfChatDownload: {
    ua: 'Скачати файл з чату (1 шт.)',
    en: 'Download file from chat (1 pc.)',
    de: 'Datei aus dem Chat herunterladen (1 Stk.)',
  },
  clientCInfCuratorTitle: {
    ua: 'Куратор по договору:',
    en: 'Contract curator:',
    de: 'Vertragskurator:',
  },
  clientCInfCuratorButton1: {
    ua: 'Написати',
    en: 'Write',
    de: 'Schreiben',
  },
  clientCInfCuratorButton2: {
    ua: 'Подзвонити',
    en: 'Call',
    de: 'Anrufen',
  },
  clientCInfCuratorButton3: {
    ua: 'Поскаржитись',
    en: 'Complain',
    de: 'Beschweren',
  },
  clientCInfCuratorButton4: {
    ua: 'Оплата',
    en: 'Payment',
    de: 'Zahlung',
  },
  clientCInfCuratorButton5: {
    ua: 'Події',
    en: 'Events',
    de: 'Ereignisse',
  },
  clientCInfCuratorButton6: {
    ua: 'Кореспонденція',
    en: 'Correspondence',
    de: 'Korrespondenz',
  },
  clientCInfCuratorButton7: {
    ua: 'Автоматичні списання',
    en: 'Automatic deductions',
    de: 'Automatische Abbuchungen',
  },
  clientCInfCuratorButton8: {
    ua: 'Задачі',
    en: `Tasks`,
    de: `Aufgaben`,
  },
  clientCInfCuratorButton9: {
    ua: `Файли`,
    en: `Files`,
    de: `Dateien`,
  },
  clientCInfCuratorButton10: {
    ua: `Оплати`,
    en: `Payments`,
    de: `Zahlungen`,
  },
  clientCInfCuratorButton11: {
    ua: 'Менеджмент платежів',
    en: 'Payment management',
    de: 'Zahlungsverwaltung',
  },
  // PreviewImage
  previewImage: {
    ua: 'Помилка',
    en: 'Error',
    de: 'Fehler',
  },
  // AddFileTabList
  addFileTabListError1: {
    ua: 'Відсутні дані для відправки',
    en: 'No data to send',
    de: 'Keine Daten zum Senden',
  },
  addFileTabListError2: {
    ua: 'Не вказана функція зворотнього виклику',
    en: 'Callback function not specified',
    de: 'Rückruffunktion nicht angegeben',
  },
  addFileTabListError3: {
    ua: 'Помилка під час відправки файлу',
    en: 'Error sending file',
    de: 'Fehler beim Senden der Datei',
  },
  addFileTabListSuccess: {
    ua: 'Файл успішно додано',
    en: 'File successfully added',
    de: 'Datei erfolgreich hinzugefügt',
  },
  addFileTabListWarn: {
    ua: 'У файлі відсутній конкретний формат. Запис неможливий у такому випадку',
    en: 'The file does not have a specific format. Writing is not possible in this case',
    de: 'Die Datei hat kein spezifisches Format. Das Schreiben ist in diesem Fall nicht möglich',
  },
  addFileTabListBtn: {
    ua: 'Додати файл в чат з куратором',
    en: 'Add file to chat with curator',
    de: 'Datei zum Chat mit dem Kurator hinzufügen',
  },
  addFileTabListLoader: {
    ua: 'Зачекайте, йде процес додавання файлу.',
    en: 'Please wait, file upload in progress.',
    de: 'Bitte warten, Datei wird hochgeladen.',
  },
  addFileTabListCardTitle: {
    ua: 'Форма для додавання',
    en: 'Form for adding',
    de: 'Formular zum Hinzufügen',
  },
  addFileTabListUploadFile: {
    ua: 'Завантажити файл',
    en: 'Upload file',
    de: 'Datei hochladen',
  },
  addFileTabListUpload: {
    ua: 'Завантажити',
    en: 'Upload',
    de: 'Hochladen',
  },
  addFileTabListCommentLabel: {
    ua: 'Коментар:',
    en: 'Comment:',
    de: 'Kommentar:',
  },
  addFileTabListCommentPlaceholder: {
    ua: 'Додатковий опис.',
    en: 'Additional description.',
    de: 'Zusätzliche Beschreibung.',
  },
  descriptionOfService: {
    ua: 'Опис послуги:',
    en: 'Service description:',
    de: 'Leistungsbeschreibung:',
  },
  //LastEvents
  lastEvents: {
    ua: 'Останні події:',
    en: 'Last events',
    de: 'jüngste Entwicklungen',
  },
  lastEventsMessageFrom: {
    ua: 'Повідомлення від:',
    en: 'A message from',
    de: 'Nachricht von:',
  },
  lastEventsMessage: {
    ua: 'Повідомлення',
    en: 'A message',
    de: 'Nachricht',
  },
  lastEventsTaskComment: {
    ua: 'Коментар до задачі',
    en: 'Comment on the task',
    de: 'Kommentar zur Aufgabe',
  },
  lastEventsTimeSent: {
    ua: 'Час відправки',
    en: 'Time of dispatch',
    de: 'Zeitpunkt des Versands',
  },
  lastEventsAuthor: {
    ua: 'Автор',
    en: 'Author',
    de: 'Autor',
  },
  lastEventsTaskName: {
    ua: 'Назва задачі',
    en: 'Task name',
    de: 'Name der Aufgabe',
  },
  lastEventsComment: {
    ua: 'Комментар',
    en: 'Comment',
    de: 'Kommentar',
  },
  lastEventsTime: {
    ua: 'Час',
    en: 'Time',
    de: 'Zeit',
  },
  lastEventsTransaction: {
    ua: 'Транзакція',
    en: 'Transaction',
    de: 'Transaktion',
  },
  lastEventsType: {
    ua: 'Тип',
    en: 'Type',
    de: 'Typ',
  },
  lastEventsTotal: {
    ua: 'Сумма',
    en: 'Total',
    de: 'Betrag',
  },
  lastEventsPaymentDate: {
    ua: 'Дата платежу',
    en: 'Date of payment',
    de: 'Datum der zahlung',
  },
  lastEventsDate: {
    ua: 'Дата',
    en: 'Date',
    de: 'Datum',
  },
  lastEventsStatus: {
    ua: 'Статус',
    en: 'Status',
    de: 'Status',
  },
  lastEventsContractNumber: {
    ua: 'Номер договору',
    en: 'Contract number',
    de: 'Vertragsnummer',
  },
  lastEventsPrice: {
    ua: 'Ціна',
    en: 'Price',
    de: 'Preis',
  },
  lastEventsFilia: {
    ua: 'Філія',
    en: 'Branch',
    de: 'Niederlassung',
  },
  lastEventsAddress: {
    ua: 'Адреса',
    en: 'Address',
    de: 'Adresse',
    ru: 'Адрес',
    fr: 'Adresse',
    es: 'Dirección',
    cs: 'Adresa',
  },
  lastEventsGoToHotline: {
    ua: 'Перейти до консультацій',
    en: 'Go to consultations',
    de: 'Zu den Konsultationen gehen',
  },

  // ClientContracts  Page

  // ComplaintForm
  ComplaintFormModalTitle: {
    ua: 'Поскаржитись',
    en: 'Complaint',
    de: 'Beschwerde',
  },
  ComplaintFormModalOkText: {
    ua: 'Відправити',
    en: 'Send',
    de: 'Senden',
  },
  ComplaintFormTextAreaPlaceholder: {
    ua: 'Опишіть ситуацію...',
    en: 'Describe the situation...',
    de: 'Beschreiben Sie die Situation...',
  },
  ComplaintFormMinCharactersValidation: {
    ua: 'Не менше 20 символів',
    en: 'At least 20 characters',
    de: 'Mindestens 20 Zeichen',
  },
  // Curator
  CuratorTitle: {
    ua: 'Куратор:',
    en: 'Curator:',
    de: 'Kurator:',
  },
  CuratorName: {
    ua: `Ім'я:`,
    en: 'Name:',
    de: 'Name:',
  },
  CuratorButton1: {
    ua: 'Написати',
    en: 'Write',
    de: 'Schreiben',
  },
  CuratorButton2: {
    ua: 'Поскаржитись',
    en: 'Complaint',
    de: 'Beschwerde',
  },
  CuratorButton3: {
    ua: 'Завдання',
    en: 'Tasks',
    de: 'Aufgaben',
  },
  // EventsItem
  eItemStateOccurred: {
    ua: 'Відбулася',
    en: 'Occurred',
    de: 'Ereignet',
  },
  eItemStateNotOccurred: {
    ua: 'Не відбулася',
    en: 'Not occurred',
    de: 'Nicht stattgefunden',
  },
  eItemStateInProgress: {
    ua: 'В процесі',
    en: 'In progress',
    de: 'In Bearbeitung',
  },
  eItemComment: {
    ua: 'Коментар:',
    en: 'Comment:',
    de: 'Kommentar:',
  },
  // Payments
  paymentsTitle: {
    ua: 'Сплатити по договору:',
    en: 'Pay according to the contract:',
    de: 'Zahlung gemäß Vertrag:',
  },
  // PaymentsItem
  paymentsItem1: {
    ua: 'id платежу',
    en: 'Payment ID',
    de: 'Zahlungs-ID',
  },
  paymentsItem2: {
    ua: 'Сума',
    en: 'Amount',
    de: 'Betrag',
  },
  // PaymentSuccess
  paymentsSuccessText1: {
    ua: 'Дякуємо, ваш платіж прийнято',
    en: 'Thank you, your payment has been accepted',
    de: 'Vielen Dank, Ihre Zahlung wurde akzeptiert',
  },
  paymentsSuccessText2: {
    ua: 'Дану карту збережено, для наступних оплат',
    en: 'This card has been saved for future payments',
    de: 'Diese Karte wurde für zukünftige Zahlungen gespeichert',
  },
  paymentsSuccessText3: {
    ua: 'Видалити або замінити на іншу, можна в особистому кабінеті',
    en: 'You can delete or replace it with another in your personal account',
    de: 'Sie können sie in Ihrem persönlichen Konto löschen oder durch eine andere ersetzen',
  },
  paymentsSuccessText4: {
    ua: 'ID платежу',
    en: 'Payment ID',
    de: 'Zahlungs-ID',
  },
  paymentsSuccessText5: {
    ua: 'Дані покупця захищено',
    en: 'Buyer data protected',
    de: 'Käuferdaten geschützt',
  },
  paymentsSuccessText6: {
    ua: 'Згідно з міжнародним стандартом захисту платежів PCI DSS',
    en: 'According to the international standard for payment protection PCI DSS',
    de: 'Gemäß dem internationalen Standard für Zahlungssicherheit PCI DSS',
  },
  paymentsSuccessText7: {
    ua: 'Регулюється міжнародними платіжними системами Visa та Mastercard',
    en: 'Regulated by international payment systems Visa and Mastercard',
    de: 'Reguliert von internationalen Zahlungssystemen Visa und Mastercard',
  },

  // ClientContracts2  Page

  //  CC2 ContractsModal
  contractsModalTitle1: {
    ua: 'Поточний договір',
    en: 'Current contract',
    de: 'Aktueller Vertrag',
  },
  contractsModalPlaceholder: {
    ua: 'Введіть нову назву',
    en: 'Enter a new name',
    de: 'Geben Sie einen neuen Namen ein',
  },
  contractsModalTitle2: {
    ua: 'Інші договори',
    en: 'Other contracts',
    de: 'Andere Verträge',
  },
  hideBtn: {
    ua: 'Сховати',
    en: 'Hide',
    de: 'Verbergen',
  },
  //  CC2 Payments

  CC2PaymentsTitle: {
    ua: 'Оплата по договору',
    en: 'Contract payment',
    de: 'Vertragszahlung',
  },
  closeBtn: {
    ua: 'Закрити',
    en: 'Close',
    de: 'Schließen',
  },
  CC2PaymentsIframeTitle: {
    ua: 'Інший сайт',
    en: 'Another website',
    de: 'Andere Website',
  },
  //  CC2  ContractItem
  contractItem: {
    ua: 'Справа',
    en: 'Case',
    de: 'Fall',
  },

  // ClientFeedbacks  Page

  // FeedbackAnswer
  FeedbackAnswer: {
    ua: 'З повагою,',
    en: 'Best regards,',
    de: 'Mit freundlichen Grüßen,',
  },
  // FeedbacksForm
  feedbacksFormInputName: {
    ua: `Введіть ім'я`,
    en: 'Enter your name',
    de: 'Geben Sie Ihren Namen ein',
  },
  feedbacksFormEmail: {
    ua: 'Введіть електронну пошту',
    en: 'Enter your email',
    de: 'Geben Sie Ihre E-Mail-Adresse ein',
  },
  feedbacksFormRating: {
    ua: 'Виберіть рейтинг',
    en: 'Select rating',
    de: 'Bewertung auswählen',
  },
  feedbacksFormFeedback: {
    ua: 'Напишіть ваш відгук',
    en: 'Write your feedback',
    de: 'Schreiben Sie Ihr Feedback',
  },
  feedbacksFormTitle: {
    ua: 'Залиште відгук',
    en: 'Leave feedback',
    de: 'Feedback hinterlassen',
  },
  feedbacksFormText: {
    ua: 'Опишіть ваше враження від співпраці з нами, що Вам сподобалось, а що ні. Дякуючи вашим відгукам, ми розвиваємось і стаємо кращими.',
    en: "Describe your experience of working with us, what you liked and what you didn't like. Thanks to your feedback, we grow and become better.",
    de: 'Beschreiben Sie Ihre Erfahrung mit uns, was Ihnen gefallen hat und was nicht. Dank Ihres Feedbacks wachsen wir und werden besser.',
  },
  feedbacksFormYourRating: {
    ua: 'Ваша оцінка:',
    en: 'Your rating:',
    de: 'Ihre Bewertung:',
  },
  feedbacksFormName: {
    ua: `Ваше ім'я`,
    en: 'Your name',
    de: 'Ihr Name',
  },
  // sendButton

  // ClientHome   Page

  clientHomeBalanceNew: {
    ua: 'Бонуси:',
    en: 'Bonuses:',
    de: 'Bonuspunkte:',
  },
  clientHomeTransactionsTitle1: {
    ua: 'Транзакції по бонусам',
    en: 'Bonus transactions',
    de: 'Bonus-Transaktionen',
  },
  clientHomeTransactionsTitle2: {
    ua: 'Транзакції по рахунку',
    en: 'Account transactions',
    de: 'Konto-Transaktionen',
  },

  clientBalancePayModalPlaceholder: {
    ua: 'Введіть суму поповнення',
    en: 'Enter top-up amount',
    de: 'Geben Sie den Aufladebetrag ein',
  },
  clientBalancePayModalButtonText: {
    ua: 'Поповнити',
    en: 'Top Up',
    de: 'Aufladen',
  },

  // Bonuses
  clientBonuses1: {
    ua: 'Сума послуги',
    en: 'Service amount',
    de: 'Servicebetrag',
  },
  clientBonuses2: {
    ua: 'Використати бонусів',
    en: 'Use bonuses',
    de: 'Bonusse verwenden',
  },
  clientBonuses3: {
    ua: 'є на рахунку',
    en: 'available on account',
    de: 'auf dem Konto verfügbar',
  },
  clientBonuses4: {
    ua: 'Недостатньо бонусів',
    en: 'Insufficient bonuses',
    de: 'Nicht genug Bonusse',
  },
  clientBonuses5: {
    ua: 'До сплати:',
    en: 'To pay:',
    de: 'Zu zahlen:',
  },
  clientBonuses6: {
    ua: 'Використати бонусів:',
    en: 'Use bonuses:',
    de: 'Bonusse verwenden:',
  },

  // ClientHotline   Page
  clientHotlineTitle: {
    ua: 'Для запису на консультацію завершіть реєстрацію',
    en: 'To schedule a consultation, please complete the registration',
    de: 'Um eine Beratung zu vereinbaren, bitte vervollständigen Sie die Registrierung',
  },
  clientHotlineSuccessMessage1: {
    ua: 'Послуга:',
    en: 'Service:',
    de: 'Dienstleistung:',
  },
  clientHotlineSuccessMessage2: {
    ua: 'Філія:',
    en: 'Branch:',
    de: 'Niederlassung:',
  },
  clientHotlineSuccessMessage3: {
    ua: 'Дата:',
    en: 'Date:',
    de: 'Datum:',
  },
  clientHotlineSuccessMessage4: {
    ua: 'Початок:',
    en: 'Start time:',
    de: 'Startzeit:',
  },
  clientHotlineSuccessMessage5: {
    ua: 'Завершення:',
    en: 'End time:',
    de: 'Endzeit:',
  },
  clientHotlineSuccessMessage6: {
    ua: 'Будь ласка, очікуйте на підтвердження.',
    en: 'Please, wait for confirmation.',
    de: 'Bitte warten Sie auf die Bestätigung.',
  },
  clientHotlineSubmitButton: {
    ua: 'Гаразд',
    en: 'OK',
    de: 'OK',
  },

  // ClientInsuranceDriver   Page
  clientIDLoading: {
    ua: 'Завантаження',
    en: 'Loading',
    de: 'Wird geladen',
  },
  clientIDMissing: {
    ua: 'Страховки відсутні',
    en: 'Insurance is missing',
    de: 'Versicherung fehlt',
  },
  // Buttons
  clientIDBtn1: {
    ua: 'Умови',
    en: 'Terms',
    de: 'Bedingungen',
  },
  clientIDBtn2: {
    ua: 'Транзакції',
    en: 'Transactions',
    de: 'Transaktionen',
  },

  // ClientLogin   Page

  clientLoginError: {
    ua: 'Не вірні данні',
    en: 'Invalid credentials',
    de: 'Ungültige Anmeldeinformationen',
  },
  clientLoginRule: {
    ua: 'Для запису потрібно авторизуватись',
    en: 'You need to log in to register',
    de: 'Sie müssen sich anmelden, um sich zu registrieren',
  },
  clientLoginTitle: {
    ua: 'Кабінет клієнта',
    en: 'Client Cabinet',
    de: 'Kundenkonto',
  },
  clientLoginPhoneMes: {
    ua: 'Телефон не коректний!',
    en: 'Phone number is incorrect!',
    de: 'Telefonnummer ist ungültig!',
  },
  clientLoginQuestion1: {
    ua: 'Не маєте акаунту?',
    en: "Don't have an account?",
    de: 'Sie haben kein Konto?',
  },
  clientLoginQuestion2: {
    ua: 'Забули пароль?',
    en: 'Forgot your password?',
    de: 'Passwort vergessen?',
  },
  clientLoginButton1: {
    ua: 'Зареєструватися',
    en: 'Register',
    de: 'Registrieren',
  },
  clientLoginButton2: {
    ua: 'Відновити доступ',
    en: 'Restore access',
    de: 'Zugriff wiederherstellen',
  },

  // ClientMainPage   Page

  // ClientMainPageHotlineItem
  clientMPHItemClockCircleFilled: {
    ua: 'Очікуйте підтвердження',
    en: 'Awaiting confirmation',
    de: 'Warten auf Bestätigung',
  },
  // ClientMainPageHotlines
  clientBalancePayModalTitle: {
    ua: 'Подяка юристу',
    en: 'Thank Your Lawyer',
    de: 'Dank an Ihren Anwalt',
  },
  clientMainPageHotlinesModalPlaceholder: {
    ua: 'Введіть суму подяки',
    en: 'Enter gratitude amount',
    de: 'Geben Sie den Dankbetrag ein',
  },

  // ClientProfile   Page
  clientProfileError1: {
    ua: 'Можливо завантажити фото тільки в JPG форматі!',
    en: 'Only JPG format is allowed for photo upload!',
    de: 'Nur das JPG-Format ist für den Fotoupload zulässig!',
  },
  clientProfileError2: {
    ua: 'Розмір файлу повинен бути менше 2 мегабайт!',
    en: 'File size must be less than 2 megabytes!',
    de: 'Die Dateigröße muss kleiner als 2 Megabyte sein!',
  },
  clientProfileError3: {
    ua: 'Помилка, Помилка завантаження, спробуйте ще раз',
    en: 'Error, Upload error, please try again',
    de: 'Fehler, Upload-Fehler, bitte versuchen Sie es erneut',
  },
  clientProfileSuccess: {
    ua: 'Успіх!, Ваш профіль оновлено успішно!',
    en: 'Success! Your profile has been updated successfully!',
    de: 'Erfolg! Ihr Profil wurde erfolgreich aktualisiert!',
  },
  clientProfileFullName: {
    ua: 'ПІБ',
    en: 'Full Name',
    de: 'Vollständiger Name',
  },
  ProfileEmail: {
    ua: 'Електронна пошта',
    en: 'Email',
    de: 'E-Mail',
  },
  accountDeletionTitle: {
    ua: 'Видалити аккаунт',
    en: 'Delete Account',
    de: 'Konto löschen',
  },
  accountDeletionButton1: {
    ua: 'Відмінити',
    en: 'Cancel',
    de: 'Abbrechen',
  },
  accountDeletionButton2: {
    ua: 'Редагувати Профіль',
    en: 'Edit Profile',
    de: 'Profil bearbeiten',
  },
  accountDeletionModalTitle: {
    ua: 'Ви Впевнені у видаленні аккаунту?',
    en: 'Are you sure you want to delete your account?',
    de: 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten?',
  },
  accountDeletionModalEnterPhone: {
    ua: 'Для підтвердження введіть ваш номер телефону:',
    en: 'To confirm, enter your phone number:',
    de: 'Geben Sie zur Bestätigung Ihre Telefonnummer ein:',
  },
  accountDeletionModalEnterPhonePlaceholder: {
    ua: 'Введіть ваш номер телефону...',
    en: 'Enter your phone number...',
    de: 'Geben Sie Ihre Telefonnummer ein...',
  },
  accountDeletionModalEnterPhoneError: {
    ua: 'Номер телефону не співпадає',
    en: 'Phone number does not match',
    de: 'Telefonnummer stimmt nicht überein',
  },
  changingPhone: {
    ua: 'Зміна номера телефону',
    en: 'Changing Phone Number',
    de: 'Telefonnummer ändern',
  },
  changingEmail: {
    ua: 'Зміна електронної пошти',
    en: 'Changing Email',
    de: 'E-Mail-Adresse ändern',
  },

  // ClientReg   Page

  clientRegError1: {
    ua: 'Клієнт з таким номером вже існує',
    en: 'Client with this phone number already exists',
    de: 'Kunde mit dieser Telefonnummer existiert bereits',
  },
  clientRegError2: {
    ua: 'Помилка реєстрації',
    en: 'Registration error',
    de: 'Registrierungsfehler',
  },
  clientRegSuccess: {
    ua: 'Реєстрація пройшла успішно',
    en: 'Registration successful',
    de: 'Registrierung erfolgreich',
  },
  clientRegTitle1: {
    ua: 'Для запису потрібно зареєструватись',
    en: 'Registration required to sign up',
    de: 'Registrierung erforderlich, um sich anzumelden',
  },
  clientRegTitle2: {
    ua: 'Реєстрація клієнта',
    en: 'Client registration',
    de: 'Kundenregistrierung',
  },
  clientRegStateLabel: {
    ua: 'Область',
    en: 'Region',
    de: 'Region',
  },
  // clientBtn3
  clientRegPhoneMessage1: {
    ua: 'Будь ласка, введіть номер телефону!',
    en: 'Please enter your phone number!',
    de: 'Bitte geben Sie Ihre Telefonnummer ein!',
  },
  clientRegPhoneMessage2: {
    ua: 'Номер телефону повинен містити 10 цифр (0681111111).',
    en: 'The phone number must contain 10 digits (0681111111).',
    de: 'Die Telefonnummer muss 10 Ziffern enthalten (0681111111).',
  },
  clientRegNameMessage: {
    ua: 'Будь ласка, введіть ПІБ!',
    en: 'Please enter your full name!',
    de: 'Bitte geben Sie Ihren vollständigen Namen ein!',
  },
  clientRegStateMessage: {
    ua: 'Будь ласка, введіть свою область!',
    en: 'Please enter your region!',
    de: 'Bitte geben Sie Ihre Region ein!',
  },
  clientRegPasswordMessage: {
    ua: 'Будь ласка, введіть пароль!',
    en: 'Please enter your password!',
    de: 'Bitte geben Sie Ihr Passwort ein!',
  },
  clientRegConfirmPasswordMessage: {
    ua: 'Будь-ласка, підтвердіть пароль!',
    en: 'Please confirm your password!',
    de: 'Bitte bestätigen Sie Ihr Passwort!',
  },
  clientRegPivacyPolicy: {
    ua: 'Будь ласка, ознайомтеся з політикою конфіденційності.',
    en: 'Please read the privacy policy.',
    de: 'Bitte lesen Sie die Datenschutzrichtlinie.',
  },
  clientRegPivacyPolicyCheckbox1: {
    ua: 'Я погоджуюсь з',
    en: 'I agree to the ',
    de: 'Ich stimme der ',
  },
  clientRegPivacyPolicyCheckbox2: {
    ua: 'політикою конфіденційності',
    en: 'privacy policy',
    de: 'Datenschutzrichtlinie zu',
  },
  clientRegQuestion: {
    ua: 'Вже зареєстровані?',
    en: 'Already registered?',
    de: 'Bereits registriert?',
  },
  clientRegText: {
    ua: 'Для видалення облікового запису або отримання додаткової інформації, зверніться в службу підтримки на сторінці контактів.',
    en: 'To delete your account or get additional information, please contact support on the contact page.',
    de: 'Um Ihr Konto zu löschen oder weitere Informationen zu erhalten, wenden Sie sich bitte an den Support auf der Kontaktseite.',
  },

  // ClientTenders   Page

  clientTendersTitle: {
    ua: 'Для створення тендеру завершіть реєстрацію',
    en: 'Complete registration to create a tender',
    de: 'Vervollständigen Sie die Registrierung, um eine Ausschreibung zu erstellen',
  },
  // ClientTenderInfo
  clientTTenderInfoTitle1: {
    ua: 'Поки що ніхто не відповів, потрібно зачекати',
    en: 'No responses yet, please wait',
    de: 'Noch keine Antworten, bitte warten',
  },
  clientTTenderInfoTitle2: {
    ua: 'Обраний виконавець',
    en: 'Selected performer',
    de: 'Ausgewählter Ausführender',
  },
  clientTTenderInfoTitle3: {
    ua: 'Перелік заявок',
    en: 'List of applications',
    de: 'Liste der Bewerbungen',
  },
  // RespondItem
  respondItemSuccess: {
    ua: 'Пропозицію прийнято',
    en: 'Proposal accepted',
    de: 'Vorschlag akzeptiert',
  },
  respondItem1: {
    ua: 'Ім`я:',
    en: 'Name:',
    de: 'Name:',
  },
  respondItem2: {
    ua: 'Дата:',
    en: 'Date:',
    de: 'Datum:',
  },
  respondItem3: {
    ua: 'Термін:',
    en: 'Deadline:',
    de: 'Frist:',
  },
  respondItem4: {
    ua: 'Сума:',
    en: 'Amount:',
    de: 'Betrag:',
  },
  respondItem5: {
    ua: 'Пропозиція:',
    en: 'Proposal:',
    de: 'Vorschlag:',
  },
  respondItem6: {
    ua: 'Результат:',
    en: 'Result:',
    de: 'Ergebnis:',
  },
  // ClientTendersList
  clientTendersListTotle: {
    ua: 'Тендери відсутні',
    en: 'No tenders available',
    de: 'Keine Ausschreibungen vorhanden',
  },
  createTender: {
    ua: 'Створити тендер',
    en: 'Create tender',
    de: 'Ausschreibung erstellen',
  },
  // CreateTender
  createTenderSuccess: {
    ua: 'Заявка надана',
    en: 'Tender created successfully',
    de: 'Ausschreibung erfolgreich erstellt',
  },
  createTenderProblem: {
    ua: 'Опишіть проблему',
    en: 'Describe the problem',
    de: 'Beschreiben Sie das Problem',
  },
  createTenderTask: {
    ua: 'Опишіть задачу',
    en: 'Describe the task',
    de: 'Beschreiben Sie die Aufgabe',
  },
  createTenderSum: {
    ua: 'Запропонувати суму',
    en: 'Propose a sum',
    de: 'Betrag vorschlagen',
  },
  createTenderSumPlaceholder: {
    ua: 'Запропонувати гонорар адвокату ₴',
    en: 'Propose a fee for the lawyer ₴',
    de: 'Betrag für den Anwalt vorschlagen ₴',
  },
  createTenderTerm: {
    ua: 'Термін виконання',
    en: 'Execution term',
    de: 'Ausführungsfrist',
  },
  createTenderTermPlaceholder: {
    ua: 'Термін',
    en: 'Term',
    de: 'Frist',
  },

  // FiliiState   Page

  filiiState: {
    ua: 'Орієнтир:',
    en: 'Landmark:',
    de: 'Orientierungspunkt:',
  },

  // LawyerCV   Page
  yearForm1: {
    ua: 'роки',
    en: 'years',
    de: 'Jahre',
  },
  yearForm2: {
    ua: 'років',
    en: 'years',
    de: 'Jahre',
  },
  monthForm1: {
    ua: 'місяць',
    en: 'month',
    de: 'Monat',
  },
  monthForm2: {
    ua: 'місяці',
    en: 'months',
    de: 'Monate',
  },
  monthForm3: {
    ua: 'місяців',
    en: 'months',
    de: 'Monate',
  },
  positionLawyer1: {
    ua: 'Адвокат',
    en: 'Lawyer',
    de: 'Anwalt',
  },
  positionLawyer2: {
    ua: 'Юрист',
    en: 'Legal Counsel',
    de: 'Jurist',
  },
  lawyerCVPoints1: {
    ua: 'Посада',
    en: 'Position',
    de: 'Position',
  },
  lawyerCVPoints2: {
    ua: 'Освіта',
    en: 'Education',
    de: 'Ausbildung',
  },
  lawyerCVPoints3: {
    ua: 'Досвід роботи',
    en: 'Work Experience',
    de: 'Berufserfahrung',
  },
  lawyerCVPoints4: {
    ua: 'Робочий телефон',
    en: 'Work Phone',
    de: 'Arbeitstelefon',
  },
  lawyerCVPoints5: {
    ua: 'Адреса офісу:',
    en: 'Office Address:',
    de: 'Büroadresse:',
  },
  lawyerCVPoints6: {
    ua: 'Додаткова освіта',
    en: 'Additional Education',
    de: 'Zusätzliche Ausbildung',
  },
  lawyerCVPoints7: {
    ua: 'Здобутки',
    en: 'Achievements',
    de: 'Errungenschaften',
  },
  lawyerCVPoints8: {
    ua: 'Судова практика',
    en: 'Court Practice',
    de: 'Gerichtspraxis',
  },
  successfulCases: {
    ua: 'Виграних справ',
    en: 'Successful Cases',
    de: 'Erfolgreiche Fälle',
  },
  consultationsCounter: {
    ua: 'Консультацій',
    en: 'Consultations',
    de: 'Beratungen',
  },

  // PartnerHome   Page

  balanceName: {
    ua: 'Внесено номерів:',
    en: 'Numbers added:',
    de: 'Nummern hinzugefügt:',
  },
  clientButtonText: {
    ua: 'Список запрошень',
    en: 'Invitation list',
    de: 'Einladungsliste',
  },
  inviteShareLinkText: {
    ua: 'Рекомендуйте нас другу та отримайте 300 грн. та бонуси разом з ним!',
    en: 'Recommend us to a friend and get 300 UAH and bonuses together with him!',
    de: 'Empfehlen Sie uns einem Freund und erhalten Sie 300 UAH und Boni zusammen mit ihm!',
  },
  inviteShareLinkDescription: {
    ua: 'Якщо він перейде за посиланням і запишеться на консультацію, ви обидва отримаєте по 300 бонусів! А ще – чим більше друзів ви запросите, тим більше винагород зможете отримати. Це безпрограшний варіант і для вас, і для ваших друзів!',
    en: "If they follow the link and sign up for a consultation, both of you will receive 300 bonus points! Plus, the more friends you invite, the more rewards you can earn. It's a win-win for you and your friends!",
    de: 'Wenn er dem Link folgt und sich für eine Beratung anmeldet, erhaltet ihr beide 300 Bonuspunkte! Außerdem: Je mehr Freunde du einlädst, desto mehr Belohnungen kannst du verdienen. Eine echte Win-Win-Situation für dich und deine Freunde!',
  },

  // TransactionsButton
  transactionsBonuses: {
    ua: 'Винагорода за запрошення',
    en: 'Referral Rewards',
    de: 'Empfehlungsprämien',
  },
  transactionsBonusesValue: {
    ua: 'залишок:',
    en: 'balance:',
    de: 'Saldo:',
  },
  noTransactions: {
    ua: 'Транзакції відсутні',
    en: 'No transactions',
    de: 'Keine Transaktionen',
  },
  showAll: {
    ua: 'Показати усі',
    en: 'Show all',
    de: 'Alle anzeigen',
  },
  // Invite
  inviteTitle: {
    ua: 'Запросити клієнта',
    en: 'Invite client',
    de: 'Kunden einladen',
  },
  inviteTitle1: {
    ua: 'Залишок запрошень на сьогодні:',
    en: 'Remaining invitations for today:',
    de: 'Verbleibende Einladungen für heute:',
  },
  phoneRule1: {
    ua: 'Введіть номер телефону!',
    en: 'Enter phone number!',
    de: 'Geben Sie die Telefonnummer ein!',
  },
  phoneRule2: {
    ua: 'Номер телефону повинен містити тільки 10 цифр та починатися з 0!',
    en: 'The phone number must contain only 10 digits and start with 0!',
    de: 'Die Telefonnummer muss nur 10 Ziffern enthalten und mit 0 beginnen!',
  },
  // PaymentCard
  paymentCardinfo: {
    ua: 'Ця карта вже прив`язана!',
    en: 'This card is already linked!',
    de: 'Diese Karte ist bereits verknüpft!',
  },
  paymentCardSuccess1: {
    ua: 'Карту успішно',
    en: 'Card successfully',
    de: 'Karte erfolgreich',
  },
  paymentCardSuccess2: {
    ua: 'оновлено',
    en: 'updated',
    de: 'aktualisiert',
  },
  paymentCardSuccess3: {
    ua: 'додано',
    en: 'added',
    de: 'hinzugefügt',
  },
  paymentCardUpdate: {
    ua: 'Оновити',
    en: 'Update',
    de: 'Aktualisieren',
  },
  paymentCardAdd: {
    ua: 'Додати',
    en: 'Add',
    de: 'Hinzufügen',
  },
  numbers: {
    ua: 'цифри',
    en: 'digits',
    de: 'Zahlen',
  },
  paymentCardTitle1: {
    ua: 'Карта для виплат',
    en: 'Payment Card',
    de: 'Zahlungskarte',
  },
  paymentCardTitle2: {
    ua: 'Поточна карта: ',
    en: 'Current card: ',
    de: 'Aktuelle Karte: ',
  },
  paymentCardTitle3: {
    ua: 'Додайте карту для виплат',
    en: 'Add a payment card',
    de: 'Fügen Sie eine Zahlungskarte hinzu',
  },
  card: {
    ua: 'карту',
    en: 'card',
    de: 'Karte',
  },

  // PartnerHome - Rules
  rules: {
    ua: 'Переглянути правила',
    en: 'review the rules',
    de: 'Überprüfung der Regeln',
  },
  partnerHomeRulesTitle: {
    ua: 'Партнерська програма АО «Захист»',
    en: 'Partner Program of AO "Zaхyst"',
    de: 'Partnerprogramm der AO "Zaхyst"',
  },
  partnerHomeRulesText1: {
    ua: 'Адвокатське об`єднання «Захист»',
    en: 'Lawyers Association "Zaхyst"',
    de: 'Anwaltsvereinigung "Zaхyst"',
  },
  partnerHomeRulesText2: {
    ua: 'найбільша юридична компанія України.',
    en: 'the largest legal company in Ukraine.',
    de: 'die größte juristische Firma in der Ukraine.',
  },
  partnerHomeRulesText3: {
    ua: 'Ми маємо дуже потужну розгалужену систему територіальних відділень. Наші офіси знаходяться у кожному обласному центрі, а в деяких регіонах, навіть в провінційних містах та селах.',
    en: 'We have a very powerful network of territorial branches. Our offices are located in every regional center, and in some regions, even in provincial towns and villages.',
    de: 'Wir haben ein sehr leistungsstarkes Netzwerk territorialer Filialen. Unsere Büros befinden sich in jedem Regionalzentrum und in einigen Regionen sogar in Provinzstädten und Dörfern.',
  },
  partnerHomeRulesText4: {
    ua: 'Головний офіс компанії знаходиться у м. Одеса, за адресою:',
    en: 'The main office of the company is located in the city of Odessa, at the address:',
    de: 'Der Hauptsitz des Unternehmens befindet sich in der Stadt Odessa, unter folgender Adresse:',
  },
  partnerHomeRulesText5: {
    ua: 'вул. Мала Арнаутська, 71, офіс № 6.',
    en: 'Malaya Arnautskaya St., 71, office № 6.',
    de: 'Malaya Arnautskaya Str., 71, Büro Nr. 6.',
  },
  partnerHomeRulesText6: {
    ua: 'Так само, як Ви, ми зацікавлені в постійному розвитку, зокрема шляхом залучення та обслуговування нових Клієнтів, тому ми',
    en: 'Just like you, we are interested in constant development, including by attracting and serving new Clients, so we',
    de: 'Genau wie Sie sind wir an ständiger Entwicklung interessiert, auch durch Gewinnung und Betreuung neuer Kunden, deshalb bieten wir Ihnen',
  },
  partnerHomeRulesText7: {
    ua: 'пропонуємо Вам стати нашим Партнером',
    en: 'offer you to become our Partner',
    de: 'bieten wir Ihnen an, unser Partner zu werden',
  },
  partnerHomeRulesText8: {
    ua: 'у цьому напрямку.',
    en: 'in this direction.',
    de: 'in diese Richtung.',
  },
  partnerHomeRulesText9: {
    ua: 'Суть Партнерської програми АО «Захист»',
    en: 'The essence of the Partner Program of AO "Zaхyst"',
    de: 'Die Essenz des Partnerprogramms der AO "Zaхyst"',
  },
  partnerHomeRulesText10: {
    ua: 'Партнер АО «Захист»',
    en: 'Partner of AO "Zaхyst"',
    de: 'Partner der AO "Zaхyst"',
  },
  partnerHomeRulesText11: {
    ua: 'це людина, яка рекомендує нашу компанію іншим особам для вирішення своїх юридичних проблем, та отримує за це грошову винагороду у сумі 300 грн. за залучення одного Клієнта.',
    en: 'this is a person who recommends our company to other individuals to solve their legal problems and receives a monetary reward of 300 UAH for referring one Client.',
    de: 'dies ist eine Person, die unser Unternehmen anderen Personen empfiehlt, um ihre rechtlichen Probleme zu lösen, und eine finanzielle Belohnung von 300 UAH für die Vermittlung eines Kunden erhält.',
  },
  partnerHomeRulesText12: {
    ua: 'Партнерський Клієнт',
    en: 'Partner Client',
    de: 'Partnerkunde',
  },
  partnerHomeRulesText13: {
    ua: 'людина, яка звернулася до АО «Захист» за рекомендацією Партнера. Партнерський Клієнт також отримує 300 грн. на свій бонусний рахунок.',
    en: 'a person who has contacted AO "Zaхyst" on the recommendation of a Partner. The Partner Client also receives 300 UAH to their bonus account.',
    de: 'eine Person, die sich auf Empfehlung eines Partners an AO "Zaхyst" gewandt hat. Der Partnerkunde erhält ebenfalls 300 UAH auf sein Bonuskonto.',
  },
  partnerHomeRulesText14: {
    ua: 'Як працює Партнерська програма?',
    en: 'How does the Partner Program work?',
    de: 'Wie funktioniert das Partnerprogramm?',
  },
  partnerHomeRulesText15: {
    ua: 'Рекомендуйте будь-якій особі звернутися до нашої компанії та',
    en: 'Recommend to anyone to contact our company and',
    de: 'Empfehlen Sie jedem, sich an unser Unternehmen zu wenden und',
  },
  partnerHomeRulesText16: {
    ua: 'додавайте номер телефону',
    en: 'add the phone number',
    de: 'fügen Sie die Telefonnummer hinzu',
  },
  partnerHomeRulesText17: {
    ua: 'цієї людини у свій особистий кабінет натиснувши на цю кнопку:',
    en: 'of this person to your personal account by clicking this button:',
    de: 'diese Person in Ihr persönliches Konto hinzufügen, indem Sie auf diese Schaltfläche klicken:',
  },
  partnerHomeRulesText18: {
    ua: 'Щоб переглянути список усіх доданих Вами номерів, натисніть цю кнопку:',
    en: 'To view the list of all phone numbers you have added, click this button:',
    de: 'Um die Liste aller von Ihnen hinzugefügten Telefonnummern anzuzeigen, klicken Sie auf diese Schaltfläche:',
  },
  partnerHomeRulesText19: {
    ua: 'Коли Партнерський Клієнт, звертається до нашої компанії,',
    en: 'When the Partner Client contacts our company,',
    de: 'Wenn sich der Partnerkunde an unser Unternehmen wendet,',
  },
  partnerHomeRulesText20: {
    ua: 'Ви автоматично отримаєте винагороду у сумі 300 гривень',
    en: 'you will automatically receive a reward of 300 hryvnias',
    de: 'Sie erhalten automatisch eine Belohnung von 300 Hrywnja',
  },
  partnerHomeRulesText21: {
    ua: 'на свій баланс;',
    en: 'to your balance;',
    de: 'auf Ihr Gleichgewicht;',
  },
  partnerHomeRulesText22: {
    ua: 'Натисніть на кнопку меню, оберіть опцію «Прив`язка банківської картки» та заповніть реквізити для виведення грошових коштів',
    en: 'Click on the menu button, select the "Linking a bank card" option, and fill in the details to withdraw funds',
    de: 'Klicken Sie auf die Menütaste, wählen Sie die Option "Verknüpfung einer Bankkarte" und füllen Sie die Details aus, um Geld abzuheben',
  },
  partnerHomeRulesText23: {
    ua: 'Ви можете',
    en: 'You can',
    de: 'Sie können',
  },
  partnerHomeRulesText24: {
    ua: 'вивести гроші',
    en: 'withdraw money',
    de: 'Geld abheben',
  },
  partnerHomeRulesText25: {
    ua: 'з балансу свого кабінету на будь-яку банківську картку коли завгодно, натисканням цю кнопку:',
    en: 'from the balance of your account to any bank card at any time, by clicking this button:',
    de: 'von Ihrem Kontostand auf jede Bankkarte jederzeit, indem Sie diese Schaltfläche drücken:',
  },
  partnerHomeRulesText26: {
    ua: 'Умови зарахування винагороди на баланс в особистому кабінеті:',
    en: 'Conditions for crediting the reward to the balance in the personal account:',
    de: 'Bedingungen für die Gutschrift der Belohnung auf dem Guthaben im persönlichen Konto:',
  },
  partnerHomeRulesText27: {
    ua: 'Для зарахування винагороди на баланс особистого кабінету Партнера, необхідно',
    en: "To credit the reward to the balance of the Partner's personal account, it is necessary",
    de: 'Um die Belohnung auf das Guthaben des persönlichen Kontos des Partners zu buchen, ist es erforderlich,',
  },
  partnerHomeRulesText28: {
    ua: 'виконання таких умов:',
    en: 'to fulfill the following conditions:',
    de: 'die folgenden Bedingungen zu erfüllen:',
  },
  partnerHomeRulesText29: {
    ua: 'Внесення номеру телефона людини в особистий кабінет',
    en: "Entering a person's phone number into the personal account",
    de: 'Eingabe der Telefonnummer einer Person in das persönliche Konto',
  },
  partnerHomeRulesText30: {
    ua: 'раніше',
    en: 'previously',
    de: 'zuvor',
  },
  partnerHomeRulesText31: {
    ua: ', ніж ця людина звернеться до нашої компанії;',
    en: ', before this person contacts our company;',
    de: ', bevor diese Person sich an unser Unternehmen wendet;',
  },
  partnerHomeRulesText32: {
    ua: 'При зверненні до нашої компанії, людина проходить анкетування на предмет того, як вона дізналася про нас, на що вона повинна зазначити, що',
    en: 'When contacting our company, the person undergoes a survey about how they learned about us, on which they should indicate that',
    de: 'Bei der Kontaktaufnahme mit unserem Unternehmen durchläuft die Person eine Umfrage darüber, wie sie von uns erfahren hat, worauf sie angeben sollte, dass',
  },
  partnerHomeRulesText33: {
    ua: 'звернулася за рекомендацією',
    en: 'turned for recommendation',
    de: 'sich auf Empfehlung wandte',
  },
  partnerHomeRulesText34: {
    ua: 'Якщо один і той самий номер телефону буде внесений декількома Партнерами, винагороду отримає той із Партнерів, який вніс телефон людини в свій особистий кабінет',
    en: "If the same phone number is entered by several Partners, the reward will be received by the Partner who entered the person's phone number into their personal account",
    de: 'Wenn dieselbe Telefonnummer von mehreren Partnern eingegeben wird, erhält die Belohnung der Partner, der die Telefonnummer der Person in sein persönliches Konto eingegeben hat',
  },
  partnerHomeRulesText35: {
    ua: 'останній',
    en: 'the last',
    de: 'der letzte',
  },
  partnerHomeRulesText36: {
    ua: 'Після Вашої рекомендації та внесення номеру телефону, людина повинна протягом',
    en: 'After your recommendation and entering the phone number, the person must within',
    de: 'Nach Ihrer Empfehlung und Eingabe der Telefonnummer muss die Person innerhalb von',
  },
  partnerHomeRulesText37: {
    ua: '2-х місяців',
    en: '2 months',
    de: '2 Monaten',
  },
  partnerHomeRulesText38: {
    ua: 'звернутися до нас;',
    en: 'contact us;',
    de: 'sich an uns wenden;',
  },
  partnerHomeRulesText39: {
    ua: 'При укладенні з нами договору про надання адвокатських послуг, сума оплати повинна бути',
    en: 'When concluding an agreement with us for the provision of legal services, the amount of payment must be',
    de: 'Bei Abschluss eines Vertrags mit uns über die Erbringung von Rechtsdienstleistungen muss der Zahlungsbetrag sein',
  },
  partnerHomeRulesText40: {
    ua: 'не менше 1000 грн',
    en: 'at least 1000 UAH',
    de: 'mindestens 1000 UAH',
  },
  partnerHomeRulesText41: {
    ua: 'Технічні обмеження при внесенні номерів телефону в особистий кабінет:',
    en: 'Technical limitations when adding phone numbers to the personal account:',
    de: 'Technische Einschränkungen beim Hinzufügen von Telefonnummern zum persönlichen Konto:',
  },
  partnerHomeRulesText42: {
    ua: 'Двічі',
    en: 'Twice',
    de: 'Zweimal',
  },
  partnerHomeRulesText43: {
    ua: 'вносити один і той самий номер телефону в особистий кабінет, технічно неможливо;',
    en: 'technically impossible to enter the same phone number into the personal account twice;',
    de: 'technisch unmöglich, dieselbe Telefonnummer zweimal in das persönliche Konto einzugeben;',
  },
  partnerHomeRulesText44: {
    ua: '2. Задля виключення маніпуляцій з телефонними базами даних, технічна можливість внесення телефонів в особистий кабінет Партнера',
    en: "2. In order to prevent manipulations with telephone databases, the technical possibility of entering phones into the Partner's personal account",
    de: '2. Um Manipulationen mit Telefonverzeichnissen zu verhindern, besteht die technische Möglichkeit, Telefone in das persönliche Konto des Partners einzugeben',
  },
  partnerHomeRulesText45: {
    ua: 'Обмежена п`ятьма номерами на день.',
    en: 'Limited to five numbers per day.',
    de: 'Auf fünf Nummern pro Tag beschränkt.',
  },
  partnerHomeRulesText46: {
    ua: 'Переваги співпраці з нашою компанією на умовах Партнерства:',
    en: 'Advantages of cooperation with our company under Partnership conditions:',
    de: 'Vorteile der Zusammenarbeit mit unserem Unternehmen unter Partnerschaftsbedingungen:',
  },
  partnerHomeRulesText47: {
    ua: '1. Пасивний дохід.',
    en: '1. Passive income.',
    de: '1. Passives Einkommen.',
  },
  partnerHomeRulesText48: {
    ua: 'Ви гарантовано отримуєте винагороду у разі, якщо людина з Вашого списку звернеться до наc протягом 2-х місяців, після Вашої рекомендації.',
    en: 'You are guaranteed to receive a reward if a person from your list contacts us within 2 months after your recommendation.',
    de: 'Sie erhalten garantiert eine Belohnung, wenn eine Person aus Ihrer Liste uns innerhalb von 2 Monaten nach Ihrer Empfehlung kontaktiert.',
  },
  partnerHomeRulesText49: {
    ua: '2. Легкий та зручний інтерфейс особистого кабінету.',
    en: '2. Easy and convenient personal account interface.',
    de: '2. Einfache und bequeme Benutzeroberfläche des persönlichen Kontos.',
  },
  partnerHomeRulesText50: {
    ua: 'Ми доклали величезних зусиль для того, щоб нашим Партнерам було якомога комфортніше співпрацювати з нами. Ми прибрали усе зайве, та залишили лише найголовніший інструментарій. Проте, якщо у Ваc все ж виникнуть технічні проблеми, наші спеціалісти усе миттєво виправлять.',
    en: 'We have made tremendous efforts to make it as comfortable as possible for our Partners to work with us. We have removed all unnecessary things, leaving only the most essential tools. However, if you still encounter technical problems, our specialists will fix them immediately.',
    de: 'Wir haben enorme Anstrengungen unternommen, um es unseren Partnern so bequem wie möglich zu machen, mit uns zusammenzuarbeiten. Wir haben alle unnötigen Dinge entfernt und nur die wichtigsten Werkzeuge zurückgelassen. Wenn Sie jedoch immer noch auf technische Probleme stoßen, werden unsere Spezialisten sie sofort beheben.',
  },
  partnerHomeRulesText51: {
    ua: '3. Конфіденційність.',
    en: '3. Confidentiality.',
    de: '3. Vertraulichkeit.',
  },
  partnerHomeRulesText52: {
    ua: 'Політика нашої компанії категорично забороняє розголошення будь-яких даних про партнерську співпрацю. Особливу увагу ми приділяємо не розголошенню інформації, щодо винагороди Партнера перед Партнерським Клієнтом.',
    en: "Our company's policy strictly prohibits disclosing any data about partnership cooperation. We pay special attention not to disclose information regarding the Partner's reward to the Partner Client.",
    de: 'Die Politik unseres Unternehmens verbietet streng die Offenlegung aller Daten zur Partnerschaftszusammenarbeit. Wir achten besonders darauf, keine Informationen über die Belohnung des Partners an den Partnerkunden preiszugeben.',
  },
  partnerHomeRulesText53: {
    ua: '4. Взаємна вигода.',
    en: '4. Mutual benefit.',
    de: '4. Gegenseitiger Nutzen.',
  },
  partnerHomeRulesText54: {
    ua: 'Партнерський Клієнт також отримує 300 гривень бонусів, які він може витратити на оплату послуг адвоката.',
    en: 'The Partner Client also receives 300 hryvnias of bonuses, which he can spend on paying for legal services.',
    de: 'Der Partnerkunde erhält ebenfalls 300 Hrywnja Boni, die er für die Bezahlung von Rechtsdienstleistungen ausgeben kann.',
  },
  partnerHomeRulesText55: {
    ua: '5. Особливе відношення.',
    en: '5. Special treatment.',
    de: '5. Besondere Behandlung.',
  },
  partnerHomeRulesText56: {
    ua: 'Дуже часто у наших Партнерів виникають юридичні проблеми, для вирішення яких вони звертаються до нас, як до фахівців в області права. Загалом, політика обслуговування Клієнтів в нашій компанії забороняє будь-яке особливе ставлення. Усі Клієнти повинні обслуговуватися якісно, а їх проблеми мають бути вирішені в найкоротший строк. Проте до обслуговування наших Партнерів ми підходимо з особливою увагою. Для нас Партнер є VIP Клієнтом, а його справа завжди в пріоритеті та під особливим контролем.',
    en: "Our partners often face legal problems, which they turn to us for as experts in the field of law. In general, our company's customer service policy prohibits any special treatment. All clients should be served with quality, and their problems should be resolved as quickly as possible. However, we treat our partners with special attention. For us, a partner is a VIP client, and their case is always a priority and under special control.",
    de: 'Unsere Partner haben oft rechtliche Probleme, wegen derer sie sich als Experten auf dem Gebiet des Rechts an uns wenden. Im Allgemeinen verbietet die Kundendienstrichtlinie unseres Unternehmens jede Sonderbehandlung. Alle Kunden sollten qualitativ bedient werden, und ihre Probleme sollten so schnell wie möglich gelöst werden. Wir behandeln unsere Partner jedoch mit besonderer Aufmerksamkeit. Für uns ist ein Partner ein VIP-Kunde, und sein Fall hat immer Vorrang und steht unter besonderer Kontrolle.',
  },
  partnerHomeRulesText57: {
    ua: 'За детальною інформацією телефонуйте на номер гарячої лінії',
    en: 'For detailed information, call the hotline number.',
    de: 'Für detaillierte Informationen rufen Sie die Hotline-Nummer an.',
  },
  agreeRulesBtn: {
    ua: 'Погоджуюсь з правилами',
    en: 'I agree with the rules',
    de: 'Ich stimme den Regeln zu',
  },

  // RedButton      Page
  buyRedButtonSuccess: {
    ua: 'тривожну кнопку придбано',
    en: 'Panic button purchased',
    de: 'Panikknopf gekauft',
  },
  buyRedButtonTitle: {
    ua: 'Купити тривожну кнопку',
    en: 'Buy panic button',
    de: 'Panikknopf kaufen',
  },
  activeRedButtonTitle: {
    ua: 'Послуга "Тривожна кнопка" підключена!',
    en: 'The "Panic Button" service is connected!',
    de: 'Der "Panikknopf"-Dienst ist verbunden!',
  },
  cost: {
    ua: 'Вартість',
    en: 'Cost',
    de: 'Kosten',
  },
  buyBtn: {
    ua: 'Придбати',
    en: 'Buy',
    de: 'Kaufen',
  },
  redButtonText: {
    ua: 'Після придбання Тривожної кнопки буде підключена автооплата, щомісячний платіж складатиме 911. Кешбек за автооплату',
    en: 'After purchasing the Panic button, auto-payment will be enabled, the monthly payment will be 911. Cashback for auto-payment',
    de: 'Nach dem Kauf der Panikschaltfläche wird die Autozahlung aktiviert, die monatliche Zahlung beträgt 911. Cashback für die Autozahlung',
  },
  redButtonDescription: {
    ua: 'Відчуйте захист з додатком єАдвокат. Функція «Тривожна кнопка». Миттєва юридична допомога у разі порушення Ваших прав співробітниками: поліції, ТЦК, прокуратури, працівниками інших органів. Один клік і Ви під захистом.',
    en: 'Feel protected with the eAdvocate app. Panic button function. Instant legal assistance in case of violation of your rights by employees of the police, prosecutors, and other authorities. One click and you are protected.',
    de: 'Fühlen Sie sich mit der eAdvocate App geschützt. Funktion der Panik-Taste. Sofortige Rechtshilfe bei Verletzung Ihrer Rechte durch Mitarbeiter: Polizei, Staatsanwaltschaft, Mitarbeiter anderer Behörden. Ein Klick und Sie sind geschützt.',
  },
  redButtonInfoTitle1: {
    ua: 'Вартість послуги:',
    en: 'The cost of the service:',
    de: 'Die Kosten des Dienstes:',
  },
  redButtonInfoTitle2: {
    ua: 'Що Ви отримуєте?',
    en: 'What do you get?',
    de: 'Was bekommen Sie?',
  },
  redButtonInfoList1_1: {
    ua: 'Вартість підключення:',
    en: 'The cost of connection:',
    de: 'The cost of connection:',
  },
  redButtonInfoList1_2: {
    ua: 'Вартість обслуговування: 911 грн/місяць',
    en: 'The cost of service: 911 USD/month',
    de: 'Die Kosten der Dienstleistung: 911 EUR/Monat',
  },
  redButtonInfoList2_1: {
    ua: 'Миттєву юридичну допомогу у разі протиправних дій з боку органів державної влади',
    en: 'Instant legal assistance in case of unlawful actions by public authorities',
    de: 'Sofortiger Rechtsbeistand bei rechtswidrigen Handlungen von Behörden',
  },
  redButtonInfoList2_2: {
    ua: 'Цілодобову юридичну підтримку (чат з професійним адвокатом)',
    en: 'Round-the-clock legal support (chat with a professional lawyer)',
    de: 'Rechtliche Unterstützung rund um die Uhr (Chat mit einem Fachanwalt)',
  },
  redButtonInfoList2_3: {
    ua: 'Безкоштовні юридичні консультації з приводу дій співробітників поліції, ТЦК, прокуратури чи інших держустанов',
    en: 'Free legal advice on the actions of police officers, prosecutors or other government agencies',
    de: 'Kostenlose Rechtsberatung zu Handlungen von Polizeibeamten, Staatsanwälten oder anderen staatlichen Institutionen',
  },
  redButtonInfoList2_4: {
    ua: 'Правовий аналіз документів в режимі online',
    en: 'Legal analysis of documents online',
    de: 'Rechtliche Analyse von Dokumenten online',
  },
  redBtnModalTitle: {
    ua: 'Додавання кредитної карти',
    en: 'Adding a credit card',
    de: 'Hinzufügen einer Kreditkarte',
  },

  redBtnModalText1: {
    ua: 'Тепер ви можете купити тривожну кнопку',
    en: 'Now you can buy the panic button',
    de: 'Jetzt können Sie die Panikschaltfläche kaufen',
  },
  redBtnModalText2: {
    ua: 'Для придбання тривожної кнопки необхідно додати карту',
    en: 'To purchase the panic button, you need to add a card',
    de: 'Um die Panikschaltfläche zu kaufen, müssen Sie eine Karte hinzufügen',
  },
  popconfirmTitle: {
    ua: 'Бажаєте додати карту?',
    en: 'Do you want to add a card?',
    de: 'Möchten Sie eine Karte hinzufügen?',
  },
  popconfirmText1: {
    ua: 'Буде проведено оплату на 1грн.',
    en: 'A payment of 1 UAH will be made.',
    de: 'Es wird eine Zahlung von 1 UAH geleistet.',
  },
  popconfirmText2: {
    ua: 'Кошти повернуться назад.',
    en: 'Funds will be returned.',
    de: 'Die Mittel werden zurückerstattet.',
  },
  yes: {
    ua: 'Так',
    en: 'Yes',
    de: 'Ja',
  },
  no: {
    ua: 'Ні',
    en: 'No',
    de: 'Nein',
  },

  // COMPOMENTS
  // AppSuggestionNotification
  alertNotificationButtonLabel: {
    ua: 'Відкрити',
    en: 'Open',
    de: 'Öffnen',
  },
  alertNotificationTitle: {
    ua: 'єАдвокат - справа в смартфоні',
    en: 'eLawyer - case in smartphone',
    de: 'eAnwalt - Fall im Smartphone',
  },
  alertNotificationText: {
    ua: 'Юридичні консультації з будь-яких питань',
    en: 'Legal consultations on any questions',
    de: 'Rechtsberatung zu allen Fragen',
  },
  // BalanceNew
  partnershipBalance: {
    ua: 'Партнерський баланс',
    en: 'Partnership balance',
    de: 'Partnerschaftsguthaben',
  },
  account: {
    ua: 'Рахунок',
    en: 'Account',
    de: 'Konto',
  },
  // CardForPayments
  cardForPaymentsSuccess: {
    ua: 'Платіжний засіб видалено.',
    en: 'Payment method deleted.',
    de: 'Zahlungsmethode gelöscht.',
  },
  cardForPaymentsError: {
    ua: 'Не вдалось видалити карту. Спробуйте пізніше.',
    en: 'Failed to delete the card. Please try again later.',
    de: 'Karte konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut.',
  },
  cardIsInvalid: {
    ua: 'Карта не дійсна.',
    en: 'The card is invalid.',
    de: 'Die Karte ist ungültig.',
  },
  bindtheCard: {
    ua: 'Прив`язати карту',
    en: 'Bind the card',
    de: 'Karte verknüpfen',
  },
  popconfirmRemoveTitle: {
    ua: 'Видалення платіжного засобу',
    en: 'Remove payment method',
    de: 'Zahlungsmittel entfernen',
  },
  popconfirmRemoveDescription: {
    ua: 'Впевнені, що хочете видалити карту?',
    en: 'Are you sure you want to delete the card?',
    de: 'Möchten Sie die Karte wirklich entfernen?',
  },
  // Chat
  sendingFileError: {
    ua: 'Помилка при відправці файлу',
    en: 'Error sending file',
    de: 'Fehler beim Senden der Datei',
  },
  // Client
  // Client-InsuranceTransactions
  paid: {
    ua: 'сплачено:',
    en: 'paid:',
    de: 'bezahlt:',
  },
  // ClientMenu
  // ClientMenu - ClientBalancePayModal
  ClientBPMSuccess1: {
    ua: 'Подяка юристу відправлена',
    en: 'Thank you message sent to the lawyer',
    de: 'Dankesnachricht an den Anwalt gesendet',
  },
  ClientBPMSuccess2: {
    ua: 'Баланс поповнено',
    en: 'Balance replenished',
    de: 'Kontostand aufgefüllt',
  },
  // ClientMenu - PayRedButtonDebt
  payRedButtonDebtSuccess: {
    ua: 'Платіж за Тривожну кнопку успішно проведено',
    en: 'Payment for the Panic Button successfully made',
    de: 'Zahlung für die Paniktaste erfolgreich durchgeführt',
  },
  payRedButtonDebtError: {
    ua: 'На рахунку не достатньо коштів',
    en: 'Insufficient funds in the account',
    de: 'Nicht genügend Geld auf dem Konto',
  },
  // ConfirmChangePhone
  success: {
    ua: 'Успіх!',
    en: 'Success!',
    de: 'Erfolg!',
  },
  confirmChangePhoneSuccess1: {
    ua: 'Телефон підтверджено',
    en: 'Phone confirmed',
    de: 'Telefon bestätigt',
  },
  confirmChangePhoneWarn1: {
    ua: 'Не вдалося отримати підтвердження телефону!',
    en: 'Failed to receive phone confirmation!',
    de: 'Telefonbestätigung fehlgeschlagen!',
  },
  confirmChangePhoneWarn2: {
    ua: 'Не вірний код!',
    en: 'Incorrect code!',
    de: 'Falscher Code!',
  },
  confirmChangePhoneWarn3: {
    ua: 'Цей номер телефону вже зареєстрований!',
    en: 'This phone number is already registered!',
    de: 'Diese Telefonnummer ist bereits registriert!',
  },
  confirmChangePhoneSuccess2: {
    ua: 'Код відправлено!',
    en: 'Code sent!',
    de: 'Code gesendet!',
  },
  confirmChangePhoneWarn4: {
    ua: 'Помилка',
    en: 'Error',
    de: 'Fehler',
  },
  confirmChangePhoneRules1: {
    ua: 'Введіть код',
    en: 'Enter code',
    de: 'Code eingeben',
  },
  confirmChangePhoneRules2: {
    ua: '6 символів',
    en: '6 characters',
    de: '6 Zeichen',
  },
  inAMinute: {
    ua: 'через',
    en: 'in',
    de: 'in',
  },
  second: {
    ua: 'с',
    en: 's',
    de: 's',
  },
  confirmChangePhoneRules3: {
    ua: 'Введіть номер телефону в форматі 0ХХХХХХХХ',
    en: 'Enter phone number in the format 0XXXXXXXXX',
    de: 'Geben Sie die Telefonnummer im Format 0XXXXXXXXX ein',
  },
  confirmChangePhoneRules4: {
    ua: 'Введіть ваш номер телефону',
    en: 'Enter your phone number',
    de: 'Geben Sie Ihre Telefonnummer ein',
  },
  // GoogleMap
  googleMapTitle: {
    ua: 'Дорога до філії',
    en: 'Directions to Branch',
    de: 'Weg zur Filiale',
  },
  // HotlineMeetInvite --------!!!!!!!!!!!!!!
  constHotlineMeetInviteText1_1: {
    ua: 'Дистанційну',
    en: 'Remote',
    de: 'Fern',
  },
  constHotlineMeetInviteText1_2: {
    ua: '«Преміум консультацію»',
    en: '"Premium consultation"',
    de: '"Premium-Beratung"',
  },
  constHotlineMeetInviteText1_3: {
    ua: 'консультацію',
    en: 'consultation',
    de: 'Beratung',
  },
  constHotlineMeetInviteText1_4: {
    ua: 'у місті',
    en: 'in the city',
    de: 'in der Stadt',
  },
  constHotlineMeetInviteText2_1: {
    ua: 'Досвідчений фаховий адвокат',
    en: 'Experienced professional lawyer',
    de: 'Erfahrener Fachanwalt',
  },
  constHotlineMeetInviteText2_2: {
    ua: 'Юрист відділу',
    en: 'Department lawyer',
    de: 'Abteilungsanwalt',
  },
  constHotlineMeetInviteText2_3: {
    ua: 'очікує Вас',
    en: 'is waiting for you',
    de: 'wartet auf Sie',
  },
  constHotlineMeetInviteText2_4: {
    ua: 'радо надасть Вам',
    en: 'will gladly provide you with',
    de: 'stellt Ihnen gerne zur Verfügung',
  },
  constHotlineMeetInviteTitle: {
    ua: 'Адвокатського об’єднання «Захист»',
    en: 'Law firm "Defense"',
    de: 'Rechtsanwaltskanzlei "Verteidigung"',
  },
  constHotlineMeetInviteCVTitle: {
    ua: 'Переглянути портфоліо',
    en: 'View CV',
    de: 'Lebenslauf ansehen',
  },
  constHotlineMeetInviteCV1: {
    ua: 'адвоката',
    en: 'lawyer',
    de: 'Anwalt',
  },
  constHotlineMeetInviteCV2: {
    ua: 'юриста відділу',
    en: 'department lawyer',
    de: 'Abteilungsanwalt',
  },
  on: {
    ua: 'на',
    en: 'on',
    de: 'auf',
  },
  thanksForTheOpal: {
    ua: 'Дякуємо за оплату!',
    en: 'Thank you for the payment!',
    de: 'Danke für die Zahlung!',
  },
  hotlineMeetInviteCardTitle: {
    ua: 'НАПРАВЛЕННЯ',
    en: 'REFERRAL',
    de: 'WEITERLEITUNG',
  },
  hotlineMeetInviteCardText1: {
    ua: 'на прийом до адвоката',
    en: 'for appointment with a lawyer',
    de: 'für einen Termin beim Anwalt',
  },
  hotlineMeetInviteCardText2: {
    ua: 'від',
    en: 'from',
    de: 'von',
  },
  hotlineMeetInviteCardText3: {
    ua: 'У разі, якщо у Вас виникли невідкладні справи, у зв’язку з чим необхідно перенести запис на інший час, просимо попередити про це завчасно. Так само ми проінформуємо Вас про будь-які зміни у Вашому записі.',
    en: 'In case you have urgent matters and need to reschedule your appointment, please inform us in advance. We will also inform you about any changes to your appointment.',
    de: 'Im Falle von dringenden Angelegenheiten, bei denen Sie Ihren Termin verschieben müssen, informieren Sie uns bitte im Voraus. Wir informieren Sie auch über etwaige Änderungen an Ihrem Termin.',
  },
  hotlineMeetInviteCardText4: {
    ua: 'ПРИ НАЯВНОСТІ, візьміть з собою:',
    en: 'IF AVAILABLE, please bring with you:',
    de: 'WENN VERFÜGBAR, bringen Sie bitte mit:',
  },
  hotlineMeetInviteCardText5: {
    ua: 'посвідчення особи;',
    en: 'ID card;',
    de: 'Ausweis;',
  },
  hotlineMeetInviteCardText6: {
    ua: 'документи по справі.',
    en: 'documents related to the case.',
    de: 'Dokumente zum Fall.',
  },
  hotlineMeetInviteCardNiceBtn1: {
    ua: 'Анкетування після консультації',
    en: 'Post-consultation survey',
    de: 'Umfrage nach der Beratung',
  },
  hotlineMeetInviteCardNiceBtn2: {
    ua: 'Подарунок',
    en: 'Gift',
    de: 'Geschenk',
  },
  hotlineMeetInviteCardMeetData1: {
    ua: 'З метою підвищення якості обслуговування, просимо після консультації клацнути на кнопку "Анкетування після консультації" та відповісти на декілька запитань. Ваша думка буде дуже важливою для нас.',
    en: 'In order to improve the quality of service, we kindly ask you to click on the "Post-consultation survey" button after the consultation and answer a few questions. Your opinion will be very important to us.',
    de: 'Um die Servicequalität zu verbessern, bitten wir Sie, nach der Beratung auf die Schaltfläche "Nachberatungsumfrage" zu klicken und einige Fragen zu beantworten. Ihre Meinung ist uns sehr wichtig.',
  },
  hotlineMeetInviteCardMeetData2: {
    ua: 'Подарунок зможете отримати після консультації. Не забудьте клацнути тоді кнопку "Подарунок", зареєструватися, якщо ви у нас вперше, та пройти анкетування з пройденої консультації. Після анкетування, Вам буде подаровано сертифікат на 3 безкоштовні офісні консультації зі сімейних, кредитних, земельних правовідносин та ДТП. Цей сертифікат можна самому використати, або подарувати будь-якій іншій особі. Рідні, друзі, знайомі тощо.',
    en: 'You will receive a gift after the consultation. Don`t forget to click the "Gift" button, register if you are new to us, and complete the survey on the consultation you attended. After completing the survey, you will receive a certificate for 3 free office consultations on family, credit, land legal relations, and traffic accidents. This certificate can be used by yourself or given to any other person. Relatives, friends, acquaintances, etc.',
    de: 'Sie erhalten ein Geschenk nach der Beratung. Vergessen Sie nicht, auf die Schaltfläche "Geschenk" zu klicken, sich zu registrieren, wenn Sie neu bei uns sind, und die Umfrage zur besuchten Beratung abzuschließen. Nach Abschluss der Umfrage erhalten Sie einen Gutschein für 3 kostenlose Büroberatungen zu Familien-, Kredit-, Grundstücksrechts- und Verkehrsunfallfragen. Dieser Gutschein kann von Ihnen selbst verwendet oder an eine andere Person weitergegeben werden. Verwandte, Freunde, Bekannte usw.',
  },
  hotlineMeetInviteCardText7: {
    ua: 'Також, наразі у нас діє сезонна АКЦІЯ! При укладанні Договору, Ви гарантовано отримаєте 20% знижку на послуги Адвокатського об’єднання «Захист».',
    en: 'Also, we currently have a seasonal PROMOTION! When signing the Contract, you will receive a guaranteed 20% discount on the services of the "Protection" Law Firm.',
    de: 'Außerdem haben wir derzeit eine saisonale AKTION! Beim Abschluss des Vertrags erhalten Sie einen garantierten Rabatt von 20% auf die Dienstleistungen der Anwaltskanzlei "Schutz".',
  },
  hotlineMeetInviteCardText8: {
    ua: 'Приходьте до нас! Ми будемо раді пригостити Вас чаєм і надати відповіді на усі Ваші юридичні питання!',
    en: 'Come to us! We will be happy to treat you with tea and provide answers to all your legal questions!',
    de: 'Kommen Sie zu uns! Wir freuen uns, Sie mit Tee zu bewirten und Antworten auf alle Ihre rechtlichen Fragen zu geben!',
  },
  hotlineMeetInviteCardText9: {
    ua: 'Телефон гарячої лінії:',
    en: 'Hotline phone number:',
    de: 'Hotline-Telefonnummer:',
  },
  hotlineMeetInviteCardText10: {
    ua: 'Також можете написати нам через соцмережі:',
    en: 'You can also write to us via social networks:',
    de: 'Sie können uns auch über soziale Netzwerke schreiben:',
  },
  telegram: {
    ua: 'Телеграм',
    en: 'Telegram',
    de: 'Telegramm',
  },
  viber: {
    ua: 'Вайбер',
    en: 'Viber',
    de: 'Viber',
  },
  hotlineMeetInviteCardText11: {
    ua: 'Наш сайт:',
    en: 'Our website:',
    de: 'Unsere Website:',
  },
  hotlineMeetInviteCardText12: {
    ua: 'Тепер Ви можете користуватися своїм особистим кабінетом. Якщо Ви у нас вперше, то не забудьте зареєструватися в ньому. Ви можете використовувати його багатий функціонал зв`язаний з Вашим договором, записуватися на нові консультації, зв`язуватися швидко через нього з нами, або навіть самому заробляти використовуючи нашу партнерську програму.',
    en: "Now you can use your personal account. If you are new to us, don't forget to register in it. You can use its rich functionality related to your contract, sign up for new consultations, quickly communicate through it with us, or even earn money using our affiliate program.",
    de: 'Jetzt können Sie Ihr persönliches Konto verwenden. Wenn Sie neu bei uns sind, vergessen Sie nicht, sich dafür zu registrieren. Sie können seine umfangreichen Funktionen im Zusammenhang mit Ihrem Vertrag nutzen, sich für neue Beratungen anmelden, schnell über ihn mit uns kommunizieren oder sogar Geld verdienen, indem Sie unser Partnerprogramm nutzen.',
  },
  hotlineMeetInviteCardText13: {
    ua: 'Адреси головних відділень на карті:',
    en: 'Addresses of main branches on the map:',
    de: 'Adressen der Hauptfilialen auf der Karte:',
  },
  hotlineMeetInviteCardButton1: {
    ua: 'Помітити як «Відправлено»',
    en: 'Mark as "Sent"',
    de: 'Als "Gesendet" markieren',
  },
  hotlineMeetInviteCardButton2: {
    ua: 'Немає Вайберу',
    en: 'No Viber',
    de: 'Kein Viber',
  },
  // InvitedClients
  confirmed: {
    ua: 'підтверджено',
    en: 'confirmed',
    de: 'bestätigt',
  },
  notConfirmed: {
    ua: 'не підтверджено',
    en: 'not confirmed',
    de: 'nicht bestätigt',
  },
  // Partner-InvitesList
  partnerInvitesListTitle: {
    ua: 'Список запрошених клієнтів',
    en: 'List of Invited Clients',
    de: 'Liste der eingeladenen Kunden',
  },
  // Partner-PartnerButtons
  partnerPartnerButtonsList: {
    ua: 'Список',
    en: 'List',
    de: 'Liste',
  },
  partnerPartnerButtonsInvitations: {
    ua: 'запрошень',
    en: 'invitations',
    de: 'Einladungen',
  },
  partnerPartnerButtonsClient: {
    ua: 'клієнта',
    en: 'client',
    de: 'Kunde',
  },
  partnerPartnerButtonsPayment: {
    ua: 'виплату',
    en: 'payment',
    de: 'Zahlung',
  },
  // Partner-RequestPayment
  partnerRequestPaymentWarn1_1: {
    ua: 'Не вистачає бонусів!',
    en: 'Insufficient bonuses!',
    de: 'Nicht genügend Boni!',
  },
  partnerRequestPaymentWarn1_2: {
    ua: 'Перевищено на',
    en: 'Exceeded by',
    de: 'Überschritten um',
  },
  partnerRequestPaymentWarn2_1: {
    ua: 'Введіть коректне значення!',
    en: 'Enter a valid value!',
    de: 'Geben Sie einen gültigen Wert ein!',
  },
  partnerRequestPaymentWarn2_2: {
    ua: 'Сума не може бути від`ємною!',
    en: 'Amount cannot be negative!',
    de: 'Betrag kann nicht negativ sein!',
  },
  partnerRequestPaymentInfo: {
    ua: 'Запит на виплату успішно додано!',
    en: 'Payment request successfully added!',
    de: 'Auszahlungsanfrage erfolgreich hinzugefügt!',
  },
  partnerRequestPaymentWarn3: {
    ua: 'Помилка!',
    en: 'Error!',
    de: 'Fehler!',
  },
  partnerRequestPaymentModalTitle: {
    ua: 'Запросити виплату',
    en: 'Request Payment',
    de: 'Auszahlung anfordern',
  },
  partnerRequestPaymentModalText1: {
    ua: 'Карта для виплат:',
    en: 'Payment card:',
    de: 'Karte für Auszahlungen:',
  },
  partnerRequestPaymentModalText2: {
    ua: 'Додайте карту для виплат в меню партнера!',
    en: 'Add a payment card in the partner menu!',
    de: 'Fügen Sie eine Auszahlungskarte im Partnermenü hinzu!',
  },
  partnerRequestPaymentModalText3: {
    ua: 'Баланс:',
    en: 'Balance:',
    de: 'Saldo:',
  },
  partnerRequestPaymentModalMessage: {
    ua: 'Введіть значення в межах балансу',
    en: 'Enter a value within the balance',
    de: 'Geben Sie einen Wert innerhalb des Guthabens ein',
  },
  // SelectFilWithRole
  selectFilWithRoleTitle: {
    ua: 'Оберіть філію',
    en: 'Select Branch',
    de: 'Filiale auswählen',
  },
  allFiliaOptGroup: {
    ua: 'Всі філії',
    en: 'All Branches',
    de: 'Alle Filialen',
  },

  // SwiperInsurance
  // InsuranceType1: {
  //   ua: 'При ДТП',
  //   en: 'In case of car accident',
  //   de: 'Im Falle eines Autounfalls',
  // },
  // InsuranceType2: {
  //   ua: 'Військового',
  //   en: 'Military',
  //   de: 'Militär',
  // },
  // InsuranceType3: {
  //   ua: 'Моряка',
  //   en: 'Seaman',
  //   de: 'Seemann',
  // },
  // InsuranceType4: {
  //   ua: 'Призовника',
  //   en: 'Conscript',
  //   de: 'Wehrpflichtiger',
  // },
  buyInsuranceBtn: {
    ua: 'Купити страховку',
    en: 'Buy insurance',
    de: 'Versicherung kaufen',
  },
  // TimeLine
  timeLineText: {
    ua: '(Авто-оновлення даних)',
    en: '(Auto-update data)',
    de: '(Datenautomatisierung)',
  },

  //*!----------utils ---------
  // Loader
  loader: {
    ua: 'Зачекайте...',
    en: 'Please wait...',
    de: 'Bitte warten Sie...',
  },
  loader1: {
    ua: 'Завантаження...',
    en: 'Please wait...',
    de: 'Bitte warten Sie...',
  },
  saveChanges: {
    ua: 'Зберегти зміни',
    en: 'Save Changes',
    de: 'Änderungen speichern',
  },

  // EnterLink
  enterLink: {
    ua: 'Вхід',
    en: 'Login',
    de: 'Einloggen',
  },
  enter: {
    ua: 'Увійти',
    en: 'Enter',
    de: 'Eingeben',
  },

  // required field
  requiredField: {
    ua: "Обов'язкове поле",
    en: 'Required field',
    de: 'Erforderliches Feld',
  },

  // currency
  currency: {
    ua: 'грн',
    en: 'USD',
    de: 'EUR',
  },

  password: {
    ua: 'Пароль:',
    en: 'Password:',
    de: 'Passwort:',
  },

  // club card
  clubCardWarn_1: {
    ua: 'Ви вже користуєтесь тарифом',
    en: 'You are already using the tariff',
    de: 'Sie nutzen bereits den Tarif',
  },
  clubCardWarn_2: {
    ua: 'з аналогічним строком активності.',
    en: 'with a similar period of validity.',
    de: 'mit einer ähnlichen Gültigkeitsdauer.',
  },
  clubCardWarnText_1: {
    ua: 'Новий тариф почне діяти тільки після закінчення строку дії клубної карти по старому тарифу!',
    en: 'The new tariff will only take effect after the expiration of the current club card under the old tariff!',
    de: 'Der neue Tarif tritt erst nach Ablauf der aktuellen Clubkarte mit dem alten Tarif in Kraft!',
  },
  clubCardWarnText_2: {
    ua: 'А саме, з',
    en: 'Namely, from',
    de: 'Nämlich, ab',
  },
  clubCardWarnTextFreeCard: {
    ua: 'Безкоштовна карта',
    en: 'Free card',
    de: 'Kostenlose Karte',
  },

  clubCardTitleFront: {
    ua: 'Адвокатське об`єднання "Захист"',
    en: 'Law Firm "Zahist"',
    de: 'Anwaltskanzlei "Zahist"',
  },
  clubCardTitleBack: {
    ua: 'Найбільша юридична компанія України',
    en: 'The largest law firm in Ukraine',
    de: 'Die größte Anwaltskanzlei der Ukraine',
  },
  raiseStatus: {
    ua: 'Підвищити статус',
    en: 'Raise status',
    de: 'Status erhöhen',
  },
  payByClubCardSuccess: {
    ua: 'Сплачено клубною картою',
    en: 'Paid by club card',
    de: 'Mit Clubkarte bezahlt',
  },

  clubCardType: {
    silver: {
      ua: 'Срібна',
      en: 'Silver',
      de: 'Silber',
    },
    gold: {
      ua: 'Золота',
      en: 'Gold',
      de: 'Gold',
    },
    platinum: {
      ua: 'Платинова',
      en: 'Platinum',
      de: 'Platin',
    },
  },
  buyClubCard: {
    ua: 'Купити карту',
    en: 'Buy a card',
    de: 'Karte kaufen',
  },
  upgradeClubCard: {
    ua: 'Підвищити рівень',
    en: 'Upgrade Level',
    de: 'Stufe Erhöhen',
  },
  servicesBalance: {
    ua: 'Баланс послуг',
    en: 'Service balance',
    de: 'Leistungsbilanz',
  },
  expiredAt: {
    ua: 'Дійсна до:',
    en: 'Valid until:',
    de: 'Gültig bis:',
  },
  cardTypeTitle: {
    ua: 'Тип вашої карти',
    en: 'Type of your card',
    de: 'Typ Ihrer Karte',
  },
  currentPeriod: {
    ua: 'Ви обрали період',
    en: 'You have chosen the period',
    de: 'Sie haben den Zeitraum gewählt',
  },
  choosePeriod: {
    ua: 'Обирай більший період та заощаджуй до',
    en: 'Choose a longer period and save up to',
    de: 'Wählen Sie einen längeren Zeitraum und sparen Sie bis zu',
  },
  currencyUah: {
    ua: 'грн',
    en: 'UAH',
    de: 'UAH',
  },
  month: {
    ua: 'місяць',
    en: 'month',
    de: 'Monat',
  },
  savings: {
    ua: 'Економія:',
    en: 'Savings:',
    de: 'Ersparnisse:',
  },
  clubCardPeriod: {
    0: {
      ua: '1 місяць',
      en: '1 month',
      de: '1 Monat',
    },
    1: {
      ua: '3 місяці',
      en: '3 months',
      de: '3 Monate',
    },
    2: {
      ua: '6 місяців',
      en: '6 months',
      de: '6 Monate',
    },
    3: {
      ua: '1 рік',
      en: '1 year',
      de: '1 Jahr',
    },
    4: {
      ua: '3 роки',
      en: '3 years',
      de: '3 Jahre',
    },
  },
  totalPrice: {
    ua: 'До оплати:',
    en: 'Total to pay:',
    de: 'Gesamt zu zahlen:',
  },
  clubCardPurchased: {
    ua: 'Ви придбали клубну карту.',
    en: 'You have purchased a club card.',
    de: 'Sie haben eine Clubkarte gekauft.',
  },
  clubCardServicesStatic: {
    accessToApp: {
      ua: 'Доступ до базового функціоналу "єАдвокат"',
      en: 'Access to the basic functionality of "eAdvocate"',
      de: 'Zugang zur Grundfunktionalität von "eAdvocate"',
    },
    accessToRegisters: {
      ua: 'Доступ до реєстрів',
      en: 'Access to registers',
      de: 'Zugang zu Registern',
    },
    yes: {
      ua: 'Так',
      en: 'Yes',
      de: 'Ja',
    },
    no: {
      ua: 'Ні',
      en: 'No',
      de: 'Nein',
    },
  },
  clubCardServices: {
    0: {
      ua: 'Швидке питання',
      en: 'Quick question',
      de: 'Schnelle Frage',
    },
    1: {
      ua: 'Консультація',
      en: 'Consultation',
      de: 'Beratung',
    },
    2: {
      ua: 'Подарувати консультацію знайомому',
      en: 'Give a consultation to a friend',
      de: 'Verschenken Sie eine Beratung an einen Freund',
    },
    3: {
      ua: 'Зустріч з адвокатом по справі',
      en: 'Meeting with a lawyer on the case',
      de: 'Treffen mit einem Anwalt im Fall',
    },
    4: {
      ua: 'Преміум консультація',
      en: 'Premium consultation',
      de: 'Premium-Beratung',
    },
    5: {
      ua: 'Послуга "Тривожна кнопка"',
      en: 'Service "Alarm button"',
      de: 'Service "AlarmKnopf"',
    },
    6: {
      ua: 'Участь адвоката у переговорах',
      en: 'Lawyer participation in negotiations',
      de: 'Teilnahme des Anwalts an Verhandlungen',
    },
    7: {
      ua: 'Претензійна робота',
      en: 'Claim work',
      de: 'Anspruchsarbeit',
    },
    8: {
      ua: 'Підготовка документу',
      en: 'Document preparation',
      de: 'Dokumentenvorbereitung',
    },
    9: {
      ua: 'Письмова консультація',
      en: 'Written consultation',
      de: 'Schriftliche Beratung',
    },
    10: {
      ua: 'Вихід адвоката в суд',
      en: 'Lawyer`s appearance in court',
      de: 'Anwesenheit des Anwalts vor Gericht',
    },
    11: {
      ua: 'АІ асистент',
      en: 'AI assistant',
      de: 'AI-Assistent',
    },
    12: {
      ua: 'Особистий консультант 8 годин',
      en: 'Personal assistant 8 hours',
      de: 'Persoenlicher Assistent 8 Stunden',
    },
    13: {
      ua: 'Особистий консультант 24 години',
      en: 'Personal assistant 24 hours',
      de: 'Persoenlicher Assistent 24 Stunden',
    },
  },
  aiAssistantTitle: {
    ua: 'АІ асистент',
    en: 'AI assistant',
    de: 'AI-Assistent',
  },

  serviceDescription: {
    0: {
      ua: 'Ви отримаєте відповідь на своє питання 24/7, забезпечуючи постійну підтримку та допомогу.',
      en: 'You will receive an answer to your question 24/7, ensuring constant support and assistance.',
      de: 'Sie erhalten rund um die Uhr eine Antwort auf Ihre Frage, was ständige Unterstützung und Hilfe gewährleistet.',
    },
    1: {
      ua: 'Отримайте професійну консультацію з адвокатом безпосередньо у відділенні.',
      en: 'Get professional consultation with a lawyer directly at the branch.',
      de: 'Erhalten Sie eine professionelle Beratung mit einem Anwalt direkt in der Filiale.',
    },
    2: {
      ua: 'Подаруйте консультацію з адвокатом своєму знайомому, щоб він отримав необхідну юридичну підтримку.',
      en: 'Give a lawyer consultation to a friend so they can receive the necessary legal support.',
      de: 'Schenken Sie einem Freund eine Anwaltsberatung, damit er die notwendige rechtliche Unterstützung erhält.',
    },
    3: {
      ua: 'Проведіть зустріч з адвокатом для обговорення вашої справи у відділенні.',
      en: 'Have a meeting with a lawyer to discuss your case at the branch.',
      de: 'Führen Sie ein Treffen mit einem Anwalt, um Ihren Fall in der Filiale zu besprechen.',
    },
    4: {
      ua: 'Отримайте преміум консультацію з досвідченим адвокатом у нашому відділенні.',
      en: 'Receive premium consultation with an experienced lawyer at our branch.',
      de: 'Erhalten Sie eine Premium-Beratung mit einem erfahrenen Anwalt in unserer Filiale.',
    },
    5: {
      ua: 'Миттєва юридична допомога у разі порушення ваших прав співробітниками різних органів. Один клік - і ви під захистом.',
      en: 'Instant legal assistance in case of violation of your rights by employees of various authorities. One click and you are under protection.',
      de: 'Sofortige rechtliche Unterstützung bei Verletzung Ihrer Rechte durch Mitarbeiter verschiedener Behörden. Ein Klick und Sie sind geschützt.',
    },
    6: {
      ua: 'Адвокат бере участь у переговорах, забезпечуючи юридичну підтримку та представництво.',
      en: 'A lawyer participates in negotiations, providing legal support and representation.',
      de: 'Ein Anwalt nimmt an den Verhandlungen teil und bietet rechtliche Unterstützung und Vertretung.',
    },
    7: {
      ua: 'Повний супровід у претензійній роботі для захисту ваших прав та інтересів.',
      en: 'Full support in claim work to protect your rights and interests.',
      de: 'Umfassende Unterstützung bei der Anspruchsarbeit zum Schutz Ihrer Rechte und Interessen.',
    },
    8: {
      ua: 'Підготовка юридичних документів з урахуванням всіх необхідних вимог та стандартів.',
      en: 'Preparation of legal documents considering all necessary requirements and standards.',
      de: 'Vorbereitung von Rechtsdokumenten unter Berücksichtigung aller notwendigen Anforderungen und Standards.',
    },
    9: {
      ua: 'Отримайте письмову консультацію з детальними рекомендаціями від адвоката.',
      en: 'Receive a written consultation with detailed recommendations from a lawyer.',
      de: 'Erhalten Sie eine schriftliche Beratung mit detaillierten Empfehlungen eines Anwalts.',
    },
    10: {
      ua: 'Адвокат представлятиме ваші інтереси у суді, забезпечуючи професійний захист.',
      en: 'A lawyer will represent your interests in court, providing professional defense.',
      de: 'Ein Anwalt wird Ihre Interessen vor Gericht vertreten und professionellen Schutz bieten.',
    },
  },

  emailVerification: {
    ua: 'На жаль, ви не зможете бачити свій статус у реєстрах розшуку, поки не підтвердите свою електронну пошту.',
    en: 'Unfortunately, you will not be able to see your status in the search registers until you verify your email.',
    de: 'Leider können Sie Ihren Status in den Suchregistern nicht einsehen, bis Sie Ihre E-Mail verifiziert haben.',
  },
  bonusRetrieval: {
    ua: 'На жаль, ви не зможете забрати свій бонус, поки не підтвердите свою електронну пошту.',
    en: 'Unfortunately, you will not be able to claim your bonus until you verify your email.',
    de: 'Leider können Sie Ihren Bonus nicht abholen, bis Sie Ihre E-Mail verifiziert haben.',
  },
  chatAccess: {
    ua: 'На жаль, ви не зможете бачити свої чати та вести переписку з юристами, поки не підтвердите свою електронну пошту.',
    en: 'Unfortunately, you will not be able to see your chats and communicate with lawyers until you verify your email.',
    de: 'Leider können Sie Ihre Chats nicht einsehen und nicht mit Anwälten kommunizieren, bis Sie Ihre E-Mail verifiziert haben.',
  },
  emailNotVerifiedTitle: {
    ua: 'Ви не підтвердили пошту!',
    en: 'You have not verified your email!',
    de: 'Sie haben Ihre E-Mail nicht verifiziert!',
  },
  verifyEmailButton: {
    ua: 'Підтвердити пошту',
    en: 'Verify email',
    de: 'E-Mail verifizieren',
  },
  emailAuthenticationTitle: {
    ua: 'Аутентифікація пошти',
    en: 'Email authentication',
    de: 'E-Mail-Authentifizierung',
  },

  //ticker
  tickerPromoDay: {
    ua: 'Акції дня',
    en: 'Deals of the day',
    de: 'Angebote des Tages',
  },
  tickerCharity: {
    ua: 'Частина витрачених грошей йде на допомогу ЗСУ',
    en: 'Part of the money spent goes to help the Armed Forces of Ukraine',
    de: 'Ein Teil des ausgegebenen Geldes kommt den Streitkräften der Ukraine zugute',
  },
  installment: {
    ua: 'Розстрочка',
    en: 'Installment',
    de: 'Zahlungsbetrag',
  },
  assignment: {
    ua: 'Виходи',
    en: 'Assignments',
    de: 'Zuweisungen',
  },
  monthlyPayments: {
    ua: 'Щомісячні платежі',
    en: 'Monthly payments',
    de: 'Monatliche Zahlungen',
  },
  firstPayment: {
    ua: 'Перший платіж',
    en: 'First payment',
    de: 'Erste Zahlung',
  },
  return30Percents: {
    ua: 'повернути 30%',
    en: 'return 30%',
    de: 'rendite 30 %',
  },
  // ClientLogin Page
  // clientLoginError: {
  //   ua: 'Помилка входу',
  //   en: 'Login Error',
  //   de: 'Anmeldefehler',
  // },
  // clientLoginPhoneMes: {
  //   ua: 'Введіть ваш номер телефону',
  //   en: 'Enter your phone number',
  //   de: 'Geben Sie Ihre Telefonnummer ein',
  // },

  // clientLoginRule: {
  //   ua: 'Правила використання',
  //   en: 'Terms of Use',
  //   de: 'Nutzungsbedingungen',
  // },

  clientDriverInsuranceTitle: {
    ua: 'Юридична страховка водіїв транспортних засобів',
    en: 'Legal insurance for drivers of vehicles',
    de: 'Rechtsschutz für Fahrer von Fahrzeugen',
  },
  clientProfileName: {
    ua: "Ім'я",
    en: 'Name',
    de: 'Name',
  },
  clientProfileEmail: {
    ua: 'Електронна пошта',
    en: 'Email',
    de: 'E-Mail',
  },
  clientProfilePhone: {
    ua: 'Телефон',
    en: 'Phone',
    de: 'Telefon',
  },
  clientProfileEnableBiometric: {
    ua: 'Біометрична автентифікація',
    en: 'Biometric authentication',
    de: 'Biometrische Authentifizierung',
  },
  clientProfileUpdateButton: {
    ua: 'Оновити профіль',
    en: 'Update Profile',
    de: 'Profil aktualisieren',
  },
  clientProfileCancelButton: {
    ua: 'Скасувати',
    en: 'Cancel',
    de: 'Abbrechen',
  },

  // Hotline Meet Invite EAdvokat block
  eAdvokatImageDescription: {
    ua: 'Зображення клієнтського мобільного додатку Адвокатське об’єднання «Захист», котрий надає багатий та гнучкий функціонал, що дозволяє клієнту працювати над своєю справою у реальному часі.',
    en: 'The depiction of the client mobile application of the Advocacy Association «Zahist», which provides a rich and flexible functionality, allowing the client to work on their case in real-time.',
    de: 'Die Darstellung der Kunden-Mobilanwendung des Anwaltsverband «Zahist», die eine umfangreiche und flexible Funktionalität bietet und es dem Kunden ermöglicht, in Echtzeit an seinem Fall zu arbeiten',
  },
  yourCabinet: {
    ua: 'Ваш кабінет:',
    en: 'Your cabinet:',
    de: 'Ihr Kabinett:',
  },
  appForSmartphones: {
    ua: 'Додаток для смартфонів:',
    en: 'Smartphone application:',
    de: 'Smartphone-Anwendung:',
  },

  // Webinars

  webinar: {
    ua: 'Вебінар: ',
    en: 'Webinar: ',
    de: 'Webinar: ',
  },
  buyWebinar: {
    ua: 'Придбати',
    en: 'Buy',
    de: 'Kaufen',
  },
  webinarPurchased: {
    ua: 'Ви придбали вебінар.',
    en: 'You have purchased a webinar.',
    de: 'Sie haben ein Webinar gekauft.',
  },
  webinarStatuses: [
    {
      ua: 'Заплановано',
      en: 'Scheduled',
      de: 'Geplant',
    },
    {
      ua: 'Розпочався',
      en: 'Started',
      de: 'Gestartet',
    },
    {
      ua: 'Закінчився',
      en: 'Ended',
      de: 'Beendet',
    },
  ],
  webinarPrice: {
    ua: 'Ціна:',
    en: 'Price:',
    de: 'Preis:',
  },
  webinarDate: {
    ua: 'Дата:',
    en: 'Date:',
    de: 'Datum:',
  },
  webinarTeaser: {
    ua: 'Тизер',
    en: 'Teaser',
    de: 'Teaser',
  },
  openStream: {
    ua: 'Перейти',
    en: 'Open',
    de: 'Öffnen',
  },
  // == Register pages == //

  successMessage: {
    base: {
      ua: 'Результати дивіться нижче.',
      en: 'See the results below.',
      de: 'Ergebnisse siehe unten.',
    },
    alimony: {
      ua: 'Успіх! Боржники з аліментів.',
      en: 'Successful search in the alimony debtors registry!',
      de: 'Erfolgreiche Suche im Register der Unterhaltsschuldner!',
    },
    corruptioner: {
      ua: 'Успіх! Реєстр корупціонерів.',
      en: 'Successful search in the corruption registries!',
      de: 'Erfolgreiche Suche in den Korruptionsregistern!',
    },
    wanted: {
      ua: 'Успіх! Особи в розшуку.',
      en: 'Successful search in the wanted persons registry!',
      de: 'Erfolgreiche Suche im Register der gesuchten Personen!',
    },
  },
  registerOpts: {
    alimony: {
      debtorName: {
        ua: "Ім'я боржника:",
        en: "Debtor's Name:",
        de: 'Name des Schuldners:',
      },
      debtorBirthDate: {
        ua: 'Дата народження боржника:',
        en: "Debtor's Birth Date:",
        de: 'Geburtsdatum des Schuldners:',
      },
      bankAccount: {
        ua: 'Рахунок банку:',
        en: 'Bank Account:',
        de: 'Bankkonto:',
      },
      creditorName: {
        ua: "Ім'я кредитора:",
        en: "Creditor's Name:",
        de: 'Name des Gläubigers:',
      },
      dvsCode: {
        ua: 'Код ДВС:',
        en: 'DVS Code:',
        de: 'DVS-Code:',
      },
      emailAddr: {
        ua: 'Електронна адреса:',
        en: 'Email Address:',
        de: 'E-Mail-Adresse:',
      },
      erbDebtorBirthDate: {
        ua: 'Дата народження боржника (ЕРБ):',
        en: "Debtor's Birth Date (ERB):",
        de: 'Geburtsdatum des Schuldners (ERB):',
      },
      erbDebtorName: {
        ua: "Ім'я боржника (ЕРБ):",
        en: "Debtor's Name (ERB):",
        de: 'Name des Schuldners (ERB):',
      },
      erbVpOrdernum: {
        ua: 'Номер замовлення (ЕРБ)',
        en: 'Order Number (ERB):',
        de: 'Bestellnummer (ERB):',
      },
      orgName: {
        ua: 'Назва організації:',
        en: 'Organization Name:',
        de: 'Name der Organisation:',
      },
      phoneNum: {
        ua: 'Телефонний номер:',
        en: 'Phone Number:',
        de: 'Telefonnummer:',
      },
      vpBeginDate: {
        ua: 'Дата початку виконавчого провадження:',
        en: 'Start Date of Enforcement Proceedings:',
        de: 'Beginn der Vollstreckungsverfahren:',
      },
      vpOrdernum: {
        ua: 'Номер виконавчого провадження:',
        en: 'Enforcement Proceedings Number:',
        de: 'Vollstreckungsverfahren-Nummer:',
      },
      vpState: {
        ua: 'Стан виконавчого провадження:',
        en: 'State of Enforcement Proceedings:',
        de: 'Status des Vollstreckungsverfahrens:',
      },
    },
    corruptioner: {
      fullName: {
        ua: "Повне ім'я:",
        en: 'Full Name:',
        de: 'Vollständiger Name:',
      },
      courtCaseNumber: {
        ua: 'Номер справи:',
        en: 'Case Number:',
        de: 'Aktenzeichen:',
      },
      offenseName: {
        ua: 'Назва правопорушення:',
        en: 'Name of Offense:',
        de: 'Bezeichnung der Straftat:',
      },
      punishment: {
        ua: 'Покарання:',
        en: 'Punishment:',
        de: 'Strafe:',
      },
      sentenceDate: {
        ua: 'Дата вироку:',
        en: 'Sentence Date:',
        de: 'Urteilsdatum:',
      },
      sentenceNumber: {
        ua: 'Номер вироку:',
        en: 'Sentence Number:',
        de: 'Urteilsnummer:',
      },
      punishmentStartDate: {
        ua: 'Дата початку покарання:',
        en: 'Punishment Start Date:',
        de: 'Beginn des Strafvollzugs:',
      },
      courtName: {
        ua: 'Суд:',
        en: 'Court:',
        de: 'Gericht:',
      },
      codexArticleName: {
        ua: 'Стаття кодексу:',
        en: 'Code Article:',
        de: 'Kodexartikel:',
      },
    },
    wanted: {
      full_name: {
        ua: "Повне ім'я:",
        en: 'Full Name:',
        de: 'Vollständiger Name:',
      },
      birth_date: {
        ua: 'Дата народження:',
        en: 'Date of Birth:',
        de: 'Geburtsdatum:',
      },
      lost_date: {
        ua: 'Дата зникнення:',
        en: 'Date of Disappearance:',
        de: 'Verschwindungsdatum:',
      },
      sex: {
        ua: 'Стать:',
        en: 'Sex:',
        de: 'Geschlecht:',
      },
      article_crim: {
        ua: 'Стаття кримінального кодексу:',
        en: 'Criminal Code Article:',
        de: 'Strafgesetzbuch-Artikel:',
      },
      lost_place: {
        ua: 'Місце зникнення:',
        en: 'Place of Disappearance:',
        de: 'Ort des Verschwindens:',
      },
      ovd: {
        ua: 'ОВД:',
        en: 'OVD:',
        de: 'OVD:',
      },
      category: {
        ua: 'Категорія:',
        en: 'Category:',
        de: 'Kategorie:',
      },
      status_text: {
        ua: 'Статус:',
        en: 'Status:',
        de: 'Status:',
      },
    },
  },
  registerTitles: {
    search: {
      ua: 'Пошук в реєстрах',
      en: 'Search in Registers',
      de: 'Suche in Registern',
    },
    allRegisters: {
      ua: 'Всі реєстри',
      en: 'All Registers',
      de: 'Alle Register',
    },
    findMe: {
      ua: 'Знайти себе',
      en: 'Find Myself',
      de: 'Mich finden',
    },
    selectRegistry: {
      ua: 'Оберіть реєстр',
      en: 'Select Registry',
      de: 'Register auswählen',
    },
    alimonyDebtors: {
      ua: 'Боржники аліментів',
      en: 'Alimony Debtors',
      de: 'Unterhaltsdebitoren',
    },
    registerOfCorruptioners: {
      ua: 'Реєстр корупціонерів',
      en: 'Register of Corruptioners',
      de: 'Register der Korruption',
    },
    wantedPersons: {
      ua: 'Особи у розшуку',
      en: 'Wanted Persons',
      de: 'Gesuchte Personen',
    },
    subTitle: {
      alimony: {
        ua: 'Боржники аліментів',
        en: 'Alimony Debtors',
        de: 'Unterhaltsdebitoren',
      },
      corruptioner: {
        ua: 'Реєстр корупціонерів',
        en: 'Register of Corruptioners',
        de: 'Register der Korruption',
      },
      wanted: {
        ua: 'Особи у розшуку',
        en: 'Wanted Persons',
        de: 'Gesuchte Personen',
      },
    },
    sectionName: {
      ua: 'Розрахунок по реестрах',
      en: 'Reconciliation by Registers',
      de: 'Abrechnung nach Registern',
    },

    search: {
      alimony: {
        ua: 'Пошук за ПІБ',
        en: 'Search by Full Name',
        de: 'Suche nach Vollständigem Namen',
      },
      corruptioner: {
        ua: 'Пошук за ПІБ, або покаранням',
        en: 'Search by Full Name or Punishment',
        de: 'Suche nach Vollständigem Namen oder Strafe',
      },
      wanted: {
        ua: 'Пошук за ПІБ',
        en: 'Search by Full Name',
        de: 'Suche nach Vollständigem Namen',
      },
    },
    searchBtn: {
      ua: 'Пошук',
      en: 'Search',
      de: 'Suche',
    },
    searchResults: {
      ua: 'Результати пошуку',
      en: 'Search Results',
      de: 'Suchergebnisse',
    },
    findMeInReg: {
      ua: 'Знайти себе в реєстрах',
      en: 'Find Myself in Registers',
      de: 'Mich in Registern finden',
    },
    totalAmount: {
      ua: 'Загальна сума',
      en: 'Total Amount',
      de: 'Gesamtbetrag',
    },
    nothingFound: {
      ua: 'Нічого не знайдено',
      en: 'Nothing Found',
      de: 'Nichts gefunden',
    },
    priceForOneRegister: {
      ua: 'Ціна за один реєстр',
      en: 'Price per register',
      de: 'Preis pro Register',
    },
    priceForTheService: {
      ua: 'Ціна за послугу',
      en: 'Price for the service',
      de: 'Preis für den Service',
    },
  },
  registerError: {
    enterValue: {
      ua: 'Введіть значення.',
      en: 'Enter value.',
      de: 'Geben Sie einen Wert ein.',
    },
    notEnoughFunds: {
      ua: 'Не достатньо коштів.',
      en: 'Not enough funds.',
      de: 'Nicht genügend Mittel.',
    },
    topUpYourBalance: {
      ua: 'Будь ласка, поповніть баланс.',
      en: 'Please top up your balance.',
      de: 'Bitte füllen Sie Ihr Guthaben auf.',
    },
    selectRegisters: {
      ua: 'Оберіть реєстри.',
      en: 'Select registers.',
      de: 'Wählen Sie Register aus.',
    },
  },
  clientHotline: {
    selectHotlineType: {
      ua: 'Оберіть тип консультації',
      en: 'Select consultation type',
      de: 'Wählen Sie die Art der Beratung',
    },
    selectHotlineState: {
      ua: 'Оберіть область',
      en: 'Select state',
      de: 'Wählen Sie die Region',
    },
    hotlineLimitExceed: {
      ua: 'Перевищено ліміт записів на тиждень',
      en: 'The limit of entries per week has been exceeded',
      de: 'Die Höchstzahl der Einträge pro Woche wurde überschritten',
    },
    youHaveAnAppointment: {
      ua: 'Ви вже маєте запис на',
      en: 'You already have an appointment for',
      de: 'Sie haben bereits einen Termin für',
    },
    topFilials: {
      ua: 'Топ філій за напрямком',
      en: 'Top branches by direction',
      de: 'Top-Filialen nach Richtung',
    },
    district: {
      ua: 'Окружна',
      en: 'District',
      de: 'Bezirk',
    },
    byRegion: {
      ua: 'За Областю',
      en: 'By region',
      de: 'Nach Region',
    },
    includedInDistrict: {
      ua: 'Входять в округ',
      en: 'Included in district',
      de: 'Im Bezirk enthalten',
    },
    rest: {
      ua: 'Решта',
      en: 'The rest',
      de: 'Der Rest',
    },
  },
};

module.exports = {
  languageOptions,
  translation,
};
