import React, { useEffect, useState } from 'react';
import { LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { request, error } from '../../tools';

const getIcon = (status) => {
  switch (status) {
    case 'loading':
      return <LoadingOutlined />;
    case 'success':
      return <CheckCircleTwoTone twoToneColor="#52c41a" />;
    case 'error':
      return <CloseCircleTwoTone twoToneColor="#eb2f96" />;
    default:
      return;
  }
};

const CheckingRequiredFields = ({ setContainsAllRequiredFields, documentId }) => {
  const [checkedFieldsData, setCheckedFieldsData] = useState({
    clientInn: {
      status: 'loading',
      label: 'ІПН клієнта',
    },
  });
  const [isSended, setIsSended] = useState(false);
  const [isUnchecked, setIsUnchecked] = useState(false);

  const checkNecessaryData = () => {
    if (!documentId) {
      setContainsAllRequiredFields(false);
      return;
    }

    const requestData = {
      documentId: documentId,
    };

    request.post(
      '/contracts/checkDataForAutoSending',
      requestData,
      (req) => {
        Object?.keys(req.checkedFields)?.forEach((key) => {
          setCheckedFieldsData((prev) => ({
            ...prev,
            [key]: { label: checkedFieldsData?.[key]?.label, status: req.checkedFields[key] ? 'success' : 'error' },
          }));

          if (!req.checkedFields[key]) setIsUnchecked(true);
        });
        setIsSended(true);
      },
      () => {
        error();
        setIsUnchecked(true);
      },
    );
  };

  useEffect(() => {
    if (isSended) {
      setContainsAllRequiredFields(!isUnchecked);
    }
  }, [isSended]);

  useEffect(() => {
    checkNecessaryData();
  }, []);

  return (
    <div className="check-contract-data">
      <h3>Перевірка даних договору</h3>
      <div className="items-wrapper">
        {Object.keys(checkedFieldsData)?.map((item) => (
          <div className="item" key={item}>
            <div>{getIcon(checkedFieldsData[item]?.status)}</div>
            <p>{checkedFieldsData[item]?.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckingRequiredFields;
