import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Spin, Modal, Slider, InputNumber } from 'antd';
import { CSVLink } from 'react-csv';

import { setModal } from '../../store/commonReducer';
import Excel from '../../images/excel_logo.svg';

import './styles.scss';

const ExportQuantityModal = ({ csvData, onGenerate, max, data, title, loading, startA, setStartA, endA, setEndA }) => {
  const dispatch = useDispatch();

  return (
    <Modal open className="ExportQuantityModal" title={title} onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="change">
          <Slider
            range
            value={[startA, endA]}
            min={1}
            max={max}
            onChange={(v) => {
              setStartA(v[0]);
              setEndA(v[1]);
            }}
          />
          <div className="inputs">
            <InputNumber value={startA} onChange={(v) => setStartA(v)} />
            <InputNumber value={endA} onChange={(v) => setEndA(v)} />
          </div>
          {data ? (
            <CSVLink asyncOnClick={true} data={csvData}>
              <Button className="excel">
                <img src={Excel} alt="Excel" /> Скачати .csv
              </Button>
            </CSVLink>
          ) : (
            <Button onClick={onGenerate}>Генерувати</Button>
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

export default ExportQuantityModal;
