import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spin, Modal, Input } from 'antd';

import { setModal } from '../../store/commonReducer';

import './styles.scss';
import { request, error, success } from '../../tools';

const MoveToArchive = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.reqLoader);
  const [text, setText] = useState('');
  const onCancel = () => {
    dispatch(setModal());
  };

  const onSubmit = () => {
    const list = text.split('\n').map((i) => {
      const arr = i.split('/');
      if (!arr[1] || !arr[2]) return;

      return { ai: +arr[1], i: +arr[2] };
    });
    request.post(
      '/contracts/listToArchive',
      { list },
      () => {
        success();
        dispatch(setModal());
      },
      error,
    );
  };

  return (
    <Modal open className="archive-contract" title={'Переносимо договіри в архів'} onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={loading}>
        <Input.TextArea rows={10} value={text} onChange={(e) => setText(e.target.value)} />
        <br />
        <br />
        <Button onClick={onSubmit} className="">
          Відправити
        </Button>
      </Spin>
    </Modal>
  );
};

export default MoveToArchive;
