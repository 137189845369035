import React from 'react';
import { PROPOSED_AMOUNT_PERIOD_REACH } from 'common_constants/business';
import { Select } from 'antd';

const billSelectGeneralOfferedAmount = [
  PROPOSED_AMOUNT_PERIOD_REACH,
  ['Бонуси по клієнту', 'BArr_C'],
  ['Запропонована сума по консультації', 'ampArr_H'],
  ['Запропонована сума', 'ampArr'],
];

const SelectGeneralOfferedAmount = ({ status, defaultValue, onChange, value, className, disabled, size }) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      status={status}
      className={className}
      size={size}
      showSearch
      allowClear
      placeholder="Оберіть суму"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value="_">Усі суми</Option>
      {PROPOSED_AMOUNT_PERIOD_REACH.map((i, index) => (
        <Option key={i.text} value={String(index)}>
          {i.text}
        </Option>
      ))}
    </Select>
  );
};

export { billSelectGeneralOfferedAmount, SelectGeneralOfferedAmount };
