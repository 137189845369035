import { request } from '../../../tools';

const getJudges = async (handleSetJudgesList, handleSetMainLoader) => {
  return new Promise((resolve, reject) => {
    request.post(
      '/judge/get',
      {},
      (res) => {
        handleSetJudgesList(res.judges);
        resolve();
      },
      (error) => {
        handleSetMainLoader(false);
        reject(error);
      },
    );
  });
};

export default getJudges;
