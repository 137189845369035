import React from 'react';
import { LAW_BLOCKS } from 'common_constants/business';
import SearchSelect from './SearchSelect';

const SearchSelectBlock = ({ onChange, value, className, style }) => {
  return (
    <SearchSelect
      className={className}
      style={style}
      placeholder="Оберіть блок"
      list={Object.keys(LAW_BLOCKS).map((i) => ({ value: i, label: LAW_BLOCKS[i] }))}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
    />
  );
};

export default SearchSelectBlock;
