import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Button, Form, Input, Radio, Slider, Result, Select } from 'antd';

import { LANGUAGE_OPTIONS } from 'common_constants/business';
import { setModal } from '../../store/commonReducer';
import { request } from '../../tools';

import './style.scss';

const { TextArea } = Input;

const Actualization = () => {
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);
  const [languageProficiency, setLanguageProficiency] = useState([]);
  const [relocationToCountry, setRelocationToCountry] = useState('');

  const [form] = Form.useForm();

  const onLanguageProficiencyChange = (selectedLanguageValues) => {
    const selectedLanguages = LANGUAGE_OPTIONS.filter((language) => selectedLanguageValues.includes(language.value));
    setLanguageProficiency(selectedLanguages);
  };

  const onRelocationToCountryChange = (country) => {
    setRelocationToCountry(country);
  };

  const onCancel = () => {
    if (success) dispatch(setModal());
  };

  const onSubmit = (data) => {
    const body = {
      actualization: data,
    };

    request.post('/profile/actualization', body, () => {
      setSuccess(true);
    });
  };

  const relocationOptions = [
    { label: 'Так', value: 1 },
    { label: 'Скоріше так', value: 2 },
    { label: 'Скоріше ні', value: 3 },
    { label: 'Ні', value: 4 },
  ];

  if (success)
    return (
      <Modal
        open
        className="actualization"
        title={'Актуалізація персональних данних'}
        onCancel={onCancel}
        footer={null}
        style={{ textAlign: 'center' }}
      >
        <Result status="success" title="Дякуємо за відповіді!" />
        <b>Не забудьте оновити данні про себе у профілі.</b>
        <br />
        <br />
        <Button type="primary" onClick={onCancel}>
          <Link to="/profile">Перейти до профілю</Link>
        </Button>
        <br />
        <br />
        <Button type="primary" onClick={onCancel}>
          Закрити
        </Button>
      </Modal>
    );

  return (
    <Modal open className="actualization" title={'Актуалізація персональних данних'} onCancel={onCancel} footer={null}>
      <b>Щомісяця ми проводимо актуалізацію персональних данних.</b>
      <br />
      <b>Будь ласка дайте відповіді на наступні питання:</b>
      <br />
      <br />
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item
          name="relocation"
          label="Готовність до переїзду у зв'язку з підвищенням"
          rules={[{ required: true, message: "Це поле є обов'язковим" }]}
        >
          <Radio.Group options={relocationOptions} />
        </Form.Item>
        <Form.Item
          name="relocationToCountry"
          label="Готовність до переїзду в іншу країну"
          rules={[{ required: true, message: "Це поле є обов'язковим" }]}
        >
          <Radio.Group options={relocationOptions} onChange={(e) => onRelocationToCountryChange(e.target.value)} />
        </Form.Item>

        {relocationToCountry === 1 || relocationToCountry === 2 ? (
          <Form.Item name="selectedCountry" label="Назва країни">
            <Input placeholder="Введіть назву країни" />
          </Form.Item>
        ) : null}

        <Form.Item name="languages" label="Знання іноземних мов" rules={[{ required: true, message: "Це поле є обов'язковим" }]}>
          <Select mode="multiple" placeholder="Виберіть іноземні мови" onChange={onLanguageProficiencyChange}>
            {LANGUAGE_OPTIONS.map((language) => (
              <Select.Option key={language.value} value={language.value}>
                {language.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {languageProficiency.some((language) => language.value === 99)
          ? null
          : languageProficiency.map((language) => (
              <Form.Item key={language.value} name={`languageProficiency_${language.value}`} label={`Рівень володіння ${language.label}`}>
                <Select placeholder="Оберіть рівень володіння">
                  <Select.Option value="1">Початковий</Select.Option>
                  <Select.Option value="2">Середній</Select.Option>
                  <Select.Option value="3">Вище середнього</Select.Option>
                  <Select.Option value="4">Високий</Select.Option>
                </Select>
              </Form.Item>
            ))}
        <Form.Item name="idea" label="Ідея щодо вдосконалення роботи" rules={[{ required: true, message: "Це поле є обов'язковим" }]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="complaints" label="Скарги та пропозиції" rules={[{ required: true, message: "Це поле є обов'язковим" }]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="working_conditions"
          label="На скільки Вас влаштовують умови праці"
          rules={[{ required: true, message: "Це поле є обов'язковим" }]}
        >
          <Slider min={1} max={10} marks={{ 1: '😞', 5.5: '😐', 10: '😀' }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Відправити
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Actualization;
