import { Input, Select, Typography } from 'antd';
import { CALL_NOT_LINKED_REASONS, CONSULTATION_GROUP } from 'common_constants/business';
import React, { useEffect } from 'react';

export default function SelectNoCallReason({ data, setData }) {
  const { Text } = Typography;

  useEffect(() => {
    if (![6, 5].includes(data.noCallReason)) {
      setData((prev) => ({ ...prev, noCallOtherReason: '' }));
    }
  }, [data.noCallReason]);

  if (!CONSULTATION_GROUP.includes(data.type)) {
    return null;
  }

  return (
    <>
      <div style={{ marginTop: 15 }}>
        <Text style={{ marginRight: 10 }}>Причина не прив'язки дзвінка:</Text>
        <Select
          style={{ marginBottom: '10px', minWidth: 200 }}
          showSearch
          value={data.noCallReason}
          placeholder="Оберіть причину"
          optionFilterProp="children"
          onChange={(value) => {
            setData({ ...data, noCallReason: value });
          }}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        >
          {CALL_NOT_LINKED_REASONS.map((i, index) => {
            return (
              <Select.Option key={index} value={index}>
                {i}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      {[6, 5].includes(data.noCallReason) && (
        <Input
          style={{ width: '100%' }}
          placeholder="Введіть причину"
          value={data.noCallOtherReason}
          onChange={(e) => setData((prev) => ({ ...prev, noCallOtherReason: e.target.value }))}
        />
      )}
    </>
  );
}
