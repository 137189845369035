import { useEffect, useState } from 'react';
import { PAYMENT_STATUS, CURRENCIES } from 'common_constants/business';
import { Modal, Divider, Spin, Button, Popconfirm, Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { setModal, editContract } from '../../store/commonReducer';
import { request, error, success, warn } from '../../tools';
import PaymentManagementForm from '../../components/PaymentManagementForm';
import PaymentManagementComponent from '../../components/PaymentManagementComponent';

import './PaymentManagement.scss';

const isPastDate = (date) => {
  return dayjs(date).startOf('day').isBefore(dayjs());
};

const PaymentManagement = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.common.modal);
  const { Panel } = Collapse;
  console.log(data);
  const contractsList = useSelector((state) => state.common.contracts);
  const filials = useSelector((state) => state.common.filii);

  const currentContract = contractsList?.filter((contract) => contract._id === data?.contract?._id)?.[0] || [];
  const currentClient = currentContract?.client[0];

  const { currency: filialCurrency } = filials.find((filial) => filial.i === Number(currentClient.f)) || {};
  const currentCurrency = currentClient?.currency || filialCurrency;

  const [sugar, setSugar] = useState([]);
  const [activeKey, setActiveKey] = useState([]);
  const [totalInstallment, setTotalInstallment] = useState(0);
  const [totalPayByClient, setTotalPayByClient] = useState(0);
  const [remainingMoney, setRemainingMoney] = useState(0);
  const [paymentManagement, setPaymentManagement] = useState(currentContract?.paymentManagement || []);
  const [monthlyPayments, setMonthlyPayments] = useState(currentContract?.monthlyPaymentsManagement || []);
  const [loading, setLoading] = useState(false);
  const [isPayDebtBtnLoading, setIsPayDebtBtnLoading] = useState(false);
  const assignments =
    currentContract?.assignments?.filter((item) => !item?.incorrect && dayjs(item?.date).startOf('day') > dayjs(currentContract?.md)) || [];
  const assignmentsSum = assignments.reduce((acc, item) => acc + +item?.clientAmount, 0);

  const [paymentManagementForm, setPaymentManagementForm] = useState({
    pa: currentContract?.pa,
    md: currentContract?.editPMonceAccess ? dayjs() : dayjs(currentContract?.md),
    fp: currentContract?.fp,
    installmentMonths: currentContract?.installmentMonths,
    pm: 13,
    monthlyFee: currentContract?.monthlyFee,
    ea: currentContract?.ea,
    ...(currentContract?.editPMonceAccess === true ? { editPMonceAccess: false } : {}),
  });

  const getPayAmount = () => {
    return currentClient?.clientBalance > 0 && currentClient?.clientBalance <= Math.abs(remainingMoney)
      ? currentClient?.clientBalance
      : Math.abs(remainingMoney);
  };

  const installimentSum = paymentManagement.filter((item) => isPastDate(item?.date)).reduce((acc, item) => acc + +item?.amount, 0);
  const monthlyPaymentsSum = monthlyPayments.filter((item) => isPastDate(item?.date)).reduce((acc, item) => acc + +item?.amount, 0);

  const cancelEditPaymentManagement = () => {
    setPaymentManagement(currentContract?.paymentManagement || []);
  };

  const getSugarForClient = () => {
    if (!currentContract?._id) return;

    const params = { contractIndex: currentContract?.i, payfor: 3 };

    request.post(
      '/sugar/getSugar',
      params,
      ({ sugar }) => {
        setSugar(sugar);
      },
      error,
    );
  };

  useEffect(() => {
    getSugarForClient();
  }, []);

  const payContractDebt = () => {
    if (remainingMoney > 0) {
      warn('Заборгованість по договору не знайдена');
      return;
    }
    if (Math.abs(remainingMoney) > currentClient?.clientBalance && !currentClient?.paymentCard?.cardToken) {
      warn('Неможливо провести транзакцію.');
      return;
    }
    setIsPayDebtBtnLoading(true);
    const onSuccess = () => {
      const amount = Math.abs(remainingMoney);

      dispatch(
        editContract({
          ...currentContract,
          client: [
            {
              ...currentClient,
              clientBalance: currentClient.clientBalance >= amount ? currentClient?.clientBalance - amount : currentClient?.clientBalance,
            },
          ],
        }),
      );
      success();
      setIsPayDebtBtnLoading(false);
    };

    const onError = () => {
      error();
      setIsPayDebtBtnLoading(false);
    };
    const data = {
      contractId: currentContract?._id,
      clientId: currentClient?._id,
      contractIndex: currentContract?.i,
      amount: Math.abs(remainingMoney),
      currency: currentClient?.currency,
      fil: currentContract?.f,
      payFullContractDebt: true,
    };
    if (currentClient?.clientBalance >= Math.abs(remainingMoney)) {
      request.post('/payment/payDebtContractCRM', data, onSuccess, onError);
    } else {
      request.post('/payment/mono/payContractDebtFromCRM/link', data, onSuccess, onError);
    }
  };
  console.log(currentClient?.clientBalance, Math.abs(remainingMoney));
  console.log(currentClient?.clientBalance < Math.abs(remainingMoney));

  const updateMonthlyPayments = () => {
    if (!currentContract?.monthlyFee || !currentContract?.md) return;
    setLoading(true);

    const onSuccess = (res) => {
      const { monthlyPaymentsManagement } = res;
      setMonthlyPayments(monthlyPaymentsManagement);
      const data = { ...currentContract, monthlyPaymentsManagement };
      dispatch(editContract(data));
      setIsPayDebtBtnLoading(false);
    };

    const onError = () => {
      error('Виникла помилка при генерування щомісячних платежів');
      setLoading(false);
    };
    request.post('/contracts/fillMonthlyPayments', { _id: currentContract._id, md: paymentManagementForm?.md }, onSuccess, onError);
  };

  const onModalClose = () => {
    updateMonthlyPayments();
    dispatch(setModal());
  };

  return (
    <Modal title="Менеджмент платежів" className="payment-management-modal" open onCancel={onModalClose} footer={null}>
      <Spin spinning={loading} tip="Зачекайте" size="large">
        <PaymentManagementForm contract={currentContract} form={paymentManagementForm} setForm={setPaymentManagementForm} setLoading={setLoading} />
        <Divider orientation="left" plain>
          <b>Гонорар</b>
        </Divider>
        <div>
          <p>
            Повний гонорар: {paymentManagementForm?.pa}
            {CURRENCIES[currentCurrency]?.symbol}
          </p>
          <p>
            Перший платіж: {paymentManagementForm?.fp || 0}
            {CURRENCIES[currentCurrency]?.symbol}
          </p>
          <p>
            Вихід адвоката: {paymentManagementForm?.ea || 0}
            {CURRENCIES[currentCurrency]?.symbol}
          </p>
        </div>
        <div className="block">
          <div className="block-header">
            <b>До оплати на {dayjs().format('DD.MM.YYYY')}</b>
          </div>
          <div className="block-content">
            <p>
              Перший платіж: {paymentManagementForm?.fp || 0}
              {CURRENCIES[currentCurrency]?.symbol}
            </p>
            <p>
              Виходи: {assignmentsSum}
              {CURRENCIES[currentCurrency]?.symbol}
            </p>
            <p>
              {paymentManagement && paymentManagement.length ? (
                <>
                  Розстрочка: {installimentSum}
                  {CURRENCIES[currentCurrency]?.symbol}
                </>
              ) : (
                <>
                  Гонорар: {totalInstallment}
                  {CURRENCIES[currentCurrency]?.symbol}
                </>
              )}
            </p>
            <p>
              Щомісячні платежі: {monthlyPaymentsSum}
              {CURRENCIES[currentCurrency]?.symbol}
            </p>
            <b>
              Всього:{' '}
              {(paymentManagement && paymentManagement.length ? installimentSum : totalInstallment) +
                monthlyPaymentsSum +
                assignmentsSum +
                (Number(paymentManagementForm?.fp) || 0)}
              {CURRENCIES[currentCurrency]?.symbol}
            </b>
          </div>

          <Divider />
          <b>Всього оплачено: {totalPayByClient}</b>
        </div>
        <PaymentManagementComponent
          paymentManagement={paymentManagement}
          setPaymentManagement={setPaymentManagement}
          monthlyPayments={monthlyPayments}
          setMonthlyPayments={setMonthlyPayments}
          totalInstallment={totalInstallment}
          setTotalInstallment={setTotalInstallment}
          paymentManagementForm={paymentManagementForm}
          contract={currentContract}
          setRemainingMoney={setRemainingMoney}
          cancelEdit={cancelEditPaymentManagement}
          setLoading={setLoading}
          loading={loading}
          setTotalPayByClient={setTotalPayByClient}
          currentCurrency={currentCurrency}
          client={currentClient}
        />
      </Spin>
      <div className="summary-info">
        {remainingMoney >= 0 ? (
          <p className="summary-info-result">
            Переплата:
            <b className="overpaid">
              {remainingMoney}
              {CURRENCIES[currentCurrency]?.symbol}
            </b>
          </p>
        ) : (
          <div className="summary-info-debt">
            <div>
              <Popconfirm
                title="Списання боргів"
                description={`Ви дійсно впевнені, що хочете списати ${Math.abs(remainingMoney)}${CURRENCIES[currentCurrency]?.symbol} боргу?`}
                okText="Так"
                onConfirm={payContractDebt}
                disabled={(currentClient?.clientBalance || 0) < Math.abs(remainingMoney) && !currentClient?.paymentCard?.cardToken}
              >
                <Button
                  type="primary"
                  className="pay-debt-btn"
                  disabled={(currentClient?.clientBalance || 0) < Math.abs(remainingMoney) && !currentClient?.paymentCard?.cardToken}
                  loading={isPayDebtBtnLoading}
                >
                  Списати борг{' '}
                  {(currentClient?.clientBalance || 0) >= Math.abs(remainingMoney)
                    ? 'з балансу'
                    : currentClient?.paymentCard?.cardToken
                    ? 'з карти'
                    : ''}
                </Button>
              </Popconfirm>
              <p>
                Баланс клієнта:{' '}
                <b>
                  {currentClient.clientBalance ?? 0}
                  {CURRENCIES[currentCurrency]?.symbol}
                </b>
              </p>
              <p>
                Карта<b>{currentClient?.paymentCard?.cardToken ? ' ' : ' не '}</b>прив'язана
              </p>
            </div>

            <p className="summary-info-result">
              Борг:
              <b className="debt">
                {Math.abs(remainingMoney)}
                {CURRENCIES[currentCurrency]?.symbol}
              </b>
            </p>
          </div>
        )}
      </div>
      {sugar?.length > 0 ? (
        <>
          <Collapse
            style={{ marginTop: '24px' }}
            activeKey={activeKey}
            accordion
            onChange={(key) => setActiveKey((prevKey) => (prevKey.includes(key) ? [] : [key]))}
          >
            <Panel header="Судові збори" key="1">
              <div className="payment-management">
                <div className="payment-management-block" style={{ marginTop: '0' }}>
                  {sugar
                    .sort((a, b) => new Date(a.payday) - new Date(b.payday))
                    .map((item, index) => (
                      <div
                        key={index}
                        className="payment-management-box"
                        style={{
                          textAlign: 'left',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <b>Дата оплати:</b> {dayjs(item.payday).format('DD.MM.YYYY HH:mm:ss')}
                        </span>

                        <span>
                          <b>Сума: </b>
                          {item.amount}
                          {item.currency ? CURRENCIES[item.currency].symbol || '₴' : '₴'}
                        </span>

                        {typeof item.payforRest === 'number' ? (
                          <span>
                            <b>Залишок: </b>
                            <span style={{ color: 'red' }}>
                              {item.payforRest}
                              {item.currency ? CURRENCIES[item.currency].symbol || '₴' : '₴'}
                            </span>
                          </span>
                        ) : null}
                      </div>
                    ))}
                </div>
              </div>
            </Panel>
          </Collapse>
        </>
      ) : null}
    </Modal>
  );
};

export default PaymentManagement;
