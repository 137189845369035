import { Card, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import { TENDER_TERMS, PRAVO, ROLES, FIL_OLD } from 'common_constants/business';
import { Button } from 'antd';
import { RESPOND_TO_TENDER, CHAT_FOR_TENDER } from 'common_constants/modals';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../store/commonReducer';
import { request, error, success } from '../../tools';
import { useMemo } from 'react';

import './TenderInfo.scss';
import { Link } from 'react-router-dom';
const TenderInfo = ({ data, setTenders, tenders }) => {
  const userAuth = useSelector((state) => state.common.userAuth);
  const dispatch = useDispatch();
  const responded = data?.respondList?.find((item) => item.userId === userAuth._id && !item.canceled);
  const allResponds = ROLES[userAuth.role] <= 4 ? data?.respondList?.filter((item) => item.userId !== userAuth._id && !item.canceled) : []
  
  const handleRefuseTender = () => {
    const onSuccess = (res) => {
      const { tender } = res;
      success();
      setTenders(tenders.map((item) => (item._id === tender._id ? tender : item)));
    };
    const onError = () => {
      error();
    };
    const body = {
      tenderId: data._id,
      respondId: responded?._id,
    };
    request.post('/tenders/refuseTender', body, onSuccess, onError);
  };
  return (
    <Card className="tender-info">
      <div className="tender-info__info">
        <div>
          <span>
            <b>Вартість:</b> {data.sum}₴
          </span>
          <span>
            <b>Ім'я:</b> {data.author.name}
          </span>
          <span>
            <b>Телефон:</b> {data.author.phone}
          </span>
          <span>
            <b>Проблема:</b> {data.problem}
          </span>
          <span>
            <b>Задача:</b> {data.task}
          </span>
        </div>
        <div>
          <span>
            <b>Дата:</b> {dayjs(data.createdAt).format('DD.MM.YYYY')}
          </span>
          <span>
            <b>Срок:</b> {TENDER_TERMS.find((item) => item.value === data.term).label}
          </span>
          <span>
            <b>Вид:</b> {PRAVO[data.type]}
          </span>
          <span>
            <b>Місто:</b> {data.author.location?.city}
          </span>
          {Number.isInteger(data.author.f) && (
            <span>
              <b>Філліал: </b>
              <Link to="filii">{FIL_OLD[data.author.f]}</Link>
            </span>
          )}
        </div>
      </div>
      {responded && (
        <div className="tender-info__responded">
          <div style={{ textAlign: 'center', marginBottom: 10, fontSize: 18 }}>
            <b>Моя відповідь:</b>
          </div>
          <div className="tender-info__responded-text">
            <div>
              <span>
                <b>Ім'я: </b>
                {responded.name}
              </span>
              <span>
                <b>Запропонована сума: </b>
                {responded.fee}₴
              </span>
            </div>
            <div>
              <span>
                <b>Дата:</b> {dayjs(responded.createdAt).format('DD.MM.YYYY')}
              </span>
              <span>
                <b>Срок:</b> {TENDER_TERMS.find((item) => item.value === responded.term).label}
              </span>
            </div>
          </div>
          <div>
            <b>Пропозиція:</b> {responded.proposal}
          </div>
          <div>
            <b>Результат:</b> {responded.taskResult}
          </div>
          <Button
            style={{ width: '100%', marginTop: 10 }}
            type="primary"
            onClick={() =>
              dispatch(setModal({ name: CHAT_FOR_TENDER, data: { tenderId: data._id, respondId: responded?._id, author: data.author.name } }))
            }
          >
            Чат
          </Button>
        </div>
      )}
      {!data?.accepted &&
        (responded ? (
          <Popconfirm
            onConfirm={handleRefuseTender}
            okText="Так"
            cancelText="Ні"
            title="Відмовитись"
            description="Ви впевнені, що хочете відмовитись?"
          >
            <Button danger type="primary" style={{ width: 'auto', marginTop: 10 }}>
              Відмовитись
            </Button>
          </Popconfirm>
        ) : (
          <Button
            onClick={() => dispatch(setModal({ name: RESPOND_TO_TENDER, data, setTenders, tenders }))}
            type="primary"
            style={{ width: 'auto', marginTop: 10 }}
          >
            Взяти
          </Button>
        ))}
      {/* {data?.accepted && data?.userId === userAuth._id && (
        <Button onClick={() => dispatch(setModal({ name: NEW_CONTRACT }))} type="primary" style={{ width: 'auto', marginTop: 10 }}>
          Створити контракт
        </Button>
      )} */}
      {allResponds.length > 0 && (
        <div style={{ textAlign: 'center', marginTop: 10, fontSize: 18 }}>
          <b>Всі відповіді:</b>
        </div>
      )}
      {!data?.accepted &&
        allResponds.map((item) => (
          <div key={item._id} className="tender-info__responded">
            <div className="tender-info__responded-text">
              <div>
                <span>
                  <b>Ім'я: </b>
                  {item.name}
                </span>
                <span>
                  <b>Запропонована сума: </b>
                  {item.fee}₴
                </span>
              </div>
              <div>
                <span>
                  <b>Дата:</b> {dayjs(item.createdAt).format('DD.MM.YYYY')}
                </span>
                <span>
                  <b>Срок:</b> {TENDER_TERMS.find((elem) => elem.value === item.term).label}
                </span>
              </div>
            </div>
            <div>
              <b>Пропозиція:</b> {item.proposal}
            </div>
            <div>
              <b>Результат:</b> {item.taskResult}
            </div>
          </div>
        ))}
    </Card>
  );
};

export default TenderInfo;
